<template>
  <div>
    <div class="columns">
      <div class="column is-half-desktop">
        <div
          v-if="error"
          class="notification is-danger">
          {{ error }}
        </div>
        <div class="field has-text-left">
          <label class="label">Display Name</label>
          <div class="control">
            <input
              v-validate="{ required: true }"
              id="name"
              ref="name"
              key="name-input"
              v-model="name"
              :class="{ 'is-danger': errors.has('name') }"
              class="input is-medium"
              name="name"
              type="text"
              placeholder="Your Full Name">
          </div>
        </div>
        <div class="field has-text-left">
          <label class="label">Email Address and Login</label>
          <div class="control">
            <input
              v-validate="{ required: true }"
              id="email"
              ref="email"
              key="email-input"
              v-model="email"
              :class="{ 'is-danger': errors.has('email') }"
              class="input is-medium"
              name="email"
              type="email"
              placeholder="Your Email Address">
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <a
          class="button is-primary is-rounded"
          @click="save">
          <span class="icon is-small">
            <i class="fas fa-save"/>
          </span>
          <span>Save Changes</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'

export default {
  mixins: [xhr],
  data () {
    return {
      error: null,
      name: null,
      email: null
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  mounted () {
    this.name = this.currentUser.name
    this.email = this.currentUser.email
  },
  methods: {
    save () {
      this.error = null
      var vm = this
      this.$validator.validateAll().then(result => {
        if (result) {
          vm.updateEmail(function (error) {
            if (error) {
              // error updating aws
            } else {
              // success from aws
              // safe to update engine database
              vm.updateUser()
            }
          })
        } else {
          // form has errors do nothing
          vm.error = 'There is a problem with the details form. Please check below for any errors or missing fields.'
        }
      })
    },
    updateEmail (callback) {
      var vm = this
      this.$Amplify.Auth.currentAuthenticatedUser()
        .then(user => {
          return vm.$Amplify.Auth.updateUserAttributes(user, {
            'name': vm.name,
            'email': vm.email.toLowerCase()
          })
        })
        .then(success => {
          vm.$store.dispatch('setNotify', { state: true, text: 'Your information has been updated.' })
          callback()
        })
        .catch(err => {
          switch (err.code) {
            case 'AliasExistsException':
              vm.error = 'The email address you entered is already in use by another account.'
              break
            default:
              vm.error = ''
              break
          }
          callback(err)
        })
    },
    updateUser () {
      var vm = this
      engineApi.saveUser(
        vm.name, vm.email,
        function (err, response) {
          if (err) {
            vm.handleXhrError(err)
          }
          vm.$store.dispatch('fetchUser') // refresh vuex user
        }
      )
    }
  }
}
</script>

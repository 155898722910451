<template>
  <div class="card">
    <div class="header">
      <div class="media">
        <div class="media-content">
          <p class="title is-5 client-name">{{ accountName }}</p>
        </div>
      </div>
      <div class="description-area">
        <div class="columns change-wrapper">
          <div class="column is-size-6 description-fix">
            {{ postText }}
          </div>
        </div>
      </div>
    </div>
    <div class="card-image">
      <figure class="image is-16by9">
        <img :src="postImage"/>
      </figure>
      <div class="link-area">
        <h1 class="is-size-5">
          {{ postHeading }}
        </h1>
        <p class="is-size-6">
          {{ postDescription }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/**
* PROPS
* takes v-model object with data from postContent step
* takes :account object with data from currentAccount
*/
export default {
  props: {
    value: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
    }
  },
  computed: {
    accountName: function () {
      if (this.account) return this.account.name
      return null
    },
    accountInitals: function () {
      if (this.account) {
        if (this.account.name) {
          var initials = this.account.name.match(/\b\w/g) || []
          initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
          return initials
        }
      }
      return null
    },
    postText: function () {
      if (this.value) {
        if (this.value.postContent) {
          return this.value.postContent.model
        }
      }
      return null
    },
    postImage: function () {
      if (this.value) {
        if (this.value.images) {
          if (this.value.images.length > 0) {
            if (typeof this.value.images[this.value.imgIndex] === 'object') return this.value.images[this.value.imgIndex].src
            else return this.value.images[this.value.imgIndex]
          }
        }
      }
      return null
    },
    postHeading: function () {
      if (this.value) {
        if (this.value.words) {
          if (this.value.words.buckets) {
            if (this.value.words.buckets.actionheadings) {
              if (this.value.words.buckets.actionheadings.length > 0) {
                return this.value.words.buckets.actionheadings[this.value.words.buckets.actionheadingIndex]
              }
            }
          }
        }
      }
      return null
    },
    postDescription: function () {
      if (this.value) {
        if (this.value.words) {
          if (this.value.words.buckets) {
            if (this.value.words.buckets.descriptions) {
              if (this.value.words.buckets.descriptions.length > 0) {
                return this.value.words.buckets.descriptions[this.value.words.buckets.descriptionIndex]
              }
            }
          }
        }
      }
      return null
    }
  },
  mounted () {

  }
}
</script>

<style scoped>
.link-area {
  background-color: #f6f7f9;
  padding: 10px;
}
.description-area {
  padding: 10px;
}
.description-fix {
  padding: 0!important;
}
.client-name {
  color: #365899;
}
.post-date {
  margin-top: -20px !important;
}
.card {
  width: 100%;
  max-width: 280px!important;
}
.card .header {
  padding: 5px 10px;
}
.oto-fake-profile-image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #c4c4c4;
  border: 3px solid #c4c4c4;
  color: white;
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  padding-top: 3px;
}
</style>

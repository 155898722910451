import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      guideMode: 'currentGuideMode',
      guideModeId: 'currentGuideModeId'
    })
  },
  beforeRouteLeave (to, from, next) {
    this.TourClose()
    next()
  },
  methods: {
    TourInit (steps, opts) {
      if (!opts) opts = {}
      // these are the default options
      var sleepTime = 1000 // time we sleep before attaching the introjs ... the allows us to wait for dom elements (steps) that we might need to attach the tour too
      var options = {
        overlayOpacity: 0.25,
        showStepNumbers: false,
        showButtons: false,
        showBullets: false,
        exitOnEsc: false,
        keyboardNavigation: false,
        exitOnOverlayClick: false,
        disableInteraction: false,
        tooltipPosition: 'top',
        nextLabel: 'Continue',
        doneLabel: 'Done',
        steps: steps,
        hideSkip: true,
        hideDone: true,
        hideOverlay: false
      }
      if ('overlayOpacity' in opts) options.overlayOpacity = opts.overlayOpacity
      if ('showStepNumbers' in opts) options.showStepNumbers = opts.showStepNumbers
      if ('showButtons' in opts) options.showButtons = opts.showButtons
      if ('showBullets' in opts) options.showBullets = opts.showBullets
      if ('exitOnEsc' in opts) options.exitOnEsc = opts.exitOnEsc
      if ('exitOnOverlayClick' in opts) options.exitOnOverlayClick = opts.exitOnOverlayClick
      if ('disableInteraction' in opts) options.disableInteraction = opts.disableInteraction
      if ('hideSkip' in opts) options.hideSkip = opts.hideSkip
      if ('hideDone' in opts) options.hideDone = opts.hideDone
      if ('doneLabel' in opts) options.doneLabel = opts.doneLabel
      if ('highlightClass' in opts) options.highlightClass = opts.highlightClass
      if ('hideOverlay' in opts) options.hideOverlay = opts.hideOverlay
      if ('sleepTime' in opts) sleepTime = opts.sleepTime
      this.$introjs.exit(true)
      var vm = this
      setTimeout(function () {
        vm.$introjs.setOptions(options)
        vm.$introjs.start()
        if (options.hideDone) {
          vm.$introjs.onbeforeexit(function () { // dont allow exit ( make sure though when you DO want to quit the tour, use introjs.exit(true) )
            return false
          })
        }
      }, sleepTime)
    },
    TourClose () {
      this.$introjs.exit(true)
    },
    TourDone () {
      this.TourClose()
      // let the UI know we're no longer in guide mode
      this.$store.dispatch('setGuideMode', false)
      // send the user to their campaigns page
      this.$router.push({ name: 'route-engine-campaigns' })
      // send google analytics event
      if (process.env.NODE_ENV !== 'development') window.gtag('event', 'help_tour', { 'action': 'completed', 'tourId': this.guideModeId })
    },
    TourCampaignStart (opts) {
      if (!opts) opts = {}
      var steps = [{
        intro: 'When you start a new marketing campaign the first thing must select is the <span class="has-text-weight-bold">type</span> of campaign you\'re running.<br/><br/>You can also create a marketing campaign by using one of the templates from our Campaign Library. The Campaign Library is filled with tons of marketing ideas and concepts to promote your business.'
      },
      {
        element: '#bmoc-sale',
        disableInteraction: true,
        position: 'bottom',
        intro: '<span class="has-text-weight-bold">Sale Campaigns</span> can be used when you\'re promoting the sale of an actual product or service. Use the sale type to promote a gift card sale during the holidays. Or sell member packages for an upcoming season.'
      },
      {
        element: '#bmoc-event',
        disableInteraction: true,
        position: 'bottom',
        intro: '<span class="has-text-weight-bold">Event Campaigns</span> can be used any time you\'re promoting something around a date. Use the event type to run a campaign promoting your next big trivia night and even sell tickets online.'
      },
      {
        element: '#bmoc-golf',
        disableInteraction: true,
        position: 'bottom',
        intro: '<span class="has-text-weight-bold">Golf Campaigns</span> are unique to the industry and can be used any time you want to promote  tee times. You can use these campaigns to embed your online booking engine or sell vouchers of prepaid golf rounds.'
      },
      {
        element: '#bmoc-other',
        disableInteraction: true,
        position: 'top',
        intro: '<span class="has-text-weight-bold">Other Campaigns</span> are available for everything else you want to market or promote. CampaignPilot can create email newsletters, social media posts, and even print content. Plus everything you promote with CampaignPilot comes with a mobile friendly landing page.'
      },
      {
        element: '#campaign-library-section',
        position: 'bottom-right',
        tooltipClass: 'introjs-larger-step',
        intro: 'The <span class="has-text-weight-bold">Campaign Library</span> is a excellent starting point for your next campaign, whether you have an idea or not.<br/><br/>For our tour we\'re going to use a template from the campaign library to build our campaign.<br/><br/><span class="has-text-weight-bold">Click the \'Start With A Campaign Template\' section above to continue.</span>'
      }]
      this.TourInit(steps, opts)
      // send google analytics event
      if (process.env.NODE_ENV !== 'development') window.gtag('event', 'help_tour', { 'action': 'started', 'tourId': this.guideModeId })
    },
    TourCampaignLibrary (opts) {
      if (!opts) opts = {}
      opts.sleepTime = 1 // dont sleep
      var steps = [{
        element: '#campaign-select-find',
        disableInteraction: true,
        position: 'bottom',
        intro: 'CampaignPilot is loaded with campaign templates to help you get started on your next great marketing campaign.<br/><br/>You can use the search box here to help find a template relevant to anything you\'re promoting.'
      },
      {
        element: '#campaign-select-popular',
        disableInteraction: true,
        position: 'top',
        intro: 'You can also search through campaign templates using the rotating list of popular campaign categories.'
      },
      {
        element: '#campaign-select-tags',
        disableInteraction: true,
        position: 'top',
        intro: 'Campaign templates are also organized by tags and keywords to help you find exactly what you\'re looking for.'
      },
      {
        element: '#tag-contest',
        position: 'top',
        intro: 'Let\'s try and find a campaign template to use with CampaignPilot. This looks like a good tag to start with.<br/><br/><span class="has-text-weight-bold">Click the \'Contest\' tag to continue.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourCampaignLibraryTemplate (id, opts) {
      if (!opts) opts = {}
      opts.sleepTime = 1 // dont sleep
      var steps = [{
        element: '#camp-template-' + id,
        disableInteraction: false,
        intro: 'Let\'s try out CampaignPilot by building a campaign to promote a pretend contest you\'re running.<br/><br/>Running a simple contest is a great way to encourage customers to join your email mailing list.<br/><br/><span class="has-text-weight-bold">Click the \'Run A Contest\' campaign to continue.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourCampaignWizard (opts) {
      var vm = this
      var steps = []
      // build steps using the required questions in the campaign
      vm.campaign.questions.forEach(function (question) {
        if (question.validate) {
          if (question.validate.required) {
            if (question.ref === 'title') {
              steps.push({
                element: '#title',
                position: 'bottom',
                intro: 'CampaignPilot will ask you some basic details about your promotion. First we need to enter a title. Think of this like an email subject line.<br/><br/>A title is suggested for us since we\'re using a campaign template but you can enter whatever you want here.'
              })
            } else if (question.ref === 'date') {
              steps.push({
                element: '#date',
                position: 'top',
                intro: 'Since we\'re promoting an event CampaignPilot will ask for a date. Make up a date for our draft ' + vm.campaign.title + '.'
              })
            } else if (question.ref === 'time') {
              steps.push({
                element: '#time',
                position: 'top',
                intro: 'Some campaigns might ask for a start time of your event. Make up a start time for our draft ' + vm.campaign.title + '.'
              })
            } else if (question.ref === 'short description') {
              steps.push({
                element: '#short-description',
                position: 'bottom',
                intro: 'Every campaign needs a short description. CampaignPilot uses this in spots where it needs to quickly describe your promotion.<br/><br/>Again this field has some suggested content. Feel free to adjust it if you like.'
              })
            }
          }
        } else if (question.type === 'text-rich') {
          steps.push({
            element: '#full-description',
            position: 'top',
            intro: 'You can also add a more detailed description of your promotion. Here you can add formatted text, lists, and links.<br/><br/>The campaign template we selected came up with some great text for us already.'
          })
        }
      })
      if (steps.length > 0) {
        steps.push({
          element: '#campaign-wizard-nav',
          tooltipClass: 'introjs-final-step',
          position: 'top',
          intro: 'Step through the Campaign Wizard easily using the navigation section at the bottom of the screen.<br/><br/><span class="has-text-weight-bold">Click the \'Continue\' button.</span>'
        })
        this.TourInit(steps, opts)
      }
    },
    TourTheme (opts) {
      if (!opts) opts = {}
      opts.sleepTime = 1 // dont sleep
      var steps = [{
        element: '#main-theme-holder',
        position: 'top',
        intro: 'CampaignPilot is filled with themes to style your marketing campaigns. We now need to select a theme for your contest campaign.<br/><br/><span class="has-text-weight-bold">Select a theme category to continue.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourThemeTags (opts) {
      if (!opts) opts = {}
      opts.sleepTime = 1 // dont sleep
      var steps = [{
        element: '#main-tags-holder',
        position: 'bottom',
        intro: 'You can use the tags here to further filter the campaign themes.<br/><br/><span class="has-text-weight-bold">Select another tag to continue.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourThemeCard (opts) {
      var steps = [{
        element: '#theme-card-0',
        position: 'top',
        intro: 'This looks like a great theme to use for your contest.<br/><br/><span class="has-text-weight-bold">Click the theme card below to continue.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourThemeStyle (opts) {
      var steps = [{
        element: '#theme-style-control-area',
        intro: 'You can even adjust the style settings of your campaign theme.<br/><br/><span class="has-text-weight-bold">Try changing the color or font to continue.</span>',
        position: 'top'
      }]
      this.TourInit(steps, opts)
    },
    TourThemeContinueStyle (opts) {
      var steps = [{
        element: '#campaign-wizard-nav',
        tooltipClass: 'introjs-final-step',
        position: 'top',
        intro: 'Your campaign is looking great! Let\'s continue to the next step.<br/><br/><span class="has-text-weight-bold">Click the \'Continue\' button.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourLandingPageLayout (opts) {
      if (!opts) opts = {}
      opts.sleepTime = 1 // dont sleep
      var steps = [{
        element: '#landing-page-layout-select',
        position: 'bottom',
        intro: 'Using the theme and campaign details you provided, CampaignPilot has built a beautiful landing page for your contest.<br/><br/>You can customize your landing page even further on this step. Try changing the layout of the page. <br/><br/><span class="has-text-weight-bold">Change the layout above to continue.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourLandingPageImageSelect (opts) {
      var steps = [{
        element: '#landing-page-image-select',
        position: 'bottom',
        intro: 'Look at that, a whole new landing page! You can even change the background image just by clicking on a new one.<br/><br/><span class="has-text-weight-bold">Try changing the background image to continue.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourLandingPageForm (opts) {
      var steps = [{
        element: '#landing-page-edit-form-button',
        position: 'top',
        intro: 'You can also add elements to your landing page, like forms and buttons.<br/><br/>Since you\'re running a contest, let\'s create a form on your landing page to use for sign ups.<br/><br/><span class="has-text-weight-bold">Click the \'Add A Form\' button below to continue.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourLandingPageFormAddEmail (opts) {
      var steps = [{
        element: '#form-add-field-email-input-1',
        position: 'bottom',
        intro: 'CampaignPilot makes it super easy to build a form. You can add preset fields to capture customer data points, like name, email, or phone number, or you can create your own fields.<br/><br/>We want to collect email addresses for your contest so let\'s add that field to your form.<br/><br/><span class="has-text-weight-bold">Click the \'Email Address\' button above to continue.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourLandingPageReview (opts) {
      var vm = this
      setTimeout(function () {
        vm.$scrollTo('#landing-page-iframe-holder', 3000, { easing: 'ease' })
      }, 1500)
      var steps = [{
        element: '#landing-page-iframe-holder',
        disableInteraction: true,
        intro: 'Wow your landing page looks great! You now have a custom, mobile friendly, landing page for your contest campaign.<br/><br/>While in this campaign your landing page is using a form to capture email addresses, CampaignPilot can also generate landing pages for online sales, event registrations, tee time bookings and more.'
      },
      {
        element: '#campaign-wizard-nav',
        tooltipClass: 'introjs-final-step',
        position: 'top',
        showButtons: false,
        intro: 'Now that we have our landing page created we can continue building out marketing content. Continue to the next step.<br/><br/><span class="has-text-weight-bold">Click the \'Continue\' button.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourPostContentImage (opts) {
      if (!opts) opts = {}
      opts.sleepTime = 1 // dont sleep
      var steps = [{
        element: '#image-holder',
        position: 'top',
        tooltipClass: 'introjs-larger-step',
        intro: 'CampaignPilot automatically generates tons of images you can use to market your campaign.<br/><br/>In this step we need to select an image to use for any social media posts or emails we might send.<br/><br/><span class="has-text-weight-bold">Select a new image below to continue.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourPostContentText (opts) {
      var steps = [{
        element: '#post-content-card',
        intro: 'Here is the social media post for your campaign. You will need to add a short post message to your customers.<br/><br/>Something like \'Join our monthly contest today! #winning\' would be appropriate.<br/><br/><span class="has-text-weight-bold">Type in a post message to continue.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourPostContentContinue (opts) {
      var steps = [{
        element: '#campaign-wizard-nav',
        position: 'top',
        tooltipClass: 'introjs-final-step',
        intro: 'Fantastic! Just like that you have a custom marketing message CampaignPilot can use to promote your contest. Even if you\'re not posting a message to social media, this step helps CampaignPilot design print media and emails for your campaign.<br/><br/>Let\'s continue to the last step.<br/><br/><span class="has-text-weight-bold">Click the \'Continue\' button.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourScheduleStart (opts) {
      var steps = [{
        element: '#schedule-campaign-channel-area',
        position: 'bottom',
        tooltipClass: 'introjs-larger-step',
        disableInteraction: true,
        intro: 'CampaignPilot can post this marketing message to different online channels, like Facebook, Twitter, and Google. On this step you can select which channels you want CampaignPilot to automatically post to.<br/><br/>Since we\'re not actually going to post this campaign we can just skip this area for now.'
      },
      {
        element: '#schedule-campaign-schedule-area',
        position: 'top',
        tooltipClass: 'introjs-larger-step',
        disableInteraction: false,
        intro: 'Here you can set the date and time CampaignPilot should post your message.<br/><br/>We\'re not actually going to post this campaign so just make up a date and time.<br/><br/><span class="has-text-weight-bold">Select a date and time to continue.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourScheduleFinal (opts) {
      var steps = [{
        element: '#campaign-wizard-nav',
        position: 'top',
        tooltipClass: 'introjs-final-step',
        intro: 'That\'s it! You made it through the CampaignPilot Campaign Wizard. We\'ll finish off the tour by quickly going over what happens after your marketing campaign is built.<br/><br/><span class="has-text-weight-bold">Click the \'Save and Schedule\' button to finish the tour.</span>'
      }]
      this.TourInit(steps, opts)
    },
    TourConfirmation (opts) {
      if (!opts) opts = {}
      opts.sleepTime = 1
      var steps = [{
        element: '#confirmation-page-section-summary',
        position: 'bottom',
        disableInteraction: true,
        intro: 'When you\'re done building your campaign the confirmation page will provide you with a link to a dedicated Campaign Summary page. <br/><br/>This summary page will detail EVERYTHING about your campaign and is super useful to share with colleagues and other staff members to review.'
      },
      {
        element: '#confirmation-page-section-landing',
        position: 'top',
        disableInteraction: true,
        intro: 'Every message CampaignPilot sends will link back to your custom landing page. That is where your customers can fill out forms, make purchases, or buy tickets.<br/><br/>The landing page details will be displayed on the confirmation page here so that you can easily review and share it.'
      },
      {
        element: '#confirmation-page-section-print',
        position: 'top',
        disableInteraction: true,
        intro: 'CampaignPilot automatically generates print materials for every marketing campaign. Print out and use the included 8x10 flyers or signs around your business to market your campaign. The print materials even have these cool little QR codes that link back to your landing page.'
      },
      {
        tooltipClass: 'introjs-larger-step',
        intro: 'That completes our tour of the Campaign Wizard. Hopefully you see that building a marketing campaign for your business is easy with CampaignPilot.<br/><br/>Once you start publishing live campaigns, CampaignPilot will automatically handle the data and statistic collection. With every marketing campaign you will be able to see which online channels are performing the best, where your customers are coming from, and export all your form submissions and orders.<br/><br/>With that we\'ll leave you to it. We know you have many options when it comes to marketing and we thank you for choosing to fly with CampaignPilot.<br/><br/><a role="button" class="introjs-button introjs-nextbutton" id="tour-exit-button">Exit Tour</a>'
      }]
      this.TourInit(steps, opts)
    },
    TourSettingsChannels (opts) {
      if (!opts) opts = {}
      opts.sleepTime = 1
      var steps = [{
        intro: 'CampaignPilot auto-distributes your marketing campaigns to online channels like Facebook and Twitter. This section allows you to configure those channels so CampaignPilot knows where and how to post them.'
      },
      {
        element: '#vertical-tabs-channels',
        position: 'bottom',
        disableInteraction: true,
        intro: 'You will only need to configure your channels once. Click through each one here and follow the instructions on how to set them up.'
      },
      {
        element: '#settings-channels-facebook-area',
        position: 'bottom',
        disableInteraction: true,
        intro: 'The Facebook channel, for example, requires you to log into your Facebook account. This will grant CampaignPilot secure access to publish campaigns to your business\'s Facebook page.'
      },
      {
        element: '#settings-tour-back-button',
        position: 'bottom',
        disableInteraction: true,
        intro: 'When you\'re done configuring your channels, you can click this button to return to your marketing work.<br/><br/>For now you can exit the tour and try setting up a channel!'
      }]
      this.TourInit(steps, opts)
    },
    TourSettingsPayments (opts) {
      if (!opts) opts = {}
      opts.sleepTime = 1
      var steps = [{
        intro: 'When you\'re marketing something for sale, like a product or ticket, you have the option of selling that item online using CampaignPilot. This section allows you to set up a payment processor to securely take payment from your customers.'
      },
      {
        element: '#vertical-tabs-payments',
        position: 'bottom',
        disableInteraction: true,
        intro: 'CampaignPilot has several payment processors you can select from. You only need to set up one payment processor to enable payments with CampaignPilot.'
      },
      {
        element: '#settings-channels-stripe-area',
        position: 'bottom',
        disableInteraction: true,
        intro: 'Enable the payment processor you want to use and then follow the instructions here to complete the configuration. If you need any help along the way, just click the chat bubble to connect with our support team.'
      },
      {
        element: '#settings-tour-back-button',
        position: 'bottom',
        disableInteraction: true,
        intro: 'When you\'re done configuring a payment processor, you can click this button to return to your marketing work.<br/><br/>For now you can exit the tour and try setting up a payment processor!'
      }]
      this.TourInit(steps, opts)
    },
    TourSettingsLogoStart (opts) {
      var steps = [{
        intro: 'Uploading your business logo customizes all the content generated by CampaignPilot. We\'ll add your logo to landing pages and print content automatically once you upload one.'
      },
      {
        element: '#settings-media-logo-area',
        position: 'top',
        disableInteraction: false,
        intro: 'Let\'s add your logo to CampaignPilot. We\'ll need to select an image from your computer to use.<br/><br/>Click the Add Logo button to continue.'
      }]
      this.TourInit(steps, opts)
    },
    TourSettingsLogoUpload (opts) {
      var steps = [{
        element: '#image-uploader',
        position: 'top',
        disableInteraction: false,
        intro: 'Click the button below to open your file browser and find the image you want to use for your logo.'
      }]
      this.TourInit(steps, opts)
    },
    TourSettingsLogoAdjust (opts) {
      var steps = [{
        element: '#image-uploader',
        position: 'bottom',
        disableInteraction: false,
        intro: 'That looks great! Use your mouse to position your logo inside the gray box.<br/><br/>When you\'re done click Save Logo.'
      }]
      this.TourInit(steps, opts)
    }
  }
}

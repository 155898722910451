<template>
  <div
    :class="{ 'is-active': iu.modalOpen }"
    class="modal">
    <div class="modal-background"/>
    <div
      :style="{ maxWidth: modalWidth, minWidth: modalWidth }"
      class="modal-content">
      <div class="card" id="image-uploader">
        <div class="card-content" id="image-uploader-top-section">
          <div v-if="croppa.img">
            <p class="title">
              <span v-if="mode === 'logo'">Edit Your Logo</span>
              <span v-else>Edit Your Image</span>
            </p>
            <p class="subtitle">
              <span v-if="mode === 'logo'">Use the controls below to crop and resize your logo.</span>
              <span v-else>Use the controls below to crop and resize your image.</span>
            </p>
            <hr>
            <div
              v-if="iu.allowedRatios.length > 1"
              class="field">
              <label class="label">Format</label>
              <div class="buttons has-addons">
                <span
                  v-if="iu.allowedRatios.includes('square')"
                  :class="{ 'is-primary':imageMode === 'square' }"
                  class="button is-rounded"
                  @click="setRatio('square')">
                  Square
                </span>
                <span
                  v-if="iu.allowedRatios.includes('landscape')"
                  :class="{ 'is-primary':imageMode === 'landscape' }"
                  class="button is-rounded"
                  @click="setRatio('landscape')">
                  Landscape
                </span>
                <span
                  v-if="iu.allowedRatios.includes('widescreen')"
                  :class="{ 'is-primary':imageMode === 'widescreen' }"
                  class="button is-rounded"
                  @click="setRatio('widescreen')">
                  Widescreen
                </span>
                <span
                  v-if="iu.allowedRatios.includes('landing page')"
                  :class="{ 'is-primary':imageMode === 'landing page' }"
                  class="button is-rounded"
                  @click="setRatio('landing page')">
                  Widescreen
                </span>
                <span
                  v-if="iu.allowedRatios.includes('wide logo')"
                  :class="{ 'is-primary':imageMode === 'wide logo' }"
                  class="button is-rounded"
                  @click="setRatio('wide logo')">
                  Wide
                </span>
                <span
                  v-if="iu.allowedRatios.includes('square logo')"
                  :class="{ 'is-primary':imageMode === 'square logo' }"
                  class="button is-rounded"
                  @click="setRatio('square logo')">
                  Square
                </span>
                <span
                  v-if="iu.allowedRatios.includes('tall logo')"
                  :class="{ 'is-primary':imageMode === 'tall logo' }"
                  class="button is-rounded"
                  @click="setRatio('tall logo')">
                  Tall
                </span>
              </div>
            </div>
            <div
              v-if="iu.includeTitle"
              class="field">
              <label class="label">Alternative Text</label>
              <div class="control">
                <input
                  v-model="imageTitle"
                  class="input"
                  type="text"
                  placeholder="A text description of the image for ADA compliance">
              </div>
            </div>
            <div class="field">
              <label class="label">
                <span v-if="mode === 'logo'">Your Logo</span>
                <span v-else>Your Image</span>
              </label>
            </div>
            <div
              v-if="ratioWarn"
              class="field">
              <span class="icon has-text-danger">
                <i class="fa fa-exclamation-triangle"/>
              </span>
              {{ ratioWarn }}
            </div>
          </div>
          <div v-else>
            <p class="title">
              <span v-if="mode === 'logo'">Upload Your Logo</span>
              <span v-else>Upload Your Image</span>
            </p>
            <p class="subtitle padding-bottom">
              <span v-if="mode === 'logo'">Select a PNG logo from your device to get started.</span>
              <span v-else>Select a JPG/JPEG image from your device to get started.</span>
            </p>
          </div>
          <div class="columns croppa-holder" id="croppa-holder">
            <div class="column">
              <croppa
                v-model="croppa"
                :quality="quality"
                :placeholder="'Click Here'"
                :placeholder-font-size="18"
                :width="imageWidth"
                :height="imageHeight"
                :prevent-white-space="preventWhiteSpace"
                :show-remove-button="false"
                @new-image-drawn="onNewImage"
                @zoom="onZoom"/>
            </div>
          </div>
          <div
            v-if="croppa.img"
            :style="{ maxWidth: imageWidth + 'px', minWidth: imageWidth + 'px' }"
            class="columns is-mobile image-controls-bottom">
            <div class="column">
              <span class="icon">
                <i class="fa fa-arrows-alt"/>
              </span>
              Click and hold to drag
              <br/>
              <span class="icon">
                <i class="fa fa-mouse"/>
              </span>
              Mouse wheel to zoom
            </div>
            <div class="column has-text-right">
              <a
                class="button is-small is-rounded"
                @click="rotateImage">
                <span class="icon">
                  <i class="fa fa-undo"/>
                </span>
                <span>Rotate</span>
              </a>
            </div>
          </div>
        </div>
        <footer class="card-footer">
          <a
            v-if="croppa.img"
            class="card-footer-item"
            @click="generateImage">
            <span v-if="mode === 'logo'">Save Logo</span>
            <span v-else>Save Image</span>
          </a>
          <a
            class="card-footer-item"
            @click="closeModal">
            Cancel
          </a>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
/**
* PROPS
* takes :iu object with params
*   modalOpen: boolean
*   includeTitle: boolean
*   defaultTitle: string
*   allowedRatios: array from `square`, `landscape`, `widescreen`
*   defaultRatio: string from `square`, `landscape`, `widescreen`
*   format: string from `image/jpeg`
*   compress: double percent like 99% is passed as 0.99
* EMITS
* input
*   returns object with params
*     title: string
*     url: base64 image url string
*/
import tour from '@/mixins/Tour.js'

export default {
  mixins: [tour],
  props: {
    iu: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      mode: 'image', // 'image', 'campaign', or 'logo'
      croppa: {},
      zoomValue: 0,
      zoomMin: 0,
      zoomMax: 0,
      imageWidth: 1,
      imageHeight: 1,
      modalWidth: null,
      windowWidth: null,
      quality: null,
      ratioWarn: null,
      screenMode: null,
      imageMode: null,
      imageTitle: null,
      preventWhiteSpace: true
    }
  },
  watch: {
    'iu.modalOpen' (val) {
      if (val) {
        this.openModal()
      }
    },
    'croppa.img' (val) {
      if (val) {
        if (this.guideMode) {
          this.TourClose()
          this.TourSettingsLogoAdjust({ showButtons: false, hideOverlay: true, highlightClass: 'introjs-hide-helper-layer' })
        }
      }
    }
  },
  mounted () {
    if (this.iu) {
      if (this.iu.defaultTitle) {
        this.imageTitle = this.iu.defaultTitle
      }
      if (this.iu.mode) {
        this.mode = this.iu.mode
        if (this.mode === 'logo') {
          this.preventWhiteSpace = false
        }
      }
    }
    // since this is a modal, we're going to move it to an outside div
    document.body.appendChild(this.$el)
  },
  destroyed () {
    // destroy what we appended, the append functions more like vue then
    document.body.removeChild(this.$el)
  },
  methods: {
    openModal () {
      this.setScreenMode()
      this.setRatio(this.iu.defaultRatio)
      this.iu.modalOpen = true
      if (this.guideMode) this.TourSettingsLogoUpload({ showButtons: false, hideOverlay: true, highlightClass: 'introjs-hide-helper-layer' })
    },
    closeModal () {
      if (this.guideMode) this.TourClose()
      this.iu.modalOpen = false
      this.croppa.remove()
    },
    setRatio (type) {
      this.imageMode = type
      var width
      var height
      var padding = 40
      if (this.imageMode === 'square') {
        width = 800
        height = 800
        if (this.screenMode === 'mobile') {
          this.quality = 5
        } else if (this.screenMode === 'tablet') {
          this.quality = 3
        } else if (this.screenMode === 'desktop') {
          this.quality = 3
        } else if (this.screenMode === 'widescreen') {
          this.quality = 2
        }
      } else if (this.imageMode === 'landscape') {
        width = 1024
        height = 512
        if (this.screenMode === 'mobile') {
          this.quality = 5
        } else if (this.screenMode === 'tablet') {
          this.quality = 3
        } else if (this.screenMode === 'desktop') {
          this.quality = 3
        } else if (this.screenMode === 'widescreen') {
          this.quality = 2
        }
      } else if (this.imageMode === 'widescreen') {
        width = 1600
        height = 900
        if (this.screenMode === 'mobile') {
          this.quality = 5
        } else if (this.screenMode === 'tablet') {
          this.quality = 3
        } else if (this.screenMode === 'desktop') {
          this.quality = 3
        } else if (this.screenMode === 'widescreen') {
          this.quality = 2
        }
      } else if (this.imageMode === 'landing page') {
        width = 2000
        height = 1125
        if (this.screenMode === 'mobile') {
          this.quality = 6
        } else if (this.screenMode === 'tablet') {
          this.quality = 4
        } else if (this.screenMode === 'desktop') {
          this.quality = 4
        } else if (this.screenMode === 'widescreen') {
          this.quality = 3
        }
      } else if (this.imageMode === 'wide logo') {
        width = 750
        height = 150
        if (this.screenMode === 'mobile') {
          this.quality = 5
        } else if (this.screenMode === 'tablet') {
          this.quality = 3
        } else if (this.screenMode === 'desktop') {
          this.quality = 3
        } else if (this.screenMode === 'widescreen') {
          this.quality = 2
        }
      } else if (this.imageMode === 'tall logo') {
        width = 100
        height = 500
        if (this.screenMode === 'mobile') {
          this.quality = 5
        } else if (this.screenMode === 'tablet') {
          this.quality = 3
        } else if (this.screenMode === 'desktop') {
          this.quality = 3
        } else if (this.screenMode === 'widescreen') {
          this.quality = 2
        }
      } else if (this.imageMode === 'square logo') {
        width = 500
        height = 500
        if (this.screenMode === 'mobile') {
          this.quality = 5
        } else if (this.screenMode === 'tablet') {
          this.quality = 3
        } else if (this.screenMode === 'desktop') {
          this.quality = 3
        } else if (this.screenMode === 'widescreen') {
          this.quality = 2
        }
      }
      this.imageWidth = width / this.quality
      this.imageHeight = height / this.quality
      // Set the modal width, this is set to the highest width in the allowed ratios
      var startModalWidthAt = 0
      if (this.iu.allowedRatios.includes('landing page')) {
        startModalWidthAt = 2000
      } else if (this.iu.allowedRatios.includes('widescreen') || this.screenMode === 'mobile') {
        startModalWidthAt = 1600
      } else if (this.iu.allowedRatios.includes('landscape')) {
        startModalWidthAt = 1024
      } else {
        startModalWidthAt = 800
      }
      this.modalWidth = ((startModalWidthAt / this.quality) + padding) + 'px'
      this.ratioWarn = null
      if (width && height) {
        if (this.croppa.img) {
          if (this.croppa.naturalWidth < width || this.croppa.naturalHeight < height) {
            this.ratioWarn = 'The image you uploaded might be too small and may appear pixelated. The recommended image size for this format is ' + width + ' x ' + height + ' pixels, however the image you uploaded is only ' + this.croppa.naturalWidth + ' x ' + this.croppa.naturalHeight + ' pixels.'
          }
        }
      }
    },
    onNewImage () {
      this.zoomValue = this.croppa.scaleRatio
      this.zoomMin = 1
      this.zoomMax = this.croppa.scaleRatio * 20
      this.setRatio(this.imageMode)
    },
    onZoom () {
      // // To prevent zooming out of range when using scrolling to zoom
      // if (this.zoomMax && this.croppa.scaleRatio >= this.zoomMax) {
      //   this.croppa.scaleRatio = this.zoomMax
      // } else if (this.zoomMin && this.croppa.scaleRatio <= this.zoomMin) {
      //   this.croppa.scaleRatio = this.zoomMin
      // }
      // this.zoomValue = this.croppa.scaleRatio
    },
    rotateImage () {
      this.croppa.rotate(1)
    },
    setScreenMode () {
      var windowWidth = document.documentElement.clientWidth
      if (windowWidth < 768) {
        this.screenMode = 'mobile'
      } else if (windowWidth >= 769 && windowWidth < 1024) {
        this.screenMode = 'tablet'
      } else if (windowWidth >= 1024 && windowWidth < 1216) {
        this.screenMode = 'desktop'
      } else if (windowWidth >= 1216) {
        this.screenMode = 'widescreen'
      }
    },
    generateImage () {
      var url = this.croppa.generateDataUrl(this.iu.format, this.iu.compress)
      this.$emit('input', { title: this.imageTitle, url: url, type: this.imageMode })
      this.closeModal()
    }
  }
}
</script>

<style scoped>
.modal-content {
  width: calc(100% - 50px);
}
.croppa-holder {
  margin-bottom: 0;
  text-align: center;
}
.croppa-holder > .columns {
  padding-bottom: 0;
}
.image-controls-bottom {
  margin-left: 0px;
}
.image-controls-bottom > .column {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
</style>

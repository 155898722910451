<template>
  <div class="oto-duo">
    <div class="oto-lead">
      <!-- Left Column -->
      <div id="leftColumnTop"/>
      <div class="step-heading is-hidden-mobile">
        <h2 class="subtitle uppercase">
          Campaign: {{ vuexWizardTitle }}
        </h2>
        <h1 class="title">
          Booking Engine Details
        </h1>
      </div>
      <div
        v-if="hasErrors"
        class="notification is-danger">
        Looks like there is a problem here with your booking engine settings. Please check below for any highlighted errors.
      </div>
      <div>
        <!-- Booking engine URL -->
        <TextInput
          v-if="!foreUPEnabled"
          :props="url"
          :focus="true"
          v-model="url.model"
          @focus="changeHelp(url)"/>
        <!-- Time of Day -->
        <div class="field">
          <label class="label">Time of Day</label>
          <div class="field has-addons">
            <p class="control">
              <a
                :class="{'is-primary': timeOfDay === null}"
                class="button is-rounded"
                @click="setTimeOfDay('any')">
                <span>All Day</span>
              </a>
            </p>
            <p class="control">
              <a
                :class="{'is-primary': timeOfDay === 'morning'}"
                class="button is-rounded"
                @click="setTimeOfDay('morning')">
                <span class="icon is-small">
                  <i class="fas fa-coffee"></i>
                </span>
                <span>Morning</span>
              </a>
            </p>
            <p class="control">
              <a
                :class="{'is-primary': timeOfDay === 'afternoon'}"
                class="button is-rounded"
                @click="setTimeOfDay('afternoon')">
                <span class="icon is-small">
                  <i class="fas fa-sun"></i>
                </span>
                <span>Afternoon</span>
              </a>
            </p>
            <p class="control">
              <a
                :class="{'is-primary': timeOfDay === 'evening'}"
                class="button is-rounded"
                @click="setTimeOfDay('evening')">
                <span class="icon is-small">
                  <i class="fas fa-moon"></i>
                </span>
                <span>Twilight</span>
              </a>
            </p>
          </div>
        </div>
        <!-- Number of Players -->
        <div v-if="!foreUPEnabled" class="field">
          <label class="label">Number of Players</label>
          <div class="field has-addons">
            <p class="control">
              <a
                :class="{'is-primary': numberOfPlayers === null}"
                class="button is-rounded"
                @click="setPlayers('any')">
                <span>Any</span>
              </a>
            </p>
            <p class="control">
              <a
                :class="{'is-primary': numberOfPlayers === '1'}"
                class="button is-rounded"
                @click="setPlayers('1')">
                <span>1</span>
              </a>
            </p>
            <p class="control">
              <a
                :class="{'is-primary': numberOfPlayers === '2'}"
                class="button is-rounded"
                @click="setPlayers('2')">
                <span>2</span>
              </a>
            </p>
            <p class="control">
              <a
                :class="{'is-primary': numberOfPlayers === '3'}"
                class="button is-rounded"
                @click="setPlayers('3')">
                <span>3</span>
              </a>
            </p>
            <p class="control">
              <a
                :class="{'is-primary': numberOfPlayers === '4'}"
                class="button is-rounded"
                @click="setPlayers('4')">
                <span>4</span>
              </a>
            </p>
          </div>
        </div>
        <!-- foreup -->
        <div v-if="foreUPEnabled">
          <div>
            <div class="field">
              <Toggler
                :props="foreup.toggle"
                :label="foreup.toggle.labelStyle"
                v-model="foreup.toggle.model"/>
            </div>
            <div v-if="foreup.toggle.model">
              <foreUPTeesheets
                :props="foreup.teesheet"
                v-model="foreup.teesheet.model" />
              <foreUPReservationGroups
                v-if="foreup.teesheet.model"
                :props="foreup.reservationGroup"
                :teesheetId="foreup.teesheet.model.id"
                v-model="foreup.reservationGroup.model" />
              <div class="field is-horizontal">
                <div class="field-body">
                  <TextInput
                    :props="foreup.price18gf"
                    :control="foreup.price18gf.control"
                    v-model="foreup.price18gf.model"/>
                  <TextInput
                    :props="foreup.price18cart"
                    :control="foreup.price18cart.control"
                    v-model="foreup.price18cart.model"/>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-body">
                  <TextInput
                    :props="foreup.price9gf"
                    :control="foreup.price9gf.control"
                    v-model="foreup.price9gf.model"/>
                  <TextInput
                    :props="foreup.price9cart"
                    :control="foreup.price9cart.control"
                    v-model="foreup.price9cart.model"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <aside class="oto-side">
      <div class="step-heading is-hidden-tablet">
        <h2 class="subtitle uppercase">
          Campaign: {{ vuexWizardTitle }}
        </h2>
        <h1 class="title">
          Product Details
        </h1>
      </div>
      <div class="content">
        <div class="right-column-text">
          You can integrate your tee time booking engine into the landing page CampaignPilot generates for this golf campaign. Tell us some details about your booking engine.
        </div>
        <div
          v-if="currentHelp"
          id="sticky"
          class="help-notification notification is-hidden-mobile is-primary sticky-div">
          <div class="help-label capitalize">
            {{ currentHelpLabel }}
          </div>
          <div class="help-text">
            {{ currentHelp }}
          </div>
          <span class="icon is-medium has-text-white">
            <i class="fas fa-info-circle fa-lg"/>
          </span>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TextInput from '@/components/elements/TextInput'
import Toggler from '@/components/elements/Toggler'
import foreUPTeesheets from '@/components/elements/foreUPTeesheets'
import foreUPReservationGroups from '@/components/elements/foreUPReservationGroups'

export default {
  components: {
    TextInput,
    Toggler,
    foreUPTeesheets,
    foreUPReservationGroups
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    hasErrors: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      currentHelp: null,
      currentHelpLabel: null,
      url: {
        name: 'booking engine url',
        label: 'Booking Engine URL',
        placeholder: 'http://www.golf-at-my-course.com/',
        model: null,
        validate: { required: true },
        desktopHelp: 'Enter the full website URL to your booking engine. This is the URL given to you from your tee sheet provider and will include their domain.'
      },
      timeOfDay: null,
      numberOfPlayers: null,
      foreup: {
        toggle: {
          model: false,
          name: 'foreup-special-toggle',
          ref: 'foreup-special-toggle',
          labelStyle: { style: 'flex-grow: 5!important' },
          label: 'foreUP Online Special',
          placeholder: 'Create an online special in foreUP?',
          mobileHideLabel: false,
          options: { checked: 'Yes', unchecked: 'No' },
          height: 30,
          width: 80
        },
        teesheet: {
          model: null,
          name: 'foreup-teesheet',
          ref: 'foreup-teesheet',
          label: 'Online Teesheet',
          icon: 'fa-table',
          selectLabel: 'Select a tee sheet'
        },
        reservationGroup: {
          model: null,
          name: 'foreup-reservation-group',
          ref: 'foreup-reservation-group',
          label: 'Reservation Group',
          icon: 'fa-users',
          selectLabel: 'Select a reservation group'
        },
        price18gf: {
          model: null,
          name: 'foreup-18gf',
          ref: 'foreup-18gf',
          label: '18 Hole Green Fee',
          icon: 'fas fa-dollar-sign',
          placeholder: '100.00',
          convertTo: 'price',
          rightIcon: 'fas fa-flag',
          fieldClass: 'oto-field-no-grow',
          control: {
            divstyle: 'max-width: 140px;'
          }
        },
        price18cart: {
          model: null,
          name: 'foreup-18cart',
          ref: 'foreup-18cart',
          label: '18 Hole Cart Fee',
          icon: 'fas fa-dollar-sign',
          placeholder: '100.00',
          convertTo: 'price',
          rightIcon: 'fas fa-truck-pickup',
          fieldClass: 'oto-field-no-grow',
          control: {
            divstyle: 'max-width: 140px;'
          }
        },
        price9gf: {
          model: null,
          name: 'foreup-9gf',
          ref: 'foreup-9gf',
          label: '9 Hole Green Fee',
          icon: 'fas fa-dollar-sign',
          placeholder: '100.00',
          convertTo: 'price',
          rightIcon: 'fas fa-flag',
          fieldClass: 'oto-field-no-grow',
          control: {
            divstyle: 'max-width: 140px;'
          }
        },
        price9cart: {
          model: null,
          name: 'foreup-9cart',
          ref: 'foreup-9cart',
          label: '9 Hole Cart Fee',
          icon: 'fas fa-dollar-sign',
          placeholder: '100.00',
          convertTo: 'price',
          rightIcon: 'fas fa-truck-pickup',
          fieldClass: 'oto-field-no-grow',
          control: {
            divstyle: 'max-width: 140px;'
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      vuexWizardTitle: 'wizardTitle',
      vuexWizardDetails: 'wizardDetails',
      currentAccount: 'currentAccount'
    }),
    teetime: function () {
      return {
        url: this.url,
        params: this.urlParams,
        fullUrl: this.fullUrl,
        timeOfDay: this.timeOfDay,
        numberOfPlayers: this.numberOfPlayers,
        foreup: this.foreup
      }
    },
    urlParams: function () {
      var ps = []
      // DATE INJECTION
      // this.defaultDate is stored as YYYY-MM-DD
      if (this.defaultDate && this.provider) {
        // if (this.provider === 'GN01')
        if (this.provider === 'GN02') ps.push('d=' + this.defaultDate)
        if (this.provider === 'GN03') ps.push('d=' + this.defaultDate)
        if (this.provider === 'GN04') ps.push('d_date=' + this.defaultDate)
        if (this.provider === 'EZ01') ps.push('dt=' + this.defaultDate.replace(/-/g, ''))
        if (this.provider === 'EZ02') ps.push('dt=' + this.defaultDate.replace(/-/g, ''))
        if (this.provider === 'QE01') ps.push('teedate=' + this.defaultDate.replace(/-/g, ''))
        if (this.provider === 'CP01') ps.push('Date=' + this.defaultDate)
        if (this.provider === 'CP02') ps.push('Date=' + this.defaultDate)
      }
      // TIME INJECTION
      // this.timeOfDay is stored as morning, afternoon, evening
      if (this.timeOfDay && this.provider) {
        // CPS
        if (this.provider === 'CP01' || this.provider === 'CP02') {
          if (this.timeOfDay === 'morning') ps.push('Time=Morning')
          if (this.timeOfDay === 'afternoon') ps.push('Time=Afternoon')
          if (this.timeOfDay === 'evening') ps.push('Time=Evening')
        }
      }
      // NUMBER OF PLAYERS
      if (this.numberOfPlayers && this.provider) {
        // CPS
        if (this.provider === 'CP01' || this.provider === 'CP02') {
          if (this.numberOfPlayers === '1' || this.numberOfPlayers === '2' || this.numberOfPlayers === '3' || this.numberOfPlayers === '4') ps.push('Player=' + this.numberOfPlayers)
        }
      }
      if (ps.length > 0) return ps.join('&')
      else return null
    },
    defaultDate: function () {
      var d = null
      this.vuexWizardDetails.questions.every(function (question) {
        if (question.name) {
          if (question.model) {
            if (question.name === 'date') {
              d = question.model.split(',')[0]
            }
          }
        }
        if (d) {
          return false
        } else {
          return true
        }
      })
      return d
    },
    provider: function () {
      if (this.url.model.match(/play\.teeitup\.com/g)) return 'GN01' // something.play.teeitup.com (GolfNow version 01)
      if (this.url.model.match(/go\.teeitup\.com/g)) return 'GN02' // go.teeitup.com (GolfNow version 02)
      if (this.url.model.match(/golf\.teeitup\.com/g)) return 'GN03' // golf.teeitup.com (GolfNow version 03)
      if (this.url.model.match(/brsgolf\.com/g)) return 'GN04' // www.brsgolf.com (GolfNow version 04)
      if (this.url.model.match(/ezlinksgolf\.com/g)) return 'EZ01' // something.ezlinksgolf.com (EZLinks version 01)
      if (this.url.model.match(/ezlinks\.com/g)) return 'EZ02' // something.ezlinks.com (EZLinks version 02)
      if (this.url.model.match(/quick18\.com/g)) return 'QE01' // something.quick18.com (Quick18 version 01)
      if (this.url.model.match(/prophetservices\.com/g)) return 'CP01' // secure.east.prophetservices.com (ClubProphet 01)
      if (this.url.model.match(/cps\.golf/g)) return 'CP02' // something.cps.golf (ClubProphet version 02)
      else return null // Unknown provider
    },
    fullUrl: function () {
      var u = this.url.model
      if (u) {
        if (this.provider === 'GN01') {
          if (!u.match(/^.*\/$/g)) u = u + '/' // assure theres a trailing slash
          return u + this.defaultDate
        }
        if (this.provider === 'GN02' || this.provider === 'GN03') {
          if (this.urlParams) {
            return u + '&' + this.urlParams
          }
          return u
        }
        // this below is the catch-all default behavior
        if (this.urlParams) {
          if (!u.match(/^.*\?$/g)) u = u + '?' // add a question mark if there isn't one (and the engine needs it)
          return u + this.urlParams
        }
        return u
      }
      return null
    },
    foreUPEnabled: function () {
      if (this.currentAccount.foreup_active && this.currentAccount.foreup_id) return true
      return false
    }
  },
  watch: {
    url: {
      handler: function (val) {
        this.$emit('input', this.teetime)
      },
      deep: true
    },
    timeOfDay () {
      this.$emit('input', this.teetime)
    },
    numberOfPlayers () {
      this.$emit('input', this.teetime)
    },
    foreup: {
      handler: function (val) {
        this.$emit('input', this.teetime)
      },
      deep: true
    },
    value (val, oval) {
      if (val && oval === null) {
        this.refresh()
      }
    }
  },
  mounted () {
    if (this.value) {
      this.refresh()
    } else {
      // we dont have any info to load, maybe prime the url?
      if (this.currentAccount) {
        if (this.currentAccount['foreup_id'] && this.currentAccount['foreup_teesheet']) {
          var teesheet = this.currentAccount['foreup_teesheet']
          if (typeof teesheet === 'string') teesheet = JSON.parse(teesheet)
          this.url.model = 'https://foreupsoftware.com/index.php/booking/' + this.currentAccount['foreup_id'] + '/' + teesheet.id + '#teetimes'
          this.foreup.teesheet.model = teesheet
        } else if (this.currentAccount['booking_engine_url']) {
          this.url.model = this.currentAccount['booking_engine_url']
        }
      }
    }
  },
  methods: {
    changeHelp (question) {
      var label = question.name
      if (question.label && !question.useNameInError) {
        label = question.label
      }
      if (question.helpTitle) {
        label = question.helpTitle
      }
      this.currentHelpLabel = label
      this.currentHelp = question.desktopHelp
    },
    refresh () {
      if (this.value) {
        if (this.value.url) this.url = this.value.url
        if (this.value.timeOfDay) this.timeOfDay = this.value.timeOfDay
        if (this.value.numberOfPlayers) this.numberOfPlayers = this.value.numberOfPlayers
        if (this.value.foreup) this.foreup = this.value.foreup
      } else if (this.currentAccount) {
        if (this.currentAccount.booking_engine_url) this.url.model = this.currentAccount.booking_engine_url
      }
    },
    setTimeOfDay (v) {
      this.changeHelp({
        name: 'Time of Day',
        desktopHelp: 'Depending on your booking engine provider, we can display tee times during a specific time period.'
      })
      if (v === 'morning' || v === 'afternoon' || v === 'evening') this.timeOfDay = v
      else this.timeOfDay = null
    },
    setPlayers (v) {
      this.changeHelp({
        name: 'Number of Players',
        desktopHelp: 'Depending on your booking engine provider, we can display only tee times that have a specific quantity available.'
      })
      if (v === '4' || v === '3' || v === '2' || v === '1') this.numberOfPlayers = v
      else this.numberOfPlayers = null
    }
  },
  inject: {
    $validator: '$validator'
  }
}
</script>

<template>
  <div class="container">
    <div class="columns search-bar">
      <div class="column">
        <router-link
          :to="{ name: 'route-engine-campaigns-wizard' }"
          class="button is-rounded is-primary new-campaign-button">
          <span>Create A New Campaign</span>
        </router-link>
      </div>
      <div class="column">
        <transition name="fade" mode="out-in">
          <TextInput
            v-if="hasLoaded && hasCampaigns"
            class="search-campaigns-input"
            :props="searchInput"
            v-model="searchInput.model"
            @click="searchCampaigns()"
            @enter="searchCampaigns()"/>
        </transition>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div
        v-if="hasLoaded && hasCampaigns"
        id="campaign-table">
        <div class="table-mobile-container">
          <table class="table vertical-middle campaign-table">
            <thead>
              <tr>
                <!-- CAMPAIGN IMAGE -->
                <th class="image-group is-hidden-touch">&nbsp;</th>
                <!-- CAMPAIGN TITLE -->
                <th class="title-group">
                  <a
                    :class="{ 'down': currentSortColumn === 'campaign' && currentSortDirection === 'desc', 'up': currentSortColumn === 'campaign' && currentSortDirection === 'asc' }"
                    class="table-sort"
                    @click="toggleSort('campaign')">Campaign</a>
                  <div class="filter-in-header">
                    <FilterElement
                      v-model="filters.type"
                      by="type"
                      what="campaign"
                      :showIntroText="false"
                      :miniMode="true"
                      showOnlyId="typeId"
                      @change="onFilterChange('type')"/>
                  </div>
                </th>
                <!-- STATUS -->
                <th class="status-group">
                  <a
                    :class="{ 'down': currentSortColumn === 'sent' && currentSortDirection === 'desc', 'up': currentSortColumn === 'sent' && currentSortDirection === 'asc' }"
                    class="table-sort"
                    @click="toggleSort('sent')">Status</a>
                  <div class="filter-in-header">
                    <FilterElement
                      v-model="filters.status"
                      by="status"
                      what="campaign"
                      :showIntroText="false"
                      :miniMode="true"
                      showOnlyId="statusId"
                      @change="onFilterChange('status')"/>
                  </div>
                </th>
                <!-- CHANNELS -->
                <th class="channel-group">
                  <span>Channels</span>
                  <div class="filter-in-header only-filter">
                    <FilterElement
                      v-model="filters.channel"
                      by="channel"
                      what="campaign"
                      :showIntroText="false"
                      :miniMode="true"
                      showOnlyId="channelId"
                      @change="onFilterChange('channel')"/>
                  </div>
                </th>
                <!-- LAST MODIFIED -->
                <th class="last-edit-group">
                  <a
                    class="table-sort"
                    :class="{ 'down': currentSortColumn === 'edit' && currentSortDirection === 'desc', 'up': currentSortColumn === 'edit' && currentSortDirection === 'asc' }"
                    @click="toggleSort('edit')">Modified</a>
                    <div class="filter-in-header"></div>
                </th>
                <!-- CAMPAIGN ACTIONS -->
                <th class="action-group">
                  <a
                    class="togglearchived"
                    @click="toggleArchived()">
                    <span v-if="search.archive">
                      <span key="hide-icon" id="hide-icon" class="icon has-text-primary">
                        <i class="fas fa-eye-slash"/>
                      </span>
                      Hide Archived
                    </span>
                    <span v-else>
                      <span key="show-icon" id="show-icon" class="icon has-text-primary">
                        <i class="fas fa-eye"/>
                      </span>
                      Show Archived
                    </span>
                  </a>
                  <a
                    v-if="hasFilters"
                    class="resetfilters"
                    @click="resetFilters()">
                    <span>
                      <span key="show-icon" id="show-icon" class="icon has-text-primary">
                        <i class="fas fa-times"/>
                      </span>
                      Clear Filters
                    </span>
                  </a>
                </th>
              </tr>
            </thead>
            <transition name="fade" mode="out-in">
              <tbody v-if="campaigns.length > 0">
                <tr
                  v-for="(campaign, index) in campaigns"
                  :key="'camprow-'+index">
                  <!-- CAMPAIGN IMAGE -->
                  <td class="image-group is-hidden-touch">
                    <div
                      class="campaign-image"
                      @click="viewSummary(index)">
                      <img
                        v-if="campaign.postimage"
                        alt="Show campaign summary"
                        title="Show campaign summary"
                        :src="campaign.postimage"/>
                      <img
                        v-else
                        src="../../../assets/imgs/other/campaign-coming-soon.jpg"/>
                    </div>
                  </td>
                  <!-- CAMPAIGN TITLE -->
                  <td class="title-group">
                    <div>
                      <div class="campaign-title-text">{{ campaign.title }}</div>
                      <div class="campaign-type-text">{{ campaign.type }}</div>
                    </div>
                  </td>
                  <!-- STATUS -->
                  <td class="status-group">
                    <div class="campaign-status-tag">
                      <!-- DRAFT -->
                      <span
                        v-if="campaign.status === 0"
                        class="tag is-draft">Draft</span>
                      <!-- SCHEDULED -->
                      <span
                        v-if="campaign.status === 1"
                        class="tag is-scheduled">Scheduled</span>
                      <!-- DISABLED -->
                      <!-- PUBLISHED -->
                      <span
                        v-else-if="campaign.status === 2"
                        class="tag is-published">Published</span>
                      <span
                        v-if="campaign.archived === 1"
                        class="icon has-text-grey-dark" alt="Campaign archived" title="Campaign archived">
                        <i class="fas fa-archive"/>
                      </span>
                      <span
                        v-if="campaign['disable_actions'] === 1"
                        class="icon has-text-grey-dark" alt="Purchases and forms disabled" title="Purchases and forms disabled">
                        <i class="fas fa-store-alt-slash"/>
                      </span>
                    </div>
                    <div v-if="campaign.status === 1" class="status-date-text">{{ campaign.senddate }}<div>at {{ campaign.sendtime }}</div></div>
                    <div v-else-if="campaign.status === 2" class="status-date-text">{{ campaign.sentdate }}<div>at {{ campaign.senttime }} ET</div></div>
                  </td>
                  <!-- CHANNELS -->
                  <td class="channel-group">
                    <div class="channel-holder">
                      <span
                        v-for="(enabled, cindex) in generateChannelDisplay(campaign)"
                        :key="'camp'+index+'channel'+cindex">
                        <!-- facebook = 0 -->
                        <span v-if="enabled && cindex === 0">
                          <a
                            v-if="campaign.facebook_url"
                            :href="campaign.facebook_url"
                            class="channel-link"
                            target="_blank">
                            <span class="icon has-text-grey-dark" alt="Show Facebook post" title="Show Facebook post">
                              <i class="fab fa-facebook"/>
                            </span>
                          </a>
                          <span
                            v-else
                            class="icon has-text-grey-dark" alt="Facebook channel" title="Facebook channel">
                            <i class="fab fa-facebook"/>
                          </span>
                        </span>
                        <span
                          v-else-if="cindex === 0"
                          class="icon has-text-grey-lighter" alt="Facebook channel" title="Facebook channel">
                          <i class="fab fa-facebook"/>
                        </span>
                        <!-- twitter = 1
                        <span v-else-if="enabled && cindex === 1">
                          <a
                            v-if="campaign.twitter_url"
                            :href="campaign.twitter_url"
                            class="channel-link"
                            target="_blank">
                            <span class="icon has-text-grey-dark" alt="Show Twitter post" title="Show Twitter post">
                              <i class="fab fa-twitter"/>
                            </span>
                          </a>
                          <span
                            v-else
                            class="icon has-text-grey-dark" alt="Twitter channel" title="Twitter channel">
                            <i class="fab fa-twitter"/>
                          </span>
                        </span>
                        <span
                          v-else-if="cindex === 1"
                          class="icon has-text-grey-lighter" alt="Twitter channel" title="Twitter channel">
                          <i class="fab fa-twitter"/>
                        </span>
                        -->
                        <!-- google = 2 -->
                        <span v-else-if="enabled && cindex === 2">
                          <a
                            v-if="campaign.google_url"
                            :href="campaign.google_url"
                            class="channel-link"
                            target="_blank">
                            <span class="icon has-text-grey-dark" alt="Show Google My Business post" title="Show Google My Business post">
                              <i class="fab fa-google"/>
                            </span>
                          </a>
                          <span
                            v-else
                            class="icon has-text-grey-dark" alt="Google My Business channel" title="Google My Business channel">
                            <i class="fab fa-google"/>
                          </span>
                        </span>
                        <span
                          v-else-if="cindex === 2"
                          class="icon has-text-grey-lighter" alt="Google My Business channel" title="Google My Business channel">
                          <i class="fab fa-google"/>
                        </span>
                        <!-- instagram = 3 -->
                        <span v-else-if="enabled && cindex === 3">
                          <a
                            v-if="campaign.instagram_url"
                            :href="campaign.instagram_url"
                            class="channel-link"
                            target="_blank">
                            <span class="icon has-text-grey-dark" alt="Show Instagram post" title="Show Instagram post">
                              <i class="fab fa-instagram"/>
                            </span>
                          </a>
                          <span
                            v-else
                            class="icon has-text-grey-dark" alt="Instagram channel" title="Instagram channel">
                            <i class="fab fa-instagram"/>
                          </span>
                        </span>
                        <span
                          v-else-if="cindex === 3"
                          class="icon has-text-grey-lighter" alt="Instagram channel" title="Instagram channel">
                          <i class="fab fa-instagram"/>
                        </span>
                        <!-- website = 4 -->
                        <span v-else-if="enabled && cindex === 4">
                          <a
                            v-if="campaign.website_url"
                            :href="campaign.website_url"
                            class="channel-link"
                            target="_blank">
                            <span v-if="campaign.hide_from_feed === 1" class="icon has-text-grey-lighter" alt="Show Website post" title="Show Website post">
                              <i class="fas fa-globe"/>
                            </span>
                            <span v-else class="icon has-text-grey-dark" alt="Show Website post" title="Show Website post">
                              <i class="fas fa-globe"/>
                            </span>
                          </a>
                          <span v-else>
                            <span v-if="campaign.hide_from_feed === 1" class="icon has-text-grey-lighter" alt="Show Website post" title="Show Website post">
                              <i class="fas fa-globe"/>
                            </span>
                            <span v-else class="icon has-text-grey-dark" alt="Website channel" title="Website channel">
                              <i class="fas fa-globe"/>
                            </span>
                          </span>
                        </span>
                        <span
                          v-else-if="cindex === 4"
                          class="icon has-text-grey-lighter" alt="Website channel" title="Website channel">
                          <i class="fas fa-globe"/>
                        </span>
                        <!-- app = 5 -->
                        <span
                          v-else-if="enabled && cindex === 5"
                          class="icon has-text-grey-dark" alt="Mobile app channel" title="Mobile app channel">
                          <i class="fas fa-mobile"/>
                        </span>
                        <span
                          v-else-if="cindex === 5"
                          class="icon has-text-grey-lighter" alt="Mobile app channel" title="Mobile app channel">
                          <i class="fas fa-mobile"/>
                        </span>
                        <!-- email = 6 -->
                        <span v-else-if="enabled && cindex === 6">
                          <a
                            v-if="campaign.acy_id || campaign.cinch_emailId"
                            :href="generateEmailURL(campaign)"
                            class="channel-link"
                            target="_blank">
                            <span class="icon has-text-grey-dark" alt="Show email" title="Show email">
                              <i class="fas fa-envelope"/>
                            </span>
                          </a>
                          <span
                            v-else
                            class="icon has-text-grey-dark" alt="Email channel" title="Email channel">
                            <i class="fas fa-envelope"/>
                          </span>
                        </span>
                        <span
                          v-else-if="cindex === 6"
                          class="icon has-text-grey-lighter" alt="Email channel" title="Email channel">
                          <i class="fas fa-envelope"/>
                        </span>
                        <br v-if="cindex === 3"/>
                      </span>
                    </div>
                  </td>
                  <!-- LAST MODIFIED -->
                  <td class="last-edit-group">
                    <div class="last-edit-text" v-html="generateLastEditVerbiage(campaign)"/>
                    <div
                      v-if="campaign.editname"
                      class="status-last-edit-text">by {{ returnFirstName(campaign.editname) }}</div>
                  </td>
                  <!-- CAMPAIGN ACTIONS -->
                  <td class="action-group">
                    <div class="action-group-holder">
                      <a
                        v-if="campaign.status === 2"
                        key="campaign-stats-button"
                        class="campaign-edit-button"
                        alt="See stats for this campaign"
                        title="See stats for this campaign"
                        @click="setAction(index, 'stats')">
                        <span class="icon has-text-primary">
                          <i class="fas fa-chart-pie"/>
                        </span>
                        <div>Statistics</div>
                      </a>
                      <a
                        v-else
                        key="campaign-edit-button"
                        class="campaign-edit-button"
                        alt="Edit this campaign"
                        title="Edit this campaign"
                        @click="setAction(index, 'edit')">
                        <span class="icon has-text-primary">
                          <i class="fas fa-edit"/>
                        </span>
                        <div>Edit</div>
                      </a>
                      <div class="action-row">
                        <!-- ACTION MENU -->
                        <div class="control">
                          <div
                            :class="{ 'is-active': campaign.menus.actions }"
                            :id="'actionmenu' + index"
                            class="dropdown">
                            <div @click="toggleCampaignMenu('actions', index)" class="dropdown-click-helper" alt="Open campaign actions menu" title="Open campaign actions menu" />
                            <div class="dropdown-trigger">
                              <span class="icon has-text-primary">
                                <i class="fas fa-sliders-h"/>
                              </span>
                              <div>Actions</div>
                              <span class="icon has-text-primary">
                                <i class="fas fa-angle-down"/>
                              </span>
                            </div>
                            <div
                              :class="{'open-up' : menuOpenPosition === 'up', 'open-down' : menuOpenPosition === 'down'}"
                              class="dropdown-menu">
                              <div class="dropdown-content animated fadeIn">
                                <a
                                  v-if="campaign.status === 2"
                                  class="dropdown-item"
                                  @click="setAction(index, 'edit')">Edit Campaign</a>
                                <a
                                  class="dropdown-item"
                                  @click="setAction(index, 'copy')">Copy Campaign</a>
                                <hr v-if="(campaign.summary_key) || (campaign.postimage && campaign.slug) || (campaign.asset_key)" />
                                <a
                                  v-if="campaign.summary_key"
                                  class="dropdown-item"
                                  @click="setAction(index, 'summary')">Campaign Summary</a>
                                <a
                                  v-if="campaign.postimage && campaign.slug"
                                  class="dropdown-item"
                                  @click="setAction(index, 'website')">View Landing Page</a>
                                <a
                                  v-if="campaign.asset_key"
                                  class="dropdown-item"
                                  @click="setAction(index, 'assets')">Download Assets</a>
                                <hr/>
                                <a
                                  v-if="campaign.status === 2 && campaign['disable_actions'] === 1"
                                  class="dropdown-item"
                                  @click="setAction(index, 'enable')">
                                  Enable
                                  <span v-if="campaign.action === 'sale' && campaign['action_subtype'] === 'paid'">Purchases</span>
                                  <span v-else-if="campaign.action === 'event' && campaign['action_subtype'] === 'paid'">Ticket Sales</span>
                                  <span v-else>Forms</span>
                                </a>
                                <a
                                  v-else-if="campaign.status === 2 && campaign['disable_actions'] !== 1"
                                  class="dropdown-item"
                                  @click="setAction(index, 'disable')">
                                  Disable
                                  <span v-if="campaign.action === 'sale' && campaign['action_subtype'] === 'paid'">Purchases</span>
                                  <span v-else-if="campaign.action === 'event' && campaign['action_subtype'] === 'paid'">Ticket Sales</span>
                                  <span v-else>Forms</span>
                                </a>
                                <span v-if="campaign.channels">
                                  <a
                                    v-if="campaign.channels.website && campaign.status === 2 && campaign['hide_from_feed'] === 0"
                                    class="dropdown-item"
                                    @click="setAction(index, 'removefeed')">Remove From Widget</a>
                                  <a
                                    v-else-if="campaign.channels.website && campaign.status === 2 && campaign['hide_from_feed'] === 1"
                                    class="dropdown-item"
                                    @click="setAction(index, 'repostfeed')">Repost To Widget</a>
                                </span>
                                <a
                                  v-if="campaign.status === 0 || campaign.status === 1"
                                  class="dropdown-item"
                                  @click="setAction(index, 'delete')">Delete Campaign</a>
                                <a
                                  v-else-if="campaign.status === 2 && campaign.archived === 0"
                                  class="dropdown-item"
                                  @click="setAction(index, 'archive')">Archive Campaign</a>
                                <a
                                  v-else-if="campaign.status === 2 && campaign.archived === 1"
                                  class="dropdown-item"
                                  @click="setAction(index, 'unarchive')">Unarchive Campaign</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </transition>
          </table>
          <p v-if="hasFilters && campaigns.length === 0">
            There are no campaigns matching your search or filters.
            Try <a @click="resetFilters()">clearing your filters</a> or change your search.
          </p>
        </div>
        <div
          v-if="totalPages > 0"
          class="pages">
          <Pages
            :totalPages="totalPages"
            :currentPage="currentPage"
            :itemsPerPage="5"
            @change="gotoPage"/>
        </div>

      </div>
      <div v-else-if="hasLoaded"><!-- no campaigns created yet --></div>
    </transition>
    <Modal v-model="contentModal"/>
    <Modal v-model="pageModal"/>
    <Confirm :data="deleteConfirm"/>
    <Confirm :data="disableConfirm"/>
    <Confirm :data="archiveConfirm"/>
  </div>
</template>

<script>
import engineApi from '@/plugins/engine-api.js'
import { mapGetters } from 'vuex'
import xhr from '@/mixins/Xhr.js'
import Modal from '@/components/elements/Modal'
import Confirm from '@/components/elements/Confirm'
import Pages from '@/components/elements/Pages'
import FilterElement from '@/components/elements/Filter'
import TextInput from '@/components/elements/TextInput'

export default {
  components: {
    Modal,
    Confirm,
    Pages,
    FilterElement,
    TextInput
  },
  mixins: [xhr],
  data () {
    return {
      hasCampaigns: false,
      hasLoaded: false,
      totalPages: null,
      currentPage: 1,
      campaigns: [],
      contentModal: {
        type: 'image',
        modalOpen: false,
        image: null
      },
      pageModal: {
        type: 'iframe',
        modalOpen: false,
        src: null
      },
      deleteConfirm: {
        show: false,
        result: false,
        question: 'Are you sure you want to delete this campaign?',
        text: 'This action is final and cannot be reversed.',
        button: 'Yes, Delete'
      },
      disableConfirm: {
        show: false,
        result: false,
        question: 'Are you sure you want to disable this campaign?',
        text: 'This will prevent future submissions or purchases from the campaign website.',
        button: 'Yes, Disable'
      },
      archiveConfirm: {
        show: false,
        result: false,
        question: 'Are you sure you want to archive this campaign?',
        text: 'This will disable future submissions or purchases from the campaign website and hide this campaign in the dashboard.',
        button: 'Yes, Archive'
      },
      search: {
        sort: 'sent:desc',
        name: null,
        status: 'all',
        type: 'all',
        channel: 'all',
        archive: false
      },
      searchInput: {
        name: 'searchcampaign',
        ref: 'searchcampaign',
        model: null,
        placeholder: 'Find a campaign',
        icon: 'fas fa-search',
        fieldClass: 'oto-input-field-text-medium',
        buttonText: 'Search'
      },
      filters: {
        // the "[status/type/channel]Ids" here are irrelevant and are only used to
        // play nice with the filter component. we need them, but the ids just need
        // to be different in each array set
        status: [
          { status: 'Draft', statusId: 1, checked: true },
          { status: 'Scheduled', statusId: 2, checked: true },
          { status: 'Published', statusId: 3, checked: true }
        ],
        type: [
          { type: 'Sale', typeId: 1, checked: true },
          { type: 'Event', typeId: 2, checked: true },
          { type: 'Tee Times', typeId: 3, checked: true },
          { type: 'Info', typeId: 4, checked: true },
          { type: 'Other', typeId: 5, checked: true }
        ],
        channel: [
          { channel: 'Facebook', channelId: 1, checked: true },
          { channel: 'Google', channelId: 3, checked: true },
          { channel: 'Instagram', channelId: 4, checked: true },
          { channel: 'Website', channelId: 5, checked: true },
          { channel: 'App', channelId: 6, checked: true },
          { channel: 'Email', channelId: 7, checked: true }
        ]
      },
      iframeDomain: process.env.VUE_APP_LANDING_PAGE_IFRAME_DOMAIN,
      menuOpenPosition: 'down'
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentAccountChannels: 'currentAccountChannels'
    }),
    accountEmailSettings: function () {
      var email = {
        provider: 'none configured',
        url: null
      }
      if (this.currentAccountChannels) {
        if (this.currentAccountChannels.length > 0) {
          this.currentAccountChannels.forEach(function (channel) {
            if (channel) {
              if (channel.type === 'email') {
                if (channel.id === 'acy') {
                  email.provider = '1-2-1 Marketing'
                  email.url = channel.name + '/index.php?option=com_campaignpilot&type=newsletter'
                } else if (channel.id === 'cinch') {
                  email.provider = 'Cinch'
                  email.url = 'https://marketing.cinch.io/'
                  var options = JSON.parse(channel.options)
                  email.integrationMode = options.integrationMode
                }
              }
            }
          })
        }
      }
      return email
    },
    currentSortColumn: function () {
      var currentSort = (this.search.sort) ? this.search.sort.split(':') : null
      if (currentSort) if (currentSort.length >= 0) return currentSort[0]
      return null
    },
    currentSortDirection: function () {
      var currentSort = (this.search.sort) ? this.search.sort.split(':') : null
      if (currentSort) if (currentSort.length >= 1) return currentSort[1]
      return null
    },
    hasFilters: function () {
      if (this.search.name || this.search.status !== 'all' || this.search.type !== 'all' || this.search.channel !== 'all') return true
      return false
    }
  },
  watch: {
    'deleteConfirm.result' (val) {
      // if we get a success result from the delete, then we need to refresh campaigns
      if (val) {
        this.fetchCampaigns()
        this.deleteConfirm.result = false
      }
    },
    'disableConfirm.result' (val) {
      // if we get a success result from the disable, then we need to refresh campaigns
      if (val) {
        this.fetchCampaigns()
        this.disableConfirm.result = false
      }
    },
    'archiveConfirm.result' (val) {
      // if we get a success result from the archive, then we need to refresh campaigns
      if (val) {
        this.fetchCampaigns()
        this.archiveConfirm.result = false
      }
    }
  },
  mounted () {
    if (this.$route.params.camp) {
      // these forces the view to scheduled campaigns. we do this when the user comes from the home page upcoming widget
      // which passes through a camp id
      this.search.status = ['Scheduled']
      this.filters.status.forEach(status => {
        if (status.status === 'Scheduled') {
          status.checked = true
        } else {
          status.checked = false
        }
        return true
      })
      this.onFilterChange('status')
    } else {
      this.fetchCampaigns()
    }
  },
  created () {
    document.addEventListener('click', this.closeCampaignMenus)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.closeCampaignMenus, true)
  },
  methods: {
    closeCampaignMenus (e) {
      var close = true
      if (e) {
        if (e.target) {
          if (e.target.className) {
            if (typeof e.target.className === 'string') {
              if (e.target.className.includes('dropdown-click-helper')) {
                close = false
              }
            }
          }
        }
      }
      if (close) {
        this.campaigns.forEach(function (campaign) {
          campaign.menus.file = false
          campaign.menus.actions = false
          campaign.menus.stats = false
        })
      }
    },
    setAction (campaignIndex, action) {
      this.campaigns[campaignIndex].menuAction = action
      this.runAction(campaignIndex, action)
    },
    runAction (campaignIndex, action) {
      if (action === 'stats') {
        this.statsCamp(campaignIndex)
      } else if (action === 'website') {
        this.openIframeModal(this.iframeDomain + '/' + this.campaigns[campaignIndex].slug + '?skiphit=1')
      } else if (action === 'copy') {
        this.copyCamp(campaignIndex)
      } else if (action === 'edit') {
        this.editCamp(campaignIndex)
      } else if (action === 'delete') {
        this.deleteCamp(campaignIndex)
      } else if (action === 'assets') {
        this.downloadAssets(campaignIndex)
      } else if (action === 'summary') {
        this.viewSummary(campaignIndex)
      } else if (action === 'disable') {
        this.disableCamp(campaignIndex)
      } else if (action === 'enable') {
        this.enableCamp(campaignIndex)
      } else if (action === 'removefeed') {
        this.toggleFeed(campaignIndex, 'remove')
      } else if (action === 'repostfeed') {
        this.toggleFeed(campaignIndex, 'repost')
      } else if (action === 'archive') {
        this.archiveCamp(campaignIndex)
      } else if (action === 'unarchive') {
        this.unarchiveCamp(campaignIndex)
      }
    },
    fetchCampaigns () {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchCampaigns(
        vm.currentAccount.id, null, vm.currentPage, vm.search,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          vm.hasLoaded = true
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (response.results) {
              response.results.forEach(function (campaign) {
                campaign.channels = JSON.parse(campaign.channels)
                campaign.menus = {
                  file: false,
                  stats: false,
                  actions: false
                }
                campaign.menu = false
                if (campaign.status === 2) {
                  campaign.menuAction = 'stats'
                } else if (campaign.status === 1) {
                  campaign.menuAction = 'summary'
                } else {
                  campaign.menuAction = 'edit'
                }
              })
              vm.totalPages = response.pages
              vm.campaigns = response.results
              if (response.results.length > 0) vm.hasCampaigns = true
            } else {
              vm.totalPages = 0
              vm.campaigns = []
            }
          }
        }
      )
    },
    fetchCampaign (id) {
      this.$store.dispatch('setLoadingState', true)
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      var vm = this
      engineApi.fetchCampaign(
        vm.currentAccount.id, id,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.$store.dispatch('injectWizard', JSON.parse(response))
            vm.$router.push({ name: 'route-engine-campaigns-wizard-campaign-wizard' })
          }
        }
      )
    },
    editCamp (index) {
      var campId = this.campaigns[index].id
      // reset the current wizard
      this.$store.dispatch('resetWizardState')
      // set the editId
      this.$store.dispatch('saveEditId', campId)
      // set the start mode to edit (we use this for the back button nav in the wizard)
      this.$store.dispatch('setWizardStartMode', 'edit')
      // set the edit mode if needed
      if (this.campaigns[index].status === 2) {
        this.$store.dispatch('setEditPublishedMode')
      }
      // fetch the campaign
      this.fetchCampaign(campId)
    },
    copyCamp (index) {
      var campId = this.campaigns[index].id
      // reset the current wizard
      this.$store.dispatch('resetWizardState')
      // fetch the campaign
      this.fetchCampaign(campId)
    },
    deleteCamp (index) {
      this.deleteConfirm.show = true
      this.deleteConfirm.action = 'delete'
      this.deleteConfirm.id = this.campaigns[index].id
    },
    disableCamp (index) {
      this.disableConfirm.show = true
      this.disableConfirm.action = 'disable'
      this.disableConfirm.id = this.campaigns[index].id
    },
    enableCamp (index) {
      var enableId = this.campaigns[index].id
      this.$store.dispatch('setLoadingState', true)
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      var vm = this
      engineApi.toggleCampaign(vm.currentAccount.id, enableId, 'actions',
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          }
          vm.$store.dispatch('setNotify', { state: true, text: 'Your marketing campaign has been enabled.' })
          vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
          vm.fetchCampaigns()
        }
      )
    },
    statsCamp (index) {
      var campId = this.campaigns[index].id
      this.$store.dispatch('resetPerformanceState') // reset any stored performance filter and date settings, we want the default
      this.$router.push({ name: 'route-engine-performance-home-w-id', params: { campaignId: campId } })
    },
    archiveCamp (index) {
      this.archiveConfirm.show = true
      this.archiveConfirm.action = 'archive'
      this.archiveConfirm.id = this.campaigns[index].id
    },
    unarchiveCamp (index) {
      var campId = this.campaigns[index].id
      this.$store.dispatch('setLoadingState', true)
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      var vm = this
      engineApi.toggleCampaign(vm.currentAccount.id, campId, 'archive',
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          }
          vm.$store.dispatch('setNotify', { state: true, text: 'Your marketing campaign has been unarchived.' })
          vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
          vm.fetchCampaigns()
        }
      )
    },
    downloadAssets (index) {
      var key = this.campaigns[index].asset_key
      if (key) {
        var assetUrl = process.env.VUE_APP_CONTENT_URL + key
        window.location.href = assetUrl
      }
    },
    viewSummary (index) {
      if (this.campaigns[index]) {
        var key = this.campaigns[index].summary_key
        if (key) {
          var summaryUrl = process.env.VUE_APP_CONTENT_URL + key
          this.openIframeModal(summaryUrl, { hideViewToggle: true, copyConfirmText: 'Your campaign summary URL has been copied to your clipboard.' })
        }
      }
    },
    openContentModal (image) {
      document.getElementsByTagName('html')[0].classList.add('model-is-open')
      this.contentModal.modalOpen = true
      this.contentModal.image = image
    },
    openIframeModal (src, opts) {
      document.getElementsByTagName('html')[0].classList.add('model-is-open')
      this.pageModal.modalOpen = true
      this.pageModal.src = src
      if (opts) {
        if (opts.hideViewToggle) this.pageModal.hideViewToggle = opts.hideViewToggle
        if (opts.copyConfirmText) this.pageModal.copyConfirmText = opts.copyConfirmText
      } else {
        this.pageModal.hideViewToggle = null
        this.pageModal.copyConfirmText = null
      }
    },
    gotoPage (page) {
      if (page < 1) page = 1
      if (page > this.totalPages) page = this.totalPages
      if (page !== this.currentPage) {
        this.currentPage = page
        this.fetchCampaigns()
      }
    },
    resetFilters () {
      this.filters.status.forEach(status => { status.checked = true; return true })
      this.filters.type.forEach(type => { type.checked = true; return true })
      this.filters.channel.forEach(channel => { channel.checked = true; return true })
      this.search.name = null
      this.searchInput.model = null
      this.search.status = 'all'
      this.search.type = 'all'
      this.search.channel = 'all'
      this.currentPage = 1
      this.fetchCampaigns()
    },
    toggleCampaignMenu (menu, index) {
      if (!this.campaigns[index].menus[menu]) {
        this.closeCampaignMenus() // if we're opening a menu, close all of them just in case
        // determine to open menu up or down based on window height
        const windowHeight = document.documentElement.clientHeight || window.innerHeight // returns total height of the browser window
        const windowWidth = document.documentElement.clientWidth || window.innerWidth // returns total width of the browser window
        const menuElement = document.getElementById('actionmenu' + index) // get the element the user just clicked on
        const menuPos = (menuElement) ? menuElement.getBoundingClientRect() : {} // get the position of that element
        var spaceLeft = null
        if (windowWidth < 660) {
          const tableElement = document.getElementById('campaign-table') // in mobile we need to use the bottom of the table, not the window
          const tablePos = (tableElement) ? tableElement.getBoundingClientRect() : {} // get the position of that table
          spaceLeft = (tablePos.bottom && menuPos.bottom) ? (tablePos.bottom - menuPos.bottom) : null // find the difference between the table pos and element pos
        } else {
          spaceLeft = (windowHeight && menuPos.bottom) ? (windowHeight - menuPos.bottom) : null // find the difference between the window height and element pos
        }
        if (spaceLeft && spaceLeft < 225) this.menuOpenPosition = 'up' // if we only have this many pixels left, open the menu up
        else this.menuOpenPosition = 'down'
      }
      this.campaigns[index].menus[menu] = !this.campaigns[index].menus[menu] // toggle the menu
    },
    toggleFeed (index, type) {
      var toggleId = this.campaigns[index].id
      this.$store.dispatch('setLoadingState', true)
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      var vm = this
      engineApi.toggleFeed(
        vm.currentAccount.id,
        toggleId,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          }
          var t = 'Your marketing campaign has been '
          if (type === 'repost') t = t + 'reposted to your website widget.'
          else t = t + 'removed from your website widget.'
          vm.$store.dispatch('setNotify', { state: true, text: t })
          vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
          vm.fetchCampaigns()
        }
      )
    },
    toggleArchived () {
      this.search.archive = !this.search.archive
      if (!this.search.archive) this.currentPage = 1 // if we're hiding archived, we're reducing the total campaigns. return to page one so we dont get stuck on a high page that no longer exists
      this.fetchCampaigns()
    },
    searchCampaigns () {
      this.currentPage = 1 // change the page to "1" when we adjust the search
      this.search.name = this.searchInput.model
      this.fetchCampaigns()
    },
    onFilterChange (filter) {
      var totalFilters = this.filters[filter].length
      var totalChecked = 0
      this.search[filter] = []
      var vm = this
      this.filters[filter].forEach(function (status) {
        if (status.checked) {
          totalChecked++
          vm.search[filter].push(status[filter])
        }
      })
      if (totalChecked === totalFilters) {
        this.search[filter] = 'all'
      }
      if (totalChecked === 0) {
        // if we got here that means the user unchecked all filters
        // for now, we'll do nothing
      } else {
        // only fetch if we have at least one toggled!
        this.currentPage = 1 // change the page to "1" when we adjust the filter
        this.fetchCampaigns() // refresh the campaigns
      }
    },
    toggleSort (column) {
      var sort = column + ':'
      if (this.currentSortColumn === column) {
        if (this.currentSortDirection === 'desc') sort += 'asc'
        else sort += 'desc'
      } else {
        if (column === 'campaign') sort += 'asc' // asc is the default for the campaign column
        else sort += 'desc' // desc is the default for all other columns
      }
      this.search.sort = sort
      this.fetchCampaigns() // refresh the campaigns
    },
    generateEmailURL (campaign = {}) {
      if (campaign.acy_version === 6) {
        if (campaign.acy_campaign_id) {
          return this.accountEmailSettings.url + '&id=' + campaign.acy_campaign_id + '&v=' + campaign.acy_version
        } else {
          return this.accountEmailSettings.url
        }
      } else if (campaign.acy_id) {
        return this.accountEmailSettings.url + '&id=' + campaign.acy_id + '&v=' + campaign.acy_version
      } else if (campaign.cinch_broadcastId) {
        return this.accountEmailSettings.url + 'broadcasts'
      } else if (campaign.cinch_emailId) {
        return this.accountEmailSettings.url + 'templates/edit/email/' + campaign.cinch_emailId
      }
      return null
    },
    generateLastEditVerbiage (campaign = {}) {
      var string = ''
      var secs = -1
      var days = -1
      if (typeof campaign.editseconds === 'number') secs = campaign.editseconds
      if (typeof campaign.editdays === 'number') days = campaign.editdays
      if (days === 0 && secs > 0) {
        // phrases for edits made today
        if (secs > 0 && secs <= 600) string = 'A few moments ago'
        else if (secs > 600 && secs <= 3600) string = 'Within the past hour'
        else if (secs > 3600 && secs <= 7200) string = 'An hour ago'
        else if (secs > 7200 && secs <= 10800) string = 'Two hours ago'
        else string = 'Earlier today'
      } else if (days === 1) string = 'Yesterday'
      else if (days > 1 && days < 6 && campaign.editdayname) string = campaign.editdayname
      else string = campaign.editdate
      // if (campaign.edittime) string += ' at ' + campaign.edittime
      return string
    },
    generateChannelDisplay (campaign = {}) {
      var chs = [ false, false, false, false, false, false, false ]
      if (campaign.channels && typeof campaign.channels === 'object') {
        if ('facebook' in campaign.channels && campaign.channels.facebook) chs[0] = true
        if ('twitter' in campaign.channels && campaign.channels.twitter) chs[1] = true
        if ('google' in campaign.channels && campaign.channels.google) chs[2] = true
        if ('instagram' in campaign.channels && campaign.channels.instagram) chs[3] = true
        if ('website' in campaign.channels && campaign.channels.website) chs[4] = true
        if ('app' in campaign.channels && campaign.channels.app) chs[5] = true
        if ('email' in campaign.channels && campaign.channels.email) chs[6] = true
      }
      return chs
    },
    returnFirstName (name) {
      var n
      if (name) {
        if (typeof name === 'string') {
          n = name.trim().split(' ')[0]
        }
      }
      return n
    }
  }
}
</script>

<style lang="scss" scoped>
.has-background-danger-light {
 background-color:#ffebf0;
 border-color: #dbdbdb;
}
.has-background-danger-light:hover {
 background-color:#f7e2e7;
 border-color: #dbdbdb;
}
.search-bar {
  margin-top: 12px!important;
  margin-bottom: 0!important;
}
.search-campaigns-input {
  float: right;
}
.campaign-table {
  width: 98%;
}
.campaign-table tbody tr:hover {background-color: $white-bis;}
.table td {
  padding-top: 12px!important;
  padding-bottom: 12px!important;
}
.table-container {
  min-height: 300px;
}
table > thead > tr > th {
  white-space: nowrap;
  vertical-align: bottom;
}
table.vertical-middle > tbody > tr > td {
  vertical-align: middle;
}
th a.table-sort {
  padding-right: 18px;
  position: relative;
  color: #363636;
}
th a.table-sort:hover {
  color: $grey-darker;
}
a.table-sort:before,
a.table-sort:after {
  border: 8px solid transparent;
  content: "";
  display: block;
  height: 0;
  right: -6px;
  top: 50%;
  position: absolute;
  width: 0;
}
a.table-sort:before {
  border-bottom-color: #b5b5b5;
  margin-top: -18px;
}
a.table-sort:after {
  border-top-color: #b5b5b5;
  margin-top: 1px;
}
a.table-sort.up:before {
  border-bottom-color: $primary;
}
a.table-sort.down:after {
  border-top-color: $primary;
}
table > thead > tr > th.image-group,
table > tbody > tr > td.image-group {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}
table > tbody > tr > td.image-group {
  padding-bottom: 5px!important;
}
table > thead > tr > th.title-group,
table > tbody > tr > td.title-group {
  width: 280px;
  min-width: 160px;
}
table > thead > tr > th.status-group,
table > tbody > tr > td.status-group {
  width: 170px;
  max-width: 170px;
  min-width: 170px;
}
table > thead > tr > th.channel-group,
table > tbody > tr > td.channel-group {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}
table > thead > tr > th.last-edit-group,
table > tbody > tr > td.last-edit-group {
  width: 130px;
  max-width: 130px;
  min-width: 130px;
}
table > thead > tr > th.action-group,
table > tbody > tr > td.action-group {
  width: 130px;
  max-width: 130px;
  min-width: 130px;
}
.campaign-image > img {
  cursor: hand;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  box-shadow: 0 2px 3px rgba(0, 0, 4, 0.1);
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 4, 0.1);
}
.campaign-image > img:hover {
  box-shadow: 0 2px -1px rgba(0, 0, 4, 0.2);
  -webkit-box-shadow: 0 2px -1px rgba(0, 0, 4, 0.2);
  transform: translate(0px, -1px);
}
.filter-in-header {
  margin-left: 15px;
  display: inline;
}
.filter-in-header.only-filter {
  margin-left: 6px;
  display: inline;
}
.campaign-title-text {
  font-size: 1em;
  line-height: 1.5em;
  max-height: 3em;
  overflow: hidden;
}
.campaign-type-text {
  font-size: 0.9em;
  text-transform: capitalize;
  color: $grey;
}
.tag {
  min-width: 80px;
  font-size: 0.75em;
}
.status-date-text {
  font-size: 0.9em;
  line-height: 1.5em;
  max-height: 3em;
  overflow: hidden;
  margin-top: 5px;
}
.status-date-text > div {
  color: $grey;
}
.status-last-edit-text {
  font-size: 0.9em;
  color: $grey;
}
.campaign-status-tag .icon {
  font-size: 0.8em;
}
.channel-group .icon {
  margin: 5px 4px;
}
.channel-holder {
  margin-left: -8px;
}
.last-edit-text {
  font-size: 0.9em;
  line-height: 1.5em;
  max-height: 3em;
  overflow: hidden;
}
.action-row {
  display: flex;
  margin-top: 4px;
}
.action-row > .control:not(:last-child) {
  margin-right: 10px;
}
.action-group > .togglearchived .icon {
  margin-right: -2px;
}
.control > .dropdown > .dropdown-trigger {
  white-space: nowrap;
  display: flex;
  line-height: 23px;
}
.dropdown > .dropdown-click-helper {
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 20px;
  cursor: hand; cursor: pointer;
}
.campaign-edit-button {
  display: flex;
  margin-bottom: 10px;
}
.campaign-edit-button > div {
  margin-left: 2px;
  line-height: 23px;
}
.dropdown > .dropdown-click-helper:hover ~ .dropdown-trigger > div,
.dropdown > .dropdown-click-helper:hover ~ .dropdown-trigger > span,
.campaign-edit-button:hover > div,
.campaign-edit-button:hover > span,
.resetfilters > span:hover,
.togglearchived > span:hover,
.channel-link:hover > .icon {
  color: #00A964!important;
}
.channel-link > span {
  border-bottom: 1px #4a4a4a dotted;
}
.campaign-edit-button > div,
.dropdown > .dropdown-trigger > div {
  color: $primary;
  font-size: 0.9em !important;
}
.dropdown > .dropdown-trigger > div {
  margin-left: 2px;
}
.dropdown > .dropdown-menu {
  min-width: 0!important;
}
.dropdown > .dropdown-menu.open-up {
  top: unset;
  bottom: 100%;
  padding-bottom: 4px;
  padding-top: 0px;
}
.dropdown-content > .dropdown-item {
  padding-right:1rem!important;
}
.dropdown-content > hr {
  margin: 0;
}
.pages {
  margin-top: 25px;
}
.tiny {
  height: 15px;
  padding: 10px 15px!important;
}
.resetfilters > span {
  line-height: 12pt;
  font-size: 0.8em;
  color: $primary;
  font-weight: 400;
  display: block;
}
.togglearchived > span {
  line-height: 12pt;
  font-size: 0.8em;
  color: $primary;
  font-weight: 400;
}
@media only screen and (min-width : 0px) and (max-width : $mobile-max) {
  .search-campaigns-input {
    float: none;
  }
  .table-mobile-container {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    overflow-y: hidden;
    max-width: 100%;
    min-height: 350px;
  }
}
@media only screen and (min-width : 0px) and (max-width : 1100px) {
  .dropdown > .dropdown-menu {
    left: -115px;
    max-width: 220px;
    width: 220px;
    min-width: 220px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
</style>

<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title">
            Performance
          </h1>
          <h2 class="subtitle">
            Track the performance of your campaigns.
          </h2>
        </div>
      </div>
      <!-- Info Bar -->
      <nav class="level has-background-white-ter">
        <div class="level-left">
          <div class="level-item"></div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <Select
              :props="goBackThisManyDays"
              :control="{ 'style': { 'margin-top': '2px' } }"
              v-model="goBackThisManyDays.model"></Select>
            <div v-if="showDateRange" class="date-picker">
              <Calendar
                :props="customDateRange"
                v-model="customDateRange.model"/>
            </div>
          </div>
        </div>
      </nav>
      <div class="dropdown">
        <div class="field">
          <label class="label"><span>Filter Statistics By Campaign</span></label>
          <FilterElement
            v-model="campaigns"
            by="campaign"
            what="statistic"
            :show="9999"
            :showIcon="true"
            :showIntroText="false"
            :scrollMode="true"
            :sortMode="true"
            :includeSearch="true"
            :showOnlyId="showOnlyId"
            :showOnlyValue="showOnlyValue"
            @change="onCampaignFilterChange()"></FilterElement>
        </div>
      </div>
      <div v-if="totalCampaignsChecked > 0">
        <!-- METRICS -->
        <Stats
          widget="metrics"
          key="metrics"
          :showTitle="false"
          :showSubtitle="false"
          :showTip="false"
          :hideMetricFooter="true"
          :onlyShowMetrics="['impressions', 'clicks', 'submissions', 'conversions', 'revenue']"
          :daysBack="dateForStat"
          :search="search"
          :selectedMetric="selectedMetric"
          @click="onMetricClick($event)"></Stats>
        <!-- CONVERSIONS && REVENUE -->
        <!-- order timeline & channel chart -->
        <transition name="fade" mode="out-in">
          <div v-if="selectedMetric === 'conversions'" class="columns">
            <div class="column is-half">
              <Stats
                key="timeline"
                widget="orderchart"
                :showTitle="false"
                :showSubtitle="false"
                :showTip="false"
                :daysBack="dateForStat"
                :search="search"></Stats>
            </div>
            <div class="column is-half channel-column">
              <Stats
                key="channelgroup"
                widget="orderchannel"
                :showTitle="false"
                :showSubtitle="true"
                :showTip="false"
                :daysBack="dateForStat"
                :search="search"></Stats>
            </div>
          </div>
        </transition>
         <!-- revenue timeline -->
        <transition name="fade" mode="out-in">
          <div v-if="selectedMetric === 'revenue'" class="columns">
            <div class="column is-half">
              <Stats
                key="timeline"
                widget="orderrevenuetimeline"
                :showTitle="false"
                :showSubtitle="false"
                :showTip="false"
                :daysBack="dateForStat"
                :search="search"></Stats>
            </div>
            <div class="column is-half channel-column">
              <Stats
                key="channelgroup"
                widget="revenuechannel"
                :showTitle="false"
                :showSubtitle="true"
                :showTip="false"
                :daysBack="dateForStat"
                :search="search"></Stats>
            </div>
          </div>
        </transition>
        <!-- order table -->
        <transition name="fade" mode="out-in">
          <div
            v-if="selectedMetric === 'conversions' || selectedMetric === 'revenue'"
            class="metric-table">
            <MetricTable
              key="order-table"
              metric="orders"
              :metricClickMode="true"
              :daysBack="dateForStat"
              :search="search"
              :columnsToShow="['short_date', 'campaign', 'confirmation', 'total', 'hits_referralDomain']"
              :columnLabels="['Date', '', '', '', 'Channel']"
              @click="gotoOrders()"></MetricTable>
          </div>
        </transition>
        <!-- SUBMISSIONS -->
        <!-- submission timeline & channel chart -->
        <transition name="fade" mode="out-in">
          <div v-if="selectedMetric === 'submissions'" class="columns">
            <div class="column is-half">
              <Stats
                widget="formchart"
                key="submissionstimeline"
                :showTitle="false"
                :showSubtitle="false"
                :showTip="false"
                :daysBack="dateForStat"
                :search="search"></Stats>
            </div>
            <div class="column is-half channel-column">
              <Stats
                key="channelgroup"
                widget="submissionchannel"
                :showTitle="false"
                :showSubtitle="true"
                :showTip="false"
                :daysBack="dateForStat"
                :search="search"></Stats>
            </div>
          </div>
        </transition>
        <!-- submission table -->
        <transition name="fade" mode="out-in">
          <div
            v-if="selectedMetric === 'submissions'"
            class="metric-table">
            <MetricTable
              key="submissions-table"
              metric="submissions"
              :metricClickMode="true"
              :daysBack="dateForStat"
              :search="search"
              :columnsToShow="['short_date', 'campaign']"
              :columnLabels="['Date', '', ]"
              @click="gotoSubmissions($event)"></MetricTable>
          </div>
        </transition>
        <!-- CLICKS -->
        <!-- clicks timeline -->
        <transition name="fade" mode="out-in">
          <Stats
            v-if="selectedMetric === 'clicks'"
            widget="clicktimeline"
            key="clicktimeline"
            :showTitle="false"
            :showSubtitle="false"
            :showTip="false"
            :daysBack="dateForStat"
            :search="search"></Stats>
        </transition>
        <!-- clicks table -->
        <transition name="fade" mode="out-in">
          <div
            v-if="selectedMetric === 'clicks'"
            class="metric-table">
            <MetricTable
              key="click-table"
              metric="clicks"
              :daysBack="dateForStat"
              :search="search"
              :columnsToShow="['short_date', 'campaign', 'cleanReferral', 'cleanMedium']"
              :columnLabels="['Date', '', 'Referral', 'Medium']"></MetricTable>
          </div>
        </transition>
        <!-- IMPRESSIONS -->
        <!-- impressions stacked bar -->
        <transition name="fade" mode="out-in">
          <Stats
            v-if="selectedMetric === 'impressions'"
            widget="channelimpressions"
            key="channelimpressions"
            :width="300"
            :showTitle="false"
            :showSubtitle="false"
            :showTip="false"
            :daysBack="dateForStat"
            :search="search"></Stats>
        </transition>
        <!-- impressions table -->
        <transition name="fade" mode="out-in">
          <div
            v-if="selectedMetric === 'impressions'"
            class="metric-table">
            <MetricTable
              key="impression-table"
              metric="impressions"
              :daysBack="dateForStat"
              :search="search"
              :columnsToShow="['short_date', 'title', 'widget_impressions', 'email_impressions', 'gallus_impressions', 'facebook_impressions', 'google_impressions', 'instagram_impressions']"
              :columnLabels="['Date Sent', 'Campaign', 'Widget', 'Email', 'App', 'Facebook', 'Google', 'Instagram']"></MetricTable>
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import MetricTable from '@/components/dashboard/widgets/MetricTable'
import Stats from '@/components/dashboard/widgets/Stats'
import Select from '@/components/elements/Select'
import Calendar from '@/components/elements/Calendar'
import FilterElement from '@/components/elements/Filter'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import { mapGetters } from 'vuex'

export default {
  components: {
    Calendar,
    FilterElement,
    MetricTable,
    Select,
    Stats
  },
  mixins: [xhr],
  data () {
    return {
      dateForStat: null,
      selectedMetric: null,
      showDateRange: false,
      search: {
        range: null,
        campaigns: 'all'
      },
      customDateRange: {
        model: null,
        subtype: 'range',
        reportmode: true,
        name: 'daterange',
        ref: 'daterange',
        label: '',
        hideIcons: true,
        placeholder: 'Start Date',
        endPlaceholder: 'End Date',
        fieldStyle: 'max-width: 275px;',
        fieldClass: 'no-margin'
      },
      goBackThisManyDays: {
        model: 90,
        name: 'goBackThisManyDays',
        ref: 'goBackThisManyDays',
        options: [{ 'name': 'Last Week', 'value': 7 }, { 'name': 'Last Month', 'value': 30 }, { 'name': 'Last Quarter', 'value': 90 }, { 'name': 'Last Year', 'value': 365 }, { 'name': 'Custom Range', 'value': 'range' }],
        icon: 'fas fa-calendar-alt',
        fieldClass: 'no-margin'
      },
      loadedFilter: false,
      campaigns: [],
      showOnlyValue: null,
      showOnlyId: 'campaign_id'
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentLoading: 'currentLoading',
      currentPerformanceFilter: 'currentPerformanceFilter',
      currentPerformanceDaysBack: 'currentPerformanceDaysBack',
      currentPerformanceDateRange: 'currentPerformanceDateRange'
    }),
    totalCampaignsChecked: function () {
      var totalChecked = 0
      this.campaigns.forEach(function (campaign) { if (campaign.checked) totalChecked++ })
      return totalChecked
    }
  },
  watch: {
    'goBackThisManyDays.model' (val) { // watch the date select box
      if (val) {
        this.$store.dispatch('setPerformanceDaysBack', val) // save the days back model into vuex
        if (val === 'range') {
          if (this.search.range) {
            this.refresh()
          }
          this.showDateRange = true
        } else {
          this.showDateRange = false
          this.refresh()
        }
      }
    },
    'customDateRange.model' (val) { // watch the custom date picker
      if (val) {
        this.$store.dispatch('setPerformanceDateRange', val) // save the date range model into vuex
        if (val.includes(',')) {
          this.search.range = val
          this.refresh()
        }
      }
    }
  },
  mounted () {
    if (this.$route.params.campaignId) {
      this.showOnlyValue = parseInt(this.$route.params.campaignId) // set the "campaign value", we use this to default the filter element
      this.search.campaigns = [this.$route.params.campaignId] // set the search
    }
    if (this.currentPerformanceDaysBack) this.goBackThisManyDays.model = this.currentPerformanceDaysBack
    if (this.currentPerformanceDateRange) this.customDateRange.model = this.currentPerformanceDateRange
    if (this.currentAccount) {
      if (this.currentAccount.email) {
        var parts = this.currentAccount.email.split('@')
        if (parts.length > 1) {
          this.search.domain = parts[1]
        }
      }
    }
    this.fetchCampaigns()
    this.selectedMetric = 'revenue'
  },
  methods: {
    onMetricClick (event) {
      this.selectedMetric = event
    },
    refresh () {
      this.dateForStat = this.goBackThisManyDays.model + ''
    },
    fetchCampaigns () {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchStatistics(
        vm.currentAccount.id, 'recentcampaigns', 999, null,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.processRecentCampaignsResponse(response)
          }
        }
      )
    },
    processRecentCampaignsResponse (response) {
      if (response) {
        this.campaigns = []
        var vm = this
        response.forEach(function (campaign) {
          vm.campaigns.push({
            campaign: campaign.title,
            'campaign_id': campaign.id,
            checked: true,
            conversions: campaign.conversions,
            impressions: campaign.impressions
          })
        })
        if (vm.currentPerformanceFilter && vm.currentPerformanceFilter !== 'all' && !vm.loadedFilter) { // we use this flag so we don't load vuex
          vm.campaigns.forEach(function (filter) {
            filter.checked = false
            if (vm.currentPerformanceFilter.includes(filter['campaign_id'])) filter.checked = true
          })
          vm.onCampaignFilterChange()
          vm.loadedFilter = true
        }
      }
    },
    onCampaignFilterChange () {
      var totalCampaignFilters = this.campaigns.length
      var totalChecked = 0
      var newFilter = []
      this.campaigns.forEach(function (campaign) {
        if (campaign.checked) {
          totalChecked++
          newFilter.push(campaign['campaign_id'])
        }
      })
      if (totalChecked === totalCampaignFilters) {
        newFilter = 'all'
      }
      this.search.campaigns = newFilter
      this.$store.dispatch('setPerformanceFilter', newFilter) // save the campaign filter into vuex
    },
    gotoOrders () {
      this.$router.push({ name: 'route-engine-performance-orders' })
    },
    gotoSubmissions (id) {
      this.$router.push({ name: 'route-engine-performance-submissions', params: { campaignId: id } })
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
.level {
  padding: 20px 30px;
  margin-bottom: 20px!important;
}
.date-picker {
  padding-left: 12px;
}
.level-left > .level-item > p > strong:not(:first-child) {
  margin-left: 12px;
}
.metric-table {
  margin-top: 20px;
}
.dropdown {
  margin-bottom: 20px;
}
.level-right {
  margin-top: -2px;
}
.channel-column {
  margin-top: 14px;
}
// Responsive Stuff
@media only screen and (min-width : 0px) and (max-width : $desktop) {
  .level-left > .level-item{
    align-items: normal;
    display: block;
  }
  .level-right > .level-item {
    display: block!important;
  }
  .date-picker {
    padding-left: 0px!important;
    padding-top: 12px!important;
  }
}
@media screen and (max-width: $mobile-max) {
  .level-left + .level-right {
    margin-top: 0px!important;
  }
  .channel-column {
    margin-top: 0px;
  }
}
</style>

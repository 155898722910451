<template>
  <div>
    <div v-if="savedSettings.saved">
      <div class="field">
        <label class="label">Cinch Account</label>
        <p>Company: <strong>{{ savedSettings.companyName }}</strong></p>
        <p>Default Segment: <strong>{{ savedSettings.segmentName }}</strong></p>
        <div class="input-footer-text">
          <a @click="resetSaved()"><i class="fas fa-unlink"/> Reset Settings</a>
        </div>
      </div>
      <div class="field">
        <Toggler
          :props="integrationMode"
          @input="toggleIntegrationMode()"
          v-model="integrationMode.model"/>
      </div>
    </div>
    <div v-else>
      <label class="label">Cinch Account</label>
      <div v-if="segment && company">
        <p class="step-text">Review your Cinch settings below. If you are satisfied, click the Save Changes button to complete your email channel setup.</p>
        <p>Company: <strong>{{ company.name }}</strong></p>
        <p>Default Segment: <strong>{{ segment.name }}</strong></p>
        <div class="input-footer-text">
          <a @click="back('review')">&#60; Go Back</a>
        </div>
      </div>
      <div v-else-if="segments.length > 0">
        <div class="step-text">Select the default customer segment your campaigns should be created for.</div>
        <Select
          key="segment-select"
          :props="propsForSegmentSelect"
          :control="{ 'style': { 'margin-top': '2px' } }"
          v-model="segmentId"></Select>
        <div class="input-footer-text small-margin">
          <a @click="back('segment')">&#60; Go Back</a>
        </div>
      </div>
      <div v-else-if="companies.length > 0">
        <div class="step-text">Select the Cinch company you want to connect with CampaignPilot.</div>
        <Select
          key="company-select"
          :props="propsForCompanySelect"
          :control="{ 'style': { 'margin-top': '2px' } }"
          v-model="companyId"></Select>
        <div class="input-footer-text small-margin">
          <a @click="back('company')">&#60; Go Back</a>
        </div>
      </div>
      <div v-else>
        <div class="step-text">Enter your Cinch credentials below. This will allow CampaignPilot to access Cinch on your behalf.</div>
        <TextInput
          :props="username"
          @enter="login(true)"
          v-model="username.model"/>
        <TextInput
          :props="password"
          @enter="login(true)"
          v-model="password.model"/>
        <div class="oto-settings-save-button">
          <a
            :class="{ 'is-loading': waitingOnAPI }"
            class="button is-primary is-rounded"
            @click="login(true)">
            <span>Login</span>
          </a>
        </div>
        <div class="error-box">
          <div
            v-if="error"
            class="notification">
            {{ error }}
          </div>
        </div>
      </div>
    </div>
    <div class="margin-top">
      <a
        href="https://help.campaignpilot.com/how-tos/cinch-integration"
        target="_blank">
        <div class="icon has-text-primary">
          <i class="fas fa-question-circle"/>
        </div>
        Learn more about how CampaignPilot works with Cinch as an email distribution channel.
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import xhr from '@/mixins/Xhr.js'
import channelApi from '@/plugins/channel-api.js'
import engineApi from '@/plugins/engine-api.js'
import Select from '@/components/elements/Select'
import TextInput from '@/components/elements/TextInput'
import Toggler from '@/components/elements/Toggler'

export default {
  components: {
    Select, TextInput, Toggler
  },
  mixins: [xhr],
  props: ['value'],
  data () {
    return {
      error: null,
      waitingOnAPI: false,
      savedSettings: {
        saved: false
      },
      username: {
        model: null,
        name: 'cinch-username',
        ref: 'cinch-username',
        label: 'Email',
        inputType: 'email',
        fieldClass: 'oto-input-field-text-long'
      },
      password: {
        model: null,
        name: 'cinch-password',
        ref: 'cinch-password',
        label: 'Password',
        inputType: 'password',
        fieldClass: 'oto-input-field-text-long'
      },
      integrationMode: {
        model: true,
        name: 'cinch-integration-mode',
        ref: 'cinch-integration-mode',
        label: 'Campaign Integration Mode',
        placeholder: 'CampaignPilot can either create an email template or schedule a broadcast to your default Cinch segment.',
        options: { checked: 'Create Template', unchecked: 'Schedule Broadcast' },
        optionColors: { checked: '#24C980', unchecked: '#EFC20E' },
        height: 40,
        width: 200
      },
      token: null,
      companyId: null,
      segmentId: null,
      companies: [],
      segments: []
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    }),
    companiesForSelect: function () {
      var companies = []
      if (this.companies.length > 0) {
        companies.push({ 'name': '', 'value': '' })
        this.companies.forEach(function (company) { companies.push({ 'name': company.name, 'value': company.id }) })
      }
      return companies
    },
    propsForCompanySelect: function () {
      if (this.companiesForSelect.length > 0) {
        return {
          label: 'Company',
          model: this.companyId,
          name: 'cinch-company',
          ref: 'cinch-company',
          options: this.companiesForSelect,
          icon: 'fas fa-building',
          fieldClass: 'no-margin'
        }
      } else {
        return null
      }
    },
    company: function () {
      if (this.companyId && this.companies.length > 0) {
        var match = this.companies.find(m => m.id === this.companyId)
        return match
      } else {
        return null
      }
    },
    segmentsForSelect: function () {
      var segments = []
      if (this.segments.length > 0) {
        segments.push({ 'name': '', 'value': '' })
        this.segments.forEach(function (segment) { segments.push({ 'name': segment.name, 'value': segment.id }) })
      }
      return segments
    },
    propsForSegmentSelect: function () {
      if (this.segmentsForSelect.length > 0) {
        return {
          label: 'Segment',
          model: this.segmentId,
          name: 'cinch-segment',
          ref: 'cinch-segment',
          options: this.segmentsForSelect,
          icon: 'fas fa-filter',
          fieldClass: 'no-margin'
        }
      } else {
        return null
      }
    },
    segment: function () {
      if (this.segmentId && this.segments.length > 0) {
        var match = this.segments.find(m => m.id === this.segmentId)
        return match
      } else {
        return null
      }
    },
    settings: function () {
      if (this.company && this.segment) {
        return {
          'has_error': false, // adding this to be consistent with other email channels
          'access_token': this.company.id,
          'token_secret': Buffer.from(this.username.model + ':' + this.password.model).toString('base64'),
          'name': this.company.name,
          'options': {
            'segmentId': this.segment.id,
            'segmentName': this.segment.name,
            'integrationMode': this.integrationMode.model
          }
        }
      } else {
        return {}
      }
    }
  },
  watch: {
    companyId (val) {
      if (val) this.getSegments()
    },
    segment (val) {
      if (val && this.company) this.$emit('input', this.settings)
    },
    company (val) {
      if (val && this.segment) this.$emit('input', this.settings)
    }
  },
  mounted () {
    if (this.value) {
      if (this.value.active === 1) {
        this.savedSettings.saved = true
        this.savedSettings.companyName = this.value.name
        if (this.value.options) {
          var o = JSON.parse(this.value.options)
          if (o) {
            this.savedSettings.segmentName = o.segmentName
            this.integrationMode.model = o.integrationMode
          }
        }
      }
    }
  },
  methods: {
    resetSaved: function () {
      this.savedSettings = {
        saved: false
      }
    },
    back: function (from) {
      if (from === 'company') {
        this.companyId = null
        this.companies = []
        this.segmentId = null
        this.segments = []
      } else if (from === 'segment') {
        this.segmentId = null
        this.segments = []
      } else if (from === 'review') {
        this.segmentId = null
      }
    },
    login: function (triggerCompaniesAfter) {
      var vm = this
      vm.waitingOnAPI = true
      vm.error = null
      channelApi.cinchLogin(vm.currentAccount.id, vm.username.model, vm.password.model, function (err, res) {
        vm.waitingOnAPI = false
        if (err) {
          vm.handleXhrError(err)
          vm.error = 'There was an issue accessing your Cinch account. Check your credentials and try again.'
        } else {
          vm.token = res
          if (triggerCompaniesAfter) vm.getCompanies()
        }
      })
    },
    getCompanies: function () {
      var vm = this
      vm.waitingOnAPI = true
      channelApi.cinchCompanies(vm.currentAccount.id, vm.token, function (err, res) {
        vm.waitingOnAPI = false
        if (err) {
          vm.handleXhrError(err)
        } else {
          if (res) {
            vm.companies = res
          }
        }
      })
    },
    getSegments: function () {
      var vm = this
      vm.waitingOnAPI = true
      channelApi.cinchSegments(vm.currentAccount.id, vm.token, vm.companyId, function (err, res) {
        vm.waitingOnAPI = false
        if (err) {
          vm.handleXhrError(err)
        } else {
          if (res) {
            vm.segments = res
          }
        }
      })
    },
    toggleIntegrationMode: function () {
      engineApi.cinchToggle(this.currentAccount.id, function () {})
    }
  }
}
</script>

<style lang="scss" scoped>
.step-text {
  margin-bottom: 0.5rem;
}
.error-box {
  margin-top: 0.5rem;
}
.input-footer-text {
  font-size: 0.9rem;
}
.small-margin {
  margin-top: 10px;
}
.margin-top {
  margin-top: 1rem;
}
</style>

<template>
  <nav
    v-if="!hideNav"
    id="campaign-wizard-nav"
    class="navbar is-fixed-bottom wizard-nav">
    <div class="navbar-item navbar-item-mobile-fix">
      <div class="navbar-column navbar-column-mobile-fix is-mobile">
        <div class="column has-text-right button-holder">
          <a
            v-if="showBack"
            :disabled="currentLoading || disableBack"
            id="campaign-wizard-nav-back"
            class="button button-left is-rounded"
            @click="goBack(currentLoading)">
            <span class="icon">
              <i class="fa fa-angle-left"/>
            </span>
            <span class="is-hidden-mobile">Back</span>
          </a>
        </div>
        <div class="column has-text-centered">
          <div class="is-hidden-tablet has-text-primary">
            {{ sectionTitle }}
          </div>
          <div id="campaign-wizard-nav-middle" class="columns is-mobile middle-section">
            <!-- Details Step (step 1, 2, 3) -->
            <div
              :class="{ 'has-text-primary': vuexWizardStep>=1 }"
              class="column first-column">
              <div class="is-hidden-mobile">Details</div>
              <div
                :class="{ 'has-background-primary': vuexWizardStep>=2, 'has-background-grey-lighter': vuexWizardStep<2 }"
                class="step-bar-third step-first">
                <div
                  v-if="vuexWizardStep == 1"
                  class="step-circle step-circle"/>
              </div>
              <div
                :class="{ 'has-background-primary': vuexWizardStep>=3, 'has-background-grey-lighter': vuexWizardStep<3 }"
                class="step-bar-third">
                <div
                  v-if="vuexWizardStep == 2"
                  class="step-circle step-circle"/>
              </div>
              <div
                :class="{ 'has-background-primary': vuexWizardStep>=4, 'has-background-grey-lighter': vuexWizardStep<4 }"
                class="step-bar-third">
                <div
                  v-if="vuexWizardStep == 3"
                  class="step-circle step-circle"/>
              </div>
            </div>
            <!-- Theme Step (steps 4, 5, 6) -->
            <div
              :class="{ 'has-text-primary': vuexWizardStep>=4 }"
              class="column middle-column">
              <div class="is-hidden-mobile">Theme</div>
              <div
                :class="{ 'has-background-primary': vuexWizardStep>=5, 'has-background-grey-lighter': vuexWizardStep<5 }"
                class="step-bar-third">
                <div
                  v-if="vuexWizardStep == 4"
                  class="step-circle step-circle"/>
              </div>
              <div
                :class="{ 'has-background-primary': vuexWizardStep>=6, 'has-background-grey-lighter': vuexWizardStep<6 }"
                class="step-bar-third">
                <div
                  v-if="vuexWizardStep == 5"
                  class="step-circle step-circle"/>
              </div>
              <div
                :class="{ 'has-background-primary': vuexWizardStep>=7, 'has-background-grey-lighter': vuexWizardStep<7 }"
                class="step-bar-third">
                <div
                  v-if="vuexWizardStep == 6"
                  class="step-circle step-circle"/>
              </div>
            </div>
            <!-- Landing Page Step (step 7) -->
            <div
              :class="{ 'has-text-primary': vuexWizardStep>=7 }"
              class="column middle-column">
              <div class="is-hidden-mobile">Page</div>
              <div
                :class="{ 'has-background-primary': vuexWizardStep>=8, 'has-background-grey-lighter': vuexWizardStep<8 }"
                class="step-bar-full">
                <div
                  v-if="vuexWizardStep == 7"
                  class="step-circle step-circle"/>
              </div>
            </div>
            <!-- Post Content & Schedule/Channels Step (step 8, 9) -->
            <div
              v-if="!editPublishedMode"
              :class="{ 'has-text-primary': vuexWizardStep>=8 }"
              class="column last-column">
              <div class="is-hidden-mobile">Message</div>
              <div
                :class="{ 'has-background-primary': vuexWizardStep>=9, 'has-background-grey-lighter': vuexWizardStep<9 }"
                class="step-bar-left">
                <div
                  v-if="vuexWizardStep == 8"
                  class="step-circle step-circle"/>
              </div>
              <div
                :class="{ 'has-background-primary': vuexWizardStep>=10, 'has-background-grey-lighter': vuexWizardStep<10 }"
                class="step-bar-right step-last">
                <div
                  v-if="vuexWizardStep == 9"
                  class="step-circle step-circle"/>
              </div>
            </div>
          </div>
        </div>
        <div class="column has-text-left button-holder">
          <a
            v-if="showForward === 1"
            :disabled="currentLoading"
            id="campaign-wizard-nav-save"
            class="button button-right is-primary is-rounded"
            @click="goForward(currentLoading)">
            <span class="icon">
              <i class="fa fa-calendar-check"/>
            </span>
            <span class="is-hidden-mobile">Save and Schedule</span>
          </a>
          <a
            v-else-if="showForward === 2"
            :disabled="currentLoading"
            id="campaign-wizard-nav-save"
            class="button button-right is-primary is-rounded"
            @click="goForward(currentLoading)">
            <span class="icon">
              <i class="fa fa-calendar-check"/>
            </span>
            <span class="is-hidden-mobile">Save Changes</span>
          </a>
          <a
            v-else-if="showForward"
            :disabled="currentLoading"
            id="campaign-wizard-nav-continue"
            class="button button-right is-primary is-rounded"
            @click="goForward(currentLoading)">
            <span class="is-hidden-mobile">Continue</span>
            <span class="icon">
              <i class="fa fa-angle-right"/>
            </span>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      disableForward: false
    }
  },
  computed: {
    ...mapGetters({
      currentLoading: 'currentLoading',
      guideMode: 'currentGuideMode',
      vuexWizardType: 'wizardType',
      vuexWizardSubtype: 'wizardSubtype',
      vuexWizardStep: 'wizardStep',
      vuexWizardStartMode: 'wizardStartMode',
      editPublishedMode: 'editPublishedMode'
    }),
    hideNav: function () {
      return false
    },
    showBack: function () {
      // show back button by default
      return true
    },
    showForward: function () {
      if (this.disableForward || this.vuexWizardStep === 2 || this.vuexWizardStep === 4 || this.vuexWizardStep === 5) {
        // hide forward button in the above steps
        return false
      } else if (this.vuexWizardStep === 9) {
        // show save and schedule button
        return 1
      } else if (this.editPublishedMode && this.vuexWizardStep === 7) {
        // show save changes button
        return 2
      }
      // show forward button by default
      return true
    },
    sectionTitle: function () {
      var title = null
      switch (this.vuexWizardStep) {
        case 1:
        case 2:
        case 3:
          title = 'Details'
          break
        case 4:
        case 5:
        case 6:
          title = 'Theme'
          break
        case 7:
          title = 'Page'
          break
        case 8:
        case 9:
          title = 'Message'
          break
        default:
          break
      }
      return title
    },
    disableBack: function () {
      if (this.guideMode) return true
      return false
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (val === 'disableforward') {
          this.disableForward = true
        } else {
          this.disableForward = false
        }
      }
    }
  },
  methods: {
    goBack (stop) {
      if (!stop && !this.disableBack) {
        if (this.vuexWizardStep === 1) { // details
          if (this.vuexWizardStartMode === 'blank') {
            this.$router.push({ name: 'route-engine-campaigns-start', params: { navdir: 'back' } })
          } else if (this.vuexWizardStartMode === 'edit') {
            this.$router.push({ name: 'route-engine-campaigns', params: { navdir: 'back' } })
          } else {
            this.$router.push({ name: 'route-engine-campaigns-campaign-select', params: { navdir: 'back' } })
          }
        } else if (this.vuexWizardStep === 2) { // type
          this.$router.push({ name: 'route-engine-campaigns-wizard-campaign-wizard', params: { navdir: 'back' } })
        } else if (this.vuexWizardStep === 3) { // type details
          this.$router.push({ name: 'route-engine-campaigns-wizard-type', params: { navdir: 'back', way: 'back' } })
        } else if (this.vuexWizardStep === 4) { // theme tag selection
          if (this.vuexWizardType === 'golf' && this.vuexWizardSubtype === 'info') {
            this.$router.push({ name: 'route-engine-campaigns-wizard-type', params: { navdir: 'back', way: 'back' } })
          } else if (this.vuexWizardType === 'other') {
            this.$router.push({ name: 'route-engine-campaigns-wizard-campaign-wizard', params: { navdir: 'back' } })
          } else {
            this.$router.push({ name: 'route-engine-campaigns-wizard-type-details', params: { navdir: 'back' } })
          }
        } else if (this.vuexWizardStep === 5) {
          this.$emit('input', 'back')
        } else if (this.vuexWizardStep === 6) {
          this.$emit('input', 'back2')
        } else if (this.vuexWizardStep === 7) {
          this.$router.push({ name: 'route-engine-campaigns-wizard-theme', params: { navdir: 'back' } })
        } else if (this.vuexWizardStep === 8) {
          this.$router.push({ name: 'route-engine-campaigns-wizard-landing-page', params: { navdir: 'back' } })
        } else if (this.vuexWizardStep === 9) {
          this.$router.push({ name: 'route-engine-campaigns-wizard-post-content', params: { navdir: 'back' } })
        }
      }
    },
    goForward (stop) {
      if (!stop) {
        if (this.vuexWizardStep === 1) { // details
          this.$router.push({ name: 'route-engine-campaigns-wizard-type', params: { navdir: 'forward' } })
        } else if (this.vuexWizardStep === 2) { // type
          // no forward button here
        } else if (this.vuexWizardStep === 3) { // type details
          this.$router.push({ name: 'route-engine-campaigns-wizard-theme', params: { navdir: 'forward' } })
        } else if (this.vuexWizardStep === 4) { // theme tag
          // no forward button here
        } else if (this.vuexWizardStep === 5) { // theme
          // no forward button here
        } else if (this.vuexWizardStep === 6) { // theme customize
          this.$router.push({ name: 'route-engine-campaigns-wizard-landing-page', params: { navdir: 'forward' } })
        } else if (this.vuexWizardStep === 7) { // landing page
          if (this.editPublishedMode) this.$router.push({ name: 'route-engine-campaigns-home', params: { navdir: 'forward' } })
          else this.$router.push({ name: 'route-engine-campaigns-wizard-post-content', params: { navdir: 'forward' } })
        } else if (this.vuexWizardStep === 8) { // post content
          this.$router.push({ name: 'route-engine-campaigns-wizard-schedule', params: { navdir: 'forward' } })
        } else if (this.vuexWizardStep === 9) { // channels and schedule
          this.$router.push({ name: 'route-engine-campaigns-wizard-confirmation', params: { navdir: 'forward' } })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wizard-nav {
  border-top: 1px solid rgb(219, 219, 219);
  height: 75px;
}
.navbar-item {
  width: 100%;
}
.navbar-column {
  display: flex;
  width: 100%;
}
.step-bar-full {
  display: block;
  width: 100%;
  height: 10px;
}
.step-bar-left {
  width: 40%;
  height: 10px;
  float: left;
}
.step-bar-right {
  width: 60%;
  height: 10px;
  float: right;
}
.step-bar-third {
  width: 33%;
  height: 10px;
  float: left;
}
.step-first {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.step-last {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.step-circle {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid gray;
  position: relative;
  top: -3px;
  left: -3px;
}
.first-column {
  padding-left: 6px;
  padding-right: 3px;
}
.middle-column {
  padding-left: 3px;
  padding-right: 3px;
}
.last-column {
  padding-left: 3px;
  padding-right: 6px;
}
.middle-section {
  padding: 0 10px 0 10px;
}
.navbar-item img {
  max-height: 2.25rem!important;
}
@media only screen and (min-width : 0px) and (max-width : $mobile-max) {
  .navbar-item-mobile-fix {
    padding: 0px;
    height: 100%;
  }
  .navbar-column-mobile-fix {
    height: 100%;
  }
}
</style>

<template>
  <div
    v-if="currentUser && currentAccounts && currentAccount && currentAccountChannels"
    id="dashboard">
    <NavBar/>
    <LoadingBar/>
    <Notify/>
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
    <Footer/>
    <!-- INTERCOM START -->
    <script2>
      var IC_APP_ID = 'nvzbtm77';
      var IC_USER_EMAIL = '{{ currentUser.email }}';
      var IC_USER_NAME = '{{ currentUser.name }}';
      var IC_USER_ID = '{{ currentUser['cognito_id'] }}';
      window.intercomSettings = {
        app_id: IC_APP_ID,
        name: IC_USER_NAME,
        email: IC_USER_EMAIL,
        user_id: IC_USER_ID
      };
    </script2>
    <script2>(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();</script2>
    <!-- INTERCOM END -->
  </div>
  <div v-else>
    <LoadingBar
      :force-on="true"
      :force-top="true"/>
    <table class="oto-app-loading-screen">
      <tr>
        <td>
          <img src="../../assets/cp-logo.png">
          <p>{{ loadingPhrase }}</p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import NavBar from '@/components/elements/NavBar'
import LoadingBar from '@/components/elements/LoadingBar'
import Notify from '@/components/elements/Notify'
import Footer from '@/components/dashboard/Footer'
import { mapGetters } from 'vuex'

export default {
  components: {
    NavBar,
    LoadingBar,
    Notify,
    Footer
  },
  computed: {
    loadingPhrase: function () {
      return this.loadingPhrases[Math.floor(Math.random() * this.loadingPhrases.length)]
    },
    ...mapGetters({
      fatal: 'fatal',
      currentUser: 'currentUser',
      currentAccounts: 'currentAccounts',
      currentAccount: 'currentAccount',
      currentAccountChannels: 'currentAccountChannels'
    })
  },
  data () {
    return {
      loadingTimeout: 10000, // time we wait for a proper account and business before we ditch to the login screen
      loadingPhrases: [
        'The Captain has turned off the Fasten Seat Belt sign.',
        'Make sure your seat backs and tray tables are in their full upright position.',
        'Flight attendants please prepare for gate departure.',
        'Cabin crew please take your seats for take-off.',
        'Sit back, relax, and enjoy the rest of the flight.',
        'Flight attendants prepare doors for departure.',
        'Please remain seated until the Captain turns off the Fasten Seat Belt sign.',
        'Buckle up and get ready for takeoff!',
        'Welcome aboard! We\'re ready to soar to new heights.',
        'Cabin crew, prepare for landing.',
        'Flying high above the clouds.',
        'Pilot here, get ready for a smooth ride.',
        'We hope you have enjoyed your flight with us.',
        'Clear skies and tailwinds ahead.',
        'Up, up, and away we go!'
      ]
    }
  },
  watch: {
    fatal: function (val, oldVal) {
      if (val) {
        this.$router.push({ name: 'route-login', query: { redirect: this.$router.currentRoute.fullPath, fatal: true } })
      }
    }
  },
  mounted () {
    var vm = this
    setTimeout(function () {
      if (!vm.currentUser || !vm.currentAccounts || !vm.currentAccount || !vm.currentAccountChannels) {
        vm.$router.push({ name: 'route-login', query: { redirect: vm.$router.currentRoute.fullPath, fatal: true } })
      }
    }, vm.loadingTimeout)
    // this is used when embedding in CampaignPilot into another system (i.e. Tee Sheet)
    if (this.$route.query.embedmode) {
      var jwt = null
      if (this.$route.query.token) {
        var base64Url = this.$route.query.token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
        jwt = JSON.parse(jsonPayload)
      }
      if (jwt) {
        // valid embedmode, save it to the global store
        this.$store.dispatch('setEmbedMode', {
          mode: this.$route.query.embedmode,
          token: this.$route.query.token,
          jwt: jwt
        })
      }
    }
  }
}
</script>

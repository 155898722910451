<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title">
            Administration
          </h1>
          <h2 class="subtitle">
            You're super.
          </h2>
          <div class="tabs">
            <ul>
              <li :class="{ 'is-active': currentTab === 'business' }">
                <a
                  @click="setTab('business')">
                  Create Business
                </a>
              </li>
              <!-- <li :class="{ 'is-active': currentTab === 'user' }">
                <a
                  @click="setTab('user')">
                  Create User
                </a>
              </li> -->
              <li :class="{ 'is-active': currentTab === 'banner' }">
                <a
                  @click="setTab('banner')">
                  Manage Home Banner
                </a>
              </li>
            </ul>
          </div>
          <Business v-if="currentTab === 'business'"/>
          <User v-else-if="currentTab === 'user'"/>
          <Banner v-else-if="currentTab === 'banner'"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Banner from '@/components/dashboard/admin/Banner.vue'
import Business from '@/components/dashboard/admin/Business.vue'
// import User from '@/components/dashboard/admin/User.vue'

export default {
  components: {
    Banner,
    Business
    // User
  },
  data () {
    return {
      currentTab: 'business'
    }
  },
  mounted () {

  },
  methods: {
    setTab (tab) {
      this.currentTab = tab
    }
  }
}
</script>

<style scoped>
.tabs {
  overflow-x: hidden!important;
}
</style>

<template>
  <div>
    <nav class="navbar">
      <div class="navbar-w-max-width">
        <div class="navbar-brand">
          <component
            :is="guideMode ? 'span' : 'router-link'"
            :to="{ name: 'route-engine-home' }"
            class="navbar-item">
            <img
              class="toolbar-img"
              src="../../assets/cp-logo.png">
          </component>
          <div
            v-if="inWizardMode"
            class="no-print navbar-item tablet-only-items is-hidden-desktop is-hidden-mobile oto-nav-draft-title">
            {{ draftTitle }}
          </div>
          <div
            v-else-if="!guideMode"
            class="no-print tablet-only-items is-hidden-desktop is-hidden-mobile">
            <router-link
              :to="{ name: 'route-engine-campaigns' }"
              :class="{ 'is-active': currentSection === 'campaigns' }"
              class="navbar-item">
              Campaigns
            </router-link>
            <router-link
              :to="{ name: 'route-engine-performance' }"
              :class="{ 'is-active': currentSection === 'performance' }"
              class="navbar-item">
              Performance
            </router-link>
            <router-link
              :to="{ name: 'route-engine-settings' }"
              :class="{ 'is-active': currentSection === 'settings' }"
              class="navbar-item">
              Settings
            </router-link>
          </div>
          <component
            v-if="inWizardMode && !editPublishedMode"
            :is="guideMode ? 'span' : 'router-link'"
            :to="{ name: 'route-engine-campaigns', params: { 'button': 'draft' } }"
            class="no-print navbar-item tablet-only-save-button has-background-primary has-text-white is-hidden-desktop is-hidden-mobile">
            <span class="icon">
              <font-awesome-icon icon="save" />
            </span>
            Save Draft
          </component>
          <router-link
            v-else-if="!inWizardMode && !guideMode"
            :to="{ name: 'route-engine-campaigns-wizard' }"
            class="no-print navbar-item tablet-only-new-button is-hidden-desktop is-hidden-mobile">
            <span class="icon">
              <font-awesome-icon icon="plus" />
            </span>
          </router-link>
          <a
            id="mobile-nav-button"
            :class="{ 'is-active': currentMenuStates.main }"
            class="no-print navbar-burger"
            @click="toggleMenu('main', !currentMenuStates.main)">
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
          </a>
        </div>
        <div
          :class="{ 'is-active': currentMenuStates.main }"
          class="no-print navbar-menu">
          <div class="navbar-start">
            <div
              v-if="inWizardMode"
              class="navbar-item is-hidden-touch oto-nav-draft-title">
              {{ draftTitle }}
            </div>
            <router-link
              v-if="!inWizardMode && !guideMode"
              :to="{ name: 'route-engine-campaigns' }"
              :class="{ 'is-active': currentSection === 'campaigns' }"
              class="navbar-item is-hidden-tablet-only">
              Campaigns
            </router-link>
            <router-link
              v-if="!inWizardMode && !guideMode"
              :to="{ name: 'route-engine-performance' }"
              :class="{ 'is-active': currentSection === 'performance' }"
              class="navbar-item is-hidden-tablet-only">
              Performance
            </router-link>
            <router-link
              v-if="!inWizardMode && !guideMode"
              :to="{ name: 'route-engine-settings' }"
              :class="{ 'is-active': currentSection === 'settings' }"
              class="navbar-item is-hidden-tablet-only">
              Settings
            </router-link>
            <div class="is-hidden-desktop">
              <div v-if="currentAccounts.length > 1">
                <hr
                  v-if="!inWizardMode"
                  class="is-hidden-tablet-only no-margin"/>
                <div v-if="currentAccounts.length > maxBeforeSearch">
                  <div class="navbar-item">
                    <span class="icon">
                      <i class="fa fa-store"/>
                    </span>
                    <span>{{ currentAccount.name }}</span>
                  </div>
                  <vue-autosuggest
                    ref="mini-autosuggest"
                    :suggestions="filteredOptions"
                    :input-props="{id:'search-accounts-list', placeholder:'Search Accounts', autocomplete:'off'}"
                    component-attr-class-autosuggest-results-container="account-list-container"
                    class="account-list navbar-item oto-no-padding-top"
                    @input="autoOnInputChange"
                    @selected="autoSelectHandler">
                    <template slot-scope="{suggestion}">
                      <span class="account-list-item">{{ suggestion.item.name }}</span>
                    </template>
                  </vue-autosuggest>
                </div>
                <div v-else>
                  <a
                    v-for="(account, index) in currentAccounts"
                    :key="index"
                    :class="{ 'is-active':account.id === currentAccount.id }"
                    class="navbar-item"
                    @click="setAccount(account.id)">
                    {{ account.name }}
                  </a>
                </div>
                <hr class="no-margin"/>
              </div>
              <a
                :href="helpURL"
                class="navbar-item"
                target="_blank">
                Help
              </a>
              <hr class="no-margin"/>
              <router-link
                :to="{ name: 'route-engine-profile' }"
                :class="{ 'is-active': currentSection === 'profile' }"
                class="navbar-item">
                My Profile
              </router-link>
              <router-link
                v-if="currentUser.superadmin === 1"
                :to="{ name: 'route-engine-admin' }"
                :class="{ 'is-active': currentSection === 'superadmin' }"
                class="navbar-item ">
                Admin
              </router-link>
              <a
                v-if="currentUser.superadmin === 1 && currentUser.superdesigner === 1 && inWizardMode"
                class="navbar-item"
                href="#"
                @click="openTemplateBuilder()">
                New Template
              </a>
              <router-link
                :to="{ name: 'route-logout' }"
                class="navbar-item">
                Log Out
              </router-link>
            </div>
          </div>
          <div class="navbar-end is-hidden-touch">
            <component
              v-if="inWizardMode && !editPublishedMode"
              :is="guideMode ? 'span' : 'router-link'"
              :to="{ name: 'route-engine-campaigns', params: { 'button': 'draft' } }"
              class="navbar-item has-background-primary has-text-white">
              <span class="icon">
                <font-awesome-icon icon="save" />
              </span>
              Save Draft
            </component>
            <router-link
              v-else-if="!inWizardMode && !guideMode"
              :to="{ name: 'route-engine-campaigns-wizard' }"
              id="navbar-button-new-campaign"
              class="navbar-item">
              <span class="icon">
                <font-awesome-icon icon="plus" />
              </span>
              <div class="is-hidden-desktop-only">New Campaign</div>
            </router-link>
            <div
              v-if="currentAccounts.length > 1"
              :class="{ 'is-active': currentMenuStates.account }"
              class="navbar-item has-dropdown account-section">
              <a
                :class="{ 'is-active': currentMenuStates.account }"
                class="navbar-link"
                @click="toggleMenu('account', !currentMenuStates.account)">
                <span class="icon">
                  <i class="fa fa-store"/>
                </span>
                <div class="navbar-account-name">{{ currentAccount.name }}</div>
              </a>
              <transition name="fade" mode="out-in">
                <div
                  v-if="currentMenuStates.account"
                  class="navbar-dropdown account-search-list">
                  <vue-autosuggest
                    v-if="currentAccounts.length > maxBeforeSearch"
                    ref="desktop-autosuggest"
                    :suggestions="filteredOptions"
                    :input-props="{id:'search-accounts-list', placeholder:'Search Accounts', autocomplete:'off'}"
                    component-attr-class-autosuggest-results-container="account-list-container"
                    class="account-list navbar-item"
                    @input="autoOnInputChange"
                    @selected="autoSelectHandler">
                    <template slot-scope="{suggestion}">
                      <span class="account-list-item">{{ suggestion.item.name }}</span>
                    </template>
                  </vue-autosuggest>
                  <div v-else>
                    <a
                      v-for="(account, index) in currentAccounts"
                      :key="index"
                      :class="{ 'is-active':account.id === currentAccount.id }"
                      class="navbar-item"
                      @click="setAccount(account.id)">
                      {{ account.name }}
                    </a>
                  </div>
                </div>
              </transition>
            </div>
            <div
              v-else
              class="navbar-item solo-account">
              <span class="icon">
                <i class="fa fa-store"/>
              </span>
              <div class="navbar-account-name">{{ currentAccount.name }}</div>
            </div>
            <div
              :class="{ 'is-active': currentMenuStates.user }"
              class="navbar-item has-dropdown profile-section">
              <a
                :class="{ 'is-active': currentMenuStates.user }"
                class="navbar-link"
                @click="toggleMenu('user', !currentMenuStates.user)">
                <span
                  :class="{ 'has-text-danger':currentUser.superadmin === 1 }"
                  class="icon">
                  <i
                    :class="{ 'fa-user-ninja':currentUser.superadmin === 1, 'fa-user-circle':currentUser.superadmin !== 1 }"
                    class="fa"
                  />
                </span>
                <div class="navbar-user-name"></div>
              </a>
              <transition name="fade" mode="out-in">
                <div
                  v-if="currentMenuStates.user"
                  class="navbar-dropdown">
                  <router-link
                    :to="{ name: 'route-engine-profile' }"
                    :class="{ 'is-active': currentSection === 'profile' }"
                    class="navbar-item ">
                    My Profile
                  </router-link>
                  <router-link
                    v-if="currentUser.superadmin === 1"
                    :to="{ name: 'route-engine-admin' }"
                    :class="{ 'is-active': currentSection === 'superadmin' }"
                    class="navbar-item ">
                    Admin
                  </router-link>
                  <a
                    v-if="currentUser.superadmin === 1 && currentUser.superdesigner === 1 && inWizardMode"
                    class="navbar-item"
                    href="#"
                    @click="openTemplateBuilder()">
                    New Template
                  </a>
                  <router-link
                    :to="{ name: 'route-logout' }"
                    class="navbar-item">
                    Log Out
                  </router-link>
                </div>
              </transition>
            </div>
            <a
              :href="helpURL"
              class="navbar-item"
              target="_blank">
              <span class="icon">
                <i class="fa fa-question"/>
              </span>
            </a>
          </div>
        </div>
      </div>
    </nav>
    <TemplateBuilder
      v-if="currentUser.superadmin === 1 && currentUser.superdesigner === 1 && inWizardMode"
      :builder="templateBuilder" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VueAutosuggest } from 'vue-autosuggest'
import TemplateBuilder from '@/components/elements/TemplateBuilder'

export default {
  components: {
    VueAutosuggest,
    TemplateBuilder
  },
  data () {
    return {
      helpURL: process.env.VUE_APP_HELP_DOCS_URL,
      maxBeforeSearch: 4,
      maxSearchResults: 20,
      filteredOptions: [{ data: [] }],
      templateBuilder: {
        modalOpen: false
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      currentMenuStates: 'currentMenuStates',
      currentSection: 'currentSection',
      currentAccounts: 'currentAccounts',
      currentAccount: 'currentAccount',
      editPublishedMode: 'editPublishedMode',
      guideMode: 'currentGuideMode',
      vuexWizardTitle: 'wizardTitle',
      vuexWizardDetails: 'wizardDetails'
    }),
    inWizardMode: function () {
      if (this.$route.name.match(/route-engine-campaigns-/g) && this.$route.name !== 'route-engine-campaigns-home' && this.$route.name !== 'route-engine-campaigns-start' && this.$route.name !== 'route-engine-campaigns-campaign-select' && this.$route.name !== 'route-engine-campaigns-wizard-confirmation') return true
      return false
    },
    draftTitle: function () {
      var vm = this
      var title = null
      if (vm.vuexWizardDetails) {
        vm.vuexWizardDetails.questions.every(function (question) {
          if (question.name) {
            if (question.model) {
              if (question.name === 'title') {
                title = question.model
              }
            }
          }
          if (title) {
            return false
          } else {
            return true
          }
        })
      }
      return title
    }
  },
  created () {
    document.addEventListener('click', this.closeMenus)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.closeMenus, true)
  },
  methods: {
    toggleMenu: function (menu, state) {
      this.$store.dispatch('setMenuState', { menu: menu, state: state })
      if (state) {
        this.filteredOptions = [{ data: [] }] // clear the search list
      }
    },
    closeMenus (e) {
      if (e) {
        if (e.target) {
          var close = true
          var inspect = e.path
          if (!inspect) {
            if (e.composedPath) {
              inspect = e.composedPath()
            }
          }
          if (inspect) {
            if (inspect.length > 0) {
              inspect.forEach(function (path) {
                if (typeof path.className === 'string') {
                  if (path.className === 'navbar') close = false
                }
              })
            }
          } else {
            // get path from target (this is mainly a fix for Edge browser)
            if (e.target.classList) {
              if (e.target.classList.contains('navbar-link') || e.target.classList.contains('form-control') || e.target.classList.contains('navbar-item')) {
                close = false
              }
            }
          }
          if (close) this.$store.dispatch('setMenuState', { menu: 'main', state: false })
        }
      }
    },
    setAccount (accountId) {
      this.$store.dispatch('setLoadingState', true)
      this.$store.dispatch('setAccount', accountId)
      var vm = this
      if (this.$route.name === 'route-engine-home') {
        setTimeout(function () {
          vm.$store.dispatch('setLoadingState', false)
          vm.$router.go()
        }, 3000)
      } else {
        setTimeout(function () {
          vm.$store.dispatch('setLoadingState', false)
          vm.$router.push({ name: 'route-engine-home' })
        }, 3000)
      }
    },
    autoOnInputChange (text, oldText) {
      if (text === '') {
        this.filteredOptions = [{ data: [] }] // if there is no search, clear the results
        return
      }
      var filteredData = this.currentAccounts.filter(option => {
        return option.name.toLowerCase().indexOf(text.toLowerCase()) > -1
      })
      this.filteredOptions = [{ data: filteredData.slice(0, this.maxSearchResults) }]
    },
    autoSelectHandler (item) {
      if (item) {
        var selectedId = item.item.id
        this.$store.dispatch('setMenuState', { menu: 'main', state: false }) // close the menu
        this.setAccount(selectedId)
        this.filteredOptions = [{ data: [] }] // clear the results
        if (this.$refs['mini-autosuggest']) this.$refs['mini-autosuggest'].searchInput = ''
        if (this.$refs['desktop-autosuggest']) this.$refs['desktop-autosuggest'].searchInput = ''
      }
    },
    launchChat () {
      var chatButton = document.getElementsByClassName('zls-btnmn')
      if (chatButton) {
        if (chatButton.length > 0) {
          chatButton[0].click()
        }
      }
    },
    openTemplateBuilder () {
      this.templateBuilder.modalOpen = true
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  border-bottom: 1px solid rgb(219, 219, 219);
}
.navbar-item {
  padding: 0.5rem 1rem;
}
.account-section {
  border-left: 1px solid rgb(219, 219, 219);
}
.profile-section {
  border-left: 1px solid rgb(219, 219, 219);
  border-right: 1px solid rgb(219, 219, 219);
}
.account-section,
.profile-section,
.help-section {
  padding: 0;
}
.account-section > .navbar-link,
.profile-section > .navbar-link,
.help-section > .navbar-link {
  padding-left: 1.5em;
}
.icon {
  padding-right: 15px;
}
.tablet-only-items {
  display: flex;
}
.tablet-only-new-button {
  position: absolute;
  right: 54px;
  top: 6px;
}
.tablet-only-save-button {
  padding: 0.86rem 1rem;
  position: absolute;
  right: 54px;
  top: 0px;
}
.navbar-item img {
  max-height: 2.25rem!important;
}
.navbar-account-name {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.oto-nav-draft-title {
  font-weight: 600;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  line-height: 2.3rem;
}
.solo-account {
  border-left: 1px solid #dbdbdb;
}
//IE EDGE FIX FOR DROP DOWN MENUS
.navbar-item.has-dropdown.is-active > .navbar-dropdown {
  display: block;
}
@media only screen and (min-width : 0px) and (max-width : $mobile-max) {
  .navbar-account-name {
  }
}
@media only screen and (min-width : $tablet) and (max-width : $desktop) {
  .navbar-account-name {
  }
  .oto-nav-draft-title {
    max-width: calc(100vw - 425px);
  }
}
@media only screen and (min-width : $desktop) and (max-width : $widescreen) {
  .navbar-account-name {
    min-width: 100px;
    max-width: 200px;
  }
  .navbar-start > .navbar-item.is-active {
    border-bottom: 4px solid $secondary;
    padding-top: 12px !important;
  }
  .navbar-w-max-width {
    display: flex;
    max-width: 1475px;
    width: 100%;
    margin: auto;
  }
  .oto-nav-draft-title {
    max-width: calc(100vw - 800px);
  }
}
@media only screen and (min-width : $widescreen) {
  .navbar-account-name {
    min-width: 100px;
    max-width: 250px;
  }
  .navbar-start > .navbar-item.is-active {
    border-bottom: 4px solid $secondary;
    padding-top: 12px !important;
  }
  .navbar-w-max-width {
    display: flex;
    max-width: 1475px;
    width: 100%;
    margin: auto;
  }
  .oto-nav-draft-title {
    max-width: calc(100vw - 850px);
  }
  .account-search-list {
  }
}
</style>

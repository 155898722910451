<template>
  <div
    :class="{ 'is-active':data.show }"
    class="modal">
    <div class="modal-background"/>
    <div class="modal-content">
      <div class="card">
        <div class="card-content">
          <p class="title">
            {{ data.question }}
          </p>
          <p class="subtitle">
            {{ data.text }}
          </p>
        </div>
        <footer class="card-footer">
          <p
            v-if="!data.hideBack"
            class="card-footer-item buttons-left">
            <span
              :disabled="currentLoading"
              class="button is-light is-rounded"
              @click="denyButton">
              {{ data.backtext ? data.backtext : 'Go Back'}}
            </span>
          </p>
          <p class="card-footer-item buttons-right">
            <span
              :class="{ 'is-loading': currentLoading }"
              class="button is-error is-rounded"
              @click="confirmButton">
              {{ data.button }}
            </span>
          </p>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import xhr from '@/mixins/Xhr.js'
import engineApi from '@/plugins/engine-api.js'

export default {
  mixins: [xhr],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentLoading: 'currentLoading'
    })
  },
  mounted () {
    // since this is a modal, we're going to move it to an outside div
    document.body.appendChild(this.$el)
  },
  destroyed () {
    // destroy what we appended, the append functions more like vue then
    document.body.removeChild(this.$el)
  },
  methods: {
    denyButton: function () {
      this.reset()
    },
    confirmButton: function () {
      var vm = this
      if (vm.data.action === 'delete') {
        vm.$store.dispatch('setLoadingState', true)
        engineApi.deleteCampaign(
          vm.currentAccount.id,
          vm.data.id,
          function (err, response) {
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            }
            vm.$store.dispatch('setNotify', { state: true, text: 'Your marketing campaign has been deleted.' })
            vm.data.result = true
            vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
            vm.reset()
          }
        )
      } else if (vm.data.action === 'disable') {
        vm.$store.dispatch('setLoadingState', true)
        engineApi.toggleCampaign(vm.currentAccount.id, vm.data.id, 'actions',
          function (err, response) {
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            }
            vm.$store.dispatch('setNotify', { state: true, text: 'Your marketing campaign has been disabled.' })
            vm.data.result = true
            vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
            vm.reset()
          }
        )
      } else if (vm.data.action === 'archive') {
        vm.$store.dispatch('setLoadingState', true)
        engineApi.toggleCampaign(vm.currentAccount.id, vm.data.id, 'archive',
          function (err, response) {
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            }
            vm.$store.dispatch('setNotify', { state: true, text: 'Your marketing campaign has been archived.' })
            vm.data.result = true
            vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
            vm.reset()
          }
        )
      } else if (vm.data.action === 'editpublishedwarning') {
        vm.$store.dispatch('setDismissedPublishedModeWarning')
        vm.reset()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(`action not passed or not found!`)
        vm.data.result = true
        vm.reset()
      }
    },
    reset: function () {
      this.data.show = false
      this.data.action = null
      this.data.id = null
    }
  }
}
</script>

<style scoped>
.card-footer, .card-footer-item {
  border: none;
}
.buttons-left {
  justify-content: flex-start;
}
.buttons-right {
  justify-content: flex-end;
}
.add-margin-right {
  margin-right: 20px;
}
</style>

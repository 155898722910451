<template>
  <div
    :class="fieldClasses"
    class="field">
    <label
      v-if="props.label"
      :style="label.style"
      :class="label.class"
      class="label">
      <span v-if="props.label === '&nbsp;'">&nbsp;</span>
      <span v-else>{{ props.label }}</span>
      <span
        v-if="isRequired && !props.hideRequired"
        class="icon has-text-danger is-small oto-required-field">
        <i class="fas fa-star-of-life oto-fa-mini"/>
      </span>
      <i
        v-if="props.limit>0"
        class="has-text-grey-light content is-small">&nbsp;(limit {{ props.limit }} characters)</i>
    </label>
    <div class="control">
      <textarea
        v-validate="props.validate"
        :value="props.model"
        :placeholder="props.placeholder"
        :name="props.name"
        :ref="props.ref"
        :disabled="disabled"
        :rows="props.rows"
        :maxlength="props.limit"
        :data-vv-as="props.label && !props.useNameInError ? props.label : props.name"
        :style="control.style"
        :class="inputClasses"
        class="textarea"
        @blur="onBlur()"
        @focus="onFocus()"
        @input="onInput($event.target.value)"/>
      <span
        v-if="errors.has(props.name) && !props.hideErrors"
        class="has-text-danger animated fadeInDown sentence-case">
        {{ errors.first(props.name) }}
      </span>
    </div>
    <p
      v-if="props.mobileHelp && isInputActive"
      class="oto-mobile-help is-info is-hidden-tablet">
      <span class="icon">
        <i class="fas fa-info-circle"/>
      </span>
      {{ props.mobileHelp }}
    </p>
  </div>
</template>

<script>
import inputs from '@/mixins/Inputs.js'

export default {
  mixins: [inputs]
}
</script>

<template>
  <div>
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
    <SaveWarning/>
  </div>
</template>

<script>
import SaveWarning from '@/components/dashboard/campaigns/wizard/SaveWarning'

export default {
  components: {
    SaveWarning
  }
}
</script>

<template>
  <div class="upcoming">
    <div
      v-if="campaigns.length > 0"
      class="upcoming-wrapper">
      <div
        v-for="(campaign, i) in campaigns"
        :key="'upcoming-campaign-' + i">
        <hr v-if="i>0" class="upcoming-campaign-hr" />
        <div class="upcoming-campaign">
          <div class="upcoming-campaign-date-header">
            <div>{{ generateMonth(campaign.date) }}</div>
            <div>{{ generateDay(campaign.date) }}</div>
          </div>
          <div class="upcoming-campaign-details-area">
            <div class="upcoming-campaign-campaign-title">
              <router-link
                :to="{ name: 'route-engine-campaigns', params: { 'camp': campaign.id } }">
                {{ campaign.title }}
              </router-link>
            </div>
            <div v-if="campaign.longdate" class="upcoming-campaign-campaign-schedule">Scheduled for {{ campaign.longdate }}</div>
            <div class="upcoming-campaign-campaign-type-channel-row">
              <div class="upcoming-campaign-campaign-type">{{ campaign.action }}</div>
              <div class="upcoming-campaign-campaign-sep">|</div>
              <div class="upcoming-campaign-campaign-channels">
                <span v-if="campaign.channels.facebook" class="icon is-small has-text-grey-dark">
                  <i class="fab fa-facebook"/>
                </span>
                <span v-if="campaign.channels.twitter" class="icon is-small has-text-grey-dark">
                  <i class="fab fa-twitter"/>
                </span>
                <span v-if="campaign.channels.google" class="icon is-small has-text-grey-dark">
                  <i class="fab fa-google"/>
                </span>
                <span v-if="campaign.channels.instagram" class="icon is-small has-text-grey-dark">
                  <i class="fab fa-instagram"/>
                </span>
                <span v-if="campaign.channels.website" class="icon is-small has-text-grey-dark">
                  <i class="fas fa-globe"/>
                </span>
                <span v-if="campaign.channels.app" class="icon is-small has-text-grey-dark">
                  <i class="fas fa-mobile"/>
                </span>
                <span v-if="campaign.channels.email" class="icon is-small has-text-grey-dark">
                  <i class="fas fa-envelope"/>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="fetched"
      class="upcoming-wrapper">
      <p>You don't have any campaigns scheduled. Check out our Campaign Library for some ideas.</p>
      <router-link
        :to="{ name: 'route-engine-campaigns-campaign-select' }"
        class="button is-primary is-rounded longer-button">
        Browse Library
      </router-link>
    </div>
  </div>
</template>

<script>
import utils from '@/plugins/utils.js'

export default {
  props: {
    campaigns: {
      type: Array, // array of campaign objects
      default: function () {
        return []
      }
    },
    fetched: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    generateMonth (datestring) {
      var mnth = null
      var date = (typeof datestring === 'string') ? datestring.split('-') : []
      if (date.length === 3) mnth = date[1]
      if (mnth) return utils.monthNameShort(parseInt(mnth))
      return null
    },
    generateDay (datestring) {
      var day = null
      var date = (typeof datestring === 'string') ? datestring.split('-') : []
      if (date.length === 3) day = date[2]
      if (day) return parseInt(day)
      return null
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
.upcoming {
  width: 100%;
  padding-left: 40px;
}
.upcoming p {
  font-size: 0.9em;
  padding-bottom: 30px;
  max-width: 500px;
}
.upcoming-wrapper {
  min-height: 165px;
  max-height: 165px;
  overflow-y: auto;
}
.narrow-desktop .upcoming {
  padding-left: 0px;
  padding-top: 20px;
}
.upcoming-campaign {
  display: flex;
}
.upcoming-campaign-hr {
  margin: 0.5rem 0!important;
  height: 1px!important;
  background-color: $grey-light!important;
}
.upcoming-campaign-date-header {
  font-weight: 600;
  font-size: 0.9em;
  text-align: center;
  margin: auto 0;
}
.upcoming-campaign-date-header div {
  line-height: 1.15;
}
.upcoming-campaign-details-area {
  padding-left: 20px;
  display: grid;
}
.upcoming-campaign-campaign-title {
  color: $primary;
  font-weight: 600;
  font-size: 0.9em;
  line-height: 1.75;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}
.upcoming-campaign-campaign-schedule {
  font-size: 0.8em;
  max-width: 234px;
  line-height: 1.75;
}
.upcoming-campaign-campaign-type-channel-row {
  display: flex;
  line-height: 1.75;
}
.upcoming-campaign-campaign-sep {
  padding-left: 5px;
  padding-right: 10px;
  font-size: 0.8em;
  line-height: 1.75;
}
.upcoming-campaign-campaign-type {
  font-size: 0.8em;
  text-transform: capitalize;
}
.upcoming-campaign-campaign-channels {}
.upcoming-campaign-campaign-channels .icon {
  padding: 0 20px 0 0;
  transform: scale(0.8);
}
@media only screen and (min-width : 0px) and (max-width : $mobile-max) {
  .upcoming {
    padding-left: 0px;
  }
}
@media only screen and (min-width : $tablet) and (max-width : $desktop) {
  .narrow-desktop .upcoming {
    padding-left: 40px;
    padding-top: 0px;
  }
}
</style>

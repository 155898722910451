<template ref="confirmationpage">
  <section class="section">
    <div class="container">
      <div class="columns">
        <!-- Header -->
        <div class="column has-text-centered">
          <figure class="image container is-128x128">
            <img src="../../../../../assets/imgs/other/campaign-completed-icon.png"/>
          </figure>
          <h1 class="title">Congratulations!</h1>
          <h2 class="subtitle">Your marketing campaign is completed and scheduled.</h2>
        </div>
      </div>
      <div class="columns">
        <div
          v-if="showEmailMarketing"
          :class="{ 'is-full': !showAsset, 'is-half': showAsset }"
          class="column oto-conf-page-box">
          <div class="notification oto-confirmation-notification">
            <div class="subtitle-w-bar no-top-margin" style="margin-bottom: 4px;">NEXT:</div>
            <h3 class="title is-5" style="padding-left: 13px;">Send this Campaign as an Email</h3>
            <div class="content">
              <p>
                CampaignPilot has generated a <strong>draft</strong> email message for this campaign in your {{ accountEmailSettings.provider }} email software.
                You can review and send this email message there.</p>
              <a
                :href="accountEmailSettings.url"
                target="_blank"
                class="button is-primary is-rounded">GO TO EMAIL MARKETING</a>
            </div>
          </div>
        </div>
        <div
          v-if="showAsset"
          :class="{ 'is-full': !showEmailMarketing, 'is-half': showEmailMarketing }"
          id="confirmation-page-section-summary"
          class="column oto-conf-page-box">
          <div class="notification oto-confirmation-notification">
            <div class="subtitle-w-bar no-top-margin" style="margin-bottom: 4px;">NEXT:</div>
            <h3 class="title is-5" style="padding-left: 13px;">Review Your Marketing Campaign</h3>
            <div class="content">
              <p>CampaignPilot has put together a complete summary and rundown of this marketing campaign. You can share this summary page with employees and stakeholders so they can review the campaign.</p>
              <a
                :href="campaignAssetURL"
                target="_blank"
                class="button is-primary is-rounded">
                <span class="icon is-small">
                  <i class="fas fa-search"></i>
                </span>
                <span>SHOW CAMPAIGN SUMMARY</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showLanding"
        class="columns">
        <div id="confirmation-page-section-landing" class="column">
          <div class="notification oto-confirmation-notification">
            <div class="columns">
              <div class="column has-text-centered">
                <div class="oto-thumbnail-container">
                  <div class="oto-thumbnail">
                    <iframe
                      :src="campaignLandingPageURL + '?hidescroll=1&skiphit=1'"
                      frameborder="0"
                      onload="this.style.opacity = 1"></iframe>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="level is-mobile">
                  <div class="level-left">
                    <div class="level-item">
                      <h3 class="subtitle-w-bar title is-5" style="margin-top:0;">Landing Page</h3>
                    </div>
                    <div class="level-item">
                      <span class="icon is-medium has-text-primary">
                        <i class="fas fa-2x fa-check-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="content">
                  <p>
                    Here is your SEO and mobile friendly web page for your marketing campaign.
                    Your customers can use this page to
                    {{ campaignAction }}.
                  </p>
                  <div class="columns is-mobile no-bottom-margin">
                    <div class="column is-narrow">
                      <p class="has-text-weight-bold">URL:</p>
                    </div>
                    <div class="column">
                      <p>
                        <a
                          :href="campaignLandingPageURL"
                          target="_blank">
                          {{ campaignLandingPageURL }}
                        </a>
                        &nbsp;
                        <a @click="copyText(campaignLandingPageURL)">
                          <span class="icon is-small">
                            <i class="fas fa-paste"/>
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showScheduled"
        class="columns">
        <div id="confirmation-page-section-scheduled" class="column">
          <div class="notification oto-confirmation-notification">
            <div class="columns">
              <div class="column">
                <PostCard
                    v-model="vuexWizard.wizardPostContent"
                    :account="currentAccount"/>
              </div>
              <div class="column">
                <div class="level is-mobile">
                  <div class="level-left">
                    <div class="level-item">
                      <h3 class="subtitle-w-bar title is-5" style="margin-top:0;">Scheduled Posts</h3>
                    </div>
                    <div class="level-item">
                      <span class="icon is-medium has-text-primary">
                        <i class="fas fa-2x fa-check-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="content">
                  <p>This marketing message will automatically be posted to your integrated channels.</p>
                  <div class="columns is-mobile no-bottom-margin">
                    <div class="column is-narrow">
                      <p class="has-text-weight-bold">Date:</p>
                    </div>
                    <div class="column">
                      <p>{{ campaignSendDate }}</p>
                    </div>
                  </div>
                  <div class="columns is-mobile">
                    <div class="column is-narrow">
                      <p class="has-text-weight-bold">Channels:&nbsp;</p>
                    </div>
                    <div class="column is-narrow">
                      <div
                        v-if="campaignChannels.email && accountEmailSettings.id === 'cinch' && !accountEmailSettings.integrationMode"
                        class="level is-marginless is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <span class="icon">
                              <i class="fas fa-envelope"></i>
                            </span>
                          </div>
                          <div class="level-item">
                            <div>Email</div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="campaignChannels.website"
                        class="level is-marginless is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <span class="icon">
                              <i class="fas fa-globe"></i>
                            </span>
                          </div>
                          <div class="level-item">
                            <div>Website</div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="campaignChannels.app"
                        class="level is-marginless is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <span class="icon">
                              <i class="fas fa-mobile"></i>
                            </span>
                          </div>
                          <div class="level-item">
                            <div>App</div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="campaignChannels.facebook"
                        class="level is-marginless is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <span class="icon">
                              <i class="fab fa-facebook"></i>
                            </span>
                          </div>
                          <div class="level-item">
                            <div>Facebook</div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="campaignChannels.twitter"
                        class="level is-marginless is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <span class="icon">
                              <i class="fab fa-twitter"></i>
                            </span>
                          </div>
                          <div class="level-item">
                            <div>Twitter</div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="campaignChannels.google"
                        class="level is-marginless is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <span class="icon">
                              <i class="fab fa-google"></i>
                            </span>
                          </div>
                          <div class="level-item">
                            <div>Google</div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="campaignChannels.instagram"
                        class="level is-marginless is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <span class="icon">
                              <i class="fab fa-instagram"></i>
                            </span>
                          </div>
                          <div class="level-item">
                            <div>Instagram</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showEmailHTML"
        class="columns">
        <div id="confirmation-page-section-emailhtml" class="column">
          <div class="notification oto-confirmation-notification">
            <div class="columns">
              <div class="column has-text-centered">
                <div class="oto-thumbnail-container">
                  <div class="oto-thumbnail oto-pdf-holder">
                    <iframe
                      frameBorder="0"
                      id="emailiframe"
                      onload="this.style.opacity = 1"
                      :srcdoc="emailHTML"></iframe>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="level is-mobile">
                  <div class="level-left">
                    <div class="level-item">
                      <h3 class="subtitle-w-bar title is-5" style="margin-top:0;">Email Marketing</h3>
                    </div>
                    <div class="level-item">
                      <span class="icon is-medium has-text-primary">
                        <i class="fas fa-2x fa-check-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="content">
                  <p>CampaignPilot has created an email newsletter for this campaign. You can copy the HTML for this email by clicking the button below. Then paste the HTML into your email marketing software.</p>
                  <p>
                    <a
                      class="button is-primary is-rounded"
                      @click="copyText(emailHTML, true)">
                      <span class="icon is-small">
                        <i class="fas fa-file-code"></i>
                      </span>
                      <span>Copy Email HTML</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showPrint"
        class="columns">
        <div id="confirmation-page-section-print" class="column">
          <div class="notification oto-confirmation-notification">
            <div class="columns">
              <div class="column has-text-centered">
                <button
                  v-if="waitingForPDF"
                  class="button is-loading is-large pdf-loading"></button>
                <div
                  v-else
                  class="oto-thumbnail-container">
                  <div class="oto-thumbnail oto-pdf-holder">
                    <iframe
                      :src="flyerURL"
                      frameborder="0"
                      onload="this.style.opacity = 1"></iframe>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="level is-mobile">
                  <div class="level-left">
                    <div class="level-item">
                      <h3 class="subtitle-w-bar title is-5" style="margin-top:0;">Print Marketing</h3>
                    </div>
                    <div class="level-item">
                      <span class="icon is-medium has-text-primary">
                        <i class="fas fa-2x fa-check-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="content">
                  <p>CampaignPilot has also created the following print materials for you to display and advertise around your business. They're ready to go, just open and hit print.</p>
                  <p>
                  <a
                    :href="flyerURL"
                    target="_blank"
                    class="button is-primary is-rounded"
                    :disabled="waitingForPDF">
                    <span class="icon is-small">
                      <i class="fas fa-file-image"></i>
                    </span>
                    <span>Full Page Flyer</span>
                  </a>
                  </p>
                  <p>
                  <a
                    :href="cartsignURL"
                    target="_blank"
                    class="button is-primary is-rounded"
                    :disabled="waitingForPDF">
                    <span class="icon is-small">
                      <i class="fas fa-truck-pickup"></i>
                    </span>
                    <span>Cart Signs</span>
                  </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { parseISO, format } from 'date-fns'
import { mapGetters } from 'vuex'
import utils from '@/plugins/utils.js'
import wizard from '@/mixins/Wizard.js'
import tour from '@/mixins/Tour.js'
import PostCard from '@/components/elements/PostCard'

export default {
  mixins: [wizard, tour],
  components: {
    PostCard
  },
  data () {
    return {
      confirmationPhrases: [
        'Flight attendants, prepare for landing.',
        'Folks, we\'re clear for landing.',
        'Thank you for flying with CampaignPilot.'
      ],
      waitingForPDF: true,
      pdfWaitTime: 6 // in seconds
    }
  },
  computed: {
    confirmationPhrase: function () {
      return this.confirmationPhrases[Math.floor(Math.random() * this.confirmationPhrases.length)]
    },
    campaignTitle: function () {
      if (this.vuexWizard) {
        if (this.vuexWizard.wizardPostContent) {
          if (this.vuexWizard.wizardPostContent.words) {
            if (this.vuexWizard.wizardPostContent.words.variables) {
              return this.vuexWizard.wizardPostContent.words.variables.title
            }
          }
        }
      }
      return null
    },
    campaignAction: function () {
      if (this.vuexWizard) {
        if (this.vuexWizard.wizardAction && this.vuexWizard.wizardSubtype) {
          var a = this.vuexWizard.wizardAction.callToAction
          var s = this.vuexWizard.wizardSubtype
          // action (a), subtype (s)
          // sale, paid
          // sale, info
          // event, paid
          // event, info
          // teetime, paid
          // teetime, engine
          // teetime, info
          if (a === 'sale') {
            if (s === 'paid') return 'purchase your product online'
            else return 'learn more about your product'
          } else if (a === 'event') {
            if (s === 'paid') return 'register and purchase tickets online'
            else return 'learn more about your event'
          } else if (a === 'teetime') {
            if (s === 'paid') return 'purchase a tee time voucher'
            else if (s === 'engine') return 'book a tee time online'
            else return 'learn more about your golf promotion'
          }
          return 'get more information'
        }
      }
      return null
    },
    campaignLandingPageURL: function () {
      if (this.vuexWizard) {
        if (this.vuexWizard.wizardSlug) {
          return process.env.VUE_APP_LANDING_PAGE_IFRAME_DOMAIN + '/' + this.vuexWizard.wizardSlug
        }
      }
      return null
    },
    campaignAssetURL: function () {
      if (this.vuexWizard) {
        if (this.vuexWizard.wizardAssets) {
          if (this.vuexWizard.wizardAssets.key) {
            return process.env.VUE_APP_CONTENT_URL + this.vuexWizard.wizardAssets.key
          }
        }
      }
      return null
    },
    flyerURL: function () {
      if (this.showPrint) {
        if (this.vuexWizardPostRender.flyer) return this.vuexWizardPostRender.flyer
      }
      return null
    },
    cartsignURL: function () {
      if (this.showPrint) {
        if (this.vuexWizardPostRender.cartsign) return this.vuexWizardPostRender.cartsign
      }
      return null
    },
    emailHTML: function () {
      if (this.vuexWizardPostRender) if (this.vuexWizardPostRender.emailHTML) return this.vuexWizardPostRender.emailHTML
      return null
    },
    campaignChannels: function () {
      var channels = {
        facebook: false,
        twitter: false,
        website: false,
        email: false,
        app: false,
        google: false,
        instagram: false
      }
      if (this.vuexWizard) {
        if (this.vuexWizard.wizardSchedule) {
          if (this.vuexWizard.wizardSchedule.channels) {
            if (this.vuexWizard.wizardSchedule.channels.facebook) channels.facebook = true
            if (this.vuexWizard.wizardSchedule.channels.twitter) channels.twitter = true
            if (this.vuexWizard.wizardSchedule.channels.website) channels.website = true
            if (this.vuexWizard.wizardSchedule.channels.email) channels.email = true
            if (this.vuexWizard.wizardSchedule.channels.app) channels.app = true
            if (this.vuexWizard.wizardSchedule.channels.google) channels.google = true
            if (this.vuexWizard.wizardSchedule.channels.instagram) channels.instagram = true
          }
        }
      }
      return channels
    },
    campaignSendDate: function () {
      if (this.vuexWizard) {
        if (this.vuexWizard.wizardSchedule) {
          if (this.vuexWizard.wizardSchedule.date) {
            var jDate = parseISO(this.vuexWizard.wizardSchedule.date)
            if (jDate) {
              // Sunday, January 1st, 2024 at 8:00PM
              return format(jDate, 'EEEE, MMMM do, yyyy') + ' at ' + this.vuexWizard.wizardSchedule.time
            }
          }
        }
      }
      return null
    },
    accountEmailSettings: function () {
      var email = {
        provider: 'none configured',
        url: null
      }
      if (this.currentAccountChannels) {
        if (this.currentAccountChannels.length > 0) {
          this.currentAccountChannels.forEach(function (channel) {
            if (channel) {
              if (channel.type === 'email') {
                if (channel.id === 'acy') {
                  email.provider = '1-2-1 Marketing'
                  email.id = 'acy'
                  email.url = channel.name + '/index.php?option=com_campaignpilot&type=newsletter'
                } else if (channel.id === 'cinch') {
                  email.provider = 'Cinch'
                  email.id = 'cinch'
                  email.url = 'https://marketing.cinch.io/'
                  var options = JSON.parse(channel.options)
                  email.integrationMode = options.integrationMode
                }
              }
            }
          })
        }
      }
      return email
    },
    showScheduled: function () {
      for (var channel in this.campaignChannels) {
        if (this.campaignChannels.hasOwnProperty(channel)) {
          if (this.campaignChannels[channel] && channel !== 'email') return true // there is an active channel other than email
          else if (this.postToCinch) return true // email channel is active to cinch broadcast mode
        }
      }
      return false // no channel that would be scheduled/posted
    },
    showLanding: function () {
      if (this.campaignLandingPageURL) return true
      else return false
    },
    showEmailMarketing: function () {
      // section which reminds users that they need to manually send the email
      if (this.accountEmailSettings.id === 'cinch' && this.accountEmailSettings.integrationMode && this.campaignChannels.email) return true // cinch (template only mode)
      else if (this.accountEmailSettings.id === 'acy' && this.accountEmailSettings.url && this.campaignChannels.email) return true // acy draft newsletter mode
      return false
    },
    showAsset: function () {
      if (this.campaignAssetURL) return true
      else return false
    },
    showPrint: function () {
      if (this.vuexWizardPostRender) {
        if (this.vuexWizardPostRender.flyer || this.vuexWizardPostRender.cartsign) return true
      }
      return false
    },
    showEmailHTML: function () {
      if (this.emailHTML && !this.campaignChannels.email) return true // show if email channel is off
      return false
    },
    licenseName: function () {
      if (this.currentUser) if (this.currentUser.name) return this.currentUser.name
      return 'Your Name'
    },
    licenseBusinessName: function () {
      if (this.currentAccount) if (this.currentAccount.name) return this.currentAccount.name
      return 'Your Business'
    },
    licenseDate: function () {
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      var date = new Date()
      return date.getFullYear() + '-' + months[date.getMonth()] + '-' + ('0' + date.getDate()).slice(-2)
    },
    postToCinch: function () {
      if (this.accountEmailSettings.id === 'cinch' && !this.accountEmailSettings.integrationMode && this.campaignChannels.email) return true
      return false
    },
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentUser: 'currentUser',
      currentAccountChannels: 'currentAccountChannels',
      vuexWizardPostRender: 'wizardPostRender',
      vuexWizard: 'fullWizard'
    })
  },
  mounted () {
    this.$store.dispatch('setWizardStep', 10)
    this.waitForPDF()
    // Kickoff the Tour
    if (this.guideMode) this.TourConfirmation({ showButtons: true })
  },
  created () {
    document.addEventListener('click', this.clickMethod)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickMethod)
  },
  methods: {
    waitForPDF () {
      var vm = this
      setTimeout(function () {
        vm.waitingForPDF = false
      }, vm.pdfWaitTime * 1000)
    },
    copyText (text, isEmail) {
      var vm = this
      if (isEmail) {
        text = text.replace('<!-- **** ', '')
        text = text.replace(' **** -->', '')
      }
      utils.copyToClipboard(text, function (err, copied) {
        if (err) {}
        if (copied) {
          vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
          if (isEmail) vm.$store.dispatch('setNotify', { state: true, text: 'The HTML for your marketing email has been copied to your clipboard. Paste this into your email software.' })
          else vm.$store.dispatch('setNotify', { state: true, text: 'The Landing Page URL has been copied to your clipboard.' })
        }
      })
    },
    clickMethod (event) {
      if (event) {
        if (event.target) {
          if (event.target.id === 'tour-exit-button') {
            if (this.guideMode) this.TourDone()
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.pdf-loading {
  background-color: whitesmoke;
  border-color: whitesmoke;
  font-size: 6em!important;
}
.oto-conf-page-box {
  display: flex;
}
.card {
  margin-left: auto;
  margin-right: auto;
}
.link-area {
  background-color: #f6f7f9;
  padding: 10px;
}
.client-name {
  color: #365899;
}
.card .header {
  padding: 5px 10px;
}
.oto-confirmation-notification {
  padding: 3rem;
}
.oto-fake-profile-image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #c4c4c4;
  border: 3px solid #c4c4c4;
  color: #fff;
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  padding-top: 3px;
}
.oto-thumbnail-container {
  width: calc(700px * 0.40);
  height: calc(800px * 0.40);
  display: inline-block;
  overflow: hidden;
  position: relative;
  background: #f9f9f9;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.oto-thumbnail {
  -ms-zoom: 0.40;
  -moz-transform: scale(0.40);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.40);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.40);
  -webkit-transform-origin: 0 0;
}
.oto-thumbnail,
.oto-thumbnail iframe {
  width: 700px;
  height: 800px;
}
.oto-thumbnail iframe {
  opacity: 0;
  transition: all 300ms ease-in-out;
}
.oto-thumbnail:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.oto-pdf-holder iframe {
  position: absolute!important;
  top: -75px!important;
  left: 0!important;
  width: 720px!important;
  max-width: 720px!important;
  height: 900px!important;
}
</style>

<template>
  <div
    v-if="loaded"
    class="content">
    <div class="right-column-text">
      <div class="subtitle-w-bar">
        <span v-if="mode === 'booking-engine'">Edit Booking Engine</span>
        <span v-else>Edit IFrame</span>
      </div>
      <div
        v-if="mode !== 'booking-engine'"
        class="field">
        <label class="label">IFrame URL</label>
        <div class="control has-icons-left">
          <span class="icon is-small is-left">
            <i class="fas fa-globe"/>
          </span>
          <input
            v-model="url"
            type="text"
            class="input"
            placeholder="https://www.url.com/">
        </div>
      </div>
      <div class="field">
        <label class="label">Width</label>
        <div class="control">
          <div class="columns is-mobile">
            <div class="column">
              <vue-slider
                v-model="width"
                v-bind="widthOptions"/>
            </div>
            <div class="column is-one-fifth">
              <Toggler
                :props="widthModeToggle"
                v-model="widthModeToggle.model"/>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">Height</label>
        <div class="control">
          <div class="columns is-mobile">
            <div class="column">
              <vue-slider
                v-model="height"
                v-bind="heightOptions"></vue-slider>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">Align</label>
        <div class="field has-addons">
          <p class="control">
            <a
              :class="{'is-primary': align === 'left'}"
              class="button is-rounded"
              @click="setAlign('l')">
              <span class="icon is-small">
                <i class="fas fa-align-left"></i>
              </span>
              <span>Left</span>
            </a>
          </p>
          <p class="control">
            <a
              :class="{'is-primary': align === 'center'}"
              class="button is-rounded"
              @click="setAlign('c')">
              <span class="icon is-small">
                <i class="fas fa-align-center"></i>
              </span>
              <span>Center</span>
            </a>
          </p>
          <p class="control">
            <a
              :class="{'is-primary': align === 'right'}"
              class="button is-rounded"
              @click="setAlign('r')">
              <span class="icon is-small">
                <i class="fas fa-align-right"></i>
              </span>
              <span>Right</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import Toggler from '@/components/elements/Toggler'

export default {
  components: {
    VueSlider,
    Toggler
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: function () { return {} }
    }
  },
  data () {
    return {
      loaded: false,
      mode: null,
      height: 500,
      width: 100,
      url: null,
      align: 'center',
      widthOptions: {
        min: 1,
        max: 100,
        formatter: '{value}%',
        processClass: 'oto-slider-process',
        tooltipClass: 'oto-slider-tooltip'
      },
      heightOptions: {
        min: 200,
        max: 1000,
        formatter: '{value}px',
        processClass: 'oto-slider-process',
        tooltipClass: 'oto-slider-tooltip'
      },
      widthModeToggle: {
        model: false, // false = %, true = px
        name: 'widthToggle',
        ref: 'widthToggle',
        options: { checked: 'px', unchecked: '%' },
        optionColors: { checked: '#24C980', unchecked: '#24C980' },
        height: 30,
        width: 60,
        hideBuffer: true
      }
    }
  },
  computed: {
    iframe: function () {
      if (this.url) {
        var i = {
          mode: this.mode,
          width: this.width,
          height: this.height,
          url: this.url,
          align: this.align,
          widthMode: this.widthModeToggle.model,
          finalHeight: this.height + 'px'
        }
        if (this.widthModeToggle.model) i.finalWidth = this.width + 'px'
        else i.finalWidth = this.width + '%'
        return i
      } else {
        return null
      }
    }
  },
  watch: {
    'widthModeToggle.model' (val) {
      this.toggleWidthMode()
    },
    iframe: {
      handler: function (val) {
        this.$emit('input', this.iframe)
      },
      deep: true
    }
  },
  mounted () {
    if (this.value) {
      if (this.value.height) this.height = this.value.height
      if (this.value.width) this.width = this.value.width
      if (this.value.url) this.url = this.value.url
      if (this.value.align) this.align = this.value.align
      if (this.value.widthMode) this.widthModeToggle.model = this.value.widthMode
      if (this.value.mode) this.mode = this.value.mode
    }
    this.loaded = true
  },
  methods: {
    toggleWidthMode () {
      if (this.widthModeToggle.model) {
        // pixel mode (true)
        if (this.width < 200 || this.width > 1000) this.width = 500
        this.widthOptions.min = 200
        this.widthOptions.max = 1000
        this.widthOptions.formatter = '{value}px'
      } else {
        // percent mode (false)
        if (this.width < 1 || this.width > 100) this.width = 100
        this.widthOptions.min = 1
        this.widthOptions.max = 100
        this.widthOptions.formatter = '{value}%'
      }
    },
    setAlign (a) {
      if (a === 'l') this.align = 'left'
      if (a === 'c') this.align = 'center'
      if (a === 'r') this.align = 'right'
    }
  }
}
</script>

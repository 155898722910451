<template>
<div>
  <label class="label" :class="props.labelClass" v-if="props.label">{{props.label}}</label>
  <div v-if="selectedItemLabel" class="selectedItemText">
    {{selectedItemLabel}}
    <a
        class="delete"
        @click="reset"></a>
  </div>
  <div v-else>
    <div class="field has-addons">
      <div
        :class="{'is-loading': isSearching}"
        class="control has-icons-left full-width-select">
        <span class="icon is-small is-left">
          <i class="fas fa-search"></i>
        </span>
        <input
          :placeholder="props.searchplaceholder"
          :name="props.name"
          :id="props.ref"
          :ref="props.ref"
          :key="props.ref"
          :disabled="isSearching"
          @focus="onFocus"
          v-model.lazy="searchvalue"
          class="input is-fullwidth"
          type="text">
      </div>
      <div class="control" v-if="props.display !== 'small'">
        <a
          class="button is-primary is-rounded">
          Search
        </a>
      </div>
    </div>
    <div
      v-if="salesItems.options.length > 0"
      class="field">
      <Select
        :props="salesItems"
        v-model="salesItems.model" />
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Select from '@/components/elements/Select'
import channelApi from '@/plugins/channel-api.js'
import xhr from '@/mixins/Xhr.js'
import inputs from '@/mixins/Inputs.js'

export default {
  props: {},
  mixins: [inputs, xhr],
  components: {
    Select
  },
  data () {
    return {
      searchvalue: null,
      isSearching: false,
      selectedItemLabel: null,
      salesItems: {
        model: null,
        name: 'items',
        ref: 'items',
        options: [],
        icon: 'fas fa-tag',
        class: 'is-fullwidth',
        desktopHelp: '...',
        mobileHelp: '...'
      }
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    })
  },
  watch: {
    searchvalue: {
      handler: function (val, oval) {
        if (val && (val !== oval)) this.fetchSalesItems()
      }
    },
    'salesItems.model': {
      handler: function (val) {
        if (val) {
          if (typeof val === 'string') val = JSON.parse(val)
          this.selectedItemLabel = `${val.attributes.name} : ${val.attributes.department} : ${val.attributes.category} : $${val.attributes.basePrice}`
          this.$emit('input', val)
        }
      }
    },
    'props.model': {
      handler: function (val, oval) {
        if (val) {
          this.salesItems.model = val
        } else if (oval) {
          this.reset() // reset if the model is cleared
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    fetchSalesItems: function () {
      var vm = this
      this.isSearching = true
      channelApi.foreupFetchSalesItems(vm.currentAccount.id, vm.searchvalue,
        function (err, items) {
          vm.isSearching = false
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.buildSalesItemsOption(items)
          }
        }
      )
    },
    buildSalesItemsOption: function (itemsFromforeUP) {
      if (itemsFromforeUP) {
        var items = []
        itemsFromforeUP.forEach(function (foreUPItem) {
          var selectName = `${foreUPItem.attributes.name} : ${foreUPItem.attributes.department} : ${foreUPItem.attributes.category} : $${foreUPItem.attributes.basePrice}`
          items.push({ name: selectName, value: JSON.stringify(foreUPItem) })
        })
        this.salesItems.options = items
      }
    },
    reset () {
      this.searchvalue = null
      this.selectedItemLabel = null
      this.salesItems.model = null
      this.salesItems.options = []
      this.props.model = null
    }
  }
}
</script>

<style lang="scss" scoped>
.selectedItemText {
  margin-bottom: 1rem !important;
}
</style>

<template>
  <div class="field">
    <label
      v-if="props.label"
      class="label">
      <span v-if="props.label === '&nbsp;'">&nbsp;</span>
      <span v-else>{{ props.label }}</span>
      <span
        v-if="isRequired"
        class="icon has-text-danger is-small oto-required-field">
        <i class="fas fa-star-of-life oto-fa-mini"/>
      </span>
    </label>
    <div calss="field-body">
      <div class="field has-addons">
        <div class="control">
          <div class="select">
            <select
              v-model="props.select.model"
              :name="props.select.name"
              :ref="props.select.ref"
              @blur="onBlur()"
              @focus="onFocus()"
              @input="$emit('input', $event.target.value)">
              <option
                v-for="(option, index) in props.select.options"
                :key="index"
                :value="option.value">
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
        <div
          :class="{ 'has-icons-left': props.input.icon }"
          class="control full-width-select">
          <input
            v-validate="props.validate"
            v-model="props.input.model"
            :placeholder="props.input.placeholder"
            :name="props.input.name"
            :ref="props.input.ref"
            :data-vv-as="props.label && !props.useNameInError ? props.label : props.input.name"
            :class="{ 'is-danger': errors.has(props.input.name) }"
            class="input"
            type="text"
            @blur="onBlur()"
            @focus="onFocus()"
            @input="$emit('input', $event.target.value)">
          <span
            v-if="props.input.icon"
            class="icon is-small is-left">
            <i :class="props.input.icon"/>
          </span>
        </div>
      </div>
    </div>
    <span
      v-if="errors.has(props.input.name)"
      class="has-text-danger animated fadeInDown sentence-case">
      {{ errors.first(props.input.name) }}
    </span>
  </div>
</template>

<script>
import inputs from '@/mixins/Inputs.js'

export default {
  mixins: [inputs]
}
</script>

<template>
  <div>
    <main class="oto-main">
      <div class="oto-side-background is-hidden-mobile"/>
      <div class="oto-main-container container">
        <div class="oto-duo">
          <div class="oto-lead oto-iframe-mobile-fix">
            <!-- Left Column -->
            <div id="left-column">
              <hr class="oto-mobile-break is-hidden-tablet">
              <div class="step-heading is-hidden-mobile">
                <h2 class="subtitle uppercase">
                  Campaign: {{ vuexWizardTitle }}
                </h2>
                <h1 class="title">
                  Customize Your Page
                </h1>
              </div>
              <div
                :style="iframeStyle"
                id="landing-page-iframe-holder"
                class="iframe-holder">
                <iframe
                  id="iframe"
                  :src="iframeUrl"
                  scrolling="no"
                  allowfullscreen
                  @load="iframeLoaded"/>
              </div>
            </div>
          </div>
          <!-- DEFAULT SIDEBAR -->
          <aside
            v-if="sidebar === 'default'"
            class="oto-side">
            <affix
              class="sidebar-menu"
              relative-element-selector="#left-column"
              :enabled="stickyEnabled">
              <div class="step-heading is-hidden-tablet">
                <h2 class="subtitle uppercase">
                  Campaign: {{ vuexWizardTitle }}
                </h2>
                <h1 class="title">
                  Customize Your Page
                </h1>
              </div>
              <div>
                <div class="right-column-text">
                  Here is your campaign landing page. We'll automatically link all your promotional messaging to this page.
                </div>
                <div class="subtitle-w-bar">Select a layout</div>
                <div
                  id="landing-page-layout-select"
                  class="columns is-multiline is-mobile questions">
                  <div class="column is-third is-question">
                    <div
                      :class="{ 'is-selected-border': layout === 'simple', 'oto-hover': layout !== 'simple' }"
                      class="oto-box no-border has-background-white"
                      @click="setLayout('simple')">
                      <div class="title-holder is-capitalized">
                        <div class="icon is-small has-text-grey-darker is-hidden">
                          <i class="fas fa-user"/>
                        </div>
                        <div class="question-name">Layout One</div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-third is-question">
                    <div
                      :class="{ 'is-selected-border': layout === 'detail', 'oto-hover': layout !== 'detail' }"
                      class="oto-box no-border has-background-white"
                      @click="setLayout('detail')">
                      <div class="title-holder is-capitalized">
                        <div class="icon is-small has-text-grey-darker is-hidden">
                          <i class="fas fa-user"/>
                        </div>
                        <div class="question-name">Layout Two</div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-third is-question">
                    <div
                      :class="{ 'is-selected-border': layout === 'column', 'oto-hover': layout !== 'column' }"
                      class="oto-box no-border has-background-white"
                      @click="setLayout('column')">
                      <div class="title-holder is-capitalized">
                        <div class="icon is-small has-text-grey-darker is-hidden">
                          <i class="fas fa-user"/>
                        </div>
                        <div class="question-name">Layout Three</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="subtitle-w-bar">Select an image</div>
                <div
                  v-if="imagesAvailable"
                  id="landing-page-image-select"
                  class="columns is-multiline is-mobile questions">
                  <div
                    v-for="(image, index) in imagesAvailable.landscape"
                    :key="index"
                    class="column is-image">
                    <div
                      :class="{ 'is-selected-border': selectedImage === index, 'oto-hover': imagesAvailable.indexes[0] !== index }"
                      class="no-border has-background-white">
                      <div class="title-holder">
                        <figure
                          :id="'image-' + index"
                          class="image is-16by9 show-hand"
                          @click="changeImage(index)">
                          <img v-lazy.container="image"/>
                          <div v-lazy:background-image.container="image"/>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!guideMode"
                    class="column is-image">
                    <div
                      class="oto-box no-border has-background-white oto-hover"
                      @click="openUnsplash">
                      <div class="title-holder is-capitalized">
                        <div class="question-name">
                          <img src="../../../../../assets/imgs/other/unsplash-logo-alt.png" class="is-hidden-mobile">
                          <img src="../../../../../assets/imgs/other/unsplash-logo-small.png" class="is-hidden-tablet unsplash-logo-mini">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!guideMode"
                    class="column is-image">
                    <div
                      class="oto-box no-border has-background-white oto-hover"
                      @click="openImageUploader">
                      <div class="title-holder is-capitalized">
                        <span class="icon is-small"><i class="fas fa-upload"></i></span>
                        <div class="question-name">
                          <span class="is-hidden-mobile">Upload</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="subtitle-w-bar">Add a component</div>
                <div class="columns is-multiline is-mobile questions">
                  <div
                    id="landing-page-edit-form-button"
                    class="column is-half is-question"
                    @click="editForm()">
                    <div class="oto-box no-border has-background-white oto-hover">
                      <div class="title-holder is-capitalized">
                        <div class="icon is-small has-text-grey-darker is-hidden">
                          <i class="fas fa-user"/>
                        </div>
                        <div class="question-name">{{ formText }}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="column is-half is-question"
                    @click="editIFrame()">
                    <div class="oto-box no-border has-background-white oto-hover">
                      <div class="title-holder is-capitalized">
                        <div class="icon is-small has-text-grey-darker is-hidden">
                          <i class="fas fa-user"/>
                        </div>
                        <div class="question-name">{{ iframeText }}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="column is-half is-question"
                    @click="editButton()">
                    <div class="oto-box no-border has-background-white oto-hover">
                      <div class="title-holder is-capitalized">
                        <div class="icon is-small has-text-grey-darker is-hidden">
                          <i class="fas fa-user"/>
                        </div>
                        <div class="question-name">{{ buttonText }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </affix>
          </aside>
          <!-- FORM SIDEBAR -->
          <aside
            v-if="sidebar === 'form'"
            class="oto-side">
            <affix
              class="sidebar-menu"
              relative-element-selector="#left-column"
              :enabled="stickyEnabled">
              <FormEditor
                v-model="questions"
                :selected="selectedQuestion"
                @refresh="postQuestions"
                @selected="updateSelected($event)"/>
              <p
                v-if="selectedQuestion<0"
                class="buttons">
                <button
                  class="button is-rounded"
                  @click="closeSidebar">
                  <span>Cancel</span>
                </button>
              </p>
            </affix>
          </aside>
          <!-- iFRAME SIDEBAR -->
          <aside
            v-if="sidebar === 'iframe'"
            class="oto-side">
            <affix
              class="sidebar-menu"
              relative-element-selector="#left-column"
              :enabled="stickyEnabled">
              <iFrameEditor
                v-model="embedIFrame"
                @input="postIFrame"/>
              <p class="buttons">
                <button
                  class="button is-rounded"
                  @click="closeSidebar">
                  <span>{{ iframeSaveText }}</span>
                </button>
              </p>
            </affix>
          </aside>
          <!-- BUTTON SIDEBAR -->
          <aside
            v-if="sidebar === 'button'"
            class="oto-side">
            <affix
              class="sidebar-menu"
              relative-element-selector="#left-column"
              :enabled="stickyEnabled">
              <ButtonEditor
                v-model="embedButton"
                @input="postButton"/>
              <p class="buttons">
                <button
                  class="button is-rounded"
                  @click="closeSidebar">
                  <span>Save Button</span>
                </button>
              </p>
            </affix>
          </aside>
        </div>
      </div>
    </main>
    <Navigation v-model="navigation"/>
    <ImageUploader
      :iu="iu"
      v-model="uploadedImage"/>
    <UnsplashPicker
      :unsplash="unsplash"
      v-model="unsplashImage"/>
  </div>
</template>

<script>
import { Affix } from 'vue-affix'
import wizard from '@/mixins/Wizard.js'
import words from '@/mixins/Words.js'
import tour from '@/mixins/Tour.js'
import engineApi from '@/plugins/engine-api.js'
import { mapGetters } from 'vuex'
import ImageUploader from '@/components/elements/ImageUploader'
import UnsplashPicker from '@/components/elements/UnsplashPicker'
import FormEditor from '@/components/dashboard/campaigns/wizard/sections/landingpage/FormEditor'
import iFrameEditor from '@/components/dashboard/campaigns/wizard/sections/landingpage/iFrameEditor'
import ButtonEditor from '@/components/dashboard/campaigns/wizard/sections/landingpage/ButtonEditor'
import xhr from '@/mixins/Xhr.js'

export default {
  components: {
    FormEditor,
    ImageUploader,
    UnsplashPicker,
    iFrameEditor,
    ButtonEditor,
    Affix
  },
  mixins: [wizard, words, xhr, tour],
  data () {
    return {
      iframeUrl: null,
      iframeDomain: process.env.VUE_APP_LANDING_PAGE_IFRAME_DOMAIN,
      layout: 'simple',
      iframeStyle: {
        display: 'none',
        height: '0px'
      },
      imagesAvailable: null,
      accountImages: null,
      iu: {
        modalOpen: false,
        mode: 'campaign',
        allowedRatios: ['landing page'],
        defaultRatio: 'landing page',
        format: 'image/jpeg',
        compress: 0.99
      },
      unsplash: {
        modalOpen: false,
        searchTerm: null,
        numResults: 20
      },
      unsplashImage: null,
      uploadedImage: null,
      uploadedImages: [],
      iframeIsLoaded: null,
      richContent: null,
      sidebar: 'default',
      selectedQuestion: -1,
      selectedImage: 0,
      questions: null,
      stickyEnabled: true,
      embedIFrame: null,
      embedButton: null
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      vuexTheme: 'wizardTheme',
      vuexLandingPage: 'wizardLandingPage',
      vuexAction: 'wizardAction',
      vuexSuggestedForm: 'wizardSuggestedForm',
      vuexWizard: 'fullWizard',
      vuexEditId: 'editId',
      vuexCampaignRichContent: 'campaignRichContent'
    }),
    showPlaceholder: function () {
      var f = null
      if (this.questions && this.selectedQuestion) {
        if (this.questions.length) {
          f = this.questions[this.selectedQuestion].type
        }
      }
      if (f) {
        if (f === 'grouped-field' || f === 'option-group') {
          return false
        }
      }
      return true
    },
    formText: function () {
      if (this.questions) {
        if (this.questions.length > 0) {
          if (this.vuexWizardSubtype === 'paid') {
            return 'Add Custom Field'
          } else {
            return 'Add Field to Form'
          }
        }
      }
      if (this.vuexWizardSubtype === 'paid') {
        return 'Add Custom Field'
      } else {
        return 'Add a Form'
      }
    },
    iframeText: function () {
      if (this.embedIFrame) {
        if (this.embedIFrame.url) {
          if (this.embedIFrame.mode === 'booking-engine') {
            return 'Edit Booking Engine'
          }
          return 'Edit IFrame'
        }
      }
      return 'Add an IFrame'
    },
    iframeSaveText: function () {
      if (this.embedIFrame) {
        if (this.embedIFrame.mode === 'booking-engine') {
          return 'Save Booking Engine'
        }
      }
      return 'Save IFrame'
    },
    buttonText: function () {
      if (this.embedButton) {
        if (this.embedButton.url) {
          return 'Edit Button'
        }
      }
      return 'Add a Button'
    }
  },
  watch: {
    layout (val) {
      if (val === 'simple') {
        this.iframeUrl = this.iframeDomain + '/edit-simple'
        this.iframeIsLoaded = false
      } else if (val === 'detail') {
        this.iframeUrl = this.iframeDomain + '/edit-detail'
        this.iframeIsLoaded = false
      } else if (val === 'column') {
        this.iframeUrl = this.iframeDomain + '/edit-column'
        this.iframeIsLoaded = false
      }
      // Continue the Tour
      if (this.guideMode) this.TourLandingPageImageSelect()
    },
    iframeIsLoaded (val) {
      if (val) {
        this.$store.dispatch('setLoadingState', false)
      } else {
        this.$store.dispatch('setLoadingState', true)
      }
    },
    questions: {
      handler: function (val) {
        this.postQuestions()
      },
      deep: true
    },
    selectedQuestion (val) {
      if (val > -1) {
        this.sidebar = 'form'
        // Continue the Tour
        if (this.guideMode) this.TourLandingPageReview({ showButtons: true })
      } else {
        this.sidebar = 'default'
      }
      this.postSelected(val)
    },
    selectedImage (val) {
      if (val) {
        // Continue the Tour
        if (this.guideMode) this.TourLandingPageForm()
      }
    },
    sidebar (val) {
      if (val) {
        // Continue the Tour
        if (val === 'form' && this.guideMode) this.TourLandingPageFormAddEmail()
      }
    },
    'uploadedImage.url' (val) {
      if (val) {
        this.uploadImage('file', val)
      }
    },
    'unsplashImage.urls' (val) {
      if (val) {
        this.uploadImage('url', val.raw)
      }
    }
  },
  mounted () {
    this.$store.dispatch('setWizardStep', 7)
    this.iframeIsLoaded = false
    if (this.vuexLandingPage) {
      this.iframeUrl = process.env.VUE_APP_LANDING_PAGE_IFRAME_URL // set the url to start loading
      if (process.env.NODE_ENV === 'development') console.log('need to load into step: ' + JSON.stringify(this.vuexLandingPage))
      if (this.vuexLandingPage.layout) this.layout = this.vuexLandingPage.layout
      if (this.vuexLandingPage.words) this.words = this.$lodash.cloneDeep(this.vuexLandingPage.words)
      if (this.vuexLandingPage.imagesAvailable) {
        this.imagesAvailable = this.$lodash.cloneDeep(this.vuexLandingPage.imagesAvailable)
      } else {
        this.generateImages()
      }
      if (this.vuexLandingPage.questions) this.questions = this.vuexLandingPage.questions
      if (this.vuexLandingPage.embedIFrame) this.embedIFrame = this.$lodash.cloneDeep(this.vuexLandingPage.embedIFrame)
      if (this.vuexLandingPage.embedButton) this.embedButton = this.$lodash.cloneDeep(this.vuexLandingPage.embedButton)
      if (this.vuexLandingPage.uploadedImages) this.uploadedImages = this.$lodash.cloneDeep(this.vuexLandingPage.uploadedImages)
      // set the selected image from memory
      if (this.imagesAvailable) {
        if (this.imagesAvailable.indexes) {
          this.selectedImage = this.imagesAvailable.indexes[0]
        }
      }
    } else {
      // this is a fresh step
      // the word mixin will handle generating the default phrases
      // now let's load in the default images
      this.generateImages()
      // on fresh steps, we can load in any suggested forms for the user
      if (this.vuexSuggestedForm) this.questions = this.vuexSuggestedForm
    }
    // if this is a teetime (booking engine) action and we have a URL
    // then create an iframe with the booking engine inside
    if (this.vuexAction) {
      if (this.vuexAction.callToAction === 'teetime') {
        if (this.vuexAction.actions) {
          if (this.vuexAction.actions.url) {
            if (this.embedIFrame) {
              // if we already have an iframe saved (from vuex)
              // then just override the URL
              this.embedIFrame.url = this.vuexAction.actions.fullUrl
            } else {
              // create a default iframe with the booking engine URL
              this.embedIFrame = {
                url: this.vuexAction.actions.fullUrl,
                height: 800,
                width: 100,
                finalHeight: '800px',
                finalWidth: '100%',
                widthMode: false,
                align: 'center',
                mode: 'booking-engine'
              }
            }
            this.postIFrame()
          }
        }
      }
    }
    // set default tags for unsplash
    if (this.vuexTheme) if (this.vuexTheme.tagsToFilter) if (this.vuexTheme.tagsToFilter.length > 0) this.unsplash.searchTerm = this.vuexTheme.tagsToFilter[0]
    // Sync Support For Rich Content
    if (this.vuexCampaignRichContent) this.richContent = this.vuexCampaignRichContent
    // check to toggle sticky (aka it needs to be off for mobile)
    this.checkToStick()
    // Kickoff the Tour
    if (this.guideMode) this.TourLandingPageLayout()
  },
  created () {
    window.addEventListener('message', this.messageListener)
    window.addEventListener('resize', this.checkToStick)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.messageListener)
    window.removeEventListener('resize', this.checkToStick)
  },
  methods: {
    sendConentToLayout () {
      if (process.env.NODE_ENV === 'development') console.log('sending content to landing page')
      this.postAction()
      this.postImages()
      this.postTheme()
      this.postWords()
      this.postQuestions()
      this.postAccount()
      this.postActions()
      this.postRichContent()
      this.postIFrame()
      this.postButton()
      this.iframeStyle.display = 'block'
    },
    messageListener (e) {
      if (e.origin !== this.iframeDomain) {
        // block messages from unknown sources
        if (process.env.NODE_ENV === 'development') console.log('landing page blocked message:', e.origin, this.iframeDomain, e.data)
      } else if (e.data) {
        var messagePayload = JSON.parse(e.data)
        if (process.env.NODE_ENV === 'development') console.log('Message Received From Layout: ', messagePayload)
        if (messagePayload.height) {
          // iframe height has changed
          this.iframeStyle.height = messagePayload.height + 'px'
          if (process.env.NODE_ENV === 'development') console.log('setting iframe height to: ' + this.iframeStyle.height)
        } else if (messagePayload.words) {
          this.words = messagePayload.words
        } else if (messagePayload.richContent) {
          this.richContent = messagePayload.richContent
        } else if (messagePayload.selectedQuestion > -2) {
          this.selectedQuestion = messagePayload.selectedQuestion
        }
      }
    },
    checkToStick () {
      if (this.guideMode) this.stickyEnabled = false // disable sticky sidebar in the tour
      else if (window.innerWidth >= 769) this.stickyEnabled = true
      else this.stickyEnabled = false
    },
    generateImages () {
      if (process.env.NODE_ENV === 'development') console.log('creating images')
      this.imagesAvailable = {
        landscape: this.vuexTheme.currentTheme.images.landscape,
        square: this.vuexTheme.currentTheme.images.square,
        indexes: [0, 0, 0, 0]
      }
      var vm = this
      engineApi.fetchImages(
        vm.currentAccount.id, 'image',
        function (err, response) {
          vm.iframeUrl = process.env.VUE_APP_LANDING_PAGE_IFRAME_URL // set the url to start loading
          if (err) {
            vm.handleXhrError(err)
          } else {
            var accountImages = {
              landscape: [],
              square: []
            }
            response.forEach(function (image) {
              if (image.type === 'square') {
                accountImages.square.push(image.url)
              } else {
                accountImages.landscape.push(image.url)
              }
            })
            vm.imagesAvailable.landscape = vm.imagesAvailable.landscape.concat(accountImages.landscape)
            vm.imagesAvailable.square = vm.imagesAvailable.square.concat(accountImages.square)
            // add any uploaded images if we have them
            if (vm.uploadedImages.length > 0) {
              vm.imagesAvailable.landscape = vm.uploadedImages.concat(vm.imagesAvailable.landscape)
            }
            vm.postImages()
          }
        }
      )
    },
    openImageManager () {
      this.im.open = true
      this.sidebar = 'nothing'
    },
    openImageUploader () {
      this.iu.modalOpen = true
    },
    openUnsplash () {
      this.unsplash.modalOpen = true
    },
    uploadImage (mode, url) {
      // mode = file or url
      if (mode === 'file') {
        this.image = {}
        this.$store.dispatch('setLoadingState', true)
        var vm = this
        engineApi.uploadImage(
          vm.currentAccount.id, url, 'campaign', vm.uploadedImage.type,
          function (err, response) {
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            }
            // add image to those available
            if (response) {
              if (response.url) {
                vm.imagesAvailable.landscape.unshift(response.url)
                vm.changeImage(0)
                vm.uploadedImages.push(response.url)
              }
            }
          }
        )
      } else if (mode === 'url') {
        var resizeSettings = '&w=2000&h=1125&fit=crop'
        url = url + resizeSettings
        this.imagesAvailable.landscape.unshift(url)
        this.changeImage(0)
        this.uploadedImages.push(url)
      }
    },
    editForm () {
      var resetForm = true
      if (this.questions) {
        if (this.questions.length > 0) {
          resetForm = false
        }
      }
      if (resetForm) {
        // SET THE DEFAULT QUESTIONS
        this.questions = []
      }
      this.sidebar = 'form'
    },
    closeSidebar () {
      this.sidebar = 'default'
      this.updateSelected(-1)
    },
    editIFrame () {
      this.sidebar = 'iframe'
    },
    editButton () {
      this.sidebar = 'button'
    },
    updateSelected (val) {
      this.selectedQuestion = val
    },
    postImages () {
      var messagePayload = {
        'imagesAvailable': this.imagesAvailable
      }
      this.postMessage(messagePayload)
    },
    postTheme () {
      var messagePayload = {
        'theme': this.vuexTheme
      }
      this.postMessage(messagePayload)
    },
    postWords () {
      var messagePayload = {
        'words': this.words
      }
      this.postMessage(messagePayload)
    },
    postRichContent () {
      var messagePayload = {
        'richContent': this.richContent
      }
      this.postMessage(messagePayload)
    },
    postSelected () {
      var messagePayload = {
        'selected': this.selectedQuestion
      }
      this.postMessage(messagePayload)
    },
    postQuestions () {
      var messagePayload = {
        'questions': this.questions
      }
      this.postMessage(messagePayload)
    },
    postIFrame () {
      var messagePayload = {
        'embedIFrame': this.embedIFrame
      }
      this.postMessage(messagePayload)
    },
    postButton () {
      var messagePayload = {
        'embedButton': this.embedButton
      }
      this.postMessage(messagePayload)
    },
    postAccount () {
      var messagePayload = {
        'account': this.currentAccount
      }
      this.postMessage(messagePayload)
    },
    postMessage (payload) {
      // post to child iframe
      document.getElementsByTagName('iframe')[0].contentWindow.postMessage(JSON.stringify(payload), this.iframeDomain)
    },
    postAction () {
      var messagePayload = {
        'action': this.vuexAction.callToAction,
        'subtype': this.vuexWizardSubtype
      }
      this.postMessage(messagePayload)
    },
    postActions () {
      var messagePayload = {
        'actions': this.vuexAction.actions
      }
      this.postMessage(messagePayload)
    },
    setLayout (layout) {
      this.layout = layout
    },
    iframeLoaded () {
      this.iframeIsLoaded = true
      this.sendConentToLayout()
    },
    changeImage (index) {
      if (this.imagesAvailable) {
        if (this.imagesAvailable.indexes) {
          this.imagesAvailable.indexes[0] = index
          this.selectedImage = index
          this.postImages()
        }
      }
    }
  }
}
</script>

<style scoped>
.iframe-holder {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  max-width: 100%;
}
.iframe-holder iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.iframe-holder-4x3 {
  padding-top: 75%;
}

/* Layout Preview */
.add-bottom-padding {
  padding-bottom: 5px;
}
.layout-holder {
  height: 100%;
  max-height: 400px;
  overflow: hidden;
  position: relative;
}
.layout-holder > .hero {
  max-height: 100px;
  overflow: hidden;
}
.layout-holder > .hero > .columns > .column > .image {
  height: 100%;
}
.layout-holder > .hero > .columns > .column:first-child {
  padding-right: 0px;
}
.layout-holder > .hero > .columns > .column:last-child {
  padding-left: 0px;
  padding-top: 15px;
}
.layout-holder > .is-overlay {
  top: 2.3em;
}
.layout-holder > .section {
  margin: 0 15px 0 15px;
  padding: 5px 10px 5px 10px;
}
.layout-holder > .section:last-child {
  height: 100%;
}
.layout-holder > .section.margin-less {
  margin: 0;
}
.preview-desktop-form {
  width: 100%;
  margin: auto;
  border: 0px!important;
  box-shadow: none!important;
}
.preview-desktop-form > .card-content {
  padding: 5px 10px;
}
.placeholder-bars .placeholder-bar {
  height: .7rem;
  margin-bottom: .4rem;
}
.form-placeholder-bars .form-placeholder-bar {
  height: 1rem;
  margin-bottom: .4rem;
}
.form-placeholder-bars .form-placeholder-button {
  height: 1.5rem;
  margin-top: 1rem;
}
.placeholder-bar-100 {
  width: 100%;
}
.placeholder-bar-95 {
  width: 95%;
}
.placeholder-bar-60 {
  width: 60%;
}
.placeholder-bar-30 {
  width: 30%;
}
.placeholder-bar-center {
  margin: 0 auto 6px auto !important;
}
.layout-title {
  border: 5px solid white;
  padding: 0!important;
  width: 100%;
  margin: 0!important;
}

.questions > .is-label:not(:first-child) {
  padding-top: 0px!important;
}
.question-label {
  padding: 0 12px;
}
.question-label-text {
  color: #7a7a7a;
  font-size: 1em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold;
}
.is-question-xx {
  padding: 5px;
  width: 125px;
  max-width: 125px;
  height: 90px;
  max-height: 90px;
  overflow: hidden;
}
.question-name {
  max-height: 50px;
}
.is-label {
  padding-bottom: 0;
}
.questions > .is-image {
  width: 100px;
  -ms-flex-preferred-size: 0;
  flex-basis: unset;
  -webkit-box-flex: unset;
  -ms-flex-positive: unset;
  flex-grow: unset;
  -ms-flex-negative: unset;
  flex-shrink: unset;
}
.unsplash-logo-mini {
  width:20px;
}
</style>

<template>
  <div>
    <main class="oto-main">
      <div class="oto-side-background is-hidden-mobile"/>
      <div class="oto-main-container container">
        <div class="oto-duo">
          <div class="oto-lead">
            <!-- Left Column -->
            <div id="leftColumnTop"/>
            <div v-if="campaign">
              <div class="step-heading is-hidden-mobile">
                <h2 class="subtitle uppercase">
                  Campaign: {{ vuexWizardTitle }}
                </h2>
                <h1 class="title">
                  {{ campaign.title }}
                </h1>
              </div>
              <div
                v-if="stepHasErrors"
                class="notification is-danger">
                Looks like there is a problem here on the <span class="is-lowercase">{{ campaign.title }}</span> step. Please check below for any highlighted errors.
              </div>
              <div
                v-for="(question, index) in campaign.questions"
                :key="index"
                :id="question.ref ? question.ref.replace(' ','-') : null"
                class="field">
                <Calendar
                  v-if="question.type === 'calendar'"
                  :props="question"
                  :focus="index === 0"
                  v-model="question.model"
                  @focus="changeHelp(question)"/>
                <RichText
                  v-else-if="question.type === 'text-rich'"
                  :props="question"
                  :focus="index === 0"
                  :super="(currentUser.superadmin === 1) ? true : false"
                  v-model="question.model"
                  @focus="changeHelp(question)"/>
                <Select
                  v-else-if="question.type === 'select'"
                  :props="question"
                  :focus="index === 0"
                  v-model="question.model"
                  @focus="changeHelp(question)"/>
                <TextInput
                  v-else-if="question.type === 'text-input' || question.type === 'places'"
                  :props="question"
                  :focus="index === 0"
                  v-model="question.model"
                  @focus="changeHelp(question)"/>
                <TextArea
                  v-else-if="question.type === 'text-area'"
                  :props="question"
                  :focus="index === 0"
                  v-model="question.model"
                  @focus="changeHelp(question)"/>
                <Clock
                  v-else-if="question.type === 'time'"
                  :props="question"
                  :focus="index === 0"
                  v-model="question.model"
                  @focus="changeHelp(question)"/>
              </div>
            </div>
          </div>
          <aside class="oto-side">
            <div
              v-if="campaign"
              class="step-heading is-hidden-tablet">
              <h2 class="subtitle uppercase">
                Campaign: {{ vuexWizardTitle }}
              </h2>
              <h1 class="title">
                {{ campaign.title }}
              </h1>
            </div>
            <div
              v-if="campaign"
              class="content">
              <div class="right-column-text">
                {{ campaign.desc }}
              </div>
              <div
                v-if="currentHelp"
                id="sticky"
                class="help-notification notification is-hidden-mobile is-primary sticky-div">
                <div class="help-label capitalize">
                  {{ currentHelpLabel }}
                </div>
                <div class="help-text">
                  {{ currentHelp }}
                </div>
                <span class="icon is-medium has-text-white">
                  <i class="fas fa-info-circle fa-lg"/>
                </span>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </main>
    <Navigation v-model="navigation"/>
    <Confirm :data="editWarning"/>
  </div>
</template>

<script>
import wizard from '@/mixins/Wizard.js'
import sticky from '@/mixins/StickyListener.js'
import tour from '@/mixins/Tour.js'
import { mapGetters } from 'vuex'
import Calendar from '@/components/elements/Calendar'
import Clock from '@/components/elements/Clock'
import RichText from '@/components/elements/RichText'
import Select from '@/components/elements/Select'
import TextInput from '@/components/elements/TextInput'
import TextArea from '@/components/elements/TextArea'
import Confirm from '@/components/elements/Confirm'

export default {
  components: {
    Calendar,
    Clock,
    RichText,
    Select,
    TextArea,
    TextInput,
    Confirm
  },
  mixins: [wizard, sticky, tour],
  data () {
    return {
      currentHelp: null,
      currentHelpLabel: null,
      campaign: null,
      editWarning: {
        action: 'editpublishedwarning',
        show: false,
        result: false,
        hideBack: true,
        question: 'You are editing a published campaign.',
        text: 'Since this campaign is already published you can only edit certain portions of the campaign. In order to save any edits you must complete each step.',
        button: 'Okay, Got It!'
      }
    }
  },
  computed: {
    ...mapGetters({
      vuexWizardDetails: 'wizardDetails',
      vuexCampaignRichContent: 'campaignRichContent',
      vuexLandingPage: 'wizardLandingPage',
      dismissedPublishedModeWarning: 'dismissedPublishedModeWarning',
      currentUser: 'currentUser'
    })
  },
  mounted () {
    this.$store.dispatch('setWizardStep', 1)
    if (this.editPublishedMode && !this.dismissedPublishedModeWarning) {
      this.editWarning.show = true
    }
    if (this.vuexWizardDetails) {
      if (process.env.NODE_ENV === 'development') console.log('need to load into step: ' + JSON.stringify(this.vuexWizardDetails))
      this.campaign = this.$lodash.cloneDeep(this.vuexWizardDetails)
      if (this.campaign) {
        var wt = this.campaign.type
        if (this.campaign.subtype && this.campaign.subtype !== '') wt += ' > ' + this.campaign.subtype
        this.$store.dispatch('setWizardTitle', wt)
        // SET THE WIZARD TYPE
        // this takes out "campaign type" from the JSON and normalizes them to one of 4 wizard types (event, sale, golf, and other)
        if (this.campaign.type === 'event') this.$store.dispatch('setWizardType', 'event')
        else if (this.campaign.type === 'sale') this.$store.dispatch('setWizardType', 'sale')
        else if (this.campaign.type === 'golf' || this.campaign.type === 'tee times') this.$store.dispatch('setWizardType', 'golf')
        else this.$store.dispatch('setWizardType', 'other')
      }
    }
    // Smart Date and Rich Text Sync Support
    var vm = this
    this.campaign.questions.forEach(function (question) {
      // Smart Date Support
      if (question.smartDate) {
        if (!question.model) {
          try {
            var d = new Date()
            var month, day, year
            if (question.smartDate === 'today') {
              month = '' + (d.getMonth() + 1)
              day = '' + d.getDate()
              year = d.getFullYear()
            } else if (question.smartDate === 'tomorrow') {
              d.setDate(d.getDate() + 1)
              month = '' + (d.getMonth() + 1)
              day = '' + d.getDate()
              year = d.getFullYear()
            } else {
              var cy = d.getFullYear()
              var holiday = vm.$holidays[question.smartDate](cy)
              if (d > holiday) {
                holiday = vm.$holidays[question.smartDate](cy + 1)
              }
              month = '' + (holiday.getMonth() + 1)
              day = '' + holiday.getDate()
              year = holiday.getFullYear()
            }
            if (month.length < 2) month = '0' + month
            if (day.length < 2) day = '0' + day
            question.model = [year, month, day].join('-')
          } catch (err) {
            if (process.env.NODE_ENV === 'development') console.log(err)
            question.model = null
          }
        }
      }
      // Sync Support For Rich Content and Landing Page HTML
      if (question.type === 'text-rich') {
        if (vm.vuexCampaignRichContent) question.model = vm.vuexCampaignRichContent
        else if (vm.vuexLandingPage && vm.vuexLandingPage.richContent) question.model = vm.vuexLandingPage.richContent
      }
    })
    // Kickoff the Tour
    if (this.guideMode) this.TourCampaignWizard({ showButtons: true })
  },
  methods: {
    changeHelp (question) {
      var label = question.name
      if (question.label && !question.useNameInError) {
        label = question.label
      }
      this.currentHelpLabel = label
      this.currentHelp = question.desktopHelp
    }
  }
}
</script>

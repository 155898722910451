<template>
  <div v-if="loaded">
    <main class="oto-main">
      <div class="oto-side-background is-hidden-mobile"/>
      <div class="oto-main-container container">
        <Event
          v-if="callToAction === 'event'"
          v-model="actions"
          :mode="vuexWizardSubtype"
          :has-errors="stepHasErrors"/>
        <Sale
          v-if="callToAction === 'sale'"
          v-model="actions"
          :mode="vuexWizardSubtype"
          :has-errors="stepHasErrors"/>
        <BookingEngine
          v-if="callToAction === 'teetime'"
          v-model="actions"
          :mode="vuexWizardSubtype"
          :has-errors="stepHasErrors"/>
      </div>
    </main>
    <Navigation v-model="navigation"/>
  </div>
</template>

<script>
import wizard from '@/mixins/Wizard.js'
import { mapGetters } from 'vuex'
import Event from '@/components/dashboard/campaigns/wizard/sections/types/Event'
import Sale from '@/components/dashboard/campaigns/wizard/sections/types/Sale'
import BookingEngine from '@/components/dashboard/campaigns/wizard/sections/types/BookingEngine'

export default {
  components: {
    BookingEngine,
    Event,
    Sale
  },
  mixins: [wizard],
  data () {
    return {
      actions: null,
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      vuexWizardType: 'wizardType',
      vuexAction: 'wizardAction'
    }),
    callToAction: function () {
      // returns: sale, teetime, info, event
      if (this.vuexWizardType === 'golf') {
        if (this.vuexWizardSubtype === 'paid') return 'sale'
        else if (this.vuexWizardSubtype === 'engine') return 'teetime'
        else return 'info'
      } else if (this.vuexWizardType === 'event') {
        return 'event'
      } else if (this.vuexWizardType === 'sale') {
        return 'sale'
      } else return 'info' // this used to be null
    }
  },
  mounted () {
    this.$store.dispatch('setWizardStep', 3)
    // there are a few scenarios where we'll skip the details and take the user
    // straight to the theme set up
    if (this.vuexWizardType === 'golf' && this.vuexWizardSubtype === 'info') {
      // skip details on a golf info site
      this.$router.push({ name: 'route-engine-campaigns-wizard-theme' })
    } else if (this.vuexWizardType === 'other') {
      // skip details on a other info site
      this.$router.push({ name: 'route-engine-campaigns-wizard-theme' })
    } else if (this.vuexAction) {
      // load actions from vuex
      if (process.env.NODE_ENV === 'development') console.log('need to load into step: ' + JSON.stringify(this.vuexAction))
      if (this.vuexAction.actions) {
        this.actions = this.$lodash.cloneDeep(this.vuexAction.actions)
        // setting this loaded flag is a fix to WAIT for cloning vuex actions
        // this way we dont mount the type details components (events,  sales, etc)
        // until after the actions have been cloned from vuex
        this.loaded = true
      } else this.loaded = true // no actions, load blank component
    } else this.loaded = true // no saved actions, load blank component
  }
}
</script>

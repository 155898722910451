<template>
  <div v-if="allowLoad">
    <main class="oto-main">
      <div class="oto-side-background is-hidden-mobile"/>
      <div class="oto-main-container container">
        <div class="oto-duo">
          <div class="oto-lead">
            <!-- Left Column -->
            <div id="leftColumnTop"/>
            <div class="step-heading is-hidden-mobile">
              <h2 class="subtitle uppercase">
                Campaign: {{ vuexWizardTitle }}
              </h2>
              <h1 class="title">
                {{ typeTitle }}
              </h1>
            </div>
            <div>
              <!-- EVENT -->
              <div
                v-if="vuexWizardType === 'event'"
                class="columns is-desktop">
                <div id="campaign-type-select-paid" class="column">
                  <div
                    @click="selectAction('paid')"
                    :class="{'oto-objective-not-configured': !paymentsConfigured}"
                    class="card oto-objective-card oto-hover">
                    <div class="card-content oto-objective-card-content">
                      <div class="content">
                        <p
                          :class="{ 'has-text-grey': !paymentsConfigured, 'has-text-primary': paymentsConfigured }"
                          class="card-header-title is-size-5 is-centered is-marginless">Sell Event Tickets with CampaignPilot</p>
                        <p>Select this if you want to use CampaignPilot to sell tickets for your event.</p>
                      </div>
                    </div>
                    <div
                      :class="{'oto-margin-bottom': !paymentsConfigured}"
                      class="card-content oto-objective-card-content">
                      <div class="content">
                        <ul>
                          <li>Great for any ticketed event</li>
                          <li>Implement ticket sale windows and limits</li>
                          <li>Collect registration details for all attendees</li>
                        </ul>
                      </div>
                    </div>
                    <span
                      v-if="!paymentsConfigured"
                      class="tag is-warning payment-config-tag">
                      Payments Not Configured
                    </span>
                  </div>
                </div>
                <div id="campaign-type-select-info" class="column">
                  <div
                    @click="selectAction('info')"
                    class="card oto-objective-card oto-hover">
                    <div class="card-content oto-objective-card-content">
                      <div class="content">
                        <p class="card-header-title is-size-5 is-centered is-marginless has-text-primary">Create an Informational Event Website</p>
                        <p>Select this to create an event web page without online ticket sales.</p>
                      </div>
                    </div>
                    <div class="card-content oto-objective-card-content">
                      <div class="content">
                        <ul>
                          <li>Great for events that don't require tickets</li>
                          <li>Easily generate an informational event web page.</li>
                          <li>Collect registrations with form submissions</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- SALE -->
              <div
                v-if="vuexWizardType === 'sale'"
                class="columns is-desktop">
                <div class="column">
                  <div
                    @click="selectAction('paid')"
                    :class="{'oto-objective-not-configured': !paymentsConfigured}"
                    class="card oto-objective-card oto-hover">
                    <div class="card-content oto-objective-card-content">
                      <div class="content">
                        <p
                          :class="{ 'has-text-grey': !paymentsConfigured, 'has-text-primary': paymentsConfigured }"
                          class="card-header-title is-size-5 is-centered is-marginless">Sell Your Product with CampaignPilot</p>
                        <p>Select this if you want to use CampaignPilot to sell this product.</p>
                      </div>
                    </div>
                    <div
                      :class="{'oto-margin-bottom': !paymentsConfigured}"
                      class="card-content oto-objective-card-content">
                      <div class="content">
                        <ul>
                          <li>Great for gift cards, lessons, and other items you want to sell online</li>
                          <li>Collect payment right on your product page</li>
                        </ul>
                      </div>
                    </div>
                    <span
                      v-if="!paymentsConfigured"
                      class="tag is-warning payment-config-tag">
                      Payments Not Configured
                    </span>
                  </div>
                </div>
                <div class="column">
                  <div
                    @click="selectAction('info')"
                    class="card oto-objective-card oto-hover">
                    <div class="card-content oto-objective-card-content">
                      <div class="content">
                        <p class="card-header-title is-size-5 is-centered is-marginless has-text-primary">Create an Informational Product Website</p>
                        <p>Select this to create a product web page without online sales.</p>
                      </div>
                    </div>
                    <div class="card-content oto-objective-card-content">
                      <div class="content">
                        <ul>
                          <li>Great for in-store and other offline sales</li>
                          <li>Collect interest with form submissions</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- GOLF -->
              <div
                v-if="vuexWizardType === 'golf'"
                class="columns is-mobile is-multiline">
                <div class="column is-full-mobile is-full-tablet is-half-desktop is-one-third-widescreen">
                  <div
                    @click="selectAction('paid')"
                    :class="{'oto-objective-not-configured': !paymentsConfigured}"
                    class="card oto-objective-card oto-hover">
                    <div class="card-content oto-objective-card-content">
                      <div class="content">
                        <p
                          :class="{ 'has-text-grey': !paymentsConfigured, 'has-text-primary': paymentsConfigured }"
                          class="card-header-title is-size-5 is-centered is-marginless">Sell Tee Time Vouchers with CampaignPilot</p>
                        <p>Select this if you want to use CampaignPilot to sell prepaid tee time vouchers.</p>
                      </div>
                    </div>
                    <div
                      :class="{'oto-margin-bottom': !paymentsConfigured}"
                      class="card-content oto-objective-card-content">
                      <div class="content">
                        <ul>
                          <li>Great for prepaid specials and deals</li>
                          <li>Customer prepays for rounds to redeem in golf shop</li>
                        </ul>
                      </div>
                    </div>
                    <span
                      v-if="!paymentsConfigured"
                      class="tag is-warning payment-config-tag">
                      Payments Not Configured
                    </span>
                  </div>
                </div>
                <div class="column is-full-mobile is-full-tablet is-half-desktop is-one-third-widescreen">
                  <div
                    @click="selectAction('engine')"
                    class="card oto-objective-card oto-hover">
                    <div class="card-content oto-objective-card-content">
                      <div class="content">
                        <p class="card-header-title is-size-5 is-centered is-marginless has-text-primary">Embed Your Booking Engine</p>
                        <p>Select this to sell rounds of golf using your tee time booking engine.</p>
                      </div>
                    </div>
                    <div class="card-content oto-objective-card-content">
                      <div class="content">
                        <ul>
                          <li>Great for tee time specials</li>
                          <li>Integrate directly with your existing tee sheet</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-full-mobile is-full-tablet is-half-desktop is-one-third-widescreen">
                  <div
                    @click="selectAction('info')"
                    class="card oto-objective-card oto-hover">
                    <div class="card-content oto-objective-card-content">
                      <div class="content">
                        <p class="card-header-title is-size-5 is-centered is-marginless has-text-primary">Create an Informational Golf Website</p>
                        <p>Select this to create a golf website without online booking.</p>
                      </div>
                    </div>
                    <div class="card-content oto-objective-card-content">
                      <div class="content">
                        <ul>
                          <li>Great for book by phone specials</li>
                          <li>Market tee time and golf deals that don't require booking</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <aside class="oto-side">
            <div class="step-heading is-hidden-tablet">
              <h2 class="subtitle uppercase">
                Campaign: {{ vuexWizardTitle }}
              </h2>
              <h1 class="title">
                {{ typeTitle }}
              </h1>
            </div>
            <div class="content">
              <div class="right-column-text">
                {{ typeDesc }}
              </div>
            </div>
          </aside>
        </div>
      </div>
    </main>
    <Navigation v-model="navigation"/>
  </div>
</template>

<script>
import wizard from '@/mixins/Wizard.js'
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  mixins: [wizard],
  data () {
    return {
      allowLoad: false
    }
  },
  computed: {
    ...mapGetters({
      vuexWizardType: 'wizardType',
      vuexWizardSubtype: 'wizardSubtype',
      vuexCurrentAccount: 'currentAccount'
    }),
    typeTitle: function () {
      if (this.vuexWizardType === 'event') return 'Type of Event Campaign'
      else if (this.vuexWizardType === 'sale') return 'Type of Sale Campaign'
      else if (this.vuexWizardType === 'golf') return 'Type of Golf Campaign'
      return null
    },
    typeDesc: function () {
      if (this.vuexWizardType === 'event') return 'When customers click on your campaign message they will be forwarded to a web page customized for your event. Select the experience you want your customers to have on this event web page.'
      else if (this.vuexWizardType === 'sale') return 'When customers click on your campaign message they will be forwarded to a web page customized for your product sale. Select the experience you want your customers to have on this sale web page.'
      else if (this.vuexWizardType === 'golf') return 'When customers click on your campaign message they will be forwarded to a web page customized for this golf promotion. Select the experience you want your customers to have on this golf web page.'
      return null
    },
    paymentsConfigured: function () {
      if (this.vuexCurrentAccount.cc_paypal === 1 || this.vuexCurrentAccount.cc_ets === 1 || this.vuexCurrentAccount.cc_payroc === 1 || (this.vuexCurrentAccount.cc_stripe === 1 && this.vuexCurrentAccount.cc_stripe_token)) return true
      return false
    }
  },
  mounted () {
    this.$store.dispatch('setWizardStep', 2)
    // skip the type and type details section if this isn't a specific wizard type
    if (!this.vuexWizardType || this.vuexWizardType === 'other') {
      // saving the call to action if we skip the step, so that we always have one
      // otherwise the call to action is always saved on the TypeDetails page
      this.$store.dispatch('saveSection', { name: 'wizardAction', value: { callToAction: 'info' } })
      this.$router.push({ name: 'route-engine-campaigns-wizard-theme' })
    } else if (this.vuexWizardSubtype) {
      // this logic handles skipping the type page if we have a type saved
      // in memory. although we do want the user to be able to change their
      // action by clicking the back button.
      // the allowLoad makes sure the component itself doesn't render
      // if we're going to skip this type step entirely (fixes animation glitch)
      var selectTypeFromMemory = true
      if (this.$route.params) if (this.$route.params.way === 'back') selectTypeFromMemory = false
      if (selectTypeFromMemory) {
        this.selectAction(this.vuexWizardSubtype)
      } else this.allowLoad = true
    } else this.allowLoad = true
  },
  methods: {
    selectAction (action) {
      var sendToAction = true
      if (action === 'paid') {
        if (!this.paymentsConfigured) {
          sendToAction = false
        }
      }
      if (sendToAction) {
        // actions are: paid, info, engine (these should be used in combination with wizard type, i.e. paid + sale)
        this.$store.dispatch('setWizardSubtype', action)
        this.$router.push({ name: 'route-engine-campaigns-wizard-type-details' })
      } else {
        this.configurePayments()
      }
    },
    configurePayments () {
      let routeData = this.$router.resolve({ name: 'route-engine-settings', query: { refreshtab: 'payments', wm: '3' } })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

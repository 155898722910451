<template>
<div>
  <div class="oto-second-heading is-hidden-mobile">App Integration</div>
  <p class="padding-bottom">Enable powerful 3rd party integrations with CampaignPilot. </p>
  <div>
    <div
      v-for="(app) in apps"
      :key="'app_'+app.appid"
      :id="'app_'+app.appid"
      class="card padding-bottom">
      <div class="card-content has-background-white-ter">
        <div class="media">
          <div class="media-left">
            <figure class="image is-128x128">
              <img :src="app.logo" class="is-hidden-mobile">
            </figure>
          </div>
          <div class="media-content">
            <div class="title-with-status">
              <p class="oto-second-heading">{{ app.title }}</p>
              <div v-if="app.appid === '121'" v-html="statusTagOneTwoOne" class="status-tag"></div>
              <div v-if="app.appid === 'foreup'" v-html="statusTagforeUP" class="status-tag"></div>
              <div v-if="app.appid === 'cps'" v-html="statusTagCPS" class="status-tag"></div>
            </div>
            <p class="oto-smalltext">{{ app.type }}</p>
          </div>
        </div>
        <div class="content">
          {{ app.desc }}
          <a @click="toggleAppSettings(app)" class="configure-link">
            Configure App
            <span class="icon">
              <i v-if="app.expanded" class="fas fa-angle-up"></i>
              <i v-else class="fas fa-angle-down"></i>
            </span>
          </a>
          <OneTwoOneMarketing v-if="app.appid === '121'" :class="{ expanded: app.expanded, minimized: !app.expanded }" />
          <foreUP v-if="app.appid === 'foreup'" :class="{ expanded: app.expanded, minimized: !app.expanded }" />
          <ClubProphet v-if="app.appid === 'cps'" :class="{ expanded: app.expanded, minimized: !app.expanded }" />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import OneTwoOneMarketing from '@/components/dashboard/settings/other/apps/OneTwoOneMarketing'
import foreUP from '@/components/dashboard/settings/other/apps/foreUP'
import ClubProphet from '@/components/dashboard/settings/other/apps/ClubProphet'
import { mapGetters } from 'vuex'

export default {
  components: {
    OneTwoOneMarketing, foreUP, ClubProphet
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    }),
    statusTagCPS: function () {
      if (this.currentAccount) if (this.currentAccount.cps_active === 1 && this.currentAccount.cps_key) return '<span class="tag is-enabled">Enabled</span>'
      return null
    },
    statusTagOneTwoOne: function () {
      if (this.currentAccount) if (this.currentAccount.queue_acy === 1) return '<span class="tag is-enabled">Enabled</span>'
      return null
    },
    statusTagforeUP: function () {
      if (this.currentAccount) if (this.currentAccount.foreup_active === 1) return '<span class="tag is-enabled">Enabled</span>'
      return null
    }
  },
  data () {
    return {
      apps: [{
        appid: '121',
        title: '1-2-1 Marketing',
        type: 'Website & Marketing Software',
        desc: 'Synchronize customers generated from CampaignPilot with your 1-2-1 Marketing email software.',
        logo: require('../../../../assets/imgs/apps/121marketing-logo.png'),
        expanded: false
      },
      {
        appid: 'foreup',
        title: 'foreUP',
        type: 'Tee Sheet Software',
        desc: 'Integrate your foreUP Tee Sheet and Point-of-Sale software with CampaignPilot. Synchronize customers generated from CampaignPilot, sell sales items, and more.',
        logo: require('../../../../assets/imgs/apps/foreup-logo.png'),
        expanded: false
      },
      {
        appid: 'cps',
        title: 'Club Prophet',
        type: 'Tee Sheet Software',
        desc: 'Integrate your Club Prophet software with CampaignPilot. Sell sales items, virtual gift cards, and more.',
        logo: require('../../../../assets/imgs/apps/clubprophet-logo.png'),
        expanded: false
      }]
    }
  },
  mounted () {
    // allow app expanding via url
    if (this.$route.query.setup === '121') {
      this.apps[0].expanded = true
      this.$scrollTo('#app_121', 200, { easing: 'ease' })
    } else if (this.$route.query.setup === 'foreup') {
      this.apps[1].expanded = true
      this.$scrollTo('#app_foreup', 200, { easing: 'ease' })
    } else if (this.$route.query.setup === 'cps') {
      this.apps[2].expanded = true
      this.$scrollTo('#app_cps', 200, { easing: 'ease' })
    }
  },
  methods: {
    toggleAppSettings (app) {
      app.expanded = !app.expanded
    }
  }
}
</script>

<style scoped>
  .card {
    box-shadow: none !important;
  }
  .expanded {
    opacity: 1;
    display: block;
  }
  .minimized {
    opacity: 0;
    height: 0;
    display: none;
  }
  .is-128x128 {
    height: 50%;
  }
  .title-with-status {
    overflow: auto;
    display: flex;
  }
  .title-with-status p {
    padding-right: 10px;
  }
  .configure-link {
    display: block;
    padding: 10px 0;
  }
</style>

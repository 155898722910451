<template>
  <div
    :class="fieldClasses"
    class="field">
    <label
      v-if="props.label"
      :style="label.style"
      :class="label.class"
      class="label">
      <span v-if="props.label === '&nbsp;'">&nbsp;</span>
      <span v-else>{{ props.label }}</span>
      <span
        v-if="isRequired && !props.hideRequired"
        class="icon has-text-danger is-small oto-required-field">
        <i class="fas fa-star-of-life oto-fa-mini"/>
      </span>
    </label>
    <div
      :class="{ 'has-icons-left': props.icon }"
      class="control">
      <div
        :class="[{ 'is-danger': errors.has(props.name) }, props.class ]"
        class="select">
        <select
          v-validate="props.validate"
          :value="props.model"
          :name="props.name"
          :ref="props.ref"
          :disabled="disabled"
          :data-vv-as="props.label && !props.useNameInError ? props.label : props.name"
          :style="control.style"
          :class="inputClasses"
          @blur="onBlur()"
          @focus="onFocus()"
          @input="onInput($event.target.value)">
          <option/>
          <option
            v-for="(state, index) in states"
            :key="index"
            :value="state.value">
            {{ state.display }}
          </option>
        </select>
      </div>
      <span
        v-if="props.icon"
        class="icon is-small is-left">
        <i :class="props.icon"/>
      </span>
      <span
        v-if="errors.has(props.name) && !props.hideErrors"
        class="has-text-danger animated fadeInDown">
        {{ errors.first(props.name) }}
      </span>
    </div>
    <p
      v-if="props.mobileHelp && isInputActive"
      class="oto-mobile-help is-info is-hidden-tablet">
      <span class="icon">
        <i class="fas fa-info-circle"/>
      </span>
      {{ props.mobileHelp }}
    </p>
  </div>
</template>

<script>
import inputs from '@/mixins/Inputs.js'

export default {
  mixins: [inputs],
  data () {
    return {
      states: [
        { value: 'AL', display: 'Alabama' },
        { value: 'AK', display: 'Alaska' },
        { value: 'AZ', display: 'Arizona' },
        { value: 'AR', display: 'Arkansas' },
        { value: 'CA', display: 'California' },
        { value: 'CO', display: 'Colorado' },
        { value: 'CT', display: 'Connecticut' },
        { value: 'DE', display: 'Delaware' },
        { value: 'DC', display: 'District of Columbia' },
        { value: 'FL', display: 'Florida' },
        { value: 'GA', display: 'Georgia' },
        { value: 'HI', display: 'Hawaii' },
        { value: 'ID', display: 'Idaho' },
        { value: 'IL', display: 'Illinois' },
        { value: 'IN', display: 'Indiana' },
        { value: 'IA', display: 'Iowa' },
        { value: 'KS', display: 'Kansas' },
        { value: 'KY', display: 'Kentucky' },
        { value: 'LA', display: 'Louisiana' },
        { value: 'ME', display: 'Maine' },
        { value: 'MD', display: 'Maryland' },
        { value: 'MA', display: 'Massachusetts' },
        { value: 'MI', display: 'Michigan' },
        { value: 'MN', display: 'Minnesota' },
        { value: 'MS', display: 'Mississippi' },
        { value: 'MO', display: 'Missouri' },
        { value: 'MT', display: 'Montana' },
        { value: 'NE', display: 'Nebraska' },
        { value: 'NV', display: 'Nevada' },
        { value: 'NH', display: 'New Hampshire' },
        { value: 'NJ', display: 'New Jersey' },
        { value: 'NM', display: 'New Mexico' },
        { value: 'NY', display: 'New York' },
        { value: 'NC', display: 'North Carolina' },
        { value: 'ND', display: 'North Dakota' },
        { value: 'OH', display: 'Ohio' },
        { value: 'OK', display: 'Oklahoma' },
        { value: 'OR', display: 'Oregon' },
        { value: 'PA', display: 'Pennsylvania' },
        { value: 'RI', display: 'Rhode Island' },
        { value: 'SC', display: 'South Carolina' },
        { value: 'SD', display: 'South Dakota' },
        { value: 'TN', display: 'Tennessee' },
        { value: 'TX', display: 'Texas' },
        { value: 'UT', display: 'Utah' },
        { value: 'VT', display: 'Vermont' },
        { value: 'VA', display: 'Virginia' },
        { value: 'WA', display: 'Washington' },
        { value: 'WV', display: 'West Virginia' },
        { value: 'WI', display: 'Wisconsin' },
        { value: 'WY', display: 'Wyoming' },
        { value: '99', display: '----------------------' },
        { value: 'AB', display: 'Alberta' },
        { value: 'BC', display: 'British Columbia' },
        { value: 'MB', display: 'Manitoba' },
        { value: 'NB', display: 'New Brunswick' },
        { value: 'NL', display: 'Newfoundland' },
        { value: 'NS', display: 'Nova Scotia' },
        { value: 'NT', display: 'Northwest Territories' },
        { value: 'NU', display: 'Nunavut' },
        { value: 'ON', display: 'Ontario' },
        { value: 'PE', display: 'Prince Edward' },
        { value: 'QC', display: 'Quebec' },
        { value: 'SK', display: 'Saskatchewan' },
        { value: 'YT', display: 'Yukon' },
        { value: '99', display: '----------------------' },
        { value: 'AS', display: 'American Samoa' },
        { value: 'GU', display: 'Guam' },
        { value: 'MP', display: 'Northern Mariana Islands' },
        { value: 'PR', display: 'Puerto Rico' },
        { value: 'VI', display: 'U.S. Virgin Islands' },
        { value: 'UM', display: 'U.S. Outlying Islands' },
        { value: '99', display: '----------------------' },
        { value: 'AA', display: 'Armed Forces Americas' },
        { value: 'AE', display: 'Armed Forces Europe' },
        { value: 'AP', display: 'Armed Forces Pacific' }
      ]
    }
  }
}
</script>

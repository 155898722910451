<template>
  <div>
    <div class="columns is-desktop is-multiline">
      <div class="column is-two-thirds">
        <!-- form stuff here -->
        <label class="label"><span>Banner Image</span></label>
        <div v-if="imageURL" class="banner-preview">
          <img :src="imageURL"/>
        </div>
        <a
          class="button is-primary is-rounded"
          @click="openImageUploader">
          <span class="icon is-small">
            <i class="fas fa-plus"/>
          </span>
          <span>Change Image</span>
        </a>
      </div>
      <div class="column is-half">
        <RichText
          :props="html"
          :super="(currentUser.superadmin === 1) ? true : false"
          v-model="html.model" />
        <a
          class="button is-primary is-rounded"
          @click="saveHTML">
          <span class="icon is-small">
            <i class="fas fa-save"/>
          </span>
          <span>Save HTML Changes</span>
        </a>
      </div>
    </div>
    <ImageUploader
      :iu="iu"
      v-model="uploadedImage"/>
  </div>
</template>

<script>
import ImageUploader from '@/components/elements/ImageUploader'
import RichText from '@/components/elements/RichText'
import { mapGetters } from 'vuex'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'

export default {
  components: {
    ImageUploader,
    RichText
  },
  mixins: [xhr],
  data () {
    return {
      html: {
        model: null,
        name: 'banner-html',
        ref: 'banner-html',
        label: 'Banner Content'
      },
      iu: {
        modalOpen: false,
        allowedRatios: ['widescreen'],
        defaultRatio: 'widescreen',
        format: 'image/jpeg',
        compress: 0.99
      },
      uploadedImage: null,
      imageURL: null
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentUser: 'currentUser',
      currentLoading: 'currentLoading'
    })
  },
  watch: {
    'uploadedImage.url' (val) {
      if (val) {
        this.uploadImage(val)
      }
    }
  },
  mounted () {
    this.fetchBanners()
  },
  methods: {
    openImageUploader () {
      this.iu.modalOpen = true
    },
    fetchBanners () {
      var vm = this
      engineApi.fetchBanners(vm.currentAccount.id,
        function (err, banners) {
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (banners.length > 0) {
              vm.html.model = banners[0].html
              vm.imageURL = banners[0].image
            }
          }
        }
      )
    },
    saveHTML () {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.saveBannerHTML(
        vm.html.model,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          }
          if (response) {
            vm.$store.dispatch('setNotify', { state: true, text: 'The banner HTML has been updated.' })
          }
        }
      )
    },
    uploadImage (url) {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.saveBannerImage(
        url,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          }
          if (response) {
            vm.$store.dispatch('setNotify', { state: true, text: 'The banner image has been updated.' })
            vm.imageURL = response.image
          }
        }
      )
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
.banner-preview img {
  width: 40%;
  height: 40%;
}
</style>

<template>
  <nav
    v-if="showPagination"
    class="pagination is-rounded"
    role="navigation"
    aria-label="pagination"
  >
    <a
      :disabled="currentPage < 2"
      class="pagination-previous"
      @click="previous"
      v-text="previousText"
    />
    <a
      :disabled="currentPage > pagesTotal - 1"
      class="pagination-next"
      @click="next"
      v-text="nextText"
    />
    <ul class="pagination-list">
      <li v-if="showFirstPageButton">
        <a
          :aria-label="`${goToText} 1`"
          class="pagination-link"
          v-text="1"
          @click="goPage(1)"
        />
      </li>
      <li v-if="buttons[0] > 2">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li
        v-for="button in buttons"
        :key="button"
      >
        <a
          :class="{ 'is-current': button === currentPage }"
          :aria-label="`${button === currentPage ? pageText : goToText} ${button}`"
          :aria-current="button === currentPage ? 'page' : false"
          class="pagination-link"
          v-text="button"
          @click="goPage(button)"
        />
      </li>
      <li v-if="buttons[buttons.length - 1] < pagesTotal - 1">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li v-if="showLastPageButton">
        <a
          :aria-label="`${goToText} ${pagesTotal}`"
          class="pagination-link"
          v-text="pagesTotal"
          @click="goPage(pagesTotal)"
        />
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    totalPages: {
      type: Number,
      default: 0
    },
    itemsPerPage: {
      type: Number,
      default: 5
    },
    currentPage: {
      type: Number,
      default: 1
    },
    buttonsMax: {
      type: Number,
      default: 5,
      validator: value => value > 4 && Math.abs(value % 2) === 1
    },
    nextText: {
      type: String,
      default: 'Next page'
    },
    previousText: {
      type: String,
      default: 'Previous'
    },
    goToText: {
      type: String,
      default: 'Goto page'
    },
    pageText: {
      type: String,
      default: 'Page'
    }
  },
  data () {
    return {
      totalPagesLocal: 0
    }
  },
  computed: {
    itemsTotal () {
      return this.totalPagesLocal * this.itemsPerPage
    },
    pagesTotal () {
      return Math.ceil(this.itemsTotal / this.itemsPerPage)
    },
    buttonsTotal () {
      return this.pagesTotal > this.buttonsMax - 2 ? this.buttonsMax - 2 : this.pagesTotal
    },
    showPagination () {
      return this.itemsTotal && this.buttonsTotal > 1
    },
    showFirstPageButton () {
      return this.buttons[0] > 1
    },
    showLastPageButton () {
      return this.buttons[this.buttons.length - 1] < this.pagesTotal
    },
    buttons () {
      let firstButton = this.currentPage - Math.floor(this.buttonsTotal / 2)
      let lastButton = firstButton + (this.buttonsTotal - Math.ceil(this.buttonsTotal % 2))
      if (firstButton < 1) {
        firstButton = 1
        lastButton = firstButton + (this.buttonsTotal - 1)
      }
      if (lastButton > this.pagesTotal) {
        lastButton = this.pagesTotal
        firstButton = lastButton - (this.buttonsTotal - 1)
      }
      const pages = []
      for (let page = firstButton; page <= lastButton; page += 1) {
        pages.push(page)
      }
      return pages
    }
  },
  watch: {
    totalPages (val) {
      this.totalPagesLocal = val
    }
  },
  mounted () {
    if (this.totalPages) this.totalPagesLocal = this.totalPages
  },
  methods: {
    goPage (page) {
      this.$emit('change', page)
    },
    previous () {
      if (this.currentPage > 1) {
        this.goPage(this.currentPage - 1)
      }
    },
    next () {
      if (this.currentPage < this.pagesTotal) {
        this.goPage(this.currentPage + 1)
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="columns">
      <div class="column is-half-desktop">
        <div
          v-if="error"
          class="notification is-danger">
          {{ error }}
        </div>
        <div class="field has-text-left">
          <label class="label">Current Password</label>
          <div class="control">
            <input
              v-validate="{ required: true }"
              id="oldPassword"
              ref="oldPassword"
              key="oldPassword-input"
              v-model="oldPassword"
              :class="{ 'is-danger': errors.has('oldPassword') }"
              class="input is-medium"
              name="oldPassword"
              type="password"
              placeholder="Your Current Password">
          </div>
        </div>
        <div class="field has-text-left">
          <label class="label">New Password</label>
          <div class="control">
            <input
              v-validate="{ required: true }"
              id="password"
              ref="password"
              key="password-input"
              v-model="password"
              :class="{ 'is-danger': errors.has('password') }"
              class="input is-medium"
              name="password"
              type="password"
              placeholder="Your New Password">
          </div>
        </div>
        <div class="field has-text-left">
          <label class="label">Confirm New Password</label>
          <div class="control">
            <input
              v-validate="{ required: true }"
              id="password2"
              ref="password2"
              key="password2-input"
              v-model="password2"
              :class="{ 'is-danger': errors.has('password2') }"
              class="input is-medium"
              name="password2"
              type="password"
              placeholder="Confirm Your New Password">
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <a
          class="button is-primary is-rounded"
          @click="changePassword()">
          <span class="icon is-small">
            <i class="fas fa-lock"/>
          </span>
          <span>Change Password</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      error: null,
      oldPassword: '',
      password: '',
      password2: ''
    }
  },
  methods: {
    changePassword () {
      this.error = null
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.password !== this.password2) {
            // passwords dont match
            this.error = 'Your new passwords do not match. Please re-enter those and try again.'
          } else {
            var vm = this
            this.$Amplify.Auth.currentAuthenticatedUser()
              .then(user => {
                return vm.$Amplify.Auth.changePassword(user, vm.oldPassword, vm.password)
              })
              .then(success => {
                vm.oldPassword = ''
                vm.password = ''
                vm.password2 = ''
                vm.$store.dispatch('setNotify', { state: true, text: 'Your password has been updated.' })
              })
              .catch(err => {
                switch (err.code) {
                  case 'NotAuthorizedException':
                    vm.error = 'Your current password is incorrect. Please try agian.'
                    break
                  case 'InvalidParameterException':
                    vm.error = 'You did not complete the entire form. Please enter your old password and your new password twice.'
                    break
                  case 'InvalidPasswordException':
                    vm.error = 'Your new password is not strong enough. Make sure your password at least 8 characters long and contains a mix of numbers, uppercase, and lowercase letters.'
                    break
                  default:
                    vm.error = 'We could not change your password at this time. Please try after some time.'
                    break
                }
              })
          }
        } else {
          // form has errors do nothing
          this.error = 'There is a problem with the change password form. Please check below for any errors or missing fields.'
        }
      })
    }
  }
}
</script>

<template>
  <div
    :class="fieldClasses"
    class="field">
    <label
      v-if="props.label"
      :style="label.style"
      :class="label.class"
      class="label">
      <span v-if="props.label === '&nbsp;'">&nbsp;</span>
      <span v-else>{{ props.label }}</span>
      <span
        v-if="isRequired && !props.hideRequired"
        class="icon has-text-danger is-small oto-required-field">
        <i class="fas fa-star-of-life oto-fa-mini"/>
      </span>
    </label>
    <div
      :style="control.divstyle"
      :class="{ 'has-icons-left': props.icon, 'has-icons-right': props.rightIcon }"
      class="control">
      <input
        v-validate="props.validate"
        :value="props.model"
        :placeholder="props.placeholder"
        :name="props.name"
        :ref="props.ref"
        :type="inputType"
        :disabled="disabled"
        :data-vv-as="props.label && !props.useNameInError ? props.label : props.name"
        :style="control.style"
        :class="inputClasses"
        class="input"
        v-on:keyup.enter="$emit('enter')"
        @blur="onBlur()"
        @focus="onFocus()"
        @input="onInput($event.target.value)"/>
      <span
        v-if="props.icon"
        :key="'left-icon-span-' + props.name"
        :id="'left-icon-span-' + props.name"
        class="icon is-small is-left">
        <i
          :class="props.icon"
          :key="'left-icon-i-' + props.name"
          :id="'left-icon-i-' + props.name" />
      </span>
      <span
        v-if="props.rightIcon"
        :key="'right-icon-span-' + props.name"
        :id="'right-icon-span-' + props.name"
        class="icon is-small is-right">
        <i
          :class="props.rightIcon"
          :key="'right-icon-i-' + props.name"
          :id="'right-icon-i-' + props.name" />
      </span>
      <span
        v-if="errors.has(props.name) && !props.hideErrors"
        class="has-text-danger animated fadeInDown sentence-case">
        {{ errors.first(props.name) }}
      </span>
    </div>
    <div v-if="props.buttonText" class="control">
      <a
        class="button is-primary"
        @click="$emit('click')">
        {{ props.buttonText }}
      </a>
    </div>
    <p
      v-if="props.mobileHelp && isInputActive"
      class="oto-mobile-help is-info is-hidden-tablet">
      <span class="icon">
        <i class="fas fa-info-circle"/>
      </span>
      {{ props.mobileHelp }}
    </p>
  </div>
</template>

<script>
import inputs from '@/mixins/Inputs.js'

export default {
  mixins: [inputs]
}
</script>

<style scoped>
.control > .button {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>

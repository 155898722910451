<template>
  <div>
    <div class="columns">
      <div class="column oto-settings-sub-details">
        <p>Adding a payment processor allows your customers to make purchases directly from your landing pages.</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="tile">
          <div class="is-hidden-mobile oto-vertical-tabs-is-left">
            <nav class="oto-vertical-tabs" id="vertical-tabs-payments">
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 0}"
                @click="changeTab(0)">
                <div>
                  <span class="icon">
                    <i class="fab fa-stripe-s"></i>
                  </span>
                  <span>Stripe</span>
                </div>
                <div v-html="statusTagStripe" class="status-tag" />
              </a>
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 1}"
                @click="changeTab(1)">
                <div>
                  <span class="icon">
                    <i class="fab fa-paypal"></i>
                  </span>
                  <span>PayPal</span>
                </div>
                <div v-html="statusTagPayPal" class="status-tag" />
              </a>
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 2}"
                @click="changeTab(2)">
                <div>
                  <span class="icon">
                    <i class="fas fa-credit-card"></i>
                  </span>
                  <span>Payroc</span>
                </div>
                <div v-html="statusTagPayroc" class="status-tag" />
              </a>
              <a
                v-if="currentUser.superadmin === 1"
                class="panel-block"
                :class="{'active-panel': currentTab === 3}"
                @click="changeTab(3)">
                <div>
                  <span class="icon">
                    <i class="fas fa-credit-card"></i>
                  </span>
                  <span>Elavon</span>
                </div>
                <div v-html="statusTagElavon" class="status-tag" />
              </a>
              <a/>
            </nav>
          </div>
          <div class="oto-vertical-tabs-is-right">
            <!--  STRIPE -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 0}"
              @click="changeTab(0, true)">
              <div id="tab0">
                <span>Stripe</span>
              </div>
              <div>
                <div v-html="statusTagStripe" class="status-tag" />
              </div>
            </div>
            <div
              v-if="currentTab === 0"
              id="settings-channels-stripe-area"
              class="oto-settings-content-area animated fadeIn">
              <div class="stripe-setup">
                <div class="field">
                  <Toggler
                    :props="stripeToggle"
                    v-model="stripeToggle.model"/>
                </div>
                <div v-if="stripeToggle.model">
                  <div class="field">
                    <div class="field is-horizontal">
                      <div class="field-label label-helper">
                        <label class="label no-margin">Connect Stripe</label>
                        <div
                          v-if="currentAccount.cc_stripe_token"
                          class="placeholder">CampaignPilot is connected to your Stripe account</div>
                        <div
                          v-else
                          class="placeholder">Connect your Stripe account to CampaignPilot to complete setup</div>
                      </div>
                      <div class="field-body">
                        <div
                          v-if="currentAccount.cc_stripe_token"
                          class="stripe-text">
                          {{ currentAccount.cc_stripe_account_name }}
                        </div>
                        <a
                          v-else
                          :href="stripeOAuthURL">
                          <img
                            class="stripe-button"
                            src="../../../assets/imgs/payment/cc-stripe-button.png">
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- SAVE BUTTON -->
                <div class="oto-settings-save-button">
                  <a
                    class="button is-primary is-rounded"
                    @click="save()">
                    <span class="icon is-small">
                      <i class="fas fa-save"/>
                    </span>
                    <span>Save Changes</span>
                  </a>
                </div>
              </div>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!--  PAYPAL -->
            <div
              :class="{'oto-accordian-expanded': currentTab === 1}"
              class="oto-accordian-holder show-hand is-hidden-tablet"
              @click="changeTab(1, true)">
              <div id="tab1">PayPal</div>
              <div>
                <div v-html="statusTagPayPal" class="status-tag" />
              </div>
            </div>
            <div
              v-if="currentTab === 1"
              class="oto-settings-content-area animated fadeIn">
              <div class="paypal-setup">
                <div class="field">
                  <Toggler
                    :props="paypalToggle"
                    v-model="paypalToggle.model"/>
                </div>
                <div v-if="paypalToggle.model">
                  <div class="field">
                    <Toggler
                      :props="paypalMode"
                      v-model="paypalMode.model"/>
                  </div>
                  <div class="field">
                    <Toggler
                      :props="paypalCurrency"
                      v-model="paypalCurrency.model"/>
                  </div>
                  <div class="field">
                    <TextInput
                      :props="paypalProduction"
                      v-model="paypalProduction.model"/>
                  </div>
                  <div class="field">
                    <TextInput
                      :props="paypalSandbox"
                      v-model="paypalSandbox.model"/>
                    <a
                      href="https://help.campaignpilot.com/payment-setup/paypal"
                      target="_blank">
                      <div class="icon has-text-primary">
                        <i class="fas fa-question-circle"/>
                      </div>
                      Get help configuring PayPal and obtaining client IDs.
                    </a>
                  </div>
                </div>
                <!-- SAVE BUTTON -->
                <div class="oto-settings-save-button">
                  <a
                    class="button is-primary is-rounded"
                    @click="save()">
                    <span class="icon is-small">
                      <i class="fas fa-save"/>
                    </span>
                    <span>Save Changes</span>
                  </a>
                </div>
              </div>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!--  PAYROC -->
            <div
              :class="{'oto-accordian-expanded': currentTab === 2}"
              class="oto-accordian-holder show-hand is-hidden-tablet"
              @click="changeTab(2, true)">
              <div id="tab2">PayRoc</div>
              <div>
                <div v-html="statusTagPayroc" class="status-tag" />
              </div>
            </div>
            <div
              v-if="currentTab === 2"
              class="oto-settings-content-area animated fadeIn">
              <div class="payroc-setup">
                <div class="field">
                  <Toggler
                    :props="payrocToggle"
                    v-model="payrocToggle.model"/>
                </div>
                <div v-if="payrocToggle.model">
                  <div class="field">
                    <TextInput
                      :props="payrocUsername"
                      v-model="payrocUsername.model"/>
                  </div>
                  <div class="field">
                    <TextInput
                      :props="payrocKey"
                      v-model="payrocKey.model"/>
                      <a
                        href="https://help.campaignpilot.com/payment-setup/payroc"
                        target="_blank">
                        <div class="icon has-text-primary">
                          <i class="fas fa-question-circle"/>
                        </div>
                        Get help configuring Payroc and obtaining API settings.
                      </a>
                  </div>
                </div>
                <!-- SAVE BUTTON -->
                <div class="oto-settings-save-button">
                  <a
                    class="button is-primary is-rounded"
                    @click="save()">
                    <span class="icon is-small">
                      <i class="fas fa-save"/>
                    </span>
                    <span>Save Changes</span>
                  </a>
                </div>
              </div>
            </div>
            <!--  ELAVON -->
            <hr
              v-if="currentUser.superadmin === 1"
              class="is-hidden-tablet oto-accordian-hr"/>
            <div
              v-if="currentUser.superadmin === 1"
              :class="{'oto-accordian-expanded': currentTab === 3}"
              class="oto-accordian-holder show-hand is-hidden-tablet"
              @click="changeTab(3, true)">
              <div id="tab3">Elavon</div>
              <div>
                <div v-html="statusTagElavon" class="status-tag" />
              </div>
            </div>
            <div
              v-if="currentTab === 3 && currentUser.superadmin === 1"
              class="oto-settings-content-area animated fadeIn">
              <div class="setup-warning-area has-text-danger">
                <div class="icon has-text-danger">
                  <i class="fas fa-exclamation-circle"/>
                </div>
                Elavon is no longer a supported platform. The Elavon configuration area here is displayed only for super admins. Other CampaignPilot users cannot see this section. Do not proceed configuring this client with Elavon unless explicitly instructed to.
              </div>
              <div class="ets-setup">
                <div class="field">
                  <Toggler
                    :props="etsToggle"
                    v-model="etsToggle.model"/>
                </div>
                <div v-if="etsToggle.model">
                  <div class="field">
                    <Toggler
                      :props="cardsVisa"
                      v-model="cardsVisa.model"/>
                  </div>
                  <div class="field">
                    <Toggler
                      :props="cardsMastercard"
                      v-model="cardsMastercard.model"/>
                  </div>
                  <div class="field">
                    <Toggler
                      :props="cardsDiscover"
                      v-model="cardsDiscover.model"/>
                  </div>
                  <div class="field">
                    <Toggler
                      :props="cardsAmex"
                      v-model="cardsAmex.model"/>
                  </div>
                  <div class="field">
                    <Toggler
                      :props="cardsJcb"
                      v-model="cardsJcb.model"/>
                  </div>
                  <div class="field">
                    <TextInput
                      :props="etsKey"
                      v-model="etsKey.model"/>
                    <a
                      href="https://elavonpayments.com/support/"
                      target="_blank">
                      <div class="icon has-text-primary">
                        <i class="fas fa-question-circle"/>
                      </div>
                      Contact Elavon for help setting up your account and obtainining your Business API Key.
                    </a>
                  </div>
                </div>
                <div class="oto-settings-save-button">
                  <a
                    class="button is-primary is-rounded"
                    @click="save()">
                    <span class="icon is-small">
                      <i class="fas fa-save"/>
                    </span>
                    <span>Save Changes</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TextInput from '@/components/elements/TextInput'
import Toggler from '@/components/elements/Toggler'
import engineApi from '@/plugins/engine-api.js'
import utils from '@/plugins/utils.js'
import xhr from '@/mixins/Xhr.js'
import settings from '@/mixins/Settings.js'

export default {
  components: {
    TextInput,
    Toggler
  },
  mixins: [xhr, settings],
  data: function () {
    return {
      ss: null,
      etsToggle: {
        model: true,
        name: 'ets-toggle',
        ref: 'ets-toggle',
        label: 'Elavon',
        mobileHideLabel: true,
        placeholder: 'Enable checkout with Elavon',
        options: { checked: 'Enabled', unchecked: 'Disabled' },
        height: 30,
        width: 120
      },
      etsKey: {
        model: null,
        name: 'ets-key',
        ref: 'ets-key',
        label: 'Business API Key',
        fieldClass: 'oto-input-field-text-medium'
      },
      cardsVisa: {
        model: true,
        name: 'cards-visa',
        ref: 'cards-visa',
        label: 'VISA',
        placeholder: 'Accept VISA cards on checkout',
        options: { checked: 'Accept', unchecked: 'Disabled' },
        height: 30,
        width: 120
      },
      cardsMastercard: {
        model: true,
        name: 'cards-mastercard',
        ref: 'cards-mastercard',
        label: 'MasterCard',
        placeholder: 'Accept MasterCard on checkout',
        options: { checked: 'Accept', unchecked: 'Disabled' },
        height: 30,
        width: 120
      },
      cardsDiscover: {
        model: true,
        name: 'cards-discover',
        ref: 'cards-discover',
        label: 'Discover',
        placeholder: 'Accept Discover Card on checkout',
        options: { checked: 'Accept', unchecked: 'Disabled' },
        height: 30,
        width: 120
      },
      cardsAmex: {
        model: true,
        name: 'cards-amex',
        ref: 'cards-amex',
        label: 'American Express',
        placeholder: 'Accept American Express on checkout',
        options: { checked: 'Accept', unchecked: 'Disabled' },
        height: 30,
        width: 120
      },
      cardsJcb: {
        model: true,
        name: 'cards-jcb',
        ref: 'cards-jcb',
        label: 'JCB',
        placeholder: 'Accept JCB on checkout',
        options: { checked: 'Accept', unchecked: 'Disabled' },
        height: 30,
        width: 120
      },
      paypalToggle: {
        model: true,
        name: 'paypal-toggle',
        ref: 'paypal-toggle',
        label: 'PayPal',
        mobileHideLabel: true,
        placeholder: 'Enable checkout with PayPal',
        options: { checked: 'Enabled', unchecked: 'Disabled' },
        height: 30,
        width: 120
      },
      paypalMode: {
        model: true,
        name: 'paypal-mode',
        ref: 'paypal-mode',
        label: 'Mode',
        placeholder: 'Toggle between live or sandbox mode for testing',
        options: { checked: 'Live', unchecked: 'Sandbox' },
        optionColors: { checked: '#24C980', unchecked: '#fdde41' },
        height: 30,
        width: 120
      },
      paypalProduction: {
        model: null,
        name: 'paypal-prod',
        ref: 'paypal-prod',
        label: 'Live Client ID',
        fieldClass: 'oto-input-field-text-medium'
      },
      paypalSandbox: {
        model: null,
        name: 'paypal-sandbox',
        ref: 'paypal-sandbox',
        label: 'Sandbox Client ID',
        fieldClass: 'oto-input-field-text-medium'
      },
      paypalCurrency: {
        model: true,
        name: 'paypal-currency',
        ref: 'paypal-currency',
        label: 'Currency',
        placeholder: 'Currency to charge during checkout',
        options: { checked: 'USD', unchecked: 'CAD' },
        optionColors: { checked: '#0D25BB', unchecked: '#0D25BB' },
        height: 30,
        width: 120
      },
      stripeToggle: {
        model: true,
        name: 'stripe-toggle',
        ref: 'stripe-toggle',
        label: 'Stripe',
        placeholder: 'Enable checkout with Stripe',
        mobileHideLabel: true,
        options: { checked: 'Enabled', unchecked: 'Disabled' },
        height: 30,
        width: 120
      },
      payrocToggle: {
        model: true,
        name: 'payroc-toggle',
        ref: 'payroc-toggle',
        label: 'Payroc',
        mobileHideLabel: true,
        placeholder: 'Enable checkout with Payroc',
        options: { checked: 'Enabled', unchecked: 'Disabled' },
        height: 30,
        width: 120
      },
      payrocUsername: {
        model: null,
        name: 'payroc-username',
        ref: 'payroc-username',
        label: 'API Username',
        fieldClass: 'oto-input-field-text-medium'
      },
      payrocKey: {
        model: null,
        name: 'payroc-key',
        ref: 'payroc-key',
        label: 'API Key',
        fieldClass: 'oto-input-field-text-medium'
      }
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentUser: 'currentUser'
    }),
    stripeOAuthURL: function () {
      return 'https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write&client_id=' + process.env.VUE_APP_STRIPE_ID + '&redirect_uri=' + process.env.VUE_APP_GATEWAY_URL + '/oauth/stripe&state=' + this.currentAccount.id + '_ss_' + this.ss
    },
    statusTagStripe: function () {
      if (this.currentAccount) if (this.currentAccount.cc_stripe === 1 && this.currentAccount.cc_stripe_token) return '<span class="tag is-enabled">Enabled</span>'
      return null
    },
    statusTagPayPal: function () {
      if (this.currentAccount) if (this.currentAccount.cc_paypal === 1 && this.currentAccount.cc_paypal_prod) return '<span class="tag is-enabled">Enabled</span>'
      return null
    },
    statusTagPayroc: function () {
      if (this.currentAccount) if (this.currentAccount.cc_payroc === 1 && this.currentAccount.cc_payroc_key) return '<span class="tag is-enabled">Enabled</span>'
      return null
    },
    statusTagElavon: function () {
      if (this.currentAccount) if (this.currentAccount.cc_ets === 1 && this.currentAccount.cc_ets_key) return '<span class="tag is-enabled">Enabled</span>'
      return null
    }
  },
  mounted () {
    // fetch the current state, we use this for stripe oauth
    var vm = this
    this.$Amplify.Auth.currentSession().then((data) => {
      if (data) vm.ss = data.accessToken.jwtToken
    }).catch((e) => {})
    // refresh account (using the set account function if we're refreshing this tab)
    if (this.$route.query.refreshtab) this.$store.dispatch('setAccount', this.currentAccount.id)
    // update component values with the current account information
    this.updateComponents()
    // Kickoff the Tour
    if (this.guideMode && !this.$route.query.fromoauth) this.TourSettingsPayments({ showButtons: true, hideDone: false })
  },
  watch: {
    currentAccount: {
      deep: true,
      handler: function () {
        this.updateComponents()
      }
    }
  },
  methods: {
    updateComponents () {
      this.etsToggle.model = this.currentAccount.cc_ets === 1
      this.etsKey.model = this.currentAccount.cc_ets_key
      this.cardsVisa.model = this.currentAccount.cc_visa === 1
      this.cardsMastercard.model = this.currentAccount.cc_mastercard === 1
      this.cardsDiscover.model = this.currentAccount.cc_discover === 1
      this.cardsAmex.model = this.currentAccount.cc_amex === 1
      this.cardsJcb.model = this.currentAccount.cc_jcb === 1
      this.paypalToggle.model = this.currentAccount.cc_paypal === 1
      this.paypalMode.model = this.currentAccount.cc_paypal_mode === 1
      this.paypalProduction.model = this.currentAccount.cc_paypal_prod
      this.paypalSandbox.model = this.currentAccount.cc_paypal_sandbox
      this.paypalCurrency.model = this.currentAccount.cc_paypal_currency === 1
      this.stripeToggle.model = this.currentAccount.cc_stripe === 1
      this.payrocToggle.model = this.currentAccount.cc_payroc === 1
      this.payrocUsername.model = this.currentAccount.cc_payroc_username
      this.payrocKey.model = this.currentAccount.cc_payroc_key
    },
    save (returnPath) {
      var accountUpdates = {
        account_id: this.currentAccount.id,
        cc_ets_key: this.etsKey.model,
        cc_paypal_prod: this.paypalProduction.model,
        cc_paypal_sandbox: this.paypalSandbox.model,
        cc_payroc_username: this.payrocUsername.model,
        cc_payroc_key: this.payrocKey.model,
        cc_ets: utils.convertToBinary(this.etsToggle.model),
        cc_visa: utils.convertToBinary(this.cardsVisa.model),
        cc_mastercard: utils.convertToBinary(this.cardsMastercard.model),
        cc_discover: utils.convertToBinary(this.cardsDiscover.model),
        cc_amex: utils.convertToBinary(this.cardsAmex.model),
        cc_jcb: utils.convertToBinary(this.cardsJcb.model),
        cc_paypal: utils.convertToBinary(this.paypalToggle.model),
        cc_paypal_mode: utils.convertToBinary(this.paypalMode.model),
        cc_paypal_currency: utils.convertToBinary(this.paypalCurrency.model),
        cc_stripe: utils.convertToBinary(this.stripeToggle.model),
        cc_payroc: utils.convertToBinary(this.payrocToggle.model)
      }
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.saveAccount('payments', accountUpdates,
        function (err, response) {
          vm.$store.dispatch('setAccount', vm.currentAccount.id) // refresh vuex account
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.$store.dispatch('setNotify', { state: true, text: 'Your payment settings have been updated.' })
            if (returnPath) {
              if (returnPath === 'refresh') {
                setTimeout(function () {
                  window.opener.location.reload(false) // refresh parent
                  window.close() // close tab
                }, 1000)
              } else {
                vm.$router.push({ name: returnPath })
              }
            }
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.oto-vertical-tabs-is-right {
  max-width: 900px;
}
.label-helper {
  text-align: left;
  -webkit-box-flex: 4!important;
  -ms-flex-positive: 4!important;
  flex-grow: 4!important;
}
.stripe-text {
  font-weight: bold;
  margin-top: 22px;
}
.stripe-button {
  width: 200px;
  height: 33px;
  margin-top: 30px;
}
.stripe-button:hover {
  opacity: 0.9;
  cursor: pointer;
}
.stripe-button:active {
  opacity: 0.8;
  cursor: pointer;
}
.setup-warning-area {
  padding-bottom: 20px;
}
</style>

export default {
  created () {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollListener, true)
  },
  methods: {
    scrollListener () {
      if (document.getElementById('sticky')) {
        var stickyOffset = document.getElementById('sticky').offsetTop
        var maxWidth = document.getElementById('sticky').offsetWidth
        if ((window.pageYOffset - 55 > stickyOffset) && window.pageYOffset > 150) {
          document.getElementById('sticky').classList.add('is-stuck')
          document.getElementById('sticky').style.maxWidth = maxWidth + 'px'
          document.getElementById('sticky').style.width = maxWidth + 'px'
        } else {
          document.getElementById('sticky').classList.remove('is-stuck')
          document.getElementById('sticky').style.maxWidth = ''
          document.getElementById('sticky').style.width = ''
        }
      }
    }
  }
}

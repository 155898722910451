export default {
  props: {
    props: {
      type: Object,
      required: true
    },
    label: {
      type: Object,
      default: () => ({
        style: null,
        class: null
      })
    },
    control: {
      type: Object,
      default: () => ({
        style: null,
        class: null
      })
    },
    focus: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isInputActive: false
    }
  },
  computed: {
    isRequired: function () {
      if (this.props.validate) {
        if (this.props.validate.required) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    inputType: function () {
      if (this.props.inputType) {
        return this.props.inputType
      } else {
        return 'text'
      }
    },
    optionGroupItems: function () {
      // these options are used for selects, radios, and checklists
      // they can be passed in directly as an object
      // or as a string of list items and line breaks
      if (typeof this.props.options === 'object') {
        return this.props.options
      } else if (typeof this.props.options === 'string') {
        var options = []
        var p = this.props.options.split(/\r?\n/)
        if (p) {
          p.forEach(function (option) {
            var n = option
            if (n) n = n.trim()
            var v = n
            options.push({ name: n, value: v })
          })
        }
        return options
      } else {
        return null
      }
    },
    fieldClasses: function () {
      var c = {}
      if (this.props) if (this.props.mini) c['is-horizontal'] = true
      if (this.props) if (this.props.fieldClass === 'no-margin') c['no-margin'] = true
      if (this.props) if (this.props.buttonText) c['has-addons'] = true
      if (this.props) if (this.props.fieldClass) c[this.props.fieldClass] = true
      return c
    },
    inputClasses: function () {
      var c = {}
      if (this.errors && this.props) if (this.errors.has(this.props.name)) c['is-danger'] = true
      if (this.props) if (this.props.inputClass) c[this.props.inputClass] = true
      return c
    }
  },
  watch: {
    isInputActive (val) {
      // commit input value conversion when no longer focused on input
      if (!val) {
        this.commitConversion()
      }
    },
    focus (val) {
      if (val) {
        if (this.props.ref) {
          if (this.$refs[this.props.ref]) {
            if (this.$refs[this.props.ref].$el) {
              this.$refs[this.props.ref].$el.focus()
            }
          }
        }
      }
    }
  },
  mounted () {
    if (this.focus) {
      if (this.props.ref) {
        if (this.$refs[this.props.ref]) {
          if (this.$refs[this.props.ref].$el) {
            this.$nextTick(() => this.$refs[this.props.ref].$el.focus())
          }
        }
      }
    }
    // always convert the input on initial load
    this.commitConversion()
  },
  methods: {
    onFocus () {
      this.isInputActive = true
      this.$emit('focus')
    },
    onBlur () {
      this.isInputActive = false
      this.$emit('blur')
    },
    onInput (value) {
      value = this.conversionWhileTyping(value)
      this.$emit('input', value)
    },
    commitConversion () {
      if (this.props) {
        if (this.props.model) {
          switch (this.props.convertTo) {
            case 'camelcase':
              this.props.model = this.$lodash.camelCase(this.props.model)
              break
            case 'startcase':
              this.props.model = this.props.model.replace(/\w+/g, this.$lodash.capitalize)
              break
            case 'capitalize':
              this.props.model = this.$lodash.capitalize(this.props.model)
              break
            case 'snakecase':
              this.props.model = this.$lodash.snakeCase(this.props.model)
              break
            case 'kebabcase':
              this.props.model = this.$lodash.kebabCase(this.props.model)
              break
            case 'number':
              if (isNaN(this.props.model)) {
                this.props.model = null // default value if this isn't a number
              }
              break
            case 'usphone':
              var cleaned = ('' + this.props.model).replace(/\D/g, '')
              var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
              if (match) {
                this.props.model = '(' + match[1] + ') ' + match[2] + '-' + match[3]
              } else {
                this.props.model = null
              }
              break
            case 'price':
              var newValue = this.props.model.replace(/,/g, '')
              newValue = parseFloat(newValue).toFixed(2)
              if (isNaN(newValue)) {
                newValue = null // default value if we can't make a price
              } else {
                // add in commas
                newValue = newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              this.props.model = newValue
              break
            case 'float':
              if (typeof this.props.model !== 'string') this.props.model = this.props.model.toString()
              var float = this.props.model.replace(/,/g, '')
              float = parseFloat(float).toFixed(2)
              if (isNaN(float)) {
                float = null // default value if we can't make a floating number
              }
              this.props.model = float
              break
          }
        }
        // below is support for "selectInputs"
        // pretty sloppy here, might want to consolidate this
        if (this.props.input) {
          if (this.props.input.model) {
            if (this.props.input.convertTo) {
              switch (this.props.input.convertTo) {
                case 'price':
                  var newPrice = this.props.input.model.replace(/,/g, '')
                  newPrice = parseFloat(newPrice).toFixed(2)
                  if (isNaN(newPrice)) {
                    newPrice = null // default value if we can't make a price
                  } else {
                    // add in commas
                    newPrice = newPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  this.props.input.model = newPrice
                  break
              }
            }
          }
        }
      }
    },
    conversionWhileTyping (value) {
      if (value && this.props) {
        switch (this.props.convertWhileTyping) {
          case 'usphone':
            var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
            value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
            break
        }
      }
      return value
    }
  },
  inject: {
    $validator: '$validator'
  }
}

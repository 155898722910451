<template>
  <div>
    <TextInput
      :props="acyWebsite"
      v-model="acyWebsite.model"
      @blur="errorCheck()"/>
  </div>
</template>

<script>
import TextInput from '@/components/elements/TextInput'

export default {
  components: {
    TextInput
  },
  props: ['value'],
  data () {
    return {
      hasError: false,
      acyWebsite: {
        model: null,
        name: 'acyWebsite',
        ref: 'acyWebsite',
        label: 'Your Full Website Address',
        placeholder: 'https://www.mybusinessdomain.com',
        fieldClass: 'oto-input-field-text-long'
      }
    }
  },
  computed: {
    settings: function () {
      if (this.acyWebsite.model) {
        var o = {
          'access_token': null,
          'token_secret': null,
          'name': this.acyWebsite.model,
          'options': null,
          'has_error': false
        }
        o['has_error'] = this.hasError
        return o
      } else {
        return {}
      }
    }
  },
  watch: {
    'acyWebsite.model' () {
      this.$emit('input', this.settings)
    },
    'value.has_error' () {
      this.$emit('input', this.settings)
    }
  },
  mounted () {
    if (this.value) {
      this.acyWebsite.model = this.value.name
    }
  },
  methods: {
    errorCheck: function () {
      if (this.acyWebsite.model) {
        if (this.acyWebsite.model.match(/^https:\/\/.*\.[a-z]{2,}$/g)) {
          this.hasError = false
          this.$validator.errors.clear()
        } else {
          this.hasError = true
          const field = this.$validator.fields.find({ name: 'acyWebsite', scope: this.$options.scope })
          this.$validator.errors.add({
            id: field.id,
            field: 'acyWebsite',
            msg: 'Please enter your full, secure, website address with no trailing slash (i.e. https://www.yourbusiness.com)',
            scope: this.$options.scope
          })
          field.setFlags({
            invalid: true,
            valid: false,
            validated: true
          })
        }
      }
    }
  }
}
</script>

<template>
  <div
    :class="{ 'is-active': value.modalOpen }"
    class="modal">
    <div class="modal-background"/>
    <div class="buttons">
      <a
        v-if="value.type === 'iframe' && !value.hideViewToggle"
        class="button is-rounded is-oto-tiny is-hidden-touch"
        @click="toggleMobile()">
        <span v-if="mobileView">
          <span class="icon is-small">
            <i class="fas fa-desktop"/>
          </span>
          <span>View Desktop</span>
        </span>
        <span v-else>
          <span class="icon is-small">
            <i class="fas fa-mobile-alt"/>
          </span>
          <span>View Mobile</span>
        </span>
      </a>
      <a
        v-if="value.type === 'iframe'"
        class="button is-rounded is-oto-tiny"
        @click="openUrl(cleanURL)">
        <span class="icon is-small">
          <i class="fas fa-external-link-alt"/>
        </span>
        <span>New Tab</span>
      </a>
      <a
        v-if="value.type === 'iframe'"
        class="button is-rounded is-oto-tiny"
        @click="copyText(cleanURL)">
        <span class="icon is-small">
          <i class="fas fa-paste"/>
        </span>
        <span>Copy URL</span>
      </a>
      <a
        v-if="value.type !== 'card'"
        class="button is-rounded is-primary is-oto-tiny"
        @click="closeModal">
        <span class="icon is-small">
          <i class="fas fa-times-circle"/>
        </span>
        <span>Close</span>
      </a>
    </div>
    <div
      :class="{ 'oto-iframe-modal': value.type === 'iframe', 'oto-iframe-force-mobile': mobileView }"
      class="modal-content">
      <div
        v-if="value.type === 'card'"
        class="card">
        <header class="card-header">
          <p class="card-header-title">
            {{ value.title }}
          </p>
        </header>
        <div class="card-content">
          <div
            v-if="value.video"
            class="oto-help-video-holder">
            <video
              width="320"
              height="240"
              controls>
              <source
                src="movie.mp4"
                type="video/mp4">
              <source
                src="movie.ogg"
                type="video/ogg">
              Your browser does not support the video tag.
            </video>
          </div>
          {{ value.text }}
        </div>
        <footer class="card-footer">
          <a
            class="card-footer-item"
            @click="closeModal">
            {{ value.button }}
          </a>
        </footer>
      </div>
      <div
        v-if="value.type === 'image'"
        class="image is-4by3">
        <img :src="value.image">
      </div>
      <div
        v-if="value.type === 'iframe'"
        class="iframe-holder">
        <iframe :src="value.src" />
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/plugins/utils.js'
/**
* PROPS
* takes :v-model object with params
*   modalOpen: boolean
*/
export default {
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      mobileView: false
    }
  },
  computed: {
    cleanURL: function () {
      if (this.value) {
        if (this.value.src) {
          if (typeof this.value.src === 'string') {
            return this.value.src.replace('skiphit=1', '')
          }
        }
      }
      return null
    }
  },
  mounted () {
    // since this is a modal, we're going to move it to an outside div
    document.body.appendChild(this.$el)
  },
  destroyed () {
    // destroy what we appended, the append functions more like vue then
    document.body.removeChild(this.$el)
  },
  methods: {
    openModal () {
      this.value.modalOpen = true
    },
    closeModal () {
      this.value.modalOpen = false
      document.getElementsByTagName('html')[0].classList.remove('model-is-open')
    },
    toggleMobile () {
      this.mobileView = !this.mobileView
    },
    openUrl (url) {
      window.open(url)
    },
    copyText (text) {
      var vm = this
      utils.copyToClipboard(text, function (err, copied) {
        if (err) {}
        if (copied) {
          var ct = 'The campaign webpage URL has been copied to your clipboard.'
          if (vm.value.copyConfirmText) ct = vm.value.copyConfirmText
          vm.closeModal()
          vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
          vm.$store.dispatch('setNotify', { state: true, text: ct })
        }
      })
    }
  }
}
</script>

<style scoped>
.is-oto-tiny {
  height: 12px;
  padding: 15px!important;
}
.oto-iframe-modal,
.oto-iframe-force-mobile {
  width: calc(100% - 50px);
}
.oto-help-video-holder {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
}
</style>

<template>
  <div>
    <div class="oto-second-heading is-hidden-mobile">Account Users</div>
    <p class="padding-bottom">The following users have access to this CampaignPilot business. Use the form below to add or remove access.</p>
    <div class="table-container oto-no-margin-bottom">
      <table
        v-if="users"
        class="table no-margin">
        <thead v-if="users.length>0">
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Status</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tfoot>
          <tr v-if="showCreateUser">
            <th colspan="3">
              <p class="create-user-text">
                <span>The email you provided does not have a CampaignPilot account. You can create a new account by completeing the form below. Once the user account is created it will be added to this CampaignPilot business.</span>
              </p>
              <p class="create-user-email">
                <span class="icon">
                  <i class="fas fa-envelope"/>
                </span>
                <span>{{email.model}}</span>
              </p>
              <TextInput
                :props="newUser.name"
                v-model="newUser.name.model" />
              <div class="field oto-input-field-text-medium">
                <div class="control has-icons-left">
                  <input
                    v-model="newUser.password.model"
                    :id="newUser.password.ref"
                    :key="newUser.password.key"
                    :ref="newUser.password.ref"
                    :name="newUser.password.name"
                    :placeholder="newUser.password.placeholder"
                    type="password"
                    class="input">
                  <span class="icon is-medium is-left">
                    <i class="fas fa-lock"></i>
                  </span>
                </div>
                <p class="tip">
                  <span>Leave password field blank to auto-generate.</span>
                </p>
              </div>
            </th>
            <th class="bottom">
              <a
                class="button is-rounded"
                @click="cancelCreate">Cancel</a>
              <a
                class="button is-rounded is-primary"
                @click="createUser">Create</a>
            </th>
          </tr>
          <tr v-else>
            <th colspan="3">
              <TextInput
                :props="email"
                v-model="email.model"/>
              <p class="tip">
                <span class="icon">
                  <i class="fas fa-exclamation-circle"/>
                </span>
                <span>The Status toggle is a <i>system-wide</i> toggle, it will set the user account Active or Inactive for <i>all</i> of CampaignPilot. An inactive user account cannot access CampaignPilot.</span>
              </p>
            </th>
            <th>
              <a
                class="button is-rounded"
                @click="addUser">Add</a>
            </th>
          </tr>
        </tfoot>
        <tbody>
          <tr
            v-for="(u, i) in users"
            :key="'u' + i">
            <td>
              {{ u.name }}
            </td>
            <td>
              {{ u.email }}
            </td>
            <td class="accountuser-toggle">
              <Toggler
                :props="u.toggle"
                v-model="u.toggle.model"
                @input="toggleUser(u.id)"/>
            </td>
            <td>
              <span
                class="icon is-small show-hand"
                @click="deleteUser(u.id)">
                <i class="fas fa-times-circle"/>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TextInput from '@/components/elements/TextInput'
import Toggler from '@/components/elements/Toggler'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'

export default {
  components: {
    TextInput,
    Toggler
  },
  mixins: [xhr],
  data () {
    return {
      users: null,
      showCreateUser: false,
      email: {
        model: null,
        name: 'email',
        ref: 'email',
        icon: 'fas fa-envelope',
        placeholder: 'name@domain.com',
        fieldClass: 'oto-input-field-text-medium',
        inputType: 'email',
        validate: { required: true }
      },
      newUser: {
        name: {
          model: null,
          name: 'new-name',
          ref: 'new-name',
          placeholder: 'User\'s Full Name',
          fieldClass: 'oto-input-field-text-medium',
          icon: 'fas fa-user'
        },
        password: {
          model: null,
          name: 'new-password',
          ref: 'new-password',
          key: 'password-input',
          placeholder: 'A Strong Password',
          fieldClass: 'oto-input-field-text-medium'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    })
  },
  mounted () {
    this.fetchUsers()
  },
  methods: {
    fetchUsers: function () {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchAccountUsers(vm.currentAccount.id,
        function (err, users) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.users = users
            var ct = 0
            vm.users.forEach(function (user) {
              user.toggle = {}
              if (user.active === 1) {
                user.toggle.model = true
              } else {
                user.toggle.model = false
              }
              user.toggle.name = 'toggle' + ct
              user.toggle.ref = 'toggle' + ct
              user.toggle.options = { checked: 'Active', unchecked: 'Inactive' }
              user.toggle.height = 30
              user.toggle.width = 100
              ct++
            })
          }
        }
      )
    },
    addUser: function () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$store.dispatch('setLoadingState', true)
          var vm = this
          engineApi.addAccountUser(vm.currentAccount.id, vm.email.model,
            function (err) {
              vm.$store.dispatch('setLoadingState', false)
              if (err) {
                vm.handleXhrError(err)
                if (err.response) {
                  if (err.response.status === 418) {
                    // the provided user does not exist in the system
                    // setting this flag will enable the UI to create them
                    vm.showCreateUser = true
                  }
                }
                // if the flag isn't on, then there was some other error
                if (!vm.showCreateUser) vm.$store.dispatch('setNotify', { state: true, color: 'danger', text: 'The user could not be added to the account.' })
              } else {
                vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
                vm.$store.dispatch('setNotify', { state: true, text: vm.email.model + ' has been added to the account.' })
                vm.fetchUsers()
                vm.email.model = null
              }
            }
          )
        }
      })
    },
    deleteUser: function (userId) {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.deleteAccountUser(vm.currentAccount.id, userId,
        function (err, res) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.fetchUsers()
          }
        }
      )
    },
    toggleUser: function (userId) {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.toggleAccountUser(vm.currentAccount.id, userId,
        function (err, res) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.fetchUsers() // refresh the screen
          }
        }
      )
    },
    cancelCreate: function () {
      this.showCreateUser = false
      this.newUser.name.model = null
      this.newUser.password.model = null
    },
    createUser: function () {
      if (this.newUser.name.model) {
        this.$scrollTo('#dashboard', 200, { easing: 'ease' })
        this.$store.dispatch('setLoadingState', true)
        var vm = this
        engineApi.createUser(vm.newUser.name.model, vm.email.model, vm.newUser.password.model, vm.currentAccount.id,
          function (err, response) {
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
              vm.$store.dispatch('setNotify', { state: true, color: 'danger', text: 'There was a problem creating this user account.' })
            } else {
              var message = vm.email.model + ' has been created.'
              if (!vm.newUser.password.model) {
                if (response) {
                  if (response.pass) {
                    message += ' The password for the account is: ' + response.pass
                  }
                }
              }
              vm.cancelCreate()
              vm.email.model = null
              vm.$validator.reset() // reset the errors since we just deleted the email field
              vm.$store.dispatch('setNotify', { state: true, text: message, noAutoClose: true })
              vm.fetchUsers()
            }
          }
        )
      }
    }
  }
}
</script>

<style scoped>
.accountuser-toggle .field .field-body .toggle-buffer {
  display: none;
}
tfoot th {
  vertical-align: center;
}
.tip {
  font-size: 0.8em;
  font-weight: 400!important;
}
.button {
  margin-bottom: 10px;
}
.bottom {
  vertical-align: bottom;
}
.create-user-text {
  font-size: 0.8em;
  font-weight: 400!important;
  padding-bottom: 8px;
}
.create-user-email {
  font-size: 1em;
  font-weight: 600!important;
  padding-bottom: 16px;
  margin-left: 7px;
}
</style>

<template>
  <div>
    <div class="oto-second-heading is-hidden-mobile">Color Swatch Builder</div>
    <p class="padding-bottom">This swatch will be available as an option when customizing the theme of a campaign.</p>
    <div class="columns is-mobile">
      <div class="column oto-settings-swatch-column">
        <table class="table no-margin is-borderless">
          <tbody>
            <tr>
              <td>
                <strong>Primary</strong>
              </td>
              <td class="oto-color-holder">
                <v-swatches
                  v-model="primary"
                  :swatches="codes"
                  swatch-size="20"
                  row-length="19"
                  shapes="circles"
                  popover-to="left"
                  fallback-ok-text="OK"
                  fallback-ok-class="button is-primary is-rounded"
                  show-fallback/>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Secondary</strong>
              </td>
              <td class="oto-color-holder">
                <v-swatches
                  v-model="secondary"
                  :swatches="codes"
                  swatch-size="20"
                  row-length="19"
                  shapes="circles"
                  popover-to="left"
                  fallback-ok-text="OK"
                  fallback-ok-class="button is-primary is-rounded"
                  show-fallback/>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Alternate</strong>
              </td>
              <td class="oto-color-holder">
                <v-swatches
                  v-model="alternate"
                  :swatches="codes"
                  swatch-size="20"
                  row-length="19"
                  shapes="circles"
                  popover-to="left"
                  fallback-ok-text="OK"
                  fallback-ok-class="button is-primary is-rounded"
                  show-fallback/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- SAVE BUTTON -->
    <div class="oto-settings-save-button">
      <a
        class="button is-primary is-rounded"
        @click="save">
        <span class="icon is-small">
          <i class="fas fa-save"/>
        </span>
        <span>Save Changes</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VSwatches from 'vue-swatches'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import 'vue-swatches/dist/vue-swatches.css'

export default {
  components: {
    VSwatches
  },
  mixins: [xhr],
  data () {
    return {
      primary: null,
      secondary: null,
      alternate: null,
      codes: [
        ['#ffebee', '#fce4ec', '#f3e5f5', '#ede7f6', '#e8eaf6', '#e3f2fd', '#e1f5fe', '#e0f7fa', '#e0f2f1', '#e8f5e9', '#f1f8e9', '#f9fbe7', '#fffde7', '#fff8e1', '#fff3e0', '#fbe9e7', '#efebe9', '#fafafa', '#eceff1'],
        ['#ffcdd2', '#f8bbd0', '#e1bee7', '#d1c4e9', '#c5cae9', '#bbdefb', '#b3e5fc', '#b2ebf2', '#b2dfdb', '#c8e6c9', '#dcedc8', '#f0f4c3', '#fff9c4', '#ffecb3', '#ffe0b2', '#ffccbc', '#d7ccc8', '#f5f5f5', '#cfd8dc'],
        ['#ef9a9a', '#f48fb1', '#ce93d8', '#b39ddb', '#9fa8da', '#90caf9', '#81d4fa', '#80deea', '#80cbc4', '#a5d6a7', '#c5e1a5', '#e6ee9c', '#fff59d', '#ffe082', '#ffcc80', '#ffab91', '#bcaaa4', '#eeeeee', '#b0bec5'],
        ['#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb', '#64b5f6', '#4fc3f7', '#4dd0e1', '#4db6ac', '#81c784', '#aed581', '#dce775', '#fff176', '#ffd54f', '#ffb74d', '#ff8a65', '#a1887f', '#e0e0e0', '#90a4ae'],
        ['#ef5350', '#ec407a', '#ab47bc', '#7e57c2', '#5c6bc0', '#42a5f5', '#29b6f6', '#26c6da', '#26a69a', '#66bb6a', '#9ccc65', '#d4e157', '#ffee58', '#ffca28', '#ffa726', '#ff7043', '#8d6e63', '#bdbdbd', '#78909c'],
        ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#9e9e9e', '#607d8b'],
        ['#e53935', '#d81b60', '#8e24aa', '#5e35b1', '#3949ab', '#1e88e5', '#039be5', '#00acc1', '#00897b', '#43a047', '#7cb342', '#c0ca33', '#fdd835', '#ffb300', '#fb8c00', '#f4511e', '#6d4c41', '#757575', '#546e7a'],
        ['#d32f2f', '#c2185b', '#7b1fa2', '#512da8', '#303f9f', '#1976d2', '#0288d1', '#0097a7', '#00796b', '#388e3c', '#689f38', '#afb42b', '#fbc02d', '#ffa000', '#f57c00', '#e64a19', '#5d4037', '#616161', '#455a64'],
        ['#c62828', '#ad1457', '#6a1b9a', '#4527a0', '#283593', '#1565c0', '#0277bd', '#00838f', '#00695c', '#2e7d32', '#558b2f', '#9e9d24', '#f9a825', '#ff8f00', '#ef6c00', '#d84315', '#4e342e', '#424242', '#37474f'],
        ['#b71c1c', '#880e4f', '#4a148c', '#311b92', '#1a237e', '#0d47a1', '#01579b', '#006064', '#004d40', '#1b5e20', '#33691e', '#827717', '#f57f17', '#ff6f00', '#e65100', '#bf360c', '#3e2723', '#212121', '#263238'],
        ['#ffffff', '#000000']
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    }),
    accountInitals: function () {
      if (this.currentAccount.name) {
        var initials = this.currentAccount.name.match(/\b\w/g) || []
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
        return initials
      } else return null
    },
    primaryFont: function () {
      if (this.primary) {
        return this.pickTextColorBasedOnBgColorAdvanced(this.primary, '#ffffff', '#000000')
      } else return null
    },
    secondaryFont: function () {
      if (this.secondary) {
        return this.pickTextColorBasedOnBgColorAdvanced(this.secondary, '#ffffff', '#000000')
      } else return null
    },
    alternateFont: function () {
      if (this.alternate) {
        return this.pickTextColorBasedOnBgColorAdvanced(this.alternate, '#ffffff', '#000000')
      } else return null
    }
  },
  mounted () {
    var colors = this.currentAccount.style_colors
    if (colors) colors = JSON.parse(colors)
    if (colors) {
      this.primary = colors.primary
      this.secondary = colors.secondary
      this.alternate = colors.alternate
    }
  },
  methods: {
    pickTextColorBasedOnBgColorAdvanced (bgColor, lightColor, darkColor) {
      // https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color/3943023
      var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor
      var r = parseInt(color.substring(0, 2), 16)
      var g = parseInt(color.substring(2, 4), 16)
      var b = parseInt(color.substring(4, 6), 16)
      var uicolors = [r / 255, g / 255, b / 255]
      var c = uicolors.map((col) => {
        if (col <= 0.03928) {
          return col / 12.92
        }
        return Math.pow((col + 0.055) / 1.055, 2.4)
      })
      var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2])
      return (L > 0.279) ? darkColor : lightColor // this used to be > 0.179
    },
    save () {
      var colors = {
        primary: this.primary,
        primaryFont: this.primaryFont,
        secondary: this.secondary,
        secondaryFont: this.secondaryFont,
        alternate: this.alternate,
        alternateFont: this.alternateFont
      }
      var accountUpdates = {
        account_id: this.currentAccount.id,
        style_colors: colors
      }
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.saveAccount('styles', accountUpdates,
        function (err, response) {
          vm.$store.dispatch('setAccount', vm.currentAccount.id) // refresh vuex account
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (process.env.NODE_ENV === 'development') console.log('saved...')
            vm.$store.dispatch('setNotify', { state: true, text: 'Your styles have been updated.' })
          }
        }
      )
    }
  }
}
</script>

<style>
.vue-swatches__trigger {
  border: 1px solid #efefef;
}
.vue-swatches__container {
  max-height: 400px!important;
}
.oto-color-holder {
  position: relative;
  vertical-align: middle!important;
}
.oto-color-letter {
    z-index: 20;
    position: absolute;
    width: 40px;
    text-align: center;
    margin-top: 9px;
    pointer-events: none;
}
.oto-settings-swatch-column {
  max-width: 210px;
}
</style>

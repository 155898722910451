<template>
  <div class="main">
    <Notify/>
    <section
      v-if="firstName"
      class="main-holder">
      <div class="inner-holder">
        <div
          v-if="step===0"
          class="single-step">
          <div class="top-logo-holder">
            <img src="../assets/imgs/brand/campaign-pilot-fox-w-back.png">
          </div>
          <h1 class="heading-text">Welcome to CampaignPilot, {{ firstName }}!</h1>
          <p class="regular-text with-bottom-margin">Now that you have a CampaignPilot account we'll get started by setting up your business. This process should only take a minute.</p>
          <button
            class="button is-primary is-rounded longer-button"
            @click="next()">
            <span class="is-hidden-mobile">Get Started With A New Business</span>
            <span class="is-hidden-tablet">Get Started</span>
          </button>
          <p class="regular-text with-small-top-margin with-huge-bottom-margin has-text-weight-medium">
            <a
              href="#"
              @click="join()">I want to join an existing business</a>
          </p>
          <div class="bottom-logo-holder">
            <img src="../assets/cp-logo.png">
          </div>
        </div>
        <div v-else-if="step>0">
          <div class="wizard-top-bar">
            <div class="sm-logo-holder">
              <img src="../assets/cp-logo-small.png">
            </div>
          </div>
          <div
            v-if="step === 1"
            class="wizard-content-area">
            <div class="columns">
              <div class="column is-5 extra-padding-right">
                <h2 class="heading2-text">Tell us about your business</h2>
                <p class="regular-text">CampaignPilot customizes all marketing content specifically for your business. Enter your business name and phone number as it should appear to your customers.</p>
              </div>
              <div class="column extra-padding-left">
                <div>
                  <TextInput
                    :props="name"
                    key="name"
                    v-model="name.model"/>
                  <TextInput
                    :props="phone"
                    key="phone"
                    v-model="phone.model"/>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="step === 2"
            class="wizard-content-area">
            <div class="columns">
              <div class="column is-5 extra-padding-right">
                <h2 class="heading2-text">Tell us your business location</h2>
                <p class="regular-text">Please enter the street address for your business. CampaignPilot will use this to provide your customers with maps and directions to your business. This information is also used to determine your timezone and currency settings.</p>
              </div>
              <div class="column extra-padding-left">
                <div>
                  <TextInput
                    :props="street"
                    key="street"
                    v-model="street.model"/>
                  <TextInput
                    :props="street2"
                    key="street2"
                    v-model="street2.model"/>
                  <TextInput
                    :props="city"
                    key="city"
                    v-model="city.model"/>
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <StateSelect
                        ref="state-pick"
                        :props="state"
                        key="state"
                        v-model="state.model"/>
                      <TextInput
                        :props="postal"
                        key="postal"
                        v-model="postal.model"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="step === 3"
            class="single-step wizard-content-area">
            <div class="top-logo-holder">
              <img src="../assets/imgs/brand/campaign-pilot-fox-w-back.png">
            </div>
            <h1 class="heading-text">You're ready to start!</h1>
            <p class="regular-text">We have everything we need to take your marketing to new heights. Let's continue to the CampaignPilot Dashboard where we can build your first marketing campaign.</p>
          </div>
          <div class="wizard-bottom-bar">
            <div class="columns">
              <div class="column is-half">
                <div class="wizard-meter">
                  <div :class="{'active':step===1}"></div><div :class="{'active':step===2}"></div><div :class="{'active':step===3}"></div>
                </div>
              </div>
              <div class="column is-half wizard-buttons">
                <div class="field is-grouped is-pulled-right">
                  <p class="control">
                    <button
                      class="button is-white is-rounded"
                      @click="back()">
                      <span>&#60; Back</span>
                    </button>
                  </p>
                  <p class="control">
                    <button
                      :class="{'is-loading': currentLoading}"
                      class="button is-primary is-rounded"
                      @click="next()">
                      <span v-if="step>2">Let's Go &#62;</span>
                      <span v-else>Next &#62;</span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="step===-1">
          <div class="wizard-top-bar">
            <div class="sm-logo-holder">
              <img src="../assets/cp-logo-small.png">
            </div>
          </div>
          <div class="wizard-content-area">
            <div class="columns">
              <div class="column is-5 extra-padding-right">
                <h2 class="heading2-text">Request to join an existing business</h2>
                <p class="regular-text">You can associate your new CampaignPilot account with an existing business. Enter the business name you're associated with and we'll email you when your account has been connected.</p>
              </div>
              <div class="column extra-padding-left">
                <div>
                  <TextInput
                    :props="requestBusiness"
                    key="requestBusiness"
                    v-model="requestBusiness.model"/>
                </div>
                <div class="field">
                  <p class="control">
                    <button
                      :class="{'is-loading': currentLoading}"
                      class="button is-primary is-rounded longer-button"
                      @click="next()">
                      <span>Send Request</span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="wizard-bottom-bar">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <p class="control">
                    <button
                      class="button is-white is-rounded"
                      @click="back()">
                      <span>&#60; Back</span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="step===-2"
          class="single-step">
          <div class="top-logo-holder">
            <img src="../assets/imgs/brand/campaign-pilot-fox-w-back.png">
          </div>
          <h1 class="heading-text">Your request is processing</h1>
          <p class="regular-text">We have received your request to join <strong>{{ requestBusiness.model }}</strong>. You will be able to access CampaignPilot once your information has been verified. You will be notified via email as soon as your request has been approved.</p>
          <div class="baseline-logo-holder">
            <img src="../assets/cp-logo.png">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import engineApi from '@/plugins/engine-api.js'
import StateSelect from '@/components/elements/StateSelect.vue'
import TextInput from '@/components/elements/TextInput'
import Notify from '@/components/elements/Notify'

export default {
  components: {
    Notify,
    StateSelect,
    TextInput
  },
  data () {
    return {
      step: 0,
      name: {
        name: 'business-name',
        ref: 'business-name',
        label: 'Business Name',
        model: null,
        validate: { required: true },
        hideRequired: true
      },
      phone: {
        name: 'phone-number',
        ref: 'phone-number',
        label: 'Phone Number',
        convertTo: 'usphone',
        placeholder: '(555) 555-5555',
        model: null,
        validate: { required: true },
        hideRequired: true
      },
      street: {
        name: 'street',
        ref: 'street',
        label: 'Street',
        model: null,
        validate: { required: true },
        hideRequired: true
      },
      street2: {
        name: 'street2',
        ref: 'street2',
        model: null
      },
      city: {
        name: 'city',
        ref: 'city',
        label: 'City',
        model: null,
        validate: { required: true },
        hideRequired: true
      },
      state: {
        name: 'state',
        ref: 'state',
        label: 'State',
        class: 'is-fullwidth',
        model: null,
        validate: { required: true },
        hideRequired: true
      },
      postal: {
        name: 'postal',
        ref: 'postal',
        label: 'Zip',
        model: null,
        validate: { required: true },
        hideRequired: true
      },
      requestBusiness: {
        name: 'request-business-name',
        ref: 'request-business-name',
        label: 'Business Name',
        model: null,
        validate: { required: true },
        hideRequired: true
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      currentLoading: 'currentLoading'
    }),
    firstName: function () {
      if (this.currentUser) {
        if (this.currentUser.name) {
          return this.currentUser.name.split(' ')[0]
        }
      }
      return null
    }
  },
  methods: {
    next () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.step === -1) {
            // this is the join step, try to send a request email
            this.request()
          } else if (this.step === 3) {
            // this is the final step, try to create the business
            this.create()
          } else {
            this.step++
          }
        }
      })
    },
    back () {
      this.step--
      if (this.step < 0) this.step = 0
    },
    join () {
      this.step = -1
    },
    create () {
      var payload = {
        name: this.name.model,
        phone: this.phone.model,
        street: this.street.model,
        street2: this.street2.model,
        city: this.city.model,
        state: this.state.model,
        postal: this.postal.model
      }
      if (this.currentUser) {
        if (this.currentUser.email) payload.email = this.currentUser.email
        if (this.currentUser.id) payload.userId = this.currentUser.id
      }
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.createBusiness(payload, function (err) {
        vm.$store.dispatch('setLoadingState', false)
        if (err) {
          vm.$store.dispatch('setNotify', { state: true, color: 'danger', text: 'There was an issue setting up your business. Please try again. If you continue to have issues please contact us.' })
        } else {
          if (process.env.NODE_ENV !== 'development') window.gtag('event', 'user_action', { 'action': 'createbiz' })
          // send the user to the welcome tour
          vm.$router.replace({ name: 'route-engine-tours' })
        }
      })
    },
    request () {
      var payload = {
        requestBusiness: this.requestBusiness.model
      }
      if (this.currentUser) {
        if (this.currentUser.name) payload.name = this.currentUser.name
        if (this.currentUser.email) payload.email = this.currentUser.email
      }
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.joinBusiness(payload, function (err) {
        vm.$store.dispatch('setLoadingState', false)
        if (err) {
          vm.$store.dispatch('setNotify', { state: true, color: 'danger', text: 'There was an issue submitting your request. Please try again. If you continue to have issues please contact us.' })
        } else {
          if (process.env.NODE_ENV !== 'development') window.gtag('event', 'user_action', { 'action': 'requestbiz' })
          vm.step = -2 // show request confirmation page
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.heading-text {
  font-weight: 600;
  font-size: 1.25rem;
  font-height: 1.25;
  margin: 20px 0;
}
.heading2-text {
  font-weight: 600;
  font-size: 1.15rem;
  font-height: 1.15;
  margin-bottom: 20px;
}
.regular-text {
  font-size: 1rem;
}
.with-top-margin {
  margin-top: 20px;
}
.with-bottom-margin {
  margin-bottom: 20px;
}
.with-small-top-margin {
  margin-top: 10px;
}
.with-small-bottom-margin {
  margin-bottom: 10px;
}
.with-large-top-margin {
  margin-top: 30px;
}
.with-large-bottom-margin {
  margin-bottom: 30px;
}
.with-huge-top-margin {
  margin-top: 60px
}
.with-huge-bottom-margin {
  margin-bottom: 60px
}
.main {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  min-width: 100vw;
  background-color: #F5F5F5;
}
.main-holder {
  position: relative;
  min-height: 100vh;
  height: 100vh;
  width: 80vw;
  margin: 0 auto;
  max-width: 800px;
}
.inner-holder {
  margin: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  min-height: 625px;
  background-color: white;
  padding: 60px;
}
.top-logo-holder img {
  max-width: 125px;
  height: auto;
}
.sm-logo-holder img {
  max-width: 40px;
  height: auto;
}
.bottom-logo-holder img,
.baseline-logo-holder img {
  max-width: 250px;
  height: auto;
}
.wizard-top-bar {
  height: 65px;
}
.wizard-bottom-bar {
  position: absolute;
  bottom: 60px;
  width: 100%;
}
.wizard-bottom-bar > div {
  width: 87%;
}
.wizard-meter {
  display: flex;
  margin-top: 15px;
}
.wizard-meter > div {
  border-left: 5px solid #D3D3D2;
  border-right: 5px solid #D3D3D2;
  height: 10px;
  border-radius: 20px;
  margin-right: 5px;
  transition: border-width ease-out 0.3s;
}
.wizard-meter > div.active {
  border-left: 25px solid $primary;
  border-right: none;
  height: 10px;
  border-radius: 20px;
}
.wizard-buttons {
  text-align:right;
}
.single-step {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}
.longer-button {
  width: 60%;
}
.extra-padding-left {
  padding-left: 1.25rem;
}
.extra-padding-right {
  padding-right: 1.25rem;
}
.baseline-logo-holder {
  position: absolute;
  bottom: 30px;
  left: calc(50% - 125px);
  margin: 0 auto;
}
@media only screen and (min-width : 0px) and (max-width : $mobile-max) {
  .main {
    background-color: white;
  }
  .main-holder {
    margin: 0px;
    width: 100vw;
  }
  .inner-holder {
    position: static;
    background-color: white;
    padding: 30px;
    -ms-transform: none;
    transform: none;
    min-height: 100%;
  }
  .wizard-bottom-bar {
    position: static;
    width: 100%;
    background-color: white;
    padding-bottom: 30px;
  }
  .wizard-meter {
    display: none;
  }
  .wizard-top-bar {
    height: 50px;
  }
  .longer-button {
    width: auto;
  }
}
</style>

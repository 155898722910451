import axios from 'axios'
import Vue from 'vue'

function getToken () {
  return Vue.prototype.$Amplify.Auth.currentSession().then((data) => {
    if (data) {
      return data.accessToken.jwtToken
    }
  }).catch((e) => {
    return null
  })
}

export default {
  authFacebook (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var providerOptions = {
        title: 'Facebook',
        url: 'https://www.facebook.com/v11.0/dialog/oauth?',
        size: 'width=600,height=700',
        params: {
          client_id: process.env.VUE_APP_FACEBOOK_ID,
          redirect_uri: process.env.VUE_APP_GATEWAY_URL + '/oauth/facebook',
          state: accountId + 'state=' + cognitoAccessToken,
          scope: 'pages_show_list,pages_manage_posts,read_insights,instagram_basic,instagram_content_publish,instagram_manage_insights',
          display: 'popup'
        }
      }
      callback(null, providerOptions)
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  authTwitter (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/oauth/twitter', { 'headers': { 'state': cognitoAccessToken, 'mode': 'request_token', 'account_id': accountId } })
        .then((response) => {
          var providerOptions = {
            title: 'Twitter',
            url: 'https://api.twitter.com/oauth/authorize?',
            size: 'width=500,height=500',
            params: {
              oauth_token: response.data.token
            }
          }
          callback(null, providerOptions)
        }, (error) => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  authGoogle (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var providerOptions = {
        title: 'Google',
        url: 'https://accounts.google.com/o/oauth2/v2/auth?',
        size: 'width=600,height=700',
        params: {
          client_id: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
          redirect_uri: process.env.VUE_APP_GATEWAY_URL + '/oauth/google',
          scope: 'https://www.googleapis.com/auth/business.manage',
          access_type: 'offline',
          state: accountId + 'state=' + cognitoAccessToken,
          response_type: 'code'
        }
      }
      callback(null, providerOptions)
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  }
}

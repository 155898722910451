<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h1 class="title">
              Campaigns
            </h1>
            <h2 class="subtitle">
              Create a full-scale marketing campaign in minutes.
            </h2>
            <Scheduled/>
          </div>
        </div>
      </div>
    </section>
    <FloatingActionButton :props="{ mobileOnly:true, path:'route-engine-campaigns-start' }"/>
  </div>
</template>

<script>
import Scheduled from '@/components/dashboard/campaigns/Scheduled'
import FloatingActionButton from '@/components/elements/FloatingActionButton'

export default {
  components: {
    Scheduled,
    FloatingActionButton
  }
}
</script>

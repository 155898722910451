export default {
  copyToClipboard (text, callback) {
    var textArea = document.createElement('textarea')
    textArea.contentEditable = true
    textArea.readOnly = false
    textArea.style.position = 'fixed'
    textArea.style.top = 0
    textArea.style.left = 0
    textArea.style.width = '2em'
    textArea.style.height = '2em'
    textArea.style.padding = 0
    textArea.style.border = 'none'
    textArea.style.outline = 'none'
    textArea.style.boxShadow = 'none'
    textArea.style.background = 'transparent'
    textArea.value = text
    document.body.appendChild(textArea)
    var range = document.createRange()
    var s = window.getSelection()
    s.removeAllRanges()
    s.addRange(range)
    textArea.focus()
    textArea.setSelectionRange(0, 999999)
    try {
      var successful = document.execCommand('copy')
      if (successful) {
        callback(null, true)
      }
    } catch (err) {
      callback(err, null)
    }
    document.body.removeChild(textArea)
  },
  capitalizeWords (string) {
    if (string) {
      return string.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase() })
    }
    return null
  },
  uppercaseLetters (string) {
    if (string) {
      return string.toUpperCase()
    }
    return null
  },
  convertToBinary (v) {
    if (v) return 1
    else return 0
  },
  daysInMonth (year, month) {
    // returns the number of days in the given month and year
    // year is YYYY and month is 1-12
    return new Date(year, month, 0).getDate()
  },
  dayOfWeekNumber (year, month, day) {
    // returns the day of week number (1-7 for sun-sat)
    // year is YYYY and month is 1-12 and day is 1-31
    return new Date(year, month - 1, day).getDay() + 1
  },
  monthName (month) {
    // returns the english month name given the month number 1-12
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    return monthNames[month - 1]
  },
  monthNameShort (month) {
    // returns the english month name given the month number 1-12
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return monthNames[month - 1]
  },
  dateInFuture (date1, date2) {
    // takes date in YYYY-MM-DD format as a string
    // returns 1 if the dates are equal
    // returns 2 if date1 is AFTER date2 (aka first date is in future)
    // returns null if date1 is BEFORE date2
    // second date is optional and will default (compare to) today
    if (!date2) {
      date2 = new Date()
      var str = date2.getFullYear() + '-'
      if (((date2.getMonth() + 1) + '').length === 1) str += '0'
      str += (date2.getMonth() + 1) + '-'
      if (((date2.getDate() + 1) + '').length === 1) str += '0'
      str += date2.getDate()
      date2 = str
    }
    if (date1 === date2) {
      return 1
    } else {
      var date1array = date1.split('-')
      date1 = new Date(parseInt(date1array[0]), parseInt(date1array[1]) - 1, parseInt(date1array[2]), 12)
      var date2array = date2.split('-')
      date2 = new Date(parseInt(date2array[0]), parseInt(date2array[1]) - 1, parseInt(date2array[2]), 12)
      if (date1.getTime() > date2.getTime()) return 2
      return null
    }
  },
  getPercentageChange (oldNumber, newNumber, addPercent) {
    // returns the percent change of two numbers as a whole percentage (i.e. 2 to 3 is return as 50)
    if (oldNumber && newNumber) {
      if (typeof oldNumber === 'number' && typeof newNumber === 'number') {
        var decreaseValue = oldNumber - newNumber
        var change = (decreaseValue / oldNumber) * 100
        if (change === Infinity || change === -Infinity || isNaN(change)) {
          return null
        } else {
          var r = -change.toFixed(0)
          if (addPercent) r = r + '%'
          return r
        }
      } else {
        return null
      }
    } else {
      return null
    }
  },
  makeRandomString (length, novowels, charset) {
    // returns a random string of the length passed
    // can optionall pass true/false for a set that doesn't include vowels and optionally a full set to use instead of default
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    if (novowels) characters = 'BCDFGHJKLMNPQRSTVWXYZbcdfghjklmnpqrstvwxyz123456789'
    if (charset) {
      if (charset.length) {
        characters = charset
      }
    }
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
}

<template>
  <div>
    <div class="oto-second-heading is-hidden-mobile">Notifications</div>
    <p class="padding-bottom">CampaignPilot can send notifiation emails to you and your staff when something important happens.</p>
    <div class="table-container">
      <table class="table no-margin">
        <thead>
          <tr>
            <th>Recipient</th>
            <th>Trigger</th>
            <th/>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th>
              <TextInput
                :props="email"
                v-model="email.model"/>
            </th>
            <th>
              <Select
                :props="trigger"
                v-model="trigger.model"/>
            </th>
            <th>
              <a
                class="button is-rounded"
                @click="addNotification">Add</a>
            </th>
          </tr>
        </tfoot>
        <tbody>
          <tr
            v-for="(n, i) in notifications"
            :key="'n' + i">
            <td>
              {{ n.email }}
            </td>
            <td>
              <span v-if="n['notification_trigger'] === 'all'">All Notifications</span>
              <span v-if="n['notification_trigger'] === 'campaign'">Campaign Alerts</span>
              <span v-if="n['notification_trigger'] === 'performance'">Monthly Performance Report</span>
              <span v-if="n['notification_trigger'] === 'form'">Form Submitted</span>
              <span v-if="n['notification_trigger'] === 'order'">Order Made</span>
              <span v-if="n['notification_trigger'] === 'ticket'">Ticket Purchased</span>
            </td>
            <td>
              <span
                class="icon is-small show-hand"
                @click="deleteNotification(i)">
                <i class="fas fa-times-circle"/>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <a
        href="https://help.campaignpilot.com/how-tos/campaignpilot-system-notifications"
        target="_blank">
        <div class="icon has-text-primary">
          <i class="fas fa-question-circle"/>
        </div>
        Learn more about CampaignPilot notifications and how to configure them.
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import Select from '@/components/elements/Select'
import TextInput from '@/components/elements/TextInput'

export default {
  components: {
    Select,
    TextInput
  },
  mixins: [xhr],
  data () {
    return {
      notifications: [],
      notificationsFetched: false,
      email: {
        model: null,
        name: 'email',
        ref: 'email',
        icon: 'fas fa-envelope',
        placeholder: 'name@domain.com',
        fieldClass: 'oto-input-field-text-medium'
      },
      trigger: {
        model: null,
        name: 'trigger',
        ref: 'trigger',
        options: [{ 'name': 'Select a Trigger', 'value': null }, { 'name': 'All Notifications', 'value': 'all' }, { 'name': 'Campaign Alerts', 'value': 'campaign' }, { 'name': 'Monthly Performance Report', 'value': 'performance' }, { 'name': 'Form Submitted', 'value': 'form' }, { 'name': 'Order Made', 'value': 'order' }, { 'name': 'Ticket Purchased', 'value': 'ticket' }],
        label: null,
        icon: 'fas fa-bell',
        class: 'is-fullwidth'
      }
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    })
  },
  mounted () {
    this.fetchNotifications()
  },
  methods: {
    fetchNotifications: function () {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchNotifications(vm.currentAccount.id,
        function (err, notifications) {
          vm.notificationsFetched = true
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.notifications = notifications
          }
        }
      )
    },
    addNotification: function () {
      if (this.email.model && this.trigger.model) {
        this.$store.dispatch('setLoadingState', true)
        var vm = this
        engineApi.saveNotification(vm.currentAccount.id, vm.email.model, 'email', vm.trigger.model,
          function (err, notifications) {
            vm.notificationsFetched = true
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            } else {
              vm.notifications.push({ email: vm.email.model, type: 'email', 'notification_trigger': vm.trigger.model })
            }
            vm.resetAddNotification()
          }
        )
      }
    },
    deleteNotification: function (index) {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.deleteNotification(vm.currentAccount.id, vm.notifications[index].email, 'email', vm.notifications[index]['notification_trigger'],
        function (err, notifications) {
          vm.notificationsFetched = true
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          }
          vm.fetchNotifications()
        }
      )
    },
    resetAddNotification: function () {
      this.email.model = null
      this.trigger.model = null
    }
  }
}
</script>

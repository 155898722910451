<template>
  <div class="field is-horizontal">
    <div
      v-if="props.label"
      :style="label.style"
      :class="label.class"
      class="field-label is-normal">
      <label
        :class="{ 'is-hidden-mobile': props.mobileHideLabel }"
        class="label">{{ props.label }}</label>
      <div
        :class="{ 'is-hidden-mobile': props.mobileHidePlaceholder }"
        class="placeholder">{{ props.placeholder }}</div>
    </div>
    <div
      :class="{ 'extend': props.extendBody }"
      class="field-body">
      <div
        :class="{ 'toggle-buffer':!props.hideBuffer }"
        class="is-hidden-touch"/>
      <div class="field">
        <toggle-button
          :value="props.model"
          :sync="true"
          :labels="props.options"
          :color="defaultColors"
          :width="props.width"
          :height="props.height"
          @focus="onFocus()"
          @change="toggle"/>
      </div>
    </div>
  </div>
</template>

<script>
import inputs from '@/mixins/Inputs.js'
import ToggleButton from 'vue-js-toggle-button/src/Button'

export default {
  components: {
    ToggleButton
  },
  mixins: [inputs],
  data () {
    return {
      defaultColors: { checked: '#24C980', unchecked: '#CCCCCC' }
    }
  },
  created () {
    if (this.props.optionColors) {
      this.defaultColors = this.props.optionColors
    }
  },
  methods: {
    toggle (toggler) {
      this.$emit('input', toggler.value)
    }
  }
}
</script>

<style scoped>
.field-label {
  text-align: left;
  flex-grow: 4;
}
.field-label > .label {
}
.field-body {
  margin: auto 0!important;
  display: inline;
}
.field-body > .field {
  margin: 0!important;
}
.vue-js-switch {
  margin: 0!important;
}
.toggle-buffer {
  height: 10px;
  width: 1px;
}
.field-body.extend {
  flex-grow: 0!important;
}
</style>

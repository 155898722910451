<template>
  <click-outside
    :handler="clickedOutsideFilter">
    <div
      :class="{ 'is-active': filterState }"
      class="dropdown">
      <div class="dropdown-trigger">
        <div
          :class="{ 'as-dropdown':!miniMode }"
          class="filter"
          @click="toggleFilter">
          <span v-if="miniMode && showIntroText">{{ byNamePlural }}</span>
          <span
            v-if="showIcon"
            :class="{'has-text-primary': filterShowingText, 'has-text-grey-light': !filterShowingText }"
            class="icon is-small">
            <i class="fas fa-filter"/>
          </span>
          <span v-if="filterShowingText && !miniMode">
            <span class="is-hidden-touch">
              <span v-if="showIntroText">Showing {{ whatNamePlural }} From </span>
              <span v-html="filterShowingText"></span>
            </span>
            <span class="is-hidden-desktop">
              <div
                v-html="filterShowingText"
                class="mobile-helper"></div>
            </span>
          </span>
          <span v-else-if="!miniMode">
            <span class="is-hidden-mobile">Select {{ byNamePlural }}</span>
            <span class="is-hidden-tablet">{{ byNamePlural }}</span>
          </span>
        </div>
      </div>
      <div class="dropdown-menu">
        <div class="dropdown-content animated fadeIn">
          <div
            v-if="includeSearch"
            class="dropdown-item header-item">
            <input
              v-model="search"
              type="text"
              class="input filter-search"
              :placeholder="'Search ' + byNamePlural" />
          </div>
          <div :class="{'checkbox-group': scrollMode}">
            <div
              v-for="(filter, index) in vvalue.slice(0, show)"
              class="dropdown-item"
              :key="'f'+by+index">
              <div class="field has-text-left no-margin">
                <input
                  v-model="filter.checked"
                  :name="'f'+by+index"
                  :id="'f'+by+index"
                  class="is-checkradio small"
                  type="checkbox"
                  @change="onFilterChange()">
                <label
                  :for="'f'+by+index"
                  class="checkbox-label">
                  {{ filter[by] }}
                  <span v-if="filter[what]"> ({{ filter[what] }}) </span>
                  <a
                    href="#"
                    @click="onClickFilterOnly(filter, $event)">only</a>
                </label>
              </div>
            </div>
            <div
              v-if="value.length > show"
              class="dropdown-item">
              <div class="field has-text-left no-margin">
                <input
                  v-model="other"
                  :id="'filterother'+by"
                  :name="'filterother'+by"
                  class="is-checkradio small"
                  type="checkbox"
                  @change="onToggleFilterOther(other)">
                <label
                  :for="'filterother'+by"
                  class="checkbox-label">
                  Other {{ byNamePlural }}
                  <span v-if="filterOtherTotal"> ({{ filterOtherTotal }}) </span>
                  <a
                    href="#"
                    @click="onClickFilterOnly('other', $event)">only</a>
                </label>
              </div>
            </div>
          </div>
          <div class="dropdown-item footer-item check-all-text">
            <a
              href="#"
              :name="'checkall'+by"
              :key="'checkall'+by"
              :id="'checkall'+by"
              @click="onClickCheckAll($event)">Check All</a>
          </div>
        </div>
      </div>
    </div>
  </click-outside>
</template>

<script>
import ClickOutside from '@/plugins/onclick-outside'

export default {
  components: {
    ClickOutside
  },
  props: {
    value: { // array of values to show in drop down
      type: Array,
      default: function () {
        return []
      }
    },
    show: { // number of items to show in the filter drop down before they are lumped into other
      type: Number,
      default: 15
    },
    scrollMode: { // makes the filter a max height with a vertical scrollbar
      type: Boolean,
      default: false
    },
    sortMode: { // sorts the filter by checked if true
      type: Boolean,
      default: false
    },
    includeSearch: { // includes a search function
      type: Boolean,
      default: false
    },
    by: { // string name for what we are filtering by
      type: String,
      required: true
    },
    what: { // string name for what we are filtering
      type: String,
      required: true
    },
    showOnlyValue: { // string or number value that when passed will only check this value in the filter
      default: null
    },
    showOnlyId: { // string "id" or key to use in the value when setting the showOnlyValue
      type: String,
      default: null
    },
    showIcon: { // show a filter icon prepended to the dropdown
      type: Boolean,
      default: true
    },
    showIntroText: { // show text "Showing Orders From" ... inside the filter
      type: Boolean,
      default: true
    },
    miniMode: { // compact mode, typically used when the filter is part of a table header
      type: Boolean,
      default: false
    }
  },
  computed: {
    filterShowingText: {
      cache: false,
      get () {
        // string. return "4 of 15" or null if no filters is on or all are checked
        var totalChecked = 0
        var lastCheckedName = null
        var vm = this
        this.value.forEach(function (value) {
          if (value.checked) {
            totalChecked++
            lastCheckedName = value[vm.by]
          }
        })
        if (totalChecked !== this.value.length) {
          if (totalChecked === 1 && lastCheckedName) return '<span class="">' + lastCheckedName + '</span>'
          else return '<span class="has-text-weight-bold">' + totalChecked + ' of ' + this.value.length + '</span> ' + this.byNamePlural
        }
        return null
      }
    },
    filterOtherTotal: {
      cache: false,
      get () {
        if (this.value.length > 0) {
          var vm = this
          var otherTotal = 0
          this.value.forEach(function (value, index) {
            if (((index + 1) > vm.show) && value[vm.what]) otherTotal += value[vm.what]
          })
          return otherTotal
        }
        return 0
      }
    },
    byName: function () {
      return this.by.charAt(0).toUpperCase() + this.by.slice(1)
    },
    whatName: function () {
      return this.what.charAt(0).toUpperCase() + this.what.slice(1)
    },
    byNamePlural: function () {
      var n = this.by ? this.by : ''
      if (!n.endsWith('s')) n += 's'
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    whatNamePlural: function () {
      var n = this.what ? this.what : ''
      if (!n.endsWith('s')) n += 's'
      return n.charAt(0).toUpperCase() + n.slice(1)
    }
  },
  data () {
    return {
      filterState: false,
      other: true,
      search: null,
      vvalue: [] // we basically use this for display purposes. this is the value we "render". it allows us to search, etc while retaining the entire/original value array
    }
  },
  watch: {
    search (val) { // watch the search term
      this.filterSearch()
    },
    value (val) {
      this.vvalue = val
      if (this.showOnlyValue) this.processShowOnly()
    },
    showOnlyValue (val) {
      if (val) this.processShowOnly()
    }
  },
  mounted () {
    if (this.value) this.vvalue = this.value
    if (this.showOnlyValue) this.processShowOnly()
  },
  methods: {
    clickedOutsideFilter () {
      if (this.filterState) this.filterState = false
    },
    toggleFilter () {
      this.filterState = !this.filterState
      if (this.filterState && this.sortMode) this.sortFilter()
    },
    onFilterChange () {
      this.$emit('change', this.value)
    },
    onToggleFilterOther (status) {
      // basically this checks every campaign after the show limit
      var vm = this
      this.value.forEach(function (value, index) {
        if ((index + 1) > vm.show) vm.value[index].checked = status
      })
      this.onFilterChange()
    },
    onClickCheckAll (event) {
      if (event) event.preventDefault()
      this.filterState = false // close the filter menu
      this.other = true
      this.value.forEach(function (value) {
        value.checked = true
      })
      this.onFilterChange()
    },
    onClickFilterOnly (filter, event) {
      if (event) event.preventDefault()
      this.filterState = false // close the filter menu
      this.other = false
      this.value.forEach(function (value) {
        value.checked = false
      })
      // check the specific box only was clicked on
      if (filter === 'other') {
        this.onToggleFilterOther(true)
        this.other = true
      } else {
        filter.checked = true
        this.onFilterChange()
      }
    },
    filterSearch () {
      if (this.search) {
        var term = this.search.toLowerCase()
        var results = this.$lodash.filter(this.value, function (val) {
          if (val.campaign) {
            if (typeof val.campaign === 'string') {
              var terms = term.split(' ')
              var hasTerm = false
              terms.forEach(function (term) {
                if (!hasTerm) hasTerm = val.campaign.toLowerCase().includes(term)
              })
              return hasTerm
            }
          }
          return false
        })
        this.vvalue = results
      } else {
        this.vvalue = this.value
      }
    },
    sortFilter () {
      this.vvalue = this.$lodash.orderBy(this.vvalue, ['checked', this.what], ['desc', 'desc'])
    },
    processShowOnly () {
      var camp = null
      if (this.showOnlyId) {
        var o = {}
        o[this.showOnlyId] = this.showOnlyValue
        camp = this.$lodash.find(this.value, o)
      }
      if (camp) this.onClickFilterOnly(camp)
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
.checkbox-label {
  font-size: 0.8rem!important;
  line-height: 0.8rem!important;
  padding-left: 1.5rem!important;
}
.check-all-text {
  font-size: 0.8rem!important;
  line-height: 0.8rem!important;
  text-align: left!important;
}
.is-checkradio.small[type="checkbox"] + label::before, .is-checkradio.small[type="checkbox"] + label:before {
  width: 1rem!important;
  height: 1rem!important;
}
.is-checkradio.small[type="checkbox"] + label::after, .is-checkradio.small[type="checkbox"] + label:after {
  width: 0.375rem;
  height: 0.6rem;
  top: 0.1rem;
  left: 0.32rem;
}
.filter {
  white-space: nowrap;
  cursor: hand;
  cursor: pointer;
}
// Search
.filter-search {
  font-size: 0.8rem!important;
}
// Custom Dropdown
.as-dropdown {
  background-color: white!important;
  border-color: #dbdbdb!important;
  border-radius: 4px!important;
  color: #363636!important;
  font-weight:400!important;
  text-transform:none!important;
  cursor: hand;
  cursor: pointer;
  font-size: 1em!important;
  max-width: 100%;
  outline: none;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  padding-right: 46px;
  padding-left: 20px;
  padding-bottom: 23px;
  padding-top: 23px;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0px;
  box-shadow: none;
  display: inline-flex;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
}
.as-dropdown > span:not(:last-child) {
  padding-right: 16px;
}
.as-dropdown::after {
  right: 1.125em;
  z-index: 4;
  border: 3px solid #24C980;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.625em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
  transform-origin: center;
  width: 0.625em;
}
.dropdown-menu {
  white-space: nowrap;
}
.dropdown-content {
  max-width: calc(100vw - 70px);
  font-weight:400!important;
}
.dropdown-content > .checkbox-group {
  max-height: 300px;
  overflow-y: scroll;
}
.dropdown-item.footer-item {
  border-top: 1px solid #dbdbdb;
  padding-top: 13px;
}
.dropdown-item.header-item {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 13px;
}
.is-checkradio.small + .checkbox-label::before,
.is-checkradio.small + .checkbox-label::after {
  top: 4px!important;
}
// Responsive Stuff
@media only screen and (min-width : 0px) and (max-width : $desktop) {
  .as-dropdown {
    min-width: 0px;
  }
}
@media screen and (max-width: $mobile-max) {
  .dropdown-content > .checkbox-group {
    overflow-x: scroll;
  }
  .as-dropdown .mobile-helper {
    max-width: calc(100vw - 120px);
    overflow: hidden;
  }
}
</style>

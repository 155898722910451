<template>
  <div class="field">
    <label
      v-if="props.label"
      :style="label.style"
      :class="label.class"
      class="label">
      <span v-if="props.label === '&nbsp;'">&nbsp;</span>
      <span v-else>{{ props.label }}</span>
    </label>
    <div v-if="ready" class="control">
      <Editor
        v-model="props.model"
        :api-key="tinymceKey"
        :init="options"
        @onBlur="richTextBlur()"
        @onFocus="richTextFocus()"
        @change="$emit('input', $event.target.value)"/>
    </div>
    <p
      v-if="props.mobileHelp && isInputActive"
      class="oto-mobile-help is-info is-hidden-tablet">
      <span class="icon">
        <i class="fas fa-info-circle"/>
      </span>
      {{ props.mobileHelp }}
    </p>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import inputs from '@/mixins/Inputs.js'

export default {
  components: {
    Editor
  },
  mixins: [inputs],
  props: {
    super: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tinymceKey: process.env.VUE_APP_TINYMCE,
      ready: false,
      options: {
        forced_root_block: false,
        menubar: false,
        elementpath: false,
        paste_as_text: true,
        min_height: 400,
        browser_spellcheck: true,
        contextmenu: false,
        content_css: '/css/tinymce.css',
        body_class: 'campaignpilot',
        plugins: 'paste,link,lists',
        toolbar: 'undo redo | styleselect | bold italic underline strikethrough | alignleft aligncenter alignright | outdent indent | numlist bullist link',
        style_formats: [
          { title: 'Headings' },
          { title: 'Heading 1', format: 'h1' },
          { title: 'Heading 2', format: 'h2' },
          { title: 'Heading 3', format: 'h3' },
          { title: 'Heading 4', format: 'h4' },
          { title: 'Heading 5', format: 'h5' },
          { title: 'Heading 6', format: 'h6' },
          { title: 'Blocks' },
          { title: 'Paragraph', format: 'p' },
          { title: 'Blockquote', format: 'blockquote' },
          { title: 'Div', format: 'div' },
          { title: 'Pre', format: 'pre' }
        ]
      }
    }
  },
  computed: {
    element: function () {
      var t = document.getElementsByClassName('tox')
      if (t) {
        if (t.length > 0) {
          return t[0]
        }
      }
      return null
    }
  },
  mounted () {
    if (this.super) {
      this.options.plugins += ',code'
      this.options.toolbar += ' | code'
      this.options['valid_elements'] = '*[*]'
    }
    if (this.props.height) this.options['min_height'] = this.props.height
    this.ready = true
  },
  methods: {
    richTextFocus (t) {
      if (this.element) this.element.classList.add('oto-focused-element')
      this.onFocus(t)
    },
    richTextBlur (t) {
      if (this.element) this.element.classList.remove('oto-focused-element')
      this.onBlur(t)
    }
  }
}
</script>

<style lang="scss">
.oto-focused-element, .tox:hover {
  border-color: $primary;
}
</style>

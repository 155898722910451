<template>
  <span v-if="currentLoading || forceOn">
    <intersect @leave="leaveView()">
      <progress
        id="progress"
        :class="{ 'force-top': forceTop }"
        class="progress oto-progress-bar is-primary sticky-loading"
        max="100"/>
    </intersect>
  </span>
</template>

<script>
import Intersect from 'vue-intersect'
import { mapGetters } from 'vuex'

export default {
  components: { Intersect },
  props: {
    forceOn: {
      type: Boolean,
      default: false
    },
    forceTop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      currentLoading: 'currentLoading'
    })
  },
  methods: {
    leaveView () {
      document.getElementById('progress').classList.add('is-stuck')
    }
  }
}
</script>

<style>
.progress {
  background: #DBDBDB;
}
progress::-ms-fill {
  animation-name: none;
}
.progress.oto-progress-bar {
  border-radius: 0px;
  position: absolute;
  top: 53px;
  z-index: 16;
  height: 10px;
}
.progress.oto-progress-bar.force-top {
  top: 0!important;
}
.progress::-webkit-progress-bar {
  background: transparent !important;
}
.progress:indeterminate {
  animation: progress-indeterminate 1.5s linear infinite;
  background: #DBDBDB linear-gradient(to right, #24C980 30%, #DBDBDB 30%) top left/150% 150% no-repeat;
}
.progress:indeterminate::-moz-progress-bar {
  background: transparent !important;
}
@keyframes progress-indeterminate {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>

import { parseISO, format } from 'date-fns'

// WIZARD TYPES (vuexWizardType):
// golf, other, event, sale
//
// WIZARD SUB TYPE (vuexWizard.wizardSubtype)
// paid, info, engine
//
// CALL TO ACTIONS (vuexActions.callToAction):
// sale, teetime, info, event
//
// JSON -> CAMPAIGN -> TYPE (wizardDetails.type)
// event, info, golf, other, sale, tee times, publication

export default {
  data () {
    return {
      words: {
        variables: {},
        buckets: {}
      }
    }
  },
  mounted () {
    if (this.vuexWizard) {
      var baselineWords = true
      if (this.vuexWizard.wizardLandingPage) {
        if (this.vuexWizard.wizardLandingPage.words) {
          // words are generated on the wizard landing page section first
          // so if we have that section in memory, there's no need to regenerate them
          if (process.env.NODE_ENV === 'development') console.log('Skipping words generation, load these instead from landing page section')
          baselineWords = false
        }
      }
      if (baselineWords) this.baselineWords()
    } else {
      if (process.env.NODE_ENV === 'development') console.log('ERROR: Tried to load in Words mixin without vuexWizard!!!')
    }
  },
  methods: {
    baselineWords () {
      if (process.env.NODE_ENV === 'development') console.log('Generating words ...')
      this.assignVariables(this.vuexWizard)
      this.populateBuckets(this.vuexWizard)
    },
    assignVariables (wizard) {
      this.assignTitle(wizard)
      this.assignDate(wizard)
      this.assignTime(wizard)
      this.assignPrice(wizard)
      this.assignTax(wizard)
      this.assignProduct(wizard)
      this.assignLocation(wizard)
      this.assignWindows(wizard)
      this.assignCapacity(wizard)
      this.assignTicketQty(wizard)
    },
    assignTitle (wizard) {
      this.words.variables.title = null
      var vm = this
      wizard.wizardDetails.questions.every(function (question) {
        if (question.name) {
          if (question.model) {
            if (question.name === 'title') {
              vm.words.variables.title = question.model
            }
          }
        }
        if (vm.words.variables.title) {
          return false
        } else {
          return true
        }
      })
      if (!vm.words.variables.title) {
        wizard.wizardDetails.questions.every(function (question) {
          if (question.name) {
            if (question.model) {
              if (question.name.includes('title')) {
                vm.words.variables.title = question.model
              }
            }
          }
          if (vm.words.variables.title) return false
          else return true
        })
      }
    },
    assignDate (wizard) {
      this.words.variables.date = null
      this.words.variables.dateAlt = null
      this.words.variables.dateRaw = null
      this.words.variables.endDate = null
      this.words.variables.endDateAlt = null
      this.words.variables.endDateRaw = null
      this.words.variables.dateTodayRaw = format(new Date(), 'yyyy-MM-dd')
      var vm = this
      wizard.wizardDetails.questions.every(function (question) {
        if (question.name) {
          if (question.model) {
            if (question.name === 'date') {
              vm.words.variables.date = question.model
            }
          }
        }
        if (vm.words.variables.date) return false
        else return true
      })
      if (!vm.words.variables.date) {
        wizard.wizardDetails.questions.every(function (question) {
          if (question.name) {
            if (question.model) {
              if (question.name.includes('date')) {
                vm.words.variables.date = question.model
              }
            }
          }
          if (vm.words.variables.date) return false
          else return true
        })
      }
      if (vm.words.variables.date) {
        if (/^\d{4}-\d{1,2}-\d{1,2},\d{4}-\d{1,2}-\d{1,2}$/.test(vm.words.variables.date)) {
          // date found matches start + end date
          var ds = vm.words.variables.date.split(',')
          if (ds.length === 2) {
            vm.words.variables.date = ds[0]
            vm.words.variables.endDate = ds[1]
          } else if (ds.length === 1) {
            vm.words.variables.date = ds[0]
          } else {
            vm.words.variables.date = null
          }
        } else if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(vm.words.variables.date)) {
          // date found matches single date
          // don't have to do anything here, we already casted the variable
        } else {
          // not a date
          vm.words.variables.date = null
        }
        if (vm.words.variables.date) {
          var jDate = parseISO(vm.words.variables.date)
          if (jDate) {
            vm.words.variables.date = format(jDate, 'MMMM do, yyyy') // January 1st, 2024
            vm.words.variables.dateAlt = format(jDate, 'EEEE, MMMM do, yyyy') // Sunday, January 1st, 2024
            vm.words.variables.dateRaw = format(jDate, 'yyyy-MM-dd') // 2024-01-01
          }
          if (vm.words.variables.endDate) {
            jDate = parseISO(vm.words.variables.endDate)
            if (jDate) {
              vm.words.variables.endDate = format(jDate, 'MMMM do, yyyy') // January 1st, 2024
              vm.words.variables.endDateAlt = format(jDate, 'EEEE, MMMM do, yyyy') // Sunday, January 1st, 2024
              vm.words.variables.endDateRaw = format(jDate, 'yyyy-MM-dd') // 2024-01-01
            }
          }
        }
      }
    },
    assignTime (wizard) {
      this.words.variables.time = null
      this.words.variables.timeFull = null
      this.words.variables.endTime = null
      this.words.variables.endTimeFull = null
      var vm = this
      wizard.wizardDetails.questions.every(function (question) {
        if (question.name) {
          if (question.model) {
            if (question.name === 'time') {
              vm.words.variables.time = question.model
            }
          }
        }
        if (vm.words.variables.time) return false
        else return true
      })
      if (!vm.words.variables.time) {
        wizard.wizardDetails.questions.every(function (question) {
          if (question.name) {
            if (question.model) {
              if (question.name.includes('time')) {
                vm.words.variables.time = question.model
              }
            }
          }
          if (vm.words.variables.time) return false
          else return true
        })
      }
      if (vm.words.variables.time) {
        if (/^\d{1,2}:\d{1,2}\s(AM|PM),\d{1,2}:\d{1,2}\s(AM|PM)$/.test(vm.words.variables.time)) {
          // time found matches start + end time
          var ts = vm.words.variables.time.split(',')
          if (ts.length === 2) {
            vm.words.variables.time = ts[0]
            vm.words.variables.endTime = ts[1]
          } else if (ts.length === 1) {
            vm.words.variables.time = ts[0]
          } else {
            vm.words.variables.time = null
          }
        } else if (/^\d{1,2}:\d{1,2}\s(AM|PM)$/.test(vm.words.variables.time)) {
          // time found matches single time
          // don't have to do anything here, we already casted the variable
        } else {
          // not a time
          vm.words.variables.time = null
        }
        if (vm.words.variables.time) {
          var t = vm.words.variables.time.split(':')
          var h = parseInt(t[0])
          var m = t[1].split(' ')
          var p = m[1]
          m = parseInt(m[0])
          if (p === 'PM') {
            h = h + 12
          }
          var d = new Date()
          d.setHours(h)
          d.setMinutes(m)
          d.setSeconds(0)
          var min = d.getMinutes() + ''
          if (min.length === 1) min = min + '0'
          vm.words.variables.timeFull = d.getHours() + ':' + min
          if (vm.words.variables.endTime) {
            var et = vm.words.variables.endTime.split(':')
            var eh = parseInt(et[0])
            var em = et[1].split(' ')
            var ep = em[1]
            em = parseInt(em[0])
            if (ep === 'PM') {
              eh = eh + 12
            }
            var ed = new Date()
            ed.setHours(eh)
            ed.setMinutes(em)
            ed.setSeconds(0)
            var emin = ed.getMinutes() + ''
            if (emin.length === 1) emin = emin + '0'
            vm.words.variables.endTimeFull = ed.getHours() + ':' + emin
          }
        }
      }
    },
    assignPrice (wizard) {
      this.words.variables.price = null
      this.words.variables.priceAlt = null
      this.words.variables.currencySymbol = '$'
      if (this.currentAccount.currency === 'EUR') this.words.variables.currencySymbol = '€'
      var minPrice = null
      var maxPrice = null
      if (wizard.wizardAction.callToAction === 'event') {
        // let's look at ticket prices
        if (wizard.wizardAction.actions) {
          if (wizard.wizardAction.actions.tickets) {
            // pull price from the ticket details
            wizard.wizardAction.actions.tickets.forEach(function (ticket) {
              if (ticket.price) {
                var thisPrice = ticket.price.replace(/,/g, '')
                thisPrice = parseFloat(thisPrice)
                if (!minPrice || thisPrice < minPrice) minPrice = thisPrice
                if (!maxPrice || thisPrice > maxPrice) maxPrice = thisPrice
              }
            })
            if (minPrice) {
              this.words.variables.price = this.words.variables.currencySymbol + minPrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              this.words.variables.priceAlt = this.words.variables.currencySymbol + minPrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            if ((minPrice && maxPrice) && (minPrice < maxPrice)) {
              this.words.variables.priceAlt = this.words.variables.currencySymbol + minPrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' - ' + this.words.variables.currencySymbol + maxPrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
          }
        }
      } else if (wizard.wizardAction.callToAction === 'sale') {
        // let's look at item price
        if (wizard.wizardAction.actions) {
          if (wizard.wizardAction.actions.product) {
            if (wizard.wizardAction.actions.product.price) {
              if (wizard.wizardAction.actions.product.price.model) {
                // pull price from the product details
                var thisPrice = wizard.wizardAction.actions.product.price.model.replace(/,/g, '')
                thisPrice = parseFloat(thisPrice)
                if (thisPrice) {
                  if (thisPrice > 0) {
                    this.words.variables.price = this.words.variables.currencySymbol + thisPrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    this.words.variables.priceAlt = this.words.variables.currencySymbol + thisPrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    if (wizard.wizardAction.actions.product.fullPrice) {
                      if (wizard.wizardAction.actions.product.fullPrice.model) {
                        var thisFullPrice = wizard.wizardAction.actions.product.fullPrice.model.replace(/,/g, '')
                        thisFullPrice = parseFloat(thisFullPrice)
                        if (thisFullPrice) {
                          if (thisFullPrice > 0) {
                            this.words.variables.priceAlt = this.words.variables.currencySymbol + thisPrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' (originally ' + this.words.variables.currencySymbol + thisFullPrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ')'
                          }
                        }
                      }
                    }
                  } else {
                    // price is less than zero ...
                  }
                }
              }
            }
          }
        }
      } else {
        // let's look the the campaign details maybe????
      }
    },
    assignTax (wizard) {
      this.words.variables.tax = null
      if (wizard) {
        if (wizard.wizardAction) {
          if (wizard.wizardAction.actions) {
            if (wizard.wizardAction.actions.registration) {
              if (wizard.wizardAction.actions.registration.tax) {
                if (wizard.wizardAction.actions.registration.tax.charge) {
                  this.words.variables.tax = wizard.wizardAction.actions.registration.tax.model
                }
              }
            } else if (wizard.wizardAction.actions.product) {
              if (wizard.wizardAction.actions.product.tax) {
                if (wizard.wizardAction.actions.product.tax.charge) {
                  this.words.variables.tax = wizard.wizardAction.actions.product.tax.model
                }
              }
            }
          }
        }
      }
    },
    assignProduct (wizard) {
      this.words.variables.product = null
      if (wizard.wizardAction.callToAction === 'event') {
        // let's look at the tickets for events
        if (wizard.wizardAction.actions) {
          if (wizard.wizardAction.actions.tickets) {
            // pull name from the first ticket
            if (Array.isArray(wizard.wizardAction.actions.tickets)) {
              if (wizard.wizardAction.actions.tickets.length > 0) {
                this.words.variables.product = wizard.wizardAction.actions.tickets[0].name
              }
            }
          }
        }
      } else if (wizard.wizardAction.callToAction === 'sale') {
        // let's look at the product for sales
        if (wizard.wizardAction.actions) {
          if (wizard.wizardAction.actions.product) {
            // pull name from the product details
            if (wizard.wizardAction.actions.product.name.model) {
              this.words.variables.product = wizard.wizardAction.actions.product.name.model
            }
          }
        }
      }
    },
    assignLocation (wizard) {
      this.words.variables.location = null
      var vm = this
      wizard.wizardDetails.questions.every(function (question) {
        if (question.name) {
          if (question.model) {
            if (question.name === 'location') {
              vm.words.variables.location = question.model
            }
          }
        }
        if (vm.words.variables.location) {
          return false
        } else {
          return true
        }
      })
      if (!vm.words.variables.location) {
        wizard.wizardDetails.questions.every(function (question) {
          if (question.name) {
            if (question.model) {
              if (question.name.includes('location')) {
                vm.words.variables.location = question.model
              }
            }
          }
          if (vm.words.variables.location) return false
          else return true
        })
      }
    },
    assignWindows (wizard) {
      this.words.variables.openDate = null
      this.words.variables.closeDate = null
      if (wizard.wizardAction) {
        if (wizard.wizardAction.actions) {
          if (wizard.wizardAction.actions.product) {
            if (wizard.wizardAction.actions.product.saleDate) {
              this.words.variables.openDate = wizard.wizardAction.actions.product.saleDate.model
            }
          } else if (wizard.wizardAction.actions.registration) {
            if (wizard.wizardAction.actions.registration.registrationDate) {
              this.words.variables.openDate = wizard.wizardAction.actions.registration.registrationDate.model
            }
          }
        }
      }
      if (this.words.variables.openDate) {
        var vm = this
        if (/^\d{4}-\d{1,2}-\d{1,2},\d{4}-\d{1,2}-\d{1,2}$/.test(this.words.variables.openDate)) {
          // date found matches start + end date
          var ds = vm.words.variables.openDate.split(',')
          if (ds.length === 2) {
            vm.words.variables.openDate = ds[0]
            vm.words.variables.closeDate = ds[1]
          } else if (ds.length === 1) {
            vm.words.variables.openDate = ds[0]
          } else {
            vm.words.variables.openDate = null
          }
        } else if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(this.words.variables.openDate)) {
          // date found matches single date
          // don't have to do anything here, we already casted the variable
        } else {
          // not a date
          this.words.variables.openDate = null
        }
      }
    },
    assignCapacity (wizard) {
      this.words.variables.capacity = null
      if (wizard.wizardAction) {
        if (wizard.wizardAction.actions) {
          if (wizard.wizardAction.callToAction === 'event') {
            if (wizard.wizardAction.actions.registration) {
              if (wizard.wizardAction.actions.registration.capacity) {
                if (wizard.wizardAction.actions.registration.capacity.model) {
                  this.words.variables.capacity = parseInt(wizard.wizardAction.actions.registration.capacity.model)
                }
              }
            }
          } else if (wizard.wizardAction.callToAction === 'sale') {
            if (wizard.wizardAction.actions.product) {
              if (wizard.wizardAction.actions.product.stock) {
                if (wizard.wizardAction.actions.product.stock.model) {
                  this.words.variables.capacity = parseInt(wizard.wizardAction.actions.product.stock.model)
                }
              }
            }
          }
        }
      }
    },
    assignTicketQty (wizard) {
      this.words.variables.ticketQty = null
      if (wizard.wizardAction) {
        if (wizard.wizardAction.actions) {
          if (wizard.wizardAction.callToAction === 'event') {
            if (wizard.wizardAction.actions.tickets) {
              this.words.variables.ticketQty = wizard.wizardAction.actions.tickets.reduce((obj, t) => {
                const name = t.name
                const quantity = t.quantity ? parseInt(t.quantity) : null
                if (name && quantity) obj[name] = obj[name] ? obj[name] + quantity : quantity
                return obj
              }, {})
            }
          }
        }
      }
    },
    populateBuckets (wizard) {
      this.populateTitles(wizard)
      this.populateHeadings(wizard)
      this.populateDescriptions(wizard)
      this.populateContents(wizard)
      this.populateActions(wizard)
    },
    populateTitles (wizard) {
      var titles = []
      // handle campaign types and subtypes
      switch (wizard.wizardDetails.type) {
        case 'event':
          titles.unshift('Join Our Event')
          titles.unshift('Register For Our Event')
          switch (wizard.wizardDetails.subtype) {
            case 'outing':
              titles.unshift('Our Outing is Coming Up')
              titles.unshift('Annual Golf Outing')
              break
            case 'league':
              titles.unshift('Register For A League')
              break
            case 'social':
              titles.unshift('Come Out For A Fun Time')
              titles.unshift('Join Us For This Event')
              titles.unshift('Register For This Event')
              break
            default:
              break
          }
          break
        case 'sale':
          titles.unshift('Buy Now')
          titles.unshift('Purchase Now')
          switch (wizard.wizardDetails.subtype) {
            case 'gift card':
              titles.unshift('Purchase a Gift Card')
              titles.unshift('Buy a Gift Card')
              break
            case 'lessons':
              titles.unshift('Book a Lesson')
              break
            default:
              break
          }
          break
        case 'info':
          titles.unshift('Get More Information')
          titles.unshift('Details Here')
          switch (wizard.wizardDetails.subtype) {
            case 'application':
              titles.unshift('Download Our App')
              titles.unshift('Get Our App')
              titles.unshift('Download Our App Free')
              break
            default:
              break
          }
          break
        case 'publication':
          titles.unshift('Read Our Latest')
          switch (wizard.wizardDetails.subtype) {
            case 'newsletter':
              titles.unshift('Read Our Newsletter')
              titles.unshift('Stay In The Loop')
              break
            default:
              break
          }
          break
        case 'tee times':
          titles.unshift('Book Your Tee Time')
          titles.unshift('Online Specials')
          titles.unshift('Lowest Rate Guaranteed')
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default:
              break
          }
          break
        case 'golf':
          titles.unshift('Book Your Tee Time')
          titles.unshift('Online Specials')
          titles.unshift('Lowest Rate Guaranteed')
          titles.unshift('Buy A Round Today')
          titles.unshift('Get Out and Play')
          titles.unshift('Golf Specials')
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default:
              break
          }
          break
        case 'other':
          titles.unshift('More Information Here')
          titles.unshift('Details Here')
          titles.unshift('Find Out More')
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default:
              break
          }
          break
        default:
          break
      }
      // handle campaign objectives
      switch (wizard.wizardAction.callToAction) {
        case 'sale':
          // code here
          break
        case 'event':
          // code here
          break
        case 'teetime':
          // code here
          break
        case 'info':
          // code here
          break
        default:
          break
      }
      // handle some subtypes regardless of main type
      if (wizard.wizardDetails.subtype === 'food' || wizard.wizardDetails.subtype === 'restaurant') {
        titles.unshift('Place Your Take Out Order')
        titles.unshift('Order Now')
      }
      // handle direct assignments
      wizard.wizardDetails.questions.forEach(function (question) {
        if (question.name) {
          if (question.model) {
            if (question.name.includes('title')) {
              titles.unshift(question.model)
            }
          }
        }
      })
      // add in account name as a title at the end
      titles.push(this.currentAccount.name)
      // save titles
      this.words.buckets.titles = titles
      // set title index
      this.words.buckets.titleIndex = 0
      // add this bucket to our groups
      this.addToGroupBucket('allsafe', titles)
    },
    populateHeadings (wizard) {
      var headings = []
      // handle campaign types and subtypes
      switch (wizard.wizardDetails.type) {
        case 'event':
          if (this.words.variables.dateAlt && this.words.variables.price) headings.unshift('Join Us On ' + this.words.variables.dateAlt + '. Starting at ' + this.words.variables.price + '.')
          if (this.words.variables.dateAlt) headings.unshift('Join Us On ' + this.words.variables.dateAlt)
          if (this.words.variables.dateAlt && this.words.variables.priceAlt) headings.unshift(this.words.variables.dateAlt + ' | ' + this.words.variables.priceAlt)
          if (this.words.variables.date) headings.unshift(this.words.variables.date)
          if (this.words.variables.dateAlt) headings.unshift(this.words.variables.dateAlt)
          if (this.words.variables.dateAlt && this.words.variables.product && this.words.variables.price) headings.unshift(this.words.variables.dateAlt + ' | ' + this.words.variables.product + ' | ' + this.words.variables.price)
          switch (wizard.wizardDetails.subtype) {
            case 'league':
              if (this.words.variables.dateAlt) headings.unshift('Join us every week, starting ' + this.words.variables.dateAlt)
              if (this.words.variables.dateAlt && this.words.variables.price) headings.unshift(this.words.variables.dateAlt + ' | ' + this.words.variables.price)
              if (this.words.variables.dateAlt) headings.unshift('Join us on ' + this.words.variables.dateAlt)
              if (this.words.variables.price) headings.unshift(this.words.variables.price)
              break
            default:
              break
          }
          break
        case 'sale':
          if (this.words.variables.product) headings.unshift(this.words.variables.product)
          if (this.words.variables.product && this.words.variables.priceAlt) headings.unshift(this.words.variables.product + ' | ' + this.words.variables.priceAlt)
          if (this.words.variables.price) headings.unshift('As Low As ' + this.words.variables.price)
          if (this.words.variables.price) headings.unshift(this.words.variables.price)
          switch (wizard.wizardDetails.subtype) {
            case 'lessons':
              headings.unshift('Improve Your Game')
              headings.unshift('Lessons Taught by professionals')
              break
            case 'gift card':
              headings.unshift('Gift Memorable Experiences')
              headings.unshift('The Perfect Gift')
              if (this.words.variables.product) headings.unshift(this.words.variables.product)
              if (this.words.variables.product && this.words.variables.priceAlt) headings.unshift(this.words.variables.product + ' | ' + this.words.variables.priceAlt)
              if (this.words.variables.price) headings.unshift(this.words.variables.price)
              break
            default:
              break
          }
          break
        case 'info':
          headings.unshift('Get All The Details Right Here')
          headings.unshift('Learn More')
          switch (wizard.wizardDetails.subtype) {
            case 'application':
              headings.unshift('Available in Google Play')
              headings.unshift('Available in the Apple App Store')
              headings.unshift('Available in the Apple App Store and Google Play')
              break
            case 'venue':
              headings.unshift('Let us do the work for you')
              headings.unshift('Multiple venue options are available')
              headings.unshift('Our versatile space accommodates a variety of party sizes')
              break
            case 'catering':
              headings.unshift('Delicious food catered for every occasion')
              headings.unshift('Catering services personalized for any event')
              break
            default:
              break
          }
          break
        case 'publication':
          headings.unshift('Stay In The Loop')
          headings.unshift('Keep Up With News & Events')
          switch (wizard.wizardDetails.subtype) {
            case 'some subtype here':
              break
            default:
              break
          }
          break
        case 'tee times':
          headings.unshift('Lowest Rate Guaranteed')
          headings.unshift('Time Is Running Out. Book Now!')
          headings.unshift('Book Your Round Today')
          if (this.words.variables.dateAlt) headings.unshift('Join us on ' + this.words.variables.dateAlt)
          if (this.words.variables.dateAlt && this.words.variables.price) headings.unshift('Join us on ' + this.words.variables.dateAlt + '. Starting at ' + this.words.variables.price + '.')
          if (this.words.variables.price) headings.unshift('Golf As Low As ' + this.words.variables.price)
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default:
              break
          }
          break
        case 'golf':
          headings.unshift('Lowest Rate Guaranteed')
          headings.unshift('Time Is Running Out. Book Now!')
          headings.unshift('Book Your Round Today')
          headings.unshift('Great Golf Specials')
          if (this.words.variables.dateAlt) headings.unshift('Join us on ' + this.words.variables.dateAlt)
          if (this.words.variables.dateAlt && this.words.variables.price) headings.unshift('Join us on ' + this.words.variables.dateAlt + '. Starting at ' + this.words.variables.price + '.')
          if (this.words.variables.price) headings.unshift('Golf As Low As ' + this.words.variables.price)
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default:
              break
          }
          break
        case 'other':
          headings.push(this.currentAccount.name) // add account name to the front of other campaigns
          headings.push('Learn More')
          headings.push('Get All The Details Right Here')
          headings.push('Find Out More Information Here')
          headings.push('This Deal Ends Soon')
          headings.push('Time Is Running Out')
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default:
              break
          }
          break
        default:
          break
      }
      // handle campaign objectives
      switch (wizard.wizardAction.callToAction) {
        case 'sale':
          // code here
          break
        case 'event':
          // code here
          break
        case 'teetime':
          // code here
          break
        case 'info':
          // code here
          break
        default:
          break
      }
      // handle direct assignments
      var vm = this
      wizard.wizardDetails.questions.forEach(function (question) {
        if (question.name) {
          if (question.model) {
            if (question.name.includes('description')) {
              if (!vm.hasHTML(question.model)) {
                headings.push(question.model)
              }
            }
          }
        }
      })
      // save headings
      this.words.buckets.headings = headings
      // set heading index
      this.words.buckets.headingIndex = 0
      // add this bucket to our groups
      this.addToGroupBucket('actionheading', headings)
      this.addToGroupBucket('allsafe', headings)
    },
    populateDescriptions (wizard) {
      var descriptions = []
      // handle campaign types and subtypes
      switch (wizard.wizardDetails.type) {
        case 'event':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default: // default EVENT phrases
              break
          }
          break
        case 'sale':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default: // default SALE phrases
              break
          }
          break
        case 'info':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default: // default INFO phrases
              break
          }
          break
        case 'publication':
          switch (wizard.wizardDetails.subtype) {
            case 'application':
              break
            default: // default PUBLICATION phrases
              break
          }
          break
        case 'tee times':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default: // default TEE TIME phrases
              break
          }
          break
        case 'golf':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default: // default GOLF phrases
              break
          }
          break
        case 'other':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default: // default OTHER phrases
              break
          }
          break
        default:
          break
      }
      // handle campaign objectives
      switch (wizard.wizardAction.callToAction) {
        case 'sale':
          // code here
          break
        case 'event':
          // code here
          break
        case 'teetime':
          // code here
          break
        case 'info':
          // code here
          break
        default:
          break
      }
      // handle direct assignments
      var vm = this
      wizard.wizardDetails.questions.forEach(function (question) {
        if (question.name) {
          if (question.model) {
            if (question.name.includes('description')) {
              if (!vm.hasHTML(question.model)) {
                descriptions.push(question.model)
              }
            }
          }
        }
      })
      // save descriptions
      this.words.buckets.descriptions = descriptions
      // set description index
      this.words.buckets.descriptionIndex = 0
      // add this bucket to our groups
      this.addToGroupBucket('allsafe', descriptions)
    },
    populateContents (wizard) {
      var contents = []
      // handle campaign types and subtypes
      switch (wizard.wizardDetails.type) {
        case 'event':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default: // default EVENT phrases
              break
          }
          break
        case 'sale':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default: // default SALE phrases
              break
          }
          break
        case 'info':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default: // default INFO phrases
              break
          }
          break
        case 'publication':
          switch (wizard.wizardDetails.subtype) {
            case 'application':
              break
            default: // default PUBLICATION phrases
              break
          }
          break
        case 'tee times':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default: // default TEE TIME phrases
              break
          }
          break
        case 'golf':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default: // default GOLF phrases
              break
          }
          break
        case 'other':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default: // default OTHER phrases
              break
          }
          break
        default:
          break
      }
      // handle campaign objectives
      switch (wizard.wizardAction.callToAction) {
        case 'sale':
          // code here
          break
        case 'event':
          // code here
          break
        case 'teetime':
          // code here
          break
        case 'info':
          // code here
          break
        default:
          break
      }
      // handle direct assignments
      wizard.wizardDetails.questions.forEach(function (question) {
        if (question.name) {
          if (question.model) {
            if (question.name.includes('description')) {
              contents.unshift(question.model)
            }
          }
        }
      })
      // save contents
      this.words.buckets.contents = contents
      // set content index
      this.words.buckets.contentIndex = 0
    },
    populateActions (wizard) {
      var actions = []
      // #1 - The campaign objective (call to action) is the most important here. Start with that to populate the first action phrases
      switch (wizard.wizardAction.callToAction) {
        case 'sale':
          if (wizard.wizardSubtype === 'info') actions.push('More Info', 'See Details', 'Sign Up', 'Buy Now', 'Purchase Here', 'Buy Tickets', 'Get Deal', 'Get Details')
          else actions.push('Purchase', 'Buy Now', 'Purchase Here', 'Buy Tickets', 'Get Deal', 'More Info', 'Sign Up', 'Get Details')
          break
        case 'event':
          if (wizard.wizardSubtype === 'info') actions.push('More Info', 'See Details', 'Register', 'Enroll', 'Sign Up', 'Purchase Tickets', 'Purchase', 'Enroll Your Team', 'Register For This Event', 'Join Here', 'Join Event', 'Get Details')
          else actions.push('Register', 'Enroll', 'Sign Up', 'Purchase Tickets', 'Purchase', 'Enroll Your Team', 'Register For This Event', 'Join Here', 'Join Event', 'More Info', 'Get Details')
          break
        case 'teetime':
          if (wizard.wizardSubtype === 'info') actions.push('More Info', 'See Details', 'Book', 'Book Your Tee Time', 'Book Now', 'Book Your Round', 'Tee Times', 'More Info', 'Sign Up', 'Get Details')
          else actions.push('Book', 'Book Your Tee Time', 'Book Now', 'Book Your Round', 'Tee Times', 'More Info', 'Sign Up', 'Get Details')
          break
        case 'info':
          actions.push('More Info', 'Sign Up', 'See Details', 'Get Details', 'Request More Info', 'Purchase', 'Buy Now', 'Purchase Here', 'Buy Tickets', 'Register', 'Enroll', 'Book', 'Book Your Round', 'Submit')
          break
        default:
          break
      }
      // #2 - handle campaign types and subtypes. we're getting more defined here, so we'll unshift these to the front
      switch (wizard.wizardDetails.type) {
        case 'event':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default:
              break
          }
          break
        case 'sale':
          switch (wizard.wizardDetails.subtype) {
            case 'lessons':
              actions.unshift('Book a Lesson')
              actions.unshift('Schedule My Lesson')
              break
            default:
              break
          }
          break
        case 'info':
          switch (wizard.wizardDetails.subtype) {
            case 'application':
              actions.unshift('Download Now')
              actions.unshift('Download Our App')
              break
            case 'contest':
              actions.unshift('Enter Contest')
              break
            default:
              break
          }
          break
        case 'publication':
          actions.unshift('Read')
          actions.unshift('Read Now')
          switch (wizard.wizardDetails.subtype) {
            case 'mailing list':
              actions.unshift('Subscribe')
              actions.unshift('Join Mailing List')
              break
            default:
              break
          }
          break
        case 'tee times':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default:
              break
          }
          break
        case 'golf':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default:
              break
          }
          break
        case 'other':
          switch (wizard.wizardDetails.subtype) {
            case 'campaign subtype here':
              break
            default:
              break
          }
          break
        default:
          break
      }

      // handle some subtypes regardless of main type
      if (wizard.wizardDetails.subtype === 'food' || wizard.wizardDetails.subtype === 'restaurant') {
        actions.unshift('Order Now')
        actions.unshift('Place Order')
      }
      // handle direct assignments
      // save actions
      this.words.buckets.actions = actions
      // set action index
      this.words.buckets.actionIndex = 0
      // add this bucket to our groups
      this.addToGroupBucket('actionheading', actions)
      this.addToGroupBucket('allsafe', actions)
    },
    addToGroupBucket (bucket, phrases) {
      var bucketName = bucket + 's'
      if (!this.words.buckets[bucketName]) {
        // create the group bucket if we dont have it yet
        this.words.buckets[bucketName] = []
        this.words.buckets[bucket + 'Index'] = 0
      }
      this.words.buckets[bucketName] = this.words.buckets[bucketName].concat(phrases)
    },
    hasHTML (s) {
      var pattern = new RegExp(/<[a-z][\s\S]*>/)
      return pattern.test(s)
    }
  }
}

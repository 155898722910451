<template>
  <click-outside
    :handler="clickedOutside"
    class="field">
    <label
      v-if="props.label"
      :style="label.style"
      :class="label.class"
      class="label">
      <span v-if="props.label === '&nbsp;'">&nbsp;</span>
      <span v-else>{{ props.label }}</span>
      <span
        v-if="isRequired && !props.hideRequired"
        class="icon has-text-danger is-small oto-required-field">
        <i class="fas fa-star-of-life oto-fa-mini"/>
      </span>
    </label>
    <div class="columns is-mobile is-multiline no-bottom-margin">
      <div class="column is-narrow oto-no-padding-bottom">
        <div
          :class="{ 'is-active': start.s }"
          class="dropdown">
          <div class="dropdown-trigger">
            <div class="field is-grouped">
              <div class="control has-icons-right">
                <input
                  v-validate="props.validate"
                  v-model="startString"
                  :placeholder="props.placeholder"
                  :name="props.name"
                  :data-vv-as="props.label && !props.useNameInError ? props.label : props.name"
                  :class="{ 'is-danger': errors.has(props.name) }"
                  class="input show-hand oto-clock-input"
                  type="text"
                  readonly
                  @blur="onBlur()"
                  @focus="onFocus()"
                  @click="toggle()">
                <span
                  v-if="!start.s"
                  class="icon is-small is-right">
                  <i class="fas fa-clock"/>
                </span>
                <span
                  v-if="errors.has(props.name)"
                  class="has-text-danger animated fadeInDown sentence-case">
                  {{ errors.first(props.name) }}
                </span>
              </div>
            </div>
          </div>
          <div class="dropdown-menu oto-clock">
            <div class="clock-control-holder">
              <div class="field has-addons oto-no-padding">
                <div class="control">
                  <div class="select">
                    <select v-model="start.h">
                      <option
                        v-for="h in hours"
                        :key="'start_h_' + h">{{ h }}</option>
                    </select>
                  </div>
                </div>
                <div class="control">
                  <div class="select">
                    <select v-model="start.m">
                      <option
                        v-for="m in minutes"
                        :key="'start_m_' + m">{{ m }}</option>
                    </select>
                  </div>
                </div>
                <div class="control">
                  <a
                    class="button is-primary oto-slim-button"
                    @click="toggleAMPM('start')">
                    <span>{{ start.a }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showEndTime"
        class="column is-narrow oto-vertical-align oto-to-separator animated fadeInDown">
        to
      </div>
      <div
        v-if="showEndTime"
        class="column is-narrow oto-no-padding-bottom">
        <div
          :class="{ 'is-active': end.s }"
          class="dropdown">
          <div class="dropdown-trigger">
            <div class="field is-grouped">
              <div class="control has-icons-right">
                <input
                  v-validate="props.endValidate"
                  v-model="endString"
                  :placeholder="props.endPlaceholder"
                  :name="props.name + 'end'"
                  :data-vv-as="props.label && !props.useNameInError ? props.label : props.name"
                  :class="{ 'is-danger': errors.has(props.name) }"
                  class="input show-hand oto-clock-input"
                  type="text"
                  readonly
                  @blur="onBlur()"
                  @focus="onFocus()"
                  @click="toggle('end')">
                <span
                  v-if="!end.s"
                  class="icon is-small is-right">
                  <i class="fas fa-clock"/>
                </span>
              </div>
            </div>
          </div>
          <div class="dropdown-menu oto-clock">
            <div class="clock-control-holder">
              <div class="field has-addons oto-no-padding">
                <div class="control">
                  <div class="select">
                    <select v-model="end.h">
                      <option
                        v-for="h in hours"
                        :key="'end_h_' + h">{{ h }}</option>
                    </select>
                  </div>
                </div>
                <div class="control">
                  <div class="select">
                    <select v-model="end.m">
                      <option
                        v-for="m in minutes"
                        :key="'end_m_' + m">{{ m }}</option>
                    </select>
                  </div>
                </div>
                <div class="control">
                  <a
                    class="button is-primary oto-slim-button"
                    @click="toggleAMPM('end')">
                    <span>{{ end.a }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </click-outside>
</template>

<script>
import ClickOutside from '@/plugins/onclick-outside'
import inputs from '@/mixins/Inputs.js'
export default {
  components: {
    ClickOutside
  },
  mixins: [inputs],
  data () {
    return {
      start: {
        s: false,
        h: null,
        m: null,
        a: 'AM'
      },
      end: {
        s: false,
        h: null,
        m: null,
        a: 'PM'
      },
      hours: ['', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      minutes: ['', '00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']
    }
  },
  computed: {
    time: function () {
      if (this.startString) {
        if (this.endString) {
          return this.startString + ',' + this.endString
        } else return this.startString
      } else return null
    },
    startString: function () {
      if (this.start.h && this.start.m) return this.start.h + ':' + this.start.m + ' ' + this.start.a
      else return null
    },
    endString: function () {
      if (this.end.h && this.end.m) return this.end.h + ':' + this.end.m + ' ' + this.end.a
      else return null
    },
    showEndTime: function () {
      if (this.props.subtype === 'range' && this.start.h && this.start.m) {
        return true
      } else return false
    }
  },
  watch: {
    time: function (val) {
      this.$emit('input', val)
    },
    'start.h': function (val) {
      if (val && !this.start.m) this.start.m = '00'
      else if (!val) this.start.m = ''
    },
    'start.m': function (val) {
      if (!val) this.start.h = ''
    },
    'end.h': function (val) {
      if (val && !this.end.m) {
        if (this.start.m) this.end.m = this.start.m
        else this.end.m = '00'
      } else if (!val) this.end.m = ''
    },
    'end.m': function (val) {
      if (!val) this.end.h = ''
    },
    'props.model': function (val) {
      this.stringToTime(val)
    }
  },
  mounted () {
    if (this.props.model) {
      this.stringToTime(this.props.model)
    }
  },
  methods: {
    stringToTime (val) {
      if (val) {
        var times = val.split(',')
        var startTimeString = times[0]
        var endTimeString = null
        if (times.length > 1) {
          endTimeString = times[1]
        }
        if (startTimeString) {
          times = startTimeString.split(':')
          this.start.h = times[0].trim()
          times = times[1].split(' ')
          this.start.m = times[0].trim()
          this.start.a = times[1].trim()
        }
        if (endTimeString) {
          times = endTimeString.split(':')
          this.end.h = times[0].trim()
          times = times[1].split(' ')
          this.end.m = times[0].trim()
          this.end.a = times[1].trim()
        }
      }
    },
    toggleAMPM (field) {
      if (field === 'start' || field === 'end') {
        if (this[field].a === 'PM') {
          this[field].a = 'AM'
        } else {
          this[field].a = 'PM'
        }
      }
    },
    toggle (type) {
      if (type === 'end') {
        this.end.s = !this.end.s
        this.start.s = false
      } else {
        this.start.s = !this.start.s
        this.end.s = false
      }
    },
    clickedOutside () {
      if (this.start.s) {
        this.start.s = false
      }
      if (this.end.s) {
        this.end.s = false
      }
    }
  }
}
</script>

<template>
  <div>
    <section
      v-if="tagsToFilter.length === 0 && currentTheme == null"
      class="section margin-bottom">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h2 class="subtitle uppercase">
              Campaign: {{ vuexWizardTitle }}
            </h2>
            <h1 class="title">
              Select A Theme
            </h1>
            <h2 class="subtitle">
              Pick a theme to establish the look and feel for your campaign.
            </h2>
          </div>
        </div>
        <div id="main-theme-holder" class="columns is-multiline">
          <div
            v-for="(tag, index) in topTags"
            :key="index"
            class="column is-full-mobile is-one-quarter-tablet is-one-quarter-desktop"
            @click="resetFilter(tag.tag)">
            <div class="oto-box oto-hover">
              <div class="title-holder is-capitalized">{{ tag.tag }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-else-if="currentTheme == null"
      class="section margin-bottom">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h2 class="subtitle uppercase">
              Campaign: {{ vuexWizardTitle }}
            </h2>
            <h1 class="title">
              Select A Theme
            </h1>
            <h2 class="subtitle">
              Pick a theme to establish the look and feel for your campaign.
            </h2>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="tag-holder">
              <div
                v-for="(tag, index) in tagsToFilter"
                :key="index">
                <div class="tags has-addons">
                  <a
                    class="tag is-link is-lowercase is-large is-rounded"
                    @click="resetFilter(tag)">{{ tag }}</a>
                  <a
                    class="tag is-delete is-link is-large is-rounded"
                    @click="removeFromFilter(tag)"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="filteredThemes.length>1"
          id="main-tags-holder"
          class="columns">
          <div class="column">
            <div class="buttons">
              <span
                v-for="(tag, index) in tagsFromFiltered"
                :key="index"
                :class="{'is-primary is-outlined': (index < 2 && tagsToFilter.length === 1)}"
                class="button is-rounded is-lowercase"
                @click="addToFilter(tag)">
                {{ tag }}
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="filteredThemes.length > 0"
          class="columns is-multiline is-mobile">
          <div
            v-for="(theme, index) in filteredThemes"
            :key="index"
            :id="'theme-card-' + index"
            class="column column is-full-mobile is-one-third-tablet is-one-quarter-desktop">
            <div class="oto-theme-card">
              <div
                class="card oto-hover"
                @click="selectTheme(theme.id)">
                <div class="card-image">
                  <div class="columns is-gapless is-mobile">
                    <div
                      class="column"
                      style="margin-right: 4px;">
                      <figure class="image is-square">
                        <img :src="theme.images.square[0]">
                      </figure>
                    </div>
                    <div class="column">
                      <figure class="image is-square">
                        <img :src="theme.images.square[1]">
                      </figure>
                    </div>
                  </div>
                </div>
                <div class="card-content">
                  <div class="content">
                    <div class="columns is-centered is-mobile is-size-7-desktop is-size-6-widescreen">
                      <div
                        :style="{ backgroundColor: theme.style.colors.primary }"
                        :class="{ 'light-border': theme.style.colors.primary === '#ffffff' }"
                        class="column is-narrow circle"/>
                      <div
                        :style="{ backgroundColor: theme.style.colors.secondary }"
                        :class="{ 'light-border': theme.style.colors.secondary === '#ffffff' }"
                        class="column is-narrow circle"/>
                      <div
                        :style="{ backgroundColor: theme.style.colors.alternate }"
                        :class="{ 'light-border': theme.style.colors.alternate === '#ffffff' }"
                        class="column is-narrow circle"/>
                    </div>
                  </div>
                </div>
                <div
                  class="card-content"
                  style="border-top: 1px solid #dbdbdb;">
                  <div class="content">
                    <div class="columns">
                      <div class="column">
                        <h6 class="has-text-weight-bold">{{ theme.title }}</h6>
                        <div class="tags">
                          <span
                            v-for="(tag, index) in theme.tags"
                            :key="index"
                            class="tag is-white">
                            {{ tag }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <main
      v-else
      class="oto-main">
      <div class="oto-side-background is-hidden-mobile"/>
      <div class="oto-main-container container">
        <div class="oto-duo">
          <div class="oto-lead">
            <!-- Left Column -->
            <div>
              <div class="step-heading is-hidden-mobile">
                <h2 class="subtitle uppercase">
                  Campaign: {{ vuexWizardTitle }}
                </h2>
                <h1 class="title">
                  Style Your Theme
                </h1>
              </div>
              <div class="tile is-ancestor reverse-tile-order">
                <div class="tile is-vertical is-7">
                  <div class="tile is-parent position-relative">
                    <article class="tile is-child is-paddingless">
                      <figure class="image is-16by9">
                        <img :src="currentTheme.images.landscape[0]">
                      </figure>
                    </article>
                    <article
                      :style="{ backgroundColor: currentColors.primary }"
                      class="tile is-child is-size-1-widescreen is-size-2-desktop is-size-4-tablet is-size-1-mobile fb-wrapper-v1">
                      <div
                        :style="{ color: currentColors.primaryFont }"
                        :class="currentFont.class"
                        class="has-text-weight-bold">{{ previewText[campaignType][0] }}</div>
                    </article>
                  </div>
                  <div class="tile">
                    <div class="tile is-parent position-relative is-hidden-touch">
                      <article class="tile is-child is-paddingless">
                        <figure class="image is-square image-w-border">
                          <img
                            :style="{ backgroundColor: currentColors.secondary }"
                            :src="currentTheme.images.square[2]">
                        </figure>
                      </article>
                      <article
                        :style="{ backgroundColor: currentColors.secondary }"
                        class="tile is-child is-size-5-widescreen is-size-6-desktop is-size-5-tablet is-size-4-mobile insta-wrapper-v3">
                        <div
                          :style="{ color: currentColors.secondaryFont }"
                          :class="currentFont.class"
                          class="has-text-weight-bold">{{ previewText[campaignType][1] }}</div>
                      </article>
                    </div>
                    <div class="tile is-parent position-relative">
                      <article class="tile is-child is-paddingless">
                        <figure class="image is-square">
                          <img :src="currentTheme.images.square[1]">
                        </figure>
                      </article>
                      <article class="tile is-child is-paddingless has-text-white is-size-5-widescreen is-size-6-desktop is-size-5-tablet is-size-1-mobile insta-wrapper-v2">
                        <div
                          :style="{ backgroundColor: currentColors.primary, color: currentColors.primaryFont }"
                          :class="currentFont.class"
                          class="has-text-weight-bold">{{ splitPreviewText[0] }}</div>
                        <div
                          :style="{ backgroundColor: currentColors.secondary, color: currentColors.secondaryFont }"
                          :class="currentFont.class"
                          class="has-text-weight-bold">{{ splitPreviewText[1] }}</div>
                        <div
                          :style="{ backgroundColor: currentColors.alternate, color: currentColors.alternateFont }"
                          :class="currentFont.class"
                          class="has-text-weight-bold">{{ splitPreviewText[2] }}</div>
                      </article>
                    </div>
                  </div>
                </div>
                <div class="tile is-parent">
                  <article class="tile is-child notification is-paddingless preview-desktop-website">
                    <div class="content">
                      <!-- Begin Webpage Thumb -->
                      <section
                        :style="{ background: currentColors.primary }"
                        class="hero is-medium is-bold">
                        <figure
                          :style="{ background: currentColors.primary, opacity: 0.3 }"
                          class="image is-16by9 is-marginless">
                          <img :src="currentTheme.images.landscape[1]">
                        </figure>
                      </section>
                      <section class="section content is-marginless is-overlay">
                        <div class="columns is-mobile">
                          <div class="column is-three-fifths-mobile is-full-tablet is-three-fifths-desktop is-three-fifths-widescreen is-three-fifths-fullhd">
                            <div class="content">
                              <h5
                                :style="{ color: currentColors.primaryFont }"
                                :class="currentFont.class">{{ previewTextTitle }}</h5>
                              <div class="placeholder-bars is-hidden-desktop-only is-hidden-tablet-only">
                                <div
                                  :style="{ backgroundColor: currentColors.primaryFont }"
                                  class="placeholder-bar placeholder-bar-100"/>
                                <div
                                  :style="{ backgroundColor: currentColors.primaryFont }"
                                  class="placeholder-bar placeholder-bar-100"/>
                                <div
                                  :style="{ backgroundColor: currentColors.primaryFont }"
                                  class="placeholder-bar placeholder-bar-60"/>
                              </div>
                            </div>
                          </div>
                          <div class="column is-two-fifths-mobile is-hidden-tablet-only is-two-fifths-desktop is-two-fifths-widescreen is-two-fifths-fullhd">
                            <div class="card preview-desktop-form">
                              <div class="card-content has-background-white">
                                <h5
                                  :class="currentFont.class"
                                  class="has-text-centered is-size-7">{{ previewText[campaignType][0] }}</h5>
                                <div class="form-placeholder-bars">
                                  <div class="form-placeholder-bar placeholder-bar-100 has-background-grey-lighter"/>
                                  <div class="form-placeholder-bar placeholder-bar-100 has-background-grey-lighter"/>
                                  <div class="form-placeholder-bar placeholder-bar-100 has-background-grey-lighter"/>
                                  <div class="form-placeholder-bar placeholder-bar-100 has-background-grey-lighter"/>
                                  <div class="form-placeholder-bar placeholder-bar-100 has-background-grey-lighter is-hidden-tablet-only"/>
                                  <div class="form-placeholder-bar placeholder-bar-100 has-background-grey-lighter is-hidden-tablet-only"/>
                                  <div class="form-placeholder-bar placeholder-bar-100 has-background-grey-lighter is-hidden-tablet-only"/>
                                  <div class="form-placeholder-bar placeholder-bar-100 has-background-grey-lighter is-hidden-desktop-only is-hidden-tablet-only"/>
                                  <div class="form-placeholder-bar placeholder-bar-100 has-background-grey-lighter is-hidden-widescreen-only is-hidden-desktop-only is-hidden-tablet-only is-hidden-mobile"/>
                                  <div
                                    :style="{ backgroundColor: currentColors.primary }"
                                    class="form-placeholder-button placeholder-bar-100"/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section class="section content is-marginless smaller-bottom-padding">
                        <div class="columns is-mobile">
                          <div class="column is-three-fifths-mobile is-full-tablet is-three-fifths-desktop is-three-fifths-widescreen">
                            <div class="content">
                              <div class="placeholder-bars">
                                <div class="placeholder-bar placeholder-bar-100 has-background-grey-light"/>
                                <div class="placeholder-bar placeholder-bar-100 has-background-grey-light"/>
                                <div class="placeholder-bar placeholder-bar-100 has-background-grey-light"/>
                                <div class="placeholder-bar placeholder-bar-100 has-background-grey-light"/>
                                <div class="placeholder-bar placeholder-bar-100 has-background-grey-light"/>
                                <div class="placeholder-bar placeholder-bar-60 has-background-grey-light"/>
                              </div>
                            </div>
                          </div>
                          <div class="column is-two-fifths is-hidden-tablet-only"/>
                        </div>
                      </section>
                      <section>
                        <div class="columns is-gapless is-mobile">
                          <div
                            class="column"
                            style="margin-right: 4px;">
                            <figure class="image is-square is-marginless">
                              <img :src="currentTheme.images.square[0]">
                            </figure>
                          </div>
                          <div
                            class="column"
                            style="margin-right: 4px;">
                            <figure class="image is-square is-marginless">
                              <img :src="currentTheme.images.square[1]">
                            </figure>
                          </div>
                          <div class="column">
                            <figure class="image is-square is-marginless">
                              <img :src="currentTheme.images.square[2]">
                            </figure>
                          </div>
                        </div>
                      </section>
                      <section class="has-background-grey-lighter">
                        <div class="placeholder-bars">
                          <div class="placeholder-bar placeholder-bar-60 has-background-grey-light is-centered"/>
                          <div class="placeholder-bar placeholder-bar-60 has-background-grey-light is-centered"/>
                        </div>
                      </section>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
          <aside class="oto-side">
            <div class="step-heading is-hidden-tablet">
              <h2 class="subtitle uppercase">
                Campaign: {{ vuexWizardTitle }}
              </h2>
              <h1 class="title">
                Style Your Theme
              </h1>
            </div>
            <div>
              <div class="right-column-text">
                Adjust the look and feel of your campaign with the style options below. The content samples are a representation of your theme. Your actual campaign content will be generated in just a moment.
              </div>
              <div id="theme-style-control-area">
                <div class="right-column-text">
                  <div class="subtitle-w-bar">Select your colors</div>
                  <div class="field is-grouped">
                    <ColorSwatchPick
                      :props="currentTheme.style.colors"
                      :select-id="currentSwatch.id"
                      v-model="currentSwatch" />
                  </div>
                </div>
                <div class="right-column-text">
                  <div class="subtitle-w-bar">Select your font</div>
                  <div class="field is-grouped">
                    <FontPick
                      :props="currentFontId"
                      v-model="currentFont" />
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </main>
    <Navigation v-model="navigation"/>
  </div>
</template>

<script>
import wizard from '@/mixins/Wizard.js'
import tags from '@/mixins/Tags.js'
import tour from '@/mixins/Tour.js'
import engineApi from '@/plugins/engine-api.js'
import { mapGetters } from 'vuex'
import ColorSwatchPick from '@/components/elements/ColorSwatchPick'
import FontPick from '@/components/elements/FontPick'

export default {
  components: {
    ColorSwatchPick,
    FontPick
  },
  mixins: [wizard, tags, tour],
  data () {
    return {
      campaignType: 'event',
      colorChangePatch: 0, // this runs three times for some reason, so we'll need a counter
      topTags: [],
      tagLimit: 30,
      currentSwatch: {},
      currentFontId: null,
      currentFont: {},
      currentColors: {},
      currentTheme: null,
      tagsToFilter: [],
      filteredThemes: [],
      tagsFromFiltered: [],
      themes: [],
      previewText: {
        'event': ['Sign Up!', 'Enroll Here', 'Join Our Event'],
        'sale': ['Buy Now!', 'Purchase', 'It\'s A Sale'],
        'info': ['More Info', 'Read More', 'In The Loop'],
        'form': ['Enroll Here', 'More Info', 'Join Our List'],
        'teetime': ['Book Now!', 'Tee Times', 'Book Your Time']
      }
    }
  },
  computed: {
    ...mapGetters({
      vuexTheme: 'wizardTheme',
      vuexDetails: 'wizardDetails'
    }),
    mainTag: function () {
      return this.tagsToFilter[0]
    },
    splitPreviewText: function () {
      return this.previewText[this.campaignType][2].split(' ')
    },
    previewTextTitle: function () {
      return this.generateCampaignTitle(this.vuexDetails)
    }
  },
  watch: {
    navigation: function (val, oldVal) {
      if (val === 'back') {
        this.backToStart()
      } else if (val === 'back2') {
        this.backToThemes()
      }
      this.navigation = null
    },
    currentSwatch (val, oval) {
      this.changeColors()
      // Continue the Tour
      if (val && oval) if (val.id) if ((val.id !== '000') && this.guideMode) this.TourThemeContinueStyle()
    },
    tagsToFilter (val) {
      if (val) {
        if (val.length === 0) {
          this.$store.dispatch('setWizardStep', 4)
        } else {
          this.$store.dispatch('setWizardStep', 5)
        }
      }
    },
    currentTheme: {
      handler: function (val, old) {
        if (val) {
          this.$store.dispatch('setWizardStep', 6)
          if (this.guideMode) this.TourThemeStyle()
        } else {
          this.$store.dispatch('setWizardStep', 5)
        }
      },
      deep: true
    },
    currentColors: {
      handler: function (val, old) {
        if (old) {
          this.colorChangePatch++
        }
      },
      deep: true
    },
    currentFont (val, old) {
      this.currentFontId = val.id
      // Continue the Tour
      if (val && old) if (val.id && old.id) if ((val.id !== old.id) && this.guideMode) this.TourThemeContinueStyle()
    },
    mainTag (val) {
      if (val) {
        // our main tag changed, we need to pull themes from server
        this.fetchThemes(val)
      }
    },
    'topTags.length' (val) {
      // Kickoff the Tour
      if (val > 0 && this.guideMode) this.TourTheme()
    },
    'tagsFromFiltered.length' (val, oval) {
      // Continue the Tour
      if (val > 0 && oval === 0 && this.guideMode) this.TourThemeTags()
    },
    'tagsToFilter.length' (val) {
      // Continue the tour
      if (val > 1 && this.guideMode) this.TourThemeCard()
    }
  },
  mounted () {
    this.$store.dispatch('setWizardStep', 4)
    // load in tags from API
    this.fetchTags()
    // load logic
    if (this.vuexTheme) {
      if (process.env.NODE_ENV === 'development') console.log('need to load into step: ' + JSON.stringify(this.vuexTheme))
      if (this.vuexTheme.tagsToFilter) {
        // Load in tags and filter the themes.
        // this will also populate filteredThemes and tagsFromFiltered
        this.tagsToFilter = this.$lodash.cloneDeep(this.vuexTheme.tagsToFilter)
        this.filterThemes()
      }
      if (this.vuexTheme.currentTheme) {
        // Set the theme
        this.currentTheme = this.$lodash.cloneDeep(this.vuexTheme.currentTheme)
      }
      if (this.vuexTheme.currentFontId) {
        // Set the fontId, this will also set the currentFont
        this.currentFontId = this.vuexTheme.currentFontId
        // Set the currentSwatch, this will also set currentColors
        // THIS IS A TOTAL HACK HERE! I"M SLEEPING BECAUSE this.currentTheme above also toggles the swatch and colors... NEED TO FIX THIS!
        var vm = this
        setTimeout(function () {
          vm.currentSwatch = vm.$lodash.cloneDeep(vm.vuexTheme.currentSwatch)
        }, 200)
      }
      if (this.vuexTheme.recommendedTheme) {
        this.resetFilter(this.vuexTheme.recommendedTheme)
      }
    }
  },
  methods: {
    fetchTags () {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchTags('theme', null,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.topTags = response
          }
        }
      )
    },
    fetchThemes (tag) {
      // fetch themes from server
      this.themes = []
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchThemes(tag,
        function (err, response) {
          if (err) {
            vm.$store.dispatch('setLoadingState', false)
            vm.handleXhrError(err)
          } else {
            response.forEach(function (theme) {
              theme.tags = theme.tags.split(',')
              theme.images = JSON.parse(theme.images)
              theme.style = JSON.parse(theme.style)
              vm.themes.push(theme)
            })
            setTimeout(function () {
              vm.$store.dispatch('setLoadingState', false)
              vm.filterThemes()
            }, 500)
          }
        }
      )
    },
    resetFilter (tag) {
      // tag is optional.
      // if passed it will reset the filter to the single passed tag.
      this.tagsToFilter = []
      if (tag) {
        this.addToFilter(tag)
        this.filterThemes()
      }
    },
    addToFilter (tag) {
      // tag is required.
      // adds single passed tag to filter.
      this.tagsToFilter.push(tag)
      this.filterThemes()
    },
    removeFromFilter (tag) {
      // tag is required
      // removes a single passed tag from the filer
      this.tagsToFilter = this.tagsToFilter.filter((el) => !tag.includes(el))
      this.filterThemes()
    },
    filterThemes () {
      // runs the component saved filter against the current themes
      this.filteredThemes = this.searchByTags(this.tagsToFilter, this.themes)[0]
      this.tagsFromFiltered = this.removeTagsFromArray(this.tagsToFilter, this.uniqueTags(this.filteredThemes, this.tagLimit))
    },
    selectTheme (id) {
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      this.currentTheme = this.$lodash.cloneDeep(this.$lodash.filter(this.filteredThemes, { id: id })[0])
      this.currentColors = this.$lodash.cloneDeep(this.currentTheme.style.colors)
      this.currentFontId = this.currentTheme.style.font
      // send google analytics event
      if (process.env.NODE_ENV !== 'development') window.gtag('event', 'campaign_action', { 'action': 'theme', 'themeId': id })
    },
    changeColors () {
      if (this.currentSwatch) {
        this.currentColors = this.$lodash.cloneDeep(this.currentSwatch.colors)
      }
    },
    backToStart () {
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      this.currentTheme = null
      this.tagsToFilter = []
    },
    backToThemes () {
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      this.currentTheme = null
      this.currentSwatch = {}
      this.currentFont = {}
      this.currentColors = null
      this.currentTheme = null
      this.currentFontId = null
    }
  }
}
</script>

<style scoped lang="scss">
/* Theme Selection Step */
.card {
  height: 100%;
}
.card-content {
  padding: 1.0rem 1.5rem;
}
.card-content h6 {
  margin-bottom: .5rem;
}
.card .tags .tag {
  background-color: transparent;
  height: 1.5em;
  margin-bottom: 0;
  padding: 0!important;
}
/* Theme Preview Step */
.tile.is-parent{
  padding: 0;
  margin: .75rem;
}
.preview-desktop-website section:not(.hero) {
  padding: 1rem 1rem;
}
.preview-desktop-form .card-content {
  padding: 1rem 0.5rem;
}
/* Theme Preview - Placeholder Bars */
.placeholder-bars .placeholder-bar {
  height: .7rem;
  margin-bottom: .4rem;
}
.form-placeholder-bars .form-placeholder-bar {
  height: 1rem;
  margin-bottom: .4rem;
}
.form-placeholder-bars .form-placeholder-button {
  height: 1.5rem;
  margin-top: 1rem;
}
.placeholder-bar-100 {
  width: 100%;
}
.placeholder-bar-95 {
  width: 95%;
}
.placeholder-bar-60 {
  width: 60%;
}
/* Adding Positioning */
.position-relative {
  position: relative;
}
/* Theme Preview - Text Overlays */
.insta-wrapper-v1 {
  position: absolute;
  left: 2rem;
  top: 3.5rem;
  text-align: left;
}
.insta-wrapper-v1 div{
  padding: .25rem;
  margin-bottom: .75rem;
}
.insta-wrapper-v2 {
  position: absolute;
  right: 20%;
  top: 25%;
  transform:translate(20%, -25%);
  text-align: right;
}
.insta-wrapper-v2 div{
  padding: .25rem;
  margin-bottom: .75rem;
}
.insta-wrapper-v3 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform:translate(-50%, -50%);
  text-align: center;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
}
.insta-wrapper-v3 div{
  padding: .25rem;
}
.insta-wrapper-v4 {
  position: absolute;
  left: 50%;
  top: 20%;
  width: 55%;
  transform:translate(-50%, -20%);
}
.insta-wrapper-v4 div{
  padding: .25rem;
}
.fb-wrapper-v1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform:translate(-50%, -50%);
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  white-space:nowrap;
}
.fb-wrapper-v1 div{
  padding: .25rem;
}
.fb-wrapper-v2 {
  position: absolute;
  left: 50%;
  top: 80%;
  transform:translate(-50%, -80%);
  text-align: center;
}
.fb-wrapper-v2 div{
  padding: .25rem;
}
#image-holder-top {
  display: none!important;
}
@media only screen and (min-width : $tablet) and (max-width : $desktop) {
  .smaller-bottom-padding {
    padding-bottom: 35px!important;
  }
}
</style>

export default {
  methods: {
    uniqueTags: function (campaigns, limit) {
      // returns Array of unique tags
      // given array of campaign objects
      // sorted by most common
      var allTags = []
      campaigns.forEach(campaign =>
        campaign.tags.forEach(
          attr => allTags.push(attr)
        )
      )
      var s = allTags.reduce(function (m, v) {
        m[v] = (m[v] || 0) + 1
        return m
      }, {})
      var a = []
      for (var k in s) {
        a.push({ k: k, n: s[k] })
      }
      a.sort(function (a, b) { return b.n - a.n })
      a = a.map(function (a) { return a.k })
      if (limit > 1 && a.length > 0) {
        return a.slice(0, limit + 1)
      } else {
        return a
      }
    },
    groupByTag: function (campaigns) {
      // returns Array of campaign objects that are grouped by unique tags
      // given an array of campaign objects
      var campaignsByTag = []
      var vm = this
      this.uniqueTags(campaigns).forEach(function (tag) {
        var result = {}
        result[tag] = vm.$lodash.filter(campaigns, function (campaign) {
          return vm.$lodash.includes(campaign.tags, tag)
        })
        campaignsByTag.push(result)
      })
      return campaignsByTag
    },
    searchByTags: function (tags, campaigns) {
      // returns Array[0], an Array of campaign objects that contain all tags given (AND)
      // returns Array[1], an Array of campaign objects that contain at least one of the tags given (OR)
      // given an array of tags and an array of campaign objects
      var tagsOrResult = []
      var tagsAndResult = []
      var vm = this
      tags.forEach(function (selected) {
        tagsOrResult.push(vm.$lodash.filter(campaigns, function (campaign) {
          return vm.$lodash.includes(campaign.tags, selected)
        }))
      })
      tagsOrResult = vm.$lodash.flatten(tagsOrResult)
      var tagMatches = vm.$lodash.countBy(tagsOrResult, 'id')
      Object.keys(tagMatches).forEach(function (key) {
        if (tagMatches[key] === tags.length) {
          tagsAndResult.push(vm.$lodash.filter(campaigns, function (campaign) {
            return parseInt(campaign.id) === parseInt(key)
          }))
        }
      })
      tagsAndResult = vm.$lodash.flatten(tagsAndResult)
      tagsAndResult = vm.$lodash.sortBy(tagsAndResult, ['sortorder', 'title'])
      tagsOrResult = vm.$lodash.uniqBy(tagsOrResult, 'id')
      tagsOrResult = vm.$lodash.sortBy(tagsOrResult, ['sortorder', 'title'])
      return [tagsAndResult, tagsOrResult]
    },
    removeTagsFromArray: function (tagsToRemove, tags) {
      return tags.filter((el) => !tagsToRemove.includes(el))
    }
  }
}

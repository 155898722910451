<template>
  <div v-if="show">
    <router-link
      v-if="metric === 'orders'"
      :to="{ name: 'route-engine-performance-orders' }"
      class="button is-rounded is-primary table-button">
      <span>Manage Your Orders</span>
    </router-link>
    <vue-good-table
      mode="remote"
      class="hide-pagination"
      styleClass="vgt-table nowrap small-text nosort"
      :pagination-options="paginationOptions"
      :totalRows="totalInResults"
      :rows="rows"
      :columns="columns"
      :isLoading.sync="tableLoading"
      @on-page-change="onPageChange">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'campaign' && metricClickMode">
          <a @click="click(props.row['campaign_id'])">
            {{props.formattedRow[props.column.field]}}
          </a>
        </span>
        <span v-else-if="props.column.field === 'hits_referralDomain'">
          <span v-if="props.row">
            <span v-if="props.row.hits_medium === 'widget'">
              <span
                :key="'reficon-'+props.row.confirmation"
                :id="'reficonid-'+props.row.confirmation"
                class="icon has-text-grey-dark"
                alt="Your website"
                title="Your website">
                <i class="fas fa-globe"/>
              </span>
              <span class="text">Website</span>
            </span>
            <span v-else-if="props.row.hits_medium === 'email'">
              <span
                :key="'reficon-'+props.row.confirmation"
                :id="'reficonid-'+props.row.confirmation"
                class="icon has-text-grey-dark"
                alt="Email"
                title="Email">
                <i class="fas fa-envelope"/>
              </span>
              <span class="text">Email</span>
            </span>
            <span v-else-if="props.row.hits_medium === 'gallus_app'">
              <span
                :key="'reficon-'+props.row.confirmation"
                :id="'reficonid-'+props.row.confirmation"
                class="icon has-text-grey-dark"
                alt="Mobile app"
                title="Mobile app">
                <i class="fas fa-mobile"/>
              </span>
              <span class="text">App</span>
            </span>
            <span v-else-if="props.row.hits_medium === 'qrcode' || props.row.hits_medium === 'shorturl'">
              <span
                :key="'reficon-'+props.row.confirmation"
                :id="'reficonid-'+props.row.confirmation"
                class="icon has-text-grey-dark"
                alt="Print flyer"
                title="Print flyer">
                <i class="fas fa-qrcode"/>
              </span>
              <span class="text">Print</span>
            </span>
            <span v-else-if="props.row.hits_medium === 'localpost'">
              <span
                :key="'reficon-'+props.row.confirmation"
                :id="'reficonid-'+props.row.confirmation"
                class="icon has-text-grey-dark"
                alt="Google My Business"
                title="Google My Business">
                <i class="fab fa-google"/>
              </span>
              <span class="text">Google</span>
            </span>
            <span v-else-if="props.row.hits_referralDomain">
              <span v-if="props.row.hits_referralDomain.includes('facebook')">
                <span
                  :key="'reficon-'+props.row.confirmation"
                  :id="'reficonid-'+props.row.confirmation"
                  class="icon has-text-grey-dark"
                  alt="Facebook"
                  title="Facebook">
                  <i class="fab fa-facebook"/>
                </span>
                <span class="text">Facebook</span>
              </span>
              <span v-else-if="props.row.hits_referralDomain.includes('twitter') || props.row.hits_referralDomain === 't.co'">
                <span
                  :key="'reficon-'+props.row.confirmation"
                  :id="'reficonid-'+props.row.confirmation"
                  class="icon has-text-grey-dark"
                  alt="Twitter"
                  title="Twitter">
                  <i class="fab fa-twitter"/>
                </span>
                <span class="text">Twitter</span>
              </span>
              <span v-else>
                <span class="text">{{props.row.hits_referralDomain}}</span>
              </span>
            </span>
            <span v-else-if="props.row.hits_medium">
              <span
                :key="'reficon-'+props.row.confirmation"
                :id="'reficonid-'+props.row.confirmation"
                class="icon has-text-grey-dark"
                alt="Other"
                title="Other">
                <i class="fas fa-question"/>
              </span>
              <span class="text">Other</span>
            </span>
            <span v-else>
              <span
                :key="'reficon-'+props.row.confirmation"
                :id="'reficonid-'+props.row.confirmation"
                class="icon has-text-grey-dark"
                alt="Direct"
                title="Direct">
                <i class="fas fa-sign-out-alt"/>
              </span>
              <span class="text">Direct</span>
            </span>
          </span>
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
      <div slot="emptystate"><p>No data</p></div>
    </vue-good-table>
  </div>
</template>

<script>
import engineApi from '@/plugins/engine-api.js'
import { VueGoodTable } from 'vue-good-table'
import { mapGetters } from 'vuex'
import xhr from '@/mixins/Xhr.js'

export default {
  components: {
    VueGoodTable
  },
  mixins: [xhr],
  props: {
    metric: {
      type: String,
      default: null // the metric table type. 'impressions', 'clicks', 'orders'
    },
    daysBack: {
      type: String, // number of days back as a string, "30", "60" or the string "range", which then the search.range value is used
      default: null
    },
    search: {
      type: Object, // object is converted to qs and passed to the api call. includes things like: search.range
      default: function () {
        return {}
      }
    },
    columnsToShow: { // can pass this as [] to just return raw mysql columns
      type: Array,
      default: function () {
        return []
      }
    },
    columnLabels: { // these should match the columnsToShow array length, pass '' to not change column title
      type: Array,
      default: function () {
        return []
      }
    },
    columnWidths: { // these should match the columnsToShow array length, pass '' to not change column width from goodtable default
      type: Array,
      default: function () {
        return []
      }
    },
    metricClickMode: {
      type: Boolean,
      default: false // allows metrics to be clicked with effects
    }
  },
  data () {
    return {
      columns: [],
      rows: [],
      totalInResults: 0,
      paginationOptions: {
        enabled: true,
        perPage: 10,
        perPageDropdown: [10],
        dropdownAllowAll: false,
        rowsPerPageLabel: ''
      },
      currentPage: 1,
      tableLoading: false
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentLoading: 'currentLoading'
    }),
    show: function () {
      if (this.columns.length > 0 && this.rows.length > 0) return true
      return false
    }
  },
  watch: {
    currentLoading (val) {
      if (val) this.tableLoading = true
      else this.tableLoading = false
    },
    daysBack: {
      handler: function (val) {
        this.fetchData()
      }
    },
    search: {
      deep: true,
      handler: function (val) {
        this.fetchData()
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchMetricTable(
        vm.metric, vm.currentAccount.id, vm.currentPage, vm.paginationOptions.perPage, vm.daysBack, vm.search,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.totalInResults = (response.rows) ? response.rows : 0
            vm.rows = (response.results) ? response.results : []
            vm.buildColumns()
          }
        }
      )
    },
    buildColumns () {
      if (this.rows) {
        if (this.rows.length > 0 && this.columns.length === 0) { // only build the columns if we haven't already
          // grab all the unique keys
          var keys = []
          this.rows.forEach(function (row) {
            keys = keys.concat(Object.keys(row))
          })
          keys = this.$lodash.uniq(keys)
          // loop through the keys and add them as vue goodtable columns
          var vm = this
          var rawColumns = [] // this is a temp array to store the columns we want to show. unsorted and no mofidications to the raw data returned from the api/mysql
          keys.forEach(function (key) {
            if (vm.columnsToShow.length === 0 || vm.columnsToShow.includes(key)) { // only add in columns that we want to show
              var fixedLabel = key.replace('_', ' ')
              fixedLabel = vm.$lodash.startCase(fixedLabel)
              rawColumns.push({
                label: fixedLabel,
                field: key,
                filterOptions: null,
                tdClass: null,
                sortable: false
              })
            }
          })
          // sort the columns if we got em
          // and perform any modifications to them
          if (rawColumns.length > 0) {
            var sortedColumns = new Array(rawColumns.length)
            // if we need to sort then
            // go through each unsorted column and add it to the right spot in the sorted array
            if (this.columnsToShow.length > 0) {
              rawColumns.forEach(function (col) {
                var pos = vm.columnsToShow.indexOf(col.field)
                if (pos >= 0 && pos < sortedColumns.length) sortedColumns[pos] = col
              })
              // update the column names if we have them
              if (sortedColumns.length === this.columnLabels.length) {
                sortedColumns.forEach(function (col, i) {
                  if (vm.columnLabels[i] !== '') col.label = vm.columnLabels[i]
                })
              }
              // update the column widths if we have them
              if (sortedColumns.length === this.columnWidths.length) {
                sortedColumns.forEach(function (col, i) {
                  if (vm.columnWidths[i] !== '') col.width = vm.columnWidths[i]
                })
              }
            } else {
              sortedColumns = rawColumns
            }
            // Update the names of the columns
            this.columns = sortedColumns // set the columns
          }
        }
      }
    },
    onPageChange (params) {
      this.currentPage = params.currentPage
      this.fetchData()
    },
    click (value) {
      if (this.metricClickMode) this.$emit('click', value)
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
  .table-button {
    margin-top: -20px;
    margin-bottom: 25px;
  }
  @media screen and (max-width: $mobile-max) {
    .table-button {
      margin-top: 20px;
      margin-bottom: 25px;
    }
  }
</style>

<template>
  <section class="section">
    <div class="container">
      <div
        v-if="pageLoaded"
        class="columns">
        <div class="column oto-max-width-fix">
          <h1 class="title">
            {{ campaign.title }}
          </h1>
          <h2 class="subtitle">
            Form Submissions
          </h2>
          <div v-if="columnsSubmissions.length > 0">
            <vue-good-table
              ref="submissions"
              :columns="columnsSubmissions"
              :rows="rowsSubmissions"
              :pagination-options="paginationOptions"
              style-class="vgt-table nowrap small-text">
            </vue-good-table>
            <div class="export-area">
              <Select
                :props="exportOptions"
                v-model="exportOptions.model"></Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import engineApi from '@/plugins/engine-api.js'
import { mapGetters } from 'vuex'
import xhr from '@/mixins/Xhr.js'
import FileSaver from 'file-saver'
import { parse } from 'json2csv'
import Select from '@/components/elements/Select'

export default {
  components: {
    VueGoodTable,
    Select
  },
  mixins: [xhr],
  data () {
    return {
      columnsSubmissions: [],
      rowsSubmissions: null,
      paginationOptions: {
        enabled: true,
        mode: 'records',
        perPage: 25,
        perPageDropdown: [25, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Submissions per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All'
      },
      exportOptions: {
        model: null,
        name: 'exportType',
        ref: 'exportType',
        options: [{ 'name': 'Export Submissions', 'value': null }, { 'name': 'CSV File', 'value': 'csv' }],
        fieldClass: 'no-margin'
      },
      campaign: {}
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    }),
    pageLoaded: function () {
      if (this.campaign.title) return true
      return false
    }
  },
  watch: {
    'exportOptions.model' (val) { // watch the export picker
      if (val) {
        this.exportCSV('submissions')
        this.exportOptions.model = null // reset the drop-down
      }
    }
  },
  mounted () {
    this.fetchSubmissions()
  },
  methods: {
    fetchSubmissions () {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchSubmissions(
        vm.currentAccount.id, vm.$route.params.campaignId, vm.currentAccount.timezone,
        function (err, response) {
          if (err) {
            vm.$store.dispatch('setLoadingState', false)
            vm.handleXhrError(err)
          } else {
            vm.campaign = (response.details) ? response.details : {}
            var submissionsById = {}
            response.submissions.forEach(function (row) {
              if (row.label) row.field = row.label + ' (' + row.field + ')'
              if (!submissionsById[row.submission_id]) {
                submissionsById[row.submission_id] = {}
              }
              submissionsById[row.submission_id][row.field] = row.answer
              submissionsById[row.submission_id]['Date (cp_date)'] = row.date
            })
            vm.rowsSubmissions = vm.$lodash.values(submissionsById)
            vm.columnsSubmissions = vm.$lodash.map(vm.$lodash.uniqBy(response.submissions, 'field'), function (item) {
              var filterOptions = null
              if (item.field.includes('name')) {
                filterOptions = {
                  enabled: true,
                  placeholder: 'Search'
                }
              }
              if (item.field.includes('email')) {
                filterOptions = {
                  enabled: true,
                  placeholder: 'Search'
                }
              }
              if (item.field.includes('phone')) {
                filterOptions = {
                  enabled: true,
                  placeholder: 'Search'
                }
              }
              // update the column label/name to look nice, using the field name
              var fixedLabel = item.field.replace('_', ' ')
              fixedLabel = vm.$lodash.startCase(fixedLabel)
              if (item.label) fixedLabel = item.label // if there is a saved label with the form submission, use that instead
              return {
                label: fixedLabel,
                field: item.field,
                filterOptions: filterOptions
              }
            })
            if (vm.columnsSubmissions) {
              vm.columnsSubmissions.unshift({
                label: 'Date',
                field: 'Date (cp_date)'
              })
            }
            vm.$store.dispatch('setLoadingState', false)
          }
        }
      )
    },
    exportCSV (mode) {
      if (mode) {
        var csv
        var blob
        if (mode === 'submissions') {
          // this.$refs.submissions.filteredRows[0].children
          csv = parse(this.rowsSubmissions)
          blob = new Blob([csv], { type: 'text/csv' })
          FileSaver.saveAs(blob, this.campaign.slug + '-submissions.csv')
        }
      }
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
.oto-max-width-fix {
  max-width: -webkit-fill-available;
}
.export-area {
  margin-top: 20px;
}
</style>

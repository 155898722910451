<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h1 class="title">
              Dashboard
            </h1>
            <h2 class="subtitle">
              Your full-service marketing platform.
            </h2>
          </div>
        </div>
        <div v-if="hasWarning">
          <div class="columns mobile-reverse">
            <div class="column is-half-desktop">
              <div class="home-stats narrow-tablet">
                <div class="subtitle-w-bar">Monthly Statistics</div>
                <Metrics :daysBack="daysBack" :compare="true" />
              </div>
            </div>
            <div class="column">
              <div class="home-checklist">
                <div class="subtitle-w-bar">Items That Need Attention</div>
                <Checklist />
              </div>
            </div>
          </div>
          <div class="columns break-on-tablet">
            <div class="column is-two-thirds-desktop">
              <div class="home-upcoming">
                <div class="subtitle-w-bar">Upcoming Campaigns</div>
                <div class="home-upcoming-wrapper">
                  <Calendar :campaigns="campaigns" />
                  <Upcoming :campaigns="campaigns" :fetched="fetchedCampaigns" />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="home-banner narrow-desktop">
                <Banner />
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="columns mobile-reverse">
          <div class="column is-two-thirds-desktop">
            <div class="columns">
              <div class="column">
                <div class="home-stats">
                  <div class="subtitle-w-bar">Monthly Statistics</div>
                  <Metrics :daysBack="daysBack" :compare="true" />
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="home-banner">
                  <Banner />
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="home-upcoming">
              <div class="subtitle-w-bar">Upcoming Campaigns</div>
                <div class="home-upcoming-wrapper narrow-desktop">
                  <Calendar :campaigns="campaigns" />
                  <Upcoming :campaigns="campaigns" :fetched="fetchedCampaigns" />
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FloatingActionButton :props="{ mobileOnly:true, path:'route-engine-campaigns-start' }"/>
    <!-- checklist hidden - used to set the v-model -->
    <Checklist class="hidden-checklist" v-model="hasWarning" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import Banner from '@/components/dashboard/widgets/Banner'
import Calendar from '@/components/dashboard/widgets/Calendar'
import Checklist from '@/components/dashboard/widgets/Checklist'
import FloatingActionButton from '@/components/elements/FloatingActionButton'
import Metrics from '@/components/dashboard/widgets/Metrics'
import Upcoming from '@/components/dashboard/widgets/Upcoming'

export default {
  components: {
    Banner,
    Calendar,
    Checklist,
    FloatingActionButton,
    Metrics,
    Upcoming
  },
  mixins: [xhr],
  data () {
    return {
      hasWarning: false,
      fetchedCampaigns: false,
      daysBack: '30', // for metrics
      campaigns: []
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentEmbedMode: 'currentEmbedMode'
    })
  },
  watch: {
    currentEmbedMode: function (val) {
      // this code here handles the redirect to the settings
      // to enable & configure integration with a 3rd party app
      // the currentEmbedMode is set in the main Dashboard.vue.
      if (val) {
        if (this.currentEmbedMode.mode === 'foreup' && this.currentEmbedMode.jwt && (this.currentAccount.foreup_active !== 1 || !this.currentAccount.foreup_id)) {
          // have a token and jwt - & - foreup is either inactive or we dont have a foreup course id
          this.$router.replace({ name: 'route-engine-settings-home', query: { refreshtab: 'other', subtab: 'apps', setup: 'foreup' } })
        }
      }
    }
  },
  mounted () {
    this.fetchCampaigns()
  },
  methods: {
    fetchCampaigns () {
      var vm = this
      engineApi.fetchStatistics(
        this.currentAccount.id, 'upcomingcampaigns', 'all', null,
        function (err, response) {
          vm.fetchedCampaigns = true
          if (err) vm.handleXhrError(err)
          else {
            var n = vm.$lodash.cloneDeep(response)
            n.forEach(function (camp, i) {
              n[i].channels = (camp.channels) ? JSON.parse(camp.channels) : {}
            })
            vm.campaigns = n
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.home-stats, .home-upcoming, .home-checklist {
  background-color: #F5F5F5;
  padding: 15px 40px 30px 40px;
}
.home-checklist, .home-upcoming {
  height: 100%;
}
.hidden-checklist {
  display: none;
}
.home-stats {
  position: relative;
  background-image: url('../../assets/imgs/other/bar-graph-background.png');
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: 75%;
  height: 100%;
}
.home-banner {
  padding: 0;
  height: 100%;
}
.home-upcoming-wrapper:not(.narrow-desktop) {
  display: flex;
}
.home-upcoming-wrapper:not(.narrow-desktop) div {
  width: 50%;
}
.subtitle-w-bar {
  font-size: 1.15em!important;
}
@media only screen and (min-width : 0px) and (max-width : $mobile-max) {
  .mobile-reverse {
    flex-direction: column-reverse;
    display: flex!important;
  }
  .home-upcoming-wrapper, .break-on-tablet {
    display: block!important;
  }
  .home-upcoming-wrapper:not(.narrow-desktop) div {
    width: 100%;
  }
}
@media only screen and (min-width : $tablet) and (max-width : $desktop) {
  .mobile-reverse {
    flex-direction: column-reverse;
    display: flex!important;
  }
  .home-upcoming-wrapper.narrow-desktop {
    display: flex!important;
  }
  .home-upcoming-wrapper.narrow-desktop div {
    width: 50%;
  }
  .break-on-tablet {
    display: block!important;
  }
}
</style>

<template>
  <div class="columns is-multiline is-mobile questions">
    <div
      v-for="(question, index) in questions"
      :id="'form-add-field-'+question.type+'-'+index"
      :key="index"
      :class="{ 'is-12 is-label is-hidden-mobile': question.type === 'label', 'is-third is-question': question.type !== 'label' }"
      class="column">
      <p
        v-if="question.type === 'label'"
        class="question-label-text">
        {{ question.name }}
      </p>
      <div
        v-else
        class="oto-box no-border has-background-white oto-hover"
        @click="selectQuestion(question.type)">
        <div class="title-holder is-capitalized">
          <div class="icon is-small has-text-grey-darker is-hidden">
            <i :class="question.icon"/>
          </div>
          <div class="question-name">{{ question.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    props: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      question: null,
      questions: [
        {
          'type': 'label',
          'name': 'Contact Fields'
        },
        {
          'type': 'email-input',
          'name': 'Email Address',
          'icon': 'fas fa-at'
        },
        {
          'type': 'address-input',
          'name': 'Address',
          'icon': 'fas fa-home'
        },
        {
          'type': 'phone-input',
          'name': 'Phone Number',
          'icon': 'fas fa-phone-square'
        },
        {
          'type': 'label',
          'name': 'Basic Information'
        },
        {
          'type': 'full-name-input',
          'name': 'Full Name',
          'icon': 'fas fa-user-circle'
        },
        {
          'type': 'label',
          'name': 'Golf'
        },
        {
          'type': 'golf-handicap-input',
          'name': 'Handicap',
          'icon': 'fas fa-dollar-sign'
        },
        {
          'type': 'golf-team-name-input',
          'name': 'Team Name',
          'icon': 'fas fa-user-circle'
        },
        {
          'type': 'label',
          'name': 'General Fields'
        },
        {
          'type': 'text-input',
          'name': 'Text',
          'icon': 'fas fa-i-cursor'
        },
        {
          'type': 'text-area',
          'name': 'Long Text',
          'icon': 'fas fa-align-justify'
        },
        {
          'type': 'option-group',
          'name': 'Option Group',
          'icon': 'fas fa-align-justify'
        }
      ]
    }
  },
  watch: {
    question (val) {
      if (val) {
        this.$emit('input', val.type)
      } else {
        this.$emit('input', null)
      }
    },
    props (type) {
      this.selectQuestion(type)
    }
  },
  mounted: function () {
    if (this.props) {
      this.selectQuestion(this.props)
    }
  },
  methods: {
    selectQuestion (type) {
      this.question = this.$lodash.filter(this.questions, { type: type })[0]
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
.questions > .is-label:not(:first-child) {
  padding-top: 0px!important;
}
.question-label {
  padding: 0 12px;
}
.question-label-text {
  color: #7a7a7a;
  font-size: 1em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold;
}
.is-question-xx {
  padding: 5px;
  width: 125px;
  max-width: 125px;
  height: 90px;
  max-height: 90px;
  overflow: hidden;
}
.question-name {
  max-height: 50px;
}
.is-label {
  padding-bottom: 0;
}
</style>

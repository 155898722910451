<template>
  <click-outside :handler="clickedOutside">
    <div
      :class="{ 'is-active': state }"
      class="dropdown">
      <div class="dropdown-trigger">
        <button
          class="button font-select"
          @click="toggle">
          <div
            v-if="font"
            class="font-holder">
            <span
              :class="font.class"
              class="font-preview">
              {{ font.name }}
            </span>
          </div>
          <span
            v-else
            class="empty-picker">
            Select A Font
          </span>
        </button>
      </div>
      <div class="dropdown-menu">
        <div class="dropdown-content font-popup animated fadeIn">
          <div
            v-for="font in fonts"
            :key="font.id"
            class="content">
            <div class="dropdown-item">
              <div
                class="columns font-row show-hand is-mobile is-size-6"
                @click="selectFont(font.id)">
                <span
                  :class="font.class"
                  class="font-preview">
                  {{ font.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </click-outside>
</template>

<script>
import ClickOutside from '@/plugins/onclick-outside'

export default {
  components: {
    ClickOutside
  },
  props: {
    props: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      state: false,
      font: null,
      fonts: [
        { 'id': '001', 'name': 'Abril Fatface', 'class': 'font-abril' },
        { 'id': '002', 'name': 'Bahiana', 'class': 'font-bahiana' },
        { 'id': '003', 'name': 'Baloo', 'class': 'font-baloo' },
        { 'id': '004', 'name': 'Bigshot One', 'class': 'font-bigshot' },
        { 'id': '005', 'name': 'Crete Round', 'class': 'font-crete' },
        { 'id': '006', 'name': 'Dynalight', 'class': 'font-dynalight' },
        { 'id': '007', 'name': 'Fjalla One', 'class': 'font-fjalla' },
        { 'id': '008', 'name': 'Gaegu', 'class': 'font-gaegu' },
        { 'id': '009', 'name': 'Galada', 'class': 'font-galada' },
        { 'id': '010', 'name': 'IBM Plex Mono', 'class': 'font-ibm' },
        { 'id': '011', 'name': 'Indie Flower', 'class': 'font-indie' },
        { 'id': '012', 'name': 'Khand', 'class': 'font-khand' },
        { 'id': '013', 'name': 'Lato', 'class': 'font-lato' },
        { 'id': '014', 'name': 'Libre Baskerville', 'class': 'font-libre' },
        { 'id': '015', 'name': 'Merriweather', 'class': 'font-merriweather' },
        { 'id': '016', 'name': 'Montserrat', 'class': 'font-montserrat' },
        { 'id': '017', 'name': 'Mountains of Christmas', 'class': 'font-mountains' },
        { 'id': '018', 'name': 'Old Standard TT', 'class': 'font-oldstandard' },
        { 'id': '019', 'name': 'OpenSans', 'class': 'font-opensans' },
        { 'id': '020', 'name': 'Pacifico', 'class': 'font-pacifico' },
        { 'id': '021', 'name': 'Passion One', 'class': 'font-passion' },
        { 'id': '022', 'name': 'Playfair Display', 'class': 'font-playfair-display' },
        { 'id': '023', 'name': 'Poppins', 'class': 'font-poppins' },
        { 'id': '024', 'name': 'PT Mono', 'class': 'font-ptmono' },
        { 'id': '025', 'name': 'PT Serif', 'class': 'font-ptserif' },
        { 'id': '026', 'name': 'Raleway', 'class': 'font-raleway' },
        { 'id': '027', 'name': 'Roboto', 'class': 'font-roboto' },
        { 'id': '028', 'name': 'Rochester', 'class': 'font-rochester' },
        { 'id': '029', 'name': 'Shrikhand', 'class': 'font-shrikhand' },
        { 'id': '030', 'name': 'Squada One', 'class': 'font-squada' },
        { 'id': '031', 'name': 'Tajawal', 'class': 'font-tajawal' },
        { 'id': '032', 'name': 'Ubuntu', 'class': 'font-ubuntu' },
        { 'id': '033', 'name': 'Wellfleet', 'class': 'font-wellfleet' },
        { 'id': '034', 'name': 'Yesteryear', 'class': 'font-yesteryear' }
      ]
    }
  },
  watch: {
    font (val) {
      this.$emit('input', val)
    }
  },
  mounted: function () {
    if (this.props) {
      this.selectFont(this.props)
      this.state = false
    }
  },
  methods: {
    toggle (onlyClose) {
      this.state = !this.state
    },
    selectFont (id) {
      this.font = this.$lodash.filter(this.fonts, { id: id })[0]
      this.toggle()
    },
    clickedOutside () {
      if (this.state) {
        this.state = false
      }
    }
  }
}
</script>

<style scoped>
.dropdown-content {
  min-width: 275px;
}
.empty-picker {
  margin-top: -5px;
}
.font-select {
  height: 45px;
  width: 300px;
  padding: 0px 0px 0px 10px!important;
  text-transform: capitalize!important;
  justify-content: left;
  line-height: 35px;
}
.font-popup {
  height: 180px;
  overflow-y: scroll;
}
.font-row:hover {
  background-color: #f4f4f4;
}
.font-preview {
  font-size: 24px;
}
.font-row > .font-preview {
  margin-left: 10px;
}
.font-holder {
  max-width: 245px;
  overflow-x: hidden;
}
.dropdown-menu {
  min-width: 0px;
  width: 160px;
  z-index: 9999;
}
.font-select::after {
  border: 3px solid #037dd3;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.7em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 0.7em;
}
.dropdown-trigger,
.dropdown-trigger .button {
  overflow: hidden;
}
</style>

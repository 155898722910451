<template>
  <div>
    <div class="columns">
      <div class="column oto-settings-sub-details">
        <p>General settings adjust how your business is displayed on marketing content and landing pages.</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="tile">
          <div class="is-hidden-mobile oto-vertical-tabs-is-left">
            <nav class="oto-vertical-tabs">
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 0}"
                @click="changeTab(0)">
                <span class="icon">
                  <i class="fas fa-info-circle"></i>
                </span>
                <span>Basic Details</span>
              </a>
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 1}"
                @click="changeTab(1)">
                <span class="icon">
                  <i class="fas fa-map-marked-alt"></i>
                </span>
                <span>Business Address</span>
              </a>
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 2}"
                @click="changeTab(2)">
                <span class="icon">
                  <i class="fas fa-location-arrow"></i>
                </span>
                <span>Location Settings</span>
              </a>
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 4}"
                @click="changeTab(4)">
                <span class="icon">
                  <i class="fas fa-qrcode"></i>
                </span>
                <span>Tracking Codes</span>
              </a>
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 5}"
                @click="changeTab(5)">
                <span class="icon">
                  <i class="fas fa-code"></i>
                </span>
                <span>3rd Party URLs</span>
              </a>
              <a/>
            </nav>
          </div>
          <div class="oto-vertical-tabs-is-right">
            <!--  BASIC DETAILS -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 0}"
              @click="changeTab(0, true)">
              <div id="tab0">Basic Details</div>
              <div></div>
            </div>
            <div
              v-if="currentTab === 0"
              class="oto-settings-content-area animated fadeIn">
              <TextInput
                :props="name"
                v-model="name.model"/>
              <TextInput
                :props="email"
                v-model="email.model"/>
              <TextInput
                :props="phone"
                v-model="phone.model"/>
              <TextInput
                :props="website"
                v-model="website.model"/>
              <!-- SAVE BUTTON -->
              <div class="oto-settings-save-button">
                <a
                  class="button is-primary is-rounded"
                  @click="save()">
                  <span class="icon is-small">
                    <i class="fas fa-save"/>
                  </span>
                  <span>Save Changes</span>
                </a>
              </div>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!-- ADDRESS -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 1}"
              @click="changeTab(1, true)">
              <div id="tab1">Business Address</div>
              <div></div>
            </div>
            <div
              v-if="currentTab === 1"
              class="oto-settings-content-area animated fadeIn">
              <TextInput
                :props="street"
                v-model="street.model"/>
              <TextInput
                :props="street2"
                v-model="street2.model"/>
              <div class="field is-horizontal">
                <div class="field-body oto-address-tablet-fix">
                  <TextInput
                    :props="city"
                    v-model="city.model"/>
                  <StateSelect
                    ref="state-pick"
                    :props="state"
                    v-model="state.model"/>
                  <TextInput
                    :props="postal"
                    v-model="postal.model"/>
                </div>
              </div>
              <!-- SAVE BUTTON -->
              <div class="oto-settings-save-button">
                <a
                  class="button is-primary is-rounded"
                  @click="save()">
                  <span class="icon is-small">
                    <i class="fas fa-save"/>
                  </span>
                  <span>Save Changes</span>
                </a>
              </div>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!-- LOCATION -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 2}"
              @click="changeTab(2, true)">
              <div id="tab2">Location Settings</div>
              <div></div>
            </div>
            <div
              v-if="currentTab === 2"
              class="oto-settings-content-area animated fadeIn">
              <TimeZoneSelect
                ref="timezone-pick"
                :props="timezone"
                v-model="timezone.model"/>
              <Select
                :props="currency"
                v-model="currency.model"/>
              <TextInput
                :props="tax"
                :control="tax.control"
                v-model="tax.model"/>
              <!-- SAVE BUTTON -->
              <div class="oto-settings-save-button">
                <a
                  class="button is-primary is-rounded"
                  @click="save()">
                  <span class="icon is-small">
                    <i class="fas fa-save"/>
                  </span>
                  <span>Save Changes</span>
                </a>
              </div>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!-- TRACKING -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 4}"
              @click="changeTab(4, true)">
              <div id="tab4">Tracking Codes</div>
              <div></div>
            </div>
            <div
              v-if="currentTab === 4"
              class="oto-settings-content-area animated fadeIn">
              <TextInput
                :props="uacode"
                v-model="uacode.model"/>
              <TextInput
                :props="ga4code"
                v-model="ga4code.model"/>
              <div>
                <a
                  href="https://help.campaignpilot.com/getting-started/general-settings#tracking-codes"
                  target="_blank">
                  <div class="icon has-text-primary">
                    <i class="fas fa-question-circle"/>
                  </div>
                  Learn more about 3rd party analytic tracking with CampaignPilot.
                </a>
              </div>
              <!-- SAVE BUTTON -->
              <div class="oto-settings-save-button">
                <a
                  class="button is-primary is-rounded"
                  @click="save()">
                  <span class="icon is-small">
                    <i class="fas fa-save"/>
                  </span>
                  <span>Save Changes</span>
                </a>
              </div>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!-- URLS -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 5}"
              @click="changeTab(5, true)">
              <div id="tab5">3rd Party URLs</div>
              <div></div>
            </div>
            <div
              v-if="currentTab === 5"
              class="oto-settings-content-area animated fadeIn">
              <TextInput
                :props="facebook"
                v-model="facebook.model"/>
              <TextInput
                :props="twitter"
                v-model="twitter.model"/>
              <TextInput
                :props="bookingengine"
                v-model="bookingengine.model"/>
              <div>
                <a
                  href="https://help.campaignpilot.com/getting-started/general-settings#3rd-party-urls"
                  target="_blank">
                  <div class="icon has-text-primary">
                    <i class="fas fa-question-circle"/>
                  </div>
                  Learn more about 3rd party URLs and how they are used.
                </a>
              </div>
              <!-- SAVE BUTTON -->
              <div class="oto-settings-save-button">
                <a
                  class="button is-primary is-rounded"
                  @click="save()">
                  <span class="icon is-small">
                    <i class="fas fa-save"/>
                  </span>
                  <span>Save Changes</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TextInput from '@/components/elements/TextInput'
import StateSelect from '@/components/elements/StateSelect.vue'
import TimeZoneSelect from '@/components/elements/TimeZoneSelect.vue'
import Select from '@/components/elements/Select'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import settings from '@/mixins/Settings.js'

export default {
  components: {
    TextInput, StateSelect, TimeZoneSelect, Select
  },
  mixins: [xhr, settings],
  data () {
    return {
      color: null,
      color2: null,
      name: {
        name: 'account name',
        ref: 'account-name',
        label: 'Business Name',
        model: null,
        fieldClass: 'oto-input-field-text-long'
      },
      email: {
        name: 'email',
        ref: 'account-email',
        label: 'Email Address',
        model: null,
        fieldClass: 'oto-input-field-text-medium',
        placeholder: 'name@domain.com'
      },
      phone: {
        name: 'phone',
        ref: 'account-phone',
        label: 'Phone Number',
        convertWhileTyping: 'usphone',
        convertTo: 'usphone',
        model: null,
        fieldClass: 'oto-input-field-text-short',
        placeholder: '(555) 555-5555'
      },
      website: {
        name: 'account website',
        ref: 'account-website',
        label: 'Website Address',
        model: null,
        fieldClass: 'oto-input-field-text-long',
        placeholder: 'https://www.yourbusiness.com/'
      },
      street: {
        name: 'street',
        ref: 'account-street',
        label: 'Street',
        model: null,
        fieldClass: 'oto-input-field-text-long'
      },
      street2: {
        name: 'street2',
        ref: 'account-street2',
        model: null,
        fieldClass: 'oto-input-field-text-long'
      },
      city: {
        name: 'city',
        ref: 'account-city',
        label: 'City',
        model: null,
        fieldClass: 'oto-input-field-text-short'
      },
      state: {
        name: 'state',
        ref: 'account-state',
        label: 'State',
        model: null,
        class: 'is-fullwidth'
      },
      postal: {
        name: 'postal',
        ref: 'account-postal',
        label: 'Postal Code',
        model: null
      },
      timezone: {
        name: 'timezone',
        ref: 'account-timezone',
        label: 'Timezone',
        model: null
      },
      currency: {
        model: null,
        name: 'currency',
        ref: 'currency',
        label: 'Currency',
        options: [{ 'name': 'USD', 'value': 'USD' }, { 'name': 'CAD', 'value': 'CAD' }, { 'name': 'EUR', 'value': 'EUR' }]
      },
      tax: {
        name: 'tax',
        ref: 'account-tax',
        label: 'Default Tax Rate',
        rightIcon: 'fas fa-percent',
        convertTo: 'float',
        control: {
          divstyle: 'max-width: 100px;'
        },
        model: null,
        placeholder: '2.5'
      },
      uacode: {
        name: 'uacode',
        ref: 'uacode',
        label: 'Universal Analytics Tracking ID',
        model: null,
        placeholder: 'UA-123456789-1',
        fieldClass: 'oto-input-field-text-medium'
      },
      ga4code: {
        name: 'ga4code',
        ref: 'ga4code',
        label: 'Google Analytics 4 Measurement ID',
        model: null,
        placeholder: 'G-12345',
        fieldClass: 'oto-input-field-text-medium'
      },
      facebook: {
        name: 'facebook',
        ref: 'facebook',
        label: 'Facebook Page URL',
        model: null,
        placeholder: 'https://www.facebook.com/campaignpilot',
        fieldClass: 'oto-input-field-text-long'
      },
      twitter: {
        name: 'twitter',
        ref: 'twitter',
        label: 'Twitter Page URL',
        model: null,
        placeholder: 'https://twitter.com/campaignpilot',
        fieldClass: 'oto-input-field-text-long'
      },
      bookingengine: {
        name: 'bookingengine',
        ref: 'booking-engine',
        label: 'Booking Engine URL',
        model: null,
        placeholder: 'https://golf.bookingengine.com/my-course-id',
        fieldClass: 'oto-input-field-text-long'
      }
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentUser: 'currentUser'
    })
  },
  mounted () {
    this.name.model = this.currentAccount.name
    this.email.model = this.currentAccount.email
    this.phone.model = this.currentAccount.phone
    this.website.model = this.currentAccount.website
    this.street.model = this.currentAccount.street
    this.street2.model = this.currentAccount.street2
    this.city.model = this.currentAccount.city
    this.state.model = this.currentAccount.state
    this.postal.model = this.currentAccount.postal
    this.timezone.model = this.currentAccount.timezone
    this.bookingengine.model = this.currentAccount.booking_engine_url
    this.currency.model = this.currentAccount.currency
    this.uacode.model = this.currentAccount.uacode
    this.tax.model = this.currentAccount.tax_rate
    this.facebook.model = this.currentAccount.facebook_url
    this.twitter.model = this.currentAccount.twitter_url
    this.ga4code.model = this.currentAccount.ga4code
  },
  methods: {
    save (returnPath) {
      var accountUpdates = {
        account_id: this.currentAccount.id,
        name: this.name.model,
        email: this.email.model,
        phone: this.phone.model,
        website: this.website.model,
        street: this.street.model,
        street2: this.street2.model,
        city: this.city.model,
        st: this.state.model,
        postal: this.postal.model,
        timezone: this.timezone.model,
        bookingengine: this.bookingengine.model,
        currency: this.currency.model,
        uacode: this.uacode.model,
        tax: this.tax.model,
        facebook: this.facebook.model,
        twitter: this.twitter.model,
        ga4code: this.ga4code.model
      }
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.saveAccount('general', accountUpdates,
        function (err) {
          vm.$store.dispatch('fetchAccounts') // refresh account list, only need to do this becuase the name might change
          vm.$store.dispatch('setAccount', vm.currentAccount.id) // refresh vuex account
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (process.env.NODE_ENV === 'development') console.log('saved...')
            vm.$store.dispatch('setNotify', { state: true, text: 'Your settings have been updated.' })
            engineApi.triggerAggregateBuild(vm.currentAccount.id,
              function () {
                if (returnPath) {
                  vm.$router.push({ name: returnPath })
                }
              })
          }
        }
      )
    }
  }
}
</script>

<template>
  <div>
    <div class="columns">
      <div class="column oto-settings-sub-details">
        <p>Channels are where CampaignPilot distributes your marketing messages.</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="tile">
          <div class="is-hidden-mobile oto-vertical-tabs-is-left">
            <nav class="oto-vertical-tabs" id="vertical-tabs-channels">
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 0}"
                @click="changeTab(0)">
                <div>
                  <span class="icon">
                    <i class="fab fa-facebook"></i>
                  </span>
                  <span>Facebook</span>
                </div>
                <div v-html="statusTagFacebook" class="status-tag" />
              </a>
              <!--
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 1}"
                @click="changeTab(1)">
                <div>
                  <span class="icon">
                    <i class="fab fa-twitter"></i>
                  </span>
                  <span>Twitter</span>
                </div>
                <div v-html="statusTagTwitter" class="status-tag" />
              </a>
              -->
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 2}"
                @click="changeTab(2)">
                <div>
                  <span class="icon">
                    <i class="fab fa-google"></i>
                  </span>
                  <span>Google</span>
                </div>
                <div v-html="statusTagGoogle" class="status-tag" />
              </a>
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 3}"
                @click="changeTab(3)">
                <div>
                  <span class="icon">
                    <i class="fab fa-instagram"></i>
                  </span>
                  <span>Instagram</span>
                </div>
                <div v-html="statusTagInstagram" class="status-tag" />
              </a>
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 4}"
                @click="changeTab(4)">
                <div>
                  <span class="icon">
                    <i class="fas fa-globe"></i>
                  </span>
                  <span>Website</span>
                </div>
                <div v-html="statusTagWebsite" class="status-tag" />
              </a>
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 5}"
                @click="changeTab(5)">
                <div>
                  <span class="icon">
                    <i class="fas fa-mobile"></i>
                  </span>
                  <span>Mobile App</span>
                </div>
                <div v-html="statusTagMobile" class="status-tag" />
              </a>
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 6}"
                @click="changeTab(6)">
                <div>
                  <span class="icon">
                    <i class="fas fa-envelope"></i>
                  </span>
                  <span>Email</span>
                </div>
                <div v-html="statusTagEmail" class="status-tag" />
              </a>
              <a/>
            </nav>
          </div>
          <div class="oto-vertical-tabs-is-right">
            <!-- FACEBOOK -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 0}"
              @click="changeTab(0, true)">
              <div id="tab0">Facebook</div>
              <div>
                <div v-html="statusTagFacebook" class="status-tag" />
              </div>
            </div>
            <div
              v-if="currentTab === 0"
              class="oto-settings-content-area animated fadeIn"
              id="settings-channels-facebook-area">
              <div v-if="facebookAuthd">
                <div class="oto-second-heading is-hidden-mobile">Facebook Settings</div>
                <p
                  v-if="facebookWarning"
                  class="padding-bottom">There seems to be an issue with your Facebook connection. We're having problems either posting your campaigns or pulling impression data for reporting.
                  <br/><br/>You should be able to resolve this issue by unlinking your Facebook account and connecting it again. Start this process by clicking the Unlink button below.</p>
                <div v-else>
                  <p class="padding-bottom">Facebook is configured and working properly. Select the business page you want your campaigns posted to:</p>
                  <div
                    v-for="(page, index) in facebookPages"
                    :key="index">
                    <div class="field">
                      <input
                        :id="'fb-page-' + index"
                        :name="'fb-page-' + index"
                        :checked="page.id === facebookPage.id"
                        class="is-checkradio is-info is-circle"
                        type="checkbox"
                        @input="selectFacebookPage($event)">
                      <label :for="'fb-page-' + index">{{ page.name }}</label>
                    </div>
                  </div>
                </div>
                <div class="padding-bottom"/>
                <a
                  class="button is-warning is-rounded"
                  @click="resetChannel('facebook')">
                  <span class="icon is-small">
                    <i class="fas fa-unlink"/>
                  </span>
                  <span>Unlink Facebook</span>
                </a>
              </div>
              <div v-else>
                <div class="oto-second-heading is-hidden-mobile">Setting Up The Facebook Channel</div>
                <div v-if="Array.isArray(otherFacebook)">
                  <div v-if="otherFacebook.length > 0">
                    <article class="message">
                      <div class="message-body">
                        <p class="padding-bottom">
                          Your account is associated with another CampaignPilot business that has already configured Facebook. If you want to use that same Facebook account continue by selecting that business below.
                        </p>
                        <nav
                          v-if="otherFacebookPages.length > 0"
                          class="facebook-pages">
                          <a
                            v-for="(page, index) in otherFacebookPages"
                            :key="'ofbpg-' + index"
                            class="panel-block"
                            @click="saveOtherFacebookPage(page)">
                            <span class="icon">
                              <i class="fas fa-building"></i>
                            </span>
                            <span>{{ page.name }}</span>
                          </a>
                          <a
                            class="panel-block"
                            @click="goBackFromOtherFacebookPages">
                            <span class="icon is-small">
                              <i class="fas fa-angle-double-left"/>
                            </span>
                            <span>Go Back</span>
                          </a>
                        </nav>
                        <nav
                          v-else-if="facebookLoading"
                          class="facebook-pages">
                          <div class="panel-block">
                            <button class="button is-loading no-border is-light" />
                            <span>Just a moment, we're fetching the business pages for that Facebook Account.</span>
                          </div>
                        </nav>
                        <nav
                          v-else
                          class="facebook-pages">
                          <a
                            v-for="(channel, index) in otherFacebook"
                            :key="'fbch-' + index"
                            class="panel-block"
                            @click="getFacebookPages(channel.accountId, channel.channelId)">
                            <span class="icon">
                              <i class="fas fa-store"></i>
                            </span>
                            <span>{{ channel.accountName }}</span>
                            <span class="icon is-small">
                              <i class="fas fa-angle-double-right"/>
                            </span>
                          </a>
                        </nav>
                      </div>
                    </article>
                    <p class="padding-bottom">
                      Otherwise click the button below to authorize a different Facebook account.
                    </p>
                  </div>
                  <div v-else>
                    <p class="padding-bottom">
                      Connect your Facebook account to CampaignPilot and have marketing campaigns automatically posted to your business page.
                    </p>
                  </div>
                </div>
                <div
                  v-if="instagramAuthd"
                  class="oto-info-alert">
                  <div class="icon">
                    <i class="fas fa-exclamation-triangle"/>
                  </div>
                  <span>&nbsp;Facebook and Instagram use the same account to make posts. When you authorize Facebook using the button below your Instagram credentials will be refreshed. After completing this Facebook authorization please review your Instagram channel settings to assure CampaignPilot is still posting to the proper account.</span>
                </div>
                <a
                  class="button is-primary is-rounded oto-settings-save-button"
                  @click="authFacebookAndInstagram">
                  <span class="icon is-small">
                    <i class="fab fa-facebook"/>
                  </span>
                  <span>Authorize Facebook</span>
                </a>
                <transition name="fade" mode="out-in">
                  <div
                    v-if="showPopupBlockAlert"
                    class="oto-popup-block-alert">
                    <div class="icon">
                      <i class="fas fa-exclamation-triangle"/>
                    </div>
                    <span>Nothing happened? Check if your browser blocked a pop-up window.</span>
                  </div>
                </transition>
              </div>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!-- TWITTER
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 1}"
              @click="changeTab(1, true)">
              <div id="tab1">Twitter</div>
              <div>
                <div v-html="statusTagTwitter" class="status-tag" />
              </div>
            </div>
            <div
              v-if="currentTab === 1"
              class="oto-settings-content-area animated fadeIn">
              <div v-if="twitterAuthd">
                <div class="oto-second-heading is-hidden-mobile">Twitter Settings</div>
                <p class="padding-bottom">Twitter is configured properly. CampaignPilot will post to
                  <strong>
                    <a
                      :href="'https://twitter.com/' + twitterHandle"
                      target="_blank">&#64;{{ twitterHandle }}</a>
                  </strong>.
                </p>
                <a
                  class="button is-warning is-rounded oto-settings-save-button"
                  @click="resetChannel('twitter')">
                  <span class="icon is-small">
                    <i class="fas fa-unlink"/>
                  </span>
                  <span>Unlink Twitter</span>
                </a>
              </div>
              <div v-else>
                <div class="oto-second-heading is-hidden-mobile">Setting Up The Twitter Channel</div>
                <p class="padding-bottom">
                  Connect your Twitter account to CampaignPilot and have marketing campaigns automatically posted to your Twitter handle.
                </p>
                <a
                  class="button is-primary is-rounded oto-settings-save-button"
                  @click="authTwitter">
                  <span class="icon is-small">
                    <i class="fab fa-twitter"/>
                  </span>
                  <span>Authorize Twitter</span>
                </a>
                <transition name="fade" mode="out-in">
                  <div
                    v-if="showPopupBlockAlert"
                    class="oto-popup-block-alert">
                    <div class="icon">
                      <i class="fas fa-exclamation-triangle"/>
                    </div>
                    <span>Nothing happened? Check if your browser blocked a pop-up window.</span>
                  </div>
                </transition>
              </div>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            -->
            <!-- GOOGLE -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 2}"
              @click="changeTab(2, true)">
              <div id="tab2">Google</div>
              <div>
                <div v-html="statusTagGoogle" class="status-tag" />
              </div>
            </div>
            <div
              v-if="currentTab === 2"
              class="oto-settings-content-area animated fadeIn">
              <div v-if="googleAuthd">
                <div class="oto-second-heading is-hidden-mobile">Google Settings</div>
                <div v-if="googleWarning">
                  <p class="padding-bottom">There seems to be an issue with your Google connection. We're having problems either posting your campaigns or pulling impression data for reporting.
                  <br/><br/>You should be able to resolve this issue by unlinking your Google account and connecting it again. Start this process by clicking the Unlink button below.</p>
                  <a
                    class="button is-warning is-rounded oto-settings-save-button"
                    @click="resetChannel('google')">
                    <span class="icon is-small">
                      <i class="fas fa-unlink"/>
                    </span>
                    <span>Unlink Google</span>
                  </a>
                </div>
                <div v-else>
                  <div v-if="googleLocation">
                    <p class="padding-bottom">Google My Business is configured properly. CampaignPilot will post to
                      <strong>{{ googleLocation.locationName }}</strong>.
                    </p>
                    <a
                      class="button is-warning is-rounded oto-settings-save-button"
                      @click="resetChannel('google')">
                      <span class="icon is-small">
                        <i class="fas fa-unlink"/>
                      </span>
                      <span>Unlink Google</span>
                    </a>
                  </div>
                  <div v-else-if="googleLocations.length > 0">
                    <p class="padding-bottom">
                      Now select the business location to complete your Google My Business channel setup:
                    </p>
                    <nav class="facebook-pages">
                      <a
                        v-for="(location, index) in googleLocations"
                        :key="'gl-' + index"
                        class="panel-block"
                        @click="selectGoogleLocation(index)">
                        <span>{{ location.locationName }}</span>
                        <span class="icon is-small">
                          <i class="fas fa-angle-double-right"/>
                        </span>
                      </a>
                      <a
                        class="panel-block"
                        @click="goBackFromOtherGoogleLocations">
                        <span class="icon is-small">
                          <i class="fas fa-angle-double-left"/>
                        </span>
                        <span>Go Back</span>
                      </a>
                    </nav>
                  </div>
                  <div v-else-if="googleLoading">
                    <p class="padding-bottom">
                      CampaignPilot needs to know the specific location to make campaign posts to. Start by selecting an Account below:
                    </p>
                    <nav class="facebook-pages">
                      <div class="panel-block">
                        <button class="button is-loading no-border" />
                        <span>Just a moment, we're fetching the locations for your Google Account.</span>
                      </div>
                    </nav>
                  </div>
                  <div v-else>
                    <p class="padding-bottom">
                      CampaignPilot needs to know the specific location to make campaign posts to. Start by selecting an Account below:
                    </p>
                    <nav class="facebook-pages">
                      <a
                        v-for="(account, index) in googleAccounts"
                        :key="'ga-' + index"
                        class="panel-block"
                        @click="selectGoogleAccount(index)">
                        <span>{{ account.name }}</span>
                        <span class="icon is-small">
                          <i class="fas fa-angle-double-right"/>
                        </span>
                      </a>
                    </nav>
                    <a
                      class="button is-warning is-rounded oto-settings-save-button"
                      @click="resetChannel('google')">
                      <span class="icon is-small">
                        <i class="fas fa-unlink"/>
                      </span>
                      <span>Unlink Google</span>
                    </a>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="oto-second-heading is-hidden-mobile">Setting Up The Google Channel</div>
                <div v-if="Array.isArray(otherGoogle)">
                  <div v-if="otherGoogle.length > 0">
                    <p class="padding-bottom">
                      Your account is associated with another CampaignPilot business that has already configured Google. If you want to use that same Google account continue by selecting that business below.
                    </p>
                    <nav
                      v-if="googleLocations.length > 0"
                      class="facebook-pages">
                      <a
                        v-for="(location, index) in googleLocations"
                        :key="'ogopg-' + index"
                        class="panel-block"
                        @click="selectGoogleLocation(index, true)">
                        {{ location.locationName }}
                      </a>
                      <a
                        class="panel-block"
                        @click="goBackFromOtherGoogleLocations">
                        <span class="icon is-small">
                          <i class="fas fa-angle-double-left"/>
                        </span>
                        &nbsp;&nbsp;Go Back
                      </a>
                    </nav>
                    <nav
                      v-else-if="googleLoading"
                      class="facebook-pages">
                      <div class="panel-block">
                        <button class="button is-loading no-border" />
                        Just a moment, we're fetching the locations for that Google Account.
                      </div>
                    </nav>
                    <nav
                      v-else
                      class="facebook-pages">
                      <a
                        v-for="(channel, index) in otherGoogle"
                        :key="'gmbch-' + index"
                        class="panel-block"
                        @click="selectOtherGoogleAccount(index)">
                        {{ channel.accountName }}&nbsp;&nbsp;
                        <span class="icon is-small">
                          <i class="fas fa-angle-double-right"/>
                        </span>
                      </a>
                    </nav>
                    <p class="padding-bottom">
                      Otherwise click the button below to authorize a new Google account.
                    </p>
                  </div>
                  <div v-else>
                    <p class="padding-bottom">
                      Connect your Google account to CampaignPilot and have marketing campaigns automatically posted to your Google My Business listing.
                    </p>
                  </div>
                </div>
                <a
                  class="button is-primary is-rounded oto-settings-save-button"
                  @click="authGoogle">
                  <span class="icon is-small">
                    <i class="fab fa-google"/>
                  </span>
                  <span>Authorize Google</span>
                </a>
                <transition name="fade" mode="out-in">
                  <div
                    v-if="showPopupBlockAlert"
                    class="oto-popup-block-alert">
                    <div class="icon">
                      <i class="fas fa-exclamation-triangle"/>
                    </div>
                    <span>Nothing happened? Check if your browser blocked a pop-up window.</span>
                  </div>
                </transition>
                <div v-if="currentUser.superadmin === 1">
                  <br/>
                  <a
                    :disabled="currentLoading"
                    :class="{ 'is-loading': currentLoading }"
                    class="button is-danger is-rounded"
                    @click="masterGoogle">
                    <span class="icon is-small">
                      <i class="fas fa-user-ninja"/>
                    </span>
                    <span>1-2-1 Marketing Account</span>
                  </a>
                </div>
              </div>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!-- INSTAGRAM -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 3}"
              @click="changeTab(3, true)">
              <div id="tab3">Instagram</div>
              <div>
                <div v-html="statusTagInstagram" class="status-tag" />
              </div>
            </div>
            <div
              v-if="currentTab === 3"
              class="oto-settings-content-area animated fadeIn"
              id="settings-channels-instagram-area">
              <div v-if="instagramAuthd">
                <div class="oto-second-heading is-hidden-mobile">Instagram Settings</div>
                <div>
                  <p class="padding-bottom">Instagram is configured and working properly. Select the page you want your campaigns posted to:</p>
                  <div
                    v-for="(page, index) in instagramPages"
                    :key="index">
                    <div class="field">
                      <input
                        :id="'ig-page-' + index"
                        :name="'ig-page-' + index"
                        :checked="page.id === instagramPage.id"
                        class="is-checkradio is-info is-circle"
                        type="checkbox"
                        @input="selectInstagramPage($event)">
                      <label :for="'ig-page-' + index">{{ page.name }}</label>
                    </div>
                  </div>
                </div>
                <div class="padding-bottom"/>
                <a
                  class="button is-warning is-rounded"
                  @click="resetChannel('instagram')">
                  <span class="icon is-small">
                    <i class="fas fa-unlink"/>
                  </span>
                  <span>Unlink Instagram</span>
                </a>
                <div class="instagram-tip">
                  <div class="icon">
                    <i class="fas fa-lightbulb"/>
                  </div>
                  <span>Instagram posts cannot be linked to a landing page. Instead manually add the following URL to your Instagram profile:</span>
                  <br/>
                  <span><a :href="aggregateLandingPageURL" target="_blank">{{aggregateLandingPageURL}}</a></span>
                </div>
              </div>
              <div v-else>
                <div class="oto-second-heading is-hidden-mobile">Setting Up The Instagram Channel</div>
                <p class="padding-bottom">To configure the Instagram channel you will need to authorize using your Facebook account.</p>
                <div v-if="Array.isArray(otherInstagram)">
                  <div v-if="otherInstagram.length > 0">
                    <article class="message">
                      <div class="message-body">
                        <p class="padding-bottom">
                          Your account is associated with another CampaignPilot business that has already configured Instagram. If you want to use the same Facebook account that was used for that configuration continue by selecting the business below.
                        </p>
                        <nav
                          v-if="otherInstagramPages.length > 0"
                          class="facebook-pages">
                          <a
                            v-for="(page, index) in otherInstagramPages"
                            :key="'oigpg-' + index"
                            class="panel-block"
                            @click="saveOtherInstagramPage(page)">
                            <span class="icon">
                              <i class="fas fa-building"></i>
                            </span>
                            <span>{{ page.name }}</span>
                          </a>
                          <a
                            class="panel-block"
                            @click="goBackFromOtherInstagramPages">
                            <span class="icon is-small">
                              <i class="fas fa-angle-double-left"/>
                            </span>
                            <span>Go Back</span>
                          </a>
                        </nav>
                        <nav
                          v-else-if="instagramLoading"
                          class="facebook-pages">
                          <div class="panel-block">
                            <button class="button is-loading no-border is-light" />
                            <span>Just a moment, we're fetching the business pages for that Instagram Account.</span>
                          </div>
                        </nav>
                        <nav
                          v-else
                          class="facebook-pages">
                          <a
                            v-for="(channel, index) in otherInstagram"
                            :key="'igch-' + index"
                            class="panel-block"
                            @click="getInstagramPages(channel.accountId, channel.channelId)">
                            <span class="icon">
                              <i class="fas fa-store"></i>
                            </span>
                            <span>{{ channel.accountName }}</span>
                            <span class="icon is-small">
                              <i class="fas fa-angle-double-right"/>
                            </span>
                          </a>
                        </nav>
                      </div>
                    </article>
                    <p class="padding-bottom">
                      Otherwise click the button below to authorize using different Facebook account.
                    </p>
                  </div>
                  <div v-else>
                    <p class="padding-bottom">
                      Connect your Instagram account to CampaignPilot and have marketing campaigns automatically posted to your business page.
                    </p>
                  </div>
                </div>
                <div
                  v-if="facebookAuthd"
                  class="oto-info-alert">
                  <div class="icon">
                    <i class="fas fa-exclamation-triangle"/>
                  </div>
                  <span>&nbsp;Instagram and Facebook use the same account to make posts. When you authorize Instagram using the button below your Facebook credentials will be refreshed. After completeing this Instagram authroization please review your Facebook channel settings to assure CampaignPilot is still posting to the proper business page.</span>
                </div>
                <a
                  class="button is-primary is-rounded oto-settings-save-button"
                  @click="authFacebookAndInstagram">
                  <span class="icon is-small">
                    <i class="fab fa-instagram"/>
                  </span>
                  <span>Authorize Instagram</span>
                </a>
                <transition name="fade" mode="out-in">
                  <div
                    v-if="showPopupBlockAlert"
                    class="oto-popup-block-alert">
                    <div class="icon">
                      <i class="fas fa-exclamation-triangle"/>
                    </div>
                    <span>Nothing happened? Check if your browser blocked a pop-up window.</span>
                  </div>
                </transition>
                <div class="instagram-tip">
                  <a
                    href="https://help.campaignpilot.com/channel-setup/configuring-instagram"
                    target="_blank">
                    <div class="icon has-text-primary">
                      <i class="fas fa-question-circle"/>
                    </div>
                    Learn more about configuring Instagram and CampaignPilot.
                  </a>
                </div>
              </div>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!-- WEBSITE -->
            <div
              :class="{'oto-accordian-expanded': currentTab === 4}"
              class="oto-accordian-holder show-hand is-hidden-tablet"
              @click="changeTab(4, true)">
              <div id="tab4">Website</div>
              <div>
                <div v-html="statusTagWebsite" class="status-tag" />
              </div>
            </div>
            <div
              v-if="currentTab === 4"
              class="oto-settings-content-area animated fadeIn">
              <div
                v-if="currentAccount.is_121_foreup_website === 1"
                class="oto-settings-channel-website-foreup">
                <div class="oto-second-heading is-hidden-mobile">foreUP Website Integrations</div>
                <p class="padding-bottom">
                  CampaignPilot automatically integrates with your website powered by foreUP. Adjust your website channel settings below.
                </p>
                <div class="field">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal oto-field-label">
                      <label class="label">Integration Key</label>
                      <div class="placeholder">Use the following key to connect your website to CampaignPilot.</div>
                    </div>
                    <div class="field-body">
                      <div class="field oto-field-vertical">
                        {{ currentAccount.slug }}
                        <span class="icon is-small oto-clickable-icon" @click="copyIntegrationCode">
                          <i class="fas fa-paste"/>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <Toggler
                    :props="foreupWebsitePostsToggle"
                    v-model="foreupWebsitePostsToggle.model"/>
                </div>
                <div class="field">
                  <Toggler
                    :props="foreupWebsiteEventsToggle"
                    v-model="foreupWebsiteEventsToggle.model"/>
                </div>
              </div>
              <div v-else>
                <div class="oto-second-heading is-hidden-mobile">Setting Up The Website Channel</div>
                <p class="padding-bottom">
                  Add <a @click="toggleShowWebsiteCode()">this Javascript snippet</a> to your website source code to complete integration with CampaignPilot.
                </p>
                <div
                  v-if="showWebsiteCode"
                  class="oto-code-holder animated fadeInOut">
                  <pre><code>{{ scriptForBox }}</code></pre>
                  <a
                    class="button is-small"
                    @click="copyScript">
                    <span class="icon is-small">
                      <i class="fas fa-paste"/>
                    </span>
                  </a>
                </div>
              </div>
              <div class="oto-second-heading">Website Widget Settings</div>
              <p
                v-if="currentAccount.is_121_foreup_website === 1"
                class="padding-bottom">
                Customize your website widget with the settings below. There are additional settings found in your foreUP Website Dashboard.
              </p>
              <p
                v-else
                class="padding-bottom">
                Customize your website widget with the settings below. You do not need to update the snippet code on your website to change your settings. Settings will be applied immediately once you click Save Changes.
              </p>
              <Select
                :props="websiteWidget"
                v-model="websiteWidget.model"/>
              <Select
                v-if="websiteWidget.model === 'bar' || websiteWidget.model === 'feed'"
                :props="websiteWidgetPosition"
                v-model="websiteWidgetPosition.model"/>
              <Select
                :props="websiteWidgetFontSize"
                v-model="websiteWidgetFontSize.model"/>
              <Select
                :props="websiteWidgetOpenType"
                v-model="websiteWidgetOpenType.model"/>
              <div v-if="currentAccount.is_121_foreup_website === 1">
                <a
                  href="https://help.campaignpilot.com/app-integrations/foreup-website-integration"
                  target="_blank">
                  <div class="icon has-text-primary">
                    <i class="fas fa-question-circle"/>
                  </div>
                  Learn more about configuring integration with CampaignPilot and your foreUP website.
                </a>
              </div>
              <!-- SAVE BUTTON -->
              <div class="oto-settings-save-button">
                <a
                  class="button is-primary is-rounded"
                  @click="saveWebsite">
                  <span class="icon is-small">
                    <i class="fas fa-save"/>
                  </span>
                  <span>Save Changes</span>
                </a>
              </div>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!-- APP -->
            <div
              :class="{'oto-accordian-expanded': currentTab === 5}"
              class="oto-accordian-holder show-hand is-hidden-tablet"
              @click="changeTab(5, true)">
              <div id="tab5">Mobile App</div>
              <div>
                <div v-html="statusTagMobile" class="status-tag" />
              </div>
            </div>
            <div
              v-if="currentTab === 5"
              class="oto-settings-content-area animated fadeIn">
              <div v-if="appAuthd">
                <div class="oto-second-heading is-hidden-mobile">Mobile App Settings</div>
                <p class="padding-bottom">
                  Your mobile application is configured properly to work with CampaignPilot.
                </p>
              </div>
              <div v-else>
                <div class="oto-second-heading is-hidden-mobile">Setting Up The Mobile App Channel</div>
                <p class="padding-bottom">
                  CampaignPilot can turn your marketing campaigns into push messages that are delivered directly to your mobile app users. Select your mobile app provider and complete the required settings.
                </p>
              </div>
              <Select
                :props="appProvider"
                v-model="appProvider.model"/>
              <TextInput
                v-if="appProvider.model === 'gallus'"
                :props="appKey"
                v-model="appKey.model"/>
              <Select
                v-if="appProvider.model === 'gallus' && hasGallusApps"
                :props="appList"
                v-model="appList.model"/>
              <div v-if="appProvider.model === 'gallus'">
                <a
                  href="https://help.campaignpilot.com/how-tos/gallus-golf-offer-locker-integration"
                  target="_blank">
                  <div class="icon has-text-primary">
                    <i class="fas fa-question-circle"/>
                  </div>
                  Learn more about how CampaignPilot works with your Gallus Golf app as a distribution channel and for Offer Locker integration.
                </a>
              </div>
              <!-- SAVE BUTTON -->
              <div class="oto-settings-save-button">
                <a
                  class="button is-primary is-rounded"
                  @click="saveGallus">
                  <span class="icon is-small">
                    <i class="fas fa-save"/>
                  </span>
                  <span>Save Changes</span>
                </a>
              </div>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!-- EMAIL -->
            <div
              :class="{'oto-accordian-expanded': currentTab === 6}"
              class="oto-accordian-holder show-hand is-hidden-tablet"
              @click="changeTab(6, true)">
              <div id="tab6">Email</div>
              <div>
                <div v-html="statusTagEmail" class="status-tag" />
              </div>
            </div>
            <EmailChannel
              v-if="currentTab === 6"
              v-model="channelSettingsEmail"
              @refresh="refreshChannels(true, true, true)"
              class="oto-settings-content-area animated fadeIn" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Select from '@/components/elements/Select'
import TextInput from '@/components/elements/TextInput'
import Toggler from '@/components/elements/Toggler'
import oauthApi from '@/plugins/oauth-api.js'
import engineApi from '@/plugins/engine-api.js'
import channelApi from '@/plugins/channel-api.js'
import utils from '@/plugins/utils.js'
import xhr from '@/mixins/Xhr.js'
import settings from '@/mixins/Settings.js'
import EmailChannel from '@/components/dashboard/settings/channels/Email'

export default {
  components: {
    Select,
    TextInput,
    Toggler,
    EmailChannel
  },
  mixins: [xhr, settings],
  data () {
    return {
      facebookAuthd: false,
      facebookLoading: false,
      facebookPage: {},
      facebookPages: [],
      facebookWarning: false,
      otherFacebookPages: [],
      otherFacebookToken: null,
      googleAccount: null,
      googleAccounts: [],
      googleAuthd: false,
      googleLoading: false,
      googleLocation: null,
      googleLocations: [],
      googleWarning: false,
      otherGoogleLocations: [],
      otherGoogleToken: null,
      instagramAuthd: false,
      instagramLoading: false,
      instagramPage: {},
      instagramPages: [],
      instagramWarning: false,
      otherInstagramPages: [],
      otherInstagramToken: null,
      showWebsiteCode: false,
      triggerAsyncComputed: false,
      twitterAuthd: false,
      twitterHandle: null,
      channelSettingsEmail: {},
      appProvider: {
        model: null,
        name: 'appProvider',
        ref: 'appProvider',
        options: [{ 'name': 'Select a Provider', 'value': null }, { 'name': 'Gallus Golf', 'value': 'gallus' }],
        label: 'Mobile App Provider'
      },
      appKey: {
        model: null,
        name: 'appKey',
        ref: 'appKey',
        label: 'Gallus Golf API key',
        placeholder: '12345678-ABCD-EFGH-IJKL-012345678901',
        fieldClass: 'oto-input-field-text-medium'
      },
      appList: {
        model: null,
        name: 'appList',
        ref: 'appList',
        options: [],
        label: 'Push Notifications To This App'
      },
      foreupWebsitePostsToggle: {
        model: false,
        name: 'foreupWebsitePostsToggle-toggle',
        ref: 'foreupWebsitePostsToggle-toggle',
        label: 'Website Posts',
        mobileHideLabel: true,
        placeholder: 'Campaigns created in CampaignPilot also create a post on your website.',
        options: { checked: 'Enabled', unchecked: 'Disabled' },
        height: 30,
        width: 120
      },
      foreupWebsiteEventsToggle: {
        model: false,
        name: 'foreupWebsiteEventsToggle-toggle',
        ref: 'foreupWebsiteEventsToggle-toggle',
        label: 'Event Calendar',
        mobileHideLabel: true,
        placeholder: 'Event campaigns will show up on your website calendar.',
        options: { checked: 'Enabled', unchecked: 'Disabled' },
        height: 30,
        width: 120
      },
      websiteWidget: {
        model: null,
        name: 'websiteWidget',
        ref: 'websiteWidget',
        options: [
          { 'name': 'Select a Widget', 'value': null },
          { 'name': 'Campaign Feed', 'value': 'feed' },
          { 'name': 'Popup Overlay', 'value': 'popup' },
          { 'name': 'Message Bar', 'value': 'bar' }
        ],
        label: 'Widget Type'
      },
      websiteWidgetFontSize: {
        model: 'medium',
        name: 'websiteWidgetFontSize',
        ref: 'websiteWidgetFontSize',
        options: [
          { 'name': 'Small', 'value': 'small' },
          { 'name': 'Medium', 'value': 'medium' },
          { 'name': 'Large', 'value': 'large' }
        ],
        label: 'Font Size'
      },
      websiteWidgetPosition: {
        model: 'top',
        name: 'websiteWidgetPosition',
        ref: 'websiteWidgetPosition',
        options: [],
        label: 'Position'
      },
      websiteWidgetOpenType: {
        model: 'tab',
        name: 'websiteWidgetOpenType',
        ref: 'websiteWidgetOpenType',
        options: [
          { 'name': 'New Tab', 'value': 'tab' },
          { 'name': 'Same Window', 'value': 'same' }
        ],
        label: 'Open Links In'
      },
      websiteScript: `  (function (w,d,s,o,f,js,fjs) {
    w['cp-widget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
    js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
    js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
  }(window, document, 'script', 'cp', 'https://campaignpilot.com/plugins/campaignpilot.js'));`
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentAccountChannels: 'currentAccountChannels',
      currentUser: 'currentUser',
      currentLoading: 'currentLoading'
    }),
    scriptForBox: function () {
      if (this.currentAccount.slug) {
        var script = `<REPLACEME>
${this.websiteScript}
  cp('init', {id: '${this.currentAccount.slug}'});
</REPLACEME>`
        return script.replace(/REPLACEME/g, 'script') // workaround for lint and fact vue doesn't like <script> tags
      } else return null
    },
    aggregateLandingPageURL: function () {
      var url = 'https://golf.campaignpilot.com/h/'
      if (this.currentAccount) {
        if (this.currentAccount.slug) {
          url += this.currentAccount.slug
        }
      }
      return url
    },
    hasGallusApps: function () {
      if (this.appList) {
        if (this.appList.options) {
          if (this.appList.options.length > 1) return true
        }
      }
      return false
    },
    gallusAppName: function () {
      if (this.appList) {
        if (this.appList.options) {
          if (this.appList.options.length > 1) {
            if (this.appList.model) {
              var vm = this
              var result = vm.appList.options.filter(obj => {
                return obj.value === vm.appList.model
              })
              if (result) {
                if (result.length === 1) {
                  return result[0].name
                }
              }
              return null
            }
          }
        }
      }
      return null
    },
    appAuthd: function () {
      if (this.appProvider && this.appKey) if (this.appProvider.model && this.appKey.model) return true
      return false
    },
    statusTagFacebook: function () {
      if (this.facebookWarning) return '<span class="tag is-warning">Issue</span>'
      else if (this.facebookAuthd) return '<span class="tag is-enabled">Connected</span>'
      return '<span class="tag is-disabled">Not Set Up</span>'
    },
    statusTagTwitter: function () {
      if (this.twitterAuthd) return '<span class="tag is-enabled">Connected</span>'
      return '<span class="tag is-disabled">Not Set Up</span>'
    },
    statusTagGoogle: function () {
      if (this.googleWarning) return '<span class="tag is-warning">Issue</span>'
      else if (this.googleAuthd) return '<span class="tag is-enabled">Connected</span>'
      return '<span class="tag is-disabled">Not Set Up</span>'
    },
    statusTagInstagram: function () {
      if (this.instagramWarning) return '<span class="tag is-warning">Issue</span>'
      else if (this.instagramAuthd) return '<span class="tag is-enabled">Connected</span>'
      return '<span class="tag is-disabled">Not Set Up</span>'
    },
    statusTagWebsite: function () {
      if (this.websiteWidget) if (this.websiteWidget.model) return '<span class="tag is-enabled">Connected</span>'
      return '<span class="tag is-disabled">Not Set Up</span>'
    },
    statusTagMobile: function () {
      if (this.appAuthd) return '<span class="tag is-enabled">Connected</span>'
      return '<span class="tag is-disabled">Not Set Up</span>'
    },
    statusTagEmail: function () {
      if (this.channelSettingsEmail.active === 1) return '<span class="tag is-enabled">Connected</span>'
      return '<span class="tag is-disabled">Not Set Up</span>'
    }
  },
  asyncComputed: {
    otherFacebook: {
      get () {
        return this.getOtherAuths('facebook')
      },
      watch: ['triggerAsyncComputed']
    },
    otherGoogle: {
      get () {
        return this.getOtherAuths('google')
      },
      watch: ['triggerAsyncComputed']
    },
    otherInstagram: {
      get () {
        return this.getOtherAuths('instagram')
      },
      watch: ['triggerAsyncComputed']
    }
  },
  watch: {
    'websiteWidget.model' (val) {
      if (val === 'feed') this.websiteWidgetPosition.options = [ { 'name': 'Left', 'value': 'left' }, { 'name': 'Right', 'value': 'right' } ]
      if (val === 'bar') this.websiteWidgetPosition.options = [ { 'name': 'Top', 'value': 'top' }, { 'name': 'Bottom', 'value': 'bottom' } ]
    }
  },
  mounted () {
    this.refreshChannels()
    if (this.$route.query.error) {
      // there was a remote error with oauth
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      if (this.$route.query.error === 'google') this.$store.dispatch('setNotify', { state: true, color: 'danger', text: 'There was an issue authorizing your Google My Business account. You may need to remove access by visiting https://myaccount.google.com/permissions.', url: 'https://myaccount.google.com/permissions' })
    }
    // Kickoff the Tour
    if (this.guideMode && !this.$route.query.fromoauth) this.TourSettingsChannels({ showButtons: true, hideDone: false })
  },
  methods: {
    setDefaults: function () {
      this.appKey.model = null
      this.appList.model = null
      this.appProvider.model = null
      this.facebookAuthd = false
      this.facebookLoading = false
      this.facebookPage = {}
      this.facebookPages = []
      this.facebookWarning = false
      this.otherFacebookPages = []
      this.otherFacebookToken = null
      this.googleAccount = null
      this.googleAccounts = []
      this.googleAuthd = false
      this.googleLoading = false
      this.googleLocation = null
      this.googleLocations = []
      this.googleWarning = false
      this.otherGoogleLocations = []
      this.otherGoogleToken = null
      this.instagramAuthd = false
      this.instagramLoading = false
      this.instagramPage = {}
      this.instagramPages = []
      this.instagramWarning = false
      this.otherInstagramPages = []
      this.otherInstagramToken = null
      this.showWebsiteCode = false
      this.twitterAuthd = false
      this.twitterHandle = null
      this.websiteWidget.model = null
      this.websiteWidgetFontSize.model = null
      this.websiteWidgetOpenType.model = null
      this.websiteWidgetPosition.model = null
      this.foreupWebsitePostsToggle.model = null
      this.foreupWebsitePostsToggle.model = null
    },
    refreshChannels: function (skipFacebookPush, skipGooglePush, skipInstagramPush) {
      var vm = this
      vm.channelSettingsEmail = {}
      vm.$store.dispatch('fetchChannels', vm.currentAccount.id).then(response => {
        if (vm.currentAccountChannels.length > 0) {
          vm.currentAccountChannels.forEach(function (channel) {
            if (channel.type === 'facebook') {
              if (!skipFacebookPush) vm.facebookPages.push(channel)
              vm.facebookAuthd = true
              if (channel['can_post'] === 0 || channel['can_insight'] === 0) vm.facebookWarning = true
              if (channel.active === 1) {
                vm.facebookPage = vm.$lodash.cloneDeep(channel)
              }
            } else if (channel.type === 'twitter' && channel.active === 1) {
              vm.twitterHandle = channel.name
              vm.twitterAuthd = true
            } else if (channel.type === 'email') {
              vm.channelSettingsEmail = vm.$lodash.cloneDeep(channel)
            } else if (channel.type === 'website') {
              vm.websiteWidget.model = channel.name
              if (channel.options) {
                var opts = channel.options
                if (typeof opts === 'string') {
                  opts = JSON.parse(opts)
                }
                if (opts) {
                  if (opts.size) {
                    vm.websiteWidgetFontSize.model = opts.size
                  }
                  if (opts.position) {
                    vm.websiteWidgetPosition.model = opts.position
                  }
                  if (opts.open) {
                    vm.websiteWidgetOpenType.model = opts.open
                  }
                  if (opts.posts) {
                    vm.foreupWebsitePostsToggle.model = opts.posts
                  }
                  if (opts.events) {
                    vm.foreupWebsiteEventsToggle.model = opts.events
                  }
                }
              }
            } else if (channel.type === 'app' && channel.active === 1) {
              vm.appProvider.model = channel.id
              if (channel.id === 'gallus') {
                vm.appKey.model = channel['access_token']
                if (channel.name && channel['token_secret']) {
                  vm.appList.options = [{ 'name': 'Select an App', 'value': null }, { 'name': channel.name, 'value': channel['token_secret'] }]
                  vm.appList.model = channel['token_secret']
                }
              }
            } else if (channel.type === 'google') {
              if (channel.active === 1) {
                if (channel['can_post'] === 0 || channel['can_insight'] === 0) vm.googleWarning = true
                vm.googleLocation = {
                  name: channel.id,
                  locationName: channel.name
                }
              }
              if (!skipGooglePush) vm.googleAccounts.push(channel)
              vm.googleAuthd = true
            } else if (channel.type === 'instagram') {
              if (!skipInstagramPush) vm.instagramPages.push(channel)
              vm.instagramAuthd = true
              if (channel['can_post'] === 0 || channel['can_insight'] === 0) vm.instagramWarning = true
              if (channel.active === 1) {
                vm.instagramPage = vm.$lodash.cloneDeep(channel)
              }
            }
          })
        }
      })
    },
    resetChannel: function (type) {
      var vm = this
      vm.$store.dispatch('setLoadingState', true)
      vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
      engineApi.deleteChannel(vm.currentAccount.id, type, function () {
        vm.$store.dispatch('setLoadingState', false)
        vm.$store.dispatch('setNotify', { state: true, text: 'Your channel settings have been reset.' })
        vm.setDefaults()
        vm.triggerAsyncComputed = !vm.triggerAsyncComputed // this forces a refresh of the "other" credentials
        vm.refreshChannels() // setdefaults() above clears facebook & google pages, we need to push them in again
      })
      // probably do this: https://developers.google.com/identity/protocols/OAuth2WebServer#tokenrevoke
    },
    authFacebookAndInstagram: function () {
      this.showPopupBlockerAlert() // show the popup alert
      var vm = this
      // flush facebook & instagram credentials
      engineApi.deleteChannel(vm.currentAccount.id, 'facebook', function (err) {
        if (err) {
          vm.handleXhrError(err)
        } else {
          engineApi.deleteChannel(vm.currentAccount.id, 'instagram', function (err) {
            if (err) {
              vm.handleXhrError(err)
            } else {
              oauthApi.authFacebook(vm.currentAccount.id,
                function (err, provider) {
                  if (err) {
                    vm.handleXhrError(err)
                  } else {
                    vm.showPopup(provider)
                  }
                }) // MARK: end oauthApi.authFacebook
            }
          })
        }
      })
      // reset the otherGoogleToken just incase this workflow was started
      vm.otherFacebookToken = null
      vm.otherInstagramToken = null
    },
    authTwitter: function () {
      this.showPopupBlockerAlert() // show the popup alert
      var vm = this
      // flush twitter credentials first
      engineApi.deleteChannel(vm.currentAccount.id, 'twitter', function (err) {
        if (err) {
          vm.handleXhrError(err)
        } else {
          oauthApi.authTwitter(vm.currentAccount.id,
            function (err, provider) {
              if (err) {
                vm.handleXhrError(err)
              } else {
                vm.showPopup(provider)
              }
            })
        }
      })
    },
    authGoogle: function () {
      this.showPopupBlockerAlert() // show the popup alert
      var vm = this
      // flush google credentials first
      engineApi.deleteChannel(vm.currentAccount.id, 'google', function (err) {
        if (err) {
          vm.handleXhrError(err)
        } else {
          oauthApi.authGoogle(vm.currentAccount.id,
            function (err, provider) {
              if (err) {
                vm.handleXhrError(err)
              } else {
                vm.showPopup(provider)
              }
            })
        }
      })
      // rest the otherGoogleToken just incase this workflow was started
      vm.otherGoogleToken = null
    },
    saveWebsite: function () {
      if (this.websiteWidget.model) {
        var opts = {
          size: this.websiteWidgetFontSize.model,
          position: this.websiteWidgetPosition.model,
          open: this.websiteWidgetOpenType.model,
          posts: this.foreupWebsitePostsToggle.model,
          events: this.foreupWebsiteEventsToggle.model,
          website: this.currentAccount.website,
          is_121_foreup_website: this.currentAccount.is_121_foreup_website
        }
        this.$store.dispatch('setLoadingState', true)
        this.$scrollTo('#dashboard', 200, { easing: 'ease' })
        var vm = this
        engineApi.saveChannel(
          vm.currentAccount.id, 'website', 'widget', vm.websiteWidget.model, null, null, opts, 1,
          function (err) {
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            } else {
              vm.refreshChannels(true, true, true)
              vm.$store.dispatch('setNotify', { state: true, text: 'Your website channel settings have been updated.' })
            }
          }
        )
      }
    },
    saveGallus: function () {
      var vm = this
      if (vm.appProvider.model) {
        if (vm.hasGallusApps) {
          vm.$store.dispatch('setLoadingState', true)
          vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
          engineApi.saveChannel(
            vm.currentAccount.id, 'app', 'gallus', vm.gallusAppName, vm.appKey.model, vm.appList.model, null, 1,
            function (err) {
              vm.$store.dispatch('setLoadingState', false)
              if (err) {
                vm.handleXhrError(err)
              } else {
                vm.refreshChannels(true, true, true)
                vm.$store.dispatch('setNotify', { state: true, text: 'Your app channel settings have been updated.' })
              }
            }
          )
        } else {
          // we don't have a list of gallus apps for the client yet
          // let's pull those apps from their api
          vm.getGallusApps()
        }
      } else {
        // disable gallus
        vm.$store.dispatch('setLoadingState', true)
        vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
        engineApi.saveChannel(
          vm.currentAccount.id, 'app', 'gallus', vm.gallusAppName, vm.appKey.model, vm.appList.model, null, 0,
          function (err) {
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            } else {
              vm.refreshChannels(true, true, true)
              vm.$store.dispatch('setNotify', { state: true, text: 'Your app channel settings have been updated.' })
            }
          }
        )
      }
    },
    saveOtherFacebookPage: function (page) {
      this.$store.dispatch('setLoadingState', true)
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      var vm = this
      engineApi.saveChannel(
        vm.currentAccount.id, 'facebook', page.id, page.name, vm.otherFacebookToken, page.access_token, null, 1,
        function (err) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.refreshChannels(false, true, true)
          }
        }
      )
    },
    saveOtherInstagramPage: function (page) {
      this.$store.dispatch('setLoadingState', true)
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      var vm = this
      engineApi.saveChannel(
        vm.currentAccount.id, 'instagram', page.id, page.name, vm.otherInstagramToken, page.access_token, null, 1,
        function (err) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.refreshChannels(true, true, false)
          }
        }
      )
    },
    selectFacebookPage: function (event) {
      var page = parseInt(event.currentTarget.id.replace('fb-page-', ''))
      this.facebookPage = this.$lodash.cloneDeep(this.facebookPages[page])
      this.facebookPage.active = 1
      this.$store.dispatch('setLoadingState', true)
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      var vm = this
      engineApi.saveChannel(
        vm.currentAccount.id, 'facebook', vm.facebookPage.id, vm.facebookPage.name, null, null, null, vm.facebookPage.active,
        function (err) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.refreshChannels(true, true, true)
          }
        }
      )
    },
    selectInstagramPage: function (event) {
      var page = parseInt(event.currentTarget.id.replace('ig-page-', ''))
      this.instagramPage = this.$lodash.cloneDeep(this.instagramPages[page])
      this.instagramPage.active = 1
      this.$store.dispatch('setLoadingState', true)
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      var vm = this
      engineApi.saveChannel(
        vm.currentAccount.id, 'instagram', vm.instagramPage.id, vm.instagramPage.name, null, null, null, vm.instagramPage.active,
        function (err) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.refreshChannels(true, true, true)
          }
        }
      )
    },
    selectGoogleAccount: function (i) {
      this.googleAccount = this.$lodash.cloneDeep(this.googleAccounts[i])
      this.getGoogleLocations(this.currentAccount.id, this.googleAccount.id)
    },
    selectOtherGoogleAccount: function (i) {
      this.googleAccount = {
        access_token: null,
        active: 0,
        id: this.otherGoogle[i].channelId,
        name: this.otherGoogle[i].channelName,
        token_secret: null,
        type: 'google'
      }
      this.getGoogleLocations(this.otherGoogle[i].accountId, this.googleAccount.id)
    },
    selectGoogleLocation: function (i, otherMode) {
      this.googleLocation = this.$lodash.cloneDeep(this.googleLocations[i])
      this.$store.dispatch('setLoadingState', true)
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      if (!otherMode) this.otherGoogleToken = null // token already set if normal mode ("not other mode")
      var vm = this
      engineApi.saveChannel(
        vm.currentAccount.id, 'google', vm.googleAccount.id, vm.googleLocation.locationName, vm.googleLocation.name, vm.otherGoogleToken, null, 1,
        function (err) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.refreshChannels(true, true, true)
            vm.$store.dispatch('setNotify', { state: true, text: 'Your Google My Business channel has been configured.' })
          }
        }
      )
    },
    getFacebookPages: function (cpAccountId, channelId) {
      var vm = this
      vm.facebookLoading = true
      channelApi.fetchFacebookPages(cpAccountId, channelId, function (err, res) {
        vm.facebookLoading = false
        if (err) {
          vm.handleXhrError(err)
          vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
          vm.$store.dispatch('setNotify', { state: true, color: 'danger', text: 'There was an issue copying your Facebook credentials. Please contact support for more help.' })
        } else {
          if (res) {
            vm.otherFacebookPages = res.pages
            vm.otherFacebookToken = res.accessToken
          }
        }
      })
    },
    getInstagramPages: function (cpAccountId, channelId) {
      var vm = this
      vm.instagramLoading = true
      channelApi.fetchInstagramPages(cpAccountId, channelId, function (err, res) {
        vm.instagramLoading = false
        if (err) {
          vm.handleXhrError(err)
          vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
          vm.$store.dispatch('setNotify', { state: true, color: 'danger', text: 'There was an issue copying your Instagram credentials. Please contact support for more help.' })
        } else {
          if (res) {
            vm.otherInstagramPages = res.pages
            vm.otherInstagramToken = res.accessToken
          }
        }
      })
    },
    getGallusApps: function () {
      var vm = this
      channelApi.fetchGallusApps(vm.appKey.model, function (err, res) {
        if (err) {
          vm.handleXhrError(err)
          if (err.response) {
            if (err.response.status === 500) {
              const field = vm.$validator.fields.find({ name: 'appKey', scope: vm.$options.scope })
              vm.$validator.errors.add({
                id: field.id,
                field: 'appKey',
                msg: 'There was a problem setting up your app, check the api key is correct and try again.',
                scope: vm.$options.scope
              })
              field.setFlags({
                invalid: true,
                valid: false,
                validated: true
              })
            }
          }
        } else {
          if (res) {
            res = JSON.parse(res)
            if (res.length > 0) {
              vm.$validator.errors.clear()
              vm.appList.options = [{ 'name': 'Select an App', 'value': null }]
              res.forEach(function (app, i) {
                vm.appList.options.push({ 'name': app.name, 'value': app.id })
                if (i === 0) vm.appList.model = app.id // this auto-sets the push app to the first one
              })
              vm.saveGallus()
            }
          }
        }
      })
    },
    getGoogleLocations: function (cpAccountId, googleAccountId) {
      var vm = this
      vm.googleLoading = true
      channelApi.fetchGoogleLocations(cpAccountId, googleAccountId, function (err, res) {
        vm.googleLoading = false
        if (err) {
          vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
          vm.$store.dispatch('setNotify', { state: true, color: 'danger', text: 'There was an issue fetching your Google My Business locations. Please contact support for more help.' })
        } else {
          if (res) {
            vm.googleLocations = res.locations
            vm.otherGoogleToken = res.accessToken
          }
        }
      })
    },
    getOtherAuths: function (type) {
      var vm = this
      return new Promise(function (resolve, reject) {
        engineApi.fetchChannelsUser(type, function (err, channels) {
          if (err) {
            vm.handleXhrError(err)
            resolve([])
          } else {
            resolve(channels)
          }
        })
      })
    },
    goBackFromOtherGoogleLocations: function () {
      this.googleLocations = []
    },
    goBackFromOtherFacebookPages: function () {
      this.otherFacebookPages = []
    },
    goBackFromOtherInstagramPages: function () {
      this.otherInstagramPages = []
    },
    showPopup: function (options) {
      for (var paramKey in options.params) {
        options.url += paramKey + '=' + options.params[paramKey] + '&'
      }
      var popup = window.open(options.url, options.title, options.size)
      popup.focus()
      var timer = setInterval(function () {
        if (popup.closed) {
          clearInterval(timer)
          // popup closed
        }
      }, 1000)
    },
    toggleShowWebsiteCode () {
      this.showWebsiteCode = !this.showWebsiteCode
    },
    copyScript: function () {
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      var vm = this
      utils.copyToClipboard(vm.scriptForBox, function (err, copied) {
        if (err) {}
        if (copied) {
          vm.$store.dispatch('setNotify', { state: true, text: 'The widget script has been copied to your clipboard. Add this snippet to your website source code.' })
        }
      })
    },
    copyIntegrationCode: function () {
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      var vm = this
      utils.copyToClipboard(vm.currentAccount.slug, function (err, copied) {
        if (err) {}
        if (copied) {
          vm.$store.dispatch('setNotify', { state: true, text: 'Your account integration key has been copied to your clipboard. Paste this into your website to complete setup.' })
        }
      })
    },
    save: function (returnPath) {
      // we need this function for the wizard, since this tab doesn't really have a save button
      // we're creating a save function to play nice with the mixin and it just sends the user back to the wizard
      if (returnPath) {
        this.$router.push({ name: returnPath })
      }
    },
    masterGoogle: function () {
      var vm = this
      vm.$store.dispatch('setLoadingState', true)
      engineApi.masterGoogle(vm.currentAccount.id, function () {
        vm.$store.dispatch('setLoadingState', false)
        vm.refreshChannels(true, false, true)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: disc!important;
  margin: 20px 0 20px 40px!important;
}
.facebook-pages {
  padding-bottom: 15px;
}
.facebook-pages > .panel-block > span {
  padding-right: 8px;
}
.facebook-pages > a:hover {
  color: $primary!important;
}
.control > .dropdown > .dropdown-trigger {
  white-space: nowrap;
  display: flex;
  line-height: 23px;
  margin: 10px 0 20px 0;
}
.dropdown > .dropdown-click-helper {
  width: 220px;
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 10;
  height: 25px;
  cursor: hand; cursor: pointer;
}
.dropdown > .dropdown-click-helper:hover ~ .dropdown-trigger > div,
.dropdown > .dropdown-click-helper:hover ~ .dropdown-trigger > span {
  color: #00A964!important;
}
.dropdown > .dropdown-trigger > div {
  color: $primary;
  font-size: 1em!important;
}
.oto-code-holder {
  padding-top: 0!important;
}
.message-body {
  padding: 1.25em 1.5em 0 1.25em;
}
.message-body > nav > a.panel-block {
  text-decoration: none!important;
  padding: 0.25em 0.5em!important;
  border: none!important;
}
.instagram-tip {
  padding-top: 20px;
}
.input-footer-text {
  font-size: 0.9rem;
  margin-top: -10px;
}
</style>

<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title">
            Your Profile
          </h1>
          <h2 class="subtitle">
            Manage your user profile.
          </h2>
          <div class="tabs">
            <ul>
              <li :class="{ 'is-active': currentTab === 'details' }">
                <a
                  @click="setTab('details')">
                  Details
                </a>
              </li>
              <li :class="{ 'is-active': currentTab === 'password' }">
                <a
                  @click="setTab('password')">
                  Password
                </a>
              </li>
            </ul>
          </div>
          <Details v-if="currentTab === 'details'"/>
          <Password v-else-if="currentTab === 'password'"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Details from '@/components/dashboard/profile/Details.vue'
import Password from '@/components/dashboard/profile/Password.vue'

export default {
  components: {
    Details,
    Password
  },
  data () {
    return {
      currentTab: 'details'
    }
  },
  mounted () {

  },
  methods: {
    setTab (tab) {
      this.currentTab = tab
    }
  }
}
</script>

<style scoped>
.tabs {
  overflow-x: hidden!important;
}
</style>

<template>
  <div class="banner-holder">
    <div
      v-if="loaded"
      class="banner">
      <div
        v-html="html"
        :style="{ 'background': 'url(' + image + ')', 'backgroundSize': 'cover', 'backgroundPosition': 'bottom', 'backgroundRepeat': 'no-repeat' }" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'

export default {
  mixins: [xhr],
  props: {},
  data () {
    return {
      loaded: false,
      html: null,
      image: null
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    })
  },
  mounted () {
    this.fetchBanners()
  },
  methods: {
    fetchBanners () {
      var vm = this
      engineApi.fetchBanners(vm.currentAccount.id,
        function (err, banners) {
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (banners.length > 0) {
              vm.html = banners[0].html
              vm.image = banners[0].image
              vm.loaded = true
            }
          }
        }
      )
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
.banner-holder {
  height: 100%;
}
.banner {
  color: white;
  height: 100%;
  min-height: 350px;
  position: relative;
}
.banner div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
</style>

<template>
  <div>
    <div class="oto-second-heading is-hidden-mobile">Customize Your Logo</div>
    <p class="padding-bottom">Your logo will be added to marketing content and landing pages automatically. It is recommended to upload both a wide and square format logo.</p>
    <div
      v-if="currentAccount.logoWide || currentAccount.logoSquare"
      class="logos-holder">
      <div v-if="currentAccount.logoWide">
        <p class="tip">Wide Format</p>
        <div
          id="logo-wide"
          class="oto-logo-wide">
          <img :src="currentAccount.logoWide">
        </div>
      </div>
      <div v-if="currentAccount.logoSquare">
        <p class="tip">Square Format</p>
        <div
          id="logo-square"
          class="oto-logo-square">
          <img :src="currentAccount.logoSquare">
        </div>
      </div>
    </div>
    <div id="settings-media-logo-add-button">
      <a
        class="button is-primary is-rounded oto-settings-save-button"
        @click="openImageUploader">
        <span v-if="currentAccount.logoWide || currentAccount.logoSquare">
          <span class="icon is-small">
            <i class="fas fa-plus"/>
          </span>
          <span>Change Logo</span>
        </span>
        <span v-else>
          <span class="icon is-small">
            <i class="fas fa-plus"/>
          </span>
          <span>Add Logo</span>
        </span>
      </a>
    </div>
    <ImageUploader
      :iu="iu"
      v-model="uploadedImage"/>
  </div>
</template>

<script>
import engineApi from '@/plugins/engine-api.js'
import { mapGetters } from 'vuex'
import ImageUploader from '@/components/elements/ImageUploader'
import xhr from '@/mixins/Xhr.js'

export default {
  components: { ImageUploader },
  mixins: [xhr],
  data () {
    return {
      iu: {
        mode: 'logo',
        modalOpen: false,
        allowedRatios: ['wide logo', 'square logo'],
        defaultRatio: 'wide logo',
        format: 'image/png',
        compress: 0.99
      },
      modalOpen: false,
      uploadedImage: null
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    })
  },
  watch: {
    'uploadedImage.url' (val) {
      if (val) {
        this.uploadImage(val)
      }
    }
  },
  methods: {
    openImageUploader () {
      this.iu.modalOpen = true
      if (this.guideMode) {
        this.TourClose()
      }
    },
    uploadImage (url) {
      this.image = {}
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.uploadImage(
        vm.currentAccount.id, url, 'logo', vm.uploadedImage.type,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          }
          vm.$store.dispatch('setAccount', vm.currentAccount.id) // refresh vuex account
        }
      )
    },
    save: function (returnPath) {
      // we need this function for the wizard, since this tab doesn't really have a save button
      // we're creating a save function to play nice with the mixin and it just sends the user back to the wizard
      if (returnPath) {
        this.$router.push({ name: returnPath })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logos-holder > div {
  margin-bottom: 20px;
}
.tip {
  font-size: 0.8em;
  font-weight: 600;
}
</style>

<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column oto-max-width-fix">
          <h1 class="title">
            Campaign Results
          </h1>
          <h2 class="subtitle">
            {{ details.title }}
          </h2>
          <div
            v-if="details.title"
            class="columns is-multiline">
            <div
              v-for="(stat, index) in stats"
              :key="'s' + index"
              class="column is-full-mobile is-one-half-tablet">
              <div class="oto-campaign-card with-border">
                <div class="oto-campaign-card-content">
                  <div class="content is-marginless">
                    <p class="card-header-large is-centered is-paddingless is-marginless">
                      <span v-if="stat.name === 'impressions'">
                        <animated-number
                          :value="details.impressions"
                          :formatValue="numberFormat"
                          :duration="3000"/>
                      </span>
                      <span v-else-if="stat.name === 'clicks'">
                        <animated-number
                          :value="details.clicks"
                          :formatValue="numberFormat"
                          :duration="3000"/>
                      </span>
                      <span v-else-if="stat.name === 'submissions'">
                        <animated-number
                          :value="details.submissions"
                          :formatValue="numberFormat"
                          :duration="3000"/>
                      </span>
                      <span v-else-if="stat.name === 'conversions'">
                        <animated-number
                          :value="details.conversions"
                          :formatValue="numberFormat"
                          :duration="3000"/>
                      </span>
                      <span v-else-if="stat.name === 'revenue'">
                        <animated-number
                          :value="details['conversion_total']"
                          :formatValue="priceFormat"
                          :duration="3000"/>
                      </span>
                    </p>
                    <p class="card-header-title is-centered is-paddingless is-marginless has-text-primary">{{ stat.label }}</p>
                  </div>
                </div>
                <footer class="card-footer">
                  <p class="oto-campaign-card-footer-item">
                    {{ stat.details }}
                  </p>
                </footer>
              </div>
            </div>
          </div>
          <div v-if="hasOrders">
            <div class="subtitle-w-bar">Orders</div>
            <vue-good-table
              ref="orders"
              :columns="columnsOrders"
              :rows="orders"
              :line-numbers="true"
              :pagination-options="paginationOrders"
              style-class="vgt-table striped">
              <div slot="table-actions">
                <a
                  class="button campaign-action-button is-rounded"
                  @click="exportCSV('orders')">
                  Export Orders
                </a>
              </div>
            </vue-good-table>
          </div>
          <div v-else-if="hasSubmissions">
            <div class="subtitle-w-bar">Form Submissions</div>
            <vue-good-table
              ref="submissions"
              :columns="columnsSubmissions"
              :rows="rowsSubmissions"
              :line-numbers="true"
              :pagination-options="paginationSubmissions"
              style-class="vgt-table striped">
              <div slot="table-actions">
                <a
                  class="button campaign-action-button is-rounded"
                  @click="exportCSV('submissions')">
                  Export Submissions
                </a>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import engineApi from '@/plugins/engine-api.js'
import { mapGetters } from 'vuex'
import xhr from '@/mixins/Xhr.js'
import FileSaver from 'file-saver'
import { parse } from 'json2csv'
import AnimatedNumber from 'animated-number-vue'

export default {
  components: {
    VueGoodTable,
    AnimatedNumber
  },
  mixins: [xhr],
  data () {
    return {
      details: {
        impressions: 0,
        clicks: 0,
        submissions: 0,
        conversions: 0
      },
      submissions: null,
      orders: null,
      columnsSubmissions: null,
      rowsSubmissions: null,
      stats: [],
      columnsOrders: [],
      paginationSubmissions: {
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'top',
        perPageDropdown: [10, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Submissions per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All'
      },
      paginationOrders: {
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'top',
        perPageDropdown: [10, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Orders per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All'
      }
    }
  },
  computed: {
    hasSubmissions: function () {
      if (this.rowsSubmissions) {
        if (this.rowsSubmissions.length > 0) {
          return true
        }
      }
      return false
    },
    hasOrders: function () {
      if (this.orders) {
        if (this.orders.length > 0) {
          return true
        }
      }
      return false
    },
    ...mapGetters({
      currentAccount: 'currentAccount'
    })
  },
  watch: {
    'details.action' (val) {
      if (val === 'sale') {
        if (this.details['action_subtype'] === 'paid') {
          // paid sale
          if (this.details.impressions > 0) {
            this.stats.push({
              name: 'impressions',
              label: 'Impressions',
              details: 'The number of times your campaign message was seen.'
            })
          }
          this.stats.push({
            name: 'clicks',
            label: 'Clicks',
            details: 'The number of times this campaign landing page was loaded.'
          })
          this.stats.push({
            name: 'conversions',
            label: 'Purchase Orders',
            details: 'The total number of product orders made by your customers.'
          })
          this.stats.push({
            name: 'revenue',
            label: 'Revenue',
            details: 'The total overall revenue generated by this marketing campaign.'
          })
        } else {
          // informational sale
          if (this.details.impressions > 0) {
            this.stats.push({
              name: 'impressions',
              label: 'Impressions',
              details: 'The number of times your campaign message was seen.'
            })
          }
          this.stats.push({
            name: 'clicks',
            label: 'Clicks',
            details: 'The number of times the campaign landing page was loaded.'
          })
          this.stats.push({
            name: 'submissions',
            label: 'Form Submissions',
            details: 'The number of times customers completed your form.'
          })
        }
      } else if (val === 'event') {
        if (this.details['action_subtype'] === 'paid') {
          // paid event
          if (this.details.impressions > 0) {
            this.stats.push({
              name: 'impressions',
              label: 'Impressions',
              details: 'The number of times your campaign message was seen.'
            })
          }
          this.stats.push({
            name: 'clicks',
            label: 'Clicks',
            details: 'The number of times this campaign landing page was loaded.'
          })
          this.stats.push({
            name: 'conversions',
            label: 'Ticket Orders',
            details: 'The total number of ticket orders made by your customers.'
          })
          this.stats.push({
            name: 'revenue',
            label: 'Revenue',
            details: 'The total overall revenue generated by this marketing campaign.'
          })
        } else {
          // informational event
          if (this.details.impressions > 0) {
            this.stats.push({
              name: 'impressions',
              label: 'Impressions',
              details: 'The number of times your campaign message was seen.'
            })
          }
          this.stats.push({
            name: 'clicks',
            label: 'Clicks',
            details: 'The number of times the campaign landing page was loaded.'
          })
          this.stats.push({
            name: 'submissions',
            label: 'Form Submissions',
            details: 'The number of times customers completed your form.'
          })
        }
      } else if (val === 'teetime') {
        if (this.details['action_subtype'] === 'paid') {
          // paid voucher (MAYBE? there's a good chance this combo doesn't exist)
          if (this.details.impressions > 0) {
            this.stats.push({
              name: 'impressions',
              label: 'Impressions',
              details: 'The number of times your campaign message was seen.'
            })
          }
          this.stats.push({
            name: 'clicks',
            label: 'Clicks',
            details: 'The number of times this campaign landing page was loaded.'
          })
          this.stats.push({
            name: 'conversions',
            label: 'Voucher Orders',
            details: 'The total number of voucher orders made by your customers.'
          })
          this.stats.push({
            name: 'revenue',
            label: 'Revenue',
            details: 'The total overall revenue generated by this marketing campaign.'
          })
        } else {
          // informational  or booking engine tee time
          if (this.details.impressions > 0) {
            this.stats.push({
              name: 'impressions',
              label: 'Impressions',
              details: 'The number of times your campaign message was seen.'
            })
          }
          this.stats.push({
            name: 'clicks',
            label: 'Clicks',
            details: 'The number of times the campaign landing page was loaded.'
          })
          this.stats.push({
            name: 'submissions',
            label: 'Form Submissions',
            details: 'The number of times customers completed your form.'
          })
        }
      } else {
        // assume informational or other
        if (this.details.impressions > 0) {
          this.stats.push({
            name: 'impressions',
            label: 'Impressions',
            details: 'The number of times your campaign message was seen.'
          })
        }
        this.stats.push({
          name: 'clicks',
          label: 'Clicks',
          details: 'The number of times the campaign landing page was loaded.'
        })
        this.stats.push({
          name: 'submissions',
          label: 'Form Submissions',
          details: 'The number of times customers completed your form.'
        })
      }
    }
  },
  mounted () {
    this.refreshImpressions()
  },
  methods: {
    refreshImpressions () {
      var vm = this
      vm.$store.dispatch('setLoadingState', true)
      engineApi.refreshImpressions(
        vm.currentAccount.id, vm.$route.params.campaignId,
        function (err, response) {
          if (err) {} // linter fix
          vm.fetchSubmissions()
        }
      )
    },
    fetchSubmissions () {
      var vm = this
      engineApi.fetchSubmissions(
        vm.currentAccount.id, vm.$route.params.campaignId, vm.currentAccount.timezone,
        function (err, response) {
          if (err) {
            vm.$store.dispatch('setLoadingState', false)
            vm.handleXhrError(err)
          } else {
            vm.details = response.details
            vm.orders = response.orders
            if (vm.orders.length > 0) {
              // handle orders with form submissions
              response.submissions.forEach(function (answer) {
                if (answer['order_id']) {
                  if (vm.$lodash.find(vm.orders, { 'id': answer['order_id'] })) {
                    vm.$lodash.find(vm.orders, { 'id': answer['order_id'] })[answer.field] = answer.answer
                  }
                }
              })
              var orderKeys = []
              vm.orders.forEach(function (order) {
                orderKeys = orderKeys.concat(Object.keys(order))
              })
              orderKeys = vm.$lodash.uniq(orderKeys)
              orderKeys.forEach(function (key) {
                if (key !== 'id') {
                  var filterOptions = null
                  var tdClass = null
                  if (key === 'email') {
                    filterOptions = {
                      enabled: true,
                      placeholder: 'Filter by email'
                    }
                  }
                  if (key === 'confirmation_number') {
                    filterOptions = {
                      enabled: true,
                      placeholder: 'Search confirmation'
                    }
                    tdClass = 'font-ptmono'
                  }
                  var fixedLabel = key.replace('_', ' ')
                  fixedLabel = vm.$lodash.startCase(fixedLabel)
                  if (key === 'cp_date') { fixedLabel = 'Date' }
                  vm.columnsOrders.push({
                    label: fixedLabel,
                    field: key,
                    filterOptions: filterOptions,
                    tdClass: tdClass
                  })
                }
              })
            } else {
              // just handle form submissions
              var submissionsById = {}
              response.submissions.forEach(function (row) {
                if (!submissionsById[row.submission_id]) {
                  submissionsById[row.submission_id] = {}
                }
                submissionsById[row.submission_id][row.field] = row.answer
                submissionsById[row.submission_id]['cp_date'] = row.date
              })
              vm.rowsSubmissions = vm.$lodash.values(submissionsById)
              vm.columnsSubmissions = vm.$lodash.map(vm.$lodash.uniqBy(response.submissions, 'field'), function (item) {
                var filterOptions = null
                if (item.field.includes('name')) {
                  filterOptions = {
                    enabled: true,
                    placeholder: 'Filter by name'
                  }
                }
                if (item.field.includes('email')) {
                  filterOptions = {
                    enabled: true,
                    placeholder: 'Filter by email'
                  }
                }
                if (item.field.includes('phone')) {
                  filterOptions = {
                    enabled: true,
                    placeholder: 'Filter by phone number'
                  }
                }
                var fixedLabel = item.field.replace('_', ' ')
                fixedLabel = vm.$lodash.startCase(fixedLabel)
                return {
                  label: fixedLabel,
                  field: item.field,
                  filterOptions: filterOptions
                }
              })

              if (vm.columnsSubmissions) {
                vm.columnsSubmissions.unshift({
                  label: 'Date',
                  field: 'cp_date'
                })
              }
            }
            vm.$store.dispatch('setLoadingState', false)
          }
        }
      )
    },
    exportCSV (mode) {
      if (mode) {
        var csv
        var blob
        if (mode === 'submissions') {
          // this.$refs.submissions.filteredRows[0].children
          csv = parse(this.rowsSubmissions)
          blob = new Blob([csv], { type: 'text/csv' })
          FileSaver.saveAs(blob, this.details.slug + '_submissions.csv')
        } else if (mode === 'orders') {
          var cleanOrders = this.orders
          cleanOrders.forEach(function (order) {
            delete order.id
          })
          csv = parse(cleanOrders)
          blob = new Blob([csv], { type: 'text/csv' })
          FileSaver.saveAs(blob, this.details.slug + '_orders.csv')
        }
      }
    },
    numberFormat (num) {
      if (num) {
        num = parseInt(num)
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return 0
      }
    },
    priceFormat (num) {
      if (num) return `$${num.toFixed(2)}`
      else return 'n/a'
    }
  }
}
</script>

<style scoped>
.oto-max-width-fix {
  max-width: -webkit-fill-available;
}
</style>

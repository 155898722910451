<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title">
            How would you like to start?
          </h1>
          <h2 class="subtitle">
            Build a marketing campaign from scratch by selecting the type of campaign you're running. Otherwise select one of the many templates from our campaign library.
          </h2>
          <div class="columns">
            <div class="column is-half oto-build-my-own">
              <h3 class="title is-5 has-text-centered oto-h3-header">Build My Own Campaign:</h3>
              <div
                id="bmoc-sale"
                class="columns box is-paddingless is-mobile oto-build-my-own show-hand"
                @click="click('sale')">
                <div class="column is-narrow is-paddingless">
                  <figure class="image oto-is-128xcover">
                    <img src="../../../../../assets/imgs/other/campaignpilot-campaign-sale.png">
                  </figure>
                </div>
                <div class="column is-marginless oto-build-my-own-card-description">
                  <h4 class="title is-marginless oto-camp-title">Sale</h4>
                  <p class="oto-desc-size is-size-7-tablet">
                    Sell or promote your products online.<br/>
                    <span class="has-text-weight-bold">Common sale campaigns are Gift Cards, Memberships, and Lesson Packages.</span>
                  </p>
                </div>
                <div
                  v-if="!guideMode"
                  class="column is-narrow oto-is-vertical-center oto-build-own-right-column is-hidden-mobile">
                  <div class="has-text-centered">
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div
                id="bmoc-event"
                class="columns box is-paddingless is-mobile oto-build-my-own show-hand"
                 @click="click('event')">
                <div class="column is-narrow is-paddingless">
                  <figure class="image oto-is-128xcover">
                    <img src="../../../../../assets/imgs/other/campaignpilot-campaign-event.png">
                  </figure>
                </div>
                <div class="column is-marginless oto-build-my-own-card-description">
                  <h4 class="title is-marginless oto-camp-title">Event</h4>
                  <p class="oto-desc-size is-size-7-tablet">
                    Sell tickets to your event or promote it online.<br/>
                    <span class="has-text-weight-bold">Common event campaigns are Tournaments, Outings, and Dining Events.</span>
                  </p>
                </div>
                <div
                  v-if="!guideMode"
                  class="column is-narrow oto-is-vertical-center oto-build-own-right-column is-hidden-mobile">
                  <div class="has-text-centered">
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div
                id="bmoc-golf"
                class="columns box is-paddingless is-mobile oto-build-my-own show-hand"
                 @click="click('golf')">
                <div class="column is-narrow is-paddingless">
                  <figure class="image oto-is-128xcover">
                    <img src="../../../../../assets/imgs/other/campaignpilot-campaign-golf.png">
                  </figure>
                </div>
                <div class="column is-marginless oto-build-my-own-card-description">
                  <h4 class="title is-marginless oto-camp-title">Golf</h4>
                  <p class="oto-desc-size is-size-7-tablet">
                    Sell or promote golf specials.<br/>
                    <span class="has-text-weight-bold">Common golf campaigns are Vouchers, Bundles, and Tee Time Specials.</span>
                  </p>
                </div>
                <div
                  v-if="!guideMode"
                  class="column is-narrow oto-is-vertical-center oto-build-own-right-column is-hidden-mobile">
                  <div class="has-text-centered">
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div
                id="bmoc-other"
                class="columns box is-paddingless is-mobile oto-build-my-own show-hand"
                 @click="click('other')">
                <div class="column is-narrow is-paddingless">
                  <figure class="image oto-is-128xcover">
                    <img src="../../../../../assets/imgs/other/campaignpilot-campaign-other.png">
                  </figure>
                </div>
                <div class="column is-marginless oto-build-my-own-card-description">
                  <h4 class="title is-marginless oto-camp-title">Other Campaigns</h4>
                  <p class="oto-desc-size is-size-7-tablet">
                    Collect customer information or give more details.<br/>
                    <span class="has-text-weight-bold">Common campaigns include Contests, Wedding Inquiries, and Lead Generation.</span>
                  </p>
                </div>
                <div
                  v-if="!guideMode"
                  class="column is-narrow oto-is-vertical-center oto-build-own-right-column is-hidden-mobile">
                  <div class="has-text-centered">
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-half oto-build-my-own">
            <h3 class="title is-5 has-text-centered oto-h3-header">Choose From Campaign Library:</h3>
              <div
                id="campaign-library-section"
                class="columns box is-paddingless is-mobile oto-build-my-own show-hand"
                @click="click('template')">
                <div class="column is-narrow is-paddingless">
                  <figure class="image oto-is-162xcover">
                    <img src="../../../../../assets/imgs/other/campaignpilot-templates-img-lr.jpg">
                  </figure>
                </div>
                <div class="column is-marginless oto-build-my-own-card-description">
                  <div class="content">
                    <h4 class="is-6">Start With A Campaign Template</h4>
                    <p class="oto-desc-size is-size-7-tablet">
                      Need help coming up with a marketing campaign? Our templates provide you with content ideas to get you started.
                    </p>
                  </div>
                </div>
                <div class="column is-narrow oto-is-vertical-center oto-build-own-right-column is-hidden-mobile">
                  <div class="has-text-centered">
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import engineApi from '@/plugins/engine-api.js'
import tour from '@/mixins/Tour.js'
import xhr from '@/mixins/Xhr.js'

export default {
  mixins: [tour, xhr],
  mounted () {
    // reset the current wizard
    this.$store.dispatch('resetWizardState')
    // Kickoff the Tour
    if (this.guideMode) this.TourCampaignStart({ showButtons: true })
  },
  methods: {
    click (a) {
      switch (a) {
        case 'sale':
          if (!this.guideMode) this.fetchCampaign(78)
          break
        case 'event':
          if (!this.guideMode) this.fetchCampaign(79)
          break
        case 'golf':
          if (!this.guideMode) this.fetchCampaign(80)
          break
        case 'other':
          if (!this.guideMode) this.fetchCampaign(81)
          break
        case 'template':
          this.$router.push({ name: 'route-engine-campaigns-campaign-select' })
          break
        default:
          // do nothing, can't handle this click action
      }
    },
    fetchCampaign (id) {
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchCampaign(
        null, id,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            // send google analytics event
            if (process.env.NODE_ENV !== 'development') window.gtag('event', 'campaign_action', { 'action': 'selected', 'campId': id })
            response = JSON.parse(response)
            if (response.campaign) vm.$store.dispatch('saveSection', { name: 'wizardDetails', value: response.campaign })
            if (response.action) vm.$store.dispatch('saveSection', { name: 'wizardAction', value: response.action })
            if (response.theme) vm.$store.dispatch('saveSection', { name: 'wizardTheme', value: response.theme })
            if (response.form) vm.$store.dispatch('setWizardSuggestedForm', response.form)
            vm.$store.dispatch('setWizardStartMode', 'blank')
            vm.$router.push({ name: 'route-engine-campaigns-wizard-campaign-wizard' })
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
h4 {
  padding-bottom: .4rem;
}
.oto-is-vertical-center {
  display: flex;
  align-items: center;
}
.oto-build-own-right-column {
  border-left: 1px rgba(0, 0, 0, 0.1) solid;
}
.oto-build-my-own:hover>.oto-build-own-right-column {
  color: $primary;
  background-color: $white-ter;
}
.oto-h3-header {
  /* letter-spacing: .15rem; */
}
.oto-build-my-own,
.columns:not(:last-child).oto-build-my-own {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 1.5rem
}
.oto-build-my-own-card-description {
  padding: 1.25rem;
}
.oto-is-320x640 {
  height: 360px;
  width: 180px;
}
.oto-is-128xcover {
  height: 100%;
  width: 128px;
}
.oto-is-128xcover img {
  height: 100%;
  object-fit: cover;
}
.oto-is-162xcover {
  height: 100%;
  width: 162px;
}
.oto-is-162xcover img {
  height: 100%;
  object-fit: cover;
}
.oto-desc-size {
  font-size: 16px !important;
}
.oto-camp-title {
  font-size: 18px !important;
}
</style>

import tour from '@/mixins/Tour.js'

export default {
  mixins: [tour],
  data: function () {
    return {
      currentTab: 0,
      showPopupBlockAlert: false
    }
  },
  methods: {
    changeTab (tab, mobileMode) {
      if (this.currentTab === tab && mobileMode) {
        this.currentTab = null
      } else {
        this.currentTab = tab
        if (mobileMode) {
          // scrolls to the expanded section in mobile
          var vm = this
          setTimeout(() => {
            vm.$scrollTo('#tab' + tab, 1000, { easing: 'ease' })
          }, 100)
        }
      }
    },
    showPopupBlockerAlert () {
      // when this function is called, after 4 seconds
      // the showPopupBlockAlert flag will turn on,
      // then off after 8.5 seconds. we use this to show
      // a popup block message after oauth attempts
      var vm = this
      vm.showPopupBlockAlert = false
      setTimeout(() => {
        vm.showPopupBlockAlert = true
        setTimeout(() => {
          vm.showPopupBlockAlert = false
        }, 8500)
      }, 4000)
    }
  }
}

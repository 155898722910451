<template>
  <div
    :class="{ 'is-active': builder.modalOpen }"
    class="modal"
    key="templatebuilder">
    <div class="modal-background"></div>
    <div class="modal-card full-height-card">
      <header class="modal-card-head">
        <div class="modal-card-title tabs is-centered">
          <ul>
            <li :class="{'is-active': activeTab === 1}"><a @click="tab(1)">Campaign Card</a></li>
            <li :class="{'is-active': activeTab === 2}"><a @click="tab(2)">Wizard Details</a></li>
            <li :class="{'is-active': activeTab === 3}"><a @click="tab(3)">Other Settings</a></li>
          </ul>
        </div>
      </header>
      <section class="modal-card-body">
        <div v-if="activeTab === 1">
          <TextInput
            :props="name"
            v-model="name.model"/>
          <IconPick
            label="Icon"
            v-model="icon"/>
          <Select
            :props="type"
            v-model="type.model"/>
          <TextInput
            :props="subtype"
            v-model="subtype.model"/>
          <TextArea
            :props="libraryDescription"
            v-model="libraryDescription.model"/>
          <div class="tag-area">
            <label class="label">Library Tags</label>
            <div class="tags">
              <span
                v-for="(tag, index) in tags"
                class="tag is-medium"
                :key="'t'+index">
                {{ tag }}
                <button
                  class="delete is-small"
                  @click="deleteTag(index)"></button>
              </span>
            </div>
            <vue-autosuggest
              v-model="tagInput"
              :suggestions="autoFilteredOptions"
              :get-suggestion-value="getSuggestionValue"
              :input-props="{id:'autosuggest__input', placeholder:'Enter tags', autocomplete:'off'}"
              @selected="autoSelectHandler"
              @input="autoOnInputChange"
              class="auto-suggest-campaign-wizard">
              <template slot-scope="{suggestion}">
                <span class="oto-auto-suggestion-item">{{ suggestion.item.name }}</span>
              </template>
            </vue-autosuggest>
          </div>
        </div>
        <div v-if="activeTab === 2">
          <TextArea
            :props="detailsText"
            v-model="detailsText.model"/>
          <hr />
          <div
            v-for="(question, index) in questions"
            :key="'q'+index">
            <div class="question-heading has-text-primary has-text-weight-bold is-capitalized is-size-5">{{ question.name }}</div>
            <TextInput
              :props="returnQuestionProp('Label', 'label', question.label, index)"
              v-model="question.label"/>
            <TextInput
              :props="returnQuestionProp('Default Value', 'default', question.default, index)"
              v-model="question.default"/>
            <TextInput
              :props="returnQuestionProp('Placeholder', 'placeholder', question.placeholder, index)"
              v-model="question.placeholder"/>
            <TextInput
              :props="returnQuestionProp('Help Text', 'help', question.help, index)"
              v-model="question.help"/>
            <hr v-if="index < questions.length - 1" />
          </div>
        </div>
        <div v-if="activeTab === 3">
          <Select
            :props="action"
            v-model="action.model"/>
          <TextInput
            :props="theme"
            v-model="theme.model"/>
        </div>
      </section>
      <footer class="modal-card-foot">
        <a
          :class="{'is-loading': isLoading}"
          :disabled="isLoading"
          class="card-footer-item"
          @click="create()">
          Create Template
        </a>
        <a
          :class="{'is-loading': isLoading}"
          :disabled="isLoading"
          class="card-footer-item"
          @click="close()">
          Cancel
        </a>
      </footer>
    </div>
  </div>
</template>

<script>
import IconPick from '@/components/elements/IconPick'
import Select from '@/components/elements/Select'
import TextInput from '@/components/elements/TextInput'
import TextArea from '@/components/elements/TextArea'
import engineApi from '@/plugins/engine-api.js'
import { VueAutosuggest } from 'vue-autosuggest'
import { mapGetters } from 'vuex'
import xhr from '@/mixins/Xhr.js'

export default {
  components: {
    IconPick,
    Select,
    TextInput,
    TextArea,
    VueAutosuggest
  },
  props: {
    builder: {
      type: Object,
      default: null
    }
  },
  mixins: [xhr],
  data () {
    return {
      activeTab: 1,
      isLoading: false,
      name: {
        name: 'campaign-name',
        ref: 'campaign-name',
        label: 'Name in Library',
        model: null
      },
      theme: {
        name: 'campaign-theme',
        ref: 'campaign-theme',
        label: 'Suggested Theme Tag',
        model: null
      },
      subtype: {
        name: 'campaign-subtype',
        ref: 'campaign-subtype',
        label: 'Campaign Subtype',
        model: null
      },
      detailsText: {
        name: 'campaign-details-text',
        ref: 'campaign-details-text',
        label: 'Right Sidebar Text',
        model: null,
        limit: 200,
        rows: 3
      },
      libraryDescription: {
        name: 'campaign-library-description',
        ref: 'campaign-library-description',
        label: 'Description in Library',
        model: null,
        limit: 100,
        rows: 3
      },
      type: {
        model: null,
        name: 'campaign-type',
        ref: 'campaign-type',
        label: 'Campaign Type',
        options: [{ 'name': 'Event', 'value': 'event' }, { 'name': 'Golf', 'value': 'golf' }, { 'name': 'More Info', 'value': 'info' }, { 'name': 'Sale', 'value': 'sale' }, { 'name': 'Tee Times', 'value': 'tee times' }, { 'name': 'Other', 'value': 'other' }]
      },
      action: {
        model: null,
        name: 'campaign-action',
        ref: 'campaign-action',
        label: 'Recommended Action',
        options: [{ 'name': 'Booking Engine', 'value': 'teetime' }, { 'name': 'Event', 'value': 'event' }, { 'name': 'Form', 'value': 'form' }, { 'name': 'More Info', 'value': 'info' }, { 'name': 'Sale', 'value': 'sale' }]
      },
      icon: null,
      questions: [],
      tags: [],
      tagInput: null,
      autoFilteredOptions: [],
      autoSuggestions: [ { data: [] } ]
    }
  },
  computed: {
    ...mapGetters({
      fullWizard: 'fullWizard'
    })
  },
  watch: {
    'builder.modalOpen' (val) {
      if (val) this.open()
    }
  },
  mounted () {
    // since this is a modal, we're going to move it to an outside div
    document.body.appendChild(this.$el)
  },
  destroyed () {
    // destroy what we appended, the append functions more like vue then
    if (this.$el) {
      try {
        document.body.removeChild(this.$el)
      } catch (err) {}
    }
  },
  methods: {
    open: function () {
      this.builder.modalOpen = true
      this.reset()
    },
    close: function () {
      this.builder.modalOpen = false
    },
    create: function () {
      var templateJSON = this.buildJSON()
      var otherDetails = this.buildDetails()
      this.isLoading = true
      var vm = this
      engineApi.addCampaignTemplate(templateJSON, otherDetails,
        function (err, response) {
          vm.isLoading = false
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.close()
            vm.$store.dispatch('setNotify', { state: true, text: 'Your campaign template has been added to the library. The template titled ' + vm.name.model + ' can be found under the following tags: ' + vm.tags.join(',') + '.' })
          }
        }
      )
    },
    tab: function (tab) {
      this.activeTab = tab
    },
    reset: function () {
      this.activeTab = 1
      this.isLoading = false
      this.icon = null
      this.questions = []
      this.tags = []
      this.tagInput = null
      this.type.model = null
      this.subtype.model = null
      this.name.model = null
      this.theme.model = null
      this.action.model = null
      this.libraryDescription.model = null
      this.detailsText.model = null
      if (this.fullWizard) {
        if (this.fullWizard.wizardDetails) {
          this.type.model = this.fullWizard.wizardDetails.type
          this.subtype.model = this.fullWizard.wizardDetails.subtype
          this.name.model = this.fullWizard.wizardDetails.title
          this.detailsText.model = this.fullWizard.wizardDetails.desc
        }
        if (this.fullWizard.wizardTheme) {
          if (this.fullWizard.wizardTheme.currentTheme) {
            if (this.fullWizard.wizardTheme.currentTheme.tags) {
              if (this.fullWizard.wizardTheme.currentTheme.tags.length > 0) {
                this.theme.model = this.fullWizard.wizardTheme.currentTheme.tags[0]
              }
            }
          } else if (this.fullWizard.wizardTheme.recommendedTheme) {
            this.theme.model = this.fullWizard.wizardTheme.recommendedTheme
          }
        }
      }
      this.getTags()
      this.buildQuestions()
    },
    buildQuestions: function () {
      if (this.fullWizard) {
        if (this.fullWizard.wizardDetails) {
          if (this.fullWizard.wizardDetails.questions) {
            if (this.fullWizard.wizardDetails.questions.length > 0) {
              this.questions = []
              var vm = this
              this.fullWizard.wizardDetails.questions.forEach(function (question) {
                vm.questions.push({ name: question.name, placeholder: question.placeholder, default: question.model, label: question.label, help: question.desktopHelp })
              })
            }
          }
        }
      }
    },
    returnQuestionProp: function (label, name, model, id) {
      return {
        name: 'campaign-question-' + name + '-' + id,
        ref: 'campaign-question-' + name + '-' + id,
        label: label,
        model: model
      }
    },
    getTags: function () {
      var vm = this
      engineApi.fetchTags('campaign', 'all',
        function (err, response) {
          if (err) {
            vm.handleXhrError(err)
          } else {
            response.forEach(function (tag, index) {
              vm.autoSuggestions[0].data.push({ id: index, name: tag.tag })
            })
          }
        }
      )
    },
    buildJSON: function () {
      var json = {
        campaign: {},
        action: {},
        theme: {}
      }
      json.campaign.type = this.type.model
      json.campaign.subtype = this.subtype.model
      json.campaign.title = this.name.model
      json.campaign.desc = this.detailsText.model
      // build the questions
      json.campaign.questions = this.$lodash.cloneDeep(this.fullWizard.wizardDetails.questions)
      var vm = this
      json.campaign.questions.forEach(function (question, i) {
        question.label = vm.questions[i].label
        question.placeholder = vm.questions[i].placeholder
        question.model = vm.questions[i].default
        question.desktopHelp = vm.questions[i].help
      })
      json.action.recommendedAction = this.action.model
      json.theme.recommendedTheme = this.theme.model
      return json
    },
    buildDetails: function () {
      var details = {}
      details.type = this.type.model
      details.subtype = this.subtype.model
      details.title = this.name.model
      details.description = this.libraryDescription.model
      details.icon = (this.icon) ? this.icon.value : 'flag'
      details.tags = this.tags
      return details
    },
    autoOnInputChange (text, oldText) {
      if (text === '') {
        this.autoFilteredOptions = [{ data: [] }]
        return
      }
      var filteredData = this.autoSuggestions[0].data.filter(option => {
        return option.name.toLowerCase().indexOf(text.toLowerCase()) > -1
      })
      this.autoFilteredOptions = [{ data: filteredData }]
    },
    autoSelectHandler (item) {
      this.autoFilteredOptions = [{ data: [] }]
      this.tags.push(item.item.name.toLowerCase())
    },
    deleteTag (index) {
      this.tags.splice(index, 1)
    },
    getSuggestionValue (suggestion) {
      return null // this clears out the auto suggestion box
    }
  }
}
</script>

<style scoped>
.modal-card-head {
  border: none!important;
}
.modal-card-foot {
  padding: 0!important;
}
.modal-card-head, .modal-card-foot {
  background-color: white!important;
}
.question-heading {
  padding-bottom: 10px;
}
.tag-area {
  min-height: 100px;
}
.autosuggest__results-container {
  position: unset!important;
}
a.card-footer-item.isLoading {
  color: lightgray!important;
}
</style>

<template>
  <div>
    <div class="field">
      <Toggler
        :props="onetwoone.toggle"
        v-model="onetwoone.toggle.model"/>
    </div>
    <div
      v-if="!hasAcySetup"
      class="setup-warning-area">
      <a href="/engine/settings?refreshtab=channels" class="has-text-danger">
        <div class="icon has-text-danger">
          <i class="fas fa-exclamation-circle"/>
        </div>
        Your email channel is not configured. The email channel must be configured with 1-2-1 Marketing for this intregration to function properly. Click here to configure your email channel.
      </a>
    </div>
    <div
      v-if="onetwoone.toggle.model"
      class="field">
      <Toggler
        :props="onetwoone.importMode"
        v-model="onetwoone.importMode.model"/>
    </div>
    <div class="columns">
      <div class="column additional-help-area">
        <a
          href="https://help.campaignpilot.com/how-tos/using-campaignpilot-with-1-2-1-marketing"
          target="_blank">
          <div class="icon has-text-primary">
            <i class="fas fa-question-circle"/>
          </div>
          Learn more about how CampaignPilot works with your 1-2-1 Marketing email software.
        </a>
      </div>
    </div>
    <a
      class="button is-primary is-rounded"
      @click="save()">
      <span class="icon is-small">
        <i class="fas fa-save"/>
      </span>
      <span>Save Changes</span>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import utils from '@/plugins/utils.js'
import Toggler from '@/components/elements/Toggler'
import settings from '@/mixins/Settings.js'

export default {
  components: {
    Toggler
  },
  mixins: [xhr, settings],
  data () {
    return {
      onetwoone: {
        toggle: {
          model: false,
          name: '121',
          ref: '121',
          options: { checked: 'Enabled', unchecked: 'Disabled' },
          height: 30,
          width: 120,
          extendBody: true,
          label: '1-2-1 Marketing Email Software Integration',
          mobileHideLabel: true,
          placeholder: 'Push customer information from CampaignPilot into your 1-2-1 Marketing email software.'
        },
        importMode: {
          model: true,
          name: 'importMode',
          ref: 'importMode',
          options: { checked: 'Enabled', unchecked: 'Disabled' },
          height: 30,
          width: 120,
          extendBody: true,
          label: 'Mailing List For Each Campaign',
          mobileHideLabel: false,
          placeholder: 'Create a mailing list for each CampaignPilot campaign that a customer is captured from.'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentAccountChannels: 'currentAccountChannels'
    }),
    hasAcySetup: function () {
      if (this.currentAccountChannels) {
        if (this.currentAccountChannels.length > 0) {
          var f = false
          this.currentAccountChannels.forEach(function (channel) {
            if (channel.type === 'email' && channel.id === 'acy') f = true
          })
          return f
        }
      }
      return false
    }
  },
  watch: {
    currentAccount: {
      deep: true,
      handler: function () {
        this.updateComponents()
      }
    }
  },
  mounted () {
    this.updateComponents()
  },
  methods: {
    updateComponents () {
      this.onetwoone.toggle.model = this.currentAccount.queue_acy === 1
      this.onetwoone.importMode.model = this.currentAccount.queue_acy_list === 1
    },
    save () {
      var accountUpdates = {
        account_id: this.currentAccount.id,
        queue_acy: utils.convertToBinary(this.onetwoone.toggle.model),
        queue_acy_list: utils.convertToBinary(this.onetwoone.importMode.model)
      }
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.saveAccount('onetwoone', accountUpdates,
        function (err, response) {
          vm.$store.dispatch('setAccount', vm.currentAccount.id) // refresh vuex account
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.$store.dispatch('setNotify', { state: true, text: 'Your 1-2-1 Marketing email software integration settings have been updated.' })
          }
        }
      )
    }
  }
}
</script>

<style scoped>
  .additional-help-area {
    padding-top: 20px;
  }
  .setup-warning-area {
    padding-bottom: 20px;
  }
</style>

<template>
  <div class="oto-duo">
    <div class="oto-lead">
      <!-- Left Column -->
      <div id="leftColumnTop"/>
      <div class="step-heading is-hidden-mobile">
        <h2 class="subtitle uppercase">
          Campaign: {{ vuexWizardTitle }}
        </h2>
        <h1 class="title">
          {{ stepTitle }}
        </h1>
      </div>
      <div
        v-if="hasErrors"
        class="notification is-danger">
        Looks like there is a problem here with your product details. Please check below for any highlighted errors.
      </div>
      <div>
        <div v-if="foreUPEnabled && mode === 'paid' && product.foreup && !giftCardMode">
          <div v-if="foreupLinked" class="link-section notification">
            <span class="icon is-small">
              <i class="fas fa-link"/>
            </span>
            <span>This campaign is linked to a sales item in foreUP. A sale will be posted in your point-of-sale when a customer makes a purchase.</span>
          </div>
          <foreUPProduct
              :props="product.foreup"
              v-model="product.foreup.model"
              @focus="changeHelp(product.foreup)"/>
        </div>
        <TextInput
          :props="product.name"
          v-model="product.name.model"
          @focus="changeHelp(product.name)"/>
        <div
          v-if="giftCardMode && mode === 'paid' && cpsEnabled"
          class="field">
          <Toggler
            :props="product.virtualGC"
            :label="product.virtualGC.labelStyle"
            v-model="product.virtualGC.model"/>
        </div>
        <div
          v-if="!giftCardMode"
          class="field is-horizontal">
          <div class="field-body">
            <TextInput
              :props="product.price"
              v-model="product.price.model"
              @focus="changeHelp(product.price)"/>
            <TextInput
              :props="product.fullPrice"
              v-model="product.fullPrice.model"
              @focus="changeHelp(product.fullPrice)"/>
          </div>
        </div>
        <div
          v-if="mode === 'paid' && !giftCardMode"
          class="field is-horizontal">
          <div class="field-body">
            <input
              id="taxCheckbox"
              v-model="product.tax.charge"
              name="taxCheckbox"
              class="is-checkradio"
              type="checkbox">
            <label
              for="taxCheckbox"
              class="checkbox-label">
              {{ product.tax.checkboxLabel }}
            </label>
            <transition name="fade" mode="out-in">
              <TextInput
                v-if="product.tax.charge"
                :props="product.tax"
                :control="product.tax.control"
                v-model="product.tax.model"
                @focus="changeHelp(product.tax)"/>
              <div v-else style="display: block; height: 60px; width: 100px;"></div>
            </transition>
          </div>
        </div>
        <div
          v-if="mode === 'paid'"
          class="field">
          <Calendar
            :props="product.saleDate"
            v-model="product.saleDate.model"
            @focus="changeHelp(product.saleDate)"/>
        </div>
        <TextInput
          v-if="mode === 'paid'"
          :props="product.stock"
          v-model="product.stock.model"
          @focus="changeHelp(product.stock)"/>
        <div v-if="cpsEnabled && mode === 'paid' && !giftCardMode && product.cps">
          <CPSProduct
            :props="product.cps"
            v-model="product.cps.model"
            @focus="changeHelp(product.cps)"/>
        </div>
        <div
          v-if="giftCardMode && mode === 'paid' && variants.length > 0"
          class="field">
          <label class="label">Amounts For Purchase</label>
          <div>
            <table class="table is-fullwidth is-narrow">
              <tfoot>
                <tr>
                  <th>
                    <TextInput
                      :props="variants[0].option.pAdjust.input"
                      v-model="variants[0].option.pAdjust.input.model"
                      @focus="changeHelp(variant.giftCardHelp)"/>
                  </th>
                  <th>
                    <a
                      class="button add-button-fix is-rounded"
                      @click="addOption(0)">
                      Add
                    </a>
                  </th>
                </tr>
              </tfoot>
              <tbody>
                <tr
                  v-for="(o, oindex) in variants[0].options"
                  :key="'o' + oindex">
                  <td>
                    {{ o.name }}
                  </td>
                  <td>
                    <span
                      class="icon is-small show-hand"
                      @click="deleteVariantOption(0, oindex)">
                      <i
                        class="fas fa-times-circle"/>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="field">
          <label class="label"><span>Image</span></label>
          <div class="oto-product-image-holder">
            <div
              v-if="product.imageUrl"
              class="oto-delete-product-image">
              <a
                class="delete"
                @click="removeImage">
              </a>
            </div>
            <div
              v-if="product.imageUrl"
              class="oto-product-image">
              <img :src="product.imageUrl">
            </div>
            <div class="control">
              <a
                class="button is-rounded"
                @click="openUploadImage">
                Upload
              </a>
            </div>
          </div>
        </div>
        <!-- product options -->
        <div v-if="mode === 'paid' && !giftCardMode">
          <div
            v-if="!showProductOptions"
            id="productOptions">
            <a @click="toggleProductOptions">
              + Manage Product Options (i.e. sizes, colors, add-ons)
            </a>
          </div>
          <div
            v-if="showProductOptions"
            id="productOptions">
            <a @click="toggleProductOptions">
              - Manage Product Options (i.e. sizes, colors, add-ons)
            </a>
          </div>
          <div v-if="showProductOptions">
            <p>Some of your products may have multiple options. For example, small, medium or large. Use this section to add these options.</p>
            <div v-if="variants.length > 0">
              <div
                v-for="(v, vindex) in variants"
                :key="'v' + vindex"
                class="variant-card">
                <header class="card-header">
                  <table class="table is-fullwidth no-background">
                    <tfoot>
                      <tr>
                        <th>
                          <TextInput
                            :props="v.props.name"
                            v-model="v.props.name.model"
                            @focus="changeHelp(product.help)"/>
                        </th>
                        <th>
                          <Select
                            :props="v.props.display"
                            v-model="v.props.display.model"
                            @focus="changeHelp(product.help)"/>
                        </th>
                        <th class="required-box">
                          <div class="field">
                            <label>&nbsp;</label>
                            <div class="control required-box">
                              <input
                                :id="v.props.required.id"
                                v-model="v.props.required.model"
                                :name="v.props.required.name"
                                class="is-checkradio"
                                type="checkbox"
                                @focus="changeHelp(product.help)">
                              <label :for="v.props.required.id">Required</label>
                            </div>
                          </div>
                        </th>
                        <th>
                          <a
                            class="button is-rounded delete-group-button"
                            @click="deleteVariant(vindex)">
                            Delete
                          </a>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </header>
                <div class="card-content">
                  <table class="table is-fullwidth">
                    <thead>
                      <tr>
                        <th/>
                        <th>Option</th>
                        <th>Adjust Price</th>
                        <th v-if="cpsEnabled && v.option.cps">Club Prophet Sales Item</th>
                        <th v-if="foreUPEnabled && mode === 'paid' && v.option.foreup">foreUP Sales Item</th>
                        <th/>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th/>
                        <th>
                          <TextInput
                            :props="v.option.name"
                            v-model="v.option.name.model"
                            @focus="changeHelp(variant.help)"/>
                        </th>
                        <th>
                          <SelectInput
                            :props="v.option.pAdjust"
                            @focus="changeHelp(variant.help)"/>

                        </th>
                        <th
                          v-if="cpsEnabled && v.option.cps"
                          class="oto-no-bold">
                          <CPSProduct
                            :props="v.option.cps"
                            v-model="v.option.cps.model"
                            @focus="changeHelp(v.option.cps)"/>
                        </th>
                        <th
                          v-if="foreUPEnabled && mode === 'paid' && v.option.foreup"
                          class="oto-no-bold">
                          <foreUPProduct
                            :props="v.option.foreup"
                            v-model="v.option.foreup.model"
                            @focus="changeHelp(v.option.foreup)"
                            @input="optionLoadedFromforeUP(v.option, vindex)" />
                        </th>
                        <th>
                          <a
                            class="button add-button-fix is-rounded"
                            @click="addOption(vindex)">
                            Add
                          </a>
                        </th>
                      </tr>
                    </tfoot>
                    <tbody>
                      <tr
                        v-for="(o, oindex) in v.options"
                        :key="'o' + oindex">
                        <td>
                          <a
                            v-if="oindex > 0"
                            class="icon"
                            @click="moveOptionUp(vindex, oindex)">
                            <i class="fas fa-arrow-circle-up"/>
                          </a>
                          <a
                            v-if="oindex < v.options.length - 1"
                            class="icon"
                            @click="moveOptionDown(vindex, oindex)">
                            <i class="fas fa-arrow-circle-down"/>
                          </a>
                        </td>
                        <td>
                          {{ o.name }}
                        </td>
                        <td>
                          <span v-if="!o.pAdjustAmount || o.pAdjustAmount == 0">
                            No
                          </span>
                          <div v-else>
                            <span
                              v-if="o.pAdjustType">
                              Increase product price by ${{ o.pAdjustAmount }}
                            </span>
                            <span
                              v-else
                              class="has-text-danger">
                              Decrease product price by ${{ o.pAdjustAmount }}
                            </span>
                          </div>
                        </td>
                        <td v-if="cpsEnabled && o.cps">
                          <div v-if="o.cps.item">
                            {{ o.cps.item.name }} ({{ o.cps.item.id }})
                          </div>
                        </td>
                        <td v-if="foreUPEnabled && mode === 'paid' && o.foreup">
                          {{o.foreup.attributes.name}} : {{o.foreup.attributes.department}}
                        </td>
                        <td>
                          <span
                            class="icon is-small show-hand"
                            @click="deleteVariantOption(vindex, oindex)">
                            <i
                              class="fas fa-times-circle"/>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <p>&nbsp;</p>
            <a
              id="addVariantButton"
              class="button button-right is-rounded"
              @click="addVariant">
              <span>Add Another Option Group</span>
            </a>
          </div>
        </div>
        <!-- confirmation page customization -->
        <div v-if="mode === 'paid'">
          <div
            v-if="!showConfirmation"
            id="customConfirmation">
            <a @click="toggleConfirmation">
              + Customize Confirmation Page
            </a>
          </div>
          <div
            v-if="showConfirmation"
            id="customConfirmation">
            <a @click="toggleConfirmation">
              - Customize Confirmation Page
            </a>
          </div>
          <div v-if="showConfirmation">
            <p class="padding-bottom">You can add custom content to the purchase confirmation page and email receipt. This is useful if you need to add any instructions on how to redeem a purchase or for additional terms and conditions.</p>
            <RichText
              :props="confirmation"
              :super="(currentUser.superadmin === 1) ? true : false"
              v-model="confirmation.model"
              @focus="changeHelp(confirmation)"/>
          </div>
        </div>
        <!-- gallus offer locker -->
        <div v-if="mode === 'paid' && gallusEnabled && !giftCardMode">
          <div
            v-if="!showGallusOptions"
            id="gallusOptions">
            <a @click="toggleGallusOptions">
              + Gallus Golf Offer Locker Integration
            </a>
          </div>
          <div
            v-if="showGallusOptions"
            id="gallusOptions">
            <a @click="toggleGallusOptions">
              - Gallus Golf Offer Locker Integration
            </a>
          </div>
          <div v-if="showGallusOptions">
            <p class="padding-bottom">
              You can deliver this product electronically through your mobile app. After a customer makes a purchase, the product will appear as a single-use "offer" in their Offer Locker section of your app.
            </p>
            <div class="field">
              <Toggler
                :props="gallusOfferLocker.toggle"
                v-model="gallusOfferLocker.toggle.model"/>
            </div>
            <TextInput
              :props="gallusOfferLocker.title"
              :disabled="!gallusOfferLocker.toggle.model"
              v-model="gallusOfferLocker.title.model"
              @focus="changeHelp(gallusOfferLocker.title)"/>
            <TextArea
              :props="gallusOfferLocker.description"
              :disabled="!gallusOfferLocker.toggle.model"
              v-model="gallusOfferLocker.description.model"
              @focus="changeHelp(gallusOfferLocker.description)"/>
            <Calendar
              :props="gallusOfferLocker.range"
              :disabled="!gallusOfferLocker.toggle.model"
              v-model="gallusOfferLocker.range.model"
              @focus="changeHelp(gallusOfferLocker.range)"/>
            <label class="label">Other Options</label>
            <div class="string-with-input">
              Allow {{ product.name.model }} to be redeemed
              <TextInput
                :props="gallusOfferLocker.perOrderQty"
                :disabled="!gallusOfferLocker.toggle.model"
                v-model="gallusOfferLocker.perOrderQty.model"
                @focus="changeHelp(gallusOfferLocker.perOrderQty)" />
              time(s).
            </div>
            <div class="gallus-link">
              <a
                href="https://support.gallusgolf.com/portal/en/kb/articles/setting-up-an-offer-in-the-offer-locker"
                target="_blank">
                <div class="icon has-text-primary">
                  <i class="fas fa-question-circle"/>
                </div>
                Visit the Gallus Golf Knowledge Base for more information about the Offer Locker.
              </a>
            </div>
          </div>
        </div>
      </div>
      <ImageUploader
        :iu="iu"
        v-model="product.image"/>
    </div>
    <aside class="oto-side">
      <div class="step-heading is-hidden-tablet">
        <h2 class="subtitle uppercase">
          Campaign: {{ vuexWizardTitle }}
        </h2>
        <h1 class="title">
          {{ stepTitle }}
        </h1>
      </div>
      <div class="content">
        <div class="right-column-text">
          {{ stepDescription }}
        </div>
        <div
          v-if="currentHelp"
          id="sticky"
          class="help-notification notification is-hidden-mobile is-primary sticky-div">
          <div class="help-label capitalize">
            {{ currentHelpLabel }}
          </div>
          <div class="help-text">
            {{ currentHelp }}
          </div>
          <span class="icon is-medium has-text-white">
            <i class="fas fa-info-circle fa-lg"/>
          </span>
        </div>
      </div>
    </aside>
    <Confirm :data="overwriteWarning"/>
  </div>
</template>

<script>
import engineApi from '@/plugins/engine-api.js'
import sticky from '@/mixins/StickyListener.js'
import { mapGetters } from 'vuex'
import RichText from '@/components/elements/RichText'
import Select from '@/components/elements/Select'
import SelectInput from '@/components/elements/SelectInput'
import TextArea from '@/components/elements/TextArea'
import TextInput from '@/components/elements/TextInput'
import Toggler from '@/components/elements/Toggler'
import ImageUploader from '@/components/elements/ImageUploader'
import Calendar from '@/components/elements/Calendar'
import CPSProduct from '@/components/elements/CPSProduct'
import foreUPProduct from '@/components/elements/foreUPProduct'
import Confirm from '@/components/elements/Confirm'
import xhr from '@/mixins/Xhr.js'

export default {
  components: {
    RichText,
    Select,
    SelectInput,
    TextArea,
    TextInput,
    Toggler,
    ImageUploader,
    Calendar,
    CPSProduct,
    foreUPProduct,
    Confirm
  },
  mixins: [sticky, xhr],
  props: {
    value: {
      type: Object,
      default: null
    },
    hasErrors: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      showProductOptions: false,
      showGallusOptions: false,
      showConfirmation: false,
      currentHelp: null,
      currentHelpLabel: null,
      product: {
        name: {
          model: null,
          name: 'product-name',
          ref: 'product-name',
          label: 'Product Name',
          validate: { required: true },
          desktopHelp: 'The product name should detail the actual product you\'re selling. Things like \'Gift Card\', \'Range Membership\', or \'Logo Golf Balls\' are sufficient names.'
        },
        price: {
          model: null,
          name: 'product-price',
          ref: 'product-price',
          label: 'Price',
          icon: 'fas fa-dollar-sign',
          placeholder: '100.00',
          convertTo: 'price',
          validate: { required: true },
          desktopHelp: 'The total price to charge for a single item. If you want to charge the customer separate taxes, check the Charge Tax box and enter a tax rate. The customer will be charged this rate on their total purchase order.'
        },
        fullPrice: {
          model: null,
          name: 'product-full-price',
          ref: 'product-full-price',
          label: '"Compare At" Price',
          icon: 'fas fa-dollar-sign',
          placeholder: '125.00',
          convertTo: 'price',
          desktopHelp: 'This is the full or retail price of the item you\'re selling. If you include this the product pages will display discount verbiage.'
        },
        tax: {
          model: null,
          name: 'tax',
          ref: 'tax',
          charge: false,
          checkboxLabel: 'Add Tax to Order Total',
          rightIcon: 'fas fa-percent',
          convertTo: 'float',
          control: {
            divstyle: 'max-width: 100px;'
          },
          desktopHelp: 'When a customer purchases this product you can charge a percent tax calculated against the order total. The total tax charged will appear on a separate line item during checkout and on receipts.'
        },
        stock: {
          model: null,
          name: 'product-stock',
          ref: 'product-stock',
          label: 'Quantity Available',
          convertTo: 'number',
          desktopHelp: 'Quantity available is optional and is the total number products you want to sell online. Once the total number of purchased items reaches this number, your item will no longer be sold online.'
        },
        cps: {
          model: null,
          name: 'cps',
          ref: 'cps',
          label: 'Club Prophet Sales Item',
          desktopHelp: 'You can automatically push purchases of this product into your Club Prophet point-of-sale system. When a purchase is made, a customer will be created in Club Prophet along with an order containing the selected Sales Item.'
        },
        foreup: {
          model: null,
          name: 'foreup',
          ref: 'foreup',
          label: 'foreUP Sales Item',
          searchplaceholder: 'Enter search term',
          desktopHelp: 'You can automatically push purchases of this product into your foreUP point-of-sale system. When a purchase is made, a customer will be created in foreUP along with an order containing the selected Sales Item.'
        },
        saleDate: {
          model: null,
          name: 'sale-window',
          ref: 'sale-window',
          label: 'Purchase Window',
          subtype: 'range-exp',
          placeholder: 'Start Date',
          endPlaceholder: 'End Date',
          desktopHelp: 'The purchase window is an optional date span in which you sell this item. If the current date is before or after the purchase window then customers will not be able to purchase your item.'
        },
        image: null,
        imageUrl: null,
        help: {
          helpTitle: 'Option Group',
          desktopHelp: 'When your customer is ordering your product, they can customize their purchase by selecting various Product Options. Organize these options in groups for your customer, for example \'Size\', \'Color\', or \'Add-On\'. The Limit setting determines how many options in the group your customer can select. The required setting can be toggled to determine if your customer must select an option from that group to make their purchase.'
        },
        virtualGC: {
          model: false,
          name: 'virtual-gc-toggle',
          ref: 'virtual-gc-toggle',
          labelStyle: { style: 'flex-grow: 5!important' },
          label: 'Virtual Gift Card',
          placeholder: 'Deliver as a virtual gift card using Club Prophet',
          mobileHideLabel: false,
          options: { checked: 'Enabled', unchecked: 'Disabled' },
          height: 30,
          width: 120
        }
      },
      iu: {
        modalOpen: false,
        includeTitle: true,
        defaultTitle: null,
        allowedRatios: ['square'],
        defaultRatio: 'square',
        format: 'image/jpeg',
        compress: 0.99
      },
      variant: {
        help: {
          helpTitle: 'Options',
          desktopHelp: 'Add the various options for this group. For example if this is an option group called \'Size\', your options could be \'Small\', \'Medium\', and \'Large\'. Each option can also adjust the final product price if the customer selects it. The price can be increased or decreased by a set amount for each option.'
        },
        giftCardHelp: {
          helpTitle: 'Amounts for Purchase',
          desktopHelp: 'Add the dollar amounts you want to sell your gift cards at. Typically gift cards are sold with $25, $50, and $100 increments.'
        },
        name: {
          model: null,
          name: 'option-group-name',
          ref: 'option-group-name',
          label: 'Name',
          placeholder: 'i.e. Color / Size / Add-On'
        },
        required: {
          model: null,
          name: 'option-group-required',
          id: 'option-group-required'
        },
        display: {
          model: 1,
          name: 'display-group-as',
          ref: 'display-group-as',
          label: 'Limit',
          class: 'full-width-select',
          options: [
            { name: 'One Option', value: 1 },
            { name: 'Multiple Options', value: 2 }
          ]
        }
      },
      variants: [],
      gallusOfferLocker: {
        toggle: {
          model: false,
          name: 'gallus-toggle',
          ref: 'gallus-toggle',
          label: 'Offer Locker Integration',
          placeholder: 'Create a single-use offer when purchased',
          mobileHideLabel: false,
          options: { checked: 'Enabled', unchecked: 'Disabled' },
          height: 30,
          width: 120
        },
        title: {
          model: null,
          name: 'gallus-title',
          ref: 'gallus-title',
          label: 'Offer Title',
          desktopHelp: 'The title of the offer that will be added to the Offer Locker. This will appear in the customer\'s list of offers and should be unique to this sale.'
        },
        description: {
          model: null,
          name: 'gallus-description',
          ref: 'gallus-description',
          label: 'Offer Description',
          limit: 280,
          rows: 2,
          desktopHelp: 'The description of the offer will display once the customer taps on this offer in their Offer Locker. Add details here that fully describe the product purchased, as well as any information your staff might need during redemption.'
        },
        range: {
          model: null,
          name: 'gallus-range',
          ref: 'gallus-range',
          label: 'Offer Date Range',
          subtype: 'range-exp',
          placeholder: 'Active On',
          endPlaceholder: 'Expire On',
          subtext: 'Leaving this blank defaults to purchase date.',
          endSubtext: 'Leaving this blank defaults to never expire.',
          subtextClass: 'oto-smalltext',
          desktopHelp: 'This is the date range which the offer is valid from. If you leave the active date blank then the offer will be valid on the date purchased. If you leave the expiration date blank then the offer will never expire.'
        },
        perOrderQty: {
          model: 1,
          name: 'gallus-poq',
          ref: 'gallus-poq',
          fieldClass: 'oto-input-in-sentence',
          inputClass: 'oto-input-in-sentence',
          helpTitle: 'Offer Redemption Limit',
          desktopHelp: 'You can adjust the number of times a virtual offer can be redeemed. This is useful for selling punch passes or running "Buy One, Get One"-type promotions.'
        }
      },
      confirmation: {
        model: null,
        type: 'text-rich',
        name: 'confirmation content',
        ref: 'confirmation content',
        useNameInError: true,
        height: 300,
        label: 'Custom Content For Confirmation',
        desktopHelp: 'The content you add here will be added below the order details on both the confirmation page and email sent to the customer.'
      },
      overwriteWarning: {
        action: 'overwriteforeup',
        show: false,
        result: false,
        hideBack: false,
        question: 'Overwrite existing CampaignPilot values with foreUP values?',
        text: 'You probably have the most accurate item details in foreUP. You can overwrite any existing CampaignPilot fields, like price and tax information, with the values from foreUP.',
        button: 'Overwrite',
        backtext: 'Don\'t Overwrite'
      }
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentAccountChannels: 'currentAccountChannels',
      currentUser: 'currentUser',
      vuexWizardTitle: 'wizardTitle',
      vuexWizardType: 'wizardType',
      vuexWizardDetails: 'wizardDetails'
    }),
    cpsEnabled: function () {
      if (this.currentAccount.cps_active) return true
      return false
    },
    foreUPEnabled: function () {
      if (this.currentAccount.foreup_active && this.currentAccount.foreup_id) return true
      return false
    },
    gallusEnabled: function () {
      var e = false
      if (this.currentAccountChannels.length > 0) {
        this.currentAccountChannels.forEach(function (channel) {
          if (channel.type === 'app' && channel.active === 1) e = true
        })
      }
      return e
    },
    sale: function () {
      return {
        product: this.product,
        variants: this.variants,
        gallus: this.gallusOfferLocker,
        confirmation: this.confirmation
      }
    },
    stepTitle: function () {
      if (this.giftCardMode) return 'Gift Card Details'
      else if (this.mode === 'paid') {
        if (this.vuexWizardType === 'golf') return 'Golf Voucher Details'
        return 'Online Product Details'
      }
      return 'Product Details'
    },
    stepDescription: function () {
      if (this.mode === 'paid') {
        if (this.vuexWizardType === 'golf') return 'You can use CampaignPilot to sell prepaid rounds of golf. Tell us a few additional details about the golf promotion you\'re selling.'
        return 'You can use CampaignPilot to sell your product right on your campaign landing page. Tell us a few additional details about the product you\'re selling.'
      }
      return 'While you won\'t be using CampaignPilot to sell your product, tell us any additional information you might have about it. These details will be used to generate customized content for this campaign.'
    },
    defaultProductName: function () {
      var n = null
      this.vuexWizardDetails.questions.every(function (question) {
        if (question.name) {
          if (question.model) {
            if (question.name === 'title') {
              n = question.model
            }
          }
        }
        if (n) {
          return false
        } else {
          return true
        }
      })
      return n
    },
    defaultProductDesc: function () {
      var n = null
      this.vuexWizardDetails.questions.every(function (question) {
        if (question.name && question.type === 'text-area') {
          if (question.model) {
            if (question.name.includes('description')) {
              n = question.model
            }
          }
        }
        if (n) {
          return false
        } else {
          return true
        }
      })
      return n
    },
    giftCardMode: function () {
      if (this.vuexWizardDetails.subtype === 'gift card') return true
      return false
    },
    foreupLinked: function () {
      if (this.foreUPEnabled && this.product) if (this.product.foreup) if (this.product.foreup.model) if (this.product.foreup.model.id) return true
      return false
    }
  },
  watch: {
    product: {
      handler: function (val) {
        this.$emit('input', this.sale)
      },
      deep: true
    },
    variants: {
      handler: function (val) {
        this.$emit('input', this.sale)
      },
      deep: true
    },
    'product.image.url' (val) {
      if (val) {
        this.uploadImage(val)
      }
    },
    value (val, oval) {
      if (val && oval === null) {
        this.refresh()
      }
    },
    'product.tax.charge' (val) {
      if (val) {
        if (!this.product.tax.model) {
          var r = this.currentAccount.tax_rate
          if (typeof r === 'number') r = r + ''
          this.product.tax.model = r
        }
      }
    },
    'product.foreup.model' (val) {
      if (val) {
        if (!val.loadedFromSave) {
          this.overwriteWarning.result = false
          this.overwriteWarning.show = true
        }
      }
    },
    'overwriteWarning.result' (val) {
      if (val) {
        var item = this.product.foreup.model.attributes
        // name
        this.product.name.model = null
        this.product.name.model = item.name
        // pricing
        this.product.price.model = null
        if (typeof item.basePrice === 'number') this.product.price.model = parseFloat(item.basePrice + '').toFixed(2)
        // taxes
        this.product.tax.charge = false
        this.product.tax.model = null
        if (this.product.foreup.model.relationships) {
          if (this.product.foreup.model.relationships.taxes) {
            var taxes = this.product.foreup.model.relationships.taxes.data
            if (taxes) {
              if (taxes.length > 0) {
                var tax = taxes[0].id.split('_').pop()
                tax = parseFloat(tax).toFixed(2)
                this.product.tax.charge = true
                this.product.tax.model = tax
              }
            }
          }
        }
        // stock
        this.product.stock.model = null
        if (typeof item.quantity === 'number') this.product.stock.model = item.quantity + ''
      }
    }
  },
  mounted () {
    if (this.mode !== 'paid') {
      // turn off required fields if this isn't a paid sale
      this.product.name.validate = null
      this.product.price.validate = null
    }
    // refresh with the given values, otherwise set the defaults
    if (this.value) {
      this.refresh(true)
    } else {
      this.setDefaults()
    }
    // change the label for gift card mode, this looks nicer
    if (this.giftCardMode) {
      this.product.name.label = 'Gift Card Name'
    }
    // change the price icons if we need to
    if (this.currentAccount.currency === 'EUR') {
      this.product.price.icon = 'fas fa-euro-sign'
      this.product.fullPrice.icon = 'fas fa-euro-sign'
    }
  },
  methods: {
    refresh (fromMount) {
      var p = this.value.product
      var v = this.value.variants
      var g = this.value.gallus
      var c = this.value.confirmation
      // load in the saved product
      if (p) {
        if (p.name) this.product.name.model = p.name.model
        if (p.price) this.product.price.model = p.price.model
        if (p.fullPrice) this.product.fullPrice.model = p.fullPrice.model
        if (p.tax) {
          this.product.tax.charge = p.tax.charge
          this.product.tax.model = p.tax.model
        }
        if (p.stock) this.product.stock.model = p.stock.model
        if (p.saleDate) this.product.saleDate.model = p.saleDate.model
        if (p.imageUrl) this.product.imageUrl = p.imageUrl
        if (p.cps) this.product.cps.model = p.cps.model
        if (p.virtualGC) this.product.virtualGC.model = p.virtualGC.model
        if (p.foreup) {
          if (p.foreup.model) p.foreup.model.loadedFromSave = true
          this.product.foreup.model = p.foreup.model
        }
      }
      // load in the saved variants
      if (v) this.variants = v
      if (this.variants) if (this.variants.length > 0) this.showProductOptions = true
      // load in the gallus golf stuff
      if (g) {
        // newer logic here, this format allows us to pass just the values for the wizard in template JSON
        // opposed to full element objects (with name, id, etc). we use these if statements in
        // the setters to handle both the template JSON and campaign wizard JSON.
        // if the user uses a template, we set the model of the component to that value passed.
        // if the user is using wizard JSON, we set the entire component element to the value passed.
        if ('toggle' in g) this.gallusOfferLocker.toggle.model = (typeof g.toggle === 'object' && g.toggle !== null) ? g.toggle.model : g.toggle
        if ('title' in g) this.gallusOfferLocker.title.model = (typeof g.title === 'object' && g.title !== null) ? g.title.model : g.title
        if ('description' in g) this.gallusOfferLocker.description.model = (typeof g.description === 'object' && g.description !== null) ? g.description.model : g.description
        if ('range' in g) this.gallusOfferLocker.range.model = (typeof g.range === 'object' && g.range !== null) ? g.range.model : g.range
        if ('perOrderQty' in g) this.gallusOfferLocker.perOrderQty.model = (typeof g.perOrderQty === 'object' && g.perOrderQty !== null) ? g.perOrderQty.model : g.perOrderQty
      }
      // load in the confirmation page stuff
      if (c) if (c.model) this.confirmation.model = c.model
      // show sub-sections if we need to
      if (this.confirmation.model) this.showConfirmation = true // show the confirmation options if we have something
      if (this.gallusOfferLocker.toggle.model) this.showGallusOptions = true // show the gallus options if turned on
      // if we're refreshing then we need to refresh the defaults for any values we don't have in the given JSON
      if (fromMount && !p) this.setDefaults('product')
      if (fromMount && !g) this.setDefaults('gallus')
    },
    setDefaults (type) {
      if (!type || type === 'product') {
        this.product.name.model = this.defaultProductName
        if (this.vuexWizardType === 'golf') this.product.name.model += ' Voucher'
      }
      if (!type || type === 'gallus') {
        this.gallusOfferLocker.title.model = this.defaultProductName
        if (this.vuexWizardType === 'golf') this.gallusOfferLocker.title.model += ' Voucher'
        this.gallusOfferLocker.description.model = this.defaultProductDesc
      }
    },
    changeHelp (question) {
      var label = question.name
      if (question.label && !question.useNameInError) {
        label = question.label
      }
      if (question.helpTitle) {
        label = question.helpTitle
      }
      this.currentHelpLabel = label
      this.currentHelp = question.desktopHelp
    },
    openUploadImage () {
      this.iu.modalOpen = true
    },
    uploadImage (url) {
      this.image = {}
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.uploadImage(
        vm.currentAccount.id, url, 'product', 'product',
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          }
          vm.product.imageUrl = response.url
        }
      )
      this.product.image = {}
    },
    addVariant () {
      // this appends in the working "variant" from the add product option group form
      // it sets a new working "option" for the variant and an empty array for all the
      // options we'll add later on
      var variantToAdd = {
        props: {
          name: this.$lodash.cloneDeep(this.variant.name),
          display: this.$lodash.cloneDeep(this.variant.display),
          required: this.$lodash.cloneDeep(this.variant.required)
        },
        option: {
          name: {
            name: 'optionName-' + this.variants.length,
            ref: 'optionName-' + this.variants.length,
            model: null
          },
          pAdjust: {
            label: null,
            select: {
              model: true,
              name: 'adjustType-' + this.variants.length,
              ref: 'adjustType-' + this.variants.length,
              options: [
                { name: '+', value: true },
                { name: '-', value: false }
              ]
            },
            input: {
              model: null,
              name: 'adjustAmount-' + this.variants.length,
              ref: 'adjustAmount-' + this.variants.length,
              icon: 'fas fa-dollar-sign',
              convertTo: 'price',
              placeholder: '0.00'
            },
            validate: { }
          },
          cps: {
            model: null,
            name: 'optionCps' + this.variants.length,
            ref: 'optionCps' + this.variants.length,
            helpTitle: 'Club Prophet Sales Item',
            desktopHelp: 'You can automatically push purchases of this product into your Club Prophet point-of-sale system. When a purchase is made, a customer will be created in Club Prophet along with an order containing the selected Sales Item (if they purchase this option).'
          },
          foreup: {
            model: null,
            name: 'optionforeUP' + this.variants.length,
            ref: 'optionforeUP' + this.variants.length,
            display: 'small',
            helpTitle: 'foreUP Sales Item',
            searchplaceholder: 'Search',
            desktopHelp: 'You can automatically push orders into your foreUP point-of-sale system. When a purchase is made, a customer will be created in foreUP along with an order containing the selected Sales Item (if they purchase this option).'
          }
        },
        options: []
      }
      // change the currency symbol
      if (this.currentAccount.currency === 'EUR') {
        variantToAdd.option.pAdjust.input.icon = 'fas fa-euro-sign'
      }
      // need to fix the names and refs so that they are unique
      variantToAdd.props.name.name = variantToAdd.props.name.name + '-' + this.variants.length
      variantToAdd.props.name.ref = variantToAdd.props.name.ref + '-' + this.variants.length
      variantToAdd.props.display.name = variantToAdd.props.display.name + '-' + this.variants.length
      variantToAdd.props.display.ref = variantToAdd.props.display.ref + '-' + this.variants.length
      variantToAdd.props.required.name = variantToAdd.props.required.name + '-' + this.variants.length
      variantToAdd.props.required.id = variantToAdd.props.required.id + '-' + this.variants.length
      this.variants.push(variantToAdd)
      this.resetVariant()
      this.$scrollTo('#addVariantButton', 200, { easing: 'ease' })
    },
    resetVariant () {
      this.variant.name.model = null
      this.variant.required.model = null
      this.variant.display.model = 1
    },
    deleteVariant (variantIndex) {
      this.variants.splice(variantIndex, 1)
    },
    addOption (variantIndex) {
      // this pushes in the working "option" into the variants option array
      // then resets the working "option"
      if (this.giftCardMode) {
        if (this.variants[variantIndex].option.pAdjust.input.model) {
          this.variants[variantIndex].option.pAdjust.input.model = this.variants[variantIndex].option.pAdjust.input.model.replace(',', '')
          this.variants[variantIndex].option.name.model = '$' + Math.round(this.variants[variantIndex].option.pAdjust.input.model)
          this.variants[variantIndex].option.pAdjust.select.model = true
        }
      }
      if (this.variants[variantIndex].option.name.model) {
        var cps = (this.variants[variantIndex].option.cps) ? this.variants[variantIndex].option.cps.model : null
        var foreup = (this.variants[variantIndex].option.foreup) ? this.variants[variantIndex].option.foreup.model : null
        this.variants[variantIndex].options.push(
          {
            name: this.variants[variantIndex].option.name.model,
            pAdjustType: this.variants[variantIndex].option.pAdjust.select.model,
            pAdjustAmount: this.variants[variantIndex].option.pAdjust.input.model,
            cps: cps,
            foreup: foreup
          }
        )
        this.resetOption(variantIndex)
      }
    },
    resetOption (variantIndex) {
      if (this.variants[variantIndex]) {
        if (this.variants[variantIndex].option) {
          if (this.variants[variantIndex].option.name) this.variants[variantIndex].option.name.model = null
          if (this.variants[variantIndex].option.pAdjust) if (this.variants[variantIndex].option.pAdjust.select) this.variants[variantIndex].option.pAdjust.select.model = true
          if (this.variants[variantIndex].option.pAdjust) if (this.variants[variantIndex].option.pAdjust.input) this.variants[variantIndex].option.pAdjust.input.model = null
          if (this.variants[variantIndex].option.cps) this.variants[variantIndex].option.cps.model = null
          if (this.variants[variantIndex].option.foreup) this.variants[variantIndex].option.foreup.model = null
        }
      }
    },
    deleteVariantOption (variantIndex, optionIndex) {
      this.variants[variantIndex].options.splice(optionIndex, 1)
    },
    moveOptionUp (variantIndex, optionIndex) {
      if (optionIndex > 0) {
        // cache the current option
        var upName = this.variants[variantIndex].options[optionIndex].name
        var upPAdjustType = this.variants[variantIndex].options[optionIndex].pAdjustType
        var upPAdjustAmount = this.variants[variantIndex].options[optionIndex].pAdjustAmount
        var upCPS = this.variants[variantIndex].options[optionIndex].cps
        var upforeUP = this.variants[variantIndex].options[optionIndex].foreup
        // set the current option to the option above it
        this.variants[variantIndex].options[optionIndex].name = this.variants[variantIndex].options[optionIndex - 1].name
        this.variants[variantIndex].options[optionIndex].pAdjustType = this.variants[variantIndex].options[optionIndex - 1].pAdjustType
        this.variants[variantIndex].options[optionIndex].pAdjustAmount = this.variants[variantIndex].options[optionIndex - 1].pAdjustAmount
        this.variants[variantIndex].options[optionIndex].cps = this.variants[variantIndex].options[optionIndex - 1].cps
        this.variants[variantIndex].options[optionIndex].foreup = this.variants[variantIndex].options[optionIndex - 1].foreup
        // set the option above to the cached current option
        this.variants[variantIndex].options[optionIndex - 1].name = upName
        this.variants[variantIndex].options[optionIndex - 1].pAdjustType = upPAdjustType
        this.variants[variantIndex].options[optionIndex - 1].pAdjustAmount = upPAdjustAmount
        this.variants[variantIndex].options[optionIndex - 1].cps = upCPS
        this.variants[variantIndex].options[optionIndex - 1].foreup = upforeUP
      }
    },
    moveOptionDown (variantIndex, optionIndex) {
      if (optionIndex < this.variants[variantIndex].options.length - 1) {
        // cache the current option
        var downName = this.variants[variantIndex].options[optionIndex].name
        var downPAdjustType = this.variants[variantIndex].options[optionIndex].pAdjustType
        var downPAdjustAmount = this.variants[variantIndex].options[optionIndex].pAdjustAmount
        var downCPS = this.variants[variantIndex].options[optionIndex].cps
        var downforeUP = this.variants[variantIndex].options[optionIndex].foreup
        // set the current option to the option below it
        this.variants[variantIndex].options[optionIndex].name = this.variants[variantIndex].options[optionIndex + 1].name
        this.variants[variantIndex].options[optionIndex].pAdjustType = this.variants[variantIndex].options[optionIndex + 1].pAdjustType
        this.variants[variantIndex].options[optionIndex].pAdjustAmount = this.variants[variantIndex].options[optionIndex + 1].pAdjustAmount
        this.variants[variantIndex].options[optionIndex].cps = this.variants[variantIndex].options[optionIndex + 1].cps
        this.variants[variantIndex].options[optionIndex].foreup = this.variants[variantIndex].options[optionIndex + 1].foreup
        // set the option below to the cached current option
        this.variants[variantIndex].options[optionIndex + 1].name = downName
        this.variants[variantIndex].options[optionIndex + 1].pAdjustType = downPAdjustType
        this.variants[variantIndex].options[optionIndex + 1].pAdjustAmount = downPAdjustAmount
        this.variants[variantIndex].options[optionIndex + 1].cps = downCPS
        this.variants[variantIndex].options[optionIndex + 1].foreup = downforeUP
      }
    },
    removeImage () {
      this.product.image = null
      this.product.imageUrl = null
    },
    toggleProductOptions () {
      this.showProductOptions = !this.showProductOptions
      if (this.showProductOptions && this.variants.length === 0) this.addVariant()
    },
    toggleGallusOptions () {
      this.showGallusOptions = !this.showGallusOptions
    },
    toggleConfirmation () {
      this.showConfirmation = !this.showConfirmation
    },
    optionLoadedFromforeUP (option, index) {
      if (option) {
        if (option.foreup) {
          if (option.foreup.model) {
            if (option.foreup.model.attributes) {
              this.variants[index].option.name.model = option.foreup.model.attributes.name
              if (typeof option.foreup.model.attributes.basePrice === 'number') this.variants[index].option.pAdjust.input.model = parseFloat(option.foreup.model.attributes.basePrice + '').toFixed(2)
            }
          }
        }
      }
    }
  },
  inject: {
    $validator: '$validator'
  }
}
</script>

<style scoped>
#addVariantButton {
  margin: 15px 0px 10px 0px;
}
.required-box {
  align-content: center;
  vertical-align: middle;
}
.variant-card {
  margin-top: 20px;
}
.variant-card .card-header span {
  margin: 6px;
}
.variant-card .card-content {
  padding: 0!important;
}
.card-header {
  background-color: #F5F5F5!important;
  position: relative;
  box-shadow: none!important;
}
.card-header-title {
  display: block!important;
}
.delete-group-button {
  margin: 0;
  height: 20px;
  position: absolute;
  right: 10px;
  padding: 0px 10px;
  font-size: 10px!important;
}
.tag {
  margin: 0 5px 5px 0!important;
}
.card-header-title {
  margin: 5px!important;
}
.no-background {
  background-color: inherit!important;
  color: inherit!important;
}
.no-background th {
  border: none!important;
}
.add-group-box {
  margin-bottom: 20px;
}
#productOptions {
  margin: 1.5rem 0 1rem 0;
}
#customConfirmation {
  margin: 0 0 1rem 0;
}
#gallusOptions {
  margin: 0 0 1rem 0;
}
.required-box {
  padding-top: 16px;
}
.toggle-holder {
  padding-right: 5px;
}
.oto-product-image-holder {
  display: inline-block;
  position: relative;
}
.oto-delete-product-image {
  position: absolute;
  top: 0px;
  right: -25px;
}
.string-with-input {
  display: block;
  line-height: 30px;
}
.gallus-link {
  margin-top: 15px;
}
.link-section {
  margin-bottom: 1rem;
}
.link-section span {
  margin-right: 0.5rem;
}
</style>

<template>
  <section
    v-if="firstLoadDone"
    class="section">
    <div
      class="container no-print">
      <div class="columns">
        <div class="column">
          <h1 class="title">
            Orders
          </h1>
          <h2 class="subtitle">
            Manage all your CampaignPilot orders.
          </h2>
        </div>
      </div>
      <!-- Info Bar -->
      <nav class="level has-background-white-ter">
        <div class="level-left">
          <div class="level-item">
            <p>
              <strong>Total Sales:</strong>
              {{ currencyTotalInResults }} {{ currencySettings.notation }}
              <strong>Total Orders:</strong>
              {{ totalInResults }}
            </p>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <!-- Select: Timespan -->
            <Select
              :props="goBackThisManyDays"
              :control="{ 'style': { 'margin-top': '2px' } }"
              v-model="goBackThisManyDays.model"></Select>
            <div v-if="showDateRange" class="date-picker">
              <Calendar
                :props="customDateRange"
                v-model="customDateRange.model"/>
            </div>
          </div>
        </div>
      </nav>
      <!-- Filter Orders by Campaign -->
      <div class="field">
        <label class="label"><span>Filter Orders By Campaign</span></label>
        <div class="dropdown">
          <FilterElement
            v-model="filters.campaigns.values"
            by="campaign"
            what="orders"
            :show="filters.campaigns.show"
            :showIntroText="false"
            :scrollMode="true"
            :sortMode="true"
            :includeSearch="true"
            :showOnlyId="showOnlyId"
            :showOnlyValue="showOnlyValue"
            @change="onCampaignFilterChange()"></FilterElement>
        </div>
      </div>
      <!-- Good Table -->
      <div
        v-if="columns.length > 0"
        class="table-wrap">
        <vue-good-table
          mode="remote"
          styleClass="vgt-table has-sticky nowrap small-text"
          :pagination-options="paginationOptions"
          :totalRows="totalInResults"
          :rows="orders"
          :columns="columns"
          :isLoading.sync="tableLoading"
          :sort-options="{ initialSortBy: {field: 'short_date', type: 'desc'} }"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter">
          <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'accounttoggle'">
              {{props.column.label}}
              <span
                class="icon help-tip-span is-hidden-mobile"
                goodtable-tooltip="Use these checkboxes to keep track of which orders you've managed, shipped, or fulfilled. This is just an internal flag for your record keeping.">
                <i class="fas fa-question-circle"/>
              </span>
            </span>
            <span v-else>
              {{props.column.label}}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span
              v-if="props.column.field === 'accounttoggle'"
              class="accounttoggle">
              <input
                v-model="props.row.accounttoggle"
                :id="props.row.confirmation + '_accounttoggle'"
                :name="props.row.confirmation + '_accounttoggle'"
                class="is-checkradio"
                type="checkbox"
                @change="onToggleAccountToggle(props.row)">
                <label
                  :for="props.row.confirmation + '_accounttoggle'"
                  class="checkbox-label"></label>
            </span>
            <span
              v-else-if="props.column.field === 'confirmation'"
              :class="{'light-row': props.row.accounttoggle === 1}">
              <a
                href="#"
                @click="onOrderSelect(props.row, $event)">
                {{props.formattedRow[props.column.field]}}
              </a>
            </span>
            <span v-else-if="props.column.field === 'hits_referralDomain'">
              <span v-if="props.row">
                <span v-if="props.row.hits_medium === 'widget'">
                  <span
                    :key="'reficon-'+props.row.confirmation"
                    :id="'reficonid-'+props.row.confirmation"
                    class="icon has-text-grey-dark"
                    alt="Your website"
                    title="Your website">
                    <i class="fas fa-globe"/>
                  </span>
                  <span class="text">Website</span>
                </span>
                <span v-else-if="props.row.hits_medium === 'email'">
                  <span
                    :key="'reficon-'+props.row.confirmation"
                    :id="'reficonid-'+props.row.confirmation"
                    class="icon has-text-grey-dark"
                    alt="Email"
                    title="Email">
                    <i class="fas fa-envelope"/>
                  </span>
                  <span class="text">Email</span>
                </span>
                <span v-else-if="props.row.hits_medium === 'gallus_app'">
                  <span
                    :key="'reficon-'+props.row.confirmation"
                    :id="'reficonid-'+props.row.confirmation"
                    class="icon has-text-grey-dark"
                    alt="Mobile app"
                    title="Mobile app">
                    <i class="fas fa-mobile"/>
                  </span>
                  <span class="text">App</span>
                </span>
                <span v-else-if="props.row.hits_medium === 'qrcode' || props.row.hits_medium === 'shorturl'">
                  <span
                    :key="'reficon-'+props.row.confirmation"
                    :id="'reficonid-'+props.row.confirmation"
                    class="icon has-text-grey-dark"
                    alt="Print flyer"
                    title="Print flyer">
                    <i class="fas fa-qrcode"/>
                  </span>
                  <span class="text">Print</span>
                </span>
                <span v-else-if="props.row.hits_medium === 'localpost'">
                  <span
                    :key="'reficon-'+props.row.confirmation"
                    :id="'reficonid-'+props.row.confirmation"
                    class="icon has-text-grey-dark"
                    alt="Google My Business"
                    title="Google My Business">
                    <i class="fab fa-google"/>
                  </span>
                  <span class="text">Google</span>
                </span>
                <span v-else-if="props.row.hits_referralDomain">
                  <span v-if="props.row.hits_referralDomain.includes('facebook')">
                    <span
                      :key="'reficon-'+props.row.confirmation"
                      :id="'reficonid-'+props.row.confirmation"
                      class="icon has-text-grey-dark"
                      alt="Facebook"
                      title="Facebook">
                      <i class="fab fa-facebook"/>
                    </span>
                    <span class="text">Facebook</span>
                  </span>
                  <span v-else-if="props.row.hits_referralDomain.includes('twitter') || props.row.hits_referralDomain === 't.co'">
                    <span
                      :key="'reficon-'+props.row.confirmation"
                      :id="'reficonid-'+props.row.confirmation"
                      class="icon has-text-grey-dark"
                      alt="Twitter"
                      title="Twitter">
                      <i class="fab fa-twitter"/>
                    </span>
                    <span class="text">Twitter</span>
                  </span>
                  <span v-else>
                    <span class="text">{{props.row.hits_referralDomain}}</span>
                  </span>
                </span>
                <span v-else-if="props.row.hits_medium">
                  <span
                    :key="'reficon-'+props.row.confirmation"
                    :id="'reficonid-'+props.row.confirmation"
                    class="icon has-text-grey-dark"
                    alt="Other"
                    title="Other">
                    <i class="fas fa-question"/>
                  </span>
                  <span class="text">Other</span>
                </span>
                <span v-else>
                  <span
                    :key="'reficon-'+props.row.confirmation"
                    :id="'reficonid-'+props.row.confirmation"
                    class="icon has-text-grey-dark"
                    alt="Direct"
                    title="Direct">
                    <i class="fas fa-sign-out-alt"/>
                  </span>
                  <span class="text">Direct</span>
                </span>
              </span>
            </span>
            <span
              v-else
              :class="{'light-row': props.row.accounttoggle === 1}">
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
          <div slot="emptystate"></div>
        </vue-good-table>
      </div>
      <!-- Export -->
      <transition name="fade" mode="out-in">
        <div
          v-if="hasOrders"
          class="export-area">
          <Select
            :props="exportOptions"
            v-model="exportOptions.model"></Select>
        </div>
      </transition>
    </div>
    <transition
      name="slideLeft"
      mode="out-in">
      <div
        v-if="selectedOrder"
        class="detail-overlay">
        <click-outside
          :handler="clickedOutsideCampaignDetail">
          <div class="detail-body order-detail">
            <div class="columns is-marginless is-multiline is-mobile">
              <div class="column is-half oto-no-padding-bottom">
                <div class="title">
                  <div class="title-slug">{{ selectedOrder.order.slug }}</div>
                </div>
              </div>
              <div class="column is-half oto-no-padding-bottom no-print">
                <p class="buttons is-pulled-right">
                  <button
                    class="icon-button"
                    @click="print($event)">
                    <span class="icon">
                      <i class="fas fa-print"></i>
                    </span>
                  </button>
                  <button
                    class="icon-button"
                    :disabled="selectedOrder.index <= 0"
                    @click="prevOrder($event)">
                    <span class="icon">
                      <i class="fas fa-arrow-left"></i>
                    </span>
                  </button>
                  <button
                    class="icon-button"
                    :disabled="selectedOrder.index >= orders.length - 1"
                    @click="nextOrder($event)">
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </button>
                </p>
              </div>
              <div class="column is-full oto-no-padding-top">
                <p>{{ selectedOrder.order['nice_date'] }}</p>
              </div>
            </div>
            <div
              v-if="selectedOrder.order"
              class="columns is-marginless is-multiline is-mobile no-print">
              <div class="column is-full">
                <input
                  v-model="selectedOrder.order.accounttoggle"
                  id="selectedOrderToggle"
                  name="selectedOrderToggle"
                  class="is-checkradio"
                  type="checkbox"
                  @change="onToggleAccountToggleFromDetail(selectedOrder.order, $event)">
                <label
                  :class="{'has-text-primary': selectedOrder.order.accounttoggle}"
                  class="order-label has-text-weight-bold"
                  for="selectedOrderToggle">Fulfilled</label>
              </div>
            </div>
            <div
              v-if="selectedOrder.order"
              class="columns is-marginless is-multiline is-mobile">
              <div class="column is-full oto-no-padding-bottom custom-padding-override">
                <h1 class="header">Order Details</h1>
              </div>
              <div
                v-if="selectedOrderHTML"
                class="column">
                <div class="oto-aggregate-order-summary">
                  <div
                    v-html="selectedOrderHTML"
                    class="o-holder">
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="selectedOrder.order"
              class="columns is-marginless is-multiline is-mobile custom-padding-override">
              <div class="column is-full oto-no-padding-bottom">
                <h1 class="header">Customer Details</h1>
              </div>
              <div class="column">
                <p
                  v-if="selectedOrder.order.name"
                  class="has-text-weight-bold">
                  <span class="icon is-hidden-touch">
                    <i class="fas fa-user"/>
                  </span>
                  {{ selectedOrder.order.name }}
                </p>
                <p v-if="selectedOrder.order.email">
                  <span class="icon is-hidden-touch">
                    <i class="fas fa-envelope"/>
                  </span>
                  {{ selectedOrder.order.email }}
                </p>
                <p>
                  <span v-if="selectedOrder.order.hits_medium === 'widget'">
                    <span
                      class="icon has-text-grey-dark"
                      alt="Your website"
                      title="Your website">
                      <i class="fas fa-globe"/>
                    </span>
                    Website
                  </span>
                  <span v-else-if="selectedOrder.order.hits_medium === 'email'">
                    <span
                      class="icon has-text-grey-dark"
                      alt="Email"
                      title="Email">
                      <i class="fas fa-envelope"/>
                    </span>
                    Email
                  </span>
                  <span v-else-if="selectedOrder.order.hits_medium === 'gallus_app'">
                    <span
                      class="icon has-text-grey-dark"
                      alt="Mobile app"
                      title="Mobile app">
                      <i class="fas fa-mobile"/>
                    </span>
                    App
                  </span>
                  <span v-else-if="selectedOrder.order.hits_medium === 'qrcode' || selectedOrder.order.hits_medium === 'shorturl'">
                    <span
                      class="icon has-text-grey-dark"
                      alt="Print flyer"
                      title="Print flyer">
                      <i class="fas fa-qrcode"/>
                    </span>
                    Print
                  </span>
                  <span v-else-if="selectedOrder.order.hits_medium === 'localpost'">
                    <span
                      class="icon has-text-grey-dark"
                      alt="Google My Business"
                      title="Google My Business">
                      <i class="fab fa-google"/>
                    </span>
                    Google
                  </span>
                  <span v-else-if="selectedOrder.order.hits_referralDomain">
                    <span v-if="selectedOrder.order.hits_referralDomain.includes('facebook')">
                      <span
                        class="icon has-text-grey-dark"
                        alt="Facebook"
                        title="Facebook">
                        <i class="fab fa-facebook"/>
                      </span>
                      Facebook
                    </span>
                    <span v-else-if="selectedOrder.order.hits_referralDomain.includes('twitter') || selectedOrder.order.hits_referralDomain === 't.co'">
                      <span
                        class="icon has-text-grey-dark"
                        alt="Twitter"
                        title="Twitter">
                        <i class="fab fa-twitter"/>
                      </span>
                      Twitter
                    </span>
                    <span v-else>
                      <span
                        class="icon has-text-grey-dark"
                        alt="Referral"
                        title="Referral">
                        <i class="fas fa-bullhorn"/>
                      </span>
                      {{selectedOrder.order.hits_referralDomain}}
                    </span>
                  </span>
                  <span v-else-if="selectedOrder.order.hits_medium">
                    <span
                      class="icon has-text-grey-dark"
                      alt="Other"
                      title="Other">
                      <i class="fas fa-question"/>
                    </span>
                    Other
                  </span>
                  <span v-else>
                    <span
                      class="icon has-text-grey-dark"
                      alt="Direct"
                      title="Direct">
                      <i class="fas fa-sign-out-alt"/>
                    </span>
                    Direct
                  </span>
                </p>
                <p v-if="selectedOrder.order.phone">
                  <span class="icon is-hidden-touch">
                    <i class="fas fa-phone"/>
                  </span>
                  {{ selectedOrder.order.phone }}
                </p>
                <p v-if="selectedOrder.order.hits_ip">
                  <span class="icon is-hidden-touch">
                    <i class="fas fa-laptop-house"/>
                  </span>
                  {{ selectedOrder.order.hits_ip }}
                </p>
                <p v-if="selectedOrder.order.street">
                  <span class="icon is-hidden-touch">
                    <i class="fas fa-map-marker-alt"/>
                  </span>
                  {{ selectedOrder.order.street }}
                </p>
                <p
                  v-if="selectedOrder.order.street2"
                  class="no-icon-padding">
                  {{ selectedOrder.order.street2 }}
                </p>
                <p
                  v-if="selectedOrder.order.city && selectedOrder.order.state"
                  class="no-icon-padding">{{ selectedOrder.order.city }}, {{ selectedOrder.order.state }} {{ selectedOrder.order.postal }}</p>
              </div>
              <div
                v-if="googleMapsOrderUrl"
                class="column is-hidden-mobile">
                <iframe
                  title="Google Map"
                  :src="googleMapsOrderUrl"
                  allowfullscreen>
                </iframe>
              </div>
            </div>
            <div
              v-if="selectedOrder.campaign"
              class="columns is-marginless is-multiline is-mobile custom-padding-override">
              <div class="column is-full oto-no-padding-bottom">
                <h1 class="header">Campaign Details</h1>
              </div>
              <div class="column">
                <p
                  v-if="selectedOrder.campaign.title"
                  class="has-text-weight-bold">{{ selectedOrder.campaign.title }}</p>
                <p v-if="selectedOrder.campaign['nice_date']">{{ selectedOrder.campaign['nice_date'] }}</p>
                <p v-if="selectedOrder.campaign.slug">
                  <a
                    target="_blank"
                    :href="this.iframeDomain + '/' + selectedOrder.campaign.slug">
                    {{ this.iframeDomain + '/' + selectedOrder.campaign.slug }}
                  </a>
                </p>
              </div>
              <div
                v-if="selectedOrder.campaign.postimage"
                class="column is-hidden-mobile">
                <div>
                  <img :src="selectedOrder.campaign.postimage">
                </div>
              </div>
            </div>
          </div>
        </click-outside>
      </div>
    </transition>
  </section>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import FileSaver from 'file-saver'
import Select from '@/components/elements/Select'
import Calendar from '@/components/elements/Calendar'
import FilterElement from '@/components/elements/Filter'
import engineApi from '@/plugins/engine-api.js'
import ClickOutside from '@/plugins/onclick-outside'
import utils from '@/plugins/utils.js'
import { mapGetters } from 'vuex'
import xhr from '@/mixins/Xhr.js'

export default {
  components: {
    Calendar,
    ClickOutside,
    FilterElement,
    Select,
    VueGoodTable
  },
  mixins: [xhr],
  props: {
    columnsToShow: { // can pass this as [] to just return raw mysql columns
      type: Array,
      default: function () {
        return ['short_date', 'campaign', 'confirmation', 'total', 'name', 'email', 'hits_referralDomain', 'action', 'accounttoggle']
      }
    },
    columnLabels: { // these should match the columnsToShow array length, pass '' to not change column title
      type: Array,
      default: function () {
        return ['Date', '', '', '', '', '', 'Channel', 'Type', 'Fulfilled']
      }
    },
    columnWidths: { // these should match the columnsToShow array length, pass '' to not change column width from goodtable default
      type: Array,
      default: function () {
        return ['125px', '', '150px', '', '', '', '', '150px', '135px']
      }
    },
    noSortColumns: { // columns which we should turn off sort
      type: Array,
      default: function () {
        return ['campaign', 'action']
      }
    },
    filterColumns: { // columns to enable filter/search on
      type: Array,
      default: function () {
        return ['name', 'email', 'confirmation']
      }
    }
  },
  data () {
    return {
      paginationOptions: {
        enabled: true,
        perPage: 25,
        perPageDropdown: [5, 10, 25],
        dropdownAllowAll: false,
        rowsPerPageLabel: 'Orders per page'
      },
      search: {
        sort: null,
        campaigns: 'all',
        range: null
      },
      filters: {
        campaigns: {
          show: 9999,
          values: [],
          other: false
        }
      },
      loadedFilter: false,
      totalInResults: 0,
      currentPage: 1,
      currencyTotalInResults: '$0.00',
      iframeDomain: process.env.VUE_APP_LANDING_PAGE_IFRAME_DOMAIN,
      columns: [],
      orders: null,
      selectedOrder: null,
      tableLoading: false,
      showDateRange: false,
      firstLoadDone: false,
      showOnlyValue: null,
      showOnlyId: 'campaign_id',
      customDateRange: {
        model: null,
        subtype: 'range',
        reportmode: true,
        name: 'daterange',
        ref: 'daterange',
        label: '',
        hideIcons: true,
        placeholder: 'Start Date',
        endPlaceholder: 'End Date',
        fieldStyle: 'max-width: 275px;',
        fieldClass: 'no-margin'
      },
      goBackThisManyDays: {
        model: 90,
        name: 'goBackThisManyDays',
        ref: 'goBackThisManyDays',
        options: [{ 'name': 'Last Week', 'value': 7 }, { 'name': 'Last Month', 'value': 30 }, { 'name': 'Last Quarter', 'value': 90 }, { 'name': 'Last Year', 'value': 365 }, { 'name': 'All Time', 'value': 'all' }, { 'name': 'Custom Range', 'value': 'range' }],
        icon: 'fas fa-calendar-alt',
        fieldClass: 'no-margin'
      },
      exportOptions: {
        model: null,
        name: 'exportType',
        ref: 'exportType',
        options: [{ 'name': 'Export Orders', 'value': null }, { 'name': 'CSV File', 'value': 'csv' }, { 'name': 'Excel File', 'value': 'excel' }],
        fieldClass: 'no-margin'
      }
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentLoading: 'currentLoading',
      currentPerformanceFilter: 'currentPerformanceFilter',
      currentPerformanceDaysBack: 'currentPerformanceDaysBack',
      currentPerformanceDateRange: 'currentPerformanceDateRange'
    }),
    hasOrders: function () {
      if (this.orders) if (this.orders.length > 0) return true
      return false
    },
    googleMapsOrderUrl: function () {
      if (this.selectedOrder) {
        if (this.selectedOrder.order) {
          if (this.selectedOrder.order.street && this.selectedOrder.order.city && this.selectedOrder.order.state && this.selectedOrder.order.postal) return encodeURI('https://www.google.com/maps/embed/v1/place?zoom=10&key=' + process.env.VUE_APP_GOOGLE_MAPS_KEY + '&q=' + this.selectedOrder.order.street + ',' + this.selectedOrder.order.city + ',' + this.selectedOrder.order.state + ' ' + this.selectedOrder.order.postal + '&attribution_source=' + process.env.VUE_APP_GOOGLE_MAPS_ATTRIBUTION)
        }
      }
      return null
    },
    selectedOrderHTML: function () {
      var html = ''
      if (this.selectedOrder) {
        if (this.selectedOrder.order) {
          if (this.selectedOrder.order.json) {
            var orderObject = (typeof this.selectedOrder.order.json === 'string') ? JSON.parse(this.selectedOrder.order.json) : this.selectedOrder.order.json
            if (orderObject) {
              var vm = this
              if (orderObject.product) { // product sales
                html += '<div class="o-row">'
                html += '<div class="o-cell">' + orderObject.totalToBuy + ' x ' + orderObject.product.name + '</div>'
                html += '<div class="o-cell o-price">'
                if (orderObject.product.price) html += this.currencySettings.symbol + orderObject.product.price
                html += '</div>'
                html += '</div>'
                var printedOptions = [] // use this to keep track of which options we print, used later below
                Object.keys(orderObject.selectedOptions).forEach(function (ao) { // print out product add ons
                  printedOptions.push(orderObject.selectedOptions[ao].field.toLowerCase())
                  html += '<div class="o-row">'
                  html += '<div class="o-cell oto-addon-name">' + orderObject.selectedOptions[ao].field + ': ' + orderObject.selectedOptions[ao].name + '</div>'
                  html += '<div class="o-cell o-price">'
                  if (orderObject.selectedOptions[ao].price > 0) {
                    html += vm.currencySettings.symbol + parseFloat(orderObject.selectedOptions[ao].price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  } else if (orderObject.selectedOptions[ao].price < 0) {
                    html += '<span class="o-subtract">- ' + vm.currencySettings.symbol + Math.abs(parseFloat(orderObject.selectedOptions[ao].price)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '</span>'
                  }
                  html += '</div>'
                  html += '</div>'
                })
                if (this.selectedOrder.submissions) { // now print out any custom form fields
                  vm.selectedOrder.submissions.forEach(function (submission) {
                    if (submission.field) submission.field = submission.field.replaceAll('_', ' ')
                    if (submission.field !== 'order quantity' && printedOptions.indexOf(submission.field.toLowerCase()) === -1) {
                      // don't print if it's the order quantity (originally order_quantity) field or any option already printed (that was part of the product options)
                      var fieldNameToPrint = submission.label ? submission.label : submission.field // use label then field and clean it up
                      if (fieldNameToPrint) fieldNameToPrint = fieldNameToPrint.replaceAll('_', ' ')
                      if (fieldNameToPrint) fieldNameToPrint = utils.capitalizeWords(fieldNameToPrint)
                      html += '<div class="o-row">'
                      html += '<div class="o-cell oto-addon-name">' + fieldNameToPrint + ': ' + submission.answer + '</div>'
                      html += '<div class="o-cell o-price"></div>'
                      html += '</div>'
                    }
                  })
                }
              } else if (orderObject.ticketOrder) { // event ticket sales
                var ticketNum = 1
                html += '<div class="o-rows">'
                orderObject.ticketOrder.forEach(function (o) { // each ticket
                  html += '<div class="o-row">'
                  html += '<div class="o-cell">1 x ' + o.ticket.name + '</div>'
                  html += '<div class="o-cell o-price">' + o.ticket.priceString + '</div>'
                  html += '</div>'
                  Object.keys(o.addons).forEach(function (ao) { // each addon in this ticket
                    html += '<div class="o-row">'
                    html += '<div class="o-cell oto-addon-name">Add-on: ' + o.addons[ao].name + '</div>'
                    html += '<div class="o-cell o-price">'
                    if (o.addons[ao].price) {
                      html += o.addons[ao].priceString
                    }
                    html += '</div>'
                    html += '</div>'
                  })
                  if (vm.selectedOrder.submissions) { // now print out any custom form fields
                    vm.selectedOrder.submissions.forEach(function (submission) {
                      if (submission.submission_id === ticketNum) {
                        if (submission.field !== 'ticket' && !submission.field.startsWith('ticket_') && submission.field !== 'addons' && !submission.field.startsWith('addons_')) {
                          // don't print if the field is the ticket name or one of the add ons (already printed out)
                          var fieldNameToPrint = submission.label ? submission.label : submission.field // use label then field and clean it up
                          if (fieldNameToPrint) fieldNameToPrint = fieldNameToPrint.replaceAll('_', ' ')
                          if (fieldNameToPrint) fieldNameToPrint = utils.capitalizeWords(fieldNameToPrint)
                          html += '<div class="o-row">'
                          html += '<div class="o-cell oto-addon-name">' + fieldNameToPrint + ': ' + submission.answer + '</div>'
                          html += '<div class="o-cell o-price"></div>'
                          html += '</div>'
                        }
                      }
                    })
                  }
                  ticketNum++
                })
              }
              html += '<div class="o-row o-empty"><div class="o-cell o-empty"></div><div class="o-cell o-empty"></div></div>'
              if (this.selectedOrder.order.taxTotal) {
                html += '<div class="o-row">'
                html += '<div class="o-cell subtotal-row light">Subtotal</div>'
                html += '<div class="o-cell o-price subtotal-row light">' + this.selectedOrder.order.orderTotal + '</div>'
                html += '</div>'
                html += '<div class="o-row">'
                html += '<div class="o-cell tax-row">Tax'
                if (this.selectedOrder.order.taxRate) html += '<span class="oto-tax-rate"> (' + (this.selectedOrder.order.taxRate * 100) + '%)</span>'
                html += '</div>'
                html += '<div class="o-cell o-price tax-row">' + this.selectedOrder.order.taxTotal + '</div>'
                html += '</div>'
              }
              html += '<div class="o-row">'
              html += '<div class="o-cell total-row light">Total Charged</div>'
              html += '<div class="o-cell o-price total-row light">' + this.selectedOrder.order.total + ' ' + this.currencySettings.notation + '</div>'
              html += '</div>'
              if (this.selectedOrderPaymentStringHTML) {
                html += '<div class="o-row">'
                html += '<div class="o-cell is-size-7">' + this.selectedOrderPaymentStringHTML + '</div>'
                html += '</div>'
              }
              if (orderObject.ticketOrder) html += '</div>'
            }
          }
        }
      }
      return html
    },
    selectedOrderPaymentStringHTML: function () {
      var html = ''
      if (this.selectedOrder) {
        if (this.selectedOrder.order) {
          if (this.selectedOrder.order.ets_transaction_approval_code) {
            // ets
            html += 'Card ending in ' + this.selectedOrder.order.ets_instrument_last_four + ' charged via Elavon<br>Confirmation ' + this.selectedOrder.order.ets_transaction_approval_code
          } else if (this.selectedOrder.order.paypal_orderId) {
            // paypal
            html += 'Charged via Paypal<br>PayPal Order Id ' + this.selectedOrder.order.paypal_orderId
          } else if (this.selectedOrder.order.stripe_id) {
            // stripe
            html += 'Charged via Stripe<br>Stripe Id <a href="' + this.selectedOrder.order.stripe_receipt_url + '" target="_blank">' + this.selectedOrder.order.stripe_id + '</a>'
          } else if (this.selectedOrder.order.payroc_id) {
            // payroc
            html += 'Charged via Payroc<br>Payroc Id ' + this.selectedOrder.order.payroc_id
          }
        }
      }
      return html
    },
    selectedCampaign: function () {
      if (this.filters.campaigns.values.length > 0 && this.$route.params.campaignId) {
        var id = parseInt(this.$route.params.campaignId)
        return this.$lodash.find(this.filters.campaigns.values, { 'campaign_id': id })
      }
      return null
    },
    currencySettings: function () {
      // the various currency settings
      // code - use this if we need to match something
      // symbol - added to the front of all currency digits
      // notation - added to the end of all currency digits, along with an initial space
      var usd = {
        code: 'usd',
        symbol: '$',
        notation: ''
      }
      var cad = {
        code: 'cad',
        symbol: '$',
        notation: 'CAD'
      }
      var eur = {
        code: 'eur',
        symbol: '€',
        notation: ''
      }
      // the default
      var resp = usd
      // if the account has some settings, change the currency settings
      // basically we'll use whatever the payment processor is set to, otherwise
      // we use the general settings
      if (this.currentAccount) {
        if (this.currentAccount.currency === 'CAD') {
          resp = cad
        } else if (this.currentAccount.currency === 'EUR') {
          resp = eur
        }
      }
      return resp
    }
  },
  watch: {
    currentLoading (val) {
      if (val) this.tableLoading = true
      else this.tableLoading = false
    },
    'goBackThisManyDays.model' (val) { // watch the date select box
      if (val) {
        this.$store.dispatch('setPerformanceDaysBack', val) // save the days back model into vuex
        if (val === 'range') {
          if (this.search.range) {
            this.hardReset()
          }
          this.showDateRange = true
        } else {
          this.showDateRange = false
          this.hardReset()
        }
      }
    },
    'customDateRange.model' (val) { // watch the custom date picker
      if (val) {
        this.$store.dispatch('setPerformanceDateRange', val) // save the date range model into vuex
        if (val.includes(',')) {
          this.search.range = val
          this.hardReset()
        }
      }
    },
    'exportOptions.model' (val) { // watch the export picker
      if (val) {
        this.exportOrders(val)
        this.exportOptions.model = null
      }
    }
  },
  mounted () {
    if (this.$route.params.campaignId) {
      this.showOnlyValue = parseInt(this.$route.params.campaignId) // set the "campaign value", we use this to default the filter element
      this.search.campaigns = [this.$route.params.campaignId] // set the search
    }
    if (this.currentPerformanceDaysBack) this.goBackThisManyDays.model = this.currentPerformanceDaysBack
    if (this.currentPerformanceDateRange) this.customDateRange.model = this.currentPerformanceDateRange
    this.fetchOrders()
  },
  methods: {
    hardReset () {
      this.orders = []
      this.filters.campaigns.values = []
      this.search.campaigns = 'all'
      this.softReset()
      this.fetchOrders()
    },
    softReset () {
      if (this.currentPage > 1) {
        // this is a hack. we need to trash the goodtable (by deleting the columns)
        // to get the table to go back to page one
        this.currentPage = 1
        this.columns = []
      }
    },
    simulateBlankView () {
      // we can use this when we know for sure a remote query will return nothing
      // saving us the request to our server
      this.orders = []
      this.totalInResults = 0
      this.currencyTotalInResults = this.currencySettings.symbol + '0.00'
    },
    fetchOrders () {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchOrders('ui', this.currentAccount.id, this.currentPage, this.paginationOptions.perPage, this.goBackThisManyDays.model, this.search,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (!vm.firstLoadDone) vm.firstLoadDone = true
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.processOrders(response)
          }
        }
      )
    },
    fetchOrder (confirmation) {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchOrder(this.currentAccount.id, confirmation,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.selectedOrder = response
            vm.selectedOrder.index = vm.$lodash.findIndex(vm.orders, { confirmation: confirmation }) // we use this for the navigation buttons
          }
        }
      )
    },
    toggleOrder (confirmation) {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.toggleOrder(this.currentAccount.id, confirmation,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          }
        }
      )
    },
    processOrders (orderResponse) {
      if (orderResponse) {
        // this foreach adds a "checked" key and sets that to true for every campaign
        // filter provided.
        orderResponse.campaigns.forEach(function (campaign, index) {
          campaign.checked = true
        })
        this.totalInResults = orderResponse.rows
        this.currencyTotalInResults = orderResponse.orderTotal
        this.orders = orderResponse.results
        this.buildFilters(orderResponse)
        this.buildColumns()
      }
    },
    buildFilters (orderResponse) {
      if (orderResponse) {
        // build campaign filter
        if (orderResponse.campaigns) {
          if (this.filters.campaigns) {
            if (this.filters.campaigns.values.length === 0) {
              this.filters.campaigns.values = orderResponse.campaigns
              this.filters.campaigns.other = true
              if (this.currentPerformanceFilter && this.currentPerformanceFilter !== 'all' && !this.loadedFilter) { // we use this flag so we don't load vuex filter again
                var vm = this
                this.filters.campaigns.values.forEach(function (filter) {
                  filter.checked = false
                  if (vm.currentPerformanceFilter.includes(filter['campaign_id'])) filter.checked = true
                })
                this.onCampaignFilterChange()
                this.loadedFilter = true
              }
            }
          }
        }
      }
    },
    buildColumns () {
      if (this.orders) {
        if (this.orders.length > 0 && this.columns.length === 0) { // only build the columns if we haven't already
          // grab all the unique keys
          var orderKeys = []
          this.orders.forEach(function (order) {
            orderKeys = orderKeys.concat(Object.keys(order))
          })
          orderKeys = this.$lodash.uniq(orderKeys)
          // loop through the keys and add them as vue goodtable columns
          var vm = this
          var rawColumns = [] // this is a temp array to store the columns we want to show. unsorted and no mofidications to the raw data returned from the api/mysql
          orderKeys.forEach(function (key) {
            if (vm.columnsToShow.length === 0 || vm.columnsToShow.includes(key)) { // only add in columns that we want to show
              var sortable = true
              if (vm.noSortColumns.includes(key)) { sortable = false } // disallow sort on some columns
              var filterOptions = null
              if (vm.filterColumns.includes(key)) {
                filterOptions = {
                  enabled: true,
                  placeholder: 'Search'
                }
              }
              var tdClass = null
              var fixedLabel = key.replace('_', ' ')
              fixedLabel = vm.$lodash.startCase(fixedLabel)
              rawColumns.push({
                label: fixedLabel,
                field: key,
                filterOptions: filterOptions,
                tdClass: tdClass,
                sortable: sortable
              })
            }
          })
          // sort the columns if we got em
          // and perform any modifications to them
          if (rawColumns.length > 0) {
            var sortedColumns = new Array(rawColumns.length)
            // if we need to sort then
            // go through each unsorted column and add it to the right spot in the sorted array
            if (this.columnsToShow.length > 0) {
              rawColumns.forEach(function (col) {
                var pos = vm.columnsToShow.indexOf(col.field)
                if (pos >= 0 && pos < sortedColumns.length) sortedColumns[pos] = col
              })
              // update the column names if we have them
              if (sortedColumns.length === this.columnLabels.length) {
                sortedColumns.forEach(function (col, i) {
                  if (vm.columnLabels[i] !== '') col.label = vm.columnLabels[i]
                })
              }
              // update the column widths if we have them
              if (sortedColumns.length === this.columnWidths.length) {
                sortedColumns.forEach(function (col, i) {
                  if (vm.columnWidths[i] !== '') col.width = vm.columnWidths[i]
                })
              }
            } else {
              sortedColumns = rawColumns
            }
            // Update the names of the columns
            this.columns = sortedColumns // set the columns
          }
        }
      }
    },
    print (event) {
      if (event) event.preventDefault()
      window.print()
    },
    nextOrder (event) {
      if (event) event.preventDefault()
      var oi = this.selectedOrder.index + 1
      if (oi === 0 || oi <= this.orders.length - 1) this.fetchOrder(this.orders[oi].confirmation)
    },
    prevOrder (event) {
      if (event) event.preventDefault()
      var oi = this.selectedOrder.index - 1
      if (oi === 0 || oi > 0) this.fetchOrder(this.orders[oi].confirmation)
    },
    exportOrders (type) {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchOrders(type, this.currentAccount.id, this.currentPage, this.paginationOptions.perPage, this.goBackThisManyDays.model, this.search,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            var blob = vm.base64toBlob(response.data, response.headers['content-type']) // our api returns the data as base64, need to conver this to a blob for FileSaver
            var filename = (type === 'excel') ? 'orders.xlsx' : 'orders.csv'
            FileSaver.saveAs(blob, filename)
          }
        }
      )
    },
    onPageChange (params) {
      this.currentPage = params.currentPage
      this.fetchOrders()
    },
    onPerPageChange (params) {
      this.paginationOptions.perPage = params.currentPerPage
      this.fetchOrders()
    },
    onSortChange (params) {
      // need to change sort!
      this.search.sort = params[0].field + ':' + params[0].type
      this.fetchOrders()
    },
    onColumnFilter (params) {
      if (params) {
        if (params.columnFilters) {
          var vm = this
          Object.keys(params.columnFilters).forEach(key => {
            vm.search[key] = params.columnFilters[key]
          })
          this.fetchOrders()
        }
      }
    },
    onCampaignFilterChange () {
      var totalCampaignFilters = this.filters.campaigns.values.length
      var totalChecked = 0
      this.search.campaigns = []
      var vm = this
      this.filters.campaigns.values.forEach(function (campaign) {
        if (campaign.checked) {
          totalChecked++
          vm.search.campaigns.push(campaign['campaign_id'])
        }
      })
      if (totalChecked === totalCampaignFilters) {
        this.search.campaigns = 'all'
      }
      if (totalChecked === 0) {
        // if we got here that means the user unchecked all campaign filters
        this.simulateBlankView()
      } else {
        // only fetch if we have at least one toggled!
        this.softReset()
        this.fetchOrders()
      }
    },
    onToggleAccountToggle (order, event) {
      if (event) event.preventDefault()
      this.toggleOrder(order.confirmation)
    },
    onToggleAccountToggleFromDetail (order, event) {
      if (event) event.preventDefault()
      this.orders[this.selectedOrder.index].accounttoggle = order.accounttoggle
      this.toggleOrder(order.slug)
    },
    onOrderSelect (order, event) {
      if (event) event.preventDefault()
      this.fetchOrder(order.confirmation)
    },
    onClearOrderSelect () {
      this.selectedOrder = null
    },
    clickedOutsideCampaignDetail () {
      this.onClearOrderSelect()
    },
    base64toBlob (base64Data, contentType) {
      // found this somewhere, but it "should" convert base64 into a Blob that we can use for Filesaver
      contentType = contentType || ''
      var sliceSize = 1024
      var byteCharacters = atob(base64Data)
      var bytesLength = byteCharacters.length
      var slicesCount = Math.ceil(bytesLength / sliceSize)
      var byteArrays = new Array(slicesCount)
      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize
        var end = Math.min(begin + sliceSize, bytesLength)
        var bytes = new Array(end - begin)
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0)
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes)
      }
      return new Blob(byteArrays, { type: contentType })
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
iframe {
  max-width: 100%;
  min-width: 100%;
}
.button-fix {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.checkbox-label {
  font-size: 0.8rem!important;
  line-height: 0.8rem!important;
  padding-left: 1.5rem!important;
}
.order-label {
  font-size: 1rem!important;
  line-height: 1.2rem!important;
  padding-left: 2rem!important;
}
.order-label:before {
  margin-top: 3px;
}
.order-label:after {
  margin-top: 2px;
}
.level {
  padding: 20px 30px;
  margin-bottom: 20px!important;
}
.is-checkradio.small[type="checkbox"] + label::before, .is-checkradio.small[type="checkbox"] + label:before {
  width: 1rem!important;
  height: 1rem!important;
}
.is-checkradio.small[type="checkbox"] + label::after, .is-checkradio.small[type="checkbox"] + label:after {
  width: 0.375rem;
  height: 0.6rem;
  top: 0.1rem;
  left: 0.32rem;
}
.date-picker {
  padding-left: 12px;
}
.has-border {
  border: 1px solid #dcdfe6;
}
.table-wrap {
  border-left: 1px solid #dcdfe6;
}
.accounttoggle {
  margin-left: 43px;
}
.export-area {
  margin-top: 20px;
}
.level-left > .level-item > p > strong:not(:first-child) {
  margin-left: 12px;
}
.level-right {
  margin-top: -2px;
}
.dropdown {
  margin-bottom: 20px;
}
.custom-padding-override {
  padding-top: 15px!important;
}
// Order Details
.detail-overlay {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.detail-body {
  padding: 30px;
  background: #ffffff;
  min-height: 100vh;
  max-height: 100vh;
  width: 60%;
  max-width: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: none;
  left: 0;
  position: absolute;
  box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28);
}
.detail-body > .button {
  margin: 0 0 0 auto;
  border: none!important;
}
.order-detail .header {
  font-family: $family-primary;
  font-weight: 600;
  font-size: 1.25rem;
}
.order-detail .title {
  display: block;
}
.order-detail .title-slug {
  display: inline-block;
  font-family: $family-primary;
  font-weight: 600;
  font-size: 1.5rem;
  color: $primary;
  margin-right: 12px;
}
.order-detail .title-date {
  display: inline-block;
  font-family: $family-primary;
  font-size: 1rem;
  font-weight: 400;
  color: $grey;
}
.no-icon-padding {
  padding-left: 27px;
}
// Vue Good Table
[goodtable-tooltip]:before {
  font-family: $family-primary;
  font-weight: 600;
  border-radius: 2px;
  background: $primary;
  color: #F5F5F5;
  content: attr(goodtable-tooltip);
  font-size: 12px;
  visibility: hidden;
  opacity: 0;
  padding: 5px 7px;
  position: absolute;
  width: 200px;
  max-width: 200px;
  white-space: normal;
  z-index: 100;
  // right: 105%;
  // top: 225%;
  right: 100px;
  top: 80px;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -webkit-appearance: none;
}
[goodtable-tooltip]:hover:before,
[goodtable-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
[goodtable-tooltip]:after {
  box-sizing: border-box;
  color: white;
  display: inline-block;
  font-size: 0.75rem;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  z-index: 1020;
  content: '';
  border-style: solid;
  border-width: 8px;
  border-color: transparent transparent transparent $primary;
  // right: 95%;
  // top: 250%;
  right: 84px;
  top: 90px;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -webkit-appearance: none;
}
.help-tip-span {
  z-index: 1;
  position: absolute;
}
// Responsive Stuff
@media only screen and (min-width : 0px) and (max-width : $desktop) {
  .level-left > .level-item{
    align-items: normal;
    display: block;
  }
  .level-right > .level-item {
    display: block!important;
  }
  .date-picker {
    padding-left: 0px!important;
    padding-top: 12px!important;
  }
  .detail-body {
    width: 70%;
  }
  .no-icon-padding {
    padding-left: 0px;
  }
  .order-channel {
    max-width: 250px;
  }
}
@media screen and (max-width: $mobile-max) {
  .level-left + .level-right {
    margin-top: 12px!important;
  }
  .detail-body {
    width: 80%;
    padding: 12px;
  }
  .custom-padding-override {
    padding-top: 0px;
  }
  .order-detail .title-slug {
    font-size: 1.15rem;
  }
  .icon-button {
    font-size: 0.8rem!important;
    padding-bottom: .8335rem;
    padding-left: .825rem;
    padding-right: .825rem;
    padding-top: .8335rem;
  }
  .order-channel {
    max-width: 140px;
  }
}
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .icon-button > .icon > svg {
    // margin-bottom: 20px;
  }
}}
// Print classes
@media print {
  .detail-body {
    max-width: 100%!important;
    width: 100%!important;
    max-height: 100%!important;
    height: 100%!important;
    box-shadow: none!important;
    overflow: visible!important;
  }
  .detail-overlay {
    position: unset;
    display: block;
  }
}
</style>

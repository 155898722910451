<template>
  <div class="container is-fullhd">
    <div class="columns is-full-percent-height">
      <div class="column is-half-desktop is-vertical-center">
        <div class="columns is-multiline center-fixed">
          <div class="column is-half-vertical-mobile">
            <div
              id="login-page-top"
              class="logo-holder">
              <img src="../assets/cp-logo.png">
            </div>
            <div class="login-form-holder">
              <div v-if="showCreateAccount" class="animated fadeIn">
                <h2 class="subtitle">
                  Create A New Account
                </h2>
              </div>
              <div v-else-if="showAccountVerify" class="animated fadeIn">
                <h2 class="subtitle">
                  Verify Your Email Address
                </h2>
              </div>
              <div v-else-if="showForgotPasswordCodeRequest || showForgotPasswordForm" class="animated fadeIn">
                <h2 class="subtitle">
                  Reset Account Password
                </h2>
              </div>
              <div v-else-if="showChangePasswordForm" class="animated fadeIn">
                <h2 class="subtitle">
                  Change Your Temporary Password
                </h2>
              </div>
              <div v-else>
                <h2 class="subtitle animated fadeIn">
                  <span v-if="returningUser">Welcome Back!</span>
                  <span v-else>Sign Into Your Account</span>
                </h2>
              </div>
              <div class="center-fixed">
                <div
                  v-if="currentNotify.state"
                  :class="currentNotify.color"
                  class="notification">
                  {{ currentNotify.text }}
                </div>
              </div>
              <div class="form-container">
                <div v-if="showCreateAccount" class="animated fadeIn">
                  <form @submit.prevent="makeUser">
                    <div class="field has-text-left">
                      <label class="label">Name</label>
                      <div class="control">
                        <input
                          v-validate="{ required: true }"
                          id="new-fullname"
                          ref="new-fullname"
                          key="new-fullname-input"
                          v-model="fullname"
                          :class="{ 'is-danger': errors.has('full name') }"
                          class="input is-medium"
                          name="full name"
                          type="text">
                      </div>
                    </div>
                    <div class="field has-text-left">
                      <label class="label">Email</label>
                      <div class="control">
                        <input
                          v-validate="{ required: true }"
                          id="new-username"
                          ref="new-username"
                          key="new-username-input"
                          v-model="username"
                          :class="{ 'is-danger': errors.has('username') }"
                          class="input is-medium"
                          name="username"
                          type="email">
                      </div>
                    </div>
                    <div class="field has-text-left">
                      <label class="label">Password</label>
                      <div class="control">
                        <input
                          v-validate="{ required: true }"
                          id="new-password"
                          ref="new-password"
                          key="new-password-input"
                          v-model="password"
                          :class="{ 'is-danger': errors.has('password') }"
                          class="input is-medium"
                          name="password"
                          type="password">
                      </div>
                    </div>
                    <div class="field has-text-left terms-area">
                      <input
                        id="termsBox"
                        v-model="terms"
                        name="termsBox"
                        class="is-checkradio"
                        type="checkbox">
                      <label
                        for="termsBox"
                        class="checkbox-label">
                        Check here to indicate that you have read and agree to the CampaignPilot
                        <a
                          href="https://campaignpilot.com/terms"
                          target="_blank">terms</a>
                        and
                        <a
                          href="https://campaignpilot.com/privacy"
                          target="_blank">privacy policy</a>.
                      </label>
                    </div>
                    <button
                      :class="{ 'is-loading':waitingOnAws }"
                      class="button is-block is-primary is-large is-fullwidth is-rounded uppercase">
                      Create Account
                    </button>
                  </form>
                  <div class="new-account-text">
                    Already have an account?
                    <a @click="goToLogin()">
                      Log in now!
                    </a>
                  </div>
                </div>
                <div v-else-if="showAccountVerify" class="animated fadeIn">
                  <form @submit.prevent="verifyUser">
                    <div class="field has-text-left">
                      <label class="label">Your Email</label>
                      <div class="control">
                        <input
                          v-validate="{ required: true }"
                          id="verify-email"
                          ref="verify-email"
                          key="verify-email"
                          v-model="username"
                          :class="{ 'is-danger': errors.has('email') }"
                          class="input is-medium"
                          name="email"
                          type="email">
                      </div>
                    </div>
                    <div class="field has-text-left">
                      <label class="label">Verification Code</label>
                      <div class="control">
                        <input
                          v-validate="{ required: true }"
                          id="verify-code"
                          ref="verify-code"
                          key="verify-code"
                          v-model="vcode"
                          :class="{ 'is-danger': errors.has('verification code') }"
                          class="input is-medium"
                          name="verification code"
                          type="text"
                          placeholder="Enter code from email">
                        <div class="oto-input-footer-text with-padding">
                          <a @click="sendVerifyCode()">Send Another Code</a>
                        </div>
                      </div>
                    </div>
                    <button
                      :class="{ 'is-loading':waitingOnAws }"
                      class="button is-block is-primary is-large is-fullwidth is-rounded uppercase">
                      Verify Account
                    </button>
                  </form>
                  <div class="new-account-text">
                    Already have an account?
                    <a @click="goToLogin()">
                      Log in now!
                    </a>
                  </div>
                </div>
                <div v-else-if="showForgotPasswordCodeRequest" class="animated fadeIn">
                  <form @submit.prevent="sendForgotPasswordCode">
                    <div class="field has-text-left">
                      <label class="label">Email Address</label>
                      <div class="control">
                        <input
                          v-validate="{ required: true }"
                          id="forgot-username"
                          ref="forgot-username"
                          key="forgot-username-input"
                          v-model="username"
                          :class="{ 'is-danger': errors.has('email') }"
                          class="input is-medium"
                          name="email"
                          type="email">
                      </div>
                    </div>
                    <button
                      :class="{ 'is-loading':waitingOnAws }"
                      class="button is-block is-primary is-large is-fullwidth is-rounded uppercase">
                      Request Password Change
                    </button>
                  </form>
                  <div class="new-account-text">
                    Remembered your password?
                    <a @click="goToLogin()">
                      Log in now!
                    </a>
                  </div>
                </div>
                <div v-else-if="showForgotPasswordForm" class="animated fadeIn">
                  <form @submit.prevent="forgotPassword">
                    <div class="field has-text-left">
                      <label class="label">New Password</label>
                      <div class="control">
                        <input
                          v-validate="{ required: true }"
                          id="new-forgot-password"
                          ref="new-forgot-password"
                          key="new-forgot-password-input"
                          v-model="password"
                          :class="{ 'is-danger': errors.has('password') }"
                          class="input is-medium"
                          name="password"
                          type="password">
                      </div>
                    </div>
                    <div class="field has-text-left">
                      <label class="label">Confirm New Password</label>
                      <div class="control">
                        <input
                          v-validate="{ required: true }"
                          id="new-forgot-password2"
                          ref="new-forgot-password2"
                          key="new-forgot-password2-input"
                          v-model="password2"
                          :class="{ 'is-danger': errors.has('confirm password') }"
                          class="input is-medium"
                          name="confirm password"
                          type="password">
                      </div>
                    </div>
                    <button
                      :class="{ 'is-loading':waitingOnAws }"
                      class="button is-block is-primary is-large is-fullwidth is-rounded uppercase">
                      Change Password
                    </button>
                  </form>
                  <div class="new-account-text">
                    Remembered your password?
                    <a @click="goToLogin()">
                      Log in now!
                    </a>
                  </div>
                </div>
                <div v-else-if="showChangePasswordForm" class="animated fadeIn">
                  <form @submit.prevent="changeTempPassword">
                    <div class="field has-text-left">
                      <label class="label">New Password</label>
                      <div class="control">
                        <input
                          v-validate="{ required: true }"
                          id="new-change-password"
                          ref="new-change-password"
                          key="new-change-password-input"
                          v-model="password"
                          :class="{ 'is-danger': errors.has('password') }"
                          class="input is-medium"
                          name="password"
                          type="password">
                      </div>
                    </div>
                    <div class="field has-text-left">
                      <label class="label">Confirm New Password</label>
                      <div class="control">
                        <input
                          v-validate="{ required: true }"
                          id="new-change-password2"
                          ref="new-change-password2"
                          key="new-change-password2-input"
                          v-model="password2"
                          :class="{ 'is-danger': errors.has('confirm password') }"
                          class="input is-medium"
                          name="confirm password"
                          type="password">
                      </div>
                    </div>
                    <button
                      :class="{ 'is-loading':waitingOnAws }"
                      class="button is-block is-primary is-large is-fullwidth is-rounded uppercase">
                      Change Temporary Password
                    </button>
                  </form>
                </div>
                <div v-else>
                  <form @submit.prevent="login" class="animated fadeIn">
                    <div class="field has-text-left">
                      <label class="label">Email</label>
                      <div class="control">
                        <input
                          v-validate="{ required: true }"
                          id="username"
                          ref="username"
                          key="username-input"
                          v-model="username"
                          :class="{ 'is-danger': errors.has('email') }"
                          class="input is-medium"
                          name="email"
                          type="email">
                      </div>
                    </div>
                    <div class="field has-text-left">
                      <label class="label">Password</label>
                      <div class="control">
                        <input
                          v-validate="{ required: true }"
                          id="password"
                          ref="password"
                          key="password-input"
                          v-model="password"
                          :class="{ 'is-danger': errors.has('password') }"
                          class="input is-medium"
                          name="password"
                          type="password">
                        <div class="oto-input-footer-text">
                          <a @click="goToForgotCode()">Forgot Password?</a>
                        </div>
                      </div>
                    </div>
                    <div class="field has-text-left remember-area">
                      <input
                        id="rememberBox"
                        v-model="remember"
                        name="rememberBox"
                        class="is-checkradio"
                        type="checkbox">
                      <label
                        for="rememberBox"
                        class="checkbox-label">Remember me</label>
                    </div>
                    <button
                      :class="{ 'is-loading':waitingOnAws }"
                      class="button is-block is-primary is-large is-fullwidth is-rounded uppercase">
                      Sign In
                    </button>
                  </form>
                  <div class="new-account-text">
                    Don't have an account yet?
                    <a @click="goToCreate()">
                      Create one now!
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="copy-footer">
              &copy; 2023 CampaignPilot. All Rights Reserved.
              <br>
              <a
                href="https://campaignpilot.com/privacy"
                target="_blank">
                Privacy
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://campaignpilot.com/terms"
                target="_blank">
                Terms
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://campaignpilot.com/dmca"
                target="_blank">
                DMCA
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://help.campaignpilot.com"
                target="_blank">
                Help
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="ellipse-background is-hidden-touch">
        <img src="../assets/imgs/brand/campaign-pilot-ellipse.png">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      showCreateAccount: false,
      showAccountVerify: false,
      showForgotPasswordCodeRequest: false,
      showForgotPasswordForm: false,
      showChangePasswordForm: false,
      fullname: '',
      businessname: '',
      phone: '',
      username: '',
      password: '',
      password2: '',
      vcode: null,
      remember: false,
      terms: false,
      waitingOnAws: false,
      cogUser: null,
      passwordCheck: new RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.{8,})') // 1 letter, 1 number, 8 or longer
    }
  },
  computed: {
    ...mapGetters({
      currentNotify: 'currentNotify'
    }),
    referral: function () {
      if (this.$route.query.cpr) {
        return atob(decodeURI(this.$route.query.cpr)).substring(0, 255)
      } else if (document.referrer) {
        return document.referrer.substring(0, 255)
      } else {
        return null
      }
    },
    returningUser: function () {
      if (this.$route.query.redirect || this.$cookie.get('email')) return true
      return false
    }
  },
  watch: {
    currentNotify: {
      handler (val) {
        if (val) {
          if (val.text) {
            this.$scrollTo('#login-page-top', 200, { easing: 'ease' })
          }
        }
      },
      deep: true
    }
  },
  mounted () {
    // reset everything
    this.$store.dispatch('resetUserState')
    this.$store.dispatch('resetUiState')
    // load email address / username from cookie
    var savedEmail = this.$cookie.get('email')
    if (savedEmail) {
      this.username = savedEmail
      this.remember = true
      this.$refs.password.focus()
    } else {
      this.$refs.username.focus()
    }
    if (this.$route.query.expired) {
      // if this was a session expiration, show a message
      this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'Your session has expired. Please log in again to continue.' })
    } else if (this.$route.query.fatal) {
      // if this was a fatal error (i.e. no user/accounts), show a message
      this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'Your session has expired. Please refresh your browser and log in again to continue.' })
    } else if (this.$route.query.verify && this.$route.query.code) {
      this.vcode = this.$route.query.code
      this.username = this.$route.query.verify
      this.goToVerifyCode()
      this.verifyUser()
    } else if (this.$route.query.forgot && this.$route.query.code) {
      this.vcode = this.$route.query.code
      this.username = this.$route.query.forgot
      this.goToForgotForm()
    } else if (this.$route.query.signup) {
      this.goToCreate()
    }
  },
  methods: {
    goToLogin () {
      this.clearNotification()
      this.clearInterfaces()
      this.password = ''
      this.password2 = ''
      this.$scrollTo('#login-page-top', 200, { easing: 'ease' })
    },
    goToCreate () {
      this.clearNotification()
      this.clearInterfaces()
      this.showCreateAccount = true
      this.terms = false
      this.password = ''
      this.password2 = ''
      var vm = this
      setTimeout(function () {
        vm.$refs['new-fullname'].focus()
      }, 500)
    },
    goToForgotCode () {
      this.clearNotification()
      this.clearInterfaces()
      this.showForgotPasswordCodeRequest = true
      this.$scrollTo('#login-page-top', 200, { easing: 'ease' })
    },
    goToForgotForm () {
      this.clearNotification()
      this.clearInterfaces()
      this.showForgotPasswordForm = true
      this.$scrollTo('#login-page-top', 200, { easing: 'ease' })
    },
    goToVerifyCode () {
      this.clearNotification()
      this.clearInterfaces()
      this.showAccountVerify = true
      this.$scrollTo('#login-page-top', 200, { easing: 'ease' })
    },
    goToChangePassword () {
      this.clearNotification()
      this.clearInterfaces()
      this.showChangePasswordForm = true
      this.$scrollTo('#login-page-top', 200, { easing: 'ease' })
    },
    login (mode) {
      if (this.remember) {
        this.$cookie.set('email', this.username, 30)
      } else {
        this.$cookie.delete('email', { domain: process.env.VUE_APP_COOKIE_DOMAIN })
      }
      this.$validator.validateAll().then(result => {
        if (result) {
          this.waitingOnAws = true
          this.$Amplify.Auth.signIn(this.username.toLowerCase(), this.password)
            .then(success => {
              this.waitingOnAws = false
              this.cogUser = success
              if (success.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.goToChangePassword()
                this.password = ''
                this.password2 = ''
                this.$store.dispatch('setNotify', { state: true, color: 'is-warning', text: 'Your account was created with a temporary password. Please enter a new password to access CampaignPilot.' })
              } else {
                if (process.env.NODE_ENV === 'development') console.log('successful sign in')
                // send google analytics event
                if (process.env.NODE_ENV !== 'development') window.gtag('event', 'user_action', { 'action': 'login' })
                this.clearNotification()
                if (mode === 'createbusiness') {
                  this.$router.replace({ name: 'route-create-business' })
                } else {
                  this.$router.replace(this.$route.query.redirect || { name: 'route-engine-home' })
                }
              }
            })
            .catch(err => {
              this.waitingOnAws = false
              if (process.env.NODE_ENV === 'development') console.log('login error:', err)
              switch (err.code) {
                case 'UserNotFoundException':
                  this.goToCreate()
                  this.$store.dispatch('setNotify', { state: true, color: 'is-success', text: 'New to CampaignPilot? Create an account now to get started.' })
                  break
                case 'UserNotConfirmedException':
                  this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'Your account has not been activated yet. Please contact us to get started.' })
                  break
                default:
                  this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'We could not access your account. Please check your credentials and try again.' })
                  break
              }
            })
        } else {
          // form has errors, do nothing
        }
      })
    },
    makeUser () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (!this.passwordCheck.test(this.password)) {
            // password not strong enough
            this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'Your password is not strong enough. Make sure your password is 8 or more characters long and contains at least one number.' })
          } else if (!this.terms) {
            // did not agree to terms or privacy
            this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'You must agree to the terms and privacy policy to create a new account.' })
          } else {
            this.waitingOnAws = true
            var authRequestObject = {
              username: this.username.toLowerCase(),
              password: this.password,
              attributes: {
                'custom:name': this.fullname
              }
            }
            if (this.referral) authRequestObject.attributes['custom:referral'] = this.referral
            this.$Amplify.Auth.signUp(authRequestObject)
              .then(success => {
                // send google analytics event
                if (process.env.NODE_ENV !== 'development') window.gtag('event', 'user_action', { 'action': 'signup' })
                this.waitingOnAws = false
                this.login('createbusiness')
              })
              .catch(err => {
                this.waitingOnAws = false
                if (process.env.NODE_ENV === 'development') console.log('error creating user:', err)
                switch (err.code) {
                  case 'InvalidPasswordException':
                    this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'Your password is not strong enough. Make sure your password is 8 or more characters long and contains at least one number.' })
                    break
                  case 'UsernameExistsException':
                    this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'An account with that email already exists. Try logging in or use a different email address.' })
                    break
                  default:
                    this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'We could not create your account. Please contact us for further assistance.' })
                    break
                }
              })
          }
        } else {
          this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'Looks like you\'re missing a field below. Please check your input and try again.' })
        }
      })
    },
    sendForgotPasswordCode () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.waitingOnAws = true
          this.$Amplify.Auth.forgotPassword(this.username.toLowerCase())
            .then(success => {
              // send google analytics event
              if (process.env.NODE_ENV !== 'development') window.gtag('event', 'user_action', { 'action': 'forgotpw' })
              this.waitingOnAws = false
              this.$store.dispatch('setNotify', { state: true, color: 'is-success', text: 'We emailed you instructions on how to complete your password change. Please check your email to continue.' })
            })
            .catch(err => {
              this.waitingOnAws = false
              if (process.env.NODE_ENV === 'development') console.log('ERROR sendForgotPasswordCode:', err)
              this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'There was a problem generating a password change request. Please contact us.' })
            })
        }
      })
    },
    forgotPassword () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.password !== this.password2) {
            // passwords dont match
            this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'Your passwords do not match. Please re-enter those and try again.' })
          } else if (!this.passwordCheck.test(this.password)) {
            // password not strong enough
            this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'Your password is not strong enough. Make sure your password at least 8 characters long and contains at least one number.' })
          } else {
            this.waitingOnAws = true
            this.$Amplify.Auth.forgotPasswordSubmit(this.username.toLowerCase(), this.vcode, this.password)
              .then(success => {
                this.waitingOnAws = false
                this.goToLogin()
                this.$store.dispatch('setNotify', { state: true, color: 'is-success', text: 'Your account password has been updated. Please try to login with your new credentials.' })
              })
              .catch(err => {
                this.waitingOnAws = false
                if (process.env.NODE_ENV === 'development') console.log('ERROR forgotPassword:', err)
                switch (err.code) {
                  case 'InvalidPasswordException':
                    this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'Your password is not strong enough. Make sure your password at least 8 characters long and contains at least one number.' })
                    break
                  default:
                    this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'We could not update your account password. Please contact us for further assistance.' })
                    break
                }
              })
          }
        }
      })
    },
    sendVerifyCode () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.vcode = ''
          this.waitingOnAws = true
          this.$Amplify.Auth.resendSignUp(this.username.toLowerCase())
            .then(success => {
              this.waitingOnAws = false
              this.$store.dispatch('setNotify', { state: true, color: 'is-success', text: 'We emailed you a new verification code. Please check your email and then enter the new code below.' })
            })
            .catch(err => {
              this.waitingOnAws = false
              if (process.env.NODE_ENV === 'development') console.log('ERROR sendVerifyCode:', err)
              this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'There was a problem generating an account verification code. Please contact us.' })
            })
        }
      })
    },
    verifyUser () {
      this.waitingOnAws = true
      this.$Amplify.Auth.confirmSignUp(this.username.toLowerCase(), this.vcode, {})
        .then(success => {
          this.waitingOnAws = false
          this.goToLogin()
          this.$store.dispatch('setNotify', { state: true, color: 'is-success', text: 'Your CampaignPilot account has been created! Before you can access CampaignPilot we must verify your business information. This usually takes 12-24 hours. We will contact you via email once your account has been fully activated. There is no other action to take at this time.' })
        })
        .catch(err => {
          this.waitingOnAws = false
          if (process.env.NODE_ENV === 'development') console.log('ERROR verifyUser:', err)
          this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'There was a problem with the code you entered. Please try again. You can also send yourself another code using the link below.' })
        })
    },
    changeTempPassword () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.password !== this.password2) {
            // passwords dont match
            this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'Your passwords do not match. Please re-enter those and try again.' })
          } else if (!this.passwordCheck.test(this.password)) {
            // password not strong enough
            this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'Your password is not strong enough. Make sure your password is 8 or more characters long and contains at least one number.' })
          } else {
            this.waitingOnAws = true
            this.$Amplify.Auth.completeNewPassword(
              this.cogUser,
              this.password
            )
              .then(success => {
                this.waitingOnAws = false
                this.clearNotification()
                this.$router.replace(this.$route.query.redirect || { name: 'route-engine-home' })
              })
              .catch(err => {
                this.waitingOnAws = false
                console.error(err)
              })
          }
        } else {
          this.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'Looks like you\'re missing a field below. Please check your input and try again.' })
        }
      })
    },
    clearNotification () {
      this.$store.dispatch('setNotify', { state: false, text: null, color: null })
    },
    clearInterfaces () {
      this.showCreateAccount = false
      this.showForgotPasswordCodeRequest = false
      this.showAccountVerify = false
      this.showForgotPasswordForm = false
      this.showChangePasswordForm = false
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-holder img {
  max-width: 300px;
  height: auto;
}
.columns {
  margin: 0;
}
.container {
  height: 100vh;
  min-width: 100%;
}
.notification {
  display: inline-block;
  margin: 15px 0;
  min-width: 50%;
  max-width: 400px;
}
.is-full-percent-height {
  min-height: 100%;
}
.is-vertical-center {
  display: flex;
  align-items: center;
}
.copy-footer {
  padding-top: 40px;
  font-size: 0.9em!important;
  line-height: 1.7;
}
.copy-footer-mobile {
  padding-top: 50px;
  padding-bottom: 25px;
}
.center-fixed {
  width: 100%;
  text-align: center;
}
.login-form-holder {
  display: inline-block;
  min-width: 50%;
  max-width: 500px;
  padding-top: 20px;
}
.form-container {
  padding-top: 20px;
}
.info-side {
}
.new-account-text {
  padding-top: 15px;
}
.terms-area, .remember-area {
  min-width: 50%;
  max-width: 400px;
}
.terms-area > label, .remember-area > label {
  font-size: 0.9em!important;
  line-height: 1.7;
  display:inline-block;
}
.oto-right-side-box {
  padding-top: 200px;
}
.oto-right-side-box > .logo-holder > img {
  height: 185px;
  padding-bottom: 20px;
}
.oto-right-side-box > .text-holder {
  font-size: 3.2rem;
  line-height: 60px;
  font-weight: 600;
}
.oto-input-footer-text {
  float: right;
  font-size: 0.9rem;
}
.oto-input-footer-text.with-padding {
  padding-bottom: 20px;
}
.checkbox-label {
  padding: 0 0 0 32px!important;
}
.ellipse-background {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  text-align: right;
  max-width: 50%;
}
@media only screen and (min-width : 0px) and (max-width : $mobile-max) {
}
@media only screen and (min-width : $tablet) and (max-width : $desktop) {
}
@media only screen and (min-width : $desktop) and (max-width : $widescreen) {
}
@media only screen and (min-width : $widescreen) {
}
</style>

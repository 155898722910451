<template>
  <div
    :class="{ 'is-horizontal': props.mini }"
    class="field">
    <label
      v-if="props.label"
      :style="label.style"
      :class="label.class"
      class="label">
      <span v-if="props.label === '&nbsp;'">&nbsp;</span>
      <span v-else>{{ props.label }}</span>
      <span
        v-if="isRequired && !props.hideRequired"
        class="icon has-text-danger is-small oto-required-field">
        <i class="fas fa-star-of-life oto-fa-mini"/>
      </span>
    </label>
    <div v-if="selectedItem">
      {{ selectedItem.name }} ({{ selectedItem.id }})
      <a
        class="delete"
        @click="reset"></a>
    </div>
    <div
      v-else
      class="autosuggest-container">
      <vue-autosuggest
        :suggestions="filteredCategories"
        :get-suggestion-value="getSuggestionValue"
        :input-props="{id: 'search-cps-categories', placeholder: 'Start typing to search categories', autocomplete: 'off', class: 'input'}"
        component-attr-class-autosuggest-results-container="auto-suggest-cps-list-container"
        class="auto-suggest-cps-list"
        @input="categoryInputChange"
        @selected="categorySelect"
        @focus="onFocus">
        <template slot-scope="{suggestion}">
          <span class="cps-list-item">{{ suggestion.item.Description }} ({{ suggestion.item.CategoryId }})</span>
        </template>
      </vue-autosuggest>
      <vue-autosuggest
        v-if="selectedCategory"
        :suggestions="filteredItems"
        :get-suggestion-value="getSuggestionValue"
        :input-props="{id: 'search-cps-items', placeholder: 'Start typing to search items', autocomplete: 'off', class: 'input'}"
        component-attr-class-autosuggest-results-container="auto-suggest-cps-list-container"
        class="auto-suggest-cps-list"
        @input="itemInputChange"
        @selected="itemSelect"
        @focus="onFocus">
        <template slot-scope="{suggestion}">
          <span class="cps-list-item">{{ suggestion.item.ItemDescription }} ({{ suggestion.item.ItemCode }})</span>
        </template>
      </vue-autosuggest>
      <span
        v-if="errors.has(props.name) && !props.hideErrors"
        class="has-text-danger animated fadeInDown sentence-case">
        {{ errors.first(props.name) }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VueAutosuggest } from 'vue-autosuggest'
import channelApi from '@/plugins/channel-api.js'
import xhr from '@/mixins/Xhr.js'
import inputs from '@/mixins/Inputs.js'

export default {
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  mixins: [inputs, xhr],
  components: {
    VueAutosuggest
  },
  data () {
    return {
      maxSearchResults: 10,
      selectedCategory: null,
      selectedItem: null,
      filteredCategories: [{ data: [] }],
      filteredItems: [{ data: [] }],
      rawCategories: [],
      rawItems: []
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    })
  },
  watch: {
    value: {
      handler: function (val, oval) {
        if (val) {
          this.selectedCategory = val.category
          this.selectedItem = val.item
        } else if (oval) {
          this.filteredCategories = [{ data: [] }]
          this.reset()
        }
      }
    },
    selectedItem: {
      handler: function (val) {
        if (val) this.$emit('input', { category: this.selectedCategory, item: this.selectedItem })
      }
    }
  },
  mounted () {
    this.fetchCategories()
  },
  methods: {
    fetchCategories: function () {
      var vm = this
      channelApi.fetchClupProphetCategories(vm.currentAccount.id,
        function (err, categories) {
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (categories) if (categories.GetItemCategoryListResult) vm.rawCategories = categories.GetItemCategoryListResult
          }
        }
      )
    },
    fetchItems: function () {
      var vm = this
      channelApi.fetchClupProphetItems(vm.currentAccount.id, vm.selectedCategory.id,
        function (err, items) {
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (items) if (items.GetItemListResult) vm.rawItems = items.GetItemListResult
          }
        }
      )
    },
    getSuggestionValue (suggestion) {
      return (suggestion.item.Description) ? suggestion.item.Description + ' (' + suggestion.item.CategoryId + ')' : suggestion.item.ItemDescription + ' (' + suggestion.item.ItemCode + ')'
    },
    categoryInputChange (text) {
      if (text === '') {
        this.filteredCategories = [{ data: [] }] // if there is no search, clear the results
        return
      }
      var filteredData = this.rawCategories.filter(option => {
        return option.Description.toLowerCase().indexOf(text.toLowerCase()) > -1
      })
      this.filteredCategories = [{ data: filteredData.slice(0, this.maxSearchResults) }]
    },
    categorySelect (item) {
      // reset any item stuff when the category is selected
      this.selectedItem = null
      this.rawItems = []
      this.itemInputChange('')
      // set the selected category
      if (item) {
        if (item.item) {
          this.selectedCategory = {
            name: item.item.Description,
            id: item.item.CategoryId
          }
          this.fetchItems()
        }
      }
    },
    itemSelect (item) {
      if (item) {
        if (item.item) {
          this.selectedItem = {
            name: item.item.ItemDescription,
            id: item.item.ItemCode
          }
        }
      }
    },
    itemInputChange (text) {
      if (text === '') {
        this.filteredItems = [{ data: [] }] // if there is no search, clear the results
        return
      }
      var filteredData = this.rawItems.filter(option => {
        return option.ItemDescription.toLowerCase().indexOf(text.toLowerCase()) > -1
      })
      this.filteredItems = [{ data: filteredData.slice(0, this.maxSearchResults) }]
    },
    reset () {
      this.selectedCategory = null
      this.selectedItem = null
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-item-holder {
  max-height: 100px;
  overflow-y: scroll;
}
#dropdown-menu {
  z-index:999;
}
</style>

<template>
  <div
    v-if="current.month"
    class="cal">
    <div class="cal-row cal-month">{{ current.month }}</div>
    <div class="cal-row">
      <div class="cal-cell cal-dname cal-dwke">Sun</div>
      <div class="cal-cell cal-dname cal-dwkd">Mon</div>
      <div class="cal-cell cal-dname cal-dwkd">Tue</div>
      <div class="cal-cell cal-dname cal-dwkd">Wed</div>
      <div class="cal-cell cal-dname cal-dwkd">Thu</div>
      <div class="cal-cell cal-dname cal-dwkd">Fri</div>
      <div class="cal-cell cal-dname cal-dwke">Sat</div>
    </div>
    <div class="cal-row">
      <div
        v-for="n in 7"
        :key="'row-0-cell-' + n"
        :class="dayClasses(n)"
        class="cal-cell cal-dnum">
        <span>{{ generateDateForCell(n).day }}</span>
      </div>
    </div>
    <div
      v-for="row in 5"
      :key="'row-'+row"
      class="cal-row">
      <div
        v-for="n in 7"
        :key="'row-' + row + '-cell-' + n"
        :class="dayClasses(n, row)"
        class="cal-cell cal-dnum">
        <span>{{ generateDateForCell(n, row).day }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/plugins/utils.js'
// NOTE this widget uses:
// 1-12 based months (i.e Jan = 1, Dec = 12)
// 4 digit years (i.e. 2022)
// 1-7 based days (Sun = 1, Fri = 6, Sat = 7)
export default {
  props: {
    campaigns: {
      type: Array, // array of campaign objects
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      today: null,
      current: {
        month: null,
        year: null,
        firstIs: null,
        daysInMonth: null
      },
      last: {
        month: null,
        year: null,
        daysInMonth: null
      },
      next: {
        month: null,
        year: null,
        daysInMonth: null
      }
    }
  },
  mounted () {
    // default view to current month
    this.setView((new Date().getMonth() + 1), new Date().getFullYear())
  },
  methods: {
    setView (month, year) {
      // set today details if the calendar month is current actual month
      if ((new Date().getMonth() + 1) === month && new Date().getFullYear() === year) this.today = new Date().getDate()
      // set the details of the calendar view month
      this.current.year = year
      this.current.monthNumber = month
      this.current.month = utils.monthName(this.current.monthNumber)
      this.current.daysInMonth = utils.daysInMonth(year, month)
      this.current.firstIs = utils.dayOfWeekNumber(year, month, 1)
      // set the details of month prior to calendar view month
      var lm = new Date(year, month, 1)
      lm.setMonth(lm.getMonth() - 2)
      this.last.year = lm.getFullYear()
      this.last.monthNumber = lm.getMonth() + 1
      this.last.month = utils.monthName(this.last.monthNumber)
      this.last.daysInMonth = utils.daysInMonth(lm.getFullYear(), (lm.getMonth() + 1))
      // set the details of month after the calendar view month
      var nm = new Date(year, month, 1)
      nm.setMonth(nm.getMonth())
      this.next.year = nm.getFullYear()
      this.next.monthNumber = nm.getMonth() + 1
      this.next.month = utils.monthName(this.next.monthNumber)
      this.next.daysInMonth = utils.daysInMonth(nm.getFullYear(), (nm.getMonth() + 1))
    },
    dayClasses (n, row) {
      // determines the css classes needed for the date cell
      var classes = {}
      // today class
      if ((row) && ((row * 7) + (n - 1) - (this.current.firstIs - 2) <= this.current.daysInMonth) && (this.today === ((row * 7) + (n - 1) - (this.current.firstIs - 2)))) classes['cal-today'] = true
      else if (!row && n === this.current.firstIs && this.today === 1) classes['cal-today'] = true
      else if (!row && n > this.current.firstIs && this.today === (n - this.current.firstIs + 1)) classes['cal-today'] = true
      // last month class
      if (!row && (this.current.firstIs - n) >= 1) classes['cal-lastmnth'] = true
      // next month class
      if (row && !((row * 7) + (n - 1) - (this.current.firstIs - 2) <= this.current.daysInMonth)) classes['cal-nextmnth'] = true
      // class for campaigns
      var workingDate = this.generateDateForCell(n, row)
      var found = this.$lodash.find(this.campaigns, { 'date': workingDate.string })
      if (found) {
        // this turns off the class if the date is in the past
        if (utils.dateInFuture(found.date)) found = true
        else found = false
      }
      if (found) classes['cal-hascamp'] = true
      // return all the day classes
      return classes
    },
    generateDateForCell (n, row) {
      // generates an object holding the year, month, and day of the given cell
      var date = {}
      if (row && ((row * 7) + (n - 1) - (this.current.firstIs - 2) <= this.current.daysInMonth)) {
        date.day = (row * 7) + (n - 1) - (this.current.firstIs - 2)
        date.month = this.current.monthNumber
        date.year = this.current.year
      } else if (row) {
        date.day = ((row * 7) + (n - 1) - (this.current.firstIs - 2)) - this.current.daysInMonth
        date.month = this.next.monthNumber
        date.year = this.next.year
      } else if ((!row) && n === this.current.firstIs) {
        date.day = 1
        date.month = this.current.monthNumber
        date.year = this.current.year
      } else if ((!row) && n > this.current.firstIs) {
        date.day = n - this.current.firstIs + 1
        date.month = this.current.monthNumber
        date.year = this.current.year
      } else if ((!row) && (this.current.firstIs - n) === 1) {
        date.day = this.last.daysInMonth
        date.month = this.last.monthNumber
        date.year = this.last.year
      } else if ((!row) && (this.current.firstIs - n) === 2) {
        date.day = this.last.daysInMonth - 1
        date.month = this.last.monthNumber
        date.year = this.last.year
      } else if ((!row) && (this.current.firstIs - n) === 3) {
        date.day = this.last.daysInMonth - 2
        date.month = this.last.monthNumber
        date.year = this.last.year
      } else if ((!row) && (this.current.firstIs - n) === 4) {
        date.day = this.last.daysInMonth - 3
        date.month = this.last.monthNumber
        date.year = this.last.year
      } else if ((!row) && (this.current.firstIs - n) === 5) {
        date.day = this.last.daysInMonth - 4
        date.month = this.last.monthNumber
        date.year = this.last.year
      } else if ((!row) && (this.current.firstIs - n) === 6) {
        date.day = this.last.daysInMonth - 5
        date.month = this.last.monthNumber
        date.year = this.last.year
      }
      date.string = date.year + '-'
      if ((date.month + '').length === 1) date.string += '0'
      date.string += date.month + '-'
      if ((date.day + '').length === 1) date.string += '0'
      date.string += date.day
      return date
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
  .cal {}
  .cal .cal-month {
    margin: 0 0 5px 8px;
    font-weight: 800;
  }
  .cal .cal-row:not(.cal-month) {
    display: flex;
    margin: 0 0 15px 0;
  }
  .cal .cal-cell {
    width: 30px;
    white-space: nowrap;
    text-align: center;
    position: relative;
    flex-grow: 1;
  }
  .cal .cal-cell.cal-dname {
    font-size: 0.8em;
  }
  .cal .cal-cell.cal-dnum {
    font-size: 0.8em;
    font-weight: 600;
  }
  .cal .cal-cell.cal-dnum.cal-lastmnth,
  .cal .cal-cell.cal-dnum.cal-nextmnth {
    color: lightgrey;
  }
  // today marker
  .cal .cal-today:after {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 50%;
    transform: translate(50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $secondary;
    mix-blend-mode: darken;
  }
  // campaign marker (month)
  .cal .cal-hascamp:not(.cal-nextmnth):before {
    content: "";
    position: absolute;
    top: 10px;
    right: 50%;
    transform: translate(50%, -50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $primary;
    mix-blend-mode: darken;
  }
  // campaign marker (next month)
  .cal .cal-hascamp.cal-nextmnth:before {
    content: "";
    position: absolute;
    top: 10px;
    right: 50%;
    transform: translate(50%, -50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $primary;
    mix-blend-mode: darken;
    opacity: 0.25;
  }
</style>

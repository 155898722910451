<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div
            v-if="guideMode"
            class="oto-back-to-home">
            <a
              class="button is-primary is-rounded"
              id="settings-tour-back-button"
              @click="onClickTourBackButton">
              <span class="icon is-small">
                <i class="fas fa-angle-double-left"/>
              </span>
              <span>{{ backButtonText }}</span>
            </a>
          </div>
          <div
            v-else
            class="settings-navigation">
            <h1 class="title">
              Settings
            </h1>
            <h2 class="subtitle">
              Configure CampaignPilot for your business.
            </h2>
            <div class="tabs">
              <ul>
                <li :class="{ 'is-active': currentTab === 'general' }">
                  <a @click="setTab('general')">
                    General
                  </a>
                </li>
                <li :class="{ 'is-active': currentTab === 'channels' }">
                  <a @click="setTab('channels')">
                    Channels
                  </a>
                </li>
                <li :class="{ 'is-active': currentTab === 'payments' }">
                  <a @click="setTab('payments')">
                    Payments
                  </a>
                </li>
                <li :class="{ 'is-active': currentTab === 'media' }">
                  <a @click="setTab('media')">
                    Media
                  </a>
                </li>
                <li :class="{ 'is-active': currentTab === 'other' }">
                  <a @click="setTab('other')">
                    Other
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <General v-if="currentTab === 'general'" />
          <Channels v-else-if="currentTab === 'channels'"/>
          <Payments v-else-if="currentTab === 'payments'"/>
          <Media v-else-if="currentTab === 'media'"/>
          <Other v-else-if="currentTab === 'other'"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import General from '@/components/dashboard/settings/General.vue'
import Channels from '@/components/dashboard/settings/Channels.vue'
import Payments from '@/components/dashboard/settings/Payments.vue'
import Media from '@/components/dashboard/settings/Media.vue'
import Other from '@/components/dashboard/settings/Other.vue'

export default {
  components: {
    General,
    Channels,
    Payments,
    Media,
    Other
  },
  data () {
    return {
      currentTab: null
    }
  },
  computed: {
    ...mapGetters({
      guideMode: 'currentGuideMode',
      lastWM: 'currentGuideModeLastWM'
    }),
    backButtonText: function () {
      if (this.lastWM === '1') {
        return 'Go Back Home'
      } else if (this.lastWM === '2' || this.lastWM === '3') {
        return 'Go Back To Wizard'
      }
      return 'Go Back'
    }
  },
  mounted () {
    // enable wizard/tour/guided mode
    if (this.$route.query.wm) {
      // save the last wm value, we'll use this if the oauth refresh loses the wm
      this.$store.dispatch('setGuideModeLastWM', this.$route.query.wm)
      // turn on global guide mode
      this.$store.dispatch('setGuideMode', true)
      // generate a new id for the tour analytics
      var tourId = Math.floor(1000 + Math.random() * 9000)
      this.$store.dispatch('setGuideModeId', tourId)
      // send google analytics event for settings guide
      if (process.env.NODE_ENV !== 'development') window.gtag('event', 'settings', { 'action': 'started', 'wizardId': this.$route.query.wm, 'tourId': tourId })
      // wm = 2 - user sent to settings from "Schedule Your Camapaign" (WIZARD)
      // wm = 3 - user sent to settings from "Type of Sale Campaign" (WIZARD)
    }
    // allow tab switching via url
    if (this.$route.query.refreshtab === 'general') {
      this.currentTab = 'general'
    } else if (this.$route.query.refreshtab === 'channels') {
      this.currentTab = 'channels'
    } else if (this.$route.query.refreshtab === 'payments') {
      this.currentTab = 'payments'
    } else if (this.$route.query.refreshtab === 'media') {
      this.currentTab = 'media'
    } else if (this.$route.query.refreshtab === 'other') {
      this.currentTab = 'other'
    } else {
      this.currentTab = 'general' // default tab
    }
  },
  methods: {
    setTab (tab) {
      this.currentTab = tab
    },
    onClickTourBackButton () {
      this.$store.dispatch('setGuideMode', false) // turn off the wizard
      if (this.lastWM === '2' || this.lastWM === '3') {
        // user came from the wizard, refresh the parent we came from and close this window
        window.opener.location.reload(false) // refresh parent
        window.close() // close this tab
      } else {
        // wm === 1 or something else
        // user came from the home screen, send them back there
        this.$router.push({ name: 'route-engine-home' })
      }
    }
  }
}
</script>

<style scoped>
.settings-navigation {
  margin-bottom: 20px;
}
</style>

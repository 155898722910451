<template>
  <div>
    <div
      :style="props.fieldStyle"
      :class="props.fieldClass"
      class="field">
      <label
        v-if="props.label"
        :style="label.style"
        :class="label.class"
        class="label">
        <span v-if="props.label === '&nbsp;'">&nbsp;</span>
        <span v-else>{{ props.label }}</span>
        <span
          v-if="isRequired && !props.hideRequired"
          class="icon has-text-danger is-small oto-required-field">
          <i class="fas fa-star-of-life oto-fa-mini"/>
        </span>
      </label>
      <div class="field-body">
        <div class="field no-bottom-margin">
          <div
            :class="{ 'has-icons-right': !props.hideIcons }"
            class="control is-expanded">
            <flat-pickr
              v-validate="props.validate"
              ref="startDate"
              v-model="startDate"
              :config="startDateConfig"
              :placeholder="props.placeholder"
              :name="props.name"
              :data-vv-as="props.label && !props.useNameInError ? props.label : props.name"
              :disabled="disabled"
              :class="{ 'is-danger': errors.has(props.name)}"
              class="input"
              @blur="onBlur()"
              @focus="onFocus()"
              @input="changeStartDate"/>
            <span
              v-if="!props.hideIcons"
              class="icon is-small is-right">
              <i class="fas fa-calendar-alt"/>
            </span>
          </div>
          <p
            v-if="props.subtext"
            :class="props.subtextClass">{{ props.subtext }}</p>
          <span
            v-if="errors.has(props.name)"
            class="has-text-danger animated fadeInDown sentence-case">
            {{ errors.first(props.name) }}
          </span>
        </div>
        <div class="field no-bottom-margin">
          <div
            v-if="(startDate!=null && props.subtype == 'range') || (props.subtype == 'range-exp')"
            :class="{ 'animated fadeIn': props.subtype == 'range', 'has-icons-right': !props.hideIcons }"
            class="control is-expanded">
            <flat-pickr
              v-validate="props.endValidate"
              ref="endDate"
              v-model="endDate"
              :config="endDateConfig"
              :placeholder="props.endPlaceholder"
              :name="props.name"
              :data-vv-as="props.label && !props.useNameInError ? props.label : props.name"
              :disabled="disabled"
              :class="{ 'is-danger': errors.has(props.name)}"
              class="input"
              @blur="onBlur()"
              @focus="onFocus()"
              @input="emitDate"/>
            <span
              v-if="!props.hideIcons"
              class="icon is-small is-right">
              <i class="fas fa-calendar-alt"/>
            </span>
          </div>
          <p
            v-if="props.endSubtext"
            :class="props.subtextClass">{{ props.endSubtext }}</p>
        </div>
      </div>
    </div>
    <p
      v-if="props.mobileHelp && isInputActive"
      class="oto-mobile-help is-info is-hidden-tablet">
      <span class="icon">
        <i class="fas fa-info-circle"/>
      </span>
      {{ props.mobileHelp }}
    </p>
  </div>
</template>

<script>
import inputs from '@/mixins/Inputs.js'
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    FlatPickr
  },
  mixins: [inputs],
  data () {
    return {
      startDate: null,
      endDate: null,
      startDateConfig: {
        minDate: null,
        dateFormat: 'Y-m-d'
      },
      endDateConfig: {
        minDate: null,
        dateFormat: 'Y-m-d'
      }
    }
  },
  computed: {
    propedDate: function () {
      if (this.props.model) {
        return this.props.model
      } else {
        return null
      }
    },
    propedStartDate: function () {
      if (this.props.model) {
        return this.props.model.split(',')[0]
      } else {
        return null
      }
    },
    propedEndDate: function () {
      if (this.props.model) {
        if (this.props.model.split(',').length > 1) {
          return this.props.model.split(',')[1]
        } else {
          return null
        }
      } else {
        return null
      }
    }
  },
  watch: {
    propedDate: function (val, oldVal) {
      if (val) {
        var dates = val.split(',')
        this.startDate = dates[0]
        if (dates.length > 1) {
          this.endDate = dates[1]
        }
      } else {
        this.startDate = null
        this.endDate = null
      }
    }
  },
  created: function () {
    if (this.props.reportmode) {
      // reportmode allows the user to go back forever
      // but limits the max dates to today
      // this mode is good for reporting pickers
      this.startDateConfig.minDate = null
      this.endDateConfig.minDate = null
      this.startDateConfig.maxDate = this.getToday(true)
      this.endDateConfig.maxDate = this.getToday(true)
    }
    this.startDate = this.propedStartDate
    this.endDate = this.propedEndDate
  },
  methods: {
    changeStartDate (event) {
      // this.endDate = null
      // var newdate = new Date(this.startDate)
      // newdate.setDate(newdate.getDate() + 2)
      // this.endDateConfig.minDate = newdate
      this.emitDate()
    },
    emitDate () {
      this.$emit('focus')
      if (this.endDate) {
        this.$emit('input', this.startDate + ',' + this.endDate)
      } else {
        this.$emit('input', this.startDate)
      }
    },
    getToday (dontOffset) {
      var d = new Date()
      d.setHours(0)
      d.setMinutes(0)
      d.setSeconds(0)
      if (!dontOffset) d.setTime(d.getTime() - (d.getTimezoneOffset() * 60000))
      return d
    }
  }
}
</script>

<template>
  <div>
    <div class="field">
      <Toggler
        :props="foreup.toggle"
        v-model="foreup.toggle.model"
        @input="toggleChange" />
    </div>
    <div
      v-if="foreup.toggle.model"
      class="field">
      <div v-if="foreup.id" class="field is-horizontal">
        <div class="field-label is-normal grow">
          <div class="placeholder">
            <div class="icon has-text-primary fa-lg">
              <i class="fas fa-network-wired"/>
            </div>
            Currently connected to <span class="fu-account-name">{{ foreup.name }}</span>
          </div>
        </div>
        <div class="field-body extend">
          <a
            class="has-text-danger"
            @click="reset()">
            <div class="icon has-text-danger">
              <i class="fas fa-unlink"/>
              <span>Reset Connection</span>
            </div>
          </a>
        </div>
      </div>
      <div v-else>
        <div class="field-label is-normal grow">
          <div class="placeholder">
            <div class="icon has-text-danger fa-lg">
              <i class="fas fa-network-wired"/>
            </div>
            Not currently linked to a foreUP account. Please initiate the connection process in the
            <a href="https://foreupsoftware.com/index.php/v2/home#app_store" target="_blank">foreUP App Store</a>
            <div class="icon">
              <i class="fas fa-external-link-alt"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foreUPTeesheets
      v-if="foreup.toggle.model && foreup.id"
      :props="foreup.teesheet"
      v-model="foreup.teesheet.model" />
    <div
      v-if="foreup.toggle.model && foreup.id"
      class="field">
      <Toggler
        :props="foreup.formToggle"
        v-model="foreup.formToggle.model"
        @input="toggleChange" />
    </div>
    <foreUPGroups
      v-if="foreup.toggle.model && foreup.id && foreup.formToggle.model"
      :props="foreup.formGroup"
      v-model="foreup.formGroup.model" />
    <div
      v-if="foreup.toggle.model && foreup.id"
      class="field">
      <Toggler
        :props="foreup.orderToggle"
        v-model="foreup.orderToggle.model"
        @input="toggleChange" />
    </div>
    <foreUPGroups
        v-if="foreup.toggle.model && foreup.id && foreup.orderToggle.model"
        :props="foreup.orderGroup"
        v-model="foreup.orderGroup.model" />
    <div class="columns">
      <div class="column additional-help-area">
        <a
          href="https://help.campaignpilot.com/how-tos/using-campaignpilot-with-foreup"
          target="_blank">
          <div class="icon has-text-primary">
            <i class="fas fa-question-circle"/>
          </div>
          Learn more about how CampaignPilot works with your foreUP software.
        </a>
      </div>
    </div>
    <a
      class="button is-primary is-rounded"
      @click="save()">
      <span class="icon is-small">
        <i class="fas fa-save"/>
      </span>
      <span>Save Changes</span>
    </a>
    <Confirm :data="warning"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Toggler from '@/components/elements/Toggler'
import foreUPTeesheets from '@/components/elements/foreUPTeesheets'
import foreUPGroups from '@/components/elements/foreUPGroups'
import Confirm from '@/components/elements/Confirm'
import engineApi from '@/plugins/engine-api.js'
import channelApi from '@/plugins/channel-api.js'
import utils from '@/plugins/utils.js'
import xhr from '@/mixins/Xhr.js'

export default {
  components: {
    Toggler, foreUPGroups, foreUPTeesheets, Confirm
  },
  mixins: [xhr],
  data () {
    return {
      foreup: {
        name: null,
        id: null,
        toggle: {
          model: true,
          name: 'foreup',
          ref: 'foreup',
          options: { checked: 'Enabled', unchecked: 'Disabled' },
          height: 30,
          width: 120,
          extendBody: true,
          label: 'foreUP Software Integration',
          mobileHideLabel: true,
          placeholder: 'Enable integration between this CampaignPilot business and your foreUP point-of-sale and tee sheet software.'
        },
        teesheet: {
          model: null,
          name: 'foreup-teesheet',
          ref: 'foreup-teesheet',
          label: 'Default Tee Sheet',
          icon: 'fa-table',
          selectLabel: 'Select a tee sheet',
          text: 'Your default tee sheet will be used for loading the appropriate online booking engine. Select the tee sheet your tee times are most commonly displayed on.'
        },
        formToggle: {
          model: true,
          name: 'formToggle',
          ref: 'formToggle',
          options: { checked: 'Enabled', unchecked: 'Disabled' },
          height: 30,
          width: 120,
          extendBody: true,
          label: 'Form Integration',
          mobileHideLabel: true,
          placeholder: 'Push customer information submitted through CampaignPilot forms into your foreUP database.'
        },
        formGroup: {
          model: null,
          name: 'foreup-form-group',
          ref: 'foreup-form-group',
          label: 'Attach the following group to all customers created through a CampaignPilot form',
          icon: 'fa-users',
          selectLabel: 'Select a Group (optional)',
          labelClass: 'oto-smalltext'
        },
        orderToggle: {
          model: true,
          name: 'orderToggle',
          ref: 'orderToggle',
          options: { checked: 'Enabled', unchecked: 'Disabled' },
          height: 30,
          width: 120,
          extendBody: true,
          label: 'Order Integration',
          mobileHideLabel: true,
          placeholder: 'Push customer and order information for purchases made through CampaignPilot into your foreUP database.'
        },
        orderGroup: {
          model: null,
          name: 'foreup-order-group',
          ref: 'foreup-order-group',
          label: 'Attach the following group to all customers that make an order through CampaignPilot',
          icon: 'fa-users',
          selectLabel: 'Select a Group (optional)',
          labelClass: 'oto-smalltext'
        }
      },
      warning: {
        action: 'warning',
        show: false,
        result: false,
        hideBack: false,
        question: 'Attach foreUP to CampaignPilot?',
        text: 'You are attempting to attach foreUP to CampaignPilot.',
        button: 'Attach',
        backtext: 'Don\'t Attach'
      }
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentEmbedMode: 'currentEmbedMode'
    })
  },
  watch: {
    currentAccount: {
      deep: true,
      handler: function () {
        this.updateComponents()
      }
    },
    'warning.result' (val) {
      if (val) {
        this.linkforeUP(this.currentEmbedMode.jwt.course_id)
      }
    }
  },
  mounted () {
    this.updateComponents()
    // handle setup from foreup
    if (this.$route.query.setup === 'foreup' && this.currentEmbedMode) {
      if (this.currentEmbedMode.mode === 'foreup' && this.currentEmbedMode.jwt) {
        if (!this.currentAccount.foreup_id && !this.currentAccount.foreup_active) {
          this.warning.text = `You are about to attach foreUP to ${this.currentAccount.name}. If this is not the correct account, click 'Don't Attach' and then change your active business in the top menu.`
          this.warning.show = true
        }
      }
    }
  },
  methods: {
    updateComponents () {
      // load account
      if (this.currentAccount.foreup_id) this.foreup.id = this.currentAccount.foreup_id
      if (this.currentAccount.foreup_name) this.foreup.name = this.currentAccount.foreup_name
      // load toggles
      this.foreup.toggle.model = this.currentAccount.foreup_active === 1
      this.foreup.formToggle.model = this.currentAccount.foreup_form_active === 1
      this.foreup.orderToggle.model = this.currentAccount.foreup_order_active === 1
      // load selects
      if (this.currentAccount.foreup_teesheet) this.foreup.teesheet.model = this.currentAccount.foreup_teesheet
      if (this.currentAccount.foreup_form_group) this.foreup.formGroup.model = this.currentAccount.foreup_form_group
      if (this.currentAccount.foreup_order_group) this.foreup.orderGroup.model = this.currentAccount.foreup_order_group
    },
    toggleChange () {},
    save () {
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      this.$store.dispatch('setLoadingState', true)
      var foreup = {
        active: utils.convertToBinary(this.foreup.toggle.model),
        id: this.foreup.id,
        name: this.foreup.name,
        teesheet: JSON.stringify(this.foreup.teesheet.model),
        formActive: utils.convertToBinary(this.foreup.formToggle.model),
        formGroup: JSON.stringify(this.foreup.formGroup.model),
        orderActive: utils.convertToBinary(this.foreup.orderToggle.model),
        orderGroup: JSON.stringify(this.foreup.orderGroup.model)
      }
      var updates = {
        account_id: this.currentAccount.id,
        foreup: foreup
      }
      var vm = this
      engineApi.saveAccount('foreup', updates,
        function (err, response) {
          vm.$store.dispatch('setAccount', vm.currentAccount.id) // refresh vuex account
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (process.env.NODE_ENV === 'development') console.log('saved...')
            vm.$store.dispatch('setNotify', { state: true, text: 'Your foreUP software integration settings have been updated.' })
          }
        }
      )
    },
    linkforeUP (courseId) {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      channelApi.foreupFetchCourseDetails(vm.currentAccount.id, courseId, function (err, details) {
        if (err) {
          vm.$store.dispatch('setNotify', { state: true, color: 'is-danger', text: 'We had trouble connecting CampaignPilot with your foreUP software. Please contact support for more help.' })
        } else {
          var foreup = {
            active: utils.convertToBinary(true),
            id: courseId,
            name: 'Course ' + courseId
          }
          if (details) if (details.data) if (details.data.attributes) if (details.data.attributes.title) foreup.name = details.data.attributes.title
          var updates = {
            account_id: vm.currentAccount.id,
            foreup: foreup
          }
          engineApi.saveAccount('foreup', updates,
            function (err) {
              vm.$store.dispatch('setAccount', vm.currentAccount.id) // refresh vuex account
              vm.$store.dispatch('setLoadingState', false)
              if (err) {
                vm.handleXhrError(err)
              } else {
                if (process.env.NODE_ENV === 'development') console.log('saved...')
                vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
                vm.$store.dispatch('setNotify', { state: true, text: 'Your foreUP software has been linked to CampaignPilot. Review your application settings below and select a default tee sheet.' })
              }
            }
          )
        }
      })
    },
    reset () {
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      this.$store.dispatch('setLoadingState', true)
      var foreup = {
        active: utils.convertToBinary(false),
        id: null,
        name: null,
        teesheet: null,
        formActive: utils.convertToBinary(false),
        formGroup: null,
        orderActive: utils.convertToBinary(false),
        orderGroup: null
      }
      var updates = {
        account_id: this.currentAccount.id,
        foreup: foreup
      }
      var vm = this
      engineApi.saveAccount('foreup', updates,
        function (err, response) {
          vm.$store.dispatch('setAccount', vm.currentAccount.id) // refresh vuex account
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (process.env.NODE_ENV === 'development') console.log('saved...')
            vm.$store.dispatch('setNotify', { state: true, text: 'Your foreUP software integration settings have been reset.' })
          }
        }
      )
    }
  }
}
</script>

<style scoped>
  .fu-account-name {
    font-weight: 700;
  }
  .grow {
    text-align: left!important;
    flex-grow: 4!important;
  }
  .extend {
    flex-grow: 0!important;
    padding-right: 55px;
  }
  .icon span{
    padding-left: 5px;
    font-size: 0.8em;
    white-space: nowrap;
  }
  .placeholder .icon {
    margin-right: 5px;
  }
</style>

import axios from 'axios'
import Vue from 'vue'

function getToken () {
  return Vue.prototype.$Amplify.Auth.currentSession().then((data) => {
    if (data) {
      return data.accessToken.jwtToken
    }
  }).catch((e) => {
    return null
  })
}

export default {
  fetchGallusApps (key, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'mode': 'apps'
      }
      if (key) {
        headers['id'] = key
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/channels/gallus', { 'headers': headers })
        .then(r => r.data)
        .then(apps => {
          callback(null, apps)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchGoogleLocations (accountId, googleAccountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'type': googleAccountId,
        'mode': 'locations'
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/channels/google', { 'headers': headers })
        .then(r => r.data)
        .then(locations => {
          callback(null, locations)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchFacebookPages (accountId, channelId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'type': channelId,
        'mode': 'me_accounts'
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/channels/facebook', { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          if (typeof res === 'string') {
            res = JSON.parse(res)
            if (res) res = res.data
          }
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchInstagramPages (accountId, channelId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'type': channelId,
        'mode': 'instagram'
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/channels/facebook', { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          if (typeof res === 'string') {
            res = JSON.parse(res)
            if (res) res = res.data
          }
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchClupProphetCategories (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'mode': 'categories'
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/channels/clubprophet', { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          if (typeof res === 'string') {
            res = JSON.parse(res)
            if (res) res = res.data
          }
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchClupProphetItems (accountId, categoryID, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'mode': 'items',
        'type': categoryID
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/channels/clubprophet', { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          if (typeof res === 'string') {
            res = JSON.parse(res)
            if (res) res = res.data
          }
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  searchUnsplashPhotos (accountId, query, page, num, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'mode': 'photos',
        'page': page,
        'type': num,
        'id': query
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/channels/unsplash', { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          if (typeof res === 'string') {
            res = JSON.parse(res)
            if (res) if (res.data) res = res.data
          }
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  cinchLogin (accountId, email, password, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'mode': 'login'
      }
      var payload = {
        email: email,
        password: password
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/channels/cinch', JSON.stringify(payload), { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  cinchCompanies (accountId, cinchToken, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'mode': 'companies'
      }
      var payload = {
        token: cinchToken
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/channels/cinch', JSON.stringify(payload), { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          if (typeof res === 'string') {
            res = JSON.parse(res)
            if (res) if (res.data) res = res.data
          }
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  cinchSegments (accountId, cinchToken, cinchCompany, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'mode': 'segments'
      }
      var payload = {
        token: cinchToken,
        company: cinchCompany
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/channels/cinch', JSON.stringify(payload), { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          if (typeof res === 'string') {
            res = JSON.parse(res)
            if (res) if (res.data) res = res.data
          }
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  foreupValidateToken (accountId, receivedToken, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'mode': 'validatetoken',
        'qs': receivedToken
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/channels/foreup', { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  foreupFetchCourseDetails (accountId, courseId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'mode': 'course',
        'qs': courseId
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/channels/foreup', { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  foreupFetchGroups (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'mode': 'groups'
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/channels/foreup', { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          if (typeof res === 'string') {
            res = JSON.parse(res)
            if (res) res = res.data
          }
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  foreupFetchTeeSheets (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'mode': 'teesheets'
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/channels/foreup', { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          if (typeof res === 'string') {
            res = JSON.parse(res)
            if (res) res = res.data
          }
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  foreupFetchReservationGroups (accountId, teesheetId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'mode': 'reservationgroups',
        'qs': teesheetId
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/channels/foreup', { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          if (typeof res === 'string') {
            res = JSON.parse(res)
            if (res) res = res.data
          }
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  foreupFetchSalesItems (accountId, q, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'mode': 'salesitems'
      }
      if (q) headers.qs = q
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/channels/foreup', { 'headers': headers })
        .then(r => r.data)
        .then(res => {
          if (typeof res === 'string') {
            res = JSON.parse(res)
            if (res) res = res.data
          }
          callback(null, res)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  }
}

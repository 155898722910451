<template>
  <div class="metrics">
    <div class="metrics-main-holder">
      <div class="metrics-stat-holder">
        <div class="metrics-number">
          <animated-number
            :value="metric.impressions"
            :formatValue="numberFormat"
            :duration="1500"/>
        </div>
        <div class="metrics-label">Impressions</div>
        <div v-if="compare" class="metrics-change" v-html="changeFormat(compareMetric.impressions, metric.impressions)"></div>
      </div>
      <div class="metrics-stat-holder">
        <div class="metrics-number">
          <animated-number
            :value="metric.clicks"
            :formatValue="numberFormat"
            :duration="1500"/>
        </div>
        <div class="metrics-label">Clicks</div>
        <div v-if="compare" class="metrics-change" v-html="changeFormat(compareMetric.clicks, metric.clicks)"></div>
      </div>
      <div class="metrics-stat-holder">
        <div class="metrics-number">
          <animated-number
            :value="metric.revenue"
            :formatValue="priceFormat"
            :duration="1500"/>
        </div>
        <div class="metrics-label">Revenue</div>
        <div v-if="compare" class="metrics-change" v-html="changeFormat(compareMetric.revenue, metric.revenue)"></div>
      </div>
    </div>
    <div class="metrics-action-area">
      <router-link
        :to="{ name: 'route-engine-performance' }">
        View Performance
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AnimatedNumber from 'animated-number-vue'
import engineApi from '@/plugins/engine-api.js'
import utils from '@/plugins/utils.js'
import xhr from '@/mixins/Xhr.js'

export default {
  components: {
    AnimatedNumber
  },
  mixins: [xhr],
  props: {
    daysBack: {
      type: String, // number of days back as a string, "30", "60" or the string "range", which then the search.range value is used
      default: null
    },
    compare: {
      type: Boolean, // pass true and metrics will display a percent change for the previous period
      default: false
    },
    search: {
      type: Object, // object is converted to qs and passed to the api call. includes things like: search.range
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      metric: {
        campaigns: 0,
        impressions: 0,
        clicks: 0,
        submissions: 0,
        conversions: 0,
        revenue: 0
      },
      compareMetric: {
        campaigns: 0,
        impressions: 0,
        clicks: 0,
        submissions: 0,
        conversions: 0,
        revenue: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    }),
    currencySettings: function () {
      // the various currency settings
      // code - use this if we need to match something
      // symbol - added to the front of all currency digits
      // notation - added to the end of all currency digits, along with an initial space
      var usd = {
        code: 'usd',
        symbol: '$',
        notation: ''
      }
      var cad = {
        code: 'cad',
        symbol: '$',
        notation: 'CAD'
      }
      var eur = {
        code: 'eur',
        symbol: '€',
        notation: ''
      }
      // the default
      var resp = usd
      // if the account has some settings, change the currency settings
      // basically we'll use whatever the payment processor is set to, otherwise
      // we use the general settings
      if (this.currentAccount) {
        if (this.currentAccount.currency === 'CAD') {
          resp = cad
        } else if (this.currentAccount.currency === 'EUR') {
          resp = eur
        }
      }
      return resp
    }
  },
  mounted () {
    this.fetchMetrics()
    if (this.compare) this.fetchCompareMetrics()
  },
  methods: {
    fetchMetrics () {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchStatistics(
        vm.currentAccount.id, 'ordermetrics', vm.daysBack, vm.search,
        function (err, response) {
          if (err) {
            vm.$store.dispatch('setLoadingState', false)
            vm.handleXhrError(err)
          } else {
            vm.buildMetrics('order', response)
            engineApi.fetchStatistics(
              vm.currentAccount.id, 'campaignmetrics', vm.daysBack, vm.search,
              function (err, response) {
                vm.$store.dispatch('setLoadingState', false)
                if (err) {
                  vm.handleXhrError(err)
                } else {
                  vm.buildMetrics('campaign', response)
                }
              }
            )
          }
        }
      )
    },
    fetchCompareMetrics () {
      var dbo = parseInt(this.daysBack)
      var db2 = dbo + 1 // this is one day less then the current days back (i.e. 30 days back = 31 days back)
      var db1 = dbo * 2 // this is twice the current days back (i.e. 30 days back = 60 days back)
      this.search.dayspans = db1 + ',' + db2
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchStatistics(vm.currentAccount.id, 'ordermetrics', 'dayspans', vm.search,
        function (err, response) {
          if (err) {
            vm.$store.dispatch('setLoadingState', false)
            vm.handleXhrError(err)
          } else {
            vm.buildMetrics('order', response, true)
            engineApi.fetchStatistics(vm.currentAccount.id, 'campaignmetrics', 'dayspans', vm.search,
              function (err, response) {
                vm.$store.dispatch('setLoadingState', false)
                if (err) {
                  vm.handleXhrError(err)
                } else {
                  vm.buildMetrics('campaign', response, true)
                }
              }
            )
          }
        }
      )
    },
    buildMetrics (type, metrics, isCompare) {
      if (metrics) {
        if (metrics.length === 1) {
          metrics = metrics[0]
        }
      }
      if (metrics) {
        if (type === 'order') {
          if (isCompare) {
            this.compareMetric.clicks = metrics.hits ? metrics.hits : 0
            this.compareMetric.conversions = metrics.orders ? metrics.orders : 0
            this.compareMetric.submissions = metrics.submissions ? metrics.submissions : 0
            this.compareMetric.revenue = metrics.total ? metrics.total : 0
          } else {
            this.metric.clicks = metrics.hits ? metrics.hits : 0
            this.metric.conversions = metrics.orders ? metrics.orders : 0
            this.metric.submissions = metrics.submissions ? metrics.submissions : 0
            this.metric.revenue = metrics.total ? metrics.total : 0
          }
        } else if (type === 'campaign') {
          if (isCompare) {
            this.compareMetric.campaigns = metrics.total ? metrics.total : 0
            this.compareMetric.impressions = metrics.impressions ? metrics.impressions : 0
          } else {
            this.metric.campaigns = metrics.total ? metrics.total : 0
            this.metric.impressions = metrics.impressions ? metrics.impressions : 0
          }
        }
      }
    },
    numberFormat (num) {
      if (num) {
        num = parseInt(num)
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return 0
      }
    },
    priceFormat (num) {
      if (num) return this.currencySettings.symbol + num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      else return 'n/a'
    },
    changeFormat (origval, newval) {
      var change = utils.getPercentageChange(origval, newval)
      var r = null
      if (origval === newval && newval > 0) {
        r = '<span class="icon is-small has-text-grey-dark"><i class="fas fa-arrows-alt-h"></i></span><span> No Change</span>'
      } else if (change) {
        if (change < 0) {
          r = '<span class="icon is-small has-text-grey-dark"><i class="fas fa-arrow-down"></i></span><span> '
        } else {
          r = '<span class="icon is-small has-text-grey-dark"><i class="fas fa-arrow-up"></i></span><span> '
        }
        r += Math.abs(change).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%</span>'
      }
      return r
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
.metrics {
  max-width: fit-content;
  margin: 0 auto;
}
.metrics-main-holder {
  display: flex;
  max-width: max-content;
  margin: 0 auto;
}
.metrics-stat-holder {
  text-align: center;
}
.metrics-stat-holder:not(:last-child) {
  margin-right: calc(6vw);
}
.metrics-stat-holder div {
  max-width: fit-content;
  margin: 0 auto;
}
.metrics-number {
  color: $secondary;
  font-weight: 600;
  font-size: 1.5em;
}
.metrics-change {
  font-size: 0.8em;
  white-space: nowrap;
}
.metrics-action-area {
  padding-top: 25px;
  padding-bottom: 60px;
  max-width: fit-content;
  margin: 0 auto;
  text-align: center;
}
.metrics-action-area a {
  font-weight: 600;
  font-size: 0.9em;
}
@media only screen and (min-width : 0px) and (max-width : $mobile-max) {
  .metrics-main-holder {
    display: block!important;
  }
  .metrics-stat-holder:not(:last-child) {
    margin-right: 0!important;
  }
  .metrics-action-area {
    white-space: nowrap;
  }
}
@media only screen and (min-width : 0px) and (max-width : $tablet) {
  .metrics-action-area {
    padding-bottom: 25px;
  }
}
@media only screen and (min-width : $tablet) and (max-width : $desktop) {
  .narrow-tablet .metrics-main-holder {
    display: block!important;
  }
  .narrow-tablet .metrics-stat-holder:not(:last-child) {
    margin-right: 0!important;
  }
  .narrow-tablet .metrics-action-area {
    white-space: nowrap;
  }
  .metrics-action-area {
    padding-bottom: 40px;
  }
}
</style>

<template>
  <div
    :class="{ 'is-active':saveWarning }"
    class="modal">
    <div class="modal-background"/>
    <div class="modal-content">
      <div class="card">
        <div class="card-content">
          <p
            v-if="mode === 'button' && !editPublishedMode"
            class="title">
            Do you want to save a draft of this campaign?
          </p>
          <p
            v-else
            class="title">
            Do you want to save the changes made to this campaign?
          </p>
          <p
            v-if="editPublishedMode"
            class="subtitle">
            Your changes will be lost if you don't complete the wizard.
          </p>
          <p
            v-else-if="mode === 'button'"
            class="subtitle">
            Save your campaign as a draft and return later to schedule it.
          </p>
          <p
            v-else
            class="subtitle">
            Your changes will be lost if you don't save them.
          </p>
        </div>
        <footer class="card-footer">
          <p class="card-footer-item buttons-left">
            <span
              v-if="mode === 'normal'"
              :disabled="currentLoading"
              class="button is-light is-rounded"
              @click="actionDontSave">
              Don't Save
            </span>
          </p>
          <p
            v-if="editPublishedMode"
            class="card-footer-item buttons-right">
            <span
              :class="{ 'is-loading': currentLoading }"
              class="button is-primary is-rounded"
              @click="actionCancel">
              Go Back
            </span>
          </p>
          <p
            v-else
            class="card-footer-item buttons-right">
            <span
              :disabled="currentLoading"
              class="button is-light add-margin-right is-rounded"
              @click="actionCancel">
              Cancel
            </span>
            <span
              :class="{ 'is-loading': currentLoading }"
              class="button is-primary is-rounded"
              @click="actionSave">
              Save and Close
            </span>
          </p>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import xhr from '@/mixins/Xhr.js'
import engineApi from '@/plugins/engine-api.js'
import campaigndefaults from '@/mixins/CampaignDefaults.js'

export default {
  mixins: [xhr, campaigndefaults],
  computed: {
    ...mapGetters({
      saveWarning: 'saveWarning',
      saveWarningMode: 'saveWarningMode',
      currentAccount: 'currentAccount',
      currentSaveAccountId: 'currentSaveAccountId',
      fullWizard: 'fullWizard',
      vuexEditId: 'editId',
      currentLoading: 'currentLoading',
      editPublishedMode: 'editPublishedMode',
      unsplash: 'wizardUnsplashImages'
    }),
    mode: function () {
      // the mode is how the user reached the save draft popup
      if (this.saveWarningMode === 'button') return 'button'
      else return 'normal'
    }
  },
  mounted () {
    // this component is loaded with the main wizard component.
    // we're saving the current account we have to the saveAccount variable.
    this.$store.dispatch('setSaveAccountId', this.currentAccount.id)
  },
  methods: {
    actionDontSave: function () {
      this.$store.dispatch('setSaveWarning', false)
      this.$store.dispatch('setSaveOverride', true)
    },
    actionCancel: function () {
      this.$store.dispatch('setSaveWarning', false)
      this.$store.dispatch('setSaveOverride', false)
      // since the user canceled the save dialog, we need to rollback the selected account
      // to the save account (this assures cancel rolls back a account change from the drop down)
      this.$store.dispatch('setAccount', this.currentSaveAccountId)
    },
    actionSave: function () {
      this.$store.dispatch('setLoadingState', true)
      var draftType = ''
      var draftSubType = ''
      var draftWizardSubtype = null
      var draftTitle = ''
      var draftAction = ''
      var draftLayout = ''
      var draftTheme = ''
      var draftPost = ''
      var draftActionText = ''
      var draftColors = null
      var draftChannels = ''
      var draftSendDate = ''
      var draftSendTime = ''
      var draftPostText = ''
      var draftDescription = ''
      var draftVariables = null
      if (this.fullWizard.wizardDetails) {
        draftType = this.fullWizard.wizardDetails.type
        draftSubType = this.fullWizard.wizardDetails.subtype
        draftTitle = this.generateCampaignTitle(this.fullWizard.wizardDetails)
      }
      if (this.fullWizard.wizardSubtype) {
        draftWizardSubtype = this.fullWizard.wizardSubtype
      }
      if (this.fullWizard.wizardAction) {
        draftAction = this.fullWizard.wizardAction.callToAction
      }
      if (this.fullWizard.wizardTheme) {
        if (this.fullWizard.wizardTheme.currentTheme) {
          draftTheme = this.fullWizard.wizardTheme.currentTheme.title
        }
        draftColors = this.generateCampaignActionText(this.fullWizard.wizardTheme)
      }
      if (this.fullWizard.wizardLandingPage) {
        draftLayout = this.fullWizard.wizardLandingPage.layout
        draftActionText = this.generateCampaignActionText(this.fullWizard.wizardLandingPage)
        if (this.fullWizard.wizardLandingPage.words) {
          draftVariables = this.fullWizard.wizardLandingPage.words.variables
        }
      }
      if (this.fullWizard.wizardPostContent) {
        draftPost = this.generateCampaignPostImage(this.fullWizard.wizardPostContent)
        draftDescription = this.generateCampaignDescription(this.fullWizard.wizardPostContent)
        draftPostText = this.generateCampaignPostText(this.fullWizard.wizardPostContent)
      }
      if (this.fullWizard.wizardSchedule) {
        draftChannels = this.fullWizard.wizardSchedule.channels
        draftSendDate = this.fullWizard.wizardSchedule.date
        draftSendTime = new Date(this.fullWizard.wizardSchedule.time)
        draftSendTime = draftSendTime.getHours() + ':' + draftSendTime.getMinutes() + ':00'
      }
      var vm = this
      engineApi.saveCampaign(
        vm.currentSaveAccountId,
        vm.vuexEditId,
        draftType,
        draftSubType,
        draftWizardSubtype,
        draftTitle,
        draftDescription,
        draftPostText,
        draftActionText,
        draftColors,
        draftAction,
        draftLayout,
        draftTheme,
        draftPost,
        draftChannels,
        draftSendDate,
        draftSendTime,
        0, // 0 is draft status
        draftVariables,
        vm.unsplash,
        vm.fullWizard,
        vm.currentAccount,
        function (err, response) {
          if (err) {
            vm.handleXhrError(err)
          }
          // trigger email channel if turned on
          if (vm.fullWizard.wizardSchedule) {
            if (vm.fullWizard.wizardSchedule.channels) {
              if (vm.fullWizard.wizardSchedule.channels.email) {
                engineApi.triggerChannels('email', vm.currentSaveAccountId, vm.vuexEditId, function () {})
              }
            }
          }
          engineApi.generateSummary(vm.currentSaveAccountId, vm.vuexEditId, function (err, response) {
            if (response) {
              if (response['Payload']) {
                if (typeof response['Payload'] === 'string') {
                  var assetResponse = JSON.parse(response['Payload'])
                  if (assetResponse) {
                    vm.$store.dispatch('setWizardAssets', assetResponse)
                  }
                }
              }
            }
            if (err) {
              vm.handleXhrError(err)
            }
            vm.$store.dispatch('setLoadingState', false)
            vm.$store.dispatch('setNotify', { state: true, text: 'Your marketing campaign is saved as a draft.' })
            vm.$store.dispatch('resetWizardState')
            vm.$store.dispatch('setSaveOverride', true)
            vm.$store.dispatch('setSaveWarning', false)
          })
        }
      )
    }
  }
}
</script>

<style scoped>
.card-footer, .card-footer-item {
  border: none;
}
.buttons-left {
  justify-content: flex-start;
}
.buttons-right {
  justify-content: flex-end;
}
.add-margin-right {
  margin-right: 20px;
}
</style>

<template>
  <div>
    <div class="field">
      <Toggler
        :props="cps.toggle"
        v-model="cps.toggle.model"
        @input="toggleChange" />
    </div>
    <div
      v-if="cps.toggle.model"
      class="field">
      <Toggler
        :props="cps.queueToggle"
        v-model="cps.queueToggle.model"
        @input="toggleChange" />
    </div>
    <div v-if="cps.toggle.model" class="info-area">
      <div class="info-heading">Club Prophet API Settings</div>
      <div class="info-body">Enter your Club Prophet API settings below. Please refer to the help documentation or contact Club Prophet support to obtain any specific settings.</div>
    </div>
    <TextInput
          v-if="cps.toggle.model"
          :props="cps.url"
          v-model="cps.url.model"/>
    <TextInput
          v-if="cps.toggle.model"
          :props="cps.key"
          v-model="cps.key.model"/>
    <div class="columns pos-margin-fix">
      <div class="column is-half-tablet">
        <TextInput
              v-if="cps.toggle.model"
              :props="cps.store"
              v-model="cps.store.model"/>
      </div>
    </div>
    <div class="columns pos-margin-fix">
      <div class="column is-half-tablet">
        <TextInput
              v-if="cps.toggle.model"
              :props="cps.payment"
              v-model="cps.payment.model"/>
      </div>
    </div>
    <div class="columns pos-margin-fix">
      <div class="column is-half-tablet">
        <TextInput
              v-if="cps.toggle.model"
              :props="cps.custclass"
              v-model="cps.custclass.model"/>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half-tablet">
        <TextInput
              v-if="cps.toggle.model"
              :props="cps.custtype"
              v-model="cps.custtype.model"/>
      </div>
    </div>
    <div class="columns">
      <div class="column additional-help-area">
        <a
          href="https://help.campaignpilot.com/how-tos/using-campaignpilot-with-club-prophet"
          target="_blank">
          <div class="icon has-text-primary">
            <i class="fas fa-question-circle"/>
          </div>
          Learn more about how CampaignPilot works with your Club Prophet software and see where to obtain the settings above.
        </a>
      </div>
    </div>
    <a
      class="button is-primary is-rounded"
      @click="save()">
      <span class="icon is-small">
        <i class="fas fa-save"/>
      </span>
      <span>Save Changes</span>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TextInput from '@/components/elements/TextInput'
import Toggler from '@/components/elements/Toggler'
import engineApi from '@/plugins/engine-api.js'
import utils from '@/plugins/utils.js'
import xhr from '@/mixins/Xhr.js'

export default {
  components: {
    TextInput,
    Toggler
  },
  mixins: [xhr],
  data () {
    return {
      cps: {
        url: {
          model: null,
          name: 'url',
          ref: 'url',
          label: 'API Endpoint URL',
          placeholder: 'i.e. s.e.pgapos.com/API.DEMO/POSExpressWS3',
          fieldClass: 'oto-input-field-text-medium'
        },
        key: {
          model: 'fg5v1x6vepx350o475m121u5s5rq0v18',
          name: 'key',
          ref: 'key',
          label: 'API Key',
          fieldClass: 'oto-input-field-text-medium'
        },
        store: {
          model: null,
          name: 'store',
          ref: 'store',
          label: 'Store ID',
          placeholder: 'i.e. 1',
          fieldClass: 'oto-input-field-text-small'
        },
        payment: {
          model: null,
          name: 'payment',
          ref: 'payment',
          label: 'Payment Method ID',
          placeholder: 'i.e. 4',
          fieldClass: 'oto-input-field-text-small'
        },
        custclass: {
          model: null,
          name: 'custclass',
          ref: 'custclass',
          label: 'Customer Class Code',
          placeholder: 'i.e. RES',
          fieldClass: 'oto-input-field-text-small'
        },
        custtype: {
          model: null,
          name: 'custtype',
          ref: 'custtype',
          label: 'Customer Type Code',
          placeholder: 'i.e. C',
          fieldClass: 'oto-input-field-text-small'
        },
        toggle: {
          model: true,
          name: 'cps',
          ref: 'cps',
          options: { checked: 'Enabled', unchecked: 'Disabled' },
          height: 30,
          width: 120,
          extendBody: true,
          label: 'Club Prophet Software Integration',
          mobileHideLabel: true,
          placeholder: 'Enable integration between this CampaignPilot business and your Club Prophet point-of-sale and tee sheet software.'
        },
        queueToggle: {
          model: true,
          name: 'cpsQueue',
          ref: 'cpsQueue',
          options: { checked: 'Enabled', unchecked: 'Disabled' },
          height: 30,
          width: 120,
          extendBody: true,
          label: 'Customer & Order Integration',
          mobileHideLabel: true,
          placeholder: 'Push customer and order information submitted in forms and purchases from CampaignPilot into your Club Prophet database.'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    })
  },
  mounted () {
    this.cps.toggle.model = this.currentAccount.cps_active === 1
    this.cps.url.model = this.currentAccount.cps_url
    this.cps.key.model = this.currentAccount.cps_key
    this.cps.store.model = this.currentAccount.cps_store
    this.cps.payment.model = this.currentAccount.cps_payment
    this.cps.custclass.model = this.currentAccount.cps_custclass
    this.cps.custtype.model = this.currentAccount.cps_custtype
    this.cps.queueToggle.model = this.currentAccount.queue_cps === 1
  },
  methods: {
    toggleChange () {
    },
    save () {
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      this.$store.dispatch('setLoadingState', true)
      var cps = {
        toggle: utils.convertToBinary(this.cps.toggle.model),
        url: this.cps.url.model,
        key: this.cps.key.model,
        store: this.cps.store.model,
        payment: this.cps.payment.model,
        custclass: this.cps.custclass.model,
        custtype: this.cps.custtype.model,
        queueToggle: utils.convertToBinary(this.cps.queueToggle.model)
      }
      var updates = {
        account_id: this.currentAccount.id,
        cps: cps
      }
      var vm = this
      engineApi.saveAccount('cps', updates,
        function (err, response) {
          vm.$store.dispatch('setAccount', vm.currentAccount.id) // refresh vuex account
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (process.env.NODE_ENV === 'development') console.log('saved...')
            vm.$store.dispatch('setNotify', { state: true, text: 'Your Club Prophet software integration settings have been updated.' })
          }
        }
      )
    }
  }
}
</script>

<style scoped>
  .pos-margin-fix {
    margin-bottom: -25px;
  }
  .additional-help-area {
    padding-top: 0px;
  }
  .info-area {
    margin-bottom: 1rem;
  }
  .info-area .info-heading {
    font-weight: 700;
  }
</style>

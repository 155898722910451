<template>
  <section class="section">
    <div
      v-if="tagsToFilter.length === 0"
      class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title">
            What are you promoting?
          </h1>
          <h2 class="subtitle">
            Click a category or tag below to narrow down the list of available campaigns.
          </h2>
        </div>
      </div>
      <div v-if="topTags.length > 0">
        <div id="campaign-select-find">
          <div class="subtitle-w-bar">Find A Campaign</div>
          <div class="columns">
            <div class="column">
              <vue-autosuggest
                :suggestions="autoFilteredOptions"
                :input-props="{id:'autosuggest__input', placeholder:'Search for campaigns', autocomplete:'off'}"
                @input="autoOnInputChange"
                @selected="autoSelectHandler"
                class="auto-suggest-campaign-wizard absolute-version">
                <template slot-scope="{suggestion}">
                  <span class="oto-auto-suggestion-item">{{ suggestion.item.name }}</span>
                </template>
              </vue-autosuggest>
            </div>
          </div>
        </div>
        <div id="campaign-select-popular">
          <div class="subtitle-w-bar">Popular Categories</div>
          <div class="columns is-multiline">
            <div
              v-for="(category, index) in categories"
              :key="'cat-' + index"
              class="column is-full-mobile is-one-quarter-tablet is-one-quarter-desktop">
              <div
                class="oto-campaign-card oto-hover"
                @click="resetFilter(category.tag)">
                <div class="oto-campaign-card-content">
                  <div class="has-text-centered">
                    <span class="icon is-medium">
                      <font-awesome-icon
                        :icon="category.icon"
                        class="fa-lg" />
                    </span>
                  </div>
                  <div class="content is-marginless">
                    <p class="card-header-title is-centered is-paddingless is-marginless has-text-primary">{{ category.title }}</p>
                    <p class="has-text-black">{{ category.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="campaign-select-tags">
          <div class="subtitle-w-bar">Tags</div>
          <div class="columns is-multiline">
            <div
              v-for="(tag, index) in topTags"
              :key="'hl-' + index"
              :id="tag.tag ? 'tag-' + tag.tag.replace(' ','-') : null"
              class="column is-full-mobile is-one-quarter-tablet is-one-quarter-desktop"
              @click="resetFilter(tag.tag)">
              <div class="oto-box oto-hover">
                <div class="title-holder is-capitalized">{{ tag.tag }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title">
            What are you promoting?
          </h1>
          <h2 class="subtitle">
            Select a campaign to get started.
          </h2>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="tag-holder">
            <div
              v-for="(tag, index) in tagsToFilter"
              :key="'t-' + index">
              <div class="tags has-addons">
                <a
                  class="tag is-link is-lowercase is-large is-rounded"
                  @click="resetFilter(tag)">
                  {{ tag }}
                </a>
                <a
                  class="tag is-delete is-link is-large is-rounded"
                  @click="removeFromFilter(tag)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="filteredCampaigns.length > 1"
        class="columns">
        <div class="column">
          <div class="buttons">
            <span
              v-for="(tag, index) in tagsFromFiltered"
              :key="'t2-' + index"
              :class="{'is-primary is-outlined': (index < 2 && tagsToFilter.length === 1)}"
              class="button is-rounded is-lowercase"
              @click="addToFilter(tag)">
              {{ tag }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="filteredCampaigns.length > 0"
        class="columns">
        <div class="column">
          <div class="columns is-multiline">
            <div
              v-for="(campaign, index) in filteredCampaigns"
              :key="'c-' + index"
              class="column is-full-mobile is-one-quarter-tablet is-one-quarter-desktop campaign-holder">
              <div
                v-if="currentUser.superadmin === 1 && currentUser.superdesigner === 1"
                class="delete-campaign-template">
                <a
                  :class="{'is-warning': deletedCampaigns.includes(campaign.id)}"
                  class="button is-light is-rounded"
                  @click="toggleCampaignTemplate(campaign.id)">
                  <span class="icon">
                    <i class="fas fa-trash-alt"/>
                  </span>
                </a>
              </div>
              <div
                v-if="currentUser.superadmin === 1 && currentUser.superdesigner === 1"
                class="order-campaign-template">
                <input
                  class="input order-input"
                  type="text"
                  v-model="campaign.sortorder"
                  @change="changeOrder(campaign.id, campaign.sortorder)" />
              </div>
              <div
                :id="'camp-template-' + campaign.id"
                class="oto-campaign-card oto-hover"
                @click="selectCampaign(campaign.id)">
                <div class="oto-campaign-card-content">
                  <div class="has-text-centered">
                    <span class="icon is-medium">
                      <font-awesome-icon
                        :icon="campaign.icon"
                        class="fa-lg" />
                    </span>
                  </div>
                  <div class="content is-marginless">
                    <p class="card-header-title is-centered is-paddingless is-marginless has-text-primary">{{ campaign.title }}</p>
                    <p class="has-text-black">{{ campaign.description }}</p>
                  </div>
                </div>
                <footer class="card-footer">
                  <p class="oto-campaign-card-footer-item">
                    <span
                      v-for="(tag, index) in campaign.tags"
                      :key="'tf-' + index"
                      class="tag is-white">
                      {{ tag }}
                    </span>
                  </p>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import tags from '@/mixins/Tags.js'
import tour from '@/mixins/Tour.js'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    VueAutosuggest
  },
  mixins: [xhr, tags, tour],
  data () {
    return {
      topTags: [],
      tagsToFilter: [],
      tagLimit: 30,
      tourCampaignId: 25,
      filteredCampaigns: [],
      tagsFromFiltered: [],
      campaigns: [],
      autoFilteredOptions: [],
      autoSuggestions: [ { data: [] } ],
      popular: [
        {
          title: 'Mother\'s Day',
          tag: 'mother\'s day',
          icon: 'spa',
          description: 'Celebrate mom this year with our campaigns built for Mother\'s Day',
          subs: [ 'mother\'s day', 'holiday', 'events' ]
        },
        {
          title: 'Course Opening',
          tag: 'course opening',
          icon: 'flag',
          description: 'Season starting? Let your customers know.',
          subs: [ 'golf course', 'tee times', 'opening', 'season pass' ]
        },
        {
          title: 'Golf',
          tag: 'golf',
          icon: 'golf-ball',
          description: 'Go for the green with golf campaigns.',
          subs: [ 'golf', 'tee times', 'events' ]
        },
        {
          title: 'Food',
          tag: 'food',
          icon: 'fish',
          description: 'Weather got you down? Cook up something nice instead!',
          subs: [ 'restaurant', 'holiday', 'fish fry' ]
        }
      ],
      categories: [
        {
          title: 'Holiday',
          tag: 'holiday',
          icon: 'gifts',
          description: 'Promote your business during an upcoming holiday.',
          subs: [ 'valentine\'s day', 'st. patrick\'s day', 'easter' ]
        },
        {
          title: 'Tee Times',
          tag: 'tee times',
          icon: 'golf-ball',
          description: 'Marketing campaigns perfect for pushing tee times.',
          subs: [ 'weekly specials', 'member discounts', 'slow days' ]
        },
        {
          title: 'Event',
          tag: 'event',
          icon: 'glass-cheers',
          description: 'Promote your next event with CampaignPilot and sell tickets.',
          subs: [ 'outings', 'tickets', 'registration' ]
        },
        {
          title: 'Sale',
          tag: 'sale',
          icon: 'dollar-sign',
          description: 'Have a hot item to sell? Promote it online.',
          subs: [ 'gift cards', 'golf packages', 'lessons', 'range passes' ]
        }
      ],
      deletedCampaigns: []
    }
  },
  computed: {
    mainTag: function () {
      return this.tagsToFilter[0]
    },
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  watch: {
    mainTag (val) {
      if (val) {
        // our main tag changed, we need to pull campaigns from server
        this.fetchCampaigns(val)
      }
    },
    'topTags.length' (val) {
      // Kickoff the Tour
      if (val > 0) if (this.guideMode) this.TourCampaignLibrary({ showButtons: true })
    },
    'tagsToFilter.length' (val, oval) {
      // Stop the Tour once the user selects a tag
      if (val > 0 && oval === 0) if (this.guideMode) this.TourClose()
    },
    'filteredCampaigns.length' (val) {
      // Continue Tour once we load some campaigns
      if (val > 0) if (this.guideMode) this.TourCampaignLibraryTemplate(this.tourCampaignId)
    }
  },
  mounted () {
    // always reset the wizard when this section loads
    this.$store.dispatch('resetWizardState')
    // load in tags from API
    this.fetchTags('top') // used for opening tags
    this.fetchTags('all') // used for autosuggest
    if (this.$route.query.tag) this.resetFilter(decodeURIComponent(this.$route.query.tag))
  },
  methods: {
    fetchTags (mode) {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchTags('campaign', mode,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (mode === 'top') vm.topTags = response
            if (mode === 'all') {
              response.forEach(function (tag, index) {
                vm.autoSuggestions[0].data.push({ id: index, name: tag.tag })
              })
            }
          }
        }
      )
    },
    fetchCampaigns (tag) {
      // fetch public campaigns from server
      this.campaigns = []
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchCampaigns(
        null, tag, 1, null,
        function (err, response) {
          if (err) {
            vm.$store.dispatch('setLoadingState', false)
            vm.handleXhrError(err)
          } else {
            response.forEach(function (campaign) {
              campaign.tags = campaign.tags.split(',')
              vm.campaigns.push(campaign)
            })
            setTimeout(function () {
              vm.$store.dispatch('setLoadingState', false)
              vm.filterCampaigns()
            }, 500)
          }
        }
      )
    },
    fetchCampaign (id) {
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchCampaign(
        null, id,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            // send google analytics event
            if (process.env.NODE_ENV !== 'development') window.gtag('event', 'campaign_action', { 'action': 'selected', 'campId': id })
            response = JSON.parse(response)
            if (response.campaign) vm.$store.dispatch('saveSection', { name: 'wizardDetails', value: response.campaign })
            if (response.action) vm.$store.dispatch('saveSection', { name: 'wizardAction', value: response.action })
            if (response.theme) vm.$store.dispatch('saveSection', { name: 'wizardTheme', value: response.theme })
            if (response.form) vm.$store.dispatch('setWizardSuggestedForm', response.form)
            vm.$store.dispatch('setWizardStartMode', 'template')
            vm.$router.push({ name: 'route-engine-campaigns-wizard-campaign-wizard' })
          }
        }
      )
    },
    resetFilter (tag) {
      // tag is optional.
      // if passed it will reset the filter to the single passed tag.
      this.tagsToFilter = []
      if (tag) {
        this.addToFilter(tag)
      }
    },
    addToFilter (tag) {
      // tag is required.
      // adds single passed tag to filter.
      this.tagsToFilter.push(tag)
      this.filterCampaigns()
    },
    removeFromFilter (tag) {
      // tag is required
      // removes a single passed tag from the filer
      this.tagsToFilter = this.tagsToFilter.filter((el) => !tag.includes(el))
      this.filterCampaigns()
    },
    filterCampaigns () {
      // runs the component saved filter against the current campaigns
      this.filteredCampaigns = this.searchByTags(this.tagsToFilter, this.campaigns)[0]
      this.tagsFromFiltered = this.removeTagsFromArray(this.tagsToFilter, this.uniqueTags(this.filteredCampaigns, this.tagLimit))
    },
    selectCampaign (id) {
      this.fetchCampaign(id)
    },
    autoOnInputChange (text, oldText) {
      if (text === '') {
        this.autoFilteredOptions = [{ data: [] }]
        return
      }
      var filteredData = this.autoSuggestions[0].data.filter(option => {
        return option.name.toLowerCase().indexOf(text.toLowerCase()) > -1
      })
      this.autoFilteredOptions = [{ data: filteredData }]
    },
    autoSelectHandler (item) {
      this.autoFilteredOptions = [{ data: [] }]
      this.resetFilter(item.item.name.toLowerCase())
    },
    toggleCampaignTemplate (id) {
      var vm = this
      engineApi.toggleCampaignTemplate(id,
        function (err, response) {
          if (err) {
            vm.handleXhrError(err)
          } else {
            // vm.resetFilter(vm.tagsToFilter[0])
            // vm.fetchCampaigns(vm.tagsToFilter[0])
            if (vm.deletedCampaigns.includes(id)) {
              var i = vm.deletedCampaigns.indexOf(id)
              if (i > -1) {
                vm.deletedCampaigns.splice(i, 1)
              }
            } else {
              vm.deletedCampaigns.push(id)
            }
          }
        }
      )
    },
    changeOrder (id, order) {
      var vm = this
      engineApi.orderCampaignTemplate(id, order,
        function (err, response) {
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.filterCampaigns()
          }
        }
      )
    }
  }
}
</script>

<style>
.campaign-holder {
  display: inline-block;
  position: relative;
}
.delete-campaign-template {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px 17px 0 0;
  z-index: 10;
}
.delete-campaign-template > .button {
  height: 1.25rem!important;
}
.order-campaign-template {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 17px 15px 0;
  z-index: 10;
}
.order-input {
  font-size: 0.75rem!important;
  max-width: 40px;
  width: 40px;
}
</style>

<template>
  <router-view/>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    })
  },
  mounted () {
    this.$store.dispatch('setAccount', this.currentAccount.id)
    this.$store.dispatch('fetchChannels', this.currentAccount.id)
  }
}
</script>

<template>
  <div
    :class="{ 'is-active': unsplash.modalOpen }"
    class="modal">
    <div class="modal-background"/>
    <div class="modal-content">
      <div class="card">
        <div class="card-content">
          <p class="title">
            <img src="../../assets/imgs/other/unsplash-logo.png">
          </p>
          <div class="columns is-mobile">
            <div class="column is-half">
              <div class="field">
                <div class="control has-icons-left">
                  <input
                    v-model="searchTerm"
                    type="text"
                    class="input"
                    placeholder="Search for images"
                    v-on:keyup.enter="newSearch()">
                  <span class="icon is-small is-left">
                    <i class="fas fa-search"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="column is-half has-text-right">
              <p
                v-if="totalPages > 1"
                class="buttons buttons-to-the-right">
                <button
                  class="button is-rounded"
                  :disabled="page === 1"
                  @click="back">
                  <span class="icon is-small mobile-button-icon-fix">
                    <i class="fas fa-arrow-left"></i>
                  </span>
                  <span class="is-hidden-mobile">Back</span>
                </button>
                <button
                  class="button is-rounded"
                  :disabled="page === totalPages"
                  @click="next">
                  <span class="icon is-small mobile-button-icon-fix">
                    <i class="fas fa-arrow-right"></i>
                  </span>
                  <span class="is-hidden-mobile">More</span>
                </button>
              </p>
            </div>
          </div>
          <div class="results-section">
            <progress
              v-if="isLoading"
              class="progress is-primary is-marginless unsplash-progress"
              max="100"/>
            <div v-else class="unsplash-progress-empty-block"/>
            <stack
              v-if="photos.length > 0"
              :column-min-width="150"
              :gutter-width="1"
              :gutter-height="1"
              :monitor-images-loaded="true">
              <stack-item
                v-for="(photo, index) in photos"
                :key="'si' + index">
                <div
                  v-if="photo.urls"
                  class="stack-image-holder stack-image-hover">
                  <img
                    :src="photo.urls.thumb"
                    @click="selectPhoto(index)">
                  <div
                    v-if="photo.user"
                    class="unsplash-credit">
                    Photo by
                    <a
                      :href="generateCreditHref(photo.user)"
                      class="credit-link"
                      target="_blank">{{photo.user.name}}</a>
                  </div>
                </div>
              </stack-item>
            </stack>
          </div>
        </div>
        <footer class="card-footer">
          <a
            class="card-footer-item"
            @click="close()">
            Cancel
          </a>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Stack, StackItem } from 'vue-stack-grid'
import channelApi from '@/plugins/channel-api.js'
import xhr from '@/mixins/Xhr.js'

export default {
  components: {
    Stack,
    StackItem
  },
  props: {
    unsplash: {
      type: Object,
      default: null
    }
  },
  mixins: [xhr],
  data () {
    return {
      isLoading: false,
      searchTerm: null,
      numResults: 10,
      page: 1,
      totalPages: 0,
      totalPhotos: 0,
      photos: [],
      currentlyUsedImages: []
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      vuexUnsplashImages: 'wizardUnsplashImages'
    })
  },
  watch: {
    page (val) {
      this.searchPhotos()
    },
    'unsplash.modalOpen' (val) {
      if (val) this.open()
    },
    'unsplash.searchTerm' (val) {
      if (val) {
        this.searchTerm = val
        this.newSearch()
      }
    }
  },
  mounted () {
    if (this.vuexUnsplashImages) this.currentlyUsedImages = this.$lodash.cloneDeep(this.vuexUnsplashImages)
    // since this is a modal, we're going to move it to an outside div
    document.body.appendChild(this.$el)
  },
  destroyed () {
    // destroy what we appended, the append functions more like vue then
    document.body.removeChild(this.$el)
  },
  methods: {
    open: function () {
      this.unsplash.modalOpen = true
      this.newSearch()
    },
    close: function () {
      this.unsplash.modalOpen = false
    },
    newSearch: function () {
      if (this.searchTerm) {
        this.page = 1
        this.totalPages = 0
        this.totalPhotos = 0
        this.photos = []
        if (this.unsplash) if (this.unsplash.numResults) this.numResults = this.unsplash.numResults
        this.searchPhotos()
      }
    },
    back: function () {
      if (this.page > 1) this.page--
    },
    next: function () {
      if (this.page < this.totalPages) this.page++
    },
    generateCreditHref: function (user) {
      if (user) return 'https://unsplash.com/@' + user.username + '?utm_source=campaignpilot&utm_medium=referral'
      return null
    },
    selectPhoto: function (photoIndex) {
      var selectedPhoto = (this.photos.length > photoIndex) ? this.photos[photoIndex] : null
      if (selectedPhoto) {
        this.currentlyUsedImages.push(selectedPhoto)
        this.$store.dispatch('setWizardUnsplashImages', this.currentlyUsedImages)
        this.$emit('input', selectedPhoto)
        this.close()
      }
    },
    searchPhotos: function () {
      var vm = this
      vm.isLoading = true
      channelApi.searchUnsplashPhotos(vm.currentAccount.id, vm.searchTerm, vm.page, vm.numResults,
        function (err, photos) {
          vm.isLoading = false
          if (err) {
            vm.handleXhrError(err)
          } else {
            if (photos) {
              vm.totalPhotos = photos.total
              vm.totalPages = photos.total_pages
              vm.photos = photos.results
            }
          }
        }
      )
    }
  }
}
</script>

<style scoped>
  .results-section {
    min-height: calc(80vh - 300px);
    max-height: calc(80vh - 300px);
    overflow-y: scroll;
  }
  .stack-image-holder {
    display: inline-block;
    position: relative;
  }
  .stack-image-holder > img {
    display: block;
  }
  .unsplash-credit {
    font-size: 0.7em;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    margin: 5px 5px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.25s linear;
    border-radius: 4px;
    padding: 2px;
  }
  .buttons-to-the-right {
    float: right
  }
  .unsplash-progress {
    border-radius: 0px;
    height: 10px;
  }
  .unsplash-progress-empty-block {
    display: block;
    height: 10px;
    min-height: 10px;
    padding: 0;
    margin: 0;
  }
  .stack-image-hover {
    cursor: hand;
    cursor: pointer;
  }
  .stack-image-hover:hover > .unsplash-credit {
    visibility: visible;
    opacity: 1;
  }
  a.credit-link:hover, a.credit-link:visited {
    color: white;
  }
  .card-content > .title > img {
   max-height: 28px;
  }
  .mobile-button-icon-fix {
    margin-left: 0!important;
  }
</style>

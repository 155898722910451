<template>
  <div>
    <div class="columns">
      <div class="column is-half-desktop">
        <div
          v-if="error"
          class="notification is-danger">
          {{ error }}
        </div>
        <div
          v-if="confirm"
          class="notification is-success">
          {{ confirm }}
        </div>
        <div class="field has-text-left">
          <label class="label">Business Name</label>
          <div class="control">
            <input
              v-validate="{ required: true }"
              id="name"
              ref="name"
              key="name-input"
              v-model="name"
              :class="{ 'is-danger': errors.has('name') }"
              class="input is-medium"
              name="name"
              type="text"
              placeholder="Pine Valley Golf Course and Spa">
          </div>
        </div>
        <div class="field has-text-left">
          <label class="label">Business Email Address</label>
          <div class="control">
            <input
              v-validate="{ required: true }"
              id="email"
              ref="email"
              key="email-input"
              v-model="email"
              :class="{ 'is-danger': errors.has('email') }"
              class="input is-medium"
              name="email"
              type="email"
              placeholder="contact.us@domain.com">
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <a
          class="button is-primary is-rounded"
          :disabled="currentLoading"
          @click="createBusiness">
          <span class="icon is-small">
            <i class="fas fa-building"/>
          </span>
          <span>Create Business</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'

export default {
  mixins: [xhr],
  data () {
    return {
      error: null,
      confirm: null,
      name: null,
      email: null
    }
  },
  computed: {
    ...mapGetters({
      currentLoading: 'currentLoading'
    })
  },
  methods: {
    createBusiness () {
      this.error = null
      var vm = this
      this.$validator.validateAll().then(result => {
        if (result) {
          vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
          vm.$store.dispatch('setLoadingState', true)
          engineApi.createAccount(
            vm.name, vm.email,
            function (err, response) {
              vm.$store.dispatch('setLoadingState', false)
              if (err) {
                vm.handleXhrError(err)
                vm.error = 'There is a problem creating this business.'
              }
              if (response) {
                vm.$store.dispatch('fetchAccounts')
                vm.confirm = vm.name + ' has been created. In just a moment we\'ll switch to that account to complete additional settings.'
                vm.error = null
                vm.name = null
                vm.email = null
                if (response.id) {
                  vm.switchAccount(response.id)
                }
              }
            }
          )
        } else {
          // form has errors do nothing
          vm.error = 'There is a problem creating this business account. Please check below for any errors or missing fields.'
        }
      })
    },
    switchAccount (accountId) {
      this.$store.dispatch('setLoadingState', true)
      this.$store.dispatch('setAccount', accountId)
      var vm = this
      setTimeout(function () {
        vm.$store.dispatch('setLoadingState', false)
        vm.$router.push({ name: 'route-engine-settings' })
      }, 5000)
    }
  }
}
</script>

<template>
  <click-outside :handler="clickedOutside">
    <div
      :class="{ 'is-active': state }"
      class="dropdown">
      <div class="dropdown-trigger">
        <button
          class="button swatch-select"
          @click="toggle">
          <div
            v-if="swatch"
            class="columns show-hand is-centered is-mobile is-size-6">
            <div
              :style="{ backgroundColor: swatch.colors.primary }"
              :class="{ 'light-border': swatch.colors.primary === '#ffffff' }"
              class="column is-narrow circle"/>
            <div
              :style="{ backgroundColor: swatch.colors.secondary }"
              :class="{ 'light-border': swatch.colors.secondary === '#ffffff' }"
              class="column is-narrow circle"/>
            <div
              :style="{ backgroundColor: swatch.colors.alternate }"
              :class="{ 'light-border': swatch.colors.alternate === '#ffffff' }"
              class="column is-narrow circle"/>
          </div>
          <span
            v-else
            class="empty-picker">
            &nbsp;
          </span>
        </button>
      </div>
      <div class="dropdown-menu">
        <div class="dropdown-content swatch-popup animated fadeIn">
          <div
            v-for="swatch in swatches"
            :key="swatch.id"
            class="content">
            <div class="dropdown-item">
              <div
                class="columns swatch-row show-hand is-centered is-mobile is-size-6"
                @click="selectSwatch(swatch)">
                <div
                  :style="{ backgroundColor: swatch.colors.primary }"
                  :class="{ 'light-border': swatch.colors.primary === '#ffffff' }"
                  class="column is-narrow circle"/>
                <div
                  :style="{ backgroundColor: swatch.colors.secondary }"
                  :class="{ 'light-border': swatch.colors.secondary === '#ffffff' }"
                  class="column is-narrow circle"/>
                <div
                  :style="{ backgroundColor: swatch.colors.alternate }"
                  :class="{ 'light-border': swatch.colors.alternate === '#ffffff' }"
                  class="column is-narrow circle"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </click-outside>
</template>

<script>
import { mapGetters } from 'vuex'
import ClickOutside from '@/plugins/onclick-outside'
import xhr from '@/mixins/Xhr.js'

export default {
  components: {
    ClickOutside
  },
  mixins: [xhr],
  props: {
    props: {
      type: Object,
      default: null
    },
    selectId: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      state: false,
      swatch: null,
      swatches: [
        { id: 7,
          title: '',
          colors:
           { primary: '#131313',
             alternate: '#980000',
             secondary: '#c25200',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 26,
          title: '',
          colors:
           { primary: '#9d0e0e',
             alternate: '#228424',
             secondary: '#332f29',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 32,
          title: '',
          colors:
           { primary: '#720000',
             alternate: '#354143',
             secondary: '#bd7400',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 50,
          title: '',
          colors:
           { primary: '#2c2c2c',
             alternate: '#1f5069',
             secondary: '#ca4e2f',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 51,
          title: '',
          colors:
           { primary: '#222222',
             alternate: '#1a1e6b',
             secondary: '#cd3c80',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 100,
          title: 'Black',
          colors:
           { primary: '#000000',
             alternate: '#ffffff',
             secondary: '#7a7a7a',
             primaryFont: '#ffffff',
             alternateFont: '#000000',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 24,
          title: '',
          colors:
           { primary: '#bc1f18',
             alternate: '#286807',
             secondary: '#009ddb',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 30,
          title: '',
          colors:
           { primary: '#8f0d00',
             alternate: '#2c2d28',
             secondary: '#b88c14',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 40,
          title: '',
          colors:
           { primary: '#bd2b15',
             alternate: '#0a807e',
             secondary: '#5e9d0b',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 9,
          title: '',
          colors:
           { primary: '#562b17',
             alternate: '#5b5a08',
             secondary: '#9c6d1c',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 20,
          title: '',
          colors:
           { primary: '#592e1a',
             alternate: '#2d3d66',
             secondary: '#8f7228',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 46,
          title: '',
          colors:
           { primary: '#c5480a',
             alternate: '#027e9d',
             secondary: '#279ea0',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 35,
          title: '',
          colors:
           { primary: '#a55d30',
             alternate: '#434342',
             secondary: '#7b9426',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 15,
          title: '',
          colors:
           { primary: '#976c18',
             alternate: '#272727',
             secondary: '#b10d18',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 57,
          title: '',
          colors:
           { primary: '#b4860b',
             alternate: '#81960f',
             secondary: '#156376',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 29,
          title: '',
          colors:
           { primary: '#927c3a',
             alternate: '#2a8e71',
             secondary: '#152f1a',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 33,
          title: '',
          colors:
           { primary: '#2c3e01',
             alternate: '#010101',
             secondary: '#cc1616',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 43,
          title: '',
          colors:
           { primary: '#5c7e0e',
             alternate: '#15808a',
             secondary: '#560000',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 59,
          title: '',
          colors:
           { primary: '#455325',
             alternate: '#005193',
             secondary: '#996e3d',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 19,
          title: '',
          colors:
           { primary: '#4b6027',
             alternate: '#47302e',
             secondary: '#a3690a',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 22,
          title: '',
          colors:
           { primary: '#597826',
             alternate: '#b06000',
             secondary: '#1f1f43',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 44,
          title: '',
          colors:
           { primary: '#4c7019',
             alternate: '#00788e',
             secondary: '#9a9b2e',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 65,
          title: '',
          colors:
           { primary: '#294b00',
             alternate: '#00547b',
             secondary: '#b45a71',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 37,
          title: '',
          colors:
           { primary: '#1c591b',
             alternate: '#455e45',
             secondary: '#329500',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 61,
          title: '',
          colors:
           { primary: '#4d8059',
             alternate: '#5c7a8a',
             secondary: '#7f6324',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 8,
          title: '',
          colors:
           { primary: '#005539',
             alternate: '#85713d',
             secondary: '#c02320',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 48,
          title: '',
          colors:
           { primary: '#245865',
             alternate: '#2c4834',
             secondary: '#9b5aba',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 39,
          title: '',
          colors:
           { primary: '#017fa2',
             alternate: '#759515',
             secondary: '#e07939',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 38,
          title: '',
          colors:
           { primary: '#0b7fad',
             alternate: '#b649a6',
             secondary: '#082d44',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 31,
          title: '',
          colors:
           { primary: '#014561',
             alternate: '#a73e17',
             secondary: '#598033',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 10,
          title: '',
          colors:
           { primary: '#31505e',
             alternate: '#a4181c',
             secondary: '#917021',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 54,
          title: '',
          colors:
           { primary: '#377ba0',
             alternate: '#2f7668',
             secondary: '#ca7a12',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 56,
          title: '',
          colors:
           { primary: '#014369',
             alternate: '#160f6f',
             secondary: '#318187',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 12,
          title: '',
          colors:
           { primary: '#314552',
             alternate: '#434036',
             secondary: '#9b6a29',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 64,
          title: '',
          colors:
           { primary: '#0473cd',
             alternate: '#233ccd',
             secondary: '#223f01',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 60,
          title: '',
          colors:
           { primary: '#385e87',
             alternate: '#615241',
             secondary: '#c14e7c',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 13,
          title: '',
          colors:
           { primary: '#011e49',
             alternate: '#c62824',
             secondary: '#056f80',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 58,
          title: '',
          colors:
           { primary: '#0136af',
             alternate: '#c30d07',
             secondary: '#8f815a',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 17,
          title: '',
          colors:
           { primary: '#183c91',
             alternate: '#a0181c',
             secondary: '#5d7d26',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 14,
          title: '',
          colors:
           { primary: '#012380',
             alternate: '#246995',
             secondary: '#cc1616',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 28,
          title: '',
          colors:
           { primary: '#061b62',
             alternate: '#3d3733',
             secondary: '#0679d0',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 4,
          title: '',
          colors:
           { primary: '#04187a',
             alternate: '#b4a17c',
             secondary: '#b30610',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 42,
          title: '',
          colors:
           { primary: '#2c3065',
             alternate: '#324b29',
             secondary: '#e02469',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 41,
          title: '',
          colors:
           { primary: '#060673',
             alternate: '#630f64',
             secondary: '#8f7949',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 45,
          title: '',
          colors:
           { primary: '#000088',
             alternate: '#cc0000',
             secondary: '#4186a2',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 25,
          title: '',
          colors:
           { primary: '#161268',
             alternate: '#820000',
             secondary: '#988860',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 2,
          title: '',
          colors:
           { primary: '#8d5ec4',
             alternate: '#F2C511',
             secondary: '#157fd0',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 62,
          title: '',
          colors:
           { primary: '#410c62',
             alternate: '#025447',
             secondary: '#048B76',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 6,
          title: '',
          colors:
           { primary: '#8f3ca9',
             alternate: '#ee83b0',
             secondary: '#80c49f',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 21,
          title: '',
          colors:
           { primary: '#4d3850',
             alternate: '#693333',
             secondary: '#b05463',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 53,
          title: '',
          colors:
           { primary: '#9c4493',
             alternate: '#c73d72',
             secondary: '#34441e',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 1,
          title: '',
          colors:
           { primary: '#AF5B8A',
             alternate: '#ad054e',
             secondary: '#391577',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 27,
          title: '',
          colors:
           { primary: '#e02481',
             alternate: '#6556b8',
             secondary: '#701239',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 52,
          title: '',
          colors:
           { primary: '#671b3d',
             alternate: '#207baf',
             secondary: '#c24c83',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 5,
          title: '',
          colors:
           { primary: '#BC2964',
             alternate: '#C09D3B',
             secondary: '#72A4A0',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 47,
          title: '',
          colors:
           { primary: '#650f2f',
             alternate: '#272727',
             secondary: '#e02469',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 16,
          title: '',
          colors:
           { primary: '#8e0222',
             alternate: '#b54217',
             secondary: '#4a5422',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 34,
          title: '',
          colors:
           { primary: '#d7375a',
             alternate: '#008573',
             secondary: '#612733',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 49,
          title: '',
          colors:
           { primary: '#bf0325',
             alternate: '#917050',
             secondary: '#4e000f',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 18,
          title: '',
          colors:
           { primary: '#9a0117',
             alternate: '#3c4169',
             secondary: '#897443',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 55,
          title: '',
          colors:
           { primary: '#ec0e28',
             alternate: '#46a22f',
             secondary: '#3586c8',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 36,
          title: '',
          colors:
           { primary: '#9c040c',
             alternate: '#314717',
             secondary: '#d38203',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 },
        { id: 63,
          title: '',
          colors:
           { primary: '#7c1215',
             alternate: '#25271d',
             secondary: '#6b8500',
             primaryFont: '#ffffff',
             alternateFont: '#ffffff',
             secondaryFont: '#ffffff' },
          active: 1 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    })
  },
  watch: {
    swatch (val) {
      this.$emit('input', val)
    },
    selectId (val) {
      this.selectSwatch(val)
    }
  },
  mounted: function () {
    // load in account colors if we have them
    var acctColors = this.currentAccount.style_colors
    if (acctColors) acctColors = JSON.parse(acctColors)
    if (acctColors) {
      this.swatches.unshift({
        'id': '999',
        'name': 'account',
        'colors': {
          'primary': acctColors.primary,
          'primaryFont': acctColors.primaryFont,
          'secondary': acctColors.secondary,
          'secondaryFont': acctColors.secondaryFont,
          'alternate': acctColors.alternate,
          'alternateFont': acctColors.alternateFont
        }
      })
    }
    // inject swatch if passed one via props,
    // for example a theme passes in a custom swatch
    if (this.props) {
      this.swatch = {
        'id': '000',
        'name': 'theme',
        'colors': {
          'primary': this.props.primary.toString(),
          'primaryFont': this.props.primaryFont.toString(),
          'secondary': this.props.secondary.toString(),
          'secondaryFont': this.props.secondaryFont.toString(),
          'alternate': this.props.alternate.toString(),
          'alternateFont': this.props.alternateFont.toString()
        }
      }
      this.swatches.unshift(this.swatch) // add swatch to front
    }
    // select the swatch
    if (this.selectId) this.selectSwatch(this.selectId)
  },
  methods: {
    toggle () {
      this.state = !this.state
    },
    selectSwatch (swatch) {
      if (swatch) {
        var id = swatch
        if (swatch.id) id = swatch.id
        this.swatch = this.$lodash.filter(this.swatches, { id: id })[0]
        this.state = false
      }
    },
    clickedOutside () {
      if (this.state) {
        this.state = false
      }
    }
  }
}
</script>

<style scoped>
.dropdown-content {
  min-width: 178px;
}
.swatch-popup {
  height: 180px;
  overflow-y: scroll;
}
.swatch-row:hover {
  background-color: #f4f4f4;
}
.empty-picker {
  margin-top: -5px;
}
.swatch-select {
  height: 45px;
  width: 180px;
  padding: 0px 20px 0px 0px!important;
}
.dropdown-menu {
  min-width: 0px;
  width: 160px;
  z-index: 999;
}
.swatch-row {
  display: inline-flex;
  padding-left: 4px;
}
.swatch-select::after{
  border: 3px solid #037dd3;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.7em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 0.7em;
}
</style>

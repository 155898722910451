<template>
  <div class="checklist">
    <div v-if="warningPayments" class="checklist-item-holder">
      <div class="checklist-item">
        <div>Campaigns that sell directly through CampaignPilot average over $4,000 in revenue. Set up a payment processor to enable online purchases with CampaignPilot.</div>
        <div>
          <button class="button is-rounded is-transparent-black is-small" @click="goTo('payment')">Set Up</button>
        </div>
      </div>
      <hr/>
    </div>
    <div v-if="warningFacebook" class="checklist-item-holder">
      <div class="checklist-item">
        <div>You haven't configured your Facebook channel yet. Post your campaigns to Facebook to immediately increase impressions.</div>
        <div>
          <button class="button is-rounded is-transparent-black is-small" @click="goTo('channels')">Set Up</button>
        </div>
      </div>
      <hr/>
    </div>
    <div v-if="warningFacebookConfig" class="checklist-item-holder">
      <div class="checklist-item">
        <div>Your Facebook channel needs to be updated. CampaignPilot is having trouble posting or providing you with reporting data.</div>
        <div>
          <button class="button is-rounded is-transparent-black is-small" @click="goTo('facebook')">Fix</button>
        </div>
      </div>
      <hr/>
    </div>
    <div v-if="warningGeneralSettings" class="checklist-item-holder">
      <div class="checklist-item">
        <div>Some of your business details are incomplete. Providing your phone number and address gives your customers confidence when making online purchases.</div>
        <div>
          <button class="button is-rounded is-transparent-black is-small"  @click="goTo('general')">Add Details</button>
        </div>
      </div>
      <hr/>
    </div>
    <div v-if="warningLogo" class="checklist-item-holder">
      <div class="checklist-item">
        <div>You haven't uploaded a logo for your business. CampaignPilot will add your custom logo to all marketing content if you upload one.</div>
        <div>
          <button class="button is-rounded is-transparent-black is-small" @click="goTo('logo')">Upload</button>
        </div>
      </div>
      <hr/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentAccountChannels: 'currentAccountChannels'
    }),
    hasWarning: function () {
      if (this.warningGeneralSettings || this.warningLogo || this.warningPayments || this.warningFacebook || this.warningFacebookConfig) return true
      return false
    },
    warningGeneralSettings: function () {
      if (!this.currentAccount.name || !this.currentAccount.email || !this.currentAccount.phone || !this.currentAccount.street || !this.currentAccount.city || !this.currentAccount.state || !this.currentAccount.postal) return true
      return false
    },
    warningLogo: function () {
      if (!this.currentAccount.logoSquare && !this.currentAccount.logoWide) return true
      return false
    },
    warningPayments: function () {
      if (this.currentAccount['cc_ets'] !== 1 && this.currentAccount['cc_paypal'] !== 1 && this.currentAccount['cc_payroc'] !== 1 && !(this.currentAccount['cc_stripe'] === 1 && this.currentAccount['cc_stripe_token'])) return true
      return false
    },
    warningFacebook: function () {
      var r = true
      if (this.currentAccountChannels) {
        this.currentAccountChannels.forEach(function (channel) {
          if (channel.active === 1 && channel.type === 'facebook') {
            r = false
          }
        })
      }
      return r
    },
    warningFacebookConfig: function () {
      var r = false
      if (this.currentAccountChannels) {
        this.currentAccountChannels.forEach(function (channel) {
          if (channel.active === 1 && channel.type === 'facebook' && (channel['can_insight'] !== 1 || channel['can_post'] !== 1)) {
            r = true
          }
        })
      }
      return r
    }
  },
  watch: {
    hasWarning (val) {
      this.$emit('input', val)
    }
  },
  mounted () {
    this.$emit('input', this.hasWarning)
  },
  methods: {
    goTo (area) {
      if (area === 'general') {
        this.$router.push({ name: 'route-engine-settings', query: { wm: '1' } })
      } else if (area === 'channels') {
        this.$router.push({ name: 'route-engine-settings', query: { refreshtab: 'channels', wm: '1' } })
      } else if (area === 'logo') {
        this.$router.push({ name: 'route-engine-settings', query: { refreshtab: 'media', wm: '1' } })
      } else if (area === 'payment') {
        this.$router.push({ name: 'route-engine-settings', query: { refreshtab: 'payments', wm: '1' } })
      } else if (area === 'facebook') {
        this.$router.push({ name: 'route-engine-settings', query: { refreshtab: 'channels' } })
      }
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
.checklist {
  font-size: 0.9em;
}
.checklist .checklist-item {
  display: flex;
}
.checklist hr {
  margin: 0.5rem 0!important;
  height: 1px!important;
  background-color: $grey-light!important;
}
.checklist-item div:not(:last-child) {
  padding-right: 30px;
}
.checklist-item-holder:last-of-type hr {
  display: none;
}
</style>

<template>
<div>
  <label class="label" :class="props.labelClass" v-if="props.label">{{props.label}}</label>
  <div v-if="selectedItemLabel" class="selectedItemText">
    {{selectedItemLabel}}
    <a
        class="delete"
        @click="reset"></a>
  </div>
  <div v-else class="field">
    <Select
      v-if="items"
      :props="items"
      v-model="items.model" />
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Select from '@/components/elements/Select'
import channelApi from '@/plugins/channel-api.js'
import xhr from '@/mixins/Xhr.js'
import inputs from '@/mixins/Inputs.js'

export default {
  props: {
    teesheetId: {
      type: String,
      default: null
    }
  },
  mixins: [inputs, xhr],
  components: {
    Select
  },
  data () {
    return {
      selectedItemLabel: null,
      items: null
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    })
  },
  watch: {
    'items.model': {
      handler: function (val) {
        if (val) {
          if (typeof val === 'string') val = JSON.parse(val)
          this.selectedItemLabel = `${val.attributes.name}`
          this.$emit('input', val)
        }
      }
    },
    'props.model': {
      handler: function (val, oval) {
        if (val) {
          this.items.model = val
        } else if (oval) {
          this.reset() // reset if the model is cleared
        }
      }
    }
  },
  mounted () {
    // set default
    var defaultItems = {
      model: null,
      name: 'items',
      ref: 'items',
      options: [],
      icon: 'fas fa-tag',
      class: 'is-fullwidth'
    }
    // override with props (loading this way works better with fa - icons)
    if (this.props.model) defaultItems.model = this.props.model
    if (this.props.icon) defaultItems.icon = 'fas ' + this.props.icon
    if (this.props.class) defaultItems.class = this.props.class
    if (this.props.name) defaultItems.name = this.props.name
    if (this.props.ref) defaultItems.ref = this.props.ref
    // now we can set the select and load the dynamic items
    this.items = defaultItems
    this.fetchItems()
  },
  methods: {
    fetchItems: function () {
      var vm = this
      channelApi.foreupFetchReservationGroups(vm.currentAccount.id, vm.teesheetId,
        function (err, res) {
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.buildItemsOption(res)
          }
        }
      )
    },
    buildItemsOption: function (itemsFromforeUP) {
      if (itemsFromforeUP) {
        var items = [
          { name: 'Select', value: null },
          { name: 'All', value: '{"type":"bookingClass","id":"all","attributes":{"name":"All"}}' }
        ]
        if (this.props.selectLabel) items[0].name = this.props.selectLabel
        itemsFromforeUP.forEach(function (foreUPItem) {
          var selectName = `${foreUPItem.attributes.name}`
          items.push({ name: selectName, value: JSON.stringify(foreUPItem) })
        })
        this.items.options = items
      }
    },
    reset () {
      this.selectedItemLabel = null
      this.items.model = null
      this.props.model = null
    }
  }
}
</script>

<style lang="scss" scoped>
.selectedItemText {
  margin-bottom: 1rem !important;
}
.loadingItems {
  display: block;
  margin-bottom: 1rem !important;
}
</style>

<template>
  <section class="section">
    <div class="container">
      <div class="main-holder">
        <h1 class="title">
          Let's Create Your First Campaign Together
        </h1>
        <p>
          CampaignPilot can help market and promote pretty much anything you have going on at your business. Whether you are running a sale, organizing an event, or simply sending out a notice to your customers, CampaignPilot will make building and distributing your message easier than ever.
        </p>
        <p>
          Each campaign is built using our simple Campaign Wizard that will guide you through all the necessary configuration. Let's walk through the Campaign Wizard together and set up a sample marketing campaign. This should only take about 10 minutes and will teach you everything you need to know about marketing with CampaignPilot.
        </p>
        <div class="field">
          <p class="control">
            <button
              class="button is-primary is-rounded"
              @click="onButtonClick()">
              <span>Start The Tour &#62;</span>
            </button>
          </p>
          <p class="skip-it">
            <a href="#" @click="skipIt()">I'm already a CampaignPilot expert, skip the tour!</a>
          </p>
        </div>
      </div>
      <div class="airplane"><img src="../../../assets/imgs/other/paper-airplane.png" /></div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      currentAccount: 'currentAccount'
    }),
    licenseName: function () {
      if (this.currentUser) if (this.currentUser.name) return this.currentUser.name
      return 'Your Name'
    },
    licenseBusinessName: function () {
      if (this.currentAccount) if (this.currentAccount.name) return this.currentAccount.name
      return 'Your Business'
    }
  },
  mounted () {
    // let the UI know we're in guide mode
    this.$store.dispatch('setGuideMode', true)
    // reset the current wizard
    this.$store.dispatch('resetWizardState')
    // generate a new id for the tour analytics
    var tourId = Math.floor(1000 + Math.random() * 9000)
    this.$store.dispatch('setGuideModeId', tourId)
    // send google analytics event
    if (process.env.NODE_ENV !== 'development') window.gtag('event', 'help_tour', { 'action': 'welcome', 'tourId': tourId })
  },
  methods: {
    onButtonClick () {
      this.$router.push({ name: 'route-engine-campaigns-wizard' })
    },
    skipIt () {
      this.$store.dispatch('setGuideMode', false)
      this.$store.dispatch('setGuideModeId', null)
      this.$router.push({ name: 'route-engine-home' })
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding-bottom: 24px;
}
.license-holder {
  padding-bottom: 14px;
}
.section {
  background-color: $light;
  background: $light;
  background: url('../../../assets/imgs/other/cloud-background.png'), $light;
  background-repeat: repeat-y;
  background-size: cover;
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.container {
  display: table-cell;
  vertical-align: middle;
}
.main-holder {
  width: 80vw;
  margin: 40px auto 0 auto;
  max-width: 725px;
}
.title,
.control {
  text-align: center;
}
.airplane {
  position: relative;
  bottom: -15px;
  left: calc(50% - 350px);
  width: 250px;
}
.skip-it {
  text-align: center;
  font-size: 0.6em;
}
.skip-it > a:hover {
  color: $primary!important;
}
@media only screen and (min-width : 0px) and (max-width : $mobile-max) {
  .airplane {
  }
}
</style>

<template>
  <div class="content">
    <div v-if="selectedQuestion > -1">
      <div class="right-column-text">
        <div
          id="edit-field"
          class="subtitle-w-bar">Edit field</div>
        <div class="field">
          <label class="label">Name</label>
          <div class="control">
            <input
              v-model="questions[selectedQuestion].name"
              type="text"
              disabled
              class="input">
          </div>
        </div>
        <div class="field">
          <label class="label">Label</label>
          <div class="control">
            <input
              v-model="questions[selectedQuestion].label"
              type="text"
              class="input">
          </div>
        </div>
        <div
          v-if="showPlaceholder"
          class="field">
          <label class="label">Placeholder</label>
          <div class="control">
            <input
              v-model="questions[selectedQuestion].placeholder"
              type="text"
              class="input">
          </div>
        </div>
        <div v-if="questions[selectedQuestion].type === 'option-group'">
          <div class="field">
            <label class="label">Type</label>
            <div class="control">
              <div class="buttons has-addons">
                <button
                  :class="{'is-primary' : questions[selectedQuestion]['option-type'] === 'select' }"
                  class="button is-rounded"
                  @click="toggleType()">
                  <span class="icon is-small">
                    <i class="fas fa-caret-square-down"/>
                  </span>
                  <span>Drop Down</span>
                </button>
                <button
                  :class="{'is-primary' : questions[selectedQuestion]['option-type'] === 'list' }"
                  class="button is-rounded"
                  @click="toggleType()">
                  <span>Item List</span>
                  <span class="icon is-small">
                    <i class="fas fa-list-ul"/>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="questions[selectedQuestion]['option-type'] === 'list'"
            class="field">
            <label class="label">Allow Multi-Select</label>
            <div class="control">
              <div class="buttons has-addons">
                <button
                  :class="{'is-primary' : questions[selectedQuestion]['option-multi'] }"
                  class="button is-rounded"
                  @click="toggleMulti()">
                  <span class="icon is-small">
                    <i class="fas fa-tasks"/>
                  </span>
                  <span>Yes</span>
                </button>
                <button
                  :class="{'is-primary' : !questions[selectedQuestion]['option-multi'] }"
                  class="button is-rounded"
                  @click="toggleMulti()">
                  <span>No</span>
                  <span class="icon is-small">
                    <i class="fas fa-list-ul"/>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">
              {{ productOptionLabels.l }}
            </label>
            <div class="control">
              <textarea
                :placeholder="productOptionLabels.p"
                v-model="questions[selectedQuestion]['options']"
                class="textarea"/>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Order</label>
          <div class="control">
            <div class="buttons has-addons">
              <button
                :disabled="selectedQuestion < 1 "
                class="button is-rounded"
                @click="moveQuestionUp">
                <span class="icon is-small">
                  <i class="fas fa-arrow-up"/>
                </span>
                <span>Move Up</span>
              </button>
              <button
                :disabled="selectedQuestion === questions.length - 1"
                class="button is-rounded"
                @click="moveQuestionDown">
                <span>Move Down</span>
                <span class="icon is-small">
                  <i class="fas fa-arrow-down"/>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Required</label>
          <div class="control required-box">
            <input
              id="requiredBox"
              v-model="questions[selectedQuestion].required"
              name="requiredBox"
              class="is-checkradio"
              type="checkbox"
              @change="toggleRequired($event)">
            <label for="requiredBox">&nbsp;</label>
          </div>
        </div>
        <p class="buttons">
          <button
            class="button is-rounded"
            @click="switchTab('add')">
            <span>Save Field</span>
          </button>
          <button
            class="button is-danger is-rounded"
            @click="removeQuestion">
            <span>Remove Field</span>
          </button>
        </p>
      </div>
    </div>
    <div v-else>
      <div class="right-column-text">
        <div class="subtitle-w-bar">Add a field</div>
        <QuestionSelect
          :props="newQuestionType"
          v-model="newQuestionType"
          @click="addQuestion()"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QuestionSelect from '@/components/elements/QuestionSelect'
import utils from '@/plugins/utils.js'

export default {
  components: {
    QuestionSelect
  },
  props: {
    value: {
      type: Array,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    hasErrors: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Object,
      required: false,
      default: function () { return {} }
    },
    selected: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      hadQuestions: false,
      questions: null,
      currentTab: 'add',
      newQuestionType: null,
      newQuestionCount: 0,
      selectedQuestion: -1,
      order: {},
      basicTheme: {
        currentColors: {
          primary: '#F5F5F5',
          primaryFont: '#000000',
          secondary: '#F5F5F5',
          secondaryFont: '#000000',
          alternate: '#F5F5F5',
          alternateFont: '#000000'
        }
      }
    }
  },
  computed: {
    showPlaceholder: function () {
      var f = this.questions[this.selectedQuestion].type
      if (f === 'grouped-field' || f === 'option-group') return false
      else return true
    },
    simpleProduct: function () {
      if (this.actions.product) {
        return {
          fullPrice: this.actions.product.fullPrice.model,
          image: this.actions.product.imageUrl,
          name: this.actions.product.name.model,
          price: this.actions.product.price.model,
          stock: this.actions.product.stock.model
        }
      } else {
        return {}
      }
    },
    productOptions: function () {
      if (this.actions.product) {
        var options = []
        this.actions.variants.forEach(function (variant) {
          var group = {
            name: variant.props.name.model,
            type: variant.props.display.model,
            required: variant.props.required.model,
            options: variant.options
          }
          options.push(group)
        })
        return options
      } else {
        return []
      }
    },
    productOptionLabels: function () {
      if (this.questions[this.selectedQuestion]['option-type'] === 'select') {
        return {
          l: 'Drop Down Options',
          p: 'Option 1\nOption 2\nOption 3'
        }
      } else {
        return {
          l: 'List Items',
          p: 'Item 1\nItem 2\nItem 3'
        }
      }
    },
    ...mapGetters({
      vuexWizardTitle: 'wizardTitle'
    })
  },
  watch: {
    questions (val) {
      this.$emit('input', val)
    },
    selected (val) {
      this.selectedQuestion = val
    },
    selectedQuestion (val) {
      this.$emit('selected', val)
    }
  },
  mounted () {
    if (this.value) {
      this.questions = this.value
      if (Array.isArray(this.questions)) {
        if (this.questions.length > 0) {
          // helps with rendering, if we never had questions injecting into
          // component, then we can hide the entire form
          // (helpful when skipping form step)
          this.hadQuestions = true
          // fixes issues with field counter resetting to 0 each time this component is mounted
          this.newQuestionCount = this.questions.length
        }
      }
    }
    if (this.selected > -1) {
      this.selectedQuestion = this.selected
    }
  },
  methods: {
    selectQuestion (index) {
      this.switchTab('field')
      this.selectedQuestion = index
    },
    addQuestion () {
      if (this.newQuestionType) {
        var n
        this.newQuestionCount++
        var assureUnique = utils.makeRandomString(3, true, '1234567890')
        if (this.newQuestionType === 'address-input') {
          n = this.newQuestionCount + ''
          n = n.padStart(2, '0')
          this.questions.push({
            'type': 'grouped-field',
            'name': 'address_' + n + assureUnique,
            'questions': [
              {
                'type': 'text-input',
                'name': 'street1_' + n + assureUnique,
                'ref': 'street1_' + n + assureUnique,
                'label': 'Street',
                'placeholder': '',
                'required': false,
                'model': null
              },
              {
                'type': 'text-input',
                'name': 'street2_' + n + assureUnique,
                'ref': 'street2_' + n + assureUnique,
                'placeholder': '',
                'required': false,
                'model': null
              },
              {
                'type': 'text-input',
                'name': 'city_' + n + assureUnique,
                'ref': 'city_' + n + assureUnique,
                'label': 'City',
                'placeholder': '',
                'required': false,
                'model': null
              },
              {
                'type': 'state-select',
                'name': 'state_' + n + assureUnique,
                'ref': 'state_' + n + assureUnique,
                'label': 'State',
                'placeholder': '',
                'required': false,
                'model': null
              },
              {
                'type': 'text-input',
                'name': 'postal_' + n + assureUnique,
                'ref': 'postal_' + n + assureUnique,
                'label': 'Postal Code',
                'placeholder': '',
                'required': false,
                'model': null
              }
            ]
          })
        } else if (this.newQuestionType === 'option-group') {
          // figure out the option group name
          n = this.newQuestionCount + ''
          n = n.padStart(2, '0')
          n = this.newQuestionType.replace(/-/g, '_') + '_' + n + assureUnique
          // push in our default option group question
          this.questions.push({
            'type': this.newQuestionType,
            'name': n,
            'ref': n,
            'label': 'Make A Selection',
            'placeholder': null,
            'inputType': null,
            'icon': null,
            'convertTo': null,
            'required': false,
            'model': null,
            'validate': null,
            'option-type': 'select', // select or list
            'option-multi': false,
            'options': null
          })
        } else {
          var type = this.newQuestionType
          var name = this.newQuestionCount + ''
          name = name.padStart(2, '0')
          var label = null
          var placeholder = null
          var convertTo = null
          var icon = null
          var inputType = null
          var optionType = null
          var optionMulti = false
          var options = null
          switch (type) {
            case 'email-input':
              type = 'text-input'
              label = 'Email Address'
              inputType = 'email'
              name = 'email_' + name + assureUnique
              break
            case 'phone-input':
              type = 'text-input'
              label = 'Phone Number'
              placeholder = '555-555-5555'
              inputType = 'tel'
              name = 'phone_' + name + assureUnique
              convertTo = 'usphone'
              break
            case 'full-name-input':
              type = 'text-input'
              label = 'Full Name'
              name = 'name_' + name + assureUnique
              break
            case 'price-input':
              type = 'text-input'
              label = 'Amount'
              placeholder = '100.00'
              convertTo = 'price'
              icon = 'fas fa-dollar-sign'
              name = 'price_' + name + assureUnique
              break
            case 'golf-handicap-input':
              type = 'option-group'
              label = 'Golf Handicap'
              name = 'golf_handicap_' + name + assureUnique
              optionType = 'select'
              options = '+4\n+3\n+2\n+1\n0\n1\n2\n3\n4\n5\n6\n7\n8\n9\n10\n11\n12\n13\n14\n15\n16\n17\n18\n19\n20\n21\n22\n23\n24\n25\n26\n27\n28\n29\n30\n31\n32\n33\n34\n35\n36\n37\n38\n39\n40'
              break
            case 'golf-team-name-input':
              type = 'text-input'
              label = 'Team Name'
              name = 'team_name_' + name + assureUnique
              break
            default:
              label = 'Your Label Here'
              name = this.newQuestionType.replace(/-/g, '_') + '_' + name + assureUnique
              break
          }
          var newQuestion = {
            'type': type,
            'name': name,
            'ref': name,
            'label': label,
            'placeholder': placeholder,
            'inputType': inputType,
            'icon': icon,
            'convertTo': convertTo,
            'required': false,
            'model': null,
            'validate': null,
            'option-type': optionType,
            'option-multi': optionMulti,
            'options': options
          }
          this.questions.push(newQuestion)
        }
        this.newQuestionType = null
        this.selectQuestion(this.questions.length - 1) // select the question we just added
      }
    },
    removeQuestion () {
      this.questions.splice(this.selectedQuestion, 1)
      this.switchTab('add')
    },
    moveQuestionUp () {
      if (this.selectedQuestion > 0) {
        var questionToMoveUp = this.questions[this.selectedQuestion]
        this.questions[this.selectedQuestion] = this.questions[this.selectedQuestion - 1]
        this.questions[this.selectedQuestion - 1] = questionToMoveUp
        this.selectedQuestion--
        this.$emit('refresh', this.questions)
      }
    },
    moveQuestionDown () {
      if (this.selectedQuestion < this.questions.length - 1) {
        var questionToMoveDown = this.questions[this.selectedQuestion]
        this.questions[this.selectedQuestion] = this.questions[this.selectedQuestion + 1]
        this.questions[this.selectedQuestion + 1] = questionToMoveDown
        this.selectedQuestion++
        this.$emit('refresh', this.questions)
      }
    },
    toggleRequired (e) {
      if (this.questions[this.selectedQuestion].required) {
        this.questions[this.selectedQuestion].validate = { 'required': true }
        // make all the grouped fields required
        if (this.questions[this.selectedQuestion].questions) {
          if (this.questions[this.selectedQuestion].questions.length > 0) {
            this.questions[this.selectedQuestion].questions.forEach(function (question) {
              if (question.label) {
                question.validate = { 'required': true }
                question.required = true
              }
            })
          }
        }
      } else {
        this.questions[this.selectedQuestion].validate = null
        // make all the grouped fields not required
        if (this.questions[this.selectedQuestion].questions) {
          if (this.questions[this.selectedQuestion].questions.length > 0) {
            this.questions[this.selectedQuestion].questions.forEach(function (question) {
              question.validate = null
              question.required = false
            })
          }
        }
      }
    },
    switchTab (tab) {
      this.currentTab = tab
      if (tab === 'add') {
        this.selectedQuestion = -1 // deselect question
      }
    },
    toggleType () {
      if (this.questions[this.selectedQuestion]['option-type'] === 'list') {
        this.questions[this.selectedQuestion]['option-type'] = 'select'
      } else {
        this.questions[this.selectedQuestion]['option-type'] = 'list'
      }
    },
    toggleMulti () {
      if (this.questions[this.selectedQuestion]['option-multi']) {
        this.questions[this.selectedQuestion]['option-multi'] = false
      } else {
        this.questions[this.selectedQuestion]['option-multi'] = true
      }
    }
  }
}
</script>

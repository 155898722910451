<template>
  <div>
    <main class="oto-main">
      <div class="oto-side-background is-hidden-mobile"/>
      <div class="oto-main-container container">
        <div class="oto-duo">
          <div class="oto-lead">
            <!-- Left Column -->
            <div>
              <div class="step-heading is-hidden-mobile">
                <h2 class="subtitle uppercase">
                  Campaign: {{ vuexWizardTitle }}
                </h2>
                <h1
                  v-if="campaignGenerating"
                  class="title">
                  Scheduling Your Campaign
                </h1>
                <h1
                  v-else
                  class="title">
                  Schedule Your Message
                </h1>
              </div>
              <div
                v-if="campaignGenerating"
                class="oto-loading-holder">
                <div class="layer-01">
                  <img
                    class="loading-posts"
                    src="../../../../../assets/svgs/paperplane.svg">
                </div>
                <div class="layer-02">
                  <img
                    class="loading-posts"
                    src="../../../../../assets/svgs/clouds.svg">
                </div>
              </div>
              <div v-else>
                <div id="schedule-campaign-channel-area">
                  <div class="subtitle-w-bar">
                    Marketing Channels
                  </div>
                  <div class="columns is-multiline">
                    <div
                      v-for="(c, i) in channelBlocks"
                      :key="'cb'+i"
                      class="column is-half-mobile is-half-tablet is-one-quarter-desktop"
                      @click="clicked(c, authdChannels[c.name])">
                      <div
                        :class="{ 'selected':channels[c.name], 'oto-channel-not-configured':!authdChannels[c.name] && !c.comingsoon && !guideMode }"
                        class="oto-campaign-card oto-hover schedule-card-min-height">
                        <span
                          :class="{ 'is-invisible':!channels[c.name] }"
                          class="icon has-text-white">
                          <i class="fas fa-check"/>
                        </span>
                        <span
                          v-if="!authdChannels[c.name] && !c.comingsoon && !guideMode"
                          class="tag is-warning channel-config-tag">
                          Not Configured
                        </span>
                        <span
                          v-if="c.comingsoon"
                          class="tag is-secondary channel-config-tag">
                          Coming Soon
                        </span>
                        <div class="oto-campaign-card-content schedule-card-content">
                          <div class="has-text-centered">
                            <span class="icon is-medium">
                              <i
                                :class="c.icon"
                                class="fa-2x"/>
                            </span>
                          </div>
                          <div class="content is-marginless has-text-centered">
                            <p
                              :class="{ 'has-text-grey':!authdChannels[c.name] && !c.comingsoon && !guideMode }"
                              class="card-header-title is-paddingless is-marginless is-centered">{{ c.title }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="schedule-campaign-schedule-area">
                  <div class="subtitle-w-bar">
                    Schedule Your Campaign
                  </div>
                  <div class="columns is-desktop">
                    <div class="column">
                      <Calendar
                        :props="sendDate"
                        v-model="sendDate.model"/>
                      <div class="buttons date-button-fix">
                        <span
                          class="button is-rounded is-small is-oto-tiny"
                          @click="quickDate('today')">
                          Today
                        </span>
                        <span
                          class="button is-rounded is-small is-oto-tiny"
                          @click="quickDate('tomorrow')">
                          Tomorrow
                        </span>
                        <span
                          class="button is-rounded is-small is-oto-tiny"
                          @click="quickDate('week')">
                          Next Week
                        </span>
                      </div>
                    </div>
                    <div class="column">
                      <Clock
                        v-model="sendTime.model"
                        :props="sendTime"/>
                      <div class="buttons date-button-fix">
                        <span
                          class="button is-rounded is-small is-oto-tiny"
                          @click="quickTime('now')">
                          Now
                        </span>
                        <span
                          class="button is-rounded is-small is-oto-tiny"
                          @click="quickTime('afternoon')">
                          Afternoon
                        </span>
                        <span
                          class="button is-rounded is-small is-oto-tiny"
                          @click="quickTime('evening')">
                          Evening
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <aside class="oto-side">
            <div class="step-heading is-hidden-tablet">
              <h2 class="subtitle uppercase">
                Campaign: {{ vuexWizardTitle }}
              </h2>
              <h1
                v-if="campaignGenerating"
                class="title">
                Scheduling Your Campaign
              </h1>
              <h1
                v-else
                class="title">
                Schedule Your Message
              </h1>
            </div>
            <div class="content">
              <div
                v-if="campaignGenerating"
                class="right-column-text">
                CampaignPilot is putting the final touches on your marketing content and scheduling this campaign. You can review a full summary of your entire campaign in just a moment.
              </div>
              <div
                v-else
                class="right-column-text">
                Select the marketing channels you would like this campaign to be automatically distributed to. You can also set the specific date and time your campaign is distributed.
              </div>
            </div>
          </aside>
        </div>
      </div>
    </main>
    <Navigation v-model="navigation"/>
  </div>
</template>

<script>
import wizard from '@/mixins/Wizard.js'
import tour from '@/mixins/Tour.js'
import { mapGetters } from 'vuex'
import Clock from '@/components/elements/Clock'
import Calendar from '@/components/elements/Calendar'

export default {
  components: {
    Clock,
    Calendar
  },
  mixins: [wizard, tour],
  data () {
    return {
      defaultChannelsToOn: true,
      campaignGenerating: false,
      selectedDate: null,
      selectedTime: null,
      sendDate: {
        model: null,
        name: 'send-date',
        ref: 'send-date',
        label: 'Date',
        hideRequired: true,
        controlClass: 'oto-schedule-picker-fix',
        validate: { required: true }
      },
      sendTime: {
        model: null,
        name: 'send-time',
        ref: 'send-time',
        label: 'Time',
        hideRequired: true,
        validate: { required: true }
      },
      channels: {
        website: false,
        email: false,
        facebook: false,
        twitter: false,
        app: false,
        google: false,
        instagram: false
      },
      authdChannels: {
        website: false,
        email: false,
        facebook: false,
        twitter: false,
        app: false,
        google: false,
        instagram: false
      },
      channelBlocks: [
        {
          name: 'website',
          title: 'Website',
          icon: 'fas fa-globe'
        },
        {
          name: 'email',
          title: 'Email',
          icon: 'fas fa-envelope'
        },
        {
          name: 'app',
          title: 'App',
          icon: 'fas fa-mobile'
        },
        {
          name: 'facebook',
          title: 'Facebook',
          icon: 'fab fa-facebook'
        },
        {
          name: 'google',
          title: 'Google',
          icon: 'fab fa-google'
        },
        {
          name: 'instagram',
          title: 'Instagram',
          icon: 'fab fa-instagram'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      currentAccountChannels: 'currentAccountChannels',
      fullWizard: 'fullWizard',
      vuexEditId: 'editId',
      vuexWizardSchedule: 'wizardSchedule',
      unsplash: 'wizardUnsplashImages'
    })
  },
  watch: {
    currentAccountChannels: {
      handler: function (val) {
        this.determineActiveChannels()
      },
      deep: true
    },
    'sendDate.model': {
      handler: function (val) {
        if (val && this.sendTime.model && this.guideMode) this.TourScheduleFinal()
      }
    },
    'sendTime.model': {
      handler: function (val) {
        if (val && this.sendDate.model && this.guideMode) this.TourScheduleFinal()
      }
    }
  },
  mounted () {
    this.$store.dispatch('setWizardStep', 9)
    if (this.vuexWizardSchedule) {
      if (process.env.NODE_ENV === 'development') console.log('need to load into step: ' + JSON.stringify(this.vuexWizardSchedule))
      this.defaultChannelsToOn = false
      this.sendDate.model = this.vuexWizardSchedule.date
      this.sendTime.model = this.vuexWizardSchedule.time
      this.channels = this.$lodash.cloneDeep(this.vuexWizardSchedule.channels)
    } else {
      // this.quickDate('today')
      // this.quickTime('now')
    }
    // handle configured/non-configured channels
    this.determineActiveChannels()
    // Kickoff the Tour
    if (this.guideMode) this.TourScheduleStart({ showButtons: true })
  },
  methods: {
    quickDate (type) {
      this.selectedDate = type
      var now = new Date()
      switch (type) {
        case 'tomorrow':
          now.setHours(now.getHours() + 24)
          break
        case 'week':
          now.setHours(now.getHours() + 168)
          break
        default:
          break
      }
      var month = '' + (now.getMonth() + 1)
      var day = '' + now.getDate()
      var year = now.getFullYear()
      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day
      this.sendDate.model = year + '-' + month + '-' + day
    },
    quickTime (type) {
      this.selectedTime = type
      var now = new Date()
      switch (type) {
        case 'morning':
          now.setHours(8)
          now.setMinutes(0)
          now.setSeconds(0)
          break
        case 'afternoon':
          now.setHours(13)
          now.setMinutes(0)
          now.setSeconds(0)
          break
        case 'evening':
          now.setHours(20)
          now.setMinutes(0)
          now.setSeconds(0)
          break
        case 'now':
          var min = (parseInt((now.getMinutes() / 15)) + 1) * 15
          min = min >= 60 ? min - 60 : min
          now.setMinutes(min)
          if (min === 0) {
            now.setHours(now.getHours() + 1)
          }
          break
        default:
          break
      }
      var hh = now.getHours()
      var m = now.getMinutes()
      var dd = 'AM'
      var h = hh
      if (h >= 12) {
        h = hh - 12
        dd = 'PM'
      }
      if (h === 0) {
        h = 12
      }
      m = m < 10 ? '0' + m : m
      this.sendTime.model = h + ':' + m + ' ' + dd
    },
    formatDate (date) {
      var d = new Date(date)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()
      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day
      return [year, month, day].join('-')
    },
    clicked (channel, authd) {
      if (channel) {
        if (!channel.comingsoon) {
          if (authd) {
            this.toggleChannel(channel.name)
          } else {
            this.configureChannel(channel.name)
          }
        }
      }
    },
    toggleChannel (channel) {
      this.channels[channel] = !this.channels[channel]
    },
    configureChannel (channel) {
      let routeData = this.$router.resolve({ name: 'route-engine-settings', query: { refreshtab: 'channels', wm: '2' } })
      window.open(routeData.href, '_blank')
    },
    determineActiveChannels () {
      var vm = this
      if (this.currentAccountChannels.length > 0) {
        this.currentAccountChannels.forEach(function (channel) {
          if (channel.type === 'facebook' && channel.active === 1) {
            vm.authdChannels.facebook = true
            if (vm.defaultChannelsToOn) vm.channels.facebook = true
          } else if (channel.type === 'twitter' && channel.active === 1) {
            vm.authdChannels.twitter = true
            if (vm.defaultChannelsToOn) vm.channels.twitter = true
          } else if (channel.type === 'email' && channel.active === 1) {
            vm.authdChannels.email = true
            if (vm.defaultChannelsToOn) vm.channels.email = true
          } else if (channel.type === 'website' && channel.active === 1) {
            vm.authdChannels.website = true
            if (vm.defaultChannelsToOn) vm.channels.website = true
          } else if (channel.type === 'app' && channel.active === 1) {
            vm.authdChannels.app = true
            if (vm.defaultChannelsToOn) vm.channels.app = true
          } else if (channel.type === 'google' && channel.active === 1) {
            vm.authdChannels.google = true
            if (vm.defaultChannelsToOn) vm.channels.google = true
          } else if (channel.type === 'instagram' && channel.active === 1) {
            vm.authdChannels.instagram = true
            if (vm.defaultChannelsToOn) vm.channels.instagram = true
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.is-oto-tiny {
  height: 10px;
  padding: 10px!important;
}
.date-button-fix {
  margin-top: 1rem;
}
.schedule-card-content {
  padding: 5px 0 20px 0;
}
</style>

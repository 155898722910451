<template>
  <transition name="fade">
    <div v-if="widgetLoaded">
      <div
        v-if="widget === 'metrics'"
        class="columns">
        <div class="column is-full">
          <div
            v-if="(showTitle && finalTitle) || (showSubtitle && finalSubtitle) || (showTip && finalTip)"
            class="pad-title">
            <strong v-if="(showTitle && finalTitle)">{{ finalTitle }}</strong>
            <span
              v-if="(showSubtitle && finalSubtitle)"
              class="stats-subtext">
              {{ finalSubtitle }}
            </span>
            <br v-if="(showTip && finalTip)">
            <span v-if="(showTip && finalTip)">
              {{ finalTip }}
            </span>
          </div>
          <div class="columns is-multiline is-mobile">
            <div
              v-if="onlyShowMetrics.length === 0 || onlyShowMetrics.includes('campaigns')"
              :class="metricClass"
              class="column">
              <div
                :class="{'oto-hover':metricClickMode, 'is-selected':selectedMetric==='campaigns'}"
                class="oto-campaign-card with-border"
                @click="$emit('click', 'campaigns')">
                <div class="oto-campaign-card-content">
                  <div class="content is-marginless">
                    <p
                      :class="{'has-text-dark':selectedMetric!=='campaigns', 'has-text-white':selectedMetric==='campaigns'}"
                      class="card-header-large is-centered is-paddingless is-marginless">
                      <span>
                        <animated-number
                          :value="metric.campaigns"
                          :formatValue="numberFormat"
                          :duration="1500"/>
                      </span>
                    </p>
                    <p
                      :class="{'has-text-primary':selectedMetric!=='campaigns', 'has-text-white':selectedMetric==='campaigns'}"
                      class="card-header-title is-centered is-paddingless is-marginless has-text-primary">
                      Published
                    </p>
                  </div>
                </div>
                <footer v-if="!hideMetricFooter" class="card-footer">
                  <p class="oto-campaign-card-footer-item">
                    The total number of campaigns your business has created
                    <span v-if="daysBack"> in the past {{daysBack}} days.</span>
                    <span v-else> all time.</span>
                  </p>
                </footer>
              </div>
            </div>
            <div
              v-if="onlyShowMetrics.length === 0 || onlyShowMetrics.includes('impressions')"
              :class="metricClass"
              class="column">
              <div
                :class="{'oto-hover':metricClickMode, 'is-selected':selectedMetric==='impressions'}"
                class="oto-campaign-card with-border"
                @click="$emit('click', 'impressions')">
                <div class="oto-campaign-card-content">
                  <div class="content is-marginless">
                    <p
                      :class="{'has-text-dark':selectedMetric!=='impressions', 'has-text-white':selectedMetric==='impressions'}"
                      class="card-header-large is-centered is-paddingless is-marginless">
                      <span>
                        <animated-number
                          :value="metric.impressions"
                          :formatValue="numberFormat"
                          :duration="1500"/>
                      </span>
                    </p>
                    <p
                      :class="{'has-text-primary':selectedMetric!=='impressions', 'has-text-white':selectedMetric==='impressions'}"
                      class="card-header-title is-centered is-paddingless is-marginless">
                      Impressions
                    </p>
                  </div>
                </div>
                <footer v-if="!hideMetricFooter" class="card-footer">
                  <p class="oto-campaign-card-footer-item">
                    The number of times your messages have been seen
                    <span v-if="daysBack"> in the past {{daysBack}} days.</span>
                    <span v-else> all time.</span>
                  </p>
                </footer>
              </div>
            </div>
            <div
              v-if="onlyShowMetrics.length === 0 || onlyShowMetrics.includes('clicks')"
              :class="metricClass"
              class="column">
              <div
                :class="{'oto-hover':metricClickMode, 'is-selected':selectedMetric==='clicks'}"
                class="oto-campaign-card with-border"
                @click="$emit('click', 'clicks')">
                <div class="oto-campaign-card-content">
                  <div class="content is-marginless">
                    <p
                      :class="{'has-text-dark':selectedMetric!=='clicks', 'has-text-white':selectedMetric==='clicks'}"
                      class="card-header-large is-centered is-paddingless is-marginless">
                      <span>
                        <animated-number
                          :value="metric.clicks"
                          :formatValue="numberFormat"
                          :duration="1500"/>
                      </span>
                    </p>
                    <p
                      :class="{'has-text-primary':selectedMetric!=='clicks', 'has-text-white':selectedMetric==='clicks'}"
                      class="card-header-title is-centered is-paddingless is-marginless">
                      Clicks
                    </p>
                  </div>
                </div>
                <footer v-if="!hideMetricFooter" class="card-footer">
                  <p class="oto-campaign-card-footer-item">
                    The number of actual visits to your campaign landing pages
                    <span v-if="daysBack"> in the past {{daysBack}} days.</span>
                    <span v-else> all time.</span>
                  </p>
                </footer>
              </div>
            </div>
            <div
              v-if="onlyShowMetrics.length === 0 || onlyShowMetrics.includes('submissions')"
              :class="metricClass"
              class="column">
              <div
                :class="{'oto-hover':metricClickMode, 'is-selected':selectedMetric==='submissions'}"
                class="oto-campaign-card with-border"
                @click="$emit('click', 'submissions')">
                <div class="oto-campaign-card-content">
                  <div class="content is-marginless">
                    <p
                      :class="{'has-text-dark':selectedMetric!=='submissions', 'has-text-white':selectedMetric==='submissions'}"
                      class="card-header-large is-centered is-paddingless is-marginless">
                      <span>
                        <animated-number
                          :value="metric.submissions"
                          :formatValue="numberFormat"
                          :duration="1500"/>
                      </span>
                    </p>
                    <p
                      :class="{'has-text-primary':selectedMetric!=='submissions', 'has-text-white':selectedMetric==='submissions'}"
                      class="card-header-title is-centered is-paddingless is-marginless">
                      Form Submits
                    </p>
                  </div>
                </div>
                <footer v-if="!hideMetricFooter" class="card-footer">
                  <p class="oto-campaign-card-footer-item">
                    The number of form submissions
                    <span v-if="daysBack"> in the past {{daysBack}} days.</span>
                    <span v-else> all time.</span>
                  </p>
                </footer>
              </div>
            </div>
            <div
              v-if="onlyShowMetrics.length === 0 || onlyShowMetrics.includes('conversions')"
              :class="metricClass"
              class="column">
              <div
                :class="{'oto-hover':metricClickMode, 'is-selected':selectedMetric==='conversions'}"
                class="oto-campaign-card with-border"
                @click="$emit('click', 'conversions')">
                <div class="oto-campaign-card-content">
                  <div class="content is-marginless">
                    <p
                      :class="{'has-text-dark':selectedMetric!=='conversions', 'has-text-white':selectedMetric==='conversions'}"
                      class="card-header-large is-centered is-paddingless is-marginless">
                      <span>
                        <animated-number
                          :value="metric.conversions"
                          :formatValue="numberFormat"
                          :duration="1500"/>
                      </span>
                    </p>
                    <p
                      :class="{'has-text-primary':selectedMetric!=='conversions', 'has-text-white':selectedMetric==='conversions'}"
                      class="card-header-title is-centered is-paddingless is-marginless">
                      Orders
                    </p>
                  </div>
                </div>
                <footer v-if="!hideMetricFooter" class="card-footer">
                  <p class="oto-campaign-card-footer-item">
                    The number of form orders and registrations
                    <span v-if="daysBack"> in the past {{daysBack}} days.</span>
                    <span v-else> all time.</span>
                  </p>
                </footer>
              </div>
            </div>
            <div
              v-if="onlyShowMetrics.length === 0 || onlyShowMetrics.includes('revenue')"
              :class="metricClass"
              class="column">
              <div
                :class="{'oto-hover':metricClickMode, 'is-selected':selectedMetric==='revenue'}"
                class="oto-campaign-card with-border"
                @click="$emit('click', 'revenue')">
                <div class="oto-campaign-card-content">
                  <div class="content is-marginless">
                    <p
                      :class="{'has-text-dark':selectedMetric!=='revenue', 'has-text-white':selectedMetric==='revenue'}"
                      class="card-header-large is-centered is-paddingless is-marginless">
                      <span>
                        <animated-number
                          :value="metric.revenue"
                          :formatValue="priceFormat"
                          :duration="1500"/>
                      </span>
                    </p>
                    <p
                      :class="{'has-text-primary':selectedMetric!=='revenue', 'has-text-white':selectedMetric==='revenue'}"
                      class="card-header-title is-centered is-paddingless is-marginless">
                      Revenue
                    </p>
                  </div>
                </div>
                <footer v-if="!hideMetricFooter" class="card-footer">
                  <p class="oto-campaign-card-footer-item">
                    The revenue generated from CampaignPilot purchases
                    <span v-if="daysBack"> in the past {{daysBack}} days.</span>
                    <span v-else> all time.</span>
                  </p>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="widget === 'timeline'"
        class="columns">
        <div class="column is-full">
          <div>
            <strong v-if="(showTitle && finalTitle)">{{ finalTitle }}</strong>
            <span
              v-if="(showSubtitle && finalSubtitle)"
              class="stats-subtext">
              {{ finalSubtitle }}
            </span>
            <br v-if="(showTip && finalTip)">
            <span v-if="(showTip && finalTip)">
              {{ finalTip }}
            </span>
            <apexchart
              :options="timeline"
              :series="timelineSeries"
              :height="height"
              ref="apexchart"
              type="line"/>
          </div>
        </div>
      </div>
      <div
        v-if="widget === 'recentcampaigns'"
        class="columns">
        <div class="column is-full">
          <div>
            <strong v-if="(showTitle && finalTitle)">{{ finalTitle }}</strong>
            <span
              v-if="(showSubtitle && finalSubtitle)"
              class="stats-subtext">
              {{ finalSubtitle }}
            </span>
            <br v-if="(showTip && finalTip)">
            <span v-if="(showTip && finalTip)">
              {{ finalTip }}
            </span>
            <apexchart
              :options="campaignClickChart"
              :series="campaignClickSeries"
              :height="height"
              ref="apexchart"
              type="bar"/>
          </div>
        </div>
      </div>
      <div
        v-if="widget === 'channelimpressions' && showImpressionChart"
        class="columns">
        <div class="column is-full">
          <div>
            <strong v-if="(showTitle && finalTitle)">{{ finalTitle }}</strong>
            <span
              v-if="(showSubtitle && finalSubtitle)"
              class="stats-subtext">
              {{ finalSubtitle }}
            </span>
            <br v-if="(showTip && finalTip)">
            <span v-if="(showTip && finalTip)">
              {{ finalTip }}
            </span>
            <apexchart
              :options="campaignImpressionChart"
              :series="campaignImpressionSeries"
              :height="height"
              ref="apexchart"
              type="bar"/>
          </div>
        </div>
      </div>
      <div
        v-if="widget === 'clicktimeline' && hitsTimelineSeries.length > 0"
        class="columns">
        <div class="column is-full">
          <div>
            <strong v-if="(showTitle && finalTitle)">{{ finalTitle }}</strong>
            <span
              v-if="(showSubtitle && finalSubtitle)"
              class="stats-subtext">
              {{ finalSubtitle }}
            </span>
            <br v-if="(showTip && finalTip)">
            <span v-if="(showTip && finalTip)">
              {{ finalTip }}
            </span>
            <apexchart
              :options="hitsTimeline"
              :series="hitsTimelineSeries"
              :height="height"
              ref="apexchart"
              type="bar"/>
          </div>
        </div>
      </div>
      <div
        v-if="widget === 'submissions'"
        class="columns">
        <div class="column is-full">
          <div>
            <strong v-if="(showTitle && finalTitle)">{{ finalTitle }}</strong>
            <span
              v-if="(showSubtitle && finalSubtitle)"
              class="stats-subtext">
              {{ finalSubtitle }}
            </span>
            <br v-if="(showTip && finalTip)">
            <span v-if="(showTip && finalTip)">
              {{ finalTip }}
            </span>
            <apexchart
              :options="formTimeline"
              :series="formTimelineSeries"
              :height="height"
              ref="apexchart"
              type="line"/>
          </div>
        </div>
      </div>
      <div
        v-if="widget === 'clickordertimeline'"
        class="columns">
        <div class="column is-full">
          <div>
            <strong v-if="(showTitle && finalTitle)">{{ finalTitle }}</strong>
            <span
              v-if="(showSubtitle && finalSubtitle)"
              class="stats-subtext">
              {{ finalSubtitle }}
            </span>
            <br v-if="(showTip && finalTip)">
            <span v-if="(showTip && finalTip)">
              {{ finalTip }}
            </span>
            <apexchart
              :options="clickOrderTimeline"
              :series="clickOrderTimelineSeries"
              :height="height"
              ref="apexchart"
              type="line"/>
          </div>
        </div>
      </div>
      <div
        v-if="widget === 'orderrevenuetimeline' && showChart"
        class="columns">
        <div class="column is-full">
          <div>
            <strong v-if="(showTitle && finalTitle)">{{ finalTitle }}</strong>
            <span
              v-if="(showSubtitle && finalSubtitle)"
              class="stats-subtext">
              {{ finalSubtitle }}
            </span>
            <br v-if="(showTip && finalTip)">
            <span v-if="(showTip && finalTip)">
              {{ finalTip }}
            </span>
            <apexchart
              :options="orderRevenueTimeline"
              :series="orderRevenueTimelineSeries"
              :height="height"
              ref="apexchart"
              type="line"/>
          </div>
        </div>
      </div>
      <div
        v-if="widget === 'orderchart' && showChart"
        class="columns">
        <div class="column is-full">
          <div>
            <strong v-if="(showTitle && finalTitle)">{{ finalTitle }}</strong>
            <span
              v-if="(showSubtitle && finalSubtitle)"
              class="stats-subtext">
              {{ finalSubtitle }}
            </span>
            <br v-if="(showTip && finalTip)">
            <span v-if="(showTip && finalTip)">
              {{ finalTip }}
            </span>
            <apexchart
              :options="orderChartOptions"
              :series="orderChartSeries"
              :height="height"
              ref="apexchart"
              type="bar"/>
          </div>
        </div>
      </div>
      <div
        v-if="widget === 'formchart' && showChart"
        class="columns">
        <div class="column is-full">
          <div>
            <strong v-if="(showTitle && finalTitle)">{{ finalTitle }}</strong>
            <span
              v-if="(showSubtitle && finalSubtitle)"
              class="stats-subtext">
              {{ finalSubtitle }}
            </span>
            <br v-if="(showTip && finalTip)">
            <span v-if="(showTip && finalTip)">
              {{ finalTip }}
            </span>
            <apexchart
              :options="formChartOptions"
              :series="formChartSeries"
              :height="height"
              ref="apexchart"
              type="bar"/>
          </div>
        </div>
      </div>
      <div
        v-if="(widget === 'orderchannel' || widget === 'submissionchannel' || widget === 'revenuechannel') && channelGroupTotal > 0"
        class="columns">
        <div class="column is-full channel-group-column">
          <div>
            <strong v-if="(showTitle && finalTitle)">{{ finalTitle }}</strong>
            <span
              v-if="(showSubtitle && finalSubtitle)"
              class="stats-subtext">
              {{ finalSubtitle }}
            </span>
            <br v-if="(showTip && finalTip)">
            <span v-if="(showTip && finalTip)">
              {{ finalTip }}
            </span>
            <div>
              <div
                v-for="(channel, index) in channelGroupDataForDisplay"
                :key="'channel-meter-' + index"
                class="channel-meter">
                <div
                  v-for="(value, key) in channel"
                  :key="'channel-meter-div-' + key">
                  <span v-if="value>=0">
                    <div class="meter">
                      <progress class="progress is-animated is-channel" :class="getChannelMeterClass(key)" :value="value" :max="channelGroupTotal"></progress>
                      <div>
                        <animated-number
                          :value="value"
                          :formatValue="getChannelMeterFormat(widget)"
                          :duration="1000"/>
                      </div>
                    </div>
                    <div class="text">
                      <span v-if="key === 'website'">
                        <span
                          class="icon has-text-grey-dark"
                          alt="Your website"
                          title="Your website">
                          <i class="fas fa-globe"/>
                        </span>
                        <span class="text">Website</span>
                      </span>
                      <span v-else-if="key === 'email'">
                        <span
                          class="icon has-text-grey-dark"
                          alt="Email"
                          title="Email">
                          <i class="fas fa-envelope"/>
                        </span>
                        <span class="text">Email</span>
                      </span>
                      <span v-else-if="key === 'print'">
                        <span
                          class="icon has-text-grey-dark"
                          alt="Print flyer"
                          title="Print flyer">
                          <i class="fas fa-qrcode"/>
                        </span>
                        <span class="text">Print</span>
                      </span>
                      <span v-else-if="key === 'app'">
                        <span
                          class="icon has-text-grey-dark"
                          alt="Mobile app"
                          title="Mobile app">
                          <i class="fas fa-mobile"/>
                        </span>
                        <span class="text">App</span>
                      </span>
                      <span v-else-if="key === 'facebook'">
                        <span
                          class="icon has-text-grey-dark"
                          alt="Facebook"
                          title="Facebook">
                          <i class="fab fa-facebook"/>
                        </span>
                        <span class="text">Facebook</span>
                      </span>
                      <span v-else-if="key === 'twitter'">
                        <span
                          class="icon has-text-grey-dark"
                          alt="Twitter"
                          title="Twitter">
                          <i class="fab fa-twitter"/>
                        </span>
                        <span class="text">Twitter</span>
                      </span>
                      <span v-else-if="key === 'google'">
                        <span
                          class="icon has-text-grey-dark"
                          alt="Google My Business"
                          title="Google My Business">
                          <i class="fab fa-google"/>
                        </span>
                        <span class="text">Google</span>
                      </span>
                      <span v-else-if="key === 'direct'">
                        <span
                          class="icon has-text-grey-dark"
                          alt="Direct"
                          title="Direct">
                          <i class="fas fa-sign-out-alt"/>
                        </span>
                        <span class="text">Direct</span>
                      </span>
                      <span v-else-if="key === 'other'">
                        <span
                          class="icon has-text-grey-dark"
                          alt="Other"
                          title="Other">
                          <i class="fas fa-question"/>
                        </span>
                        <span class="text">Other</span>
                      </span>
                      <span
                        v-else
                        class="text">
                        {{key}}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import AnimatedNumber from 'animated-number-vue'

export default {
  components: {
    AnimatedNumber
  },
  mixins: [xhr],
  props: {
    widget: {
      type: String,
      default: 'metrics' // the default widget type. metrics, timeline, clicktimeline, channelimpressions, recentcampaigns
    },
    showTitle: {
      type: Boolean,
      default: true // show the widget title
    },
    showSubtitle: {
      type: Boolean,
      default: true // show the widget subtitle
    },
    showTip: {
      type: Boolean,
      default: true // show mini tips under the widget
    },
    title: {
      type: String,
      default: null // if passed will replace the default title
    },
    subtitle: {
      type: String,
      default: null // if passed will replace the default subtitle
    },
    tip: {
      type: String,
      default: null // if passed will replace the default tip
    },
    hideMetricFooter: {
      type: Boolean,
      default: false // (metric mode ONLY) show footer text under the metrics
    },
    onlyShowMetrics: {
      type: Array, // list of metrics to show, otherwise it will show all of them. campaigns, impressions, clicks, conversions, revenue
      default: function () {
        return []
      }
    },
    metricClickMode: {
      type: Boolean,
      default: true // (metric mode ONLY) allows metrics to be clicked with effects
    },
    selectedMetric: {
      type: String,
      default: null // (metric mode ONLY) the currently selected metric, we use this for display stuff. value should me a metric like: campaigns, impressions, clicks, conversions, revenue
    },
    width: {
      type: Number, // the width of the chart
      default: 300
    },
    height: {
      type: Number, // the height of the chart
      default: 175
    },
    daysBack: {
      type: String, // number of days back as a string, "30", "60" or the string "range", which then the search.range value is used
      default: null
    },
    search: {
      type: Object, // object is converted to qs and passed to the api call. includes things like: search.range
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      defaultDays: 365,
      widgetLoaded: false,
      metric: {
        campaigns: 0,
        impressions: 0,
        clicks: 0,
        submissions: 0,
        conversions: 0,
        revenue: 0
      },
      campaignClickChart: {},
      campaignClickSeries: [
        {
          name: 'Impressions',
          data: []
        },
        {
          name: 'Clicks',
          data: []
        },
        {
          name: 'Conversions',
          data: []
        },
        {
          name: 'Revenue',
          data: []
        }
      ],
      campaignImpressionChart: {},
      campaignImpressionSeries: [],
      timeline: {
        chart: {
          type: 'area',
          fontFamily: 'Poppins, sans-serif',
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false
            },
            autoSelected: 'zoom'
          },
          animations: {
            enabled: true,
            easing: 'linear',
            animateGradually: {
              enabled: true,
              delay: 500
            },
            dynamicAnimation: {
              enabled: true,
              speed: 0
            }
          }
        },
        colors: ['#0D25BB', '#ff9c39', '#1FAF70'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        legend: {
          position: 'bottom',
          offsetY: 8,
          markers: {
            radius: 20,
            offsetX: 15
          },
          itemMargin: {
            horizontal: 8
          }
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: [
          {
            seriesName: 'Clicks',
            floating: false,
            decimalsInFloat: 0,
            title: {
              text: 'Clicks'
            }
          },
          {
            seriesName: 'Orders',
            opposite: true,
            floating: false,
            decimalsInFloat: 0,
            title: {
              text: 'Orders'
            },
            labels: {
              formatter: function (w) {
                // doing this to assure orders is never a decimal
                var total = w
                if (!total) total = 0
                total = parseInt(total)
                return total.toFixed(0)
              }
            }
          },
          {
            seriesName: 'Revenue',
            opposite: true,
            labels: {
              formatter: function (w) {
                var total = w
                if (!total) total = 0
                return '$' + total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            }
          }
        ]
      },
      timelineSeries: [
        {
          name: 'Clicks',
          type: 'line',
          data: []
        },
        {
          name: 'Orders',
          type: 'line',
          data: []
        },
        {
          name: 'Revenue',
          type: 'area',
          data: []
        }
      ],
      hitsTimeline: {
        chart: {
          type: 'bar',
          fontFamily: 'Poppins, sans-serif',
          stacked: false,
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false
            },
            autoSelected: 'pan'
          },
          animations: {
            enabled: true,
            easing: 'linear',
            animateGradually: {
              enabled: true,
              delay: 500
            },
            dynamicAnimation: {
              enabled: true,
              speed: 0
            }
          }
        },
        colors: [ '#24C980', '#EDA22F', '#FFE400', '#FF3838', '#980dbb', '#3b5998', '#1DA1F2', '#4c8bf5', '#919091' ],
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top'
            }
          }
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          title: {
            text: 'Clicks'
          }
        },
        dataLabels: {
          enabled: false,
          offsetY: -20,
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 4,
            opacity: 0.1
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Poppins, sans-serif',
            colors: undefined
          }
        },
        stroke: {
          curve: 'smooth',
          width: 2
        },
        legend: {
          position: 'bottom',
          offsetY: 8,
          markers: {
            radius: 20,
            offsetX: 15
          },
          itemMargin: {
            horizontal: 8
          }
        },
        fill: {
          opacity: 1
        }
      },
      hitsTimelineSeries: [],
      formTimeline: {
        chart: {
          type: 'area',
          fontFamily: 'Poppins, sans-serif',
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false
            },
            autoSelected: 'zoom'
          },
          animations: {
            enabled: true,
            easing: 'linear',
            animateGradually: {
              enabled: true,
              delay: 500
            },
            dynamicAnimation: {
              enabled: true,
              speed: 0
            }
          }
        },
        colors: ['#228aff', '#980dbb'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        legend: {
          position: 'bottom',
          offsetY: 8,
          markers: {
            radius: 20,
            offsetX: 15
          },
          itemMargin: {
            horizontal: 8
          }
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: [
          {
            seriesName: 'Submissions',
            labels: {
              formatter: (value) => { if (value % 1 !== 0) { return '' } else { return value + '' } }
            },
            title: {
              text: 'Submissions'
            }
          },
          {
            seriesName: 'Clicks',
            opposite: true,
            labels: {
              formatter: (value) => { if (value % 1 !== 0) { return '' } else { return value + '' } }
            },
            title: {
              text: 'Clicks'
            }
          }
        ]
      },
      formTimelineSeries: [
        {
          name: 'Submissions',
          type: 'line',
          data: []
        },
        {
          name: 'Clicks',
          type: 'line',
          data: []
        }
      ],
      clickOrderTimeline: {
        chart: {
          type: 'area',
          fontFamily: 'Poppins, sans-serif',
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false
            },
            autoSelected: 'zoom'
          },
          animations: {
            enabled: true,
            easing: 'linear',
            animateGradually: {
              enabled: true,
              delay: 500
            },
            dynamicAnimation: {
              enabled: true,
              speed: 0
            }
          }
        },
        colors: ['#0D25BB', '#980dbb'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        legend: {
          position: 'bottom',
          offsetY: 8,
          markers: {
            radius: 20,
            offsetX: 15
          },
          itemMargin: {
            horizontal: 8
          }
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: [
          {
            seriesName: 'Orders',
            labels: {
              formatter: (value) => { if (value % 1 !== 0) { return '' } else { return value + '' } }
            },
            title: {
              text: 'Orders'
            }
          },
          {
            seriesName: 'Clicks',
            opposite: true,
            labels: {
              formatter: (value) => { if (value % 1 !== 0) { return '' } else { return value + '' } }
            },
            title: {
              text: 'Clicks'
            }
          }
        ]
      },
      clickOrderTimelineSeries: [
        {
          name: 'Orders',
          type: 'line',
          data: []
        },
        {
          name: 'Clicks',
          type: 'line',
          data: []
        }
      ],
      orderRevenueTimeline: {
        chart: {
          type: 'line',
          fontFamily: 'Poppins, sans-serif',
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false
            },
            autoSelected: 'zoom'
          },
          animations: {
            enabled: true,
            easing: 'linear',
            animateGradually: {
              enabled: true,
              delay: 500
            },
            dynamicAnimation: {
              enabled: true,
              speed: 0
            }
          }
        },
        colors: ['#24C980', '#980dbb'],
        plotOptions: {
          bar: {
            borderRadius: 0
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        legend: {
          position: 'bottom',
          offsetY: 8,
          markers: {
            radius: 20,
            offsetX: 15
          },
          itemMargin: {
            horizontal: 8
          }
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: [
          {
            seriesName: 'Revenue',
            title: {
              text: 'Revenue'
            },
            labels: {
              formatter: function (w) {
                var total = w
                if (!total) total = 0
                return '$' + total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            }
          },
          {
            seriesName: 'Orders',
            title: {
              text: 'Orders'
            },
            opposite: true,
            labels: {
              formatter: (value) => { if (value % 1 !== 0) { return '' } else { return value + '' } }
            }
          }
        ]
      },
      orderRevenueTimelineSeries: [
        {
          name: 'Revenue',
          type: 'line',
          data: []
        },
        {
          name: 'Orders',
          type: 'bar',
          data: []
        }
      ],
      orderChartOptions: {
        chart: {
          type: 'bar',
          fontFamily: 'Poppins, sans-serif',
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false
            },
            autoSelected: 'zoom'
          },
          animations: {
            enabled: true,
            easing: 'linear',
            animateGradually: {
              enabled: true,
              delay: 500
            },
            dynamicAnimation: {
              enabled: true,
              speed: 0
            }
          }
        },
        colors: ['#980dbb'],
        plotOptions: {
          bar: {
            borderRadius: 0
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          seriesName: 'Orders',
          labels: {
            formatter: (value) => { if (value % 1 !== 0) { return '' } else { return value + '' } }
          },
          title: {
            text: 'Orders'
          }
        }
      },
      orderChartSeries: [{
        name: 'Orders',
        data: []
      }],
      formChartOptions: {
        chart: {
          type: 'bar',
          fontFamily: 'Poppins, sans-serif',
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false
            },
            autoSelected: 'zoom'
          },
          animations: {
            enabled: true,
            easing: 'linear',
            animateGradually: {
              enabled: true,
              delay: 500
            },
            dynamicAnimation: {
              enabled: true,
              speed: 0
            }
          }
        },
        colors: ['#228aff'],
        plotOptions: {
          bar: {
            borderRadius: 0
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          seriesName: 'Submissions',
          labels: {
            formatter: (value) => { if (value % 1 !== 0) { return '' } else { return value + '' } }
          },
          title: {
            text: 'Submissions'
          }
        }
      },
      formChartSeries: [{
        name: 'Submissions',
        data: []
      }],
      channelGroupData: [],
      channelGroupDataForDisplay: [],
      channelGroupTotal: 0,
      showChart: false
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    }),
    metricClass: function () {
      if (this.onlyShowMetrics.length === 0 || this.onlyShowMetrics.length === 5) return ['is-half-tablet', 'is-one-fifth-desktop']
      if (this.onlyShowMetrics.length === 4) return ['is-one-quarter-tablet', 'is-one-quarter-desktop', 'is-half-mobile']
      if (this.onlyShowMetrics.length === 3) return ['is-one-third-tablet', 'is-one-third-desktop']
      if (this.onlyShowMetrics.length === 2) return ['is-half-tablet', 'is-one-quarter-desktop', 'is-half-mobile']
      if (this.onlyShowMetrics.length === 1) return ['is-half-tablet', 'is-one-quarter-desktop', 'is-half-mobile']
      return []
    },
    finalTitle: function () {
      if (this.title) return this.title
      else if (this.widget === 'metrics') return 'Key Metrics'
      else if (this.widget === 'recentcampaigns') return 'Campaign Performance'
      else if (this.widget === 'channelimpressions') return 'Channel Impressions'
      else if (this.widget === 'timeline') return 'Timeline'
      else if (this.widget === 'clicktimeline') return 'Click Timeline'
      else if (this.widget === 'submissions') return 'Submission Timeline'
      else if (this.widget === 'orderchart') return 'Order Timeline'
      else if (this.widget === 'formchart') return 'Submission Timeline'
      else if (this.widget === 'orderchannel') return 'Channels'
      else if (this.widget === 'submissionchannel') return 'Submissions'
      else if (this.widget === 'revenuechannel') return 'Revenue'
      return null
    },
    finalSubtitle: function () {
      if (this.subtitle) return this.subtitle
      else if (this.widget === 'metrics') return '(Past 30 Days)'
      else if (this.widget === 'recentcampaigns') return '(Recently Sent)'
      else if (this.widget === 'channelimpressions') return '(Past 30 Days)'
      else if (this.widget === 'timeline') return '(Past 30 Days)'
      else if (this.widget === 'clicktimeline') return '(Past 30 Days)'
      else if (this.widget === 'submissions') return '(Past 30 Days)'
      else if (this.widget === 'orderchart') return 'Timeline'
      else if (this.widget === 'formchart') return '(Past 30 Days)'
      else if (this.widget === 'orderchannel') return 'Top Channels'
      else if (this.widget === 'submissionchannel') return 'Top Channels'
      else if (this.widget === 'revenuechannel') return 'Top Channels'
      return null
    },
    finalTip: function () {
      if (this.tip) return this.tip
      else if (this.widget === 'metrics') return 'What\'s happening at a glance?'
      else if (this.widget === 'recentcampaigns') return 'How are my recent campaigns performing?'
      else if (this.widget === 'channelimpressions') return 'Where are my customers seeing my marketing messages?'
      else if (this.widget === 'timeline') return 'How are my marketing campaigns performing this month?'
      else if (this.widget === 'clicktimeline') return 'Where are my customers clicking from?'
      else if (this.widget === 'submissions') return 'When did my customers fill out my forms?'
      else if (this.widget === 'orderchart') return 'When did my orders happen?'
      else if (this.widget === 'formchart') return 'When did my customers fill out my forms?'
      else if (this.widget === 'orderchannel') return 'Which channels did my orders come from?'
      else if (this.widget === 'submissionchannel') return 'Which channels did my form submissions come from?'
      else if (this.widget === 'revenuechannel') return 'Which channels did my revenue come from?'
      return null
    },
    showImpressionChart: function () {
      if (this.campaignImpressionSeries) {
        if (this.campaignImpressionSeries.length > 0) {
          var total = 0
          this.campaignImpressionSeries.forEach(function (channel) {
            if (channel.data) total += channel.data[0]
          })
          if (total > 0) return true
        }
      }
      return false
    },
    currencySettings: function () {
      // the various currency settings
      // code - use this if we need to match something
      // symbol - added to the front of all currency digits
      // notation - added to the end of all currency digits, along with an initial space
      var usd = {
        code: 'usd',
        symbol: '$',
        notation: ''
      }
      var cad = {
        code: 'cad',
        symbol: '$',
        notation: 'CAD'
      }
      var eur = {
        code: 'eur',
        symbol: '€',
        notation: ''
      }
      // the default
      var resp = usd
      // if the account has some settings, change the currency settings
      // basically we'll use whatever the payment processor is set to, otherwise
      // we use the general settings
      if (this.currentAccount) {
        if (this.currentAccount.currency === 'CAD') {
          resp = cad
        } else if (this.currentAccount.currency === 'EUR') {
          resp = eur
        }
      }
      return resp
    }
  },
  watch: {
    daysBack: {
      handler: function (val) {
        this.fetchStatistics()
      }
    },
    search: {
      deep: true,
      handler: function (val) {
        this.fetchStatistics()
      }
    },
    widgetLoaded: {
      handler: function (val) {
        if (val) this.$emit('input', this.metric) // spit back the metric, we can use this in parent components
      }
    }
  },
  mounted () {
    if (this.currencySettings.code === 'eur') {
      this.orderRevenueTimeline.yaxis[0].labels = {
        formatter: function (w) {
          var total = w
          if (!total) total = 0
          return '€' + total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
      }
    }
    // fetch the stats
    this.fetchStatistics()
  },
  methods: {
    fetchStatistics () {
      this.$store.dispatch('setLoadingState', true)
      this.showChart = false // reset this
      var vm = this
      if (this.widget === 'metrics') {
        engineApi.fetchStatistics(
          vm.currentAccount.id, 'ordermetrics', vm.daysBack, vm.search,
          function (err, response) {
            if (err) {
              vm.$store.dispatch('setLoadingState', false)
              vm.handleXhrError(err)
            } else {
              vm.buildMetrics('order', response)
              engineApi.fetchStatistics(
                vm.currentAccount.id, 'campaignmetrics', vm.daysBack, vm.search,
                function (err, response) {
                  vm.widgetLoaded = true
                  vm.$store.dispatch('setLoadingState', false)
                  if (err) {
                    vm.handleXhrError(err)
                  } else {
                    vm.buildMetrics('campaign', response)
                  }
                }
              )
            }
          }
        )
      } else if (this.widget === 'recentcampaigns') {
        engineApi.fetchStatistics(
          vm.currentAccount.id, 'recentcampaigns', vm.daysBack, vm.search,
          function (err, response) {
            vm.widgetLoaded = true
            if (err) {
              vm.$store.dispatch('setLoadingState', false)
              vm.handleXhrError(err)
            } else {
              vm.buildRecentlySentChart(response)
              vm.$store.dispatch('setLoadingState', false)
            }
          }
        )
      } else if (this.widget === 'channelimpressions') {
        engineApi.fetchStatistics(
          vm.currentAccount.id, 'channelimpressions', vm.daysBack, vm.search,
          function (err, response) {
            vm.widgetLoaded = true
            if (err) {
              vm.$store.dispatch('setLoadingState', false)
              vm.handleXhrError(err)
            } else {
              vm.buildImpressionsChart(response)
              vm.$store.dispatch('setLoadingState', false)
            }
          }
        )
      } else if (this.widget === 'timeline') {
        engineApi.fetchStatistics(
          vm.currentAccount.id, 'hitsovertimebydate', vm.daysBack, vm.search,
          function (err, response) {
            if (err) {
              vm.$store.dispatch('setLoadingState', false)
              vm.handleXhrError(err)
            } else {
              var hits = response
              engineApi.fetchStatistics(
                vm.currentAccount.id, 'ordersovertimebydate', vm.daysBack, vm.search,
                function (err, response) {
                  vm.widgetLoaded = true
                  vm.$store.dispatch('setLoadingState', false)
                  if (err) {
                    vm.handleXhrError(err)
                  } else {
                    vm.buildTimelineChart(hits, 'hits')
                    vm.buildTimelineChart(response, 'orders')
                    vm.buildTimelineChart(response, 'revenue')
                  }
                }
              )
            }
          }
        )
      } else if (this.widget === 'clicktimeline') {
        engineApi.fetchStatistics(
          vm.currentAccount.id, 'hitsovertimebysource', vm.daysBack, vm.search,
          function (err, response) {
            vm.widgetLoaded = true
            if (err) {
              vm.$store.dispatch('setLoadingState', false)
              vm.handleXhrError(err)
            } else {
              vm.buildHitsChart(response)
              vm.$store.dispatch('setLoadingState', false)
            }
          }
        )
      } else if (this.widget === 'submissions') {
        engineApi.fetchStatistics(
          vm.currentAccount.id, 'hitsovertimebydate', vm.daysBack, vm.search,
          function (err, response) {
            if (err) {
              vm.$store.dispatch('setLoadingState', false)
              vm.handleXhrError(err)
            } else {
              var hits = response
              engineApi.fetchStatistics(
                vm.currentAccount.id, 'submissionsovertimebydate', vm.daysBack, vm.search,
                function (err, response) {
                  vm.widgetLoaded = true
                  vm.$store.dispatch('setLoadingState', false)
                  if (err) {
                    vm.handleXhrError(err)
                  } else {
                    vm.buildSubmissionsChart(hits, 'hits')
                    vm.buildSubmissionsChart(response, 'submissions')
                  }
                }
              )
            }
          }
        )
      } else if (this.widget === 'clickordertimeline') {
        engineApi.fetchStatistics(
          vm.currentAccount.id, 'hitsovertimebydate', vm.daysBack, vm.search,
          function (err, response) {
            if (err) {
              vm.$store.dispatch('setLoadingState', false)
              vm.handleXhrError(err)
            } else {
              var hits = response
              engineApi.fetchStatistics(
                vm.currentAccount.id, 'ordersovertimebydate', vm.daysBack, vm.search,
                function (err, response) {
                  vm.widgetLoaded = true
                  vm.$store.dispatch('setLoadingState', false)
                  if (err) {
                    vm.handleXhrError(err)
                  } else {
                    vm.buildClickOrderTimeline(hits, 'hits')
                    vm.buildClickOrderTimeline(response, 'orders')
                  }
                }
              )
            }
          }
        )
      } else if (this.widget === 'orderrevenuetimeline') {
        engineApi.fetchStatistics(
          vm.currentAccount.id, 'ordersovertimebydate', vm.daysBack, vm.search,
          function (err, response) {
            vm.widgetLoaded = true
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            } else {
              vm.buildOrderRevenueTimeline(response, 'orders')
              vm.buildOrderRevenueTimeline(response, 'revenue')
            }
          }
        )
      } else if (this.widget === 'orderchart') {
        engineApi.fetchStatistics(
          vm.currentAccount.id, 'ordersovertimebydate', vm.daysBack, vm.search,
          function (err, response) {
            vm.widgetLoaded = true
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            } else {
              vm.buildChart('orders', response)
            }
          }
        )
      } else if (this.widget === 'formchart') {
        engineApi.fetchStatistics(
          vm.currentAccount.id, 'submissionsovertimebydate', vm.daysBack, vm.search,
          function (err, response) {
            vm.widgetLoaded = true
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            } else {
              vm.buildChart('forms', response)
            }
          }
        )
      } else if (this.widget === 'orderchannel') {
        engineApi.fetchStatistics(
          vm.currentAccount.id, 'channelgrouporders', vm.daysBack, vm.search,
          function (err, response) {
            vm.widgetLoaded = true
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            } else {
              vm.buildChannelGroup(response)
            }
          }
        )
      } else if (this.widget === 'submissionchannel') {
        engineApi.fetchStatistics(
          vm.currentAccount.id, 'channelgroupforms', vm.daysBack, vm.search,
          function (err, response) {
            vm.widgetLoaded = true
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            } else {
              vm.buildChannelGroup(response)
            }
          }
        )
      } else if (this.widget === 'revenuechannel') {
        engineApi.fetchStatistics(
          vm.currentAccount.id, 'channelgrouprevenue', vm.daysBack, vm.search,
          function (err, response) {
            vm.widgetLoaded = true
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            } else {
              vm.buildChannelGroup(response)
            }
          }
        )
      }
    },
    calculateTotals (actions) {
      if (actions) {
        if (actions.length >= 0) {
          var t = 0
          var c = 0
          var v = 0
          var r = 0
          var i = 0
          actions.forEach(function (action) {
            t = t + action.total
            c = c + action.clicks
            r = r + action.revenue
            i = i + action.impressions
            if (action.action_subtype === 'paid') {
              v = v + action.conversions
            } else {
              v = v + action.submissions
            }
          })
          this.metric.campaigns = t
          this.metric.clicks = c
          this.metric.conversions = v
          this.metric.revenue = r
          this.metric.impressions = i
        }
      }
    },
    buildMetrics (type, metrics) {
      if (metrics) {
        if (metrics.length === 1) {
          metrics = metrics[0]
        }
      }
      if (metrics) {
        if (type === 'order') {
          this.metric.clicks = metrics.hits ? metrics.hits : 0
          this.metric.conversions = metrics.orders ? metrics.orders : 0
          this.metric.submissions = metrics.submissions ? metrics.submissions : 0
          this.metric.revenue = metrics.total ? metrics.total : 0
        } else if (type === 'campaign') {
          this.metric.campaigns = metrics.total ? metrics.total : 0
          this.metric.impressions = metrics.impressions ? metrics.impressions : 0
        }
      }
    },
    buildRecentlySentChart (campaigns) {
      var vm = this
      if (campaigns) {
        if (campaigns.length > 0) {
          var titles = []
          campaigns.forEach(function (campaign) {
            titles.push(campaign.title)
            vm.campaignClickSeries[0].data.push(campaign.impressions || 0) // impressions
            vm.campaignClickSeries[1].data.push(campaign.clicks || 0) // clicks
            vm.campaignClickSeries[2].data.push(campaign.conversions || 0) // conversions
            vm.campaignClickSeries[3].data.push(campaign.conversion_total || 0) // revenue
          })
          vm.campaignClickChart = {
            chart: {
              stacked: false,
              fontFamily: 'Poppins, sans-serif',
              toolbar: {
                show: false
              }
            },
            colors: ['#9427E0', '#0D25BB', '#ff9c39', '#1FAF70'],
            plotOptions: {
              bar: {
                horizontal: false,
                endingShape: 'rounded',
                dataLabels: {
                  position: 'top'
                }
              }
            },
            dataLabels: {
              enabled: true,
              offsetY: -20,
              dropShadow: {
                enabled: true,
                top: 1,
                left: 1,
                blur: 4,
                opacity: 0.1
              },
              style: {
                fontSize: '12px',
                fontFamily: 'Poppins, sans-serif',
                colors: undefined
              },
              formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                if (w.config.series[seriesIndex].name === 'Revenue') {
                  var total = val
                  if (!total) total = 0
                  if (total > 0) {
                    return '$' + total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  } else {
                    return ''
                  }
                } else {
                  if (val === 0) {
                    return ''
                  } else {
                    return val
                  }
                }
              }
            },
            legend: {
              position: 'bottom',
              offsetY: 8,
              markers: {
                radius: 20,
                offsetX: 15
              },
              itemMargin: {
                horizontal: 8
              }
            },
            stroke: {
              colors: ['#fff'],
              lineCap: 'round'
            },
            xaxis: {
              categories: titles
            },
            yaxis: [
              {
                seriesName: 'Impressions',
                floating: false,
                decimalsInFloat: 0,
                title: {
                  text: 'Impressions'
                },
                labels: {
                  formatter: function (w) {
                    // doing this to assure impressions is never a decimal
                    var total = w
                    if (!total) total = 0
                    total = parseInt(total)
                    return total.toFixed(0)
                  }
                }
              },
              {
                seriesName: 'Clicks',
                floating: false,
                decimalsInFloat: 0,
                title: {
                  text: 'Clicks'
                },
                labels: {
                  formatter: function (w) {
                    // doing this to assure clicks is never a decimal
                    var total = w
                    if (!total) total = 0
                    total = parseInt(total)
                    return total.toFixed(0)
                  }
                }
              },
              {
                seriesName: 'Conversions',
                opposite: true,
                floating: false,
                decimalsInFloat: 0,
                title: {
                  text: 'Conversions'
                },
                labels: {
                  formatter: function (w) {
                    // doing this to assure conversions is never a decimal
                    var total = w
                    if (!total) total = 0
                    total = parseInt(total)
                    return total.toFixed(0)
                  }
                }
              },
              {
                seriesName: 'Revenue',
                opposite: true,
                labels: {
                  formatter: function (w) {
                    var total = w
                    if (!total) total = 0
                    return '$' + total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                }
              }
            ],
            fill: {
              opacity: 1
            },
            tooltip: {
              shared: true
            },
            animations: {
              enabled: true,
              easing: 'elastic',
              animateGradually: {
                enabled: true,
                delay: 500
              },
              dynamicAnimation: {
                enabled: true,
                speed: 500
              }
            },
            grid: {
              padding: {
                left: 20,
                right: 0,
                bottom: 30
              }
            },
            responsive: [
              {
                breakpoint: 500,
                options: {
                  chart: {
                    height: 400
                  },
                  legend: {
                    position: 'bottom',
                    offsetY: 20,
                    itemMargin: {
                      horizontal: 10
                    }
                  },
                  xaxis: {
                    labels: {
                      rotateAlways: true,
                      rotate: 0,
                      style: {
                        fontSize: '8px'
                      }
                    }
                  }
                }
              }
            ]
          }
        }
      }
    },
    buildImpressionsChart (impressions) {
      // aka 'Channel Impressions'
      var vm = this
      if (impressions) {
        if (impressions.length === 1) {
          vm.campaignImpressionSeries = [
            {
              name: 'Widget',
              data: [impressions[0].widget]
            },
            {
              name: 'Email',
              data: [impressions[0].email]
            },
            {
              name: 'App',
              data: [impressions[0].gallus]
            },
            {
              name: 'Facebook',
              data: [impressions[0].facebook]
            },
            {
              name: 'Google',
              data: [impressions[0].google]
            }
          ]
          vm.campaignImpressionChart = {
            chart: {
              type: 'bar',
              stacked: true,
              stackType: '100%',
              fontFamily: 'Poppins, sans-serif',
              toolbar: {
                show: false
              }
            },
            colors: [ '#24C980', '#add200', '#980dbb', '#0D25BB', '#228aff' ],
            plotOptions: {
              bar: {
                horizontal: true
              }
            },
            stroke: {
              width: 2,
              colors: ['#fff']
            },
            grid: {
              show: false
            },
            xaxis: {
              categories: ['Impressions'],
              labels: {
                show: false
              },
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false
              }
            },
            yaxis: {
              labels: {
                show: false
              },
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false
              }
            },
            fill: {
              opacity: 1
            },
            legend: {
              position: 'bottom',
              offsetY: 8,
              horizontalAlign: 'center',
              markers: {
                radius: 20,
                offsetX: 15
              },
              itemMargin: {
                horizontal: 8
              }
            }
          }
        }
      }
    },
    buildTimelineChart (campaigns, type) {
      // aka 'Order/Revenue Timeline'
      if (campaigns) {
        if (campaigns.length >= 0) {
          var vm = this
          var points = []
          if (type === 'hits') {
            // add in a series of clicks over time
            //
            // convert the campaign data into points
            campaigns.forEach(function (data) {
              var d = new Date(data.day)
              d.setHours(24, 0, 0, 0)
              d = d.getTime()
              points.push({ 'date': d, 'clicks': data.hits })
            })
            // set the series to an array of timestamps and zeros, i.e. [1552795200000, 0]
            vm.timelineSeries[0].data = this.buildDateSeries(this.daysBack)
            // loop through each day in the series of zeros
            // check to see if that day is in the data set
            // if the day is in the set, update the zero to
            // the data set value.
            vm.timelineSeries[0].data.forEach(function (d, i) {
              vm.$lodash.findIndex(points, function (p, mi) {
                if (p.date === d[0]) {
                  var nDate = p.date
                  var nClicks = p.clicks
                  var nPoint = [nDate, nClicks]
                  vm.timelineSeries[0].data[i] = nPoint
                }
              })
            })
          } else if (type === 'orders') {
            // add in a series of orders over time
            //
            // convert the campaign data into points
            campaigns.forEach(function (data) {
              var d = new Date(data.day)
              d.setHours(24, 0, 0, 0)
              d = d.getTime()
              points.push({ 'date': d, 'orders': data.orders })
            })
            // set the series to an array of timestamps and zeros, i.e. [1552795200000, 0]
            vm.timelineSeries[1].data = this.buildDateSeries(this.daysBack)
            // loop through each day in the series of zeros
            // check to see if that day is in the data set
            // if the day is in the set, update the zero to
            // the data set value.
            vm.timelineSeries[1].data.forEach(function (d, i) {
              vm.$lodash.findIndex(points, function (p, mi) {
                if (p.date === d[0]) {
                  var nDate = p.date
                  var nOrders = p.orders
                  var nPoint = [nDate, nOrders]
                  vm.timelineSeries[1].data[i] = nPoint
                }
              })
            })
          } else if (type === 'revenue') {
            // add in a series of revenue over time
            //
            // convert the campaign data into points
            campaigns.forEach(function (data) {
              var d = new Date(data.day)
              d.setHours(24, 0, 0, 0)
              d = d.getTime()
              points.push({ 'date': d, 'revenue': data.revenue })
            })
            // set the series to an array of timestamps and zeros, i.e. [1552795200000, 0]
            vm.timelineSeries[2].data = this.buildDateSeries(this.daysBack)
            // loop through each day in the series of zeros
            // check to see if that day is in the data set
            // if the day is in the set, update the zero to
            // the data set value.
            vm.timelineSeries[2].data.forEach(function (d, i) {
              vm.$lodash.findIndex(points, function (p, mi) {
                if (p.date === d[0]) {
                  var nDate = p.date
                  var nRevenue = p.revenue
                  var nPoint = [nDate, nRevenue]
                  vm.timelineSeries[2].data[i] = nPoint
                }
              })
            })
            vm.timeline = { ...vm.timeline, ...{} } // HACK to get the chart to re-render
          }
        }
      }
    },
    buildHitsChart (hits) {
      // aka 'Click Timeline'
      if (hits) {
        if (hits.length > 0) {
          var points = {}
          var channels = [
            { name: 'Widget', data: [] },
            { name: 'Website', data: [] },
            { name: 'Email', data: [] },
            { name: 'Print', data: [] },
            { name: 'App', data: [] },
            { name: 'Facebook', data: [] },
            { name: 'Twitter', data: [] },
            { name: 'Google', data: [] },
            { name: 'Other', data: [] }
          ]
          // this loops through each
          hits.forEach(function (data) {
            var d = new Date(data.day)
            d.setHours(24, 0, 0, 0)
            d = d.getTime()
            var dStr = d.toString()
            if (points[dStr]) {
              // we've already created the day
            } else {
              points[dStr] = {} // create a new day bucket
            }
            if (data.cleanMedium === 'widget') {
              if (points[dStr].widget) {
                // we've already set a widget total for the day, add to it
                points[dStr].widget = points[dStr].widget + data.total
              } else {
                points[dStr].widget = data.total
              }
            } else if (data.cleanMedium === 'website') {
              if (points[dStr].website) {
                // we've already set a website total for the day, add to it
                points[dStr].website = points[dStr].website + data.total
              } else {
                points[dStr].website = data.total
              }
            } else if (data.cleanMedium === 'email') {
              if (points[dStr].email) {
                // we've already set a email total for the day, add to it
                points[dStr].email = points[dStr].email + data.total
              } else {
                points[dStr].email = data.total
              }
            } else if (data.cleanMedium === 'gallus_app') {
              if (points[dStr].app) {
                // we've already set an app total for the day, add to it
                points[dStr].app = points[dStr].app + data.total
              } else {
                points[dStr].app = data.total
              }
            } else if (data.cleanMedium === 'qrcode' || data.cleanMedium === 'shorturl') {
              if (points[dStr].print) {
                // we've already set a print total for the day, add to it
                points[dStr].print = points[dStr].print + data.total
              } else {
                points[dStr].print = data.total
              }
            } else if (data.cleanMedium === 'localpost') {
              // counting localpost as google, that's the medium we're pusing in
              if (points[dStr].google) {
                // we've already set a google total for the day, add to it
                points[dStr].google = points[dStr].google + data.total
              } else {
                points[dStr].google = data.total
              }
            } else {
              // need to check the referral
              var r = data.cleanReferral
              var dumpToOther = false
              if (r) {
                if (r.includes('facebook')) {
                  // facebook check
                  if (points[dStr].facebook) {
                    // we've already set a facebook total for the day, add to it
                    points[dStr].facebook = points[dStr].facebook + data.total
                  } else {
                    points[dStr].facebook = data.total
                  }
                } else if (r.includes('twitter') || r === 't.co') {
                  // twitter check
                  if (points[dStr].twitter) {
                    // we've already set a twitter total for the day, add to it
                    points[dStr].twitter = points[dStr].twitter + data.total
                  } else {
                    points[dStr].twitter = data.total
                  }
                } else {
                  // dump to other
                  dumpToOther = true
                }
              } else {
                // dump to other
                dumpToOther = true
              }
              if (dumpToOther) {
                if (points[dStr].other) {
                  // we've already set a other total for the day, add to it
                  points[dStr].other = points[dStr].other + data.total
                } else {
                  points[dStr].other = data.total
                }
              }
            }
          })
          Object.entries(points).forEach(point => {
            let day = point[0]
            day = new Date(parseInt(day))
            let totals = point[1]
            if (totals.widget) {
              channels[0]['data'].push([day, totals.widget])
            }
            if (totals.website) {
              channels[1]['data'].push([day, totals.website])
            }
            if (totals.email) {
              channels[2]['data'].push([day, totals.email])
            }
            if (totals.print) {
              channels[3]['data'].push([day, totals.print])
            }
            if (totals.app) {
              channels[4]['data'].push([day, totals.app])
            }
            if (totals.facebook) {
              channels[5]['data'].push([day, totals.facebook])
            }
            if (totals.twitter) {
              channels[6]['data'].push([day, totals.twitter])
            }
            if (totals.google) {
              channels[7]['data'].push([day, totals.google])
            }
            if (totals.other) {
              channels[8]['data'].push([day, totals.other])
            }
          })
          this.hitsTimelineSeries = channels
          this.hitsTimeline = { ...this.hitsTimeline, ...{} } // HACK to get the chart to re-render
          // var min = new Date()
          // min.setDate(min.getDate() - 5)
          // min.setHours(0, 0, 0, 0)
          // min = min.getTime()
          // var max = new Date()
          // max.setHours(0, 0, 0, 0)
          // max = max.getTime()
          // this.hitsTimeline = {
          //   xaxis: {
          //     min: min.toString(),
          //     max: max.toString()
          //   }
          // }
        }
      }
    },
    buildSubmissionsChart (submissions, type) {
      if (submissions) {
        if (submissions.length >= 0) {
          var vm = this
          var points = []
          if (type === 'submissions') {
            // add in a series of orders over time
            //
            // convert the campaign data into points
            submissions.forEach(function (data) {
              var d = new Date(data.day)
              d.setHours(24, 0, 0, 0)
              d = d.getTime()
              points.push({ 'date': d, 'submissions': data.submissions })
            })
            // set the series to an array of timestamps and zeros, i.e. [1552795200000, 0]
            vm.formTimelineSeries[0].data = this.buildDateSeries(this.daysBack)
            // loop through each day in the series of zeros
            // check to see if that day is in the data set
            // if the day is in the set, update the zero to
            // the data set value.
            vm.formTimelineSeries[0].data.forEach(function (d, i) {
              vm.$lodash.findIndex(points, function (p, mi) {
                if (p.date === d[0]) {
                  var nDate = p.date
                  var nSubmissions = p.submissions
                  var nPoint = [nDate, nSubmissions]
                  vm.formTimelineSeries[0].data[i] = nPoint
                }
              })
            })
          } else if (type === 'hits') {
            // add in a series of clicks over time
            //
            // convert the campaign data into points
            submissions.forEach(function (data) {
              var d = new Date(data.day)
              d.setHours(24, 0, 0, 0)
              d = d.getTime()
              points.push({ 'date': d, 'clicks': data.hits })
            })
            // set the series to an array of timestamps and zeros, i.e. [1552795200000, 0]
            vm.formTimelineSeries[1].data = this.buildDateSeries(this.daysBack)
            // loop through each day in the series of zeros
            // check to see if that day is in the data set
            // if the day is in the set, update the zero to
            // the data set value.
            vm.formTimelineSeries[1].data.forEach(function (d, i) {
              vm.$lodash.findIndex(points, function (p, mi) {
                if (p.date === d[0]) {
                  var nDate = p.date
                  var nClicks = p.clicks
                  var nPoint = [nDate, nClicks]
                  vm.formTimelineSeries[1].data[i] = nPoint
                }
              })
            })
          }
          vm.formTimeline = { ...vm.formTimeline, ...{} } // HACK to get the chart to re-render
        }
      }
    },
    buildClickOrderTimeline (campaigns, type) {
      if (campaigns) {
        if (campaigns.length >= 0) {
          var vm = this
          var points = []
          if (type === 'orders') {
            // add in a series of orders over time
            //
            // convert the campaign data into points
            campaigns.forEach(function (data) {
              var d = new Date(data.day)
              d.setHours(24, 0, 0, 0)
              d = d.getTime()
              points.push({ 'date': d, 'orders': data.orders })
            })
            // set the series to an array of timestamps and zeros, i.e. [1552795200000, 0]
            vm.clickOrderTimelineSeries[0].data = this.buildDateSeries(this.daysBack)
            // loop through each day in the series of zeros
            // check to see if that day is in the data set
            // if the day is in the set, update the zero to
            // the data set value.
            vm.clickOrderTimelineSeries[0].data.forEach(function (d, i) {
              vm.$lodash.findIndex(points, function (p, mi) {
                if (p.date === d[0]) {
                  var nDate = p.date
                  var nOrders = p.orders
                  var nPoint = [nDate, nOrders]
                  vm.clickOrderTimelineSeries[0].data[i] = nPoint
                }
              })
            })
          } else if (type === 'hits') {
            // add in a series of clicks over time
            //
            // convert the campaign data into points
            campaigns.forEach(function (data) {
              var d = new Date(data.day)
              d.setHours(24, 0, 0, 0)
              d = d.getTime()
              points.push({ 'date': d, 'clicks': data.hits })
            })
            // set the series to an array of timestamps and zeros, i.e. [1552795200000, 0]
            vm.clickOrderTimelineSeries[1].data = this.buildDateSeries(this.daysBack)
            // loop through each day in the series of zeros
            // check to see if that day is in the data set
            // if the day is in the set, update the zero to
            // the data set value.
            vm.clickOrderTimelineSeries[1].data.forEach(function (d, i) {
              vm.$lodash.findIndex(points, function (p, mi) {
                if (p.date === d[0]) {
                  var nDate = p.date
                  var nClicks = p.clicks
                  var nPoint = [nDate, nClicks]
                  vm.clickOrderTimelineSeries[1].data[i] = nPoint
                }
              })
            })
          }
          vm.clickOrderTimeline = { ...vm.clickOrderTimeline, ...{} } // HACK to get the chart to re-render
        }
      }
    },
    buildOrderRevenueTimeline (campaigns, type) {
      if (campaigns) {
        if (campaigns.length >= 0) {
          var vm = this
          var points = []
          if (type === 'revenue') {
            // add in a series of revenue over time
            //
            // convert the campaign data into points
            campaigns.forEach(function (data) {
              var d = new Date(data.day)
              d.setHours(24, 0, 0, 0)
              d = d.getTime()
              points.push({ 'date': d, 'revenue': data.revenue })
            })
            // set the series to an array of timestamps and zeros, i.e. [1552795200000, 0]
            vm.orderRevenueTimelineSeries[0].data = this.buildDateSeries(this.daysBack)
            // loop through each day in the series of zeros
            // check to see if that day is in the data set
            // if the day is in the set, update the zero to
            // the data set value.
            vm.orderRevenueTimelineSeries[0].data.forEach(function (d, i) {
              vm.$lodash.findIndex(points, function (p, mi) {
                if (p.date === d[0]) {
                  var nDate = p.date
                  var nRevenue = p.revenue
                  var nPoint = [nDate, nRevenue]
                  vm.orderRevenueTimelineSeries[0].data[i] = nPoint
                  if (p.revenue > 0) vm.showChart = true // we have data for the chart, flip the flag to show it
                }
              })
            })
          } else if (type === 'orders') {
            // add in a series of orders over time
            //
            // convert the campaign data into points
            campaigns.forEach(function (data) {
              var d = new Date(data.day)
              d.setHours(24, 0, 0, 0)
              d = d.getTime()
              points.push({ 'date': d, 'orders': data.orders })
            })
            // set the series to an array of timestamps and zeros, i.e. [1552795200000, 0]
            vm.orderRevenueTimelineSeries[1].data = this.buildDateSeries(this.daysBack)
            // loop through each day in the series of zeros
            // check to see if that day is in the data set
            // if the day is in the set, update the zero to
            // the data set value.
            vm.orderRevenueTimelineSeries[1].data.forEach(function (d, i) {
              vm.$lodash.findIndex(points, function (p, mi) {
                if (p.date === d[0]) {
                  var nDate = p.date
                  var nOrders = p.orders
                  var nPoint = [nDate, nOrders]
                  vm.orderRevenueTimelineSeries[1].data[i] = nPoint
                }
              })
            })
          }
          vm.orderRevenueTimeline = { ...vm.orderRevenueTimeline, ...{} } // HACK to get the chart to re-render
        }
      }
    },
    buildChart (type, data) {
      var vm = this
      var points = []
      if (type === 'orders') {
        var campaigns = data
        if (campaigns) {
          if (campaigns.length >= 0) {
            // add in a series of orders over time
            //
            // convert the campaign data into points
            campaigns.forEach(function (data) {
              var d = new Date(data.day)
              d.setHours(24, 0, 0, 0)
              d = d.getTime()
              points.push({ 'date': d, 'orders': data.orders })
            })
            // set the series to an array of timestamps and zeros, i.e. [1552795200000, 0]
            vm.orderChartSeries[0].data = this.buildDateSeries(this.daysBack)
            // loop through each day in the series of zeros
            // check to see if that day is in the data set
            // if the day is in the set, update the zero to
            // the data set value.
            vm.orderChartSeries[0].data.forEach(function (d, i) {
              vm.$lodash.findIndex(points, function (p, mi) {
                if (p.date === d[0]) {
                  var nDate = p.date
                  var nOrders = p.orders
                  var nPoint = [nDate, nOrders]
                  vm.orderChartSeries[0].data[i] = nPoint
                  if (p.orders > 0) vm.showChart = true // we have data for the chart, flip the flag to show it
                }
              })
            })
            vm.orderChartOptions = { ...vm.orderChartOptions, ...{} } // HACK to get the chart to re-render
          }
        }
      } else if (type === 'forms') {
        var submissions = data
        if (submissions) {
          if (submissions.length >= 0) {
            // add in a series of orders over time
            //
            // convert the campaign data into points
            submissions.forEach(function (data) {
              var d = new Date(data.day)
              d.setHours(24, 0, 0, 0)
              d = d.getTime()
              points.push({ 'date': d, 'submissions': data.submissions })
            })
            // set the series to an array of timestamps and zeros, i.e. [1552795200000, 0]
            vm.formChartSeries[0].data = this.buildDateSeries(this.daysBack)
            // loop through each day in the series of zeros
            // check to see if that day is in the data set
            // if the day is in the set, update the zero to
            // the data set value.
            vm.formChartSeries[0].data.forEach(function (d, i) {
              vm.$lodash.findIndex(points, function (p, mi) {
                if (p.date === d[0]) {
                  var nDate = p.date
                  var nSubmissions = p.submissions
                  var nPoint = [nDate, nSubmissions]
                  vm.formChartSeries[0].data[i] = nPoint
                  if (p.submissions > 0) vm.showChart = true // we have data for the chart, flip the flag to show it
                }
              })
            })
            vm.formChartOptions = { ...vm.formChartOptions, ...{} } // HACK to get the chart to re-render
          }
        }
      }
    },
    buildDateSeries (days) {
      var start = null
      var end = null
      if (typeof days === 'string') {
        if (days === 'range') {
          if (this.search.range) {
            var dates = this.search.range.split(',', 2)
            if (dates) {
              if (dates.length === 2) {
                start = new Date(dates[0])
                start.setHours(0, 0, 0, 0)
                end = new Date(dates[1])
                end.setHours(0, 0, 0, 0)
              }
            }
          }
        } else {
          days = parseInt(days)
        }
      }
      if (!days) days = this.defaultDays
      if (!start || !end) {
        start = new Date()
        start.setHours(0, 0, 0, 0)
        start.setDate(start.getDate() - days)
        end = new Date()
        end.setHours(0, 0, 0, 0)
      }
      var arr = []
      var dt = new Date(start)
      while (dt <= end) {
        var newDate = new Date(dt)
        newDate.setHours(0, 0, 0, 0)
        arr.push([newDate.getTime(), 0])
        dt.setDate(dt.getDate() + 1)
      }
      return arr
    },
    buildChannelGroup (data) {
      var totals = {
        website: 0,
        email: 0,
        print: 0,
        app: 0,
        facebook: 0,
        twitter: 0,
        google: 0,
        direct: 0,
        other: 0
      }
      this.channelGroupTotal = 0 // reset this
      // determine which bucket to add the total to
      var vm = this
      data.forEach(group => {
        vm.channelGroupTotal += group.total // always add to the total
        if (group.cleanMedium === 'widget') {
          totals.website += group.total
        } else if (group.cleanMedium === 'email') {
          totals.email += group.total
        } else if (group.cleanMedium === 'gallus_app') {
          totals.app += group.total
        } else if (group.cleanMedium === 'qrcode' || group.cleanMedium === 'shorturl') {
          totals.print += group.total
        } else if (group.cleanMedium === 'localpost') {
          totals.google += group.total
        } else {
          // need to check the referral
          var r = group.cleanReferral
          if (typeof r === 'string') r = r.trim()
          if (r === '' || r === 'null') r = null
          if (r) {
            if (r.includes('facebook')) {
              totals.facebook += group.total
            } else if (r.includes('twitter') || r === 't.co') {
              totals.twitter += group.total
            } else {
              // dump to its own category
              if (totals[r]) {
                totals[r] += group.total
              } else {
                totals[r] = group.total
              }
            }
          } else if (group.cleanMedium) {
            // no referral but we have a medium, dump this to unknown
            totals.other += group.total
          } else {
            // no referral, no medium we'll assume this is direct
            totals.direct += group.total
          }
        }
      })
      // sort the data by count as an array
      this.channelGroupData = Object.entries(totals).sort(
        (a, b) => b[1] - a[1]
      ).reduce((acc, [key, val]) => {
        acc.push({ [key]: val })
        return acc
      }, [])
      // following logic is to add animation to the bars
      var zeroChannels = [] // use this to store channels with 0
      var display = this.$lodash.cloneDeep(this.channelGroupData).slice(0, 5) // take top five for display
      display.forEach((channel, i) => {
        for (const key in channel) {
          if (channel[key] === 0) {
            zeroChannels.push(i)
          } else {
            channel[key] = 0 // set all values in the display to 0, this is for the animation
          }
        }
      })
      // this for loop removes the channels with 0
      for (var i = zeroChannels.length - 1; i >= 0; i--) {
        display.splice(zeroChannels[i], 1)
      }
      this.channelGroupDataForDisplay = display
      // this is the actual animation
      setTimeout(function () {
        vm.channelGroupDataForDisplay.forEach(function (d, i) {
          for (const key in vm.channelGroupData[i]) {
            d[key] = vm.channelGroupData[i][key]
          }
        })
      }, 250)
    },
    numberFormat (num) {
      if (num) {
        num = parseInt(num)
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return 0
      }
    },
    priceFormat (num) {
      if (num) return this.currencySettings.symbol + num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      else return 'n/a'
    },
    getChannelMeterClass (channel) {
      var c = 'unknown'
      if (channel === 'website' || channel === 'email' || channel === 'print' || channel === 'app' || channel === 'facebook' || channel === 'twitter' || channel === 'google' || channel === 'direct' || channel === 'other') c = channel
      return 'is-' + c
    },
    getChannelMeterFormat (widget) {
      if (widget === 'revenuechannel') {
        return this.priceFormat
      } else {
        return this.numberFormat
      }
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
  .pad-title {
    padding-bottom: 15px;
  }
  .oto-campaign-card {
    border-color: $secondary;
  }
  .oto-campaign-card.is-selected {
    border-color: $secondary;
    background: $primary;
  }
  .stats-subtext {
    font-size: 0.7rem;
    font-weight: 600;
    letter-spacing: -0.25px;
  }
  .channel-meter {
    width:100%;
  }
  .channel-meter > div {
    width:100%;
  }
  .channel-meter > div > span > .meter {
    width:70%;
    float:left;
    text-align:left;
    padding-right: 5px;
    position: relative;
  }
  .channel-meter > div > span > .meter > progress {
    margin: 3px 0px 0px 0px;
  }
  .channel-meter > div > span > .meter > div {
    position: absolute;
    top: 2px;
    right: 18px;
    font-size: 0.8em;
  }
  .channel-meter > div > span > .text {
    width:30%;
    float:left;
    white-space: no-wrap;
    overflow-x: hidden;
  }
  .channel-meter > div > span > .text > .text {
    padding-left: 5px;
    line-height: 1.7em;
    font-size: 0.7em;
    vertical-align: text-top;
  }
  .channel-meter > div > span > .text > span > .text {
    line-height: 1.7em;
    font-size: 0.7em;
    vertical-align: text-top;
  }
  @media screen and (max-width: $mobile-max) {
    .channel-group-column {
      margin-top: -35px;
    }
  }
</style>

export default {
  methods: {
    handleXhrError (error) {
      var handled = false
      if (error) {
        if (error.response) {
          if (error.response.status === 401) {
            this.$router.push({ name: 'route-login', query: { redirect: this.$router.currentRoute.fullPath, expired: true } })
            handled = true
          }
        }
      }
      if (!handled) {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
    }
  }
}

<template>
  <div class="oto-duo">
    <div class="oto-lead">
      <!-- Left Column -->
      <div id="leftColumnTop"/>
      <div class="step-heading is-hidden-mobile">
        <h2 class="subtitle uppercase">
          Campaign: {{ vuexWizardTitle }}
        </h2>
        <h1 class="title">{{ stepTitle }}</h1>
      </div>
      <div
        v-if="hasErrors"
        class="notification is-danger">
        Looks like there is a problem here with your event registration details. Please check below for any highlighted errors.
      </div>
      <div>
        <div
          v-if="mode === 'paid'"
          class="field">
          <Calendar
            :props="registration.registrationDate"
            v-model="registration.registrationDate.model"
            @focus="changeHelp(registration.registrationDate)"/>
        </div>
        <div
          v-if="mode === 'paid'"
          class="field is-grouped">
          <Select
            :props="registration.min"
            v-model="registration.min.model"
            @focus="changeHelp(registration.min)"/>
          <Select
            :props="registration.max"
            v-model="registration.max.model"
            @focus="changeHelp(registration.max)"/>
        </div>
        <div
          v-if="mode === 'paid'"
          class="field is-grouped">
          <TextInput
            :props="registration.capacity"
            v-model="registration.capacity.model"
            @focus="changeHelp(registration.capacity)"/>
        </div>
      </div>
      <div>
        <div
          :class="{'no-top-margin' : mode !== 'paid'}"
          class="subtitle-w-bar">
          Event Tickets
        </div>
        <div v-if="foreupLinked" class="link-section notification">
          <span class="icon is-small">
            <i class="fas fa-link"/>
          </span>
          <span>This campaign is linked to a sales item in foreUP. A sale will be posted in your point-of-sale when a customer makes a purchase.</span>
        </div>
        <div
          v-if="mode === 'paid'"
          class="oto-margin-bottom">
          Enter the details of your event tickets below. These tickets can be purchased online with CampaignPilot.
        </div>
        <div
          v-else
          class="oto-margin-bottom">
          If your event has tickets, enter their details below to generate a pricing table on your event page.
        </div>
        <table class="table is-fullwidth no-margin">
          <thead>
            <tr>
              <th>
                Ticket Name
                <span
                  v-if="mode === 'paid'"
                  class="icon has-text-danger is-small oto-required-field">
                  <i class="fas fa-star-of-life oto-fa-mini"/>
                </span>
              </th>
              <th class="smaller-column-width">
                Price
                <span
                  v-if="mode === 'paid'"
                  class="icon has-text-danger is-small oto-required-field">
                  <i class="fas fa-star-of-life oto-fa-mini"/>
                </span>
              </th>
              <th class="smaller-column-width">
                <div>Quantity</div>
              </th>
              <th v-if="cpsEnabled && mode === 'paid' && ticket.cps">
                Club Prophet Item
              </th>
              <th v-if="foreUPEnabled && mode === 'paid' && ticket.foreup">
                foreUP Item
              </th>
              <th/>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th class="oto-no-bold">
                <TextInput
                  :props="ticket.name"
                  v-model="ticket.name.model"
                  @focus="changeHelp(ticket.name)"/>
              </th>
              <th class="oto-no-bold">
                <TextInput
                  :props="ticket.price"
                  v-model="ticket.price.model"
                  @focus="changeHelp(ticket.name)"/>
              </th>
              <th class="oto-no-bold">
                <TextInput
                  :props="ticket.quantity"
                  v-model="ticket.quantity.model"
                  @focus="changeHelp(ticket.quantity)"/>
              </th>
              <th
                v-if="cpsEnabled && mode === 'paid' && ticket.cps"
                class="oto-no-bold">
                <CPSProduct
                  :props="ticket.cps"
                  v-model="ticket.cps.model"
                  @focus="changeHelp(ticket.cps)"/>
              </th>
              <th
                v-if="foreUPEnabled && mode === 'paid' && ticket.foreup"
                class="oto-no-bold">
                <foreUPProduct
                  :props="ticket.foreup"
                  v-model="ticket.foreup.model"
                  @focus="changeHelp(ticket.foreup)"
                  @input="ticketFromforeUP(ticket)"/>
              </th>
              <th>
                <a
                  class="button is-rounded"
                  @click="addTicket">Add</a>
              </th>
            </tr>
          </tfoot>
          <tbody>
            <tr
              v-for="(t, tindex) in tickets"
              :key="'t' + tindex">
              <td>
                {{ t.name }}
              </td>
              <td>
                ${{ t.price }}
              </td>
              <td>
                {{ t.quantity }}
              </td>
              <td v-if="cpsEnabled && mode === 'paid'">
                <div v-if="t.cps">
                  <div v-if="t.cps.item">
                    {{ t.cps.item.name }} ({{ t.cps.item.id }})
                  </div>
                </div>
              </td>
              <td v-if="foreUPEnabled && mode === 'paid'">
                <div v-if="t.foreup">
                  <div v-if="t.foreup.attributes">
                    {{t.foreup.attributes.name}} : {{t.foreup.attributes.department}}
                  </div>
                </div>
              </td>
              <td>
                <span
                  class="icon is-small show-hand"
                  @click="deleteTicket(tindex)">
                  <i class="fas fa-times-circle"/>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="mode === 'paid'"
          class="field is-horizontal">
          <div class="field-body">
            <input
              id="taxCheckbox"
              v-model="registration.tax.charge"
              name="taxCheckbox"
              class="is-checkradio"
              type="checkbox">
            <label
              for="taxCheckbox"
              class="checkbox-label">
              {{ registration.tax.checkboxLabel }}
            </label>
            <transition name="fade" mode="out-in">
              <TextInput
                v-if="registration.tax.charge"
                :props="registration.tax"
                :control="registration.tax.control"
                :focus="true"
                v-model="registration.tax.model"
                @focus="changeHelp(registration.tax)"/>
              <div v-else style="display: block; height: 60px; width: 100px;"></div>
            </transition>
          </div>
        </div>
      </div>
      <div v-if="mode === 'paid'">
        <!-- ticket add-ons -->
        <div
          v-if="!showTicketOptions"
          id="ticketOptions">
          <a @click="toggleTicketOptions">
            + Ticket Add-Ons (optional)
          </a>
        </div>
        <div
          v-else
          id="ticketOptions">
          <a @click="toggleTicketOptions">
            - Ticket Add-Ons (optional)
          </a>
        </div>
        <div v-if="showTicketOptions">
          <label class="label is-marginless">Add-ons or Additional Items</label>
          <div class="oto-margin-bottom">
            Include add-ons or additional items to sell with your event tickets. These optional items will be added to the ticket price.
          </div>
          <table class="table is-fullwidth no-margin">
            <thead>
              <tr>
                <th>
                  Add-on Name
                  <span
                    v-if="mode === 'paid'"
                    class="icon has-text-danger is-small oto-required-field">
                    <i class="fas fa-star-of-life oto-fa-mini"/>
                  </span>
                </th>
                <th class="smaller-column-width">
                  Price
                  <span
                    v-if="mode === 'paid'"
                    class="icon has-text-danger is-small oto-required-field">
                    <i class="fas fa-star-of-life oto-fa-mini"/>
                  </span>
                </th>
                <th v-if="cpsEnabled && mode === 'paid' && item.cps">
                  Club Prophet Item
                </th>
                <th v-if="foreUPEnabled && mode === 'paid' && item.foreup">
                  foreUP Item
                </th>
                <th/>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th class="oto-no-bold">
                  <TextInput
                    :props="item.name"
                    v-model="item.name.model"
                    @focus="changeHelp(item.name)"/>
                </th>
                <th class="oto-no-bold">
                  <TextInput
                    :props="item.price"
                    v-model="item.price.model"
                    @focus="changeHelp(item.name)"/>
                </th>
                <th
                  v-if="cpsEnabled && mode === 'paid' && item.cps"
                  class="oto-no-bold">
                  <CPSProduct
                    :props="item.cps"
                    v-model="item.cps.model"
                    @focus="changeHelp(item.cps)"/>
                </th>
                <th
                  v-if="foreUPEnabled && mode === 'paid' && item.foreup"
                  class="oto-no-bold">
                  <foreUPProduct
                    :props="item.foreup"
                    v-model="item.foreup.model"
                    @focus="changeHelp(item.foreup)"
                    @input="itemFromforeUP(item)"/>
                </th>
                <th>
                  <a
                    class="button is-rounded"
                    @click="addItem">Add</a>
                </th>
              </tr>
            </tfoot>
            <tbody>
              <tr
                v-for="(i, iindex) in items"
                :key="'i' + iindex">
                <td>
                  {{ i.name }}
                </td>
                <td>
                  ${{ i.price }}
                </td>
                <td v-if="cpsEnabled && mode === 'paid'">
                  <div v-if="i.cps">
                    <div v-if="i.cps.item">
                      {{ i.cps.item.name }} ({{ i.cps.item.id }})
                    </div>
                  </div>
                </td>
                <td v-if="foreUPEnabled && mode === 'paid'">
                  <div v-if="i.foreup">
                    <div v-if="i.foreup.attributes">
                      {{i.foreup.attributes.name}} : {{i.foreup.attributes.department}}
                    </div>
                  </div>
                </td>
                <td>
                  <span
                    class="icon is-small show-hand"
                    @click="deleteItem(iindex)">
                    <i class="fas fa-times-circle"/>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- confirmation page customization -->
        <div
          v-if="!showConfirmation"
          id="customConfirmation">
          <a @click="toggleConfirmation">
            + Customize Confirmation Page
          </a>
        </div>
        <div
          v-if="showConfirmation"
          id="customConfirmation">
          <a @click="toggleConfirmation">
            - Customize Confirmation Page
          </a>
        </div>
        <div v-if="showConfirmation">
          <p class="padding-bottom">You can add custom content to the registration confirmation page and email receipt. This is useful if you need to add any instructions on how to redeem a ticket or for additional event terms and conditions.</p>
          <RichText
            :props="confirmation"
            :super="(currentUser.superadmin === 1) ? true : false"
            v-model="confirmation.model"
            @focus="changeHelp(confirmation)"/>
        </div>
      </div>
    </div>
    <aside class="oto-side">
      <div class="step-heading is-hidden-tablet">
        <h2 class="subtitle uppercase">
          Campaign: {{ vuexWizardTitle }}
        </h2>
        <h1 class="title">
          {{ stepTitle }}
        </h1>
      </div>
      <div class="content">
        <div class="right-column-text">
          {{ stepDescription }}
        </div>
        <div
          v-if="currentHelp"
          id="sticky"
          class="help-notification notification is-hidden-mobile is-primary sticky-div">
          <div class="help-label capitalize">
            {{ currentHelpLabel }}
          </div>
          <div class="help-text">
            {{ currentHelp }}
          </div>
          <span class="icon is-medium has-text-white">
            <i class="fas fa-info-circle fa-lg"/>
          </span>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import sticky from '@/mixins/StickyListener'
import Calendar from '@/components/elements/Calendar'
import RichText from '@/components/elements/RichText'
import TextInput from '@/components/elements/TextInput'
import Select from '@/components/elements/Select'
import CPSProduct from '@/components/elements/CPSProduct'
import foreUPProduct from '@/components/elements/foreUPProduct'

export default {
  components: {
    RichText,
    TextInput,
    Calendar,
    Select,
    CPSProduct,
    foreUPProduct
  },
  mixins: [sticky],
  props: {
    value: {
      type: Object,
      default: null
    },
    hasErrors: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      showTicketOptions: false,
      showConfirmation: false,
      currentHelp: null,
      currentHelpLabel: null,
      registration: {
        registrationDate: {
          model: null,
          name: 'registration-window',
          ref: 'registration-window',
          label: 'Registration Window',
          subtype: 'range-exp',
          placeholder: 'Start Date',
          endPlaceholder: 'Cut Off Date',
          desktopHelp: 'The event registration window is the date span in which you accept registrations. If the current date is before or after the registration window then customers will not be able to register or purchase tickets for your event.',
          mobileHelp: 'The event registration window is the date span in which you accept registrations.'
        },
        capacity: {
          model: null,
          name: 'total-capacity',
          ref: 'total-capacity',
          label: 'Total Event Capacity',
          placeholder: 'Unlimited',
          icon: 'fas fa-user',
          convertTo: 'number',
          desktopHelp: 'If applicable, limit the number of total tickets available. Leave this field empty for unlimited.',
          mobileHelp: 'If applicable, limit the number of total tickets available. Leave empty for unlimited.'
        },
        min: {
          model: '1',
          name: 'min-tickets-per',
          ref: 'min-tickets-per',
          options: [{ 'name': '1', 'value': '1' }, { 'name': '2', 'value': '2' }, { 'name': '3', 'value': '3' }, { 'name': '4', 'value': '4' }, { 'name': '5', 'value': '5' }, { 'name': '6', 'value': '6' }, { 'name': '7', 'value': '7' }, { 'name': '8', 'value': '8' }, { 'name': '9', 'value': '9' }, { 'name': '10', 'value': '10' }],
          label: 'Minimum Tickets Per Purchase',
          icon: 'fas fa-user',
          class: 'is-fullwidth',
          desktopHelp: 'The minimum number of tickets a customer can purchase. If this is set higher to one, your event will force group registration.',
          mobileHelp: 'The minimum number of tickets a customer can purchase.'
        },
        max: {
          model: '10',
          name: 'max-tickets-per',
          ref: 'max-tickets-per',
          options: [{ 'name': '1', 'value': '1' }, { 'name': '2', 'value': '2' }, { 'name': '3', 'value': '3' }, { 'name': '4', 'value': '4' }, { 'name': '5', 'value': '5' }, { 'name': '6', 'value': '6' }, { 'name': '7', 'value': '7' }, { 'name': '8', 'value': '8' }, { 'name': '9', 'value': '9' }, { 'name': '10', 'value': '10' }],
          label: 'Maximum Tickets Per Purchase',
          icon: 'fas fa-user',
          class: 'is-fullwidth',
          desktopHelp: 'The maximum number of tickets a customer can purchase.',
          mobileHelp: 'The maximum number of tickets a customer can purchase.'
        },
        tax: {
          model: null,
          name: 'tax',
          ref: 'tax',
          charge: false,
          checkboxLabel: 'Add Tax to Tickets and Add-ons',
          rightIcon: 'fas fa-percent',
          convertTo: 'float',
          control: {
            divstyle: 'max-width: 100px;'
          },
          desktopHelp: 'When a customer registers for this event you can charge a percent tax calculated against the order total. The total tax charged will appear on a separate line item during checkout and on receipts.'
        }
      },
      ticket: {
        name: {
          model: null,
          name: 'ticket-name',
          ref: 'ticket-name',
          placeholder: 'i.e. Resident Entry',
          helpTitle: 'Event Tickets',
          desktopHelp: 'Your tickets and prices will be displayed to your customer during event registration. A customer can select one ticket per registrant. Ticket prices should be tax inclusive, if applicable.'
        },
        price: {
          model: null,
          name: 'ticket-price',
          ref: 'ticket-price',
          icon: 'fas fa-dollar-sign',
          convertTo: 'price',
          helpTitle: 'Event Tickets',
          desktopHelp: 'Your tickets and prices will be displayed to your customer during event registration. A customer can select one ticket per registrant. Ticket prices should be tax inclusive, if applicable.'
        },
        cps: {
          model: null,
          name: 'ticket-cps',
          ref: 'ticket-cps',
          helpTitle: 'Club Prophet Sales Item',
          desktopHelp: 'You can automatically push event registrations into your Club Prophet point-of-sale system. When a registration is made, a customer will be created in Club Prophet along with an order containing the selected Sales Item.'
        },
        foreup: {
          model: null,
          name: 'ticket-foreup',
          ref: 'ticket-foreup',
          display: 'small',
          searchplaceholder: 'Search',
          helpTitle: 'foreUP Sales Item',
          desktopHelp: 'You can automatically push event registrations into your foreUP point-of-sale system. When a registration is made, a customer will be created in foreUP along with an order containing the selected Sales Item.'
        },
        quantity: {
          model: null,
          name: 'ticket-quantity',
          ref: 'ticket-quantity',
          helpTitle: 'Ticket Quantity',
          icon: 'fas fa-users',
          convertTo: 'number',
          placeholder: 'Unlimited',
          desktopHelp: 'Set this to limit the number of tickets available. Leave this field empty for unlimited.',
          mobileHelp: 'Set this to limit the number of tickets available. Leave empty for unlimited.'
        }
      },
      tickets: [],
      item: {
        name: {
          model: null,
          name: 'addon-name',
          ref: 'addon-name',
          placeholder: 'i.e. Steak Dinner',
          helpTitle: 'Ticket Add-ons',
          desktopHelp: 'Ticket add-ons can be used for additional items or options a customer can select when registering for your event. An add-on can also contain a price that is added to the total ticket price. Add-ons do not need a price if they are used for other options that do not affect the total ticket price.'
        },
        price: {
          model: null,
          name: 'addon-price',
          ref: 'addon-price',
          icon: 'fas fa-dollar-sign',
          convertTo: 'price',
          helpTitle: 'Ticket Add-ons',
          desktopHelp: 'Ticket add-ons can be used for additional items or options a customer can select when registering for your event. An add-on can also contain a price that is added to the total ticket price. Add-ons do not need a price if they are used for other options that do not affect the total ticket price.'
        },
        cps: {
          model: null,
          name: 'addon-cps',
          ref: 'addon-cps',
          helpTitle: 'Club Prophet Sales Item',
          desktopHelp: 'You can automatically push event registrations into your Club Prophet point-of-sale system. When a registration is made, a customer will be created in Club Prophet along with an order containing the selected Sales Item (if they purchase this add-on).'
        },
        foreup: {
          model: null,
          name: 'addon-foreup',
          ref: 'addon-foreup',
          display: 'small',
          searchplaceholder: 'Search',
          helpTitle: 'foreUP Sales Item',
          desktopHelp: 'You can automatically push event registrations into your foreUP point-of-sale system. When a registration is made, a customer will be created in foreUP along with an order containing the selected Sales Item (if they purchase this add-on).'
        }
      },
      items: [],
      confirmation: {
        model: null,
        type: 'text-rich',
        name: 'confirmation content',
        ref: 'confirmation content',
        useNameInError: true,
        height: 300,
        label: 'Custom Content For Confirmation',
        desktopHelp: 'The content you add here will be added below the order details on both the confirmation page and email sent to the customer.'
      }
    }
  },
  computed: {
    ...mapGetters({
      vuexWizardTitle: 'wizardTitle',
      currentAccount: 'currentAccount',
      currentUser: 'currentUser',
      vuexWizard: 'fullWizard',
      vuexWizardSubtype: 'wizardSubtype'
    }),
    cpsEnabled: function () {
      if (this.currentAccount.cps_active) return true
      return false
    },
    foreUPEnabled: function () {
      if (this.currentAccount.foreup_active && this.currentAccount.foreup_id) return true
      return false
    },
    foreupLinked: function () {
      if (this.foreUPEnabled) {
        var hasLink = false
        this.tickets.forEach((ticket) => {
          if (ticket) if (ticket.foreup) if (ticket.foreup.id) hasLink = true
        })
        this.items.forEach((item) => {
          if (item) if (item.foreup) if (item.foreup.id) hasLink = true
        })
        return hasLink
      }
      return false
    },
    event: function () {
      return {
        registration: this.registration,
        tickets: this.tickets,
        items: this.items,
        confirmation: this.confirmation
      }
    },
    stepTitle: function () {
      if (this.mode === 'paid') {
        return 'Event Registration Details'
      }
      return 'Other Event Details'
    },
    stepDescription: function () {
      if (this.mode === 'paid') {
        return 'You can use CampaignPilot to handle event registrations and ticket sales right on your campaign landing page. Tell us a few details on how you want to configure your online ticket sales.'
      }
      return 'While you won\'t be using CampaignPilot to sell tickets for this event, tell us any additional information you might have about it. These details will be used to generate customized content for this campaign.'
    }
  },
  watch: {
    registration: {
      handler: function (val) {
        this.cleanQuantity(val)
        this.$emit('input', this.event)
      },
      deep: true
    },
    tickets: {
      handler: function (val) {
        this.cleanQuantity(val)
        this.$emit('input', this.event)
      },
      deep: true
    },
    items: {
      handler: function (val) {
        this.$emit('input', this.event)
      },
      deep: true
    },
    value (val, oval) {
      if (val && oval === null) {
        this.refresh()
      }
    },
    'registration.tax.charge' (val) {
      if (val) {
        if (!this.registration.tax.model) {
          var r = this.currentAccount.tax_rate
          if (typeof r === 'number') r = r + ''
          this.registration.tax.model = r
        }
      }
    }
  },
  mounted () {
    if (this.value) {
      this.refresh()
    } else {
      if (this.mode === 'paid') {
        // we dont have any info to load, let's default the registration
        // window if this is a paid event
        this.registration.registrationDate.model = format(new Date(), 'yyyy-MM-dd') // 2024-01-01
      }
    }
    // change the price icons if we need to
    if (this.currentAccount.currency === 'EUR') {
      this.ticket.price.icon = 'fas fa-euro-sign'
      this.item.price.icon = 'fas fa-euro-sign'
    }
  },
  methods: {
    refresh () {
      if (this.value) {
        if (this.value.registration) {
          this.registration = this.value.registration
          this.tickets = this.value.tickets
          this.items = this.value.items
          if (this.items.length > 0) {
            this.showTicketOptions = true // show the ticket options if we have them
          }
        }
        if (this.value.confirmation) if (this.value.confirmation.model) this.confirmation.model = this.value.confirmation.model
        if (this.confirmation.model) this.showConfirmation = true // show the confirmation options if we have something
      }
    },
    changeHelp (question) {
      var label = question.name
      if (question.label && !question.useNameInError) {
        label = question.label
      }
      if (question.helpTitle) {
        label = question.helpTitle
      }
      this.currentHelpLabel = label
      this.currentHelp = question.desktopHelp
    },
    addTicket () {
      // this pushes in the working "ticket" into the tickets array
      // then resets the working "ticket"
      if (this.ticket.name.model && this.ticket.price.model) {
        var cps = (this.ticket.cps) ? this.ticket.cps.model : null
        var foreup = (this.ticket.foreup) ? this.ticket.foreup.model : null
        var ticketQty = (this.ticket.quantity) ? this.ticket.quantity.model : null
        this.tickets.push(
          {
            name: this.ticket.name.model,
            price: this.ticket.price.model,
            cps: cps,
            foreup: foreup,
            quantity: ticketQty
          }
        )
        this.resetTicket()
      }
    },
    deleteTicket (ticketIndex) {
      this.tickets.splice(ticketIndex, 1)
    },
    resetTicket () {
      if (this.ticket.name) this.ticket.name.model = null
      if (this.ticket.price) this.ticket.price.model = null
      if (this.ticket.cps) this.ticket.cps.model = null
      if (this.ticket.foreup) this.ticket.foreup.model = null
      if (this.ticket.quantity) this.ticket.quantity.model = null
    },
    addItem () {
      // this pushes in the working "item" into the items array
      // then resets the working "item"
      if (this.item.name.model && this.item.price.model) {
        var cps = (this.item.cps) ? this.item.cps.model : null
        var foreup = (this.item.foreup) ? this.item.foreup.model : null
        this.items.push(
          {
            name: this.item.name.model,
            price: this.item.price.model,
            cps: cps,
            foreup: foreup
          }
        )
        this.resetItem()
      }
    },
    deleteItem (itemIndex) {
      this.items.splice(itemIndex, 1)
    },
    resetItem () {
      if (this.item.name) this.item.name.model = null
      if (this.item.price) this.item.price.model = null
      if (this.item.cps) this.item.cps.model = null
      if (this.item.foreup) this.item.foreup.model = null
    },
    toggleTicketOptions () {
      this.showTicketOptions = !this.showTicketOptions
    },
    toggleConfirmation () {
      this.showConfirmation = !this.showConfirmation
    },
    ticketFromforeUP (ticket) {
      this.ticket.name.model = ticket.foreup.model.attributes.name
      if (typeof ticket.foreup.model.attributes.basePrice === 'number') this.ticket.price.model = parseFloat(ticket.foreup.model.attributes.basePrice + '').toFixed(2)
    },
    itemFromforeUP (item) {
      this.item.name.model = item.foreup.model.attributes.name
      if (typeof item.foreup.model.attributes.basePrice === 'number') this.item.price.model = parseFloat(item.foreup.model.attributes.basePrice + '').toFixed(2)
    },
    cleanQuantity (val) {
      if (val) {
        if (typeof val === 'object') {
          if (Array.isArray(val)) {
            for (var i = 0; i < val.length; i++) {
              if (typeof val[i].quantity === 'number' && val[i].quantity <= 0) {
                val[i].quantity = null
              } else if (typeof val[i].quantity === 'string') {
                if (typeof parseInt(val[i].quantity) === 'number' && parseInt(val[i].quantity) <= 0) {
                  val[i].quantity = null
                }
              }
            }
          } else {
            if (val.capacity) {
              if (typeof val.capacity.model === 'number' && val.capacity.model <= 0) {
                val.capacity.model = null
              } else if (typeof val.capacity.model === 'string') {
                if (typeof parseInt(val.capacity.model) === 'number' && parseInt(val.capacity.model) <= 0) {
                  val.capacity.model = null
                }
              }
            }
          }
        }
      }
    }
  },
  inject: {
    $validator: '$validator'
  }
}
</script>
<style scoped>
.smaller-column-width {
  width: 135px;
  max-width: 135px;
  min-width: 135px;
}
#ticketOptions {
  margin: 0.5rem 0 1rem 0;
}
#customConfirmation {
  margin: 0 0 1rem 0;
}
.table > tfoot > tr > th:last-child, .table > tbody > tr > td:last-child {
  text-align: center;
}
.link-section {
  margin-bottom: 1rem;
}
.link-section span {
  margin-right: 0.5rem;
}
</style>

<template>
  <div
    :class="fieldClasses"
    class="field">
    <label
      v-if="props.label"
      :style="label.style"
      :class="label.class"
      class="label">
      <span v-if="props.label === '&nbsp;'">&nbsp;</span>
      <span v-else>{{ props.label }}</span>
      <span
        v-if="isRequired"
        class="icon has-text-danger is-small oto-required-field">
        <i class="fas fa-star-of-life oto-fa-mini"/>
      </span>
    </label>
    <div
      :class="{ 'has-icons-left': props.icon }"
      class="control">
      <div
        :class="[{ 'is-danger': errors.has(props.name) }, props.class ]"
        class="select">
        <select
          v-validate="props.validate"
          :value="props.model"
          :name="props.name"
          :ref="props.ref"
          :key="props.ref"
          :disabled="disabled"
          :data-vv-as="props.label && !props.useNameInError ? props.label : props.name"
          :style="control.style"
          :class="inputClasses"
          @blur="onBlur()"
          @focus="onFocus()"
          @input="onInput($event.target.value)">
          <option
            v-for="(option, index) in optionGroupItems"
            :key="props.ref + index"
            :value="option.value">
            {{ option.name }}
          </option>
        </select>
      </div>
      <span
        v-if="props.icon"
        class="icon is-small is-left">
        <i :class="props.icon"/>
      </span>
      <span
        v-if="errors.has(props.name)"
        class="has-text-danger animated fadeInDown">
        {{ errors.first(props.name) }}
      </span>
    </div>
    <p
      v-if="props.mobileHelp && isInputActive"
      class="oto-mobile-help is-info is-hidden-tablet">
      <span class="icon">
        <i class="fas fa-info-circle"/>
      </span>
      {{ props.mobileHelp }}
    </p>
  </div>
</template>

<script>
import inputs from '@/mixins/Inputs.js'

export default {
  mixins: [inputs]
}
</script>

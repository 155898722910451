<template>
  <div>
    <div>
      <div class="oto-second-heading is-hidden-mobile">Setting Up The Email Channel</div>
      <p class="padding-bottom">
        CampaignPilot can turn your marketing campaigns into email newsletters. Select your email marketing provider and complete the required settings.
      </p>
    </div>
    <Select
      :props="provider"
      v-model="provider.model"/>
    <div v-if="provider.model === 'acy'">
      <OneTwoOneMarketing v-model="settings" />
    </div>
    <div v-if="provider.model === 'cinch'">
      <Cinch v-model="settings" />
    </div>
    <!-- SAVE CHANNEL BUTTON -->
    <div v-if="goodToSave" class="oto-settings-save-button">
      <a
        class="button is-primary is-rounded"
        @click="save">
        <span class="icon is-small">
          <i class="fas fa-save"/>
        </span>
        <span>Save Changes</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import Select from '@/components/elements/Select'
import OneTwoOneMarketing from '@/components/dashboard/settings/channels/121Marketing'
import Cinch from '@/components/dashboard/settings/channels/Cinch'

// emits refresh
// emits input value

export default {
  components: {
    Select,
    OneTwoOneMarketing,
    Cinch
  },
  mixins: [xhr],
  props: ['value'],
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    }),
    goodToSave: function () {
      if (!this.provider.model) return true // allow user to save no email channel, this will trigger a delete of any existing email channel
      else if (this.settings['has_error']) return false
      else if (this.provider.model === 'cinch' && this.settings['access_token'] && this.settings['token_secret']) return true
      else if (this.provider.model === 'acy' && this.settings.name) return true
      return false
    }
  },
  data () {
    return {
      settings: {},
      provider: {
        model: null,
        name: 'email-provider',
        ref: 'email-provider',
        options: [{ 'name': '', 'value': null }, { 'name': '1-2-1 Marketing', 'value': 'acy' }, { 'name': 'Cinch', 'value': 'cinch' }],
        label: 'Email Marketing Provider'
      }
    }
  },
  watch: {
    value (val) {
      if (val) this.set()
    }
  },
  mounted () {
    if (this.value) this.set()
  },
  methods: {
    set: function () {
      this.settings = this.$lodash.cloneDeep(this.value)
      this.provider.model = this.settings.id
    },
    save: function () {
      // delete any existing channels and then if there is a new one save that
      var vm = this
      vm.$store.dispatch('setLoadingState', true)
      vm.$scrollTo('#dashboard', 200, { easing: 'ease' })
      engineApi.deleteChannel(
        vm.currentAccount.id, 'email',
        function (err) {
          if (err) {
            vm.$store.dispatch('setLoadingState', false)
            vm.handleXhrError(err)
          } else {
            if (vm.provider.model) {
              engineApi.saveChannel(
                vm.currentAccount.id, 'email', vm.provider.model, vm.settings.name, vm.settings['access_token'], vm.settings['token_secret'], vm.settings.options, 1,
                function (err) {
                  vm.$store.dispatch('setLoadingState', false)
                  if (err) {
                    vm.handleXhrError(err)
                  } else {
                    vm.$store.dispatch('setNotify', { state: true, text: 'Your email channel settings have been updated.' })
                    vm.$emit('refresh', true) // triggers a refresh of the channels on the main Channels component
                  }
                }
              )
            } else {
              vm.$store.dispatch('setLoadingState', false)
              vm.$store.dispatch('setNotify', { state: true, text: 'Your email channel settings have been removed.' })
              vm.$emit('refresh', true) // triggers a refresh of the channels on the main Channels component
            }
          }
        }
      )
    }
  }
}
</script>

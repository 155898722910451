import { mapGetters } from 'vuex'
import Navigation from '@/components/dashboard/campaigns/wizard/Navigation'
import engineApi from '@/plugins/engine-api.js'
import xhr from '@/mixins/Xhr.js'
import campaigndefaults from '@/mixins/CampaignDefaults.js'

/**
This mix-in is intended to be included with any campaign wizard main step.
It includes global wizard components like navigation.
It includes the following variables:
- Save Override (vuexSaveOverride) - Flag that let's us know the user doesn't want to save on a bail out
- Wizard Title (vuexWizardTitle) - The top title of the campaign, i.e. SALE > GIFT CARD
- Wizard Step (vuexWizardStep) - The current step the user is on
- Wizard Subtype (vuexWizardSubtype) - The current subtype of the wizard
- Published Mode (editPublishedMode) - Flag that let's us know the user is editing an already published campaign
*/

export default {
  components: {
    Navigation
  },
  mixins: [xhr, campaigndefaults],
  data () {
    return {
      navigation: null,
      pathAfterSaveDismiss: null,
      stepHasErrors: false
    }
  },
  computed: {
    ...mapGetters({
      vuexSaveOverride: 'saveOverride',
      vuexWizardTitle: 'wizardTitle',
      vuexWizardStep: 'wizardStep',
      vuexWizardSubtype: 'wizardSubtype',
      editPublishedMode: 'editPublishedMode'
    })
  },
  watch: {
    vuexSaveOverride: function (val, oldVal) {
      if (val) {
        this.$router.push({ name: this.pathAfterSaveDismiss })
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.vuexWizardStep === 1 && to.name === 'route-engine-campaigns-wizard-type') {
      // forward from step 1 into TYPE
      this.$validator.validateAll().then(result => {
        if (this.guideMode) this.TourClose()
        if (result) {
          this.saveSectionDetails()
          // output entire form to console for debug purposes
          if (process.env.NODE_ENV === 'development') {
            this.campaign.questions.forEach(function (answer) {
              console.log('DEBUG FORM:')
              console.log(answer.model)
              if (answer.subquestions) {
                answer.subquestions.forEach(function (subanswer) {
                  console.log(subanswer.model)
                })
              }
            })
          }
          next()
        } else {
          this.stepHasErrors = true
          this.$scrollTo('#leftColumnTop', 200, { easing: 'ease' })
          next(false)
        }
      })
    } else if (this.vuexWizardStep === 3 && to.name === 'route-engine-campaigns-wizard-theme') {
      // forward from step 3 into THEME
      var hasTickets = false
      if (this.actions) {
        if (Array.isArray(this.actions.tickets)) {
          if (this.actions.tickets.length > 0) {
            hasTickets = true
          }
        }
      }
      if (!hasTickets && this.callToAction === 'event' && this.vuexWizardSubtype === 'paid') {
        // we dont have tickets and this is a paid event!
        this.stepHasErrors = true
        const field = this.$validator.fields.find({ name: 'ticket-name', scope: this.$options.scope })
        this.$validator.errors.add({
          id: 'ticket-name',
          field: 'ticket-name',
          msg: 'Create at least one ticket for purchase.',
          scope: this.$options.scope
        })
        field.setFlags({
          invalid: true,
          valid: false,
          validated: true
        })
      } else {
        // we have tickets for a paid event, or it's not paid or not an event.
        // continue checking the details form for other errors
        this.$validator.validateAll().then(result => {
          if (result) {
            this.saveSectionTypeDetails()
            next()
          } else {
            this.stepHasErrors = true
            this.$scrollTo('#leftColumnTop', 200, { easing: 'ease' })
            next(false)
          }
        })
      }
    } else if (this.vuexWizardStep === 6 && to.name === 'route-engine-campaigns-wizard-landing-page') {
      // forward from step 6 into LANDING PAGE
      this.saveSectionTheme()
      next()
    } else if (this.vuexWizardStep === 7 && to.name === 'route-engine-campaigns-home' && this.editPublishedMode) {
      // forward from step 7 in editing published mode (SAVE CHANGES)
      this.$scrollTo('#dashboard', 200, { easing: 'ease' })
      this.saveSectionLandingPage()
      this.savePublishedCampaign(next)
    } else if (this.vuexWizardStep === 7 && to.name === 'route-engine-campaigns-wizard-post-content') {
      // forward from step 7 into POST CONTENT
      this.saveSectionLandingPage()
      next()
    } else if (this.vuexWizardStep === 8 && to.name === 'route-engine-campaigns-wizard-schedule') {
      // forward from step 8 into SCHEDULE
      this.$validator.validateAll().then(result => {
        if (result) {
          this.saveSectionPostContent()
          next()
        } else {
          this.stepHasErrors = true
          this.$scrollTo('#leftColumnTop', 200, { easing: 'ease' })
          next(false)
        }
      })
    } else if (this.vuexWizardStep === 9 && to.name === 'route-engine-campaigns-wizard-confirmation') {
      // forward from step 9 (SAVE AND SCHEDULE)
      this.$validator.validateAll().then(result => {
        if (this.guideMode) this.TourClose()
        if (result) {
          this.$scrollTo('#dashboard', 200, { easing: 'ease' })
          this.saveSectionSchedule()
          this.saveEntireCampaign(next)
        } else {
          this.stepHasErrors = true
          this.$scrollTo('#leftColumnTop', 200, { easing: 'ease' })
          next(false)
        }
      })
    } else if (this.vuexWizardStep === 10) {
      // leaving the confirmation page in the wizard, let them go
      next()
    } else if (to.name.includes('route-engine-campaigns-wizard-')) {
      // still inside the wizard, see if we are going backward
      if (to.params) {
        if (to.params.navdir === 'back' && to.name === 'route-engine-campaigns-wizard-theme') {
          this.saveSectionLandingPage()
          next()
        } else if (to.params.navdir === 'back' && to.name === 'route-engine-campaigns-wizard-landing-page') {
          this.saveSectionPostContent()
          next()
        } else {
          // always allow going back
          next()
        }
      } else {
        // for some reason we're not sure which direction we're going
        next()
      }
    } else if (to.name.includes('route-login')) {
      // going to login screen, likely a session timeout, let them go
      next()
    } else if (this.vuexSaveOverride) {
      // dont save clicked, let them go
      next()
      this.$store.dispatch('setSaveOverride', false)
    } else {
      // need to show save warning here, we're leaving the wizard!
      this.$store.dispatch('setSaveWarning', true)
      this.pathAfterSaveDismiss = to.name
      // if the user clicked the 'Save Draft' button, display a different popup
      if (to.params.button === 'draft') {
        this.$store.dispatch('setSaveWarningMode', 'button')
      } else {
        this.$store.dispatch('setSaveWarningMode', null)
      }
      // forcing a save of the step here!
      if (this.vuexWizardStep === 1) {
        this.saveSectionDetails()
      } else if (this.vuexWizardStep === 2 || this.vuexWizardStep === 3) {
        this.saveSectionTypeDetails()
      } else if (this.vuexWizardStep === 4 || this.vuexWizardStep === 5 || this.vuexWizardStep === 6) {
        this.saveSectionTheme()
      } else if (this.vuexWizardStep === 7) {
        this.saveSectionLandingPage()
      } else if (this.vuexWizardStep === 8) {
        this.saveSectionPostContent()
      } else if (this.vuexWizardStep === 9) {
        this.saveSectionSchedule()
      }
      // prevent the route change
      next(false)
    }
  },
  methods: {
    saveSectionDetails () {
      if (this.vuexWizardDetails) {
        var vm = this
        var runReset = false
        if (!vm.$lodash.isEqual(vm.vuexWizardDetails, vm.campaign)) {
          // something changed on the details step
          // let's check what changed
          if (vm.vuexWizardDetails) {
            if (vm.vuexWizardDetails.questions) {
              if (vm.vuexWizardDetails.questions.length > 0) {
                vm.campaign.questions.forEach(function (question, index) {
                  if (vm.vuexWizardDetails.questions.length - 1 >= index) {
                    if (vm.vuexWizardDetails.questions[index].model !== question.model) {
                      if (question.type !== 'text-rich') {
                        // something changed that wasn't HTML
                        runReset = true
                      }
                    }
                  }
                })
              }
            }
          }
        }
        if (runReset) {
          vm.resetLandingPageWords()
          vm.resetPostContentWords()
          vm.resetPostContentImages()
        }
      }
      vm.$store.dispatch('saveSection', { name: 'wizardDetails', value: vm.campaign })
      // Sync Support For Rich Content
      // (this saves the rich content question to vuex, if there are multiple rich-text questions, tis saves the last one)
      vm.campaign.questions.forEach(function (question) {
        if (question.type === 'text-rich') vm.$store.dispatch('setCampaignRichContent', question.model)
      })
    },
    saveSectionTypeDetails () {
      if (this.vuexAction) {
        if (!this.$lodash.isEqual(this.vuexAction.actions, this.actions)) {
          // something changed on the actions/details step
          this.resetLandingPageWords()
          this.resetPostContentWords()
          this.resetPostContentImages()
        }
      }
      this.$store.dispatch('saveSection', { name: 'wizardAction', value: { callToAction: this.callToAction, actions: this.actions } })
    },
    saveSectionTheme () {
      if (this.vuexTheme) {
        if (!this.$lodash.isEqual(this.vuexTheme.currentTheme, this.currentTheme)) {
          // looks like the theme changed
          this.resetLandingPageImages()
          this.resetPostContentImages()
        } else if (!this.$lodash.isEqual(this.vuexTheme.currentColors, this.currentColors)) {
          // looks like the colors changed
          this.resetPostContentImages()
        } else if (!this.$lodash.isEqual(this.vuexTheme.currentFont, this.currentFont)) {
          // looks like the font changed
          this.resetPostContentImages()
        }
      }
      this.$store.dispatch('saveSection',
        {
          name: 'wizardTheme',
          value: {
            currentColors: this.currentColors,
            currentFont: this.currentFont,
            currentFontId: this.currentFontId,
            currentSwatch: this.currentSwatch,
            currentTheme: this.currentTheme,
            tagsToFilter: this.tagsToFilter
          }
        }
      )
    },
    saveSectionLandingPage () {
      if (process.env.NODE_ENV === 'development') console.log(this.iframeUrl)
      if (this.vuexLandingPage) {
        if (!this.$lodash.isEqual(this.vuexLandingPage.uploadedImages, this.uploadedImages)) {
          // we uploaded new pictures
          this.resetPostContentImages()
        }
        if (this.vuexLandingPage.words) {
          if ((this.words.buckets.actionIndex !== this.vuexLandingPage.words.buckets.actionIndex) || (this.words.buckets.headingIndex !== this.vuexLandingPage.words.buckets.headingIndex) || (this.words.buckets.titleIndex !== this.vuexLandingPage.words.buckets.titleIndex)) {
            // we changed some of the words on the landing page that are used in the post content images
            this.resetPostContentImages()
            this.resetPostContentWords()
          }
        }
      }
      this.$store.dispatch('saveSection', { name: 'wizardLandingPage', value: { layout: this.layout, words: this.words, imagesAvailable: this.imagesAvailable, uploadedImages: this.uploadedImages, richContent: this.richContent, questions: this.questions, embedIFrame: this.embedIFrame, embedButton: this.embedButton } })
      this.$store.dispatch('setCampaignRichContent', this.richContent)
    },
    saveSectionPostContent () {
      this.$store.dispatch('saveSection', { name: 'wizardPostContent', value: { images: this.images, imgIndex: this.imgIndex, words: this.words, postContent: this.postContent, uploadedImages: this.uploadedImages, backgroundsToUse: this.backgroundsToUse } })
    },
    saveSectionSchedule () {
      this.$store.dispatch('saveSection', { name: 'wizardSchedule', value: { date: this.sendDate.model, time: this.sendTime.model, channels: this.channels } })
    },
    savePublishedCampaign (next) {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      var finalTitle = this.generateCampaignTitle(vm.vuexWizard.wizardDetails)
      var finalColors = this.generateCampaignColors(vm.vuexWizard.wizardTheme)
      var finalActionText = this.generateCampaignActionText(vm.vuexWizard.wizardLandingPage)
      var finalDescription = this.generateCampaignDescription(vm.vuexWizard.wizardPostContent, vm.vuexWizard.wizardDetails)
      engineApi.editCampaign(
        vm.currentAccount.id,
        vm.vuexEditId,
        finalTitle,
        finalDescription,
        finalActionText,
        finalColors,
        vm.vuexWizard.wizardAction.callToAction,
        vm.vuexWizard.wizardLandingPage.layout,
        vm.vuexWizard.wizardTheme.currentTheme.title,
        vm.vuexWizardSubtype,
        vm.vuexWizard.wizardLandingPage.words.variables,
        vm.vuexWizard,
        vm.currentAccount,
        function (err, response) {
          if (err) {
            vm.$store.dispatch('setLoadingState', false)
            vm.handleXhrError(err)
            next(false)
          } else {
            // save slug
            vm.$store.dispatch('setWizardSlug', response.slug)
            // trigger email channel if turned on
            if (vm.vuexWizard.wizardSchedule) {
              if (vm.vuexWizard.wizardSchedule.channels) {
                if (vm.vuexWizard.wizardSchedule.channels.email) {
                  engineApi.triggerChannels('email', vm.currentAccount.id, response.id, function () {})
                }
              }
            }
            engineApi.generateSummary(
              vm.currentAccount.id,
              response.id,
              function (err, response) {
                if (response) {
                  if (response['Payload']) {
                    if (typeof response['Payload'] === 'string') {
                      var assetResponse = JSON.parse(response['Payload'])
                      if (assetResponse) {
                        vm.$store.dispatch('setWizardAssets', assetResponse)
                      }
                    }
                  }
                }
                vm.$store.dispatch('setLoadingState', false)
                if (err) {
                  vm.handleXhrError(err)
                  next(false)
                } else {
                  // vm.$store.dispatch('setSaveOverride', true) // CAMP-518
                  next()
                }
              }
            )
          }
        }
      )
    },
    saveEntireCampaign (next) {
      this.$store.dispatch('setLoadingState', true)
      this.campaignGenerating = true
      var vm = this
      // set the final variables
      var finalStatus = (this.guideMode) ? 0 : 1
      var finalTitle = this.generateCampaignTitle(vm.fullWizard.wizardDetails)
      var finalTime = this.fullWizard.wizardSchedule.time
      var finalPostText = this.generateCampaignPostText(vm.fullWizard.wizardPostContent)
      var finalDescription = this.generateCampaignDescription(vm.fullWizard.wizardPostContent)
      var finalColors = this.generateCampaignColors(vm.fullWizard.wizardTheme)
      var finalActionText = this.generateCampaignActionText(vm.fullWizard.wizardLandingPage)
      var finalPostImage = this.generateCampaignPostImage(vm.fullWizard.wizardPostContent)
      // set the final time
      var t = finalTime.split(':')
      var h = parseInt(t[0])
      var m = t[1].split(' ')
      var p = m[1]
      m = parseInt(m[0])
      if (p === 'PM' && h < 12) {
        h = h + 12
      } else if (p === 'AM' && h === 12) {
        h = 0
      }
      var d = new Date()
      d.setHours(h)
      d.setMinutes(m)
      d.setSeconds(0)
      finalTime = d.getHours() + ':' + d.getMinutes()
      // save the entire campaign
      engineApi.saveCampaign(
        vm.currentAccount.id,
        vm.vuexEditId,
        vm.fullWizard.wizardDetails.type,
        vm.fullWizard.wizardDetails.subtype,
        vm.vuexWizardSubtype,
        finalTitle,
        finalDescription,
        finalPostText,
        finalActionText,
        finalColors,
        vm.fullWizard.wizardAction.callToAction,
        vm.fullWizard.wizardLandingPage.layout,
        vm.fullWizard.wizardTheme.currentTheme.title,
        finalPostImage,
        vm.fullWizard.wizardSchedule.channels,
        vm.fullWizard.wizardSchedule.date,
        finalTime,
        finalStatus, // 1 is active status, 0 is draft status
        vm.fullWizard.wizardLandingPage.words.variables,
        vm.unsplash,
        vm.fullWizard,
        vm.currentAccount,
        function (err, response) {
          if (err) {
            vm.$store.dispatch('setLoadingState', false)
            vm.handleXhrError(err)
            next(false)
          } else {
            var savedCampId = response.id // this is always the campaign id
            // now that the campaign is saved, let's generate the html email
            engineApi.fetchEmailHTML(vm.currentAccount.id, savedCampId, function (emailHTMLerr, emailHTML) {
              // save the html to the response packet
              if (emailHTMLerr) {
                // soft error, save will continue
                response.emailHTML = null
              } else {
                response.emailHTML = emailHTML
              }
              // save all post save campaign properties (mainly urls, etc for generated assets that we'll use on the confirmation page)
              vm.$store.dispatch('setWizardPostRender', response)
              // save slug
              vm.$store.dispatch('setWizardSlug', response.slug)
              // generate the campaign summary page
              engineApi.generateSummary(vm.currentAccount.id, savedCampId, function (summaryErr, summaryResponse) {
                if (summaryErr) {
                  vm.$store.dispatch('setLoadingState', false)
                  vm.handleXhrError(summaryErr)
                  next(false)
                } else {
                  // now that we've generated the summary, save that response for the confirmation too
                  if (summaryResponse) {
                    if (summaryResponse['Payload']) {
                      if (typeof summaryResponse['Payload'] === 'string') {
                        var assetResponse = JSON.parse(summaryResponse['Payload'])
                        if (assetResponse) {
                          vm.$store.dispatch('setWizardAssets', assetResponse)
                        }
                      }
                    }
                  }
                  // send google analytics event
                  if (process.env.NODE_ENV !== 'development') window.gtag('event', 'campaign_action', { 'action': 'scheduled', 'campId': savedCampId, 'accountId': vm.currentAccount.id })
                  // send triggers or not (save to ACY for example)
                  var trigger = false
                  if (vm.fullWizard.wizardSchedule) if (vm.fullWizard.wizardSchedule.channels) if (vm.fullWizard.wizardSchedule.channels.email) trigger = true
                  if (trigger) {
                    // only handling email channel triggers right now
                    engineApi.triggerChannels('email', vm.currentAccount.id, savedCampId, function () {
                      vm.$store.dispatch('setLoadingState', false)
                      next()
                    })
                  } else {
                    vm.$store.dispatch('setLoadingState', false)
                    next()
                  }
                }
              })
            })
          }
        }
      )
    },
    resetLandingPageImages () {
      if (process.env.NODE_ENV === 'development') console.log('Resetting landing page images in vuex!')
      this.$store.dispatch('saveSection', { name: 'wizardLandingPage', subkey: 'imagesAvailable', value: null })
    },
    resetLandingPageWords () {
      if (process.env.NODE_ENV === 'development') console.log('Resetting landing page words in vuex!')
      this.$store.dispatch('saveSection', { name: 'wizardLandingPage', subkey: 'words', value: null })
    },
    resetPostContentWords () {
      if (this.editPublishedMode) {
        if (process.env.NODE_ENV === 'development') console.log('Skipping resetting post content words since this is a published campaign')
      } else {
        if (process.env.NODE_ENV === 'development') console.log('Resetting post content words in vuex!')
        this.$store.dispatch('saveSection', { name: 'wizardPostContent', subkey: 'words', value: null })
      }
    },
    resetPostContentImages () {
      if (this.editPublishedMode) {
        if (process.env.NODE_ENV === 'development') console.log('Skipping resetting post content images since this is a published campaign')
      } else {
        if (process.env.NODE_ENV === 'development') console.log('Resetting post content images in vuex!')
        this.$store.dispatch('saveSection', { name: 'wizardPostContent', subkey: 'images', value: null })
      }
    }
  }
}

<template>
  <div>
    <div class="columns">
      <div class="column oto-settings-sub-details">
        <p>Manage additional CampaignPilot settings and integrations.</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="tile">
          <div class="is-hidden-mobile oto-vertical-tabs-is-left-wider">
            <nav class="oto-vertical-tabs">
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 0}"
                @click="changeTab(0)">
                <span class="icon">
                  <i class="fas fa-plug"></i>
                </span>
                <span>Apps</span>
              </a>
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 1}"
                @click="changeTab(1)">
                <span class="icon">
                  <i class="fas fa-bell"></i>
                </span>
                <span>Notifications</span>
              </a>
              <a
                v-if="currentUser.superadmin === 1"
                class="panel-block"
                :class="{'active-panel': currentTab === 2}"
                @click="changeTab(2)">
                <span class="icon">
                  <i class="fas fa-users"></i>
                </span>
                <span>Users</span>
              </a>
            </nav>
          </div>
          <div class="oto-vertical-tabs-is-right">
            <!-- APPS -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 0}"
              @click="changeTab(0, true)">
              <div id="tab0">Apps</div>
              <div></div>
            </div>
            <div
              v-if="currentTab === 0"
              class="oto-settings-content-area animated fadeIn">
              <Apps />
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!-- NOTIFICATIONS -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 1}"
              @click="changeTab(1, true)">
              <div id="tab1">Notifications</div>
              <div></div>
            </div>
            <div
              v-if="currentTab === 1"
              class="oto-settings-content-area animated fadeIn">
              <Notifications
                :wizard-mode="false"
                :save-passthrough="false"
                :save-return="null"/>
            </div>
            <!-- USERS -->
            <hr
              v-if="currentUser.superadmin === 1"
              class="is-hidden-tablet oto-accordian-hr"/>
            <div
              v-if="currentUser.superadmin === 1"
              :class="{'oto-accordian-expanded': currentTab === 2}"
              class="oto-accordian-holder show-hand is-hidden-tablet"
              @click="changeTab(2, true)">
              <div id="tab2">Users</div>
              <div></div>
            </div>
            <div
              v-if="currentTab === 2 && currentUser.superadmin === 1"
              class="oto-settings-content-area animated fadeIn">
              <AccountUsers/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Apps from '@/components/dashboard/settings/other/Apps'
import Notifications from '@/components/dashboard/settings/other/Notifications'
import AccountUsers from '@/components/dashboard/settings/other/AccountUsers'
import settings from '@/mixins/Settings.js'
import { mapGetters } from 'vuex'

export default {
  components: {
    Notifications, AccountUsers, Apps
  },
  mixins: [settings],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  mounted () {
    // allow tab switching via url
    if (this.$route.query.subtab === 'apps') {
      this.currentTab = 0
    } else if (this.$route.query.subtab === 'notifications') {
      this.currentTab = 1
    } else if (this.$route.query.subtab === 'users') {
      this.currentTab = 2
    }
  }
}
</script>

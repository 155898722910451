<template>
  <transition
    name="fade"
    enter-active-class="fadeInDown"
    leave-active-class="fadeOutUp">
    <div
      v-if="currentNotify.state"
      :class="notificationClass"
      class="notification"
      @click="clickedNotify">
      <button
        class="delete is-medium"
        @click="clearNotification"/>
      {{ currentNotify.text }}
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: function () {
    return {
      autoDismissTime: 8000
    }
  },
  computed: {
    ...mapGetters({
      currentNotify: 'currentNotify'
    }),
    notificationClass: function () {
      var c = ''
      if (this.currentNotify.url) c = 'notification-has-url '
      if (!this.currentNotify.color || this.currentNotify.color === 'primary') {
        return c + 'is-primary'
      } else if (this.currentNotify.color === 'link') {
        return c + 'is-link'
      } else if (this.currentNotify.color === 'info') {
        return c + 'is-info'
      } else if (this.currentNotify.color === 'success') {
        return c + 'is-success'
      } else if (this.currentNotify.color === 'warning') {
        return c + 'is-warning'
      } else if (this.currentNotify.color === 'danger') {
        return c + 'is-danger'
      } else if (this.currentNotify.color === 'white') {
        return c + 'is-white'
      } else if (this.currentNotify.color === 'grey') {
        return c + 'is-grey'
      } else if (this.currentNotify.color === 'black') {
        return c + 'is-black'
      } else {
        return c
      }
    }
  },
  watch: {
    'currentNotify.state' (val) {
      // also watch again if the notify is shown again after creation
      if (val) {
        this.autoClose(this.autoDismissTime)
      }
    }
  },
  created () {
    this.autoClose(this.autoDismissTime)
  },
  methods: {
    clearNotification: function () {
      this.$store.dispatch('setNotify', { state: false, text: null, color: null })
    },
    autoClose: function (time) {
      var vm = this
      if (!vm.currentNotify.noAutoClose) {
        setTimeout(function () {
          vm.clearNotification()
        }, time)
      }
    },
    clickedNotify: function () {
      if (this.currentNotify.url) {
        window.location.href = this.currentNotify.url
      }
    }
  }
}
</script>

<style scoped>
.notification {
  margin-bottom: 0!important;
  z-index: 15;
  width: 100%;
}
.delete {
  right: 24px;
  top: calc(50% - 12px);
}
.notification-has-url {
  cursor: pointer;
}
</style>

<template>
  <div>
    <main class="oto-main">
      <div class="oto-side-background is-hidden-mobile"/>
      <div class="oto-main-container container">
        <div class="oto-duo">
          <div class="oto-lead">
            <!-- Left Column -->
            <div>
              <div class="step-heading is-hidden-mobile">
                <h2 class="subtitle uppercase">
                  Campaign: {{ vuexWizardTitle }}
                </h2>
                <h1 class="title">
                  Customize Your Message
                </h1>
              </div>
              <div
                v-if="imagesGenerating"
                class="oto-loading-holder">
                <div class="layer-01">
                  <img
                    class="loading-posts"
                    src="../../../../../assets/svgs/paperplane.svg">
                </div>
                <div class="layer-02">
                  <img
                    class="loading-posts"
                    src="../../../../../assets/svgs/clouds.svg">
                </div>
              </div>
              <div v-else>
                <hr class="oto-mobile-break is-hidden-tablet">
                <div id="post-content-card" class="card">
                  <div class="header">
                    <div class="media">
                      <div class="media-left">
                        <div class="oto-fake-profile-image">{{ accountInitals }}</div>
                      </div>
                      <div class="media-content">
                        <p class="title is-5 client-name">{{ currentAccount.name }}</p>
                        <p class="subtitle is-6 post-date">&nbsp;</p>
                      </div>
                    </div>
                    <div id="post-content-description-area" class="description-area">
                      <div
                        class="columns change-wrapper"
                        @click="changePhrase('allsafe')">
                        <div id="post-content-description-area-textbox" class="column is-size-6 description-fix">
                          <TextArea
                            :props="postContent"
                            :control="postContent.control"
                            :focus="true"
                            v-model="postContent.model"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-image">
                    <figure
                      v-if="images.length > 0"
                      class="image is-16by9">
                      <img v-lazy="images[imgIndex]">
                      <div v-lazy:background-image="images[imgIndex]"/>
                    </figure>
                    <div class="link-area">
                      <div
                        :class="{ 'oto-editable': numberOfPhrases('actionheading') > 1 }"
                        @click="changePhrase('actionheading')">
                        <div
                          v-if="numberOfPhrases('actionheading') > 1"
                          class="oto-editable-button">
                          <a class="tag is-dark">
                            <span class="icon is-small has-text-white">
                              <i class="fas fa-sync-alt"/>
                            </span>
                          </a>
                        </div>
                        <h1 class="is-size-5">
                          {{ currentActionHeading }}
                        </h1>
                      </div>
                      <div
                        :class="{ 'oto-editable': numberOfPhrases('description') > 1 }"
                        @click="changePhrase('description')">
                        <div
                          v-if="numberOfPhrases('description') > 1"
                          class="oto-editable-button">
                          <a class="tag is-dark">
                            <span class="icon is-small has-text-white">
                              <i class="fas fa-sync-alt"/>
                            </span>
                          </a>
                        </div>
                        <p class="is-size-6">
                          {{ currentDescription }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <aside class="oto-side-half">
            <div class="step-heading is-hidden-tablet">
              <h2 class="subtitle uppercase">
                Campaign: {{ vuexWizardTitle }}
              </h2>
              <h1 class="title">
                Customize Your Message
              </h1>
            </div>
            <div v-if="!imagesGenerating && images.length > 0">
              <div class="right-column-text">
                This is a preview of the message that will be distributed to your social media pages. Type your post message into the input area and select your favorite post image from the options below.
              </div>
              <div class="right-column-text">
                <div class="subtitle-w-bar">Select an image</div>
                <div
                  id="image-holder"
                  class="columns image-holder">
                  <div id="image-holder-top" class="is-hidden-mobile"/>
                  <div
                    v-for="(image, index) in images"
                    :key="index"
                    :id="'post-image-'+index"
                    class="column is-one-third">
                    <figure
                      :class="{ 'is-selected-border': index === imgIndex, 'oto-hover': index !== imgIndex }"
                      :id="'image-' + index"
                      class="image is-16by9 show-hand"
                      @click="changeImage(index)">
                      <img v-lazy="image">
                      <div v-lazy:background-image="image"/>
                    </figure>
                  </div>
                  <div
                    v-if="!guideMode"
                    class="column is-one-third">
                    <div
                      class="oto-box oto-set-min-height no-border has-background-white oto-hover"
                      @click="openUnsplash">
                      <div class="title-holder is-capitalized">
                        <img width="90" src="../../../../../assets/imgs/other/unsplash-logo.png">
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!guideMode"
                    class="column is-one-third">
                    <div
                      class="oto-box oto-set-min-height no-border has-background-white oto-hover"
                      @click="openImageUploader">
                      <div class="title-holder is-capitalized">
                        <span class="icon is-small"><i class="fas fa-upload"></i></span>
                        &nbsp;<span>Upload</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="content">
              <div class="right-column-text">
                Hang tight.
                We're generating your custom content that will be used for social media campaigns. This should only take a few seconds.
              </div>
            </div>
          </aside>
        </div>
      </div>
    </main>
    <Navigation v-model="navigation"/>
    <ImageUploader
      :iu="iu"
      v-model="uploadedImage"/>
    <UnsplashPicker
      :unsplash="unsplash"
      v-model="unsplashImage"/>
  </div>
</template>

<script>
import TextArea from '@/components/elements/TextArea'
import engineApi from '@/plugins/engine-api.js'
import utils from '@/plugins/utils.js'
import wizard from '@/mixins/Wizard.js'
import words from '@/mixins/Words.js'
import tour from '@/mixins/Tour.js'
import ImageUploader from '@/components/elements/ImageUploader'
import UnsplashPicker from '@/components/elements/UnsplashPicker'
import { mapGetters } from 'vuex'

export default {
  components: {
    TextArea,
    ImageUploader,
    UnsplashPicker
  },
  mixins: [wizard, words, tour],
  data () {
    return {
      imagesGenerating: false,
      backgroundsToUse: [],
      imgIndex: 0,
      images: [],
      postContent: {
        model: null,
        name: 'post message',
        ref: 'post message',
        label: '',
        limit: 280,
        rows: 2,
        placeholder: 'Type your post message here ...',
        validate: { required: true },
        control: {
          style: 'border-width: 2px; border-color: #24C980;',
          class: null
        }
      },
      iu: {
        modalOpen: false,
        mode: 'campaign',
        allowedRatios: ['widescreen'],
        defaultRatio: 'widescreen',
        format: 'image/jpeg',
        compress: 0.99
      },
      uploadedImage: null,
      uploadedImages: [],
      unsplash: {
        modalOpen: false,
        searchTerm: null,
        numResults: 20
      },
      unsplashImage: null,
      tourTimeout: null // we use this to detect when typing has stopped (used in the Tour)
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount',
      vuexTheme: 'wizardTheme',
      vuexPostContent: 'wizardPostContent',
      vuexWizardType: 'wizardType',
      vuexWizard: 'fullWizard'
    }),
    currentTitle: function () {
      if (this.words.buckets.titles) return this.words.buckets.titles[this.words.buckets.titleIndex]
      else return null
    },
    currentHeading: function () {
      if (this.words.buckets.headings) return this.words.buckets.headings[this.words.buckets.headingIndex]
      else return null
    },
    currentDescription: function () {
      if (this.words.buckets.descriptions) return this.words.buckets.descriptions[this.words.buckets.descriptionIndex]
      else return null
    },
    currentContent: function () {
      if (this.words.buckets.contents) return this.words.buckets.contents[this.words.buckets.contentIndex]
      else return null
    },
    currentAction: function () {
      if (this.words.buckets.actions) return this.words.buckets.actions[this.words.buckets.actionIndex]
      else return null
    },
    currentActionHeading: function () {
      if (this.words.buckets.actionheadings) return this.words.buckets.actionheadings[this.words.buckets.actionheadingIndex]
      else return null
    },
    currentAllSafe: function () {
      if (this.words.buckets.allsafes) return this.words.buckets.allsafes[this.words.buckets.allsafeIndex]
      else return null
    },
    currentYear: function () {
      if (this.words.variables.date) return this.words.variables.date.split(',')[1].trim()
      else return null
    },
    currentMonthDay: function () {
      if (this.words.variables.date) return this.words.variables.date.split(',')[0].trim()
      else return null
    },
    accountInitals: function () {
      if (this.currentAccount.name) {
        var initials = this.currentAccount.name.match(/\b\w/g) || []
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
        return initials
      } else return null
    }
  },
  watch: {
    imagesGenerating (val, oval) {
      if (val) {
        this.$store.dispatch('setLoadingState', true)
      } else {
        this.$store.dispatch('setLoadingState', false)
        if (oval && this.guideMode) this.TourPostContentImage() // this seems to work, if were coming off the load, that usually means we have images to work with
      }
    },
    imgIndex (val) {
      if (val > 0) if (this.guideMode) this.TourPostContentText()
    },
    'postContent.model' (val) {
      if (val) {
        // https://schier.co/blog/wait-for-user-to-stop-typing-using-javascript
        clearTimeout(this.tourTimeout)
        var vm = this
        this.tourTimeout = setTimeout(function () {
          if (vm.guideMode) vm.TourPostContentContinue()
        }, 2000)
      }
    },
    'uploadedImage.url' (val) {
      if (val) {
        this.uploadImage('file', val)
      }
    },
    'unsplashImage.urls' (val) {
      if (val) {
        this.uploadImage('url', val.raw)
      }
    }
  },
  mounted () {
    this.$store.dispatch('setWizardStep', 8)
    if (this.vuexPostContent) {
      if (process.env.NODE_ENV === 'development') console.log('need to load into step: ' + JSON.stringify(this.vuexPostContent))
      // POST
      if (this.vuexPostContent.postContent) {
        this.postContent = this.$lodash.cloneDeep(this.vuexPostContent.postContent)
      }
      // WORDS
      if (this.vuexPostContent.words) {
        this.words = this.$lodash.cloneDeep(this.vuexPostContent.words)
      } else {
        this.words = this.$lodash.cloneDeep(this.vuexWizard.wizardLandingPage.words)
      }
      // IMAGES
      if (this.vuexPostContent.images) {
        this.images = this.$lodash.cloneDeep(this.vuexPostContent.images)
        if (this.vuexPostContent.imgIndex) {
          this.imgIndex = this.vuexPostContent.imgIndex
        }
        if (this.vuexPostContent.backgroundsToUse) {
          this.backgroundsToUse = this.$lodash.cloneDeep(this.vuexPostContent.backgroundsToUse)
        }
      } else {
        // need to load images up
        this.imagesGenerating = true
        this.backgroundsToUse = this.$lodash.cloneDeep(this.vuexWizard.wizardLandingPage.imagesAvailable.landscape)
        if (this.vuexPostContent.uploadedImages) {
          this.uploadedImages = this.$lodash.cloneDeep(this.vuexPostContent.uploadedImages)
          this.backgroundsToUse = this.uploadedImages.concat(this.backgroundsToUse)
        }
        this.rebuildImages()
      }
      // this below is for backwards compatibility for campaigns that were using textinputs
      if (!this.postContent.limit) this.postContent.limit = 280
      if (!this.postContent.rows) this.postContent.rows = 2
    } else {
      // fresh step
      // WORDS
      this.words = this.$lodash.cloneDeep(this.vuexWizard.wizardLandingPage.words)
      // IMAGES
      this.imagesGenerating = true
      // take all the images from the landing page
      this.backgroundsToUse = this.$lodash.cloneDeep(this.vuexWizard.wizardLandingPage.imagesAvailable.landscape)
      this.rebuildImages()
    }
    // set default tags for unsplash
    if (this.vuexTheme) if (this.vuexTheme.tagsToFilter) if (this.vuexTheme.tagsToFilter.length > 0) this.unsplash.searchTerm = this.vuexTheme.tagsToFilter[0]
  },
  methods: {
    changeImage (index) {
      this.imgIndex = index
    },
    changePhrase (phraseToChange) {
      var i = phraseToChange + 'Index'
      var b = phraseToChange + 's'
      // cycles through words avilable object
      if (this.words.buckets[i] === this.words.buckets[b].length - 1) {
        this.words.buckets[i] = 0
      } else {
        this.words.buckets[i]++
      }
    },
    numberOfPhrases (phrase) {
      if (phrase) {
        var b = phrase + 's'
        if (this.words.buckets[b]) {
          return this.words.buckets[b].length
        }
      }
      return null
    },
    submit () {
      this.$router.push({ name: 'route-engine-campaigns-wizard-channels' })
    },
    rebuildImages () {
      var imageRequests = []
      var imageTypes = []
      // Just Backgrounds
      imageTypes.push({
        group: 'b01_',
        overlays: []
      })
      // Golf Overlays
      if (this.currentYear && this.currentMonthDay) {
        imageTypes.push({
          group: 'c01_',
          filter: this.vuexTheme.currentColors.primary,
          overlays: [
            {
              type: 'golf',
              options: {
                font: this.vuexTheme.currentFont.name,
                lineColor: this.vuexTheme.currentColors.primaryFont,
                fontColor: this.vuexTheme.currentColors.primaryFont,
                rectangleFontColor: this.vuexTheme.currentColors.secondary,
                topText: this.currentMonthDay,
                middleText: this.currentYear,
                bottomText: utils.capitalizeWords(this.currentTitle),
                rectangleText: utils.capitalizeWords(this.currentAction)
              }
            }
          ]
        })
      }
      // Color Border Overlays
      if (this.currentMonthDay) {
        imageTypes.push({
          group: 'f01_',
          overlays: [
            {
              type: 'shape',
              options: {
                type: 'rectangle',
                color: this.vuexTheme.currentColors.primary,
                alpha: 0.6,
                w: '100%',
                h: '60%',
                xalign: 'left',
                yalign: 'middle',
                text: this.currentMonthDay,
                font: this.vuexTheme.currentFont.name,
                fontColor: this.vuexTheme.currentColors.primaryFont,
                textAlign: 'center',
                textWrap: true,
                textPaddingX: '10%',
                textPaddingY: '10%'
              }
            },
            {
              type: 'shape',
              options: {
                type: 'rectangle',
                color: this.vuexTheme.currentColors.secondary,
                alpha: 0.5,
                w: '100%',
                h: '20%',
                xalign: 'left',
                yalign: 'bottom',
                text: utils.uppercaseLetters(this.currentTitle),
                font: this.vuexTheme.currentFont.name,
                fontColor: this.vuexTheme.currentColors.secondaryFont,
                textAlign: 'center',
                textWrap: true,
                textPaddingX: '10%',
                textPaddingY: '10%'
              }
            },
            {
              type: 'shape',
              options: {
                type: 'rectangle',
                color: this.vuexTheme.currentColors.secondary,
                alpha: 0.5,
                w: '100%',
                h: '20%',
                xalign: 'left',
                yalign: 'top',
                text: '',
                font: this.vuexTheme.currentFont.name,
                fontColor: this.vuexTheme.currentColors.secondaryFont,
                textAlign: 'center',
                textWrap: true,
                textPaddingX: '10%',
                textPaddingY: '10%'
              }
            }
          ]
        })
      }
      imageTypes.push({
        group: 'f02_',
        overlays: [
          {
            type: 'shape',
            options: {
              type: 'rectangle',
              color: this.vuexTheme.currentColors.primary,
              alpha: 0.6,
              w: '100%',
              h: '60%',
              xalign: 'left',
              yalign: 'middle',
              text: utils.uppercaseLetters(this.currentTitle),
              font: this.vuexTheme.currentFont.name,
              fontColor: this.vuexTheme.currentColors.primaryFont,
              textAlign: 'center',
              textWrap: true,
              textPaddingX: '10%',
              textPaddingY: '10%'
            }
          },
          {
            type: 'shape',
            options: {
              type: 'rectangle',
              color: this.vuexTheme.currentColors.secondary,
              alpha: 0.5,
              w: '100%',
              h: '20%',
              xalign: 'left',
              yalign: 'bottom',
              text: '',
              font: this.vuexTheme.currentFont.name,
              fontColor: this.vuexTheme.currentColors.secondaryFont,
              textAlign: 'center',
              textWrap: true,
              textPaddingX: '10%',
              textPaddingY: '10%'
            }
          },
          {
            type: 'shape',
            options: {
              type: 'rectangle',
              color: this.vuexTheme.currentColors.secondary,
              alpha: 0.5,
              w: '100%',
              h: '20%',
              xalign: 'left',
              yalign: 'top',
              text: '',
              font: this.vuexTheme.currentFont.name,
              fontColor: this.vuexTheme.currentColors.secondaryFont,
              textAlign: 'center',
              textWrap: true,
              textPaddingX: '10%',
              textPaddingY: '10%'
            }
          }
        ]
      })
      // Circle Overlays
      if (this.currentYear && this.currentMonthDay) {
        imageTypes.push({
          group: 'j01_',
          overlays: [
            {
              type: 'circle',
              options: {
                circleColor: this.vuexTheme.currentColors.primary,
                circleAlpha: 0.9,
                fontColor: this.vuexTheme.currentColors.primaryFont,
                lineColor: this.vuexTheme.currentColors.primaryFont,
                font: this.vuexTheme.currentFont.name,
                upperText: utils.uppercaseLetters(this.currentTitle),
                middleText: this.currentMonthDay,
                lowerText: utils.capitalizeWords(this.currentAction)
              }
            }
          ]
        })
      }
      imageTypes.push({
        group: 'j02_',
        overlays: [
          {
            type: 'circle',
            options: {
              circleColor: this.vuexTheme.currentColors.primary,
              circleAlpha: 0.9,
              fontColor: this.vuexTheme.currentColors.primaryFont,
              lineColor: this.vuexTheme.currentColors.primaryFont,
              font: this.vuexTheme.currentFont.name,
              upperText: utils.uppercaseLetters(this.currentHeading),
              middleText: utils.uppercaseLetters(this.currentAction),
              lowerText: utils.capitalizeWords(this.currentTitle)
            }
          }
        ]
      })
      imageTypes.push({
        group: 'j03_',
        overlays: [
          {
            type: 'circle',
            options: {
              circleColor: this.vuexTheme.currentColors.primary,
              circleAlpha: 0.9,
              fontColor: this.vuexTheme.currentColors.primaryFont,
              lineColor: this.vuexTheme.currentColors.primaryFont,
              font: this.vuexTheme.currentFont.name,
              upperText: utils.uppercaseLetters(this.currentAction),
              middleText: utils.uppercaseLetters(this.currentTitle),
              lowerText: utils.capitalizeWords(this.currentHeading)
            }
          }
        ]
      })
      imageTypes.push({
        group: 'j04_',
        overlays: [
          {
            type: 'circle',
            options: {
              circleColor: this.vuexTheme.currentColors.primary,
              circleAlpha: 0.9,
              fontColor: this.vuexTheme.currentColors.primaryFont,
              lineColor: this.vuexTheme.currentColors.primaryFont,
              font: this.vuexTheme.currentFont.name,
              upperText: utils.uppercaseLetters(this.currentTitle),
              middleText: utils.uppercaseLetters(this.currentAction),
              lowerText: utils.capitalizeWords(this.currentHeading)
            }
          }
        ]
      })
      // Outline Overlays
      imageTypes.push({
        group: 'm01_',
        filter: this.vuexTheme.currentColors.primary,
        overlays: [
          {
            type: 'outline',
            options: {
              lineColor: this.vuexTheme.currentColors.primaryFont,
              fontColor: this.vuexTheme.currentColors.primaryFont,
              font: this.vuexTheme.currentFont.name,
              mainText: utils.capitalizeWords(this.currentTitle),
              lowerText: utils.capitalizeWords(this.currentAction)
            }
          }
        ]
      })
      imageTypes.push({
        group: 'm02_',
        filter: this.vuexTheme.currentColors.primary,
        overlays: [
          {
            type: 'outline',
            options: {
              lineColor: this.vuexTheme.currentColors.primaryFont,
              fontColor: this.vuexTheme.currentColors.primaryFont,
              font: this.vuexTheme.currentFont.name,
              mainText: utils.capitalizeWords(this.currentHeading),
              lowerText: utils.capitalizeWords(this.currentTitle)
            }
          }
        ]
      })
      imageTypes.push({
        group: 'm03_',
        filter: this.vuexTheme.currentColors.primary,
        overlays: [
          {
            type: 'outline',
            options: {
              lineColor: this.vuexTheme.currentColors.primaryFont,
              fontColor: this.vuexTheme.currentColors.primaryFont,
              font: this.vuexTheme.currentFont.name,
              mainText: utils.capitalizeWords(this.currentDescription),
              lowerText: utils.capitalizeWords(this.currentAction)
            }
          }
        ]
      })
      // Arrow Overlay
      imageTypes.push({
        group: 'p01_',
        overlays: [
          {
            type: 'arrows',
            options: {
              arrowColor: this.vuexTheme.currentColors.primary,
              arrowAlpha: 0.9,
              circleAlpha: 1,
              circleColor: this.vuexTheme.currentColors.primaryFont,
              fontColor: this.vuexTheme.currentColors.primaryFont,
              font: this.vuexTheme.currentFont.name,
              upperText: utils.capitalizeWords(this.currentTitle),
              lowerText: utils.uppercaseLetters(this.currentAction)
            }
          }
        ]
      })
      // Shape Overlay
      imageTypes.push({
        group: 's01_',
        overlays: [
          {
            type: 'shape',
            options: {
              type: 'rectangle',
              color: this.vuexTheme.currentColors.primary,
              alpha: 0.8,
              w: '100%',
              h: '30%',
              xalign: 'center',
              yalign: 'bottom',
              text: utils.capitalizeWords(this.currentTitle),
              font: this.vuexTheme.currentFont.name,
              fontColor: this.vuexTheme.currentColors.primaryFont,
              textAlign: 'center',
              textWrap: false,
              textPaddingX: '10%',
              textPaddingY: '10%'
            }
          }
        ]
      })
      imageTypes.push({
        group: 's02_',
        overlays: [
          {
            type: 'shape',
            options: {
              type: 'rectangle',
              color: this.vuexTheme.currentColors.primary,
              alpha: 0.8,
              w: '30%',
              h: '100%',
              xalign: 'left',
              yalign: 'middle',
              text: utils.uppercaseLetters(this.currentAction),
              font: this.vuexTheme.currentFont.name,
              fontColor: this.vuexTheme.currentColors.primaryFont,
              textAlign: 'center',
              textWrap: true,
              textPaddingX: '10%',
              textPaddingY: '10%'
            }
          }
        ]
      })
      // create an image request for every background X every image type
      var isFirst = true
      var vm = this
      if (this.backgroundsToUse.length > 0) {
        this.backgroundsToUse.forEach(function (background) {
          // the unsplash code here gives every post image created with an unsplash background
          // a special URL/filename. The name has the unsplash "photo-123123sdf-123" added to it.
          // we can then look for this when we save the campaign ... and if we know we're using an
          // unsplash image, trigger a download API request qhich we're required to do
          // the logic for triggering the unsplash request is in the save campaign function(s)
          var unsplashRegex = /^https:\/\/images\.unsplash\.com[a-zA-Z/]{0,}\/(.+)\?/
          var unsplashMatch = background.match(unsplashRegex)
          imageTypes.forEach(function (type) {
            var t = vm.$lodash.cloneDeep(type)
            if (isFirst) {
              // this assures the uploaded image is the first in the list
              // if there are no uploaded images, the first bg is used
              t.group = 'a_'
              isFirst = false
            }
            t.background = background
            if (unsplashMatch) {
              if (unsplashMatch.length > 1) {
                t.group = t.group + unsplashMatch[1] + '_'
              }
            }
            imageRequests.push(t)
          })
        })
        // request images
        var totalImagesBuilt = 0
        imageRequests.forEach(function (requestBody) {
          engineApi.drawImage(requestBody,
            function (err, response) {
              totalImagesBuilt++
              if (err) {
                vm.handleXhrError(err)
              } else {
                if (response) {
                  if (response.Payload) {
                    var iURL = response.Payload.slice(1, -1) // need to remove double quotes on response
                    vm.images.push({ src: iURL, loading: 'https://dashboard.campaignpilot.com/gifs/clouds.gif' })
                  }
                }
              }
              // we're done requesting all images, sort them by group and turn off loading
              if (totalImagesBuilt === imageRequests.length) {
                vm.images.sort((a, b) => (a.src > b.src) ? 1 : -1)
                vm.imagesGenerating = false
              }
            }
          )
        })
      }
    },
    openImageUploader () {
      this.iu.modalOpen = true
    },
    openUnsplash () {
      this.unsplash.modalOpen = true
    },
    uploadImage (mode, url) {
      // mode = file or url
      if (mode === 'file') {
        this.image = {}
        this.$store.dispatch('setLoadingState', true)
        var vm = this
        engineApi.uploadImage(
          vm.currentAccount.id, url, 'campaign', vm.uploadedImage.type,
          function (err, response) {
            vm.$store.dispatch('setLoadingState', false)
            if (err) {
              vm.handleXhrError(err)
            }
            // add image to those available
            if (response) {
              if (response.url) {
                vm.images = [] // trash all images
                vm.imagesGenerating = true // turn on loading
                vm.backgroundsToUse.unshift(response.url) // add uploaded image to front of list
                vm.rebuildImages() // regenerate all images
                vm.uploadedImages.push(response.url) // stash the uploaded image
                // change to the first image in the list
                vm.changeImage(0)
              }
            }
          }
        )
      } else if (mode === 'url') {
        var resizeSettings = '&w=2000&h=1125&fit=crop'
        url = url + resizeSettings
        this.images = [] // trash all images
        this.imagesGenerating = true // turn on loading
        this.backgroundsToUse.unshift(url) // add uploaded image to front of list
        this.rebuildImages() // regenerate all images
        this.uploadedImages.push(url) // stash the uploaded image
        // change to the first image in the list
        this.changeImage(0)
      }
    }
  }
}
</script>

<style scoped>
.image-wrapper {
  padding: 20px;
}
.link-area {
  background-color: #f6f7f9;
  padding: 10px;
}
.description-area {
  padding: 10px;
}
.description-fix {
  padding: 0!important;
}
.client-name {
  color: #365899;
}
.post-date {
  margin-top: -20px !important;
}
.landing-description {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 400px;
  height: 1.5em;
  white-space: nowrap;
}
.card {
  max-width: 470px;
}
.card .header {
  padding: 5px 10px;
}
.card-footer .columns {
  width: 100%;
}
.card-footer .columns input {
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.card-footer .columns .column:last-child {
  display: flex;
  align-items: center;
}
.card-footer .columns .column:last-child button {
  border: none;
}
.oto-editable {
  cursor: pointer;
  position: relative;
  z-index: 2!important;
  border-radius: 10px;
  transition: transform .2s;
  padding: 0;
}
.oto-editable:hover {
  transform: scale(1.02);
}
.oto-editable-button {
  position: absolute;
  top: -5px;
  right: 0px;
  z-index: 10;
}
.oto-editable-button > .tag {
  background:rgba(0,0,0,0.7)!important;
  border-radius: 100px!important;
  padding: 0 9px!important;
}
.oto-fake-profile-image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #c4c4c4;
  border: 3px solid #c4c4c4;
  color: white;
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  padding-top: 3px;
}
.oto-set-min-height {
  min-height: 65px;
}
</style>

<template>
  <div>
    <div class="columns">
      <div class="column oto-settings-sub-details">
        <p>Customize CampaignPilot to match the look and feel of your business.</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="tile">
          <div class="is-hidden-mobile oto-vertical-tabs-is-left">
            <nav class="oto-vertical-tabs" id="vertical-tabs-media">
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 0}"
                @click="changeTab(0)">
                <span class="icon">
                  <i class="fas fa-paint-brush"></i>
                </span>
                <span>Colors</span>
              </a>
              <a
                class="panel-block"
                id="#settings-media-logo-tab"
                :class="{'active-panel': currentTab === 1}"
                @click="changeTab(1)">
                <span class="icon">
                  <i class="fas fa-trademark"></i>
                </span>
                <span>Logo</span>
              </a>
              <a
                class="panel-block"
                :class="{'active-panel': currentTab === 2}"
                @click="changeTab(2)">
                <span class="icon">
                  <i class="fas fa-image"></i>
                </span>
                <span>Images</span>
              </a>
              <a/>
            </nav>
          </div>
          <div class="oto-vertical-tabs-is-right">
            <!--  COLORS -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 0}"
              @click="changeTab(0, true)">
              <div id="tab0">Colors</div>
              <div></div>
            </div>
            <div
              v-if="currentTab === 0"
              class="oto-settings-content-area animated fadeIn">
              <Styles/>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!-- LOGOS -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 1}"
              @click="changeTab(1, true)">
              <div id="tab1">Logo</div>
              <div></div>
            </div>
            <div
              v-if="currentTab === 1"
              id="settings-media-logo-area"
              class="oto-settings-content-area animated fadeIn">
              <Logos/>
            </div>
            <hr class="is-hidden-tablet oto-accordian-hr"/>
            <!-- IMAGES -->
            <div
              class="oto-accordian-holder show-hand is-hidden-tablet"
              :class="{'oto-accordian-expanded': currentTab === 2}"
              @click="changeTab(2, true)">
              <div id="tab2">Images</div>
              <div></div>
            </div>
            <div
              v-if="currentTab === 2"
              class="oto-settings-content-area animated fadeIn">
              <Images/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Styles from '@/components/dashboard/settings/media/Styles'
import Logos from '@/components/dashboard/settings/media/Logos'
import Images from '@/components/dashboard/settings/media/Images'
import settings from '@/mixins/Settings.js'

export default {
  components: {
    Styles, Logos, Images
  },
  mixins: [settings],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    })
  },
  mounted () {
    // Kickoff the Tour
    if (this.guideMode) {
      this.TourSettingsLogoStart({ showButtons: true })
      this.currentTab = 1
    }
  }
}
</script>

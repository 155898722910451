<template>
  <div>
    <div class="oto-second-heading is-hidden-mobile">Image Library</div>
    <div v-if="imagesFetched">
      <p
        v-if="images.length === 0"
        class="padding-bottom">Upload images to your library and CampaignPilot will use them as backgrounds when building your marketing content.</p>
      <stack
        v-else
        :column-min-width="320"
        :gutter-width="8"
        :gutter-height="8"
        :monitor-images-loaded="true">
        <stack-item
          v-for="(img, index) in images"
          :key="index">
          <div
            :id="'image-' + index"
            class="client-image-holder oto-hover"
            @click="selectImage($event)">
            <div
              v-if="image.id === img.id"
              class="delete-client-image">
              <a
                class="button is-danger is-rounded"
                @click="deleteImage">
                <span class="icon">
                  <i class="fas fa-trash-alt"/>
                </span>
              </a>
            </div>
            <img :src="img.url">
          </div>
        </stack-item>
      </stack>
      <div>
        <a
          class="button is-primary is-rounded oto-settings-save-button"
          @click="openImageUploader">
          <span class="icon is-small">
            <i class="fas fa-plus"/>
          </span>
          <span>Add Image</span>
        </a>
      </div>
    </div>
    <ImageUploader
      :iu="iu"
      v-model="uploadedImage"/>
  </div>
</template>

<script>
import engineApi from '@/plugins/engine-api.js'
import { Stack, StackItem } from 'vue-stack-grid'
import { mapGetters } from 'vuex'
import ImageUploader from '@/components/elements/ImageUploader'
import xhr from '@/mixins/Xhr.js'

export default {
  components: {
    Stack,
    StackItem,
    ImageUploader
  },
  mixins: [xhr],
  data () {
    return {
      iu: {
        modalOpen: false,
        allowedRatios: ['widescreen'],
        defaultRatio: 'widescreen',
        format: 'image/jpeg',
        compress: 0.99
      },
      modalOpen: false,
      images: [],
      image: {},
      uploadedImage: null,
      imagesFetched: false
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'currentAccount'
    })
  },
  watch: {
    'uploadedImage.url' (val) {
      if (val) {
        this.uploadImage(val)
      }
    }
  },
  mounted () {
    this.fetchImages()
  },
  methods: {
    openImageUploader () {
      this.iu.modalOpen = true
    },
    fetchImages () {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.fetchImages(
        vm.currentAccount.id, 'image',
        function (err, response) {
          vm.imagesFetched = true
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.images = response
          }
        }
      )
    },
    selectImage (event) {
      var image = parseInt(event.currentTarget.id.replace('image-', ''))
      this.image = this.$lodash.cloneDeep(this.images[image])
    },
    deleteImage () {
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.deleteImage(
        vm.currentAccount.id, vm.image.id,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          } else {
            vm.fetchImages()
          }
        }
      )
    },
    uploadImage (url) {
      this.image = {}
      this.$store.dispatch('setLoadingState', true)
      var vm = this
      engineApi.uploadImage(
        vm.currentAccount.id, url, 'image', vm.uploadedImage.type,
        function (err, response) {
          vm.$store.dispatch('setLoadingState', false)
          if (err) {
            vm.handleXhrError(err)
          }
          vm.fetchImages()
        }
      )
    }
  }
}
</script>

<style scoped>
.client-image-holder {
  display: inline-block;
  position: relative;
}
.client-image-holder > img {
  display: block;
}
.delete-client-image {
  position: absolute;
  top: 0;
  right: 0;
  margin: 5px 5px 0 0;
}
</style>

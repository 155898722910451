export default {
  methods: {
    generateCampaignTitle (campaignDetails) {
      var title = null
      if (campaignDetails) {
        campaignDetails.questions.every(function (question) {
          if (question.name) {
            if (question.model) {
              if (question.name === 'title') {
                title = question.model
              }
            }
          }
          if (title) return false
          else return true
        })
        if (!title) {
          campaignDetails.questions.every(function (question) {
            if (question.name) {
              if (question.model) {
                if (question.name.includes('title')) {
                  title = question.model
                }
              }
            }
            if (title) return false
            else return true
          })
        }
      }
      return title
    },
    generateCampaignDescription (campaignPostContent, campaignDetails) {
      // if you pass just the campaignPostContent, this function will attempt to
      // return the content from the campaign social post as the description
      // if you pass both the campaignPostContent and campaignDetails, this function
      // will attempt to return the campaign short description from step 1, and if
      // that's not there, then the content from the campaign social post
      var postDesc = null
      var shortDesc = null
      if (campaignPostContent) {
        if (campaignPostContent.words) {
          if (campaignPostContent.words.buckets) {
            if (campaignPostContent.words.buckets.descriptions) {
              if (campaignPostContent.words.buckets.descriptions.length > 0) {
                postDesc = campaignPostContent.words.buckets.descriptions[campaignPostContent.words.buckets.descriptionIndex]
              }
            }
          }
        }
      }
      if (campaignDetails) {
        campaignDetails.questions.every(function (question) {
          if (question.name) {
            if (question.model) {
              if (question.name === 'short description') {
                shortDesc = question.model
              }
            }
          }
          if (shortDesc) return false // quit every
          else return true // keep going
        })
      }
      return shortDesc || postDesc
    },
    generateCampaignPostText (campaignPostContent) {
      var post = null
      if (campaignPostContent) {
        if (campaignPostContent.postContent) {
          post = campaignPostContent.postContent.model
        }
      }
      return post
    },
    generateCampaignPostImage (campaignPostContent) {
      var image = null
      if (campaignPostContent) {
        if (campaignPostContent.images) {
          image = campaignPostContent.images[campaignPostContent.imgIndex]
          if (typeof image === 'object') {
            if (image.src) {
              image = image.src
            }
          }
        }
      }
      return image
    },
    generateCampaignActionText (campaignLandingPage) {
      var action = null
      if (campaignLandingPage) {
        if (campaignLandingPage.words) {
          if (campaignLandingPage.words.buckets) {
            if (campaignLandingPage.words.buckets.actions) {
              if (campaignLandingPage.words.buckets.actions.length > 0) {
                action = campaignLandingPage.words.buckets.actions[campaignLandingPage.words.buckets.actionIndex]
              }
            }
          }
        }
      }
      return action
    },
    generateCampaignColors (campaignTheme) {
      var colors = null
      if (campaignTheme) {
        if (campaignTheme.currentColors) {
          colors = campaignTheme.currentColors
        }
      }
      return colors
    }
  }
}

<template>
  <div
    v-if="loaded"
    class="content">
    <div class="right-column-text">
      <div class="subtitle-w-bar">
        <span>Edit Button</span>
      </div>
      <div class="field">
        <label class="label">Button Text</label>
        <div class="control">
          <input
            v-model="text"
            type="text"
            class="input"
            placeholder="Some Label">
        </div>
      </div>
      <div class="field">
        <label class="label">Button URL</label>
        <div class="control has-icons-left">
          <span class="icon is-small is-left">
            <i class="fas fa-globe"/>
          </span>
          <input
            v-model="url"
            type="text"
            class="input"
            placeholder="https://www.url.com/">
        </div>
      </div>
      <div class="field">
        <label class="label">Align</label>
        <div class="field has-addons">
          <p class="control">
            <a
              :class="{'is-primary': align === 'left'}"
              class="button is-rounded"
              @click="setAlign('l')">
              <span class="icon is-small">
                <i class="fas fa-align-left"></i>
              </span>
              <span>Left</span>
            </a>
          </p>
          <p class="control">
            <a
              :class="{'is-primary': align === 'center'}"
              class="button is-rounded"
              @click="setAlign('c')">
              <span class="icon is-small">
                <i class="fas fa-align-center"></i>
              </span>
              <span>Center</span>
            </a>
          </p>
          <p class="control">
            <a
              :class="{'is-primary': align === 'right'}"
              class="button is-rounded"
              @click="setAlign('r')">
              <span class="icon is-small">
                <i class="fas fa-align-right"></i>
              </span>
              <span>Right</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: function () { return {} }
    }
  },
  data () {
    return {
      loaded: false,
      text: null,
      url: null,
      align: 'center'
    }
  },
  computed: {
    button: function () {
      if (this.url) {
        var b = {
          text: this.text,
          url: this.url,
          align: this.align
        }
        return b
      } else {
        return null
      }
    }
  },
  watch: {
    button: {
      handler: function (val) {
        this.$emit('input', this.button)
      },
      deep: true
    }
  },
  mounted () {
    if (this.value) {
      if (this.value.text) this.text = this.value.text
      if (this.value.url) this.url = this.value.url
      if (this.value.align) this.align = this.value.align
    }
    this.loaded = true
  },
  methods: {
    setAlign (a) {
      if (a === 'l') this.align = 'left'
      if (a === 'c') this.align = 'center'
      if (a === 'r') this.align = 'right'
    }
  }
}
</script>

<template>
  <nav
    :class="{ 'is-hidden-tablet':props.mobileOnly }"
    class="oto-fab-container">
    <a
      class="oto-fab-button"
      @click="click()">
      <span class="icon is-small has-text-white">
        <i class="fas fa-plus"/>
      </span>
    </a>
  </nav>
</template>

<script>
/**
* PROPS
* takes :props object with params
*   mobileOnly: boolean
*   path:
*/
export default {
  props: {
    props: {
      type: Object,
      default: function () { return {} }
    }
  },
  data () {
    return {

    }
  },
  methods: {
    click () {
      if (this.props.path) this.$router.push({ name: this.props.path })
    }
  }
}
</script>

<style lang="scss" scoped>
.oto-fab-container {
  bottom: 0;
  position: fixed;
  margin: 1em;
  right: 0px;
}
.oto-fab-button {
  box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.18),
              0px 4px 12px -7px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: block;
  text-align: center;
  width: 56px;
  height: 56px;
  margin: 20px auto 0;
  position: relative;
  -webkit-transition: all .1s ease-out;
          transition: all .1s ease-out;
}
.oto-fab-button:active,
.oto-fab-button:focus,
.oto-fab-button:hover {
  box-shadow: 0 0 4px rgba(0,0,0,.14),
    0 4px 8px rgba(0,0,0,.28);
}
.oto-fab-button:not(:last-child) {
  width: 40px;
  height: 40px;
  margin: 20px auto 0;
  opacity: 0;
  -webkit-transform: translateY(50px);
      -ms-transform: translateY(50px);
          transform: translateY(50px);
}
.oto-fab-container:hover .oto-fab-button:not(:last-child) {
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  margin: 15px auto 0;
}
.oto-fab-button:nth-last-child(1) {
  -webkit-transition-delay: 25ms;
          transition-delay: 25ms;
  background: $primary;
}
.oto-fab-button > .icon {
  margin-top: 20px;
}
// tooltip for fab, use it like this: < ... oto-fab-tooltip="some tooltip" ...>
[oto-fab-tooltip]:before {
  bottom: 25%;
  font-family: $family-primary;
  font-weight: 600;
  border-radius: 2px;
  background: $primary;
  color: #fff;
  content: attr(oto-fab-tooltip);
  font-size: 12px;
  visibility: hidden;
  opacity: 0;
  padding: 5px 7px;
  margin-right: 12px;
  position: absolute;
  right: 100%;
  white-space: nowrap;
}
[oto-fab-tooltip]:hover:before,
[oto-fab-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
</style>

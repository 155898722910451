<template>
  <click-outside :handler="clickedOutside">
    <div class="field">
      <label
        v-if="label"
        class="label">
        <span>{{ label }}</span>
      </label>
      <div class="control">
        <div
          :class="{ 'is-active': state }"
          class="dropdown">
          <div class="dropdown-trigger">
            <button
              class="button icon-select icon-button"
              @click="toggle">
              <div
                v-if="showIconToRender"
                class="columns show-hand is-size-7 selected-icon-text">
                <div>
                  <span class="icon is-small">
                    <i :class="'fas fa-' + iconToRender"></i>
                  </span>
                  <span>{{ icon.value }}</span>
                </div>
              </div>
              <span
                v-else
                class="empty-picker">
                &nbsp;
              </span>
            </button>
          </div>
          <div class="dropdown-menu">
            <div class="dropdown-content icon-popup animated fadeIn">
              <div
                v-for="(icon, index) in icons"
                :key="index + 'i'"
                class="content">
                <div class="dropdown-item">
                  <div
                    class="columns icon-row show-hand is-centered is-mobile is-size-6"
                    @click="selectIcon(icon)">
                    <span class="icon is-small">
                      <i :class="'fas fa-' + icon.value"></i>
                    </span>
                    <div>{{ icon.value }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </click-outside>
</template>

<script>
import ClickOutside from '@/plugins/onclick-outside'

export default {
  components: {
    ClickOutside
  },
  props: {
    label: {
      type: String,
      required: false
    },
    value: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      state: false,
      icon: null,
      iconToRender: null,
      showIconToRender: false,
      icons: [
        { id: '1', value: 'address-card' },
        { id: '2', value: 'align-center' },
        { id: '3', value: 'align-justify' },
        { id: '4', value: 'align-left' },
        { id: '5', value: 'align-right' },
        { id: '6', value: 'angle-double-left' },
        { id: '7', value: 'angle-double-right' },
        { id: '8', value: 'angle-down' },
        { id: '9', value: 'angle-left' },
        { id: '10', value: 'angle-right' },
        { id: '11', value: 'arrow-circle-down' },
        { id: '12', value: 'arrow-circle-up' },
        { id: '13', value: 'arrow-circle-left' },
        { id: '14', value: 'arrow-circle-right' },
        { id: '15', value: 'arrow-down' },
        { id: '16', value: 'arrow-left' },
        { id: '17', value: 'arrow-right' },
        { id: '18', value: 'arrow-up' },
        { id: '19', value: 'arrows-alt' },
        { id: '20', value: 'at' },
        { id: '21', value: 'backspace' },
        { id: '22', value: 'bacon' },
        { id: '23', value: 'beer' },
        { id: '24', value: 'bell' },
        { id: '25', value: 'brain' },
        { id: '26', value: 'broadcast-tower' },
        { id: '27', value: 'building' },
        { id: '28', value: 'bullhorn' },
        { id: '29', value: 'calendar-alt' },
        { id: '30', value: 'calendar-check' },
        { id: '31', value: 'caret-square-down' },
        { id: '32', value: 'certificate' },
        { id: '33', value: 'chalkboard-teacher' },
        { id: '34', value: 'chart-line' },
        { id: '35', value: 'check' },
        { id: '36', value: 'check-circle' },
        { id: '37', value: 'check-square' },
        { id: '38', value: 'chevron-down' },
        { id: '39', value: 'chevron-up' },
        { id: '40', value: 'circle' },
        { id: '41', value: 'clock' },
        { id: '42', value: 'cloud-download-alt' },
        { id: '43', value: 'coffee' },
        { id: '44', value: 'comment' },
        { id: '45', value: 'copy' },
        { id: '46', value: 'credit-card' },
        { id: '47', value: 'desktop' },
        { id: '48', value: 'dollar-sign' },
        { id: '49', value: 'dot-circle' },
        { id: '50', value: 'edit' },
        { id: '51', value: 'envelope' },
        { id: '52', value: 'envelope-open-text' },
        { id: '53', value: 'exclamation-triangle' },
        { id: '54', value: 'external-link-alt' },
        { id: '55', value: 'female' },
        { id: '56', value: 'file-image' },
        { id: '57', value: 'fish' },
        { id: '58', value: 'flag' },
        { id: '59', value: 'flag-usa' },
        { id: '60', value: 'football-ball' },
        { id: '61', value: 'futbol' },
        { id: '62', value: 'gift' },
        { id: '63', value: 'gifts' },
        { id: '64', value: 'glass-cheers' },
        { id: '65', value: 'globe' },
        { id: '66', value: 'globe-americas' },
        { id: '67', value: 'golf-ball' },
        { id: '68', value: 'grin-hearts' },
        { id: '69', value: 'grin-stars' },
        { id: '70', value: 'guitar' },
        { id: '71', value: 'hand-point-up' },
        { id: '72', value: 'heart' },
        { id: '73', value: 'home' },
        { id: '74', value: 'i-cursor' },
        { id: '75', value: 'image' },
        { id: '76', value: 'images' },
        { id: '77', value: 'infinity' },
        { id: '78', value: 'info-circle' },
        { id: '79', value: 'key' },
        { id: '80', value: 'keyboard' },
        { id: '81', value: 'laugh' },
        { id: '82', value: 'list' },
        { id: '83', value: 'list-ul' },
        { id: '84', value: 'lock' },
        { id: '85', value: 'mail-bulk' },
        { id: '86', value: 'male' },
        { id: '87', value: 'mobile' },
        { id: '88', value: 'mobile-alt' },
        { id: '89', value: 'money-bill' },
        { id: '90', value: 'moon' },
        { id: '91', value: 'mouse' },
        { id: '92', value: 'mouse-pointer' },
        { id: '93', value: 'paste' },
        { id: '94', value: 'pen' },
        { id: '95', value: 'pencil-alt' },
        { id: '96', value: 'pencil-ruler' },
        { id: '97', value: 'percent' },
        { id: '98', value: 'phone' },
        { id: '99', value: 'phone-square' },
        { id: '100', value: 'plus' },
        { id: '101', value: 'question' },
        { id: '102', value: 'question-circle' },
        { id: '103', value: 'random' },
        { id: '104', value: 'redo-alt' },
        { id: '105', value: 'retweet' },
        { id: '106', value: 'running' },
        { id: '107', value: 'save' },
        { id: '108', value: 'search' },
        { id: '109', value: 'search-plus' },
        { id: '110', value: 'skull' },
        { id: '111', value: 'smile' },
        { id: '112', value: 'spa' },
        { id: '113', value: 'star' },
        { id: '114', value: 'star-of-life' },
        { id: '115', value: 'stopwatch' },
        { id: '116', value: 'sun' },
        { id: '117', value: 'sync-alt' },
        { id: '118', value: 'tachometer-alt' },
        { id: '119', value: 'tag' },
        { id: '120', value: 'tags' },
        { id: '121', value: 'tasks' },
        { id: '122', value: 'times-circle' },
        { id: '123', value: 'trash-alt' },
        { id: '124', value: 'trophy' },
        { id: '125', value: 'truck-pickup' },
        { id: '126', value: 'undo' },
        { id: '127', value: 'unlink' },
        { id: '128', value: 'upload' },
        { id: '129', value: 'user' },
        { id: '130', value: 'user-circle' },
        { id: '131', value: 'user-friends' },
        { id: '132', value: 'user-ninja' },
        { id: '133', value: 'users' },
        { id: '134', value: 'utensils' },
        { id: '135', value: 'wifi' },
        { id: '136', value: 'window-close' },
        { id: '137', value: 'window-restore' },
        { id: '138', value: 'wine-bottle' }
      ]
    }
  },
  watch: {
    icon (val) {
      this.$emit('input', val)
      this.redrawIconFix()
    }
  },
  mounted: function () {
    if (this.value) this.icon = this.$lodash.cloneDeep(this.value)
  },
  methods: {
    toggle () {
      this.state = !this.state
    },
    selectIcon (icon) {
      this.icon = icon
      this.clickedOutside()
    },
    clickedOutside () {
      if (this.state) this.state = false
    },
    redrawIconFix () {
      if (this.icon) {
        this.showIconToRender = false
        var vm = this
        this.iconToRender = this.icon.value
        setTimeout(function () {
          vm.showIconToRender = true
        }, 200)
      }
    }
  }
}
</script>

<style scoped>
.icon-button {
  border-radius: 3px!important;
  border-color: #DBDBDB!important
}
.dropdown-content {
  min-width: 278px;
}
.icon-popup {
  height: 280px;
  overflow-y: scroll;
}
.icon-row:hover {
  background-color: #f4f4f4;
}
.empty-picker {
  margin-top: -5px;
}
.icon-select {
  height: 45px;
  width: 280px;
  padding-left: 0px;
}
.dropdown-menu {
  min-width: 0px;
  width: 260px;
}
.icon-row {
  display: block;
  padding-left: 4px;
  width: 100%;
}
.icon-row > div {
  margin-left: 15px;
  display: inline-block;
  overflow-x: hidden;
  max-width: 75%;
  white-space: nowrap;
}
.icon-select::after{
  border: 3px solid #23C980;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.7em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 0.7em;
}
.selected-icon-text {
  text-align: left;
  width: 100%;
  padding-left: 20px;
}
</style>
